import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Purchase } from "./../types";

type FormInitialState = {
  item: Purchase;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isSaving: boolean;
};

const initialState: FormInitialState = {
  item: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
};

const reducerSlice = createSlice({
  name: "purchaseForm",
  initialState,
  reducers: {
    requestItem: (state, _: PayloadAction<string>) => {
      state.item = null;
      state.successMsg = "";
      state.error = "";
      state.isRequesting = true;
    },
    itemSuccess: (state, { payload }: PayloadAction<Purchase>) => {
      state.item = payload;
      state.isRequesting = false;
    },
    itemError: (state, { payload }: PayloadAction<string>) => {
      state.isRequesting = false;
      state.error = payload;
    },
    saveRequest: (state, _: PayloadAction<Purchase>) => {
      state.isSaving = true;
      state.successMsg = "";
      state.error = "";
    },
    saveSuccess: (state, { payload }: PayloadAction<Purchase>) => {
      state.isSaving = false;
      state.successMsg = `Compra salva com sucesso!`;
      state.item = payload;
    },
    saveError: (state, { payload }: PayloadAction<string>) => {
      state.isSaving = false;
      state.error = payload;
    },
  },
});

export const purchaseFormActions = reducerSlice.actions;

export default reducerSlice;
