import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Regra } from "../actions";
import ProdSelector from "../../prod/selector";
import NcmSelector from "../../../components/NcmSelector";
import DestSelector from "../../dest/selector";
import EmissorSelector from "../../emissor/selector";
import { UFOptions } from "../../../components/UFOptions";
import Csosn from "../../../components/Csosn";
import InputNumber from "../../../components/InputNumber";
import CfopSelector from "../../../components/CfopSelector";
import CstIcms from "../../../components/CstIcms";
import ModBcIcms from "../../../components/ModBcIcms";
import ModBcIcmsSt from "../../../components/ModBcIcmsSt";
import CstIpi from "../../../components/CstIpi";
import CstPisCofins from "../../../components/CstPisCofins";

export const RegraForm: React.FC<{
  regra: Regra;
  onChange: (regra: Regra) => void;
}> = ({ regra, onChange }) => (
  <>
    <Row>
      <Col>
        <h3>Critérios</h3>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          onClick={() =>
            onChange({
              ...regra,
              quando: [
                ...regra.quando,
                {
                  tipoObj: "TipoNF",
                  operador: "eq",
                  modelo: "55",
                },
              ],
            })
          }
        >
          Incluir critério
        </Button>
      </Col>
    </Row>
    {regra.quando.map((quando, i) => (
      <Row key={`${i}`} className="py-2">
        <Col xs={1} className="d-flex align-items-end justify-content-end">
          <Button
            onClick={() => {
              const quando = [...regra.quando];
              quando.splice(i, 1);
              onChange({
                ...regra,
                quando,
              });
            }}
            title="Remover Critério"
            variant="danger"
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Col>
        <Form.Group as={Col} controlId="tipoObj" xs={3}>
          <Form.Label>Quando:</Form.Label>
          <Form.Select
            onChange={(ev) => {
              const quando = [...regra.quando];
              // @ts-ignore
              quando[i].tipoObj = ev.target.value;
              onChange({
                ...regra,
                quando,
              });
            }}
            value={quando.tipoObj}
          >
            <option value="Prod">Produto</option>
            <option value="NCM">NCM</option>
            <option value="Dest">Cliente</option>
            <option value="UF">UF</option>
            <option value="Emissor">Emissor</option>
            <option value="TipoNF">Tipo de Venda</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} controlId="operador" xs={4}>
          <Form.Label>Operador:</Form.Label>
          <Form.Select
            onChange={(ev) => {
              const quando = [...regra.quando];
              quando[i].operador = ev.target.value === "eq" ? "eq" : "ne";
              onChange({
                ...regra,
                quando,
              });
            }}
            value={quando.operador}
          >
            <option value="eq">Igual</option>
            <option value="ne">Diferente de</option>
          </Form.Select>
        </Form.Group>
        {quando.tipoObj === "Prod" && (
          <Form.Group as={Col} controlId="prod" xs={4}>
            <Form.Label>Produto:</Form.Label>
            <ProdSelector
              value={quando.prod}
              onChange={(prod) => {
                const quando = [...regra.quando];
                quando[i].prod = prod;
                onChange({
                  ...regra,
                  quando,
                });
              }}
            />
          </Form.Group>
        )}
        {quando.tipoObj === "NCM" && (
          <Form.Group as={Col} controlId="ncm" xs={4}>
            <Form.Label>NCM:</Form.Label>
            <NcmSelector
              value={quando.ncm}
              onChange={(ncm) => {
                const quando = [...regra.quando];
                quando[i].ncm = ncm;
                onChange({
                  ...regra,
                  quando,
                });
              }}
            />
          </Form.Group>
        )}
        {quando.tipoObj === "Dest" && (
          <Form.Group as={Col} controlId="ncm" xs={4}>
            <Form.Label>Cliente:</Form.Label>
            <DestSelector
              value={quando.dest}
              onChange={(dest) => {
                const quando = [...regra.quando];
                quando[i].dest = dest;
                onChange({
                  ...regra,
                  quando,
                });
              }}
            />
          </Form.Group>
        )}
        {quando.tipoObj === "UF" && (
          <Form.Group as={Col} controlId="uf" xs={4}>
            <Form.Label>UF:</Form.Label>
            <Form.Select
              onChange={(ev) => {
                const quando = [...regra.quando];
                quando[i].uf = ev.target.value;
                onChange({
                  ...regra,
                  quando,
                });
              }}
              value={quando.uf}
            >
              <UFOptions />
            </Form.Select>
          </Form.Group>
        )}
        {quando.tipoObj === "Emissor" && (
          <Form.Group as={Col} controlId="emissor" xs={4}>
            <Form.Label>Emissor:</Form.Label>
            <EmissorSelector
              value={quando.emissor}
              onChange={(emissor) => {
                const quando = [...regra.quando];
                quando[i].emissor = emissor;
                onChange({
                  ...regra,
                  quando,
                });
              }}
            />
          </Form.Group>
        )}
        {quando.tipoObj === "TipoNF" && (
          <Form.Group as={Col} controlId="modelo" xs={4}>
            <Form.Label>Tipo de Venda:</Form.Label>
            <Form.Select
              onChange={(ev) => {
                const quando = [...regra.quando];
                quando[i].modelo = ev.target.value === "55" ? "55" : "65";
                onChange({
                  ...regra,
                  quando,
                });
              }}
              value={quando.tipoObj}
            >
              <option value="55">Venda Normal</option>
              <option value="65">Venda Rápida</option>
            </Form.Select>
          </Form.Group>
        )}
      </Row>
    ))}
    <Row>
      <Col>
        <h3>Aplicar</h3>
        <sup>Todos os campos abaixo são de preenchimento opcional</sup>
      </Col>
    </Row>
    <Row>
      <Form.Group as={Col} controlId="cfop" xs={12}>
        <Form.Label>CFOP</Form.Label>
        <CfopSelector
          value={regra.cfop}
          tipo="S"
          onChange={(cfop) =>
            onChange({
              ...regra,
              cfop,
            })
          }
        />
      </Form.Group>
      <Form.Group as={Col} controlId="csosn" xs={12} md={7}>
        <Form.Label>CSOSN</Form.Label>
        <Csosn
          value={regra.csosn}
          onChange={(csosn) =>
            onChange({
              ...regra,
              csosn,
            })
          }
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pCredSN" xs={12} md={5}>
        <Form.Label>Percentual de Crédito de Simples Nacional</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pCredSN}
          decimalPlaces={2}
          onChange={(pCredSN) => onChange({ ...regra, pCredSN })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="csticms" xs={12} md={6}>
        <Form.Label>CST ICMS</Form.Label>
        <CstIcms
          value={regra.csticms}
          onChange={(csticms) =>
            onChange({
              ...regra,
              csticms,
            })
          }
        />
      </Form.Group>
      <Form.Group as={Col} controlId="modBcIcms" xs={12} md={6}>
        <Form.Label>Mod Base do ICMS</Form.Label>
        <ModBcIcms
          value={regra.modBcIcms}
          onChange={(modBcIcms) =>
            onChange({
              ...regra,
              modBcIcms,
            })
          }
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pRedIcms" xs={12} md={4}>
        <Form.Label>Percentual de Redução da Base de ICMS</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pRedIcms}
          decimalPlaces={2}
          onChange={(pRedIcms) => onChange({ ...regra, pRedIcms })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pIcms" xs={12} md={4}>
        <Form.Label>Aliquota de ICMS</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pIcms}
          decimalPlaces={2}
          onChange={(pIcms) => onChange({ ...regra, pIcms })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pIcmsFcp" xs={12} md={4}>
        <Form.Label>Aliquota de ICMS-FCP</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pIcmsFcp}
          decimalPlaces={2}
          onChange={(pIcmsFcp) => onChange({ ...regra, pIcmsFcp })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="modBcIcmsSt" xs={12}>
        <Form.Label>Mod Base do ICMS-ST</Form.Label>
        <ModBcIcmsSt
          value={regra.modBcIcmsSt}
          onChange={(modBcIcmsSt) =>
            onChange({
              ...regra,
              modBcIcmsSt,
            })
          }
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pMVA" xs={12} md={2}>
        <Form.Label>Percentual MVA</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pMVA}
          decimalPlaces={2}
          onChange={(pMVA) => onChange({ ...regra, pMVA })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pRedBcIcmsSt" xs={12} md={5}>
        <Form.Label>Percentual de Redução da base de ICMS-ST</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pRedBcIcmsSt}
          decimalPlaces={2}
          onChange={(pRedBcIcmsSt) => onChange({ ...regra, pRedBcIcmsSt })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pIcmsSt" xs={12} md={2}>
        <Form.Label>Aliquota de ICMS-ST</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pIcmsSt}
          decimalPlaces={2}
          onChange={(pIcmsSt) => onChange({ ...regra, pIcmsSt })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pIcmsStFcp" xs={12} md={3}>
        <Form.Label>Aliquota de ICMS-ST-FCP</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pIcmsStFcp}
          decimalPlaces={2}
          onChange={(pIcmsStFcp) => onChange({ ...regra, pIcmsStFcp })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="cstipi" xs={12} md={10}>
        <Form.Label>CST IPI</Form.Label>
        <CstIpi
          type="S"
          value={regra.cstipi}
          onChange={(cstipi) =>
            onChange({
              ...regra,
              cstipi,
            })
          }
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pipi" xs={12} md={2}>
        <Form.Label>Aliquota de IPI</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pipi}
          decimalPlaces={2}
          onChange={(pipi) => onChange({ ...regra, pipi })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="cstpis" xs={12} md={10}>
        <Form.Label>CST PIS</Form.Label>
        <CstPisCofins
          type="S"
          value={regra.cstpis}
          onChange={(cstpis) =>
            onChange({
              ...regra,
              cstpis,
            })
          }
        />
      </Form.Group>
      <Form.Group as={Col} controlId="ppis" xs={12} md={2}>
        <Form.Label>Aliquota de PIS</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.ppis}
          decimalPlaces={2}
          onChange={(ppis) => onChange({ ...regra, ppis })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="cstcofins" xs={12} md={10}>
        <Form.Label>CST COFINS</Form.Label>
        <CstPisCofins
          type="S"
          value={regra.cstcofins}
          onChange={(cstcofins) =>
            onChange({
              ...regra,
              cstcofins,
            })
          }
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pcofins" xs={12} md={2}>
        <Form.Label>Aliquota de COFINS</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pcofins}
          decimalPlaces={2}
          onChange={(pcofins) => onChange({ ...regra, pcofins })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pIcmsUfDest" xs={12} md={4}>
        <Form.Label>Aliquota de ICMS da UF de destino</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pIcmsUfDest}
          decimalPlaces={2}
          onChange={(pIcmsUfDest) => onChange({ ...regra, pIcmsUfDest })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pIcmsInter" xs={12} md={4}>
        <Form.Label>Aliquota de ICMS Interestadual</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pIcmsInter}
          decimalPlaces={2}
          onChange={(pIcmsInter) => onChange({ ...regra, pIcmsInter })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="pIcmsInter" xs={12} md={4}>
        <Form.Label>Aliquota de ICMS-FCP da UF de destino</Form.Label>
        <InputNumber
          placeholder="0,00"
          required
          value={regra.pIcmsInter}
          decimalPlaces={2}
          onChange={(pIcmsInter) => onChange({ ...regra, pIcmsInter })}
        />
      </Form.Group>
    </Row>
  </>
);
