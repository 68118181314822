import { all, takeLatest } from "redux-saga/effects";
import { AWSRes } from "./../../redux/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { TRequestServices, servicesActions } from "./reducer";
import { Service } from "./type";
import executeApiRequest from "../../misc/executeApiRequest";
import { call, put } from "redux-saga/effects";
import servicesFormSaga from "./servicesForm/saga";
import executeNFeRequest from "../../misc/executeNFeRequest";

function servicesCall(
  lastKey = null,
  idQuery = "",
  idFilterType = "START",
  indexToUse = "lsi1"
) {
  return executeApiRequest<AWSRes<Service>>(
    "/service",
    "GET",
    {},
    {
      queryStringParameters: {
        lastKey: JSON.stringify(lastKey),
        idQuery,
        idFilterType,
        indexToUse,
      },
    }
  );
}

function* servicesWorker({ payload }: PayloadAction<TRequestServices>) {
  try {
    const res: AWSRes<Service> = {
      items: [],
      Count: 0,
      ScannedCount: 0,
    };

    for (const status of payload.status) {
      const res1: AWSRes<Service> = yield call(
        servicesCall,
        null,
        payload.inicio && payload.fim
          ? `${status}.${payload.inicio}:${status}.${payload.fim}`
          : status,
        payload.inicio && payload.fim ? "BETWEEN" : "START",
        payload.modo
      );
      res.items = [...res.items, ...res1.items];
      res.Count += res1.Count;
      res.ScannedCount += res1.ScannedCount;
      res.lastKey = res1.lastKey;
    }
    yield put(servicesActions.servicesSuccess(res));
  } catch (error) {
    yield put(servicesActions.servicesError(error));
  }
}

function* servicesDeleteWorker({ payload }: PayloadAction<Service>) {
  try {
    yield call(
      executeApiRequest,
      `/service/${payload.invoiceId}`,
      "DELETE",
      {},
      {}
    );
    yield put(servicesActions.deleteSuccess());
    yield put(servicesActions.servicesRequest());
  } catch (error) {
    yield put(servicesActions.deleteError(error));
  }
}

function* emitirNfsWorker({ payload }: PayloadAction<Service>) {
  try {
    const res = yield call(
      executeNFeRequest,
      `/emitirnfs`,
      "GET",
      {},
      {
        queryStringParameters: {
          invoiceId: payload.invoiceId,
          orgId: payload.orgId,
        },
      }
    );
    console.log("Resultado", res);
    if (res.status === true) {
      console.log("Sucesso");
      yield put(servicesActions.emitirNfsSuccess(payload));
    } else {
      yield put(
        servicesActions.emitirNfsError(
          res.ListaMensagemRetorno?.MensagemRetorno?.map(
            (msg) => `${msg.Mensagem}`
          ).join("\n") || JSON.stringify(res)
        )
      );
    }
  } catch (error) {
    console.log(error?.response);
    yield put(servicesActions.emitirNfsError(error?.response?.data || error));
  }
}

function* cancelarNfsWorker({ payload }: PayloadAction<Service>) {
  try {
    const res = yield call(
      executeNFeRequest,
      `/cancelanfs`,
      "GET",
      {},
      {
        queryStringParameters: {
          invoiceId: payload.invoiceId,
          orgId: payload.orgId,
        },
      }
    );
    console.log("Resultado", res);
    if (res.status === true) {
      console.log("Sucesso");
      yield put(servicesActions.cancelaNfsSuccess(payload));
    } else {
      yield put(
        servicesActions.cancelaNfsError(
          res.ListaMensagemRetorno?.MensagemRetorno?.map(
            (msg) => `${msg.Mensagem}`
          ).join("\n") || JSON.stringify(res)
        )
      );
    }
  } catch (error) {
    console.log(error?.response);
    yield put(servicesActions.cancelaNfsError(error?.response?.data || error));
  }
}

export default function* servicesSaga() {
  yield all([
    takeLatest("services/servicesRequest", servicesWorker),
    takeLatest("services/deleteRequest", servicesDeleteWorker),
    takeLatest("services/emitirNfsRequest", emitirNfsWorker),
    takeLatest("services/cancelaNfsRequest", cancelarNfsWorker),
    servicesFormSaga(),
  ]);
}
