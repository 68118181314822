// reducer do form do modulo invoice
import {
  COND_FORM_INIT,
  COND_FORM_SUCCESS,
  COND_FORM_ERROR,
  COND_SAVE_REQUESTING,
  COND_SAVE_SUCCESS,
  COND_SAVE_ERROR,
} from "./actions";

const initialState = {
  item: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case COND_FORM_INIT:
      return {
        ...state,
        item: null,
        isRequesting: true,
        successMsg: "",
        error: "",
      };
    case COND_FORM_SUCCESS:
      return {
        ...state,
        item: action.payload,
        isRequesting: false,
      };
    case COND_FORM_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
      };
    case COND_SAVE_REQUESTING:
      return {
        ...state,
        isSaving: true,
      };
    case COND_SAVE_SUCCESS:
      return {
        ...state,
        item: action.payload,
        successMsg: "Condicional salva com sucesso",
        error: "",
        isSaving: false,
      };
    case COND_SAVE_ERROR:
      return {
        ...state,
        error: action.error,
        isSaving: false,
      };
    default:
      return state;
  }
};

export default reducer;
