export const CONDCLOSE_INIT = "CONDCLOSE_INIT";
export const CONDCLOSE_REQUESTING = "CONDCLOSE_REQUESTING";
export const CONDCLOSE_SUCCESS = "CONDCLOSE_SUCCESS";
export const CONDCLOSE_ERROR = "CONDCLOSE_ERROR";
export const CONDCLOSE_CLOSE_REQUESTING = "CONDCLOSE_CLOSE_REQUESTING";
export const CONDCLOSE_CLOSE_SUCCESS = "CONDCLOSE_CLOSE_SUCCESS";
export const CONDCLOSE_CLOSE_ERROR = "CONDCLOSE_CLOSE_ERROR";

export function pageInit(payload?: string) {
  return {
    type: CONDCLOSE_INIT,
    payload,
  };
}

export function condRequesting() {
  return {
    type: CONDCLOSE_REQUESTING,
  };
}

export function condSuccess(payload: any) {
  return {
    type: CONDCLOSE_SUCCESS,
    payload,
  };
}

export function condError(error: any) {
  return {
    type: CONDCLOSE_ERROR,
    error,
  };
}

export function closedRequesting(movs: any[], onFinishCallback: () => void) {
  return {
    type: CONDCLOSE_CLOSE_REQUESTING,
    payload: movs,
    onFinishCallback,
  };
}

export function closedSuccess() {
  return {
    type: CONDCLOSE_CLOSE_SUCCESS,
  };
}

export function closedError(error: any) {
  return {
    type: CONDCLOSE_CLOSE_ERROR,
    error,
  };
}
