import React, { Component } from "react";
import { connect } from "react-redux";
import { delNotification } from "../redux/notificationActions";

import "./NotificationBar.css";
import { Button } from "react-bootstrap";
import Notification from "./Notification";

type NotificationBarProps = {
  isVisible: boolean;
  onNewNotification: () => void;
  notifications: any[];
  delNotification: (notification: any) => void;
  onClose: () => void;
  className?: string;
};

type NotificationBarState = {
  widthFull: number;
  width: number;
  display: string;
};

class NotificationBar extends Component<
  NotificationBarProps,
  NotificationBarState
> {
  state = {
    widthFull: 20,
    width: 20,
    display: "flex",
  };

  componentDidMount() {
    if (this.props.isVisible === false) {
      this.setState({
        display: "none",
        width: 0,
      });
    }
  }

  componentDidUpdate(prevProps: NotificationBarProps) {
    if (this.props.isVisible !== prevProps.isVisible) {
      if (this.props.isVisible === true) {
        this.showNotificationBar();
      } else {
        this.hideNotificationBar();
      }
    }
    if (
      this.props.notifications.length > prevProps.notifications.length &&
      this.props.isVisible === false &&
      typeof this.props.onNewNotification == "function"
    ) {
      this.props.onNewNotification();
    }
  }

  hideNotificationBar = () => {
    this.hideAnimation();
  };

  hideAnimation = () => {
    const animationTime = 100 / this.state.widthFull;
    setTimeout(() => {
      this.setState((prevState) => {
        return {
          width: prevState.width - 0.5,
        };
      });
      if (this.state.width > 0) {
        this.hideAnimation();
      } else {
        this.setState({
          display: "none",
        });
      }
    }, animationTime);
  };

  showNotificationBar = () => {
    this.showAnimation();
  };

  showAnimation = () => {
    const animationTime = 100 / this.state.widthFull;
    setTimeout(() => {
      this.setState((prevState) => {
        return {
          width: prevState.width + 0.5,
        };
      });
      if (this.state.width < 1) {
        this.setState({
          display: "flex",
        });
      }
      if (this.state.width < this.state.widthFull) {
        this.showAnimation();
      }
    }, animationTime);
  };

  discardNotification = (notification: any) => {
    this.props.delNotification(notification);
  };

  renderNotifications = () => {
    if (!this.props.notifications) {
      return null;
    }
    return this.props.notifications.map((notification, i) => (
      <Notification
        key={i}
        title={notification.title}
        message={notification.message}
        onClose={() => this.discardNotification(notification)}
      />
    ));
  };

  render() {
    return (
      <div
        className={`NotificationBar ${this.props.className}`}
        style={{
          right: `${this.state.width - 20}vw`,
          display: this.state.display,
        }}
      >
        <div className="title-segment">
          <span className="title">Notifica&ccedil;&otilde;es</span>
          <Button variant="default" onClick={this.props.onClose}>
            <i className="fas fa-times" />
          </Button>
        </div>
        <div className="itens-segment">{this.renderNotifications()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    notifications: state.notification.notifications,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    delNotification: (notification: any) =>
      dispatch(delNotification({ notification })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
