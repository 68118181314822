import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Modal,
  Button,
  Alert,
  Table,
  Badge,
  Spinner,
} from "react-bootstrap";
import { userpageInit, sendinviteRequesting } from "./actions";
import LoadingContainer from "../../components/LoadingContainer";
import ShowError from "../../components/ShowError";
import LoaderButton from "../../components/LoaderButton";
import ShowSuccess from "../../components/ShowSuccess";
import { RootState } from "../app/mainReducer";
import { loginActions, UserGroup } from "../login/reducer";
import { Subscription } from "../subscription/reducer";
import { differenceInDays } from "date-fns/esm";
import { LinkButton } from "../../components/LinkButton";

const generateId = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

type ProfileProps = {
  org: any;
  orgId: string;
  loggedUser: any;
  userRole: any;
  userSaveError: any;
  isUserSaving: boolean;
  isInvinting: boolean;
  sendinviteError: any;
  sendinviteSuccess: any;
  pageInit: (orgId: string) => void;
  setOrg: (orgObj: any) => void;
  userSaveRequest: (newAttrs: any) => void;
  orgSaveRequest: (org: any) => void;
  sendinviteRequesting: (user: any) => void;
  createUserGroup: (payload: UserGroup) => void;
  isCreateGroupRequesting: boolean;
  createGroupSuccessMessage: string;
  createGroupError: any;
  activeSubscription: Subscription;
};

type ProfileState = {
  isUserEditOpen: boolean;
  userEditIndex: number;
  name: string;
  isNewUser: boolean;
  lastMessage?: string;
  error?: any;
};

class Profile extends Component<ProfileProps, ProfileState> {
  state = {
    isUserEditOpen: false,
    userEditIndex: -1,
    name: "",
    isNewUser: false,
    lastMessage: "",
    error: undefined,
  };

  componentDidMount() {
    // this.props.pageInit(this.props.orgId)
    this.setState({
      name: this.props.loggedUser.attributes.name,
    });
  }

  handleSave = (e) => {
    e.preventDefault();
    // Save the user name
    this.props.userSaveRequest({ name: this.state.name });
    // Save the org
    this.props.orgSaveRequest(this.props.org);
  };

  handleSubUserAdd = () => {
    const users = this.props.org.users ? [...this.props.org.users] : [];
    users.push({
      type: "Operador",
      status: "Novo",
      uid: generateId(),
    });
    this.props.setOrg({
      users,
    });
    this.setState({
      userEditIndex: users.length - 1,
      isUserEditOpen: true,
      isNewUser: true,
    });
  };

  handleEditSubUser = (obj) => {
    const users = this.props.org.users ? [...this.props.org.users] : [];
    users[this.state.userEditIndex] = Object.assign(
      {},
      users[this.state.userEditIndex],
      obj
    );
    this.props.setOrg({
      users,
    });
  };

  handleEditButton = (i) => {
    this.setState({
      userEditIndex: i,
      isUserEditOpen: true,
    });
  };

  handleDeleteButton = (i) => {
    const users = this.props.org.users ? [...this.props.org.users] : [];
    users.splice(i, 1);
    this.props.setOrg({
      users,
    });
    this.props.orgSaveRequest({
      ...this.props.org,
      users,
    });
  };

  sendEmailInvite = (user) => {
    if (!user) {
      return;
    }
    if (!user.email) {
      return;
    }
    // send saga event
    this.props.sendinviteRequesting({
      ...user,
      link: `${window.location.origin}/signup/${this.props.orgId}`,
    });
  };

  renderUserForm = () => {
    if (
      this.state.userEditIndex < 0 ||
      this.state.userEditIndex > this.props.org.users.length
    ) {
      return null;
    }

    const user = this.props.org.users[this.state.userEditIndex];
    if (!user) {
      return null;
    }

    return (
      <>
        <Row>
          <Form.Group as={Col} controlId="name">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome completo"
              value={user.name}
              onChange={(e) => this.handleEditSubUser({ name: e.target.value })}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email"
              value={user.email}
              onChange={(e) =>
                this.handleEditSubUser({ email: e.target.value })
              }
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="type">
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              as="select"
              value={user.type}
              onChange={(e) => this.handleEditSubUser({ type: e.target.value })}
            >
              <option value="Admin">Administrador</option>
              <option value="Operador">Operador</option>
            </Form.Control>
          </Form.Group>
        </Row>
      </>
    );
  };

  renderUsers = () => {
    return (this.props.org.users || []).map((user, i) => (
      <tr key={`${i}`}>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.type}</td>
        <td>
          <Badge bg="info">{user.status}</Badge>
        </td>
        <td>
          {(user.status === "Novo" || user.status === "Convidado") && (
            <LoaderButton
              isLoading={this.props.isInvinting}
              variant="outline-secondary"
              onClick={() => this.sendEmailInvite(user)}
            >
              Re-enviar convite
            </LoaderButton>
          )}
          <Button
            variant="outline-primary"
            onClick={() => this.handleEditButton(i)}
          >
            <i className="fas fa-edit" />
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => this.handleDeleteButton(i)}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    ));
  };

  handleConcludeUser = () => {
    this.setState({ isUserEditOpen: false });
    if (this.state.isNewUser) {
      this.sendEmailInvite(this.props.org.users[this.state.userEditIndex]);
      this.props.orgSaveRequest(this.props.org);
    }
    this.setState({
      isNewUser: false,
    });
  };

  renderUserMgmt = () => {
    if (this.props.userRole !== "Master" && this.props.userRole !== "Admin") {
      return null;
    }

    const planId =
      this.props.activeSubscription && this.props.activeSubscription.plan?.id;
    const created = new Date(
      this.props.org ? this.props.org.createdAt || 0 : 0
    );
    const dif = differenceInDays(new Date(), created);
    const isValidForTrial = dif > 30 ? false : true;

    const qtdUsers = (this.props.org.users || []).length;

    const maxUsers = planId
      ? planId === "price_1JTYJRAeOalWZYjgzDkXE4fr"
        ? 5
        : 10
      : isValidForTrial
      ? 5
      : 0;
    return (
      <>
        <Row>
          <Col>
            <LinkButton
              variant="outline-success"
              to="/subscription/subscriptions"
            >
              Ver assinatura
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Usuarios na minha organiza&ccedil;&atilde;o</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="outline-info"
              disabled={qtdUsers >= maxUsers}
              onClick={this.handleSubUserAdd}
            >
              <i className="fas fa-plus" /> Incluir Usuario
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col>
            <Table striped hover responsive variant="dark">
              <thead>
                <tr>
                  <th>Usuario</th>
                  <th>Email</th>
                  <th>Tipo</th>
                  <th>Status</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.renderUsers()}</tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    if (!this.props.org) {
      return (
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" role="status"></Spinner>
          </Col>
        </Row>
      );
    }
    const isLoading = !this.props.org || !this.props.loggedUser;
    return (
      <LoadingContainer isLoading={isLoading}>
        <Form onSubmit={this.handleSave}>
          <Modal
            show={this.state.isUserEditOpen}
            size="xl"
            onHide={() => this.setState({ isUserEditOpen: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Form do item */}
              {this.renderUserForm()}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.handleConcludeUser}>
                Concluir
              </Button>
            </Modal.Footer>
          </Modal>
          <Row>
            <Col>
              <h3>Informações da sua conta</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <ShowError error={this.props.userSaveError} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Alert
                show={!!this.state.lastMessage}
                variant={this.state.error ? "danger" : "success"}
              >
                <Alert.Heading>
                  {this.state.error ? "Ops! Ocorreu um erro" : "Sucesso!"}
                </Alert.Heading>
                <p>{this.state.lastMessage}</p>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col>Usuario: {this.props.loggedUser.attributes.email}</Col>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome completo"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="organization">
              <Form.Label>Minha Organização</Form.Label>
              <Form.Control
                type="text"
                placeholder="Organização"
                value={this.props.org.name}
                disabled={this.props.userRole !== "Master"}
                onChange={(e) => this.props.setOrg({ name: e.target.value })}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <ShowError error={this.props.sendinviteError} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ShowSuccess message={this.props.sendinviteSuccess} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ShowError error={this.props.createGroupError} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ShowSuccess message={this.props.createGroupSuccessMessage} />
            </Col>
          </Row>
          {this.renderUserMgmt()}
          <Row>
            <Col>
              <LoaderButton
                isLoading={this.props.isUserSaving}
                disabled={false}
                type="submit"
                className="my-2"
              >
                <i className="fas fa-save" />
                &nbsp;&nbsp;Salvar
              </LoaderButton>
            </Col>
          </Row>
          <Row>
            <Col>
              <LoaderButton
                isLoading={this.props.isCreateGroupRequesting}
                disabled={false}
                type="button"
                onClick={() =>
                  this.props.createUserGroup({
                    groupName: `${this.props.orgId}`,
                    groupDescription: `Grupo criado de teste`,
                  })
                }
              >
                <i className="fas fa-user" />
                Criar grupo
              </LoaderButton>
            </Col>
          </Row>
        </Form>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    org: state.login.org,
    orgId: state.login.orgId,
    activeSubscription: state.subscription.activeSubscription,
    loggedUser: state.login.loggedUser,
    userRole: state.login.userRole,
    userSaveError: state.login.userSaveError,
    isUserSaving: state.login.isUserSaving,
    isInvinting: state.profile.profilePage.isInvinting,
    sendinviteError: state.profile.profilePage.sendinviteError,
    sendinviteSuccess: state.profile.profilePage.sendinviteSuccess,
    isCreateGroupRequesting: state.login.isCreateGroupRequesting,
    createGroupSuccessMessage: state.login.createGroupSuccessMessage,
    createGroupError: state.login.createGroupError,
  };
};
const dispatchProps = {
  pageInit: userpageInit,
  setOrg: loginActions.setOrg,
  userSaveRequest: loginActions.userSaveRequesting,
  orgSaveRequest: loginActions.orgSaveRequest,
  sendinviteRequesting: sendinviteRequesting,
  createUserGroup: loginActions.createUserGroup,
};

export default connect(mapStateToProps, dispatchProps)(Profile);
