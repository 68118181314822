import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dest } from "./../../dest/actions";
import { Emissor } from "./../../emissor/reducer";
import { Serie } from "./../../serie/actions";
import { Tipoop } from "./../../tipoop/actions";
import { Sale, SaleItem } from "./../types";

type QuicksaleInitialState = {
  emissor: Emissor;
  tipoop: Tipoop;
  serie: Serie;
  dest: Dest;
  geraEstoque: boolean;
  geraCobranca: boolean;
  items: SaleItem[];
  isSaveRequesting: boolean;
  quickSalesDone: Sale[];
  saveError: string;
  saveSuccess: string;
  isUpdateRequesting: boolean;
  updateError: string;
  isListRequesting: boolean;
  listError: string;
};

const initialState: QuicksaleInitialState = {
  emissor: null,
  tipoop: null,
  serie: null,
  dest: null,
  geraEstoque: true,
  geraCobranca: false,
  items: [],
  isSaveRequesting: false,
  quickSalesDone: [],
  saveError: "",
  saveSuccess: "",
  isUpdateRequesting: false,
  updateError: "",
  isListRequesting: false,
  listError: "",
};

const reducerSlice = createSlice({
  name: "quicksale",
  initialState,
  reducers: {
    init(state, { payload }: PayloadAction<Emissor>) {
      state.emissor = payload || null;
      state.tipoop = null;
      state.serie = null;
      state.dest = null;
      state.geraEstoque = true;
      state.geraCobranca = false;
      state.items = [];
      state.isSaveRequesting = false;
      state.saveError = "";
      state.saveSuccess = "";
    },
    saveRequest(state, _: PayloadAction<Sale>) {
      state.isSaveRequesting = true;
      state.saveError = "";
      state.saveSuccess = "";
    },
    saveSuccess(state, { payload }: PayloadAction<Sale>) {
      state.quickSalesDone.push(payload);
      state.isSaveRequesting = false;
      state.items = [];
      state.saveError = "";
      state.saveSuccess = `Venda rápida criada com numero ${payload.num}`;
    },
    saveError(state, { payload }: PayloadAction<string>) {
      state.isSaveRequesting = false;
      state.saveError = payload;
    },
    set(state, { payload }: PayloadAction<Partial<Sale>>) {
      state.emissor = payload.emissor || state.emissor;
      state.tipoop = payload.tipoop || state.tipoop;
      state.serie = payload.serie || state.serie;
      state.dest = payload.dest || state.dest;
      state.items = payload.items || state.items;
    },
    updateRequest(state, _: PayloadAction<string>) {
      state.isUpdateRequesting = true;
      state.updateError = "";
    },
    updateSuccess(state, { payload }: PayloadAction<Sale>) {
      state.isUpdateRequesting = false;
      const saleIndex = state.quickSalesDone.findIndex(
        (sale) => sale.invoiceId === payload.invoiceId
      );
      if (saleIndex !== -1) {
        const newQuickSalesDone = [...state.quickSalesDone];
        newQuickSalesDone[saleIndex] = payload;
        state.quickSalesDone = newQuickSalesDone;
      }
    },
    updateError(state, { payload }: PayloadAction<string>) {
      state.isUpdateRequesting = false;
      state.updateError = payload;
    },
    listRequest(state) {
      state.isListRequesting = true;
      state.listError = "";
      state.quickSalesDone = [];
    },
    listSuccess(state, { payload }: PayloadAction<Sale[]>) {
      state.isListRequesting = false;
      state.quickSalesDone = payload;
    },
    listError(state, { payload }: PayloadAction<string>) {
      state.isListRequesting = false;
      state.listError = payload;
    },
  },
});

export const quicksalesActions = reducerSlice.actions;

export default reducerSlice;
/*

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case QUICKSALE_INIT:
      return {
        ...state,
        emissor: action.payload.emissor || null,
        tipoop: null,
        serie: null,
        dest: null,
        items: [],
        isSaveRequesting: false,
        saveError: "",
        saveSuccess: "",
      };
    case QUICKSALE_SAVE_REQUESTING:
      return {
        ...state,
        isSaveRequesting: true,
        saveError: "",
        saveSuccess: "",
      };
    case QUICKSALE_SAVE_SUCCESS:
      const quickSalesDone = state.quickSalesDone || [];
      quickSalesDone.push(action.payload);
      return {
        ...state,
        isSaveRequesting: false,
        quickSalesDone,
        items: [],
        saveError: "",
        saveSuccess: `Venda rápida criada com numero ${action.payload.num}`,
      };
    case QUICKSALE_SAVE_ERROR:
      return {
        ...state,
        isSaveRequesting: false,
        saveSuccess: "",
        saveError: action.error,
      };
    case QUICKSALE_SET:
      return {
        ...state,
        ...action.payload,
      };
    case QUICKSALE_UPDATE_REQUESTING:
      return {
        ...state,
        isUpdateRequesting: true,
      };
    case QUICKSALE_UPDATE_SUCCESS:
      const qsDone = state.quickSalesDone || [];
      const items = [...qsDone];
      const itemIndex = items.findIndex(
        (qs) => qs.movId === action.payload.movId
      );
      if (itemIndex >= 0) {
        items[itemIndex] = action.payload;
      }
      return {
        ...state,
        isUpdateRequesting: false,
        quickSalesDone: items,
      };
    case QUICKSALE_UPDATE_ERROR:
      return {
        ...state,
        isUpdateRequesting: false,
        updateError: action.error,
      };
    case QUICKSALE_LIST_REQUESTING:
      return {
        ...state,
        isListRequesting: true,
        listError: "",
      };
    case QUICKSALE_LIST_SUCCESS:
      return {
        ...state,
        isListRequesting: false,
        listError: "",
        quickSalesDone: action.payload,
      };
    case QUICKSALE_LIST_ERROR:
      return {
        ...state,
        isListRequesting: false,
        listError: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
*/
