import moment from "moment";
import { InvoiceParcela } from "../../invoice/actions";
import { Sale } from "../actions";
// actions do form do modulo sales
export const SALES_FORM_INIT = "SALES_FORM_INIT";
export const SALES_FORM_SUCCESS = "SALES_FORM_SUCCESS";
export const SALES_FORM_ERROR = "SALES_FORM_ERROR";
export const SALES_SAVE_REQUESTING = "SALES_SAVE_REQUESTING";
export const SALES_SAVE_SUCCESS = "SALES_SAVE_SUCCESS";
export const SALES_SAVE_ERROR = "SALES_SAVE_ERROR";

export function formInit(cadId: string, duplicateFrom = null) {
  return {
    type: SALES_FORM_INIT,
    payload: cadId,
    duplicateFrom,
  };
}

export function formInitSuccess(item: Sale) {
  return {
    type: SALES_FORM_SUCCESS,
    payload: item,
  };
}

export function formInitError(error: any) {
  return {
    type: SALES_FORM_ERROR,
    error,
  };
}

export function formSave(item: Sale) {
  /// Create a number if doenst exists
  item.parcelas = geraParcelas(item);
  console.log("parcelas", item.parcelas);
  item.num = `${item.num}`;
  if (!item.num || (item.num && item.num.trim().length < 1)) {
    console.log("item num type", item.num, typeof item.num);
    item.serialCreate = {
      num: !!item.serie ? `ser_${item.serie.cod}` : "sale",
    };
    console.log("item num type", item.serialCreate);
  }

  return {
    type: SALES_SAVE_REQUESTING,
    payload: item,
  };
}

export function formSuccess(item: Sale) {
  return {
    type: SALES_SAVE_SUCCESS,
    payload: item,
  };
}

export function formError(error: any) {
  return {
    type: SALES_SAVE_ERROR,
    error,
  };
}

export const geraParcelas = (sale: Sale): InvoiceParcela[] => {
  if (!sale.items || sale.items.length < 1) {
    return [];
  }
  const nparcelas = sale.nparcelas || 1;
  const totalItems = sale.items.reduce((a, p) => a + p.total, 0);
  const totIcmsSt = sale.items.reduce((a, p) => a + (p.vICMSST || 0), 0);
  const vParcela = (totalItems + totIcmsSt) / nparcelas;
  const parcelas: InvoiceParcela[] = [];
  for (let i = 0; i < sale.nparcelas * 1; i++) {
    parcelas.push({
      nParcela: i + 1,
      vencimento: moment()
        .add(i + 1, "month")
        .toDate(),
      valor: vParcela,
    });
  }
  if (sale.nparcelas === 0) {
    parcelas.push({
      nParcela: 1,
      vencimento: new Date(),
      valor: totalItems + totIcmsSt,
    });
  }
  return parcelas;
};
