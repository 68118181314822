import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const AdminOnly: React.FC<RouteComponentProps> = () => (
  <Container className="mt-4">
    <Row>
      <Col>
        <h1>
          <Icon icon={faLock} /> Voce não tem permissão para acessar esta
          página!
        </h1>
      </Col>
    </Row>
  </Container>
);

export default AdminOnly;
