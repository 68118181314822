import React from "react";
import { Pagination } from "react-bootstrap";

type PaginationWidgetProps = {
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
};

const PaginationWidget: React.FC<PaginationWidgetProps> = (props) => {
  if (props.totalPages > 25) {
    const arrPagination = new Array(25).fill(0, 0, 25);

    if (props.currentPage < 12 || props.currentPage > props.totalPages - 12) {
      for (let i = 0; i < 13; i++) {
        arrPagination[i] = i + 1;
      }
      arrPagination[13] = "...";
      for (let i = 14; i < 25; i++) {
        arrPagination[i] = props.totalPages - 25 + i - 1;
      }
    } else {
      for (let i = 0; i < 8; i++) {
        arrPagination[i] = i + 1;
      }
      arrPagination[8] = "...";
      let startSm = -3;
      for (let i = 9; i < 16; i++) {
        arrPagination[i] = props.currentPage + startSm;
        startSm++;
      }
      arrPagination[16] = "...";
      for (let i = 17; i < 25; i++) {
        arrPagination[i] = props.totalPages - 25 + i - 1;
      }
    }
    return (
      <Pagination>
        {arrPagination.map((page, i) => (
          <Pagination.Item
            key={`${i}`}
            onClick={() => {
              window.scrollTo(0, 0);
              props.onChangePage(page);
            }}
            disabled={page === "..." || page === props.currentPage}
            active={page === props.currentPage}
          >
            {page}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  }
  return (
    <Pagination>
      {new Array(props.totalPages).fill(0, 0, props.totalPages).map((_, i) => (
        <Pagination.Item
          key={i + 1}
          onClick={() => {
            window.scrollTo(0, 0);
            props.onChangePage(i + 1);
          }}
          disabled={i + 1 === props.currentPage}
          active={i + 1 === props.currentPage}
        >
          {i + 1}
        </Pagination.Item>
      ))}
    </Pagination>
  );
};

export default PaginationWidget;
