export const CLIENT_PAYMENT_REQUESTING = "CLIENT_PAYMENT_REQUESTING";
export const CLIENT_PAYMENT_SUCCESS = "CLIENT_PAYMENT_SUCCESS";
export const CLIENT_PAYMENT_ERROR = "CLIENT_PAYMENT_ERROR";

export const clientPaymentRequest = (payload) => ({
  type: CLIENT_PAYMENT_REQUESTING,
  payload,
});

export const clientPaymentSuccess = (payload) => ({
  type: CLIENT_PAYMENT_SUCCESS,
  payload,
});

export const clientPaymentError = (error) => ({
  type: CLIENT_PAYMENT_ERROR,
  error,
});
