// component do form do modulo sales
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Modal,
  ButtonGroup,
} from "react-bootstrap";
import LoadingContainer from "../../../components/LoadingContainer";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import DateInput from "../../../components/FormikComponents/DateInput";
import DateTimeInput from "../../../components/FormikComponents/DateTimeInput";
import TextAreaInput from "../../../components/FormikComponents/TextAreaInput";
import SerieSelector from "../../serie/selector";
import LoaderButton from "../../../components/LoaderButton";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import DestSelector from "../../dest/selector";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import TipoOpSelector from "../../tipoop/selector";
import EmissorSelector from "../../emissor/selector";
import SelectInput from "../../../components/FormikComponents/SelectInput";
import TranspSelector from "../../transp/selector";
import NumberInput from "../../../components/FormikComponents/NumberInput";
import TextInput from "../../../components/FormikComponents/TextInput";
import ItemForm from "./ItemForm";
import ImpostoForm from "./ImpostoForm";
import EnviarNfeButton from "../EnviarNfeButton";
import DownloadDanfeButton from "../DownloadDanfeButton";
import { Sale, SaleItem } from "../types";
import { salesFormActions } from "./reducer";
import { LinkButton } from "../../../components/LinkButton";
import geraParcelas from "../../../misc/geraParcelas";
import CheckInput from "../../../components/FormikComponents/CheckInput";
import { saleItemFill } from "./saleItemFill";

type SalesFormProps = {
  item: Sale;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  saleRequest: (_: { invoiceId: string; duplicateId?: string }) => void;
  saveRequest: (sale: Sale) => void;
  id: string;
  duplicate?: string;
};

type SalesFormState = {
  itemEditIndex: number;
  itemEditOpen: boolean;
};

class SalesForm extends Component<SalesFormProps, SalesFormState> {
  state = {
    itemEditIndex: -1,
    itemEditOpen: false,
  };

  componentDidMount() {
    const { id, duplicate } = this.props;
    this.props.saleRequest({ invoiceId: id, duplicateId: duplicate });
  }

  handleSubmit = (item: Sale) => {
    const itemToSave = { ...item };
    if (`${itemToSave.num}`.trim().length < 1) {
      itemToSave.serialCreate = {
        num: itemToSave.serie ? itemToSave.serie.cod : "SALE",
      };
    }

    if (item.tpag !== "90" && !!item.items && item.items.length > 0) {
      itemToSave.parcelas = geraParcelas(
        itemToSave.items.reduce((a, p) => a + p.total, 0),
        itemToSave.nparcelas,
        itemToSave.emission
      );
      console.log("salvando parcelas", itemToSave.parcelas);
    }

    this.props.saveRequest(itemToSave);
  };

  handleChangeItem = (
    formik: FormikProps<Sale>,
    objItem: Partial<SaleItem>
  ) => {
    const items = formik.values.items;

    const item = saleItemFill(
      { ...items[this.state.itemEditIndex], ...objItem },
      formik.values
    );
    item.total = 0;
    console.log("item filled", item);

    const bcIcms = (item.total * (100 - item.pRedBCICMS)) / 100;
    const vIcms = (bcIcms * item.pIcms) / 100;
    const vCredSN = (item.total * item.pCredSN) / 100;
    const vFCP = (item.bcFCP * item.pFCP) / 100;
    const vICMSST = (item.pICMSST * item.bcICMSST) / 100;
    const vICMSSTFCP = (item.pICMSSTFCP * item.bcICMSSTFCP) / 100;
    const bcIPI = item.total;
    const IPI = (item.total * item.pIPI) / 100;
    const bcPIS = item.total;
    const PIS = (bcPIS * item.pPIS) / 100;
    const bcCOFINS = item.total;
    const COFINS = (bcCOFINS * item.pCOFINS) / 100;

    items[this.state.itemEditIndex] = Object.assign(
      item,
      {
        vCredSN,
        bcIcms,
        vIcms,
        vFCP,
        vICMSST,
        vICMSSTFCP,
        bcIPI,
        IPI,
        bcPIS,
        PIS,
        bcCOFINS,
        COFINS,
      },
      objItem
    );
    formik.setFieldValue("items", items);
  };

  handleDeleteItem = (formik: FormikProps<Sale>, itemIndex: number) => {
    const items = formik.values.items;
    items.splice(itemIndex, 1);
    formik.setFieldValue("items", items);
  };

  handleEditItem = (itemIndex: number) => {
    this.setState({
      itemEditOpen: true,
      itemEditIndex: itemIndex,
    });
  };

  handleAddItem = (formik: FormikProps<Sale>) => {
    const items = [...(formik.values.items || [])];
    items.push({
      nItem: items.length + 1,
      prod: null,
      qtd: 1,
      valor: 0,
      total: 0,
    });
    formik.setFieldValue("items", items);
    this.setState({
      itemEditIndex: items.length - 1,
      itemEditOpen: true,
    });
  };

  renderTotals = (formik: FormikProps<Sale>) => {
    if (!formik.values.items || formik.values.items.length < 1) {
      return null;
    }
    const totalItems = formik.values.items.reduce((a, p) => a + p.total, 0);
    const totBcIcms = formik.values.items.reduce(
      (a, p) => a + (p.bcIcms || 0),
      0
    );
    const totIcms = formik.values.items.reduce((a, p) => a + (p.vIcms || 0), 0);
    const totIcmsSt = formik.values.items.reduce(
      (a, p) => a + (p.vICMSST || 0),
      0
    );
    return (
      <Row className="mt-3 mb-3">
        <Col xs={12} md={3}>
          <div className="d-block border rounded mr-2 pt-1 pb-2">
            <span className="d-block lead pl-2">Total dos itens</span>
            <span className="d-block mt-3 pl-3 lead font-weight-bold">
              R$ {formatNumber(totalItems, 2)}
            </span>
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div className="d-block border rounded mr-2 pt-1 pb-2">
            <span className="d-block lead pl-2">Total BC-ICMS</span>
            <span className="d-block mt-3 pl-3 lead font-weight-bold">
              R$ {formatNumber(totBcIcms, 2)}
            </span>
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div className="d-block border rounded mr-2 pt-1 pb-2">
            <span className="d-block lead pl-2">Total ICMS</span>
            <span className="d-block mt-3 pl-3 lead font-weight-bold">
              R$ {formatNumber(totIcms, 2)}
            </span>
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div className="d-block border rounded mr-2 pt-1 pb-2">
            <span className="d-block lead pl-2">Total ICMS-ST</span>
            <span className="d-block mt-3 pl-3 lead font-weight-bold">
              R$ {formatNumber(totIcmsSt, 2)}
            </span>
          </div>
        </Col>
      </Row>
    );
  };

  renderParcelas = (formik: FormikProps<Sale>) => {
    if (
      formik.values.tpag === "90" ||
      !formik.values.items ||
      formik.values.items.length < 1
    ) {
      return null;
    }
    const parcelas = geraParcelas(
      formik.values.items.reduce((a, p) => a + p.total, 0),
      formik.values.nparcelas,
      formik.values.emission
    );
    const headers = [
      {
        label: "#",
        dataIndex: "nParcela",
      },
      {
        label: "Vencimento",
        render: (item) => moment(item.venc).format("DD/MM/YYYY"),
      },
      {
        label: "Valor",
        render: (item) => formatNumber(item.valor, 2),
      },
    ];

    return (
      <>
        <Row>
          <Col>
            <h2>Parcelas</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableOrCard headers={headers} items={parcelas} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const isSaving = this.props.isSaving;

    return (
      <Container className="mt-3" fluid>
        <Row>
          <Col>
            <h1>Venda</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <LinkButton variant="primary" to="/sales" className="mb-3 mt-3">
              Todas as vendas
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              num: Yup.string(),
              emission: Yup.date(),
              dtExp: Yup.date(),
              tipoop: Yup.object(),
              serie: Yup.object(),
              emissor: Yup.object(),
              dest: Yup.object().nullable(),
              modFrete: Yup.string(),
              transp: Yup.object().nullable(),
              vol: Yup.number(),
              especie: Yup.string(),
              tpag: Yup.string(),
              infAdFisco: Yup.string(),
              infCpl: Yup.string(),
              items: Yup.array().min(1),
            })}
          >
            {(props) => {
              const { isValid, handleSubmit } = props;

              const headerItems = [
                {
                  label: "#",
                  dataIndex: "nItem",
                },
                {
                  label: "Produto",
                  render: (item) =>
                    item.prod ? `${item.prod.cod} - ${item.prod.name}` : "",
                },
                {
                  label: "Quantidade",
                  dataIndex: "qtd",
                },
                {
                  label: "Valor Un.",
                  render: (item) => formatNumber(item.valor, 2),
                },
                {
                  label: "Total",
                  render: (item) => formatNumber(item.total, 2),
                },
                {
                  label: "Ações",
                  render: (_, i) => (
                    <>
                      <ButtonGroup>
                        <Button
                          variant="info"
                          onClick={() => this.handleEditItem(i)}
                        >
                          <i className="fas fa-edit" />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => this.handleDeleteItem(props, i)}
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </ButtonGroup>
                    </>
                  ),
                },
              ];

              // props.setFieldValue('parcelas', parcelas)
              return (
                <Form onSubmit={handleSubmit}>
                  <Modal
                    show={this.state.itemEditOpen}
                    size="xl"
                    onHide={() => this.setState({ itemEditOpen: false })}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Item da Venda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* Form do item */}
                      {props.values.items[this.state.itemEditIndex] && (
                        <>
                          <ItemForm
                            item={props.values.items[this.state.itemEditIndex]}
                            handleChange={(item) =>
                              this.handleChangeItem(props, item)
                            }
                          />
                          <ImpostoForm
                            sale={props.values}
                            item={props.values.items[this.state.itemEditIndex]}
                            onChange={(item) =>
                              this.handleChangeItem(props, item)
                            }
                          />
                        </>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={() => this.setState({ itemEditOpen: false })}
                      >
                        Concluir
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Row>
                    <MaskInput
                      name="num"
                      label="Numero"
                      mask="99999999999"
                      formik={props}
                      size={1}
                    />
                    <DateInput
                      name="emission"
                      label="Emissão"
                      formik={props}
                      size={2}
                    />
                    <DateTimeInput
                      name="dtExp"
                      label="Data e hora de saída"
                      formik={props}
                      size={2}
                    />
                    <Form.Group as={Col} controlId="tipoop" xs={12} md={4}>
                      <Form.Label>Tipo de Operação</Form.Label>
                      <TipoOpSelector
                        onChange={(tipoop) =>
                          props.setFieldValue("tipoop", tipoop)
                        }
                        value={props.values.tipoop}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="serie" xs={12} md={3}>
                      <Form.Label>Série</Form.Label>
                      <SerieSelector
                        onChange={(serie) =>
                          props.setFieldValue("serie", serie)
                        }
                        value={props.values.serie}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="emissor" xs={12}>
                      <Form.Label>Emissor</Form.Label>
                      <EmissorSelector
                        onChange={(emissor) =>
                          props.setFieldValue("emissor", emissor)
                        }
                        value={props.values.emissor}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="dest" xs={12}>
                      <Form.Label>Cliente</Form.Label>
                      <DestSelector
                        onChange={(dest) => props.setFieldValue("dest", dest)}
                        value={props.values.dest}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <SelectInput
                      name="modFrete"
                      label="Modalidade do Frete"
                      formik={props}
                      size={3}
                    >
                      <option value={0}>Por conta do emissor</option>
                      <option value={1}>Por conta do destinatário</option>
                      <option value={2}>Por conta do terceiros</option>
                      <option value={3}>
                        Por conta do remetente via meios próprios
                      </option>
                      <option value={4}>
                        Por conta do destinatario via meios próprios
                      </option>
                      <option value={9}>Sem frete</option>
                    </SelectInput>
                    <Form.Group as={Col} controlId="transp" xs={12} md={3}>
                      <Form.Label>Transportador</Form.Label>
                      <TranspSelector
                        onChange={(transp) =>
                          props.setFieldValue("transp", transp)
                        }
                        value={props.values.transp}
                      />
                    </Form.Group>
                    <NumberInput
                      name="vol"
                      label="Volumes"
                      formik={props}
                      size={3}
                      decimalPlaces={0}
                    />
                    <TextInput
                      name="especie"
                      label="Espécie"
                      formik={props}
                      size={3}
                    />
                  </Row>
                  <Row>
                    <SelectInput
                      name="tpag"
                      label="Forma Pagamento"
                      formik={props}
                      size={5}
                    >
                      <option value="01">Dinheiro</option>
                      <option value="02">Cheque</option>
                      <option value="03">Cartão de Crédito</option>
                      <option value="04">Cartão de Débito</option>
                      <option value="05">Crédito Loja</option>
                      <option value="10">Vale alimentação</option>
                      <option value="11">Vale refeição</option>
                      <option value="12">Vale presente</option>
                      <option value="13">Vale combustivel</option>
                      <option value="14">Duplicata mercantil</option>
                      <option value="15">Boleto Bancário</option>
                      <option value="90">Sem pagamento</option>
                      <option value="99">Outros</option>
                    </SelectInput>
                    <CheckInput
                      name="geraCobranca"
                      label="Gerar Cobrança?"
                      formik={props}
                      size={2}
                    />
                    <CheckInput
                      name="geraEstoque"
                      label="Gerar Estoque?"
                      formik={props}
                      size={2}
                    />
                    {["01", "04", "90"].indexOf(props.values.tpag) === -1 && (
                      <NumberInput
                        name="nparcelas"
                        label="Numero de Parcelas"
                        formik={props}
                        size={5}
                        decimalPlaces={0}
                      />
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <h4>Produtos</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3 mb-3">
                      <Button
                        variant="primary"
                        onClick={() => this.handleAddItem(props)}
                      >
                        Incluir Item
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TableOrCard
                        headers={headerItems}
                        items={props.values.items}
                      />
                    </Col>
                  </Row>
                  {/* Render totals */}
                  {this.renderTotals(props)}
                  <Row>
                    <TextAreaInput
                      name="infAdFisco"
                      label="Informações Adicionais ao Fisco"
                      formik={props}
                      size={6}
                    />
                    <TextAreaInput
                      name="infCpl"
                      label="Informações Complementares"
                      formik={props}
                      size={6}
                    />
                  </Row>
                  {/* Render the parcels for */}
                  {this.renderParcelas(props)}
                  <Row>
                    <Col className="mt-3 mb-4">
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                      >
                        Salvar
                      </LoaderButton>
                      {props.values.invoiceId && (
                        <>
                          {(!props.values.NFe ||
                            (props.values.NFe &&
                              !props.values.NFe.autorizado)) && (
                            <EnviarNfeButton
                              sale={props.values}
                              variant="secondary"
                            >
                              Autorizar NFe
                            </EnviarNfeButton>
                          )}
                          {props.values.NFe &&
                            props.values.NFe.autorizado &&
                            !props.values.NFe.cancelado && (
                              <DownloadDanfeButton
                                sale={props.values}
                                variant="light"
                              >
                                Imprimir PDF
                              </DownloadDanfeButton>
                            )}
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {props.values.NFe &&
                        props.values.NFe.erros &&
                        !props.values.NFe.autorizado && (
                          <ShowError error={props.values.NFe.erros} />
                        )}
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </LoadingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    item: state.sales.form.item,
    successMsg: state.sales.form.successMsg,
    error: state.sales.form.error,
    isRequesting: state.sales.form.isRequesting,
    isSaving: state.sales.form.isSaving,
  };
};
const mapDispatchToProps = {
  saleRequest: salesFormActions.saleRequest,
  saveRequest: salesFormActions.saveRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesForm);
