import { Tipoop } from "../actions";

export const TIPOOP_FORM_INIT = "TIPOOP_FORM_INIT";
export const TIPOOP_FORM_SUCCESS = "TIPOOP_FORM_SUCCESS";
export const TIPOOP_FORM_ERROR = "TIPOOP_FORM_ERROR";
export const TIPOOP_SAVE_REQUESTING = "TIPOOP_SAVE_REQUESTING";
export const TIPOOP_SAVE_SUCCESS = "TIPOOP_SAVE_SUCCESS";
export const TIPOOP_SAVE_ERROR = "TIPOOP_SAVE_ERROR";

export function formInit(cadId: string) {
  return {
    type: TIPOOP_FORM_INIT,
    payload: cadId,
  };
}

export function formInitSuccess(item: Tipoop) {
  return {
    type: TIPOOP_FORM_SUCCESS,
    payload: item,
  };
}

export function formInitError(error: any) {
  return {
    type: TIPOOP_FORM_ERROR,
    error,
  };
}

export function formSave(item: Tipoop) {
  return {
    type: TIPOOP_SAVE_REQUESTING,
    payload: item,
  };
}

export function formSuccess(item: Tipoop) {
  return {
    type: TIPOOP_SAVE_SUCCESS,
    payload: item,
  };
}

export function formError(error: any) {
  return {
    type: TIPOOP_SAVE_ERROR,
    error,
  };
}
