// component do modulo invoice
import React, { Component } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteButton from "../../components/DeleteButton";
import { LinkButton } from "../../components/LinkButton";
import LoadingContainer from "../../components/LoadingContainer";
import TableOrCard from "../../components/TableOrCard";
import { formatNumber } from "../../misc/formatters";
import { invoiceDeleteRequest, InvoiceParcela, pageInit } from "./actions";

type InvoicesProps = {
  items: InvoiceParcela[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isDeleting: boolean;
  pageInit: () => void;
  deleteRequest: (movId: string) => void;
};

class Invoices extends Component<InvoicesProps, {}> {
  state = {};

  headers = [
    {
      label: "Numero",
      dataIndex: "num",
    },
    {
      label: "Emissor",
      render: (item: InvoiceParcela) => item.emissor && item.emissor.name,
    },
    {
      label: "Cliente",
      render: (item: InvoiceParcela) => item.dest && item.dest.name,
    },
    {
      label: "Valor ($)",
      render: (item: InvoiceParcela) => formatNumber(item.valor, 2),
    },
    {
      label: "Ações",
      render: (item: InvoiceParcela) => (
        <ButtonGroup>
          <LinkButton variant="info" to={`/invoice/${item.invoiceId}`}>
            <i className="fas fa-edit" />
          </LinkButton>
          <DeleteButton
            isRequesting={this.props.isDeleting}
            onDelete={() => this.handleDelete(item)}
          />
        </ButtonGroup>
      ),
    },
  ];

  componentDidMount() {
    this.props.pageInit();
  }

  handleDelete = (item: InvoiceParcela) => {
    this.props.deleteRequest(item.invoiceId);
  };

  render() {
    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Cobranças</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <LinkButton to="/invoice/new" variant="primary">
              Incluir
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={this.props.items} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.invoice.list.items,
    Count: state.invoice.list.Count,
    lastKey: state.invoice.list.lastKey,
    isRequesting: state.invoice.list.isRequesting,
    isDeleting: state.invoice.list.isDeleting,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    pageInit: () => dispatch(pageInit()),
    deleteRequest: (movId: string) => dispatch(invoiceDeleteRequest(movId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
