import { AWSRes } from "./../../redux/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import formReducer from "./salesForm/reducer";
import quicksaleReducer from "./QuickSale/reducer";
import { combineReducers } from "redux";
import { Sale } from "./types";

type SalesReducerInitialState = {
  items: Sale[];
  Count: number;
  lastKey: Record<string, string>;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isDeleting: boolean;
  isGeranfeRequesting: boolean;
  geraNFeSuccessMsg: string;
  geraNFeError: string;
  isEnviarnfeRequesting: boolean;
  enviarnfeError: string;
  isDownloadingDanfe: boolean;
  downloadDanfeError: string;
  isNfeCanceling: boolean;
  nfeCancelingError: string;
  isSendingNfe: boolean;
  sendNfeError: string;
  sendNfeSuccess: string;
};

const initialState: SalesReducerInitialState = {
  items: [],
  Count: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
  isGeranfeRequesting: false,
  geraNFeSuccessMsg: "",
  geraNFeError: "",
  isEnviarnfeRequesting: false,
  enviarnfeError: "",
  isDownloadingDanfe: false,
  downloadDanfeError: "",
  isNfeCanceling: false,
  nfeCancelingError: "",
  isSendingNfe: false,
  sendNfeError: "",
  sendNfeSuccess: "",
};

export type PayloadNfeAction = PayloadAction<{
  sale: Sale;
  onSuccessDispatch?: () => void;
}>;

export type PayloadRequest = {
  tags: string[];
  modo: string;
  inicio?: string;
  fim?: string;
};

const reducerSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    salesRequest(state, _: PayloadAction<PayloadRequest>) {
      state.Count = 0;
      state.items = [];
      state.lastKey = null;
      state.error = "";
      state.successMsg = "";
      state.geraNFeError = "";
      state.sendNfeError = "";
      state.nfeCancelingError = "";
      state.enviarnfeError = "";
      state.downloadDanfeError = "";
      state.isRequesting = true;
      state.isEnviarnfeRequesting = false;
    },
    salesSuccess(state, { payload }: PayloadAction<AWSRes<Sale>>) {
      state.items = payload.items;
      state.lastKey = payload.lastKey;
      state.Count = payload.Count;
      state.isRequesting = false;
    },
    salesError(state, { payload }: PayloadAction<string>) {
      state.isRequesting = false;
      state.error = payload;
    },
    deleteRequest(state, _: PayloadAction<Sale>) {
      state.isDeleting = true;
      state.successMsg = "";
      state.error = "";
    },
    deleteSuccess(state) {
      state.isDeleting = false;
      state.successMsg = "Venda removida com sucesso!";
    },
    deleteError(state, { payload }: PayloadAction<string>) {
      state.isDeleting = false;
      state.error = payload;
    },
    geranfeRequest(state, _: PayloadNfeAction) {
      state.isGeranfeRequesting = true;
      state.geraNFeError = "";
      state.geraNFeSuccessMsg = "";
    },
    geranfeSuccess(state) {
      state.isGeranfeRequesting = false;
      state.geraNFeSuccessMsg = "NFe gerada com sucesso!";
    },
    geranfeError(state, { payload }: PayloadAction<string>) {
      state.isGeranfeRequesting = false;
      state.geraNFeError = payload;
    },
    enviarnfeRequest(state, _: PayloadNfeAction) {
      state.isEnviarnfeRequesting = true;
      state.enviarnfeError = "";
    },
    enviarnfeSuccess(state) {
      state.isEnviarnfeRequesting = false;
    },
    enviarnfeError(state, { payload }: PayloadAction<string>) {
      state.isEnviarnfeRequesting = false;
      state.enviarnfeError = payload;
    },
    downloadDanfeRequest(state, _: PayloadAction<Sale>) {
      state.isDownloadingDanfe = true;
      state.downloadDanfeError = "";
    },
    downloadDanfeSuccess(state) {
      state.isDownloadingDanfe = false;
    },
    downloadDanfeError(state, { payload }: PayloadAction<string>) {
      state.isDownloadingDanfe = false;
      state.downloadDanfeError = payload;
    },
    cancelanfeRequest(state, _: PayloadNfeAction) {
      state.isNfeCanceling = true;
      state.nfeCancelingError = "";
    },
    cancelanfeSuccess(state) {
      state.isNfeCanceling = false;
    },
    cancelanfeError(state, { payload }: PayloadAction<string>) {
      state.isNfeCanceling = false;
      state.nfeCancelingError = payload;
    },
    sendnfeRequest(state, _: PayloadAction<Sale & { email: string }>) {
      state.isSendingNfe = true;
      state.sendNfeError = "";
      state.sendNfeSuccess = "";
    },
    sendnfeSuccess(state, { payload }: PayloadAction<string>) {
      state.isSendingNfe = false;
      state.sendNfeSuccess = payload;
    },
    sendnfeError(state, { payload }: PayloadAction<string>) {
      state.isSendingNfe = false;
      state.sendNfeError = payload;
    },
  },
});

export const salesActions = reducerSlice.actions;

export default combineReducers({
  list: reducerSlice.reducer,
  form: formReducer.reducer,
  quicksale: quicksaleReducer.reducer,
});
