import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../misc/executeApiRequest";
import { AWSRes } from "./../../redux/types";
import { Prod } from "./actions";
import inventorySaga from "./inventory/saga";
import formSaga from "./prodForm/saga";
import {
  BuscarEstoquePayload,
  EstoqueRecord,
  PayloadCreateEstoqueRequest,
  prodActions,
} from "./reducer";
import selectorSaga from "./selector/saga";

function prodCall(lastKey = null) {
  return executeApiRequest<AWSRes<Prod>>(
    "/prod",
    "GET",
    {},
    {
      queryStringParameters: {
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* prodWorker() {
  try {
    const res: AWSRes<Prod> = yield call(prodCall);
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: AWSRes<Prod> = yield call(prodCall, res.lastKey);
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(prodActions.initSuccess(res));
  } catch (error) {
    yield put(prodActions.initError(error));
  }
}

function* prodDeleteWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(executeApiRequest, `/prod/${payload}`, "DELETE", {}, {});
    yield put(prodActions.deleteSuccess());
    yield put(prodActions.init());
  } catch (error) {
    yield put(prodActions.deleteError(error));
  }
}

function* prodImportarWorker({ payload }: PayloadAction<Prod[]>) {
  try {
    const res = yield call(executeApiRequest, "/bulkcad", "POST", payload);
    console.log("res", res);
    yield put(prodActions.importarSuccess(res));
  } catch (error) {
    yield put(prodActions.importarError(error));
  }
}

function* prodCreateEstoqueWorker({
  payload,
}: PayloadAction<PayloadCreateEstoqueRequest>) {
  try {
    yield call(executeApiRequest, `/estoque`, "POST", payload);
    yield put(prodActions.criarEstoqueSuccess());
  } catch (error) {
    yield put(prodActions.criarEstoqueError(error));
  }
}

function* prodBuscarEstoqueWorker({
  payload,
}: PayloadAction<BuscarEstoquePayload>) {
  try {
    const res: AWSRes<EstoqueRecord> = yield call(
      executeApiRequest,
      "/estoque",
      "GET",
      {},
      {
        queryStringParameters: {
          data_inicial: payload.data_inicial.toISOString(),
          data_final: payload.data_final.toISOString(),
          prodId: payload.prodId,
        },
      }
    );
    yield put(prodActions.buscarEstoqueSuccess(res));
  } catch (error) {
    yield put(prodActions.buscarEstoqueError(error));
  }
}

export default function* prodSaga() {
  yield all([
    takeLatest("prod/init", prodWorker),
    takeLatest("prod/deleteRequest", prodDeleteWorker),
    takeLatest("prod/importarRequest", prodImportarWorker),
    takeLatest("prod/criarEstoqueRequest", prodCreateEstoqueWorker),
    takeLatest("prod/buscarEstoqueRequest", prodBuscarEstoqueWorker),
    formSaga(),
    selectorSaga(),
    inventorySaga(),
  ]);
}
