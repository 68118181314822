// saga do form do modulo purchases
import { put, all, call, takeLatest, select } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { Emissor } from "../../emissor/reducer";
import { Purchase } from "../types";
import { purchaseFormActions } from "./reducer";
import { PayloadAction } from "@reduxjs/toolkit";

const getDefaultEmit = (state: any) => state.login.defaultEmit;

const newPurchases = (emissor: Emissor | null = null): Purchase => {
  return {
    num: "",
    dest: null,
    emissor,
    tpag: "01",
    obs: "",
    items: [],
    entry: new Date(),
    lsi1: "",
    lsi2: "",
    lsi3: "",
    lsi4: "",
    tag: "CUSTO_FIXO",
    geraEstoque: true,
  };
};

function* purchasesRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "new") {
      const defaultEmit: Emissor = yield select(getDefaultEmit);
      yield put(purchaseFormActions.itemSuccess(newPurchases(defaultEmit)));
    } else {
      const res: Purchase = yield call(
        executeApiRequest,
        `/purchase/${payload}`,
        "GET",
        {},
        {}
      );
      yield put(purchaseFormActions.itemSuccess(res));
    }
  } catch (error) {
    console.log("error on load", error.response, JSON.stringify(error));
    yield put(purchaseFormActions.itemError(error));
  }
}

function* purchasesSaveWorker({ payload }: PayloadAction<Purchase>) {
  try {
    if (payload.invoiceId) {
      console.log("mov date", payload.entry);
      // Update
      const res = yield call(
        executeApiRequest,
        `/purchase/${payload.invoiceId}`,
        "PUT",
        payload
      );
      yield put(purchaseFormActions.saveSuccess(res));
    } else {
      const res = yield call(executeApiRequest, "/purchase", "POST", payload);
      yield put(purchaseFormActions.saveSuccess(res));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(purchaseFormActions.saveError(error.response.data));
  }
}

export default function* purchasesFormSaga() {
  yield all([
    takeLatest("purchaseForm/requestItem", purchasesRequestWorker),
    takeLatest("purchaseForm/saveRequest", purchasesSaveWorker),
  ]);
}
