import { RouteComponentProps } from "@reach/router";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../components/LoadingAnimation";
import ShowError from "../../components/ShowError";
import { RootState } from "../app/mainReducer";
import { subcsriptionActions } from "./reducer";

export interface SubscriptionPaneProps extends RouteComponentProps {}

const SubscriptionPane: React.FC<SubscriptionPaneProps> = (props) => {
  const dispatch = useDispatch();
  const publicKey = useSelector(
    (state: RootState) => state.subscription.publicKey
  );
  const isConfigRequesting = useSelector(
    (state: RootState) => state.subscription.isConfigRequesting
  );
  const configRequestError = useSelector(
    (state: RootState) => state.subscription.configRequestError
  );

  useEffect(() => {
    dispatch(subcsriptionActions.configRequest());
  }, [dispatch]);

  if (!publicKey && isConfigRequesting) {
    return <LoadingAnimation />;
  }
  if (!publicKey) {
    return <ShowError error={configRequestError} />;
  }

  const stripePromise = loadStripe(publicKey);

  return <Elements stripe={stripePromise}>{props.children}</Elements>;
};

export default SubscriptionPane;
