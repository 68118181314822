import _ from "underscore";
import {
  INVOICEPAY_INIT,
  INVOICEPAY_REQUESTING,
  INVOICEPAY_SUCCESS,
  INVOICEPAY_ERROR,
  INVOICEPAY_PAYED_REQUESTING,
  INVOICEPAY_PAYED_SUCCESS,
  INVOICEPAY_PAYED_ERROR,
  INVOICEPAY_SET_INVOICESTOCLOSE,
} from "./actions";

const initialState = {
  items: [],
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
  invoicesFilter: [],
  invoicesItems: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INVOICEPAY_INIT:
      return {
        ...state,
        error: "",
        successMsg: "",
        isRequesting: true,
        invoicesFilter: action.payload,
      };
    case INVOICEPAY_REQUESTING:
      return {
        ...state,
      };
    case INVOICEPAY_SUCCESS:
      const invoicesItems = _.filter(
        action.payload.items,
        (cob) =>
          _.findIndex(
            state.invoicesFilter,
            (fil) =>
              fil.split(":")[0] * 1 === parseInt(cob.num) * 1 ||
              fil.split(":")[0] === `${cob.num}`.trim()
          ) !== -1
      ).map((cobItem) => ({
        ...cobItem,
        valorPago: cobItem.valor,
        checked: true,
      }));
      return {
        ...state,
        isRequesting: false,
        isSaving: false,
        items: action.payload.items,
        Count: action.payload.Count,
        ScannedCount: action.payload.ScannedCount,
        lastKey: action.payload.lastKey,
        invoicesItems,
      };
    case INVOICEPAY_SET_INVOICESTOCLOSE:
      return {
        ...state,
        invoicesItems: action.payload,
      };
    case INVOICEPAY_ERROR:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
        isSaving: false,
      };
    case INVOICEPAY_PAYED_REQUESTING:
      return {
        ...state,
        error: "",
        successMsg: "",
        isSaving: true,
      };
    case INVOICEPAY_PAYED_SUCCESS:
      return {
        ...state,
        isSaving: false,
        successMsg: "Baixado com sucesso!",
      };
    case INVOICEPAY_PAYED_ERROR:
      return {
        ...state,
        isSaving: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
