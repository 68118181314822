// component do form do modulo payment
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap";
import LoadingContainer from "../../../components/LoadingContainer";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import NumberInput from "../../../components/FormikComponents/NumberInput";
import DateInput from "../../../components/FormikComponents/DateInput";
import LoaderButton from "../../../components/LoaderButton";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import EmissorSelector from "../../emissor/selector";
import DestSelector from "../../dest/selector";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import { Link } from "@reach/router";
import { Payment } from "../types";
import PaymentParcela from "../types";
import { paymentFormActions } from "./reducer";

const geraParcelas = (
  valor: number,
  nparcelas: number,
  emission: Date,
  originalPayment: Payment
): PaymentParcela[] => {
  if (!valor) {
    return [];
  }

  const dini = emission || new Date();

  if (nparcelas <= 0 || !nparcelas) {
    // Numero zero de parcelas é o valor a vista
    const retInvoices: PaymentParcela[] = [
      {
        valor,
        vencimento: new Date(),
        dest: null,
        emission,
        emissor: null,
        invoiceId: "",
        lsi1: "",
        lsi2: "",
        lsi3: "",
        lsi4: "",
        num: "",
        numparcela: 1,
        orgId: "",
        status: "OPEN",
        movType: "MOV_PAYMENT",
      },
    ];
    if (
      originalPayment &&
      originalPayment.parcelas &&
      originalPayment.parcelas.length > 0
    ) {
      retInvoices[0].invoiceId = originalPayment.parcelas[0].invoiceId;
      retInvoices[0].lsi1 = originalPayment.parcelas[0].lsi1;
      retInvoices[0].lsi2 = originalPayment.parcelas[0].lsi2;
      retInvoices[0].lsi3 = originalPayment.parcelas[0].lsi3;
      retInvoices[0].lsi4 = originalPayment.parcelas[0].lsi4;
      retInvoices[0].version = originalPayment.parcelas[0].version;
    }
    return retInvoices;
  }
  const parcelas: PaymentParcela[] = [];
  let soma = 0;
  for (let i = 0; i < nparcelas; i++) {
    soma += Math.round((100 * valor) / nparcelas) / 100;
    parcelas.push({
      valor: Math.round((100 * valor) / nparcelas) / 100,
      vencimento: moment(dini)
        .add(i + 1, "month")
        .toDate(),
      dest: null,
      emission,
      emissor: null,
      invoiceId: "",
      lsi1: "",
      lsi2: "",
      lsi3: "",
      lsi4: "",
      num: "",
      numparcela: i + 1,
      orgId: "",
      status: "OPEN",
      movType: "MOV_PAYMENT",
    });
    if (
      originalPayment &&
      originalPayment.parcelas &&
      originalPayment.parcelas[i]
    ) {
      parcelas[i].invoiceId = originalPayment.parcelas[i].invoiceId;
      parcelas[i].lsi1 = originalPayment.parcelas[i].lsi1;
      parcelas[i].lsi2 = originalPayment.parcelas[i].lsi2;
      parcelas[i].lsi3 = originalPayment.parcelas[i].lsi3;
      parcelas[i].lsi4 = originalPayment.parcelas[i].lsi4;
      parcelas[i].version = originalPayment.parcelas[i].version;
    }
  }
  if (Math.abs(Math.round(100 * (valor - soma)) / 100) > 0) {
    parcelas[0].valor += Math.round(100 * (valor - soma)) / 100;
  }

  return parcelas;
};

type PaymentFormProps = {
  item: Payment;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  initForm: (id: string) => void;
  requestSave: (payment: Payment) => void;
  id: string;
};

class PaymentForm extends Component<PaymentFormProps, {}> {
  state = {};

  componentDidMount() {
    this.props.initForm(this.props.id);
  }

  handleSubmit = (item: Payment) => {
    let beganPaying = false;
    if (item.parcelas) {
      beganPaying =
        item.parcelas.findIndex((p) => p.status === "CLOSED") !== -1;
    }
    const parcelas = !beganPaying
      ? geraParcelas(item.valor, item.nparcelas, item.emission, this.props.item)
      : item.parcelas;
    if (this.props.item.parcelas.length > parcelas.length) {
      /// Diminuiu o numero de parcelas
      /// Remover as parcelas a mais
      const start = parcelas.length;
      const end = this.props.item.parcelas.length;
      for (let i = start; i < end; i++) {
        parcelas.push({
          ...this.props.item.parcelas[i],
          deleted: true,
        });
      }
    }
    item.parcelas = parcelas;
    this.props.requestSave(item);
  };

  handleNew = () => {
    this.props.initForm("new");
  };

  render() {
    const isSaving = this.props.isSaving;

    const headerParcelas = [
      {
        label: "#",
        render: (item: PaymentParcela) => `${item.numparcela}`,
      },
      {
        label: "Vencimento",
        render: (item: PaymentParcela) =>
          moment(item.vencimento).format("DD/MM/YYYY"),
      },
      {
        label: "Valor",
        render: (item: PaymentParcela) => formatNumber(item.valor, 2),
      },
      {
        label: "Status",
        render: (item: PaymentParcela) =>
          item.status === "CLOSED" ? "Pago" : "Pendente",
      },
    ];

    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>Pagamento</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" as={Link} to="/payment">
              Todos os Pagamentos
            </Button>
            <Button
              className="ml-2"
              variant="outline-primary"
              onClick={this.handleNew}
            >
              Novo
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              num: Yup.string().required(
                "Preenchimento do Numero é obrigatório"
              ),
              valor: Yup.number().moreThan(
                0,
                "Preenchimento do valor é obrigatório"
              ),
              emission: Yup.date().required(
                "Preenchimento da Data de emissão é obrigatório"
              ),
              emissor: Yup.object().required(
                "Preenchimento do Campo empresa é obrigatório"
              ),
              dest: Yup.object().required(
                "Preenchimento do fornecedor é obrigatório"
              ),
              parcelas: Yup.array().min(
                1,
                "No minimo uma parcela deve existir"
              ),
            })}
          >
            {(props) => {
              const { isValid, handleSubmit } = props;
              const validToItem = [props.values.valor > 0].reduce(
                (a, p) => a && p
              );

              let beganPaying = false;
              if (props.values.parcelas) {
                beganPaying =
                  props.values.parcelas.findIndex(
                    (p) => p.status === "CLOSED"
                  ) !== -1;
              }
              const handleChangeInValor = (obj) => {
                let beganPaying = false;
                if (props.values.parcelas) {
                  beganPaying =
                    props.values.parcelas.findIndex(
                      (p) => p.status === "CLOSED"
                    ) !== -1;
                }
                if (beganPaying) {
                  return;
                }
                const objParcela = {
                  valor: obj.valor || props.values.valor,
                  nparcelas: obj.nparcelas || props.values.nparcelas,
                };

                const parcelas = geraParcelas(
                  objParcela.valor,
                  objParcela.nparcelas,
                  props.values.emission,
                  this.props.item
                );
                props.setFieldValue("parcelas", parcelas);
              };

              // props.setFieldValue('parcelas', parcelas)
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <MaskInput
                      name="num"
                      label="Numero"
                      mask="99999999999"
                      autoFocus
                      formik={props}
                      size={3}
                    />
                    <DateInput
                      name="emission"
                      label="Emissão"
                      formik={props}
                      size={3}
                    />
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="emissor">
                      <Form.Label>Empresa</Form.Label>
                      <EmissorSelector
                        onChange={(emissor) =>
                          props.setFieldValue("emissor", emissor)
                        }
                        value={props.values.emissor}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="dest">
                      <Form.Label>Fornecedor</Form.Label>
                      <DestSelector
                        onBlur={props.handleBlur}
                        className={
                          props.errors.dest
                            ? "form-control text-input error is-invalid"
                            : "form-control text-input"
                        }
                        onChange={(dest) => props.setFieldValue("dest", dest)}
                        value={props.values.dest}
                      />
                    </Form.Group>
                  </Row>
                  {beganPaying && (
                    <Row>
                      <Col>
                        <Alert variant="warn">
                          Não é possível modificar os valores e parcelas após
                          começar a baixa!
                        </Alert>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <NumberInput
                      decimalPlaces={2}
                      formik={props}
                      disabled={beganPaying}
                      name="valor"
                      label="Valor"
                      size={6}
                      changeCallback={handleChangeInValor}
                    />
                    <NumberInput
                      decimalPlaces={0}
                      formik={props}
                      name="nparcelas"
                      disabled={beganPaying}
                      label="Numero de Parcelas"
                      size={6}
                      changeCallback={handleChangeInValor}
                    />
                  </Row>
                  {validToItem && (
                    <>
                      <Row>
                        <Col>
                          <h4>Parcelas</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TableOrCard
                            headers={headerParcelas}
                            items={props.values.parcelas}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col>
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                      >
                        Salvar
                      </LoaderButton>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </LoadingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    item: state.payment.form.item,
    successMsg: state.payment.form.successMsg,
    error: state.payment.form.error,
    isRequesting: state.payment.form.isRequesting,
    isSaving: state.payment.form.isSaving,
  };
};

const mapDispatchToProps = {
  initForm: paymentFormActions.initForm,
  requestSave: paymentFormActions.requestSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
