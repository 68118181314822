import { Sale, SaleItem } from "../types";

export const saleItemFill = (item: SaleItem, sale: Sale) => {
  if (!sale.tipoop || !sale.tipoop.regras || sale.tipoop.regras.length < 1) {
    return;
  }

  for (const regra of sale.tipoop.regras) {
    if (!regra.quando || regra.quando.length < 1) {
      continue;
    }
    let match = true;
    for (const quando of regra.quando) {
      switch (quando.tipoObj) {
        case "TipoNF":
          if (quando.operador === "eq") {
            match = match && quando.modelo === sale.modelo;
          } else {
            match = match && quando.modelo !== sale.modelo;
          }
          break;
        case "UF":
          if (quando.operador === "eq") {
            match = match && sale.dest && quando.uf === sale.dest.estate;
          } else {
            match = match && sale.dest && quando.uf !== sale.dest.estate;
          }
          break;
        case "NCM":
          if (quando.operador === "eq") {
            match = match && item.prod && quando.ncm === item.prod.ncm;
          } else {
            match = match && item.prod && quando.ncm !== item.prod.ncm;
          }
          break;
        case "Prod":
          if (quando.operador === "eq") {
            match =
              match &&
              item.prod &&
              quando.prod &&
              quando.prod.cadId === item.prod.cadId;
          } else {
            match =
              match &&
              item.prod &&
              quando.prod &&
              quando.prod.cadId !== item.prod.cadId;
          }
          break;
        case "Dest":
          if (quando.operador === "eq") {
            match =
              match &&
              sale.dest &&
              quando.dest &&
              quando.dest &&
              quando.dest.cadId === sale.dest.cadId;
          } else {
            match =
              match &&
              sale.dest &&
              quando.dest &&
              quando.dest.cadId !== sale.dest.cadId;
          }
          break;
        case "Emissor":
          if (quando.operador === "eq") {
            match =
              match &&
              sale.emissor &&
              quando.emissor &&
              quando.emissor &&
              quando.emissor.cadId === sale.emissor.cadId;
          } else {
            match =
              match &&
              sale.emissor &&
              quando.emissor &&
              quando.emissor.cadId !== sale.emissor.cadId;
          }
          break;
        default:
          match = false;
      }
    }
    console.log("analising", match, regra);

    if (!match) {
      continue;
    }

    const itemToReturn = { ...item };

    if (regra.cfop) {
      itemToReturn.cfop = regra.cfop;
    }

    if (regra.csosn) {
      itemToReturn.csosn = regra.csosn;
    }

    if (regra.pCredSN) {
      itemToReturn.pCredSN = regra.pCredSN;
    }

    if (regra.csticms) {
      itemToReturn.cstIcms = regra.csticms;
    }

    if (regra.modBcIcms) {
      itemToReturn.modBcIcms = regra.modBcIcms;
    }

    if (regra.pRedIcms) {
      itemToReturn.pRedBCICMS = regra.pRedIcms;
    }

    if (regra.pIcms) {
      itemToReturn.pIcms = regra.pIcms;
    }

    if (regra.pIcmsFcp) {
      itemToReturn.pFCP = regra.pIcmsFcp;
    }

    if (regra.modBcIcmsSt) {
      itemToReturn.modBcIcmsST = regra.modBcIcmsSt;
    }

    if (regra.pMVA) {
      itemToReturn.pMVAST = regra.pMVA;
    }

    if (regra.pRedBcIcmsSt) {
      itemToReturn.pRedBCST = regra.pRedBcIcmsSt;
    }

    if (regra.pIcmsSt) {
      itemToReturn.pICMSST = regra.pIcmsSt;
    }

    if (regra.pIcmsStFcp) {
      itemToReturn.pICMSSTFCP = regra.pIcmsStFcp;
    }

    if (regra.cstipi) {
      itemToReturn.cstipi = regra.cstipi;
    }

    if (regra.pipi) {
      itemToReturn.pIPI = regra.pipi;
    }

    if (regra.cstpis) {
      itemToReturn.cstpis = regra.cstpis;
    }

    if (regra.ppis) {
      itemToReturn.pPIS = regra.ppis;
    }

    if (regra.cstcofins) {
      itemToReturn.cstcofins = regra.cstcofins;
    }

    if (regra.pcofins) {
      itemToReturn.pCOFINS = regra.pcofins;
    }

    if (regra.pIcmsUfDest) {
      itemToReturn.pUFDest = regra.pIcmsUfDest;
    }

    if (regra.pIcmsInter) {
      itemToReturn.pICMSInter = regra.pIcmsInter;
    }

    if (regra.pIcmsFcpUfDest) {
      itemToReturn.pFCPUFDest = regra.pIcmsFcpUfDest;
    }

    return itemToReturn;
  }
  return item;
};
