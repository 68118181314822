// component do form do modulo invoice
import { Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as Yup from "yup";
import DateSelector from "../../../components/DateSelector";
import DateInput from "../../../components/FormikComponents/DateInput";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import NumberInput from "../../../components/FormikComponents/NumberInput";
import { TextField } from "../../../components/FormikComponents/TextField";
import { LinkButton } from "../../../components/LinkButton";
import LoaderButton from "../../../components/LoaderButton";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import TableOrCard from "../../../components/TableOrCard";
import { formatDate, formatNumber } from "../../../misc/formatters";
import { RootState } from "../../app/mainReducer";
import BancoSelector from "../../banco/selector";
import DestSelector from "../../dest/selector";
import EmissorSelector from "../../emissor/selector";
import { Invoice, InvoiceParcela } from "../actions";
import { invoiceFormActions } from "./reducer";

const geraParcelas = (
  valor: number,
  nparcelas: number,
  emission: Date,
  originalInvoice: Invoice
): InvoiceParcela[] => {
  if (!valor) {
    return [];
  }

  const dini = emission || new Date();

  if (nparcelas <= 0 || !nparcelas) {
    // Numero zero de parcelas é o valor a vista
    const retInvoces: InvoiceParcela[] = [
      {
        valor,
        vencimento: new Date(),
        dest: null,
        emission,
        emissor: null,
        invoiceId: "",
        lsi1: "",
        lsi2: "",
        lsi3: "",
        lsi4: "",
        num: "",
        numparcela: 1,
        orgId: "",
        status: "OPEN",
        banco: null,
      },
    ];
    if (
      originalInvoice &&
      originalInvoice.parcelas &&
      originalInvoice.parcelas.length > 0
    ) {
      retInvoces[0].invoiceId = originalInvoice.parcelas[0].invoiceId;
      retInvoces[0].lsi1 = originalInvoice.parcelas[0].lsi1;
      retInvoces[0].lsi2 = originalInvoice.parcelas[0].lsi2;
      retInvoces[0].lsi3 = originalInvoice.parcelas[0].lsi3;
      retInvoces[0].lsi4 = originalInvoice.parcelas[0].lsi4;
      retInvoces[0].version = originalInvoice.parcelas[0].version;
      retInvoces[0].oldVencimento = originalInvoice.parcelas[0].vencimento;
    }
    return retInvoces;
  }
  const parcelas = [];
  let soma = 0;
  for (let i = 0; i < nparcelas; i++) {
    soma += Math.round((100 * valor) / nparcelas) / 100;
    parcelas.push({
      valor: Math.round((100 * valor) / nparcelas) / 100,
      vencimento: moment(dini)
        .add(i + 1, "month")
        .toDate(),
      dest: null,
      emission,
      emissor: null,
      invoiceId: "",
      lsi1: "",
      lsi2: "",
      lsi3: "",
      lsi4: "",
      num: "",
      numparcela: i + 1,
      orgId: "",
      status: "OPEN",
    });
    if (
      originalInvoice &&
      originalInvoice.parcelas &&
      originalInvoice.parcelas[i]
    ) {
      parcelas[i].invoiceId = originalInvoice.parcelas[i].invoiceId;
      parcelas[i].lsi1 = originalInvoice.parcelas[i].lsi1;
      parcelas[i].lsi2 = originalInvoice.parcelas[i].lsi2;
      parcelas[i].lsi3 = originalInvoice.parcelas[i].lsi3;
      parcelas[i].lsi4 = originalInvoice.parcelas[i].lsi4;
      parcelas[i].version = originalInvoice.parcelas[i].version;
      parcelas[i].oldVencimento = originalInvoice.parcelas[i].vencimento;
    }
  }
  if (Math.abs(Math.round(100 * (valor - soma)) / 100) > 0) {
    parcelas[0].valor += Math.round(100 * (valor - soma)) / 100;
  }

  return parcelas;
};

type InvoiceFormProps = {
  item: Invoice;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  requestItem: (movId: string) => void;
  requestSave: (mov: Invoice) => void;
  id: string;
};

class InvoiceForm extends Component<InvoiceFormProps, {}> {
  state = {
    isChangingVencimento: false,
  };

  componentDidMount() {
    this.props.requestItem(this.props.id);
  }

  handleSubmit = (item: Invoice) => {
    const parcelas = item.parcelas;
    /// Adicionar parcelas removidas
    if (this.props.item.parcelas.length > parcelas.length) {
      /// Diminuiu o numero de parcelas
      /// Remover as parcelas a mais
      const start = parcelas.length;
      const end = this.props.item.parcelas.length;
      for (let i = start; i < end; i++) {
        parcelas.push({
          ...this.props.item.parcelas[i],
          deleted: true,
        });
      }
    }
    item.parcelas = parcelas;
    if (`${item.num}`.trim().length < 1) {
      item.serialCreate = {
        num: "INVOICE",
      };
    }
    this.props.requestSave(item);
  };

  handleNew = () => {
    this.props.requestItem("new");
  };

  render() {
    const isSaving = this.props.isSaving;

    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>Cobrança</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <LinkButton variant="primary" to="/invoicePane">
              Todas as cobranças
            </LinkButton>
            <Button
              className="ms-2"
              variant="outline-primary"
              onClick={this.handleNew}
            >
              Nova
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              valor: Yup.number().required(
                "Preenchimento do valor é obrigatório"
              ),
            })}
          >
            {(props) => {
              const { isValid, handleSubmit } = props;
              const validToItem = [props.values.valor > 0].reduce(
                (a, p) => a && p
              );
              const headerParcelas = [
                {
                  label: "#",
                  render: (item: InvoiceParcela) => `${item.numparcela}`,
                },
                {
                  label: "Vencimento",
                  wrapped: true,
                  render: (item: InvoiceParcela, i: number) => {
                    if (beganPaying) {
                      return formatDate(item.vencimento);
                    }
                    return (
                      <>
                        {this.state.isChangingVencimento && (
                          <div style={{ maxWidth: "20ch" }}>
                            <DateSelector
                              value={item.vencimento}
                              id={`vencimento${i}`}
                              onChange={(vencimento) => {
                                let parcelas = [...props.values.parcelas];
                                const parcela = { ...parcelas[i] };
                                parcela.vencimento = vencimento;
                                parcelas[i] = parcela;
                                props.setFieldValue("parcelas", parcelas);
                              }}
                            />
                          </div>
                        )}
                        {!this.state.isChangingVencimento && (
                          <div
                            onClick={() =>
                              this.setState({ isChangingVencimento: true })
                            }
                          >
                            {formatDate(item.vencimento)}
                          </div>
                        )}
                      </>
                    );
                  },
                  dataIndex: "vencimento",
                },
                {
                  label: "Valor",
                  render: (item: any) => formatNumber(item.valor, 2),
                },
                {
                  label: "Status",
                  render: (item: InvoiceParcela) =>
                    item.status === "CLOSED" ? "Pago" : "Pendente",
                },
              ];

              let beganPaying = false;
              if (props.values.parcelas) {
                beganPaying =
                  props.values.parcelas.findIndex(
                    (p) => p.status === "CLOSED"
                  ) !== -1;
              }
              // const parcelas = !beganPaying
              //   ? geraParcelas(
              //       props.values.valor,
              //       props.values.nparcelas,
              //       props.values.emission,
              //       this.props.item
              //     )
              //   : props.values.parcelas;

              // props.setFieldValue('parcelas', parcelas)
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <MaskInput
                      name="num"
                      label="Numero"
                      mask="99999999999"
                      formik={props}
                      size={3}
                    />
                    <DateInput
                      name="emission"
                      label="Emissão"
                      formik={props}
                      changeCallback={({ emission }) =>
                        !beganPaying &&
                        props.setFieldValue(
                          "parcelas",
                          geraParcelas(
                            props.values.valor,
                            props.values.nparcelas,
                            emission,
                            this.props.item
                          )
                        )
                      }
                      size={3}
                    />
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="emissor">
                      <Form.Label>Emissor</Form.Label>
                      <EmissorSelector
                        onChange={(emissor) =>
                          props.setFieldValue("emissor", emissor)
                        }
                        value={props.values.emissor}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="dest">
                      <Form.Label>Cliente</Form.Label>
                      <DestSelector
                        onChange={(dest) => props.setFieldValue("dest", dest)}
                        value={props.values.dest}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="banco">
                      <Form.Label>Banco</Form.Label>
                      <BancoSelector
                        onChange={(banco) =>
                          props.setFieldValue("banco", banco)
                        }
                        value={props.values.banco}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <NumberInput
                      decimalPlaces={2}
                      formik={props}
                      name="valor"
                      label="Valor"
                      changeCallback={({ valor }) =>
                        !beganPaying &&
                        props.setFieldValue(
                          "parcelas",
                          geraParcelas(
                            valor,
                            props.values.nparcelas,
                            props.values.emission,
                            this.props.item
                          )
                        )
                      }
                      size={6}
                    />
                    <NumberInput
                      decimalPlaces={0}
                      formik={props}
                      name="nparcelas"
                      label="Numero de Parcelas"
                      changeCallback={({ nparcelas }) =>
                        !beganPaying &&
                        props.setFieldValue(
                          "parcelas",
                          geraParcelas(
                            props.values.valor,
                            nparcelas,
                            props.values.emission,
                            this.props.item
                          )
                        )
                      }
                      size={6}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <TextField name="referente" label="Referente a" />
                    </Col>
                  </Row>
                  {validToItem && (
                    <>
                      <Row>
                        <Col>
                          <h4>Parcelas</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TableOrCard
                            headers={headerParcelas}
                            items={props.values.parcelas}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="my-2">
                    <Col>
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                        className="mx-2"
                      >
                        Salvar
                      </LoaderButton>
                      <Button type="reset">Desfazer</Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </LoadingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    item: state.invoice.form.item,
    successMsg: state.invoice.form.successMsg,
    error: state.invoice.form.error,
    isRequesting: state.invoice.form.isRequesting,
    isSaving: state.invoice.form.isSaving,
  };
};
const mapDispatchToProps = {
  requestItem: invoiceFormActions.requestItem,
  requestSave: invoiceFormActions.saveRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);
