import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, CardGroup } from "react-bootstrap";
import moment from "moment";
import { formatNumber } from "../../../misc/formatters";
import { pageInit } from "../actions";
import { s3Download } from "../../../misc/awsLib";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";
import { Cond } from "../saga";

type CondPrintProps = {
  items: Cond[];
  login: any;
  pageInit: () => void;
  id: string;
};

type CondPrintState = {
  cond: Cond | null;
  logoUrl: boolean | string;
};

class CondPrint extends Component<CondPrintProps, CondPrintState> {
  state = {
    cond: null,
    logoUrl: false,
  };

  getLogo = async () => {
    if (!this.props.login.defaultEmit || !!this.state.logoUrl) {
      return;
    }
    const logoUrl =
      this.props.login.defaultEmit.logo &&
      (await s3Download(this.props.login.defaultEmit.logo));
    this.setState({ logoUrl });
  };

  componentDidMount() {
    const { id } = this.props;
    // Try to find the cond in the items already loaded
    const itemSeached = this.props.items.find((item) => item.movId === id);
    if (!itemSeached) {
      this.props.pageInit();
    } else {
      this.setState({ cond: itemSeached });
    }
    this.getLogo();
  }

  componentDidUpdate(prevProps: CondPrintProps) {
    const { id } = this.props;
    // Try to find the cond in the items already loaded
    if (prevProps.items !== this.props.items) {
      const itemSeached = this.props.items.find((item) => item.movId === id);
      this.setState({ cond: itemSeached });
    }
    if (prevProps.login.defaultEmit !== this.props.login.defaultEmit) {
      this.getLogo();
    }
  }

  render() {
    const cond = this.state.cond;
    if (!cond) {
      return (
        <Container className="mt-5">
          <Icon
            icon={faSync}
            spin
            size="4x"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          />
        </Container>
      );
    }
    cond.emissor = this.props.login.defaultEmit;

    return (
      <>
        <Row>
          <Col lg={2} md={2} sm={6} xl={2} xs={12}>
            {typeof this.state.logoUrl === "string" && (
              <img
                src={this.state.logoUrl}
                style={{ width: "100%" }}
                alt="Logo"
              />
            )}
          </Col>
          {cond.emissor && (
            <Col xs={12} sm={6} md={10}>
              <h3>{cond.emissor.name}</h3>
              <p>
                {cond.emissor.address}, {cond.emissor.addressNumber}
              </p>
              <p>
                {cond.emissor.city} - {cond.emissor.estate}
              </p>
              <p>Fone: {cond.emissor.phone}</p>
              <p>Email: {cond.emissor.email}</p>
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          <Col xs={12} sm={12} md={4}>
            <b>CONDICIONAL</b>
          </Col>
          <Col xs={12} sm={12} md={4}>
            <b>Nº {cond.num}</b>
          </Col>
          <Col xs={12} sm={12} md={4}>
            <b>Data {moment(cond.emission).format("DD/MM/YYYY")}</b>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12}>
            <b className="d-block">Nome: {cond.dest.name}</b>
            <b className="d-block">
              {cond.dest.type === 1
                ? `CPF: ${cond.dest.cpf}`
                : `CNPJ: ${cond.dest.cnpj}`}
            </b>
            <b className="d-block">
              Endereço: {cond.dest.address}, {cond.dest.addressNumber}-{" "}
              {cond.dest.city} - {cond.dest.estate}
            </b>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12}>
            <h4>Produtos</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <CardGroup>
              {cond.items.map((item, i) => (
                <Card key={`${i}`}>
                  <Card.Body>
                    <Card.Title>
                      {item.prod.cod} - {item.prod.name}
                    </Card.Title>
                    <Card.Text>
                      <b>Quantidade: </b> {formatNumber(item.qtd)}
                      <br />
                      <b>Valor Unitário: </b> {formatNumber(item.valor, 2)}
                      <br />
                      <b>Total: </b> {formatNumber(item.total, 2)}
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </CardGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <b>
              Total:{" "}
              {formatNumber(
                cond.items.reduce((a, p) => a + p.total, 0),
                2
              )}
            </b>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="mt-4">Ass: {"_".repeat(50)}</Col>
        </Row>
        <Row>
          <Col className="mt-4">Data: {"_".repeat(50)}</Col>
        </Row>
        <hr />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.cond.list.items,
    login: state.login,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    pageInit: () => dispatch(pageInit()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CondPrint);
