import React from "react";
import { Form } from "react-bootstrap";

type ModBcIcmsStProps = {
  value: string;
  onChange: (value: string) => void;
};

const ModBcIcmsSt: React.FC<ModBcIcmsStProps> = (props) => (
  <Form.Control
    as="select"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  >
    <option value="0">Preço tabelado</option>
    <option value="1">Lista negativa</option>
    <option value="2">Lista positiva</option>
    <option value="3">Lista neutra</option>
    <option value="4">MVA</option>
    <option value="5">Pauta (valor)</option>
    <option value="6">Valor da operação</option>
  </Form.Control>
);

export default ModBcIcmsSt;
