import { Link } from "@reach/router";

export const LinkButton: React.FC<{
  variant:
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "info"
    | "light"
    | "dark"
    | "link"
    | "outline-success";
  className?: string;
  title?: string;
  to: string;
}> = ({ variant, className, children, ...props }) => (
  <Link {...props} className={`btn btn-${variant} ${className}`}>
    {children}
  </Link>
);
