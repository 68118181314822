import { useFormikContext } from "formik";
import React, { InputHTMLAttributes, useState } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import ModalDialog from "../ModalDialog";
import { Dest } from "../../modules/dest/actions";
import { Prod } from "../../modules/prod/actions";
import executeApiRequest from "../../misc/executeApiRequest";
import xmljs from "xml-js";
import { Purchase } from "../../modules/purchases/types";
import LoaderButton from "../LoaderButton";
import { AWSRes } from "../../redux/types";

type DateFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

async function findDestById(id: string, nome: string) {
  const res: AWSRes<Dest> = await executeApiRequest(
    "/dest",
    "GET",
    {},
    {
      queryStringParameters: {
        cadIdStart: `${nome}`
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/ /g, "_")
          .replace(/[^A-Z0-9 _]/g, ""),
      },
    }
  );
  console.log(
    "Dests found",
    res,
    res.items.find((dest) => dest.cpf === id || dest.cnpj === id)
  );

  if (res.items.length > 0) {
    return res.items.find((dest) => dest.cpf === id || dest.cnpj === id);
  }
  return undefined;
}

async function findProdById(id: string) {
  const res: AWSRes<Prod> = await executeApiRequest(
    "/prod",
    "GET",
    {},
    {
      queryStringParameters: {
        cadIdStart: id,
      },
    }
  );
  console.log(
    "Prods found",
    id,
    res,
    res.items.find((prod) => prod.cod === id)
  );

  if (res.items.length > 0) {
    return res.items.find((prod) => prod.cod === id);
  }
  return undefined;
}

export const NfeField: React.FC<DateFieldProps> = ({ label, ...props }) => {
  const { values, setFieldValue } = useFormikContext<Purchase>();
  const [modalOpen, setModalOpen] = useState(false);
  const [xml, setXml] = useState<File>(null);
  const [xmlError, setXmlError] = useState("");
  const [isLoadingXml, setIsLoadingXml] = useState(false);

  const parseXml = () => {
    if (!xml) {
      return;
    }
    console.log("reading xml");
    setIsLoadingXml(true);
    try {
      const reader = new FileReader();
      reader.onload = async (evt) => {
        const bstr = evt.target.result as string;
        console.log("loading", bstr);
        const jsNFe: any = xmljs.xml2js(bstr, { compact: true });
        console.log("jsNFe", jsNFe);
        console.log("jsNFe", jsNFe.nfeProc);
        let NFe = jsNFe.NFe;
        if (!NFe) {
          NFe = jsNFe.nfeProc && jsNFe.nfeProc.NFe;
        }
        if (!NFe) {
          setXmlError("O XML informado não é uma NFe valida!");
          setIsLoadingXml(false);
          return;
        }

        setFieldValue("num", NFe.infNFe.ide.nNF._text);
        // Encontrar o destinatario na base de cadastro
        const cpf_cnpj_dest = NFe.infNFe.emit.CNPJ._text;
        const dest = await findDestById(
          cpf_cnpj_dest,
          NFe.infNFe.emit.xNome._text
        );
        if (dest) {
          setFieldValue("dest", dest);
        } else {
          const dest_novo: Dest = {
            cadType: "CAD_DEST",
            name: NFe.infNFe.emit.xNome._text,
            type: cpf_cnpj_dest.length === 14 ? 2 : 1,
            indFinal: false,
            cnpj: cpf_cnpj_dest,
            ie: NFe.infNFe.emit.IE._text,
            estate: NFe.infNFe.emit.enderEmit.UF._text,
            city: NFe.infNFe.emit.enderEmit.xMun._text,
            zipCode: NFe.infNFe.emit.enderEmit.CEP._text,
            address: NFe.infNFe.emit.enderEmit.xLgr._text,
            addressNumber: NFe.infNFe.emit.enderEmit.nro._text,
            bairro: NFe.infNFe.emit.enderEmit.xMun._text,
            cmun: NFe.infNFe.emit.enderEmit.cMun._text,
            email: "",
            phone: "",
          };
          setFieldValue("dest", dest_novo);
        }

        // Encontrar os itens e valores
        if (Array.isArray(NFe.infNFe.det)) {
          const novosItens = [...values.items];
          for (const det of NFe.infNFe.det) {
            const cod_prod = det.prod.cProd._text;
            let prod: Prod = await findProdById(cod_prod);
            if (!prod) {
              console.log("prod not found", cod_prod);
              prod = {
                cadType: "CAD_PROD",
                cod: cod_prod,
                name: det.prod.xProd._text,
                ean: parseInt(det.prod.cEAN._text),
                ncm: det.prod.NCM._text,
                cest: det.prod.CEST?._text,
                unitType: det.prod.uCom._text,
                preco: parseFloat(det.prod.vUnCom._text),
                orig: 0,
                quantidadeAtual: parseFloat(det.prod.qCom._text),
                custoMedio: parseFloat(det.prod.vUnCom._text),
              };
            }
            const item = {
              nItem: (values.items?.length || 0) + 1,
              prod,
              qtd: parseFloat(det.prod.qCom._text),
              valor: parseFloat(det.prod.vUnCom._text),
              total: 0,
            };
            item.total = item.qtd * item.valor;
            novosItens.push(item);
          }
          setFieldValue("items", novosItens);
        } else {
          const det = NFe.infNFe.det;
          const cod_prod = det.prod.cProd._text;
          let prod = await findProdById(cod_prod);
          if (!prod) {
            prod = {
              cadType: "CAD_PROD",
              cod: cod_prod,
              name: det.prod.xProd._text,
              ean: parseInt(det.prod.cEAN._text),
              ncm: det.prod.NCM._text,
              cest: det.prod.CEST?._text,
              unitType: det.prod.uCom._text,
              preco: parseFloat(det.prod.vUnCom._text),
              orig: 0,
              quantidadeAtual: parseFloat(det.prod.qCom._text),
              custoMedio: parseFloat(det.prod.vUnCom._text),
            };
          }
          const item = {
            nItem: (values.items?.length || 0) + 1,
            prod,
            qtd: parseFloat(det.prod.qCom._text),
            valor: parseFloat(det.prod.vUnCom._text),
            total: 0,
          };
          item.total = item.qtd * item.valor;
          const novosItens = [...values.items, item];
          setFieldValue("items", novosItens);
        }

        setModalOpen(false);
        setIsLoadingXml(false);
      };
      reader.readAsText(xml, "UTF-8");
      //reader.readAsBinaryString(this.state.xml);
    } catch (error) {
      setXmlError("O XML informado não é uma NFe valida!");
      setIsLoadingXml(false);
      console.log("error xml parse", error);
    }
  };

  return (
    <>
      <ModalDialog
        title="Importar do xml da NFe"
        buttons={
          <>
            <LoaderButton isLoading={isLoadingXml} onClick={() => parseXml()}>
              Confirmar
            </LoaderButton>
            <Button variant="secondary" onClick={() => setModalOpen(false)}>
              Cancelar
            </Button>
          </>
        }
        show={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Row className="d-print-none">
          <Col>
            <Form.Group as={Col} controlId="xml">
              <label htmlFor="xml" className="btn btn-secondary">
                Selecionar um arquivo XML
              </label>
              <input
                accept=".xml"
                type="file"
                onChange={(e) => setXml(e.target.files[0])}
                style={{ visibility: "hidden" }}
                id="xml"
              />
              {xml && `Arquivo selecionado: ${xml.name}`}
            </Form.Group>
          </Col>
        </Row>
        {xmlError && (
          <Row>
            <Col>
              <Alert variant="error">{xmlError}</Alert>
            </Col>
          </Row>
        )}
      </ModalDialog>
      <Button onClick={() => setModalOpen(true)} variant="secondary">
        {label}
      </Button>
    </>
  );
};
