import { Link } from "@reach/router";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
  AllTypeaheadOwnAndInjectedProps,
  Typeahead,
  TypeaheadModel,
} from "react-bootstrap-typeahead";
import "./ObjSelection.css";

type ModSelectorProps = {
  options: any[];
  renderOption: (value: any) => string;
  path: string;
  onChange: (value: any | null) => void;
  value: any;
  filterBy: (
    option: any,
    props: AllTypeaheadOwnAndInjectedProps<TypeaheadModel>
  ) => boolean;
  minLength?: number;
  forwardRef?: React.LegacyRef<Typeahead<TypeaheadModel>>;
};

class ModSelector extends Component<ModSelectorProps, {}> {
  static propTypes = {
    options: PropTypes.array.isRequired,
    renderOption: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    filterBy: PropTypes.func.isRequired,
    minLength: PropTypes.number,
  };

  render() {
    if (this.props.value) {
      return (
        <div className="ObjSelection">
          <Button
            variant="danger"
            tabIndex={-1}
            onClick={() => this.props.onChange(null)}
          >
            <i className="fas fa-times" />
          </Button>
          <Link
            tabIndex={-1}
            to={`${this.props.path}/${this.props.value.cadId}`}
            target="_blank"
            className="ObjOpenBtn btn btn-primary"
          >
            <i className="fas fa-arrow-right" />
          </Link>
          {this.props.renderOption(this.props.value)}
        </div>
      );
    }
    return (
      <Typeahead
        id="dest-selector"
        labelKey={this.props.renderOption}
        options={this.props.options}
        filterBy={this.props.filterBy}
        emptyLabel="Nenhum item encontrado"
        ref={this.props.forwardRef}
        onChange={(objArr: any[]) =>
          objArr.length > 0 && this.props.onChange(objArr[0])
        }
        minLength={this.props.minLength || 2}
      />
    );
  }
}

export default ModSelector;
