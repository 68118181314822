export const PROD_SELECTOR_REQUESTING = "PROD_SELECTOR_REQUESTING";
export const PROD_SELECTOR_SUCCESS = "PROD_SELECTOR_SUCCESS";
export const PROD_SELECTOR_ERROR = "PROD_SELECTOR_ERROR";

export const prodSelectorRequest = (payload) => ({
  type: PROD_SELECTOR_REQUESTING,
  payload,
});

export const prodSelectorSuccess = (payload) => ({
  type: PROD_SELECTOR_SUCCESS,
  payload,
});

export const prodSelectorError = (payload) => ({
  type: PROD_SELECTOR_ERROR,
  payload,
});
