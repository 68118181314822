import { faLifeRing, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@reach/router";
import { differenceInDays } from "date-fns/esm";
import React, { Component } from "react";
import {
  Badge,
  Button,
  Container,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import "./App.css";
import Menu from "./components/Menu/Menu";
import QuickActions from "./components/Menu/QuickActions";
import SidebarMenu from "./components/Menu/SidebarMenu";
import NotificationBar from "./components/NotificationBar";
import { RootState } from "./modules/app/mainReducer";
import { loginActions, Org } from "./modules/login/reducer";
import { Subscription } from "./modules/subscription/reducer";
import Routes from "./Routes";

import logo from "./assets/logo-completo.png";

interface AppProps {
  isAuthenticated: boolean;
  notifications: any[];
  org: Org;
  isOrgRequesting: boolean;
  activeSubscription: Subscription;
  sessionLogin: () => void;
  logout: () => void;
}

type AppState = {
  notificationBarOpened: boolean;
  barText: string;
};

export class App extends Component<AppProps, AppState> {
  state = {
    notificationBarOpened: false,
    barText: "",
  };

  componentDidMount() {
    this.props.sessionLogin();
  }

  logout = () => {
    this.props.logout();
  };

  renderMenuAuth = (isOrgNew = false) => {
    if (this.props.isAuthenticated === true) {
      return (
        <>
          <Nav className="text-secondary flex-fill">
            {!isOrgNew && <QuickActions />}
            {isOrgNew && <div style={{ width: "100%" }}></div>}
            <NavLink
              className="navitem"
              style={{ fontSize: "1.6rem" }}
              onClick={() => this.setState({ notificationBarOpened: true })}
            >
              <i className="fas fa-bell" />
              {this.props.notifications.length > 0 && (
                <Badge
                  className="menupill"
                  style={{ fontSize: "0.7rem" }}
                  pill
                  variant="danger"
                >
                  {this.props.notifications.length}
                </Badge>
              )}
            </NavLink>
            <NavLink
              className="navitem d-none d-lg-flex"
              href="mailto:support@sdcks.com?Subject=Preciso de ajuda em ...&body=Descreva o que voce precisa"
              title="Pedir ajuda"
              style={{ fontSize: "1.6rem", color: "#E74C3C" }}
            >
              <FontAwesomeIcon icon={faLifeRing} />
            </NavLink>
            <NavLink
              as={Link}
              className="navitem d-none d-lg-flex"
              style={{ fontSize: "1.6rem" }}
              to="/Profile"
            >
              <FontAwesomeIcon icon={faUser} />
            </NavLink>
            <Button variant="outline-primary" onClick={this.logout}>
              Sair
            </Button>
          </Nav>
          <Menu />
        </>
      );
    }
    return (
      <Nav className="justify-content-end ml-auto text-secondary">
        <NavItem as={Link} to="/signup" className="navitem">
          Criar conta
        </NavItem>
        <NavItem as={Link} to="/login" className="navitem">
          Entrar
        </NavItem>
      </Nav>
    );
  };

  render() {
    const orgCreated = new Date(
      this.props.org ? this.props.org.createdAt || 0 : 0
    );
    const dif = differenceInDays(new Date(), orgCreated);
    console.log("orgCreated", orgCreated, dif);

    const subscription = this.props.activeSubscription;
    const isSubscriptionActive =
      subscription &&
      (subscription.status === "active" || subscription.status === "past_due");
    const isSubscriptionPastDue =
      subscription && subscription.status === "past_due";
    console.log("subscription", subscription);
    const isOrgNew = this.props.org
      ? this.props.org.orgStatus === "NEW"
      : false;

    return (
      <div className="App">
        <NotificationBar
          isVisible={this.state.notificationBarOpened}
          className="no-print"
          onNewNotification={() =>
            this.setState({ notificationBarOpened: true })
          }
          onClose={() => this.setState({ notificationBarOpened: false })}
        />
        <Navbar bg="dark" variant="dark" className="no-print" expand="lg">
          <Navbar.Brand className="navbar-brand">
            <Link to="/">
              <Image src={logo} width={100} height={32} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="mainMenu" />
          <Navbar.Collapse id="mainMenu">
            {this.renderMenuAuth(isOrgNew)}
          </Navbar.Collapse>
        </Navbar>
        {(!isSubscriptionActive || isSubscriptionPastDue) &&
          this.props.isAuthenticated && (
            <div className="trialBar">
              {!isSubscriptionActive && (
                <>
                  {dif > 30
                    ? `O seu periodo de testes acabou! `
                    : `Voce esta em modo teste e tem ${
                        30 - dif
                      } dias de teste! `}
                  <Link to="/subscription/subscriptions">
                    Ir para assinaturas
                  </Link>
                </>
              )}
              {isSubscriptionPastDue && (
                <>
                  O seu pagamento não foi efetivado e esta em atraso! (Sua conta
                  será bloqueada em breve), por favor verifique seus dados de
                  pagamento em{" "}
                  <Link to={`/subscription/subscriptions`}>Assinaturas</Link>!
                </>
              )}
            </div>
          )}
        <div className="AppMain">
          {this.props.isAuthenticated && !isOrgNew && <SidebarMenu />}
          <Container fluid className="AppContainer">
            <Routes />
          </Container>
          <div className="LinesBackground-container">
            <div className="LinesBackground"></div>
          </div>
          <div className="LinesBackground-container">
            <div className="LinesBackground_2"></div>
          </div>
          <div className="LinesBackground-container">
            <div className="LinesBackground_3"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: state.login.isAuthenticated,
    org: state.login.org,
    isOrgRequesting: state.login.isOrgRequesting,
    activeSubscription: state.subscription.activeSubscription,
    notifications: state.notification.notifications,
  };
};

const dispatchProps = {
  sessionLogin: loginActions.sessionLogin,
  logout: loginActions.logoutRequest,
};

export const ConnectedApp = connect(mapStateToProps, dispatchProps)(App);

export default ConnectedApp;
