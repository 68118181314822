import React from "react";
import InputMask from "react-input-mask";
import { FormikProps } from "formik";

type MaskInputProps = {
  name: string;
  label: string;
  mask: string;
  formik: FormikProps<any>;
  autoFocus?: boolean;
  size: number;
};

const MaskInput: React.FC<MaskInputProps> = (props) => (
  <div className={`form-group col-12 col-md-${props.size}`}>
    <label htmlFor={props.name}>{props.label}</label>
    <InputMask
      id={props.name}
      mask={props.mask}
      maskPlaceholder=" "
      value={`${props.formik.values[props.name]}`}
      onChange={props.formik.handleChange}
      onBlur={props.formik.handleBlur}
      autoFocus={props.autoFocus}
      className={
        props.formik.errors[props.name] && props.formik.touched[props.name]
          ? "form-control text-input error is-invalid"
          : "form-control text-input"
      }
      placeholder={props.label}
    />
    {props.formik.errors[props.name] && props.formik.touched[props.name] && (
      <div className="input-feedback">{props.formik.errors[props.name]}</div>
    )}
  </div>
);

export default MaskInput;
