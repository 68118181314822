import React, { Component } from "react";
import {
  AllTypeaheadOwnAndInjectedProps,
  AsyncTypeahead,
  TypeaheadModel,
} from "react-bootstrap-typeahead";
import { Button } from "react-bootstrap";
import { Link } from "@reach/router";
import PropTypes from "prop-types";

import "./ObjSelection.css";

type ModSelectorAsyncProps = {
  options: any[];
  renderOption: (value: any) => string;
  path: string;
  onChange: (value: any | null) => void;
  value: any;
  filterBy?: (
    option: any,
    props: AllTypeaheadOwnAndInjectedProps<TypeaheadModel>
  ) => boolean;
  minLength?: number;
  forwardRef?: React.LegacyRef<AsyncTypeahead<TypeaheadModel>>;
  className?: string;
  isLoading: boolean;
  onBlur?: (e: Event) => void;
  onSearch: (query: string) => void;
};

class ModSelectorAsync extends Component<ModSelectorAsyncProps> {
  static propTypes = {
    options: PropTypes.array.isRequired,
    renderOption: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    minLength: PropTypes.number,
  };

  render() {
    if (this.props.value) {
      return (
        <div className="ObjSelection">
          <Button
            variant="danger"
            tabIndex={-1}
            onClick={() => this.props.onChange(null)}
          >
            <i className="fas fa-times" />
          </Button>
          <Link
            tabIndex={-1}
            to={`${this.props.path}/${this.props.value.cadId}`}
            target="_blank"
            className="ObjOpenBtn btn btn-primary"
          >
            <i className="fas fa-arrow-right" />
          </Link>
          {this.props.renderOption(this.props.value)}
        </div>
      );
    }
    return (
      <AsyncTypeahead
        onSearch={this.props.onSearch}
        searchText="Pesquisando..."
        id="mod-selector-async"
        isLoading={this.props.isLoading}
        labelKey={this.props.renderOption}
        options={this.props.options}
        emptyLabel="Nenhum item encontrado"
        onBlur={this.props.onBlur}
        inputProps={{
          className: this.props.className,
        }}
        ref={this.props.forwardRef}
        onChange={(objArr) =>
          objArr.length > 0 && this.props.onChange(objArr[0])
        }
        minLength={this.props.minLength || 2}
      />
    );
  }
}

export default ModSelectorAsync;
