import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import ModSelectorAsync from "../../../components/ModSelectorAsync";
import { RootState } from "../../app/mainReducer";
import { Banco } from "../types";
import { bancoSelectorActions } from "./reducer";

interface IBancoSelectorProps {
  value: Banco;
  onChange: (banco: Banco) => void;
}

const BancoSelector: React.FunctionComponent<IBancoSelectorProps> = ({
  value,
  onChange,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.banco.selector.isLoading
  );
  const itens = useSelector((state: RootState) => state.banco.selector.itens);

  const dispatch = useDispatch();

  const onSearch = (term: string) => {
    console.log("Searching", term);
    dispatch(bancoSelectorActions.requestBancos(term));
  };

  return (
    <>
      <ModSelectorAsync
        options={itens}
        isLoading={isLoading}
        renderOption={(banco) => `${banco.cod} - ${banco.name}`}
        // forwardRef={this.props.forwardRef}
        path="/banco"
        value={value}
        onChange={onChange}
        onSearch={onSearch}
        minLength={2}
      />
    </>
  );
};

export default BancoSelector;
