import { AWSRes } from "./../../redux/types";
export const SERIE_INIT = "SERIE_INIT";
export const SERIE_REQUESTING = "SERIE_REQUESTING";
export const SERIE_SUCCESS = "SERIE_SUCCESS";
export const SERIE_ERROR = "SERIE_ERROR";
export const CAD_TYPE = "CAD_SERIE";
export const SERIE_DELETE_REQUESTING = "SERIE_DELETE_REQUESTING";
export const SERIE_DELETE_SUCCESS = "SERIE_DELETE_SUCCESS";
export const SERIE_DELETE_ERROR = "SERIE_DELETE_ERROR";

export type Serie = {
  cadId?: string;
  cadType: "CAD_SERIE";
  version?: number;
  userId?: string;
  cod: string;
  name: string;
};

export function pageInit() {
  return {
    type: SERIE_INIT,
  };
}

export function serieRequest() {
  return {
    type: SERIE_REQUESTING,
  };
}

export function serieSuccess(payload: AWSRes<Serie>) {
  return {
    type: SERIE_SUCCESS,
    payload,
  };
}

export function serieError(error: any) {
  return {
    type: SERIE_ERROR,
    error,
  };
}

export function serieDeleteRequest(cadId: string) {
  return {
    type: SERIE_DELETE_REQUESTING,
    payload: cadId,
  };
}

export function serieDeleteSuccess() {
  return {
    type: SERIE_DELETE_SUCCESS,
  };
}

export function serieDeleteError(error: any) {
  return {
    type: SERIE_DELETE_ERROR,
    error,
  };
}
