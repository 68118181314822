import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import menu_links from "./menu_links";
import MenuNavLink from "./MenuNavLink";
import { useSubscriptionRoles } from "../../misc/useSubscriptionRoles";

const SidebarMenu: React.FC<{}> = () => {
  const subscriptionRoles = useSubscriptionRoles();
  console.log("subscriptionRoles", subscriptionRoles);

  return (
    <div className="Sidebar d-none d-lg-flex">
      {menu_links
        .filter(
          (link) => subscriptionRoles.findIndex((val) => val === link.id) >= 0
        )
        .map((link, i) => (
          <MenuNavLink
            to={link.link}
            activeClass="nav-link-active"
            key={`${i}`}
          >
            {link.icon && <FontAwesomeIcon icon={link.icon} />} {link.label}
          </MenuNavLink>
        ))}
    </div>
  );
};

export default SidebarMenu;
