import React, { Component, MouseEventHandler } from "react";
import { Spinner, Button } from "react-bootstrap";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

type DeleteButtonProps = {
  isRequesting: boolean;
  onDelete: MouseEventHandler;
};

type DeleteButtonState = {
  isConfirming: boolean;
};

class DeleteButton extends Component<DeleteButtonProps, DeleteButtonState> {
  state = {
    isConfirming: false,
  };

  render() {
    if (this.props.isRequesting) {
      return <Spinner animation="border" role="status" />;
    }
    if (this.state.isConfirming) {
      return (
        <>
          <Button variant="danger" onClick={this.props.onDelete}>
            Confirmar
          </Button>
          <Button
            variant="secondary"
            onClick={() => this.setState({ isConfirming: false })}
          >
            Não
          </Button>
        </>
      );
    }
    return (
      <Button
        variant="danger"
        onClick={() => this.setState({ isConfirming: true })}
      >
        <Fa icon={faTrash} />
      </Button>
    );
  }
}

export default DeleteButton;
