import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ResultadoRequest } from "./saga";

export type RegistroCaixa = {
  movId: string;
  userId: string;
  lancamentos: {
    descricao: string;
    valor: number;
    id: string;
  }[];
  saldo_inicial: number;
  saldo_final: number;
  saldo_fechamento?: number;
};

type CaixaInitialState = {
  items: RegistroCaixa[];
  ultimoSaldo?: RegistroCaixa;
  Count: number;
  lastKey: Record<string, string>;
  isRequesting: boolean;
  isCreating: boolean;
  isRemoving: boolean;
  error: string;
  ultimaDataInicial?: Date;
  ultimaDataFinal?: Date;
  isFechando: boolean;
};

const initialState: CaixaInitialState = {
  items: [],
  Count: 0,
  lastKey: null,
  isRemoving: false,
  isCreating: false,
  isRequesting: false,
  isFechando: false,
  error: "",
};

const reducer = createSlice({
  name: "caixaReducer",
  initialState,
  reducers: {
    requestCaixa(
      state,
      { payload }: PayloadAction<{ data_inicial: Date; data_final: Date }>
    ) {
      state.items = [];
      state.Count = 0;
      state.lastKey = null;
      state.isRemoving = false;
      state.isCreating = false;
      state.isRequesting = true;
      state.ultimaDataInicial = payload.data_inicial;
      state.ultimaDataFinal = payload.data_final;
    },
    requestSuccess(state, { payload }: PayloadAction<ResultadoRequest>) {
      state.items = payload.items;
      state.Count = payload.Count;
      state.lastKey = payload.lastKey;
      state.isRequesting = false;
      state.ultimoSaldo = payload.ultimoSaldo;
    },
    requestError(state, { payload }: PayloadAction<string>) {
      state.isRequesting = false;
      state.error = payload;
    },
    createLancamento(
      state,
      _: PayloadAction<{ id: string; valor: number; descricao: string }>
    ) {
      state.isCreating = true;
    },
    createLancamentoSuccess(state) {
      state.isCreating = false;
      state.error = "";
    },
    createLancamentoError(state, { payload }: PayloadAction<string>) {
      state.isCreating = false;
      state.error = payload;
    },
    removerLancamento(state, _: PayloadAction<string>) {
      state.isRemoving = true;
      state.error = "";
    },
    removerLancamentoSuccess(state) {
      state.isRemoving = false;
    },
    removerLancamentoError(state, { payload }: PayloadAction<string>) {
      state.isRemoving = false;
      state.error = payload;
    },
    requestFecharCaixa(state, _: PayloadAction<number>) {
      state.isFechando = true;
    },
    fecharCaixaSuccess(state) {
      state.isFechando = false;
    },
    fecharCaixaError(state, { payload }: PayloadAction<string>) {
      state.isFechando = false;
      state.error = payload;
    },
  },
});

export const caixaActions = reducer.actions;

export default reducer;
