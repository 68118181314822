import { redirectTo, RouteComponentProps, useLocation } from "@reach/router";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";

interface AuthenticatedRouteProps extends RouteComponentProps {
  as: React.ElementType;
  children?: React.ReactNode;
}

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  as: Comp,
  ...rest
}) => {
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector(
    (state: RootState) => state.login.isAuthenticated
  );
  console.log("path", pathname, search);
  if (!isAuthenticated) {
    redirectTo(`/login?redirect=${pathname}${search}`);
  }
  // const Comp = as;
  return <Comp {...rest} />;
};

export default AuthenticatedRoute;
