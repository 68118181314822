import React, { Component } from "react";
import { connect } from "react-redux";
import { pageInit, Transp } from "../actions";
import ModSelector from "../../../components/ModSelector";

type TranspSelectorStateProps = {
  items: Transp[];
};
type TranspSelectorDispatchProps = {
  pageInit: () => void;
};
type TranspSelectorProps = {
  value: Transp;
  onChange: (transp: Transp) => void;
};

class TranspSelector extends Component<
  TranspSelectorStateProps & TranspSelectorDispatchProps & TranspSelectorProps,
  {}
> {
  state = {};

  componentDidMount() {
    if (this.props.items.length <= 0) {
      this.props.pageInit();
    }
  }

  filterOptions = (transp, term) => {
    const reTerm = new RegExp(term.text, "i");
    return (
      reTerm.test(transp.name) ||
      reTerm.test(transp.cnpj) ||
      reTerm.test(transp.address) ||
      reTerm.test(transp.city)
    );
  };

  render() {
    return (
      <ModSelector
        options={this.props.items}
        renderOption={(transp) => `${transp.name} - CNPJ: ${transp.cnpj} - 
      Endereço: ${transp.address}, ${transp.addressNumber} - ${transp.city} - ${transp.estate}`}
        path="/transp"
        value={this.props.value}
        onChange={this.props.onChange}
        filterBy={this.filterOptions}
        minLength={2}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.transp.list.items,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: () => dispatch(pageInit()),
  };
};

export default connect<
  TranspSelectorStateProps,
  TranspSelectorDispatchProps,
  TranspSelectorProps
>(
  mapStateToProps,
  mapDispatchToProps
)(TranspSelector);
