// reducer do modulo dest
import {
  DEST_INIT,
  DEST_REQUESTING,
  DEST_SUCCESS,
  DEST_ERROR,
  DEST_DELETE_REQUESTING,
  DEST_DELETE_ERROR,
  DEST_DELETE_SUCCESS,
  DEST_IMPORTAR_INIT,
  DEST_IMPORTAR_REQUEST,
  DEST_IMPORTAR_SUCCESS,
  DEST_IMPORTAR_ERROR,
} from "./actions";
import formReducer from "./destForm/reducer";
import selectorReducer from "./selector/reducer";
import { combineReducers } from "redux";

const initialState = {
  items: [],
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
  isImportarRequesting: false,
  nimportados: 0,
  nerros: 0,
  importarError: "",
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DEST_INIT:
      return {
        ...state,
        items: [],
        Count: 0,
        ScannedCount: 0,
        lastKey: null,
        successMsg: "",
        error: "",
        isRequesting: true,
        isDeleting: false,
      };
    case DEST_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      };
    case DEST_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload.items,
        Count: action.payload.Count,
        ScannedCount: action.payload.ScannedCount,
        lastKey: action.payload.lastKey,
      };
    case DEST_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
        successMsg: "",
      };
    case DEST_DELETE_REQUESTING:
      return {
        ...state,
        isDeleting: true,
      };
    case DEST_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        successMsg: "Cliente/Fornecedor removido com sucesso!",
      };
    case DEST_DELETE_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        successMsg: "",
      };
    case DEST_IMPORTAR_INIT:
      return {
        ...state,
        isImportarRequesting: false,
        nimportados: 0,
        nerros: 0,
        importarError: "",
      };
    case DEST_IMPORTAR_REQUEST:
      return {
        ...state,
        isImportarRequesting: true,
        nimportados: 0,
        nerros: 0,
        importarError: "",
      };
    case DEST_IMPORTAR_SUCCESS:
      return {
        ...state,
        isImportarRequesting: false,
        nimportados: action.payload.nsucesso,
        nerros: action.payload.nerro,
      };
    case DEST_IMPORTAR_ERROR:
      return {
        ...state,
        isImportarRequesting: false,
        importarError: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list: reducer,
  form: formReducer,
  selector: selectorReducer,
});
