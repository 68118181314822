// reducer do modulo invoice
import {
  INVOICE_INIT,
  INVOICE_REQUESTING,
  INVOICE_SUCCESS,
  INVOICE_ERROR,
  INVOICE_DELETE_REQUESTING,
  INVOICE_DELETE_ERROR,
  INVOICE_DELETE_SUCCESS,
} from "./actions";
import formReducer from "./invoiceForm/reducer";
import payReducer from "./invoicePay/reducer";
import paneReducer from "./invoicePane/reducer";
import viewReducer from "./invoiceView/reducer";
import { combineReducers } from "redux";
import reportSlice from "./invoiceReport/reducer";

const initialState = {
  items: [],
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INVOICE_INIT:
      return {
        ...state,
        items: [],
        Count: 0,
        ScannedCount: 0,
        lastKey: null,
        successMsg: "",
        error: "",
        isRequesting: false,
        isDeleting: false,
      };
    case INVOICE_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      };
    case INVOICE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload.items,
        Count: action.payload.Count,
        ScannedCount: action.payload.ScannedCount,
        lastKey: action.payload.lastKey,
      };
    case INVOICE_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
        successMsg: "",
      };
    case INVOICE_DELETE_REQUESTING:
      return {
        ...state,
        isDeleting: true,
      };
    case INVOICE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        successMsg: "Cobrança removida com sucesso!",
      };
    case INVOICE_DELETE_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        successMsg: "",
      };
    default:
      return state;
  }
};

export default combineReducers({
  list: reducer,
  form: formReducer.reducer,
  pay: payReducer,
  pane: paneReducer.reducer,
  view: viewReducer,
  report: reportSlice.reducer,
});
