import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModSelectorAsync from "../../../components/ModSelectorAsync";
import { RootState } from "../../app/mainReducer";
import { Prod } from "../actions";
import { prodSelectorRequest } from "./actions";
import { Form } from "react-bootstrap";

type ProdSelectorProps = {
  forwardRef?: any;
  value: Prod;
  onChange: (prod: Prod) => void;
};

export const ProdField: React.FC<
  InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    name: string;
  }
> = ({ label, ...props }) => {
  const [field, { error, touched }, { setValue }] = useField<Prod>(props);

  return (
    <>
      <label>{label}</label>
      <ProdSelector value={field.value} onChange={(prod) => setValue(prod)} />
      {error && touched && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};

const ProdSelector: React.FC<ProdSelectorProps> = (props) => {
  const items = useSelector((state: RootState) => state.prod.selector.items);
  const isRequesting = useSelector(
    (state: RootState) => state.prod.selector.isRequesting
  );

  const dispatch = useDispatch();

  const onSearch = (term) => {
    console.log("searching", term);
    dispatch(prodSelectorRequest(term));
  };

  return (
    <ModSelectorAsync
      options={items}
      isLoading={isRequesting}
      renderOption={(prod) => `${prod.cod} - ${prod.name}`}
      forwardRef={props.forwardRef}
      path="/prod"
      value={props.value}
      onChange={props.onChange}
      onSearch={onSearch}
      minLength={2}
    />
  );
};

export default ProdSelector;

// class ProdSelector extends Component<ProdSelectorProps, {}> {
//   state = {};

//   onSearch = (term) => {
//     console.log("searching", term);
//     this.props.prodSelectorRequest(term);
//   };

//   render() {
//     return (
//       <ModSelectorAsync
//         options={this.props.items}
//         isLoading={this.props.isRequesting}
//         renderOption={(prod) => `${prod.cod} - ${prod.name}`}
//         forwardRef={this.props.forwardRef}
//         path="/prod"
//         value={this.props.value}
//         onChange={this.props.onChange}
//         onSearch={this.onSearch}
//         minLength={2}
//       />
//     );
//   }
// }

// const mapStateToProps = (state: any) => {
//   return {
//     items: state.prod.selector.items,
//     isRequesting: state.prod.selector.isRequesting,
//     error: state.prod.selector.error,
//   };
// };
// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     prodSelectorRequest: (payload: any) =>
//       dispatch(prodSelectorRequest(payload)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(ProdSelector);
