import { all, takeLatest } from "redux-saga/effects";
import { InvoiceReportRequestPayload, invoiceReportsActions } from "./reducer";
import { InvoiceParcela } from "./../actions";
import { AWSRes } from "./../../../redux/types";
import executeApiRequest from "../../../misc/executeApiRequest";
import { call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";

function invoiceCall(
  idQuery: string = "CLOSED",
  indexToUse: string = undefined,
  lastKey: Record<string, string> = null
) {
  return executeApiRequest<AWSRes<InvoiceParcela>>(
    "/invoice",
    "GET",
    {},
    {
      queryStringParameters: {
        lastKey: JSON.stringify(lastKey),
        idQuery: idQuery,
        idFilterType: "BETWEEN",
        indexToUse,
      },
    }
  );
}

function* invoiceReportWorker({
  payload,
}: PayloadAction<InvoiceReportRequestPayload>) {
  try {
    const dtIniString = format(payload.dataIni, "yyyy_MM_dd");
    const dtFimString = format(payload.dataFim, "yyyy_MM_dd");
    switch (payload.tipoData) {
      case "EMISSAO": {
        const res: AWSRes<InvoiceParcela> = yield call(
          invoiceCall,
          `${payload.tipo ? "CLOSED" : "OPEN"}.${dtIniString}:${
            payload.tipo ? "CLOSED" : "OPEN"
          }.${dtFimString}`,
          "lsi2"
        );
        yield put(invoiceReportsActions.invoiceSuccess(res));
        break;
      }
      case "VENCIMENTO": {
        const res: AWSRes<InvoiceParcela> = yield call(
          invoiceCall,
          `${payload.tipo ? "CLOSED" : "OPEN"}.${dtIniString}:${
            payload.tipo ? "CLOSED" : "OPEN"
          }.${dtFimString}`,
          "lsi1"
        );
        yield put(invoiceReportsActions.invoiceSuccess(res));
        break;
      }
      case "BAIXA": {
        const res: AWSRes<InvoiceParcela> = yield call(
          invoiceCall,
          `CLOSED.${dtIniString}:CLOSED.${dtFimString}`,
          "lsi3"
        );
        yield put(invoiceReportsActions.invoiceSuccess(res));
        break;
      }
      default:
        yield put(
          invoiceReportsActions.invoiceError(`Nenhum tipo de data selecionada!`)
        );
    }
  } catch (error) {
    yield put(invoiceReportsActions.invoiceError(error));
  }
}

export default function* invoiceReportSaga() {
  yield all([takeLatest("invoiceReport/requestInvoices", invoiceReportWorker)]);
}
