import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import ProdSelector from "../../prod/selector";
import InputNumber from "../../../components/InputNumber";
import CfopSelector from "../../../components/CfopSelector";
import { SaleItem } from "../types";

type ItemFormProps = {
  item: SaleItem;
  handleChange: (obj: Partial<SaleItem>) => void;
};

const ItemForm: React.FC<ItemFormProps> = ({ item, handleChange }) => (
  <>
    <Row>
      <Form.Group as={Col} controlId="prod" xs={12}>
        <Form.Label>Produto</Form.Label>
        <ProdSelector
          value={item.prod}
          onChange={(prod) =>
            handleChange({
              prod,
              valor: prod ? prod.preco : 0,
              total: (prod ? prod.preco : 0) * item.qtd,
            })
          }
        />
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col} controlId="num" xs={12}>
        <Form.Label>CFOP</Form.Label>
        <CfopSelector
          value={item.cfop}
          tipo="S"
          onChange={(cfop) => handleChange({ cfop })}
        />
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col} controlId="qtd" xs={12} md={4}>
        <Form.Label>Quantidade</Form.Label>
        <InputNumber
          placeholder="Quantidade"
          required
          value={item.qtd}
          decimalPlaces={0}
          onChange={(qtd) => handleChange({ qtd, total: item.valor * qtd })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="valor" xs={12} md={4}>
        <Form.Label>Valor Unitário</Form.Label>
        <InputNumber
          required
          placeholder="Valor"
          value={item.valor}
          decimalPlaces={2}
          onChange={(valor) => handleChange({ valor, total: item.qtd * valor })}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="total" xs={12} md={4}>
        <Form.Label>Total</Form.Label>
        <InputNumber
          placeholder="Total"
          value={item.total}
          decimalPlaces={2}
          onChange={(total) =>
            handleChange({
              total,
              valor: item.qtd > 0 ? total / item.qtd : total,
              qtd: item.qtd > 0 ? item.qtd : 1,
            })
          }
        />
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col} controlId="infAdProd" xs={12}>
        <Form.Label>Informações Adicionais</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          value={item.infAdProd}
          onChange={(e) => handleChange({ infAdProd: e.target.value })}
        />
      </Form.Group>
    </Row>
  </>
);

export default ItemForm;
