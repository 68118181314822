import {
  DEST_SELECTOR_REQUESTING,
  DEST_SELECTOR_SUCCESS,
  DEST_SELECTOR_ERROR,
} from "./actions";
import _ from "underscore";

const initialState = {
  isRequesting: false,
  items: [],
  error: "",
};

const reducer = function (state = initialState, action: any) {
  switch (action.type) {
    case DEST_SELECTOR_REQUESTING:
      return {
        ...state,
        isRequesting: true,
        error: "",
      };
    case DEST_SELECTOR_SUCCESS:
      const mergedItems =
        state.items.length > 100
          ? action.payload.items
          : [...state.items, ...action.payload.items];

      return {
        ...state,
        isRequesting: false,
        error: "",
        items: _.uniq(mergedItems, false, (it) => it.cadId),
      };
    case DEST_SELECTOR_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
