import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "underscore";
import { AWSRes } from "../../../redux/types";
import { Banco } from "../types";

type TInitialState = {
  itens: Banco[];
  isLoading: boolean;
  error: string;
};

const initialState: TInitialState = {
  itens: [],
  isLoading: false,
  error: "",
};

const bancoSelectorReducer = createSlice({
  name: "bancoSelector",
  initialState,
  reducers: {
    requestBancos(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
    },
    requestSuccess(state, { payload }: PayloadAction<AWSRes<Banco>>) {
      const mergedItems =
        state.itens.length > 100
          ? payload.items
          : [...payload.items, ...state.itens];

      state.isLoading = false;
      state.itens = _.uniq(mergedItems, false, (it) => it.cadId);
    },
    requestError(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const bancoSelectorActions = bancoSelectorReducer.actions;

export default bancoSelectorReducer;
