import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {
  Alert,
  Col,
  Container,
  Form,
  Row,
  Table,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import LoaderButton from "../../components/LoaderButton";
import {
  CAD_TYPE,
  Prod,
  prodImportarInit,
  prodImportarRequest,
} from "./actions";

type ImportarProps = {
  isImportarRequesting: boolean;
  nimportados: number;
  nerros: number;
  importarError: any;
  prodImportarRequest: (payload: Prod[]) => void;
  prodImportarInit: () => void;
};

type ImportarState = {
  xlsx: Blob;
  wb: any;
  sheetData: any;
  selectedSheet: string;
  headerMap: Record<string, string>;
};

class Importar extends Component<ImportarProps, ImportarState> {
  state = {
    xlsx: null,
    wb: null,
    sheetData: null,
    selectedSheet: "",
    headerMap: {},
  };

  componentDidMount() {
    this.props.prodImportarInit();
  }

  parseXlsx = () => {
    if (!this.state.xlsx) {
      return;
    }
    console.log("reading xlsx");
    try {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const sheetData = XLSX.utils.sheet_to_json(ws, { header: 1 });
        this.setState({ wb, selectedSheet: wsname, sheetData });
        console.log("Readed Workbook");
      };
      reader.readAsBinaryString(this.state.xlsx);
    } catch (error) {
      console.log("error xlsx parse", error);
    }
  };

  renderSheetSelector = () => {
    return (
      <Row>
        <Col>
          <Form.Group controlId="sheet">
            <label htmlFor="sheet">Planilha</label>
            <select
              className="form-control"
              id="sheet"
              value={this.state.selectedSheet}
              onChange={(e) => {
                const ws = this.state.wb.Sheets[e.target.value];
                const sheetData = XLSX.utils.sheet_to_json(ws, { header: 1 });
                this.setState({
                  selectedSheet: e.target.value,
                  sheetData,
                  headerMap: {},
                });
              }}
            >
              {this.state.wb.SheetNames.map((sheet) => (
                <option value={sheet} key={`${sheet}`}>
                  {sheet}
                </option>
              ))}
            </select>
          </Form.Group>
        </Col>
      </Row>
    );
  };

  isValidForSubmit = () => {
    // Check the wb
    if (!this.state.wb) {
      return false;
    }
    const headerMapValues = Object.values(this.state.headerMap);
    if (headerMapValues.indexOf("cod") === -1) {
      return false;
    }
    if (headerMapValues.indexOf("name") === -1) {
      return false;
    }
    if (headerMapValues.indexOf("ean") === -1) {
      return false;
    }
    if (headerMapValues.indexOf("ncm") === -1) {
      return false;
    }
    if (headerMapValues.indexOf("unitType") === -1) {
      return false;
    }

    return true;
  };

  handleHeaderChange = (header, value) => {
    const { headerMap } = this.state;
    headerMap[header] = value;
    this.setState({
      headerMap,
    });
  };

  handleSubmitImport = () => {
    // Create the JSON object array
    if (!this.isValidForSubmit()) {
      return;
    }

    if (this.state.sheetData.length < 1) {
      return <>{this.renderSheetSelector()}</>;
    }
    let headers = Object.keys(this.state.sheetData[0]);
    let readFrom = 1;
    if (headers.length < 2) {
      headers = Object.keys(this.state.sheetData[1]);
      readFrom = 2;
    }
    if (headers.length < 2) {
      headers = Object.keys(this.state.sheetData[2]);
      readFrom = 3;
    }
    if (headers.length < 2) {
      headers = Object.keys(this.state.sheetData[3]);
      readFrom = 4;
    }
    const objects = this.state.sheetData.slice(readFrom).map((row) => {
      if (Object.keys(row).length < 2) {
        return undefined;
      }
      const obj = {
        cadType: CAD_TYPE,
        cod: "",
        name: "",
        ean: "",
        ncm: "",
        cest: "",
        unitType: "",
        preco: 0,
        orig: 0,
      };
      for (let key in this.state.headerMap) {
        if (Object.keys(obj).indexOf(this.state.headerMap[key]) >= 0) {
          obj[this.state.headerMap[key]] = row[key];
        }
      }
      return obj;
    });
    console.log(
      "objects",
      objects.filter((obj) => obj !== undefined)
    );
    this.props.prodImportarRequest(objects);
  };

  renderXlsxParsed = () => {
    if (!this.state.wb) {
      return null;
    }

    let data = this.state.sheetData;
    if (data.length < 1) {
      return <>{this.renderSheetSelector()}</>;
    }
    let headers = Object.keys(data[0]);
    let readFrom = 1;
    if (headers.length < 2) {
      headers = Object.keys(data[1]);
      readFrom = 2;
    }
    if (headers.length < 2) {
      headers = Object.keys(data[2]);
      readFrom = 3;
    }
    if (headers.length < 2) {
      headers = Object.keys(data[3]);
      readFrom = 4;
    }
    const headersName = data[readFrom - 1];
    const totalItens = data.length - readFrom;

    return (
      <>
        {this.renderSheetSelector()}
        <Row>
          <Col md={11} sm={11} xs={12} lg={11} xl={11}>
            <p>Confirme para importar {totalItens} produtos.</p>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1} xl={1}>
            <LoaderButton
              isLoading={this.props.isImportarRequesting}
              disabled={!this.isValidForSubmit()}
              variant="primary"
              onClick={this.handleSubmitImport}
            >
              Concluir
            </LoaderButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert variant="dark">
              Abaixo se encontram os primeiros 30 registros a importar,
              selecione qual coluna corresponde a qual dado no sistema para
              continuar.
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table variant="dark" striped>
              <thead>
                <tr>
                  {headersName.map((header) => (
                    <th key={`${header}`}>{header}</th>
                  ))}
                </tr>
                <tr>
                  {headers.map((header) => (
                    <th key={`${header}`}>
                      <select
                        value={this.state.headerMap[header]}
                        onChange={(e) =>
                          this.handleHeaderChange(header, e.target.value)
                        }
                        className="form-control"
                      >
                        <option value={undefined}>Selecione um campo</option>
                        <option value="">Nenhum</option>
                        <option value="cod">Código</option>
                        <option value="name">Descrição</option>
                        <option value="ean">EAN</option>
                        <option value="ncm">NCM</option>
                        <option value="cest">CEST</option>
                        <option value="unitType">Unidade</option>
                        <option value="preco">Preço</option>
                        <option value="orig">Origem da mercadoria</option>
                      </select>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.slice(readFrom, 30).map((row, i) => (
                  <tr key={`${i}`}>
                    {headers.map((header) => (
                      <td key={`${header}`}>{`${row[header]}`}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    if (this.props.nimportados > 0) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <h2>
                Importados {this.props.nimportados} com sucesso, e{" "}
                {this.props.nerros} não puderam ser importados!
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => this.props.prodImportarInit()}
              >
                Importar outro arquivo
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container fluid>
        <Row>
          <Col>
            <h2>Importar produtos do excel</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert variant="warning">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              AVISO: Ao importar os produtos pode ser que o sistema importe a
              maioria e falhe ao importar alguns dos itens!
            </Alert>
          </Col>
        </Row>
        {!!this.props.importarError && (
          <Row>
            <Col>
              <Alert variant="danger">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                Ocorreu um erro ao importar! Detalhes:{" "}
                {this.props.importarError}
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Form.Group as={Col} controlId="xlsx">
              <label htmlFor="xlsx" className="btn btn-secondary">
                Selecionar um arquivo XLSX
              </label>
              <input
                accept=".xlsx"
                type="file"
                onChange={(e) =>
                  this.setState({ xlsx: e.target.files[0] }, this.parseXlsx)
                }
                style={{ visibility: "hidden" }}
                id="xlsx"
              />
              {this.state.xlsx &&
                `Arquivo selecionado: ${this.state.xlsx.name}`}
            </Form.Group>
          </Col>
        </Row>
        {this.renderXlsxParsed()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isImportarRequesting: state.prod.list.isImportarRequesting,
    nimportados: state.prod.list.nimportados,
    nerros: state.prod.list.nerros,
    importarError: state.prod.list.importarError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    prodImportarRequest: (payload) => dispatch(prodImportarRequest(payload)),
    prodImportarInit: () => dispatch(prodImportarInit()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Importar);
