import { servicesFormActions } from "./reducer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { Emissor } from "../../emissor/reducer";
import { Service } from "../type";

export const newService = (emissor: Emissor | null = null): Service => ({
  createdAt: Date.now(),
  descricao: "",
  dest: null,
  emission: new Date(),
  emissor,
  geraCobranca: false,
  invoiceId: "",
  lsi1: "",
  lsi2: "",
  lsi3: "",
  lsi4: "",
  version: 1,
  items: [],
  orgId: "",
  serie: null,
  status: "OPEN",
  tipoop: null,
});

const getDefaultEmit = (state) => state.login.defaultEmit;

function* servicesInitWorker({
  payload,
}: PayloadAction<{ invoiceId: string; duplicateId: string }>) {
  try {
    if (payload.invoiceId === "new") {
      if (payload.duplicateId) {
        const res: Service = yield call(
          executeApiRequest,
          `/service/${payload.duplicateId}`,
          "GET",
          {},
          {}
        );
        delete res.invoiceId;
        delete res.orgId;
        delete res.lsi1;
        delete res.lsi2;
        delete res.lsi3;
        delete res.lsi4;
        delete res.NFsGerada;
        delete res.NFsAutorizada;
        delete res.numeroNfs;
        delete res.serieNFs;
        delete res.verificacaoNFs;

        res.num = "";
        res.emission = new Date();
        yield put(servicesFormActions.serviceSuccess({ ...res }));
      } else {
        const defaultEmit: Emissor = yield select(getDefaultEmit);
        yield put(servicesFormActions.serviceSuccess(newService(defaultEmit)));
      }
    } else {
      console.log("service request", payload);
      const res = yield call(
        executeApiRequest,
        `/service/${payload.invoiceId}`,
        "GET",
        {},
        {}
      );
      yield put(servicesFormActions.serviceSuccess(res));
    }
  } catch (error) {
    yield put(servicesFormActions.serviceError(error));
  }
}

function* servicesSaveWorker({ payload }: PayloadAction<Service>) {
  try {
    if (payload.invoiceId) {
      // Update
      const res = yield call(
        executeApiRequest,
        `/service/${payload.invoiceId}`,
        "PUT",
        payload
      );
      yield put(servicesFormActions.saveSuccess(res));
    } else {
      const res = yield call(executeApiRequest, "/service", "POST", payload);
      yield put(servicesFormActions.saveSuccess(res));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(servicesFormActions.saveError(error.response.data));
  }
}

export default function* servicesFormSaga() {
  yield all([
    takeLatest("servicesForm/serviceRequest", servicesInitWorker),
    takeEvery("servicesForm/saveRequest", servicesSaveWorker),
  ]);
}
