import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faSync,
  faPrint,
  faWindowClose,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "../../../misc/formatters";
import ShowError from "../../../components/ShowError";
import DownloadDanfeButton from "../DownloadDanfeButton";
import CancelaNfeButton from "../CancelaNfeButton";
import EnviarNfeButton from "../EnviarNfeButton";
import { Sale } from "../types";
import { quicksalesActions } from "./reducer";

type QsCardProps = {
  sale: Sale;
  isUpdateRequesting: boolean;
  quicksaleUpdateRequest: (invoiceId: string) => void;
};
type QsCardState = {
  isErrosOpened: boolean;
};

class QsCard extends Component<QsCardProps, QsCardState> {
  state = {
    isErrosOpened: false,
  };

  render() {
    return (
      <Card bg="dark" text="light" className="mt-2">
        <Card.Body>
          <Card.Title>
            Venda Nº {this.props.sale.num}
            <Icon
              spin={this.props.isUpdateRequesting}
              onClick={() =>
                this.props.quicksaleUpdateRequest(this.props.sale.invoiceId)
              }
              icon={faSync}
              style={{ cursor: "pointer" }}
              className="float-right"
            />
          </Card.Title>
          {this.props.sale.items.map((item, j) => (
            <Card.Text key={`${j}`}>
              {formatNumber(item.qtd, 0)} x {item.prod.cod} - {item.prod.name} $
              {formatNumber(item.total, 2)}
            </Card.Text>
          ))}
          <Card.Text>
            Total:{" "}
            {formatNumber(
              this.props.sale.items.reduce((p, c) => p + c.total, 0),
              2
            )}
          </Card.Text>
          <Card.Text>
            Status: {!this.props.sale.NFe && "Não gerado NFCe"}
            {this.props.sale.NFe &&
              this.props.sale.NFe.cancelado === true &&
              "NFCe cancelada"}
            {this.props.sale.NFe &&
              this.props.sale.NFe.autorizado === true &&
              !this.props.sale.NFe.cancelado &&
              "NFCe Autorizada"}
            {this.props.sale.NFe &&
              !this.props.sale.NFe.autorizado &&
              !this.props.sale.NFe.cancelado &&
              "NFCe não autorizada"}
          </Card.Text>
          {/* Buttons for Print, Emitir, Ver erros */}
          {this.props.sale.NFe &&
            !this.props.sale.NFe.autorizado &&
            this.props.sale.NFe.erros && (
              <Button
                onClick={() =>
                  this.setState({ isErrosOpened: !this.state.isErrosOpened })
                }
                variant="danger"
              >
                <Icon icon={this.state.isErrosOpened ? faEyeSlash : faEye} />
              </Button>
            )}
          {this.props.sale.NFe &&
            !this.props.sale.NFe.autorizado &&
            this.props.sale.NFe.erros &&
            this.state.isErrosOpened && (
              <ShowError error={this.props.sale.NFe.erros} />
            )}
          {this.props.sale.NFe && !this.props.sale.NFe.autorizado && (
            <EnviarNfeButton
              sale={this.props.sale}
              onSuccessDispatch={() =>
                this.props.quicksaleUpdateRequest(this.props.sale.invoiceId)
              }
              variant="success"
            >
              <Icon icon={faUpload} />
            </EnviarNfeButton>
          )}
          {this.props.sale.NFe &&
            this.props.sale.NFe.autorizado === true &&
            !this.props.sale.NFe.cancelado && (
              <DownloadDanfeButton sale={this.props.sale} variant="secondary">
                <Icon icon={faPrint} />
              </DownloadDanfeButton>
            )}
          {this.props.sale.NFe &&
            this.props.sale.NFe.autorizado === true &&
            !this.props.sale.NFe.cancelado && (
              <CancelaNfeButton
                sale={this.props.sale}
                onSuccessDispatch={() =>
                  this.props.quicksaleUpdateRequest(this.props.sale.invoiceId)
                }
                variant="danger"
              >
                <Icon icon={faWindowClose} />
              </CancelaNfeButton>
            )}
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isUpdateRequesting: state.sales.quicksale.isUpdateRequesting,
  };
};
const mapDispatchToProps = {
  quicksaleUpdateRequest: quicksalesActions.updateRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(QsCard);
