import moment from "moment";
import ufmap from "./ufmap";
import json2xml from "./json2xml";
import { getTaxByNCM } from "./ibpt_api";

function geraIdNFe(sale: any) {
  if (!sale) {
    return {};
  }
  const cnpjemissor = sale.emissor.cnpj.replace(/[^0-9]/g, "");
  const ufemissor = (ufmap[sale.emissor.estate as string] || 0)
    .toString()
    .padStart(2, "0");
  const anomes = moment(sale.emission).format("YYMM");
  const modelo = sale.modelo || "55";
  const serie = `${sale.serie.cod}`
    .replace(/[^0-9]/g, "")
    .padStart(3, "0")
    .substring(0, 3);
  const nNf = `${sale.num}`
    .replace(/[^0-9]/g, "")
    .padStart(9, "0")
    .substring(0, 9);
  const tpEmis = "1"; // NFe normal
  const randomNumber = `${(Math.random() * 100000000).toFixed(0)}`
    .replace(/[^0-9]/g, "")
    .padStart(8, "0")
    .substring(0, 8);
  console.log("ufemissor", ufemissor);
  console.log("anomes", anomes);
  console.log("cnpjemissor", cnpjemissor);
  console.log("modelo", modelo);
  console.log("serie", serie);
  console.log("nNf", nNf);
  console.log("tpEmis", tpEmis);
  console.log("randomNumber", randomNumber);
  const chNfe = `${ufemissor}${anomes}${cnpjemissor}${modelo}${serie}${nNf}${tpEmis}${randomNumber}`;
  console.log("chNfe", chNfe);
  let mult = 1;
  const chNfespl = chNfe.split("").reduceRight((p, n, i) => {
    mult += 1;
    if (mult > 9) {
      mult = 2;
    }
    return p + parseInt(n) * mult;
  }, 0);
  console.log("cnNfespl", chNfespl);
  const dvNfe = chNfespl % 11 > 1 ? 11 - (chNfespl % 11) : 0;
  console.log("dvNfe", dvNfe);
  return {
    chNFe: `${chNfe}${dvNfe}`,
    cNf: randomNumber,
    nNf,
    ufemissor,
    serie,
    dvNfe,
    cnpjemissor,
  };
}
// NFe41200881442014000167550010000000011484386029
function formatNumber(number: number, decimals = 2) {
  if (number === undefined) {
    return undefined;
  }
  return (number * 1).toFixed(decimals);
}

const gnfe = async (sale: any) => {
  if (!sale) {
    return {};
  }
  if (sale.NFe && sale.NFe.autorizado === true) {
    throw new Error("Ja existe uma NF autorizada para esta venda!");
  }
  const { chNFe, cNf, nNf, ufemissor, serie, dvNfe, cnpjemissor } =
    geraIdNFe(sale);
  if (
    !chNFe ||
    !cNf ||
    !nNf ||
    !ufemissor ||
    !serie ||
    !Number.isInteger(dvNfe) ||
    !cnpjemissor
  ) {
    throw new Error(`Erro ao calcular chave da NFe!`);
  }

  const idDest = !sale.dest
    ? 1
    : sale.emissor.estate === sale.dest.estate
    ? 1
    : 2;
  console.log("chNFe", chNFe.length);

  let dest: Record<string, any> | undefined = {};
  console.log("dest", sale.dest);
  if (!!sale.dest) {
    if (sale.dest.type === 1) {
      // CPF
      dest = {
        CPF: sale.dest.cpf.replace(/[^0-9]/g, ""), // CPF do destinatario
        xNome:
          sale.emissor.modoNFe !== 0
            ? "NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL"
            : sale.dest.name, // Razao Social Opcional
        enderDest: {
          xLgr: sale.dest.address, // Logradouro
          nro: sale.dest.addressNumber, // Numero
          xCpl: sale.dest.complemento, // Complemento Opcional
          xBairro: sale.dest.bairro, // Bairro
          cMun: sale.dest.cmun, // Codigo de Municipio
          xMun: sale.dest.city, // Nome do Municipio
          UF: sale.dest.estate, // UF do emitente
          CEP: sale.dest.zipCode && sale.dest.zipCode.replace(/[^0-9]/g, ""), // CEP do emitente
          fone: sale.dest.phone && sale.dest.phone.replace(/[^0-9]/g, ""), // Fone com DDD Opcional
        },
        indIEDest: 9, // Tipo de IE 1 - Normal, 2 - Isento, 3 - Nao contribuinte
        email: sale.dest.email, // email Opcional
      };
    } else {
      // CNPJ
      dest = {
        CNPJ: sale.dest.cnpj.replace(/[^0-9]/g, ""), // CNPJ do destinatario
        xNome:
          sale.emissor.modoNFe !== 0
            ? "NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL"
            : sale.dest.name, // Razao Social Opcional
        enderDest: {
          xLgr: sale.dest.address, // Logradouro
          nro: sale.dest.addressNumber, // Numero
          xCpl: sale.dest.complemento, // Complemento Opcional
          xBairro: sale.dest.bairro, // Bairro
          cMun: sale.dest.cmun, // Codigo de Municipio
          xMun: sale.dest.city, // Nome do Municipio
          UF: sale.dest.estate, // UF do emitente
          CEP: sale.dest.zipCode.replace(/[^0-9]/g, ""), // CEP do emitente
          fone: sale.dest.phone.replace(/[^0-9]/g, ""), // Fone com DDD Opcional
        },
        indIEDest: sale.dest.indIe, // Tipo de IE 1 - Normal, 2 - Isento, 3 - Nao contribuinte
        email: sale.dest.email, // email Opcional
      };
      if (sale.dest.indIe * 1 === 1) {
        dest.IE = sale.dest.ie;
      }
      if (!!sale.dest.isuf) {
        dest.ISUF = sale.dest.isuf;
      }
      if (!!sale.dest.im) {
        dest.IM = sale.dest.im;
      }
    }
  } else {
    dest = undefined;
  }

  const detP = sale.items.map(async (item: any, i: number): Promise<any> => {
    const ICMS: Record<string, any> = {};
    const PIS: Record<string, any> = {};
    const COFINS: Record<string, any> = {};
    let aproxTrib = 0;
    if (sale.modelo === "65") {
      aproxTrib = await getTaxByNCM(
        item.prod.ncm,
        sale.dest ? sale.dest.estate : sale.emissor.estate
      );
    }
    if (sale.emissor.regime === 1) {
      // Simple nacional
      switch (item.csosn) {
        case "101":
          ICMS.ICMSSN101 = {
            // Para CSOSN 101
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CSOSN: "101", // Tributada pelo simples nacional com permissao de credito
            pCredSN: formatNumber(item.pCredSN), // Aliquota de credito do simples nacional
            vCredICMSSN: formatNumber(item.vCredSN), // Valor do credito a ser aproveitado
          };
          break;
        case "102":
        case "103":
        case "300":
        case "400":
          ICMS.ICMSSN102 = {
            // Para CSOSN 102, 103, 300 ou 400
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CSOSN: item.csosn,
          };
          break;
        case "201":
          ICMS.ICMSSN201 = {
            // Para CSOSN 201
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CSOSN: "201",
            // Modalidade do ICMS ST
            // 0 - Preco tabelado
            // 1 - Lista Negativa
            // 2 - Lista Positiva
            // 3 - Lista Neutra
            // 4 - Margem de Valor Agregado
            // 5 - Pauta (valor)
            // 6 - Valor da Operacao
            modBCST: item.modBcIcmsST,
            pMVAST: formatNumber(item.pMVAST, 4), // Percentual da MVA do ST Opcional
            pRedBCST: formatNumber(item.pRedBCST), // Percentual da reducado da base de ST Opcional
            vBCST: formatNumber(item.bcICMSST), // Base do ICMS ST
            pICMSST: formatNumber(item.pICMSST), // Aliquota do ICMS ST
            vICMSST: formatNumber(item.vICMSST), // Valor do ICMS ST
            vBCFCPST: formatNumber(item.bcICMSSTFCP), // Base do FCP de ST Opcional
            pFCPST: formatNumber(item.pICMSSTFCP), // Percentual do FCP de ST Opcional
            vFCPST: formatNumber(item.vICMSSTFCP), // Valor do FCP de ST Opcional
            pCredSN: formatNumber(item.pCredSN), // Aliquota de credito do simples nacional
            vCredICMSSN: formatNumber(item.vCredSN), // Valor do credito a ser aproveitado
          };
          break;
        case "202":
        case "203":
          ICMS.ICMSSN202 = {
            // Para CSOSN 202 ou 203
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CSOSN: item.csosn,
            // Modalidade do ICMS ST
            // 0 - Preco tabelado
            // 1 - Lista Negativa
            // 2 - Lista Positiva
            // 3 - Lista Neutra
            // 4 - Margem de Valor Agregado
            // 5 - Pauta (valor)
            // 6 - Valor da Operacao
            modBCST: `${item.modBcIcmsST}`,
            pMVAST: formatNumber(item.pMVAST), // Percentual da MVA do ST Opcional
            pRedBCST: formatNumber(item.pRedBCST), // Percentual da reducado da base de ST Opcional
            vBCST: formatNumber(item.bcICMSST), // Base do ICMS ST
            pICMSST: formatNumber(item.pICMSST), // Aliquota do ICMS ST
            vICMSST: formatNumber(item.vICMSST), // Valor do ICMS ST
            vBCFCPST: formatNumber(item.bcICMSSTFCP), // Base do FCP de ST Opcional
            pFCPST: formatNumber(item.pICMSSTFCP), // Percentual do FCP de ST Opcional
            vFCPST: formatNumber(item.vICMSSTFCP), // Valor do FCP de ST Opcional
          };
          break;
        case "500":
          ICMS.ICMSSN500 = {
            // Para CSOSN 500
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CSOSN: "500",
            vBCSTRet: formatNumber(item.bcICMSST), // Base do ST Retido
            pST: formatNumber(item.pICMSST), // Aliquota do consumidor final
            vICMSSubstituto: formatNumber(item.vICMSST), // Valor do ICMS proprio cobrado anteriormente
            vICMSSTRet: formatNumber(item.vICMSST), // Valor do ICMS ST Retido anteriormente
          };
          break;
        case "900":
          ICMS.ICMSSN900 = {
            // Para CSOSN 900
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CSOSN: "900",
            modBC: `${item.modBcIcms}`, // Modaldade do ICMS 0 - MVA %, 1 - Pauta (valor), 2 - Preço Tabelado Maximo (valor), 3 - Valor da Operação
            vBC: formatNumber(item.bcIcms), // Base de calculo
            pRedBC: formatNumber(item.pRedBCICMS), // Percentual de reducao da base de calculo Opcional
            pICMS: formatNumber(item.pIcms), // Percentual de ICMS
            vICMS: formatNumber(item.vIcms), // Valor do ICMS
            // Modalidade do ICMS ST
            // 0 - Preco tabelado
            // 1 - Lista Negativa
            // 2 - Lista Positiva
            // 3 - Lista Neutra
            // 4 - Margem de Valor Agregado
            // 5 - Pauta (valor)
            // 6 - Valor da Operacao
            modBCST: `${item.modBcIcmsST}`,
            pMVAST: formatNumber(item.pMVAST), // Percentual da MVA do ST Opcional
            pRedBCST: formatNumber(item.pRedBCST), // Percentual da reducado da base de ST Opcional
            vBCST: formatNumber(item.bcICMSST), // Base do ICMS ST
            pICMSST: formatNumber(item.pICMSST), // Aliquota do ICMS ST
            vICMSST: formatNumber(item.vICMSST), // Valor do ICMS ST
            vBCFCPST: formatNumber(item.bcICMSSTFCP), // Base do FCP de ST Opcional
            pFCPST: formatNumber(item.pICMSSTFCP), // Percentual do FCP de ST Opcional
            vFCPST: formatNumber(item.vICMSSTFCP), // Valor do FCP de ST Opcional
            pCredSN: formatNumber(item.pCredSN), // Aliquota de credito do simples nacional
            vCredICMSSN: formatNumber(item.vCredSN), // Valor do credito a ser aproveitado
          };
          break;
        default:
      }
      PIS.PISOutr = {
        // CST 99
        CST: "99",
        vBC: formatNumber(0), // Valor de Base
        pPIS: formatNumber(0), // Percentual do PIS
        vPIS: formatNumber(0),
      };
      COFINS.COFINSOutr = {
        // Para CST 49, 50, 51, 52, 53, 54, 55, 56, 60, 61, 62, 63, 64, 65, 66, 67, 70, 71, 72, 73, 74, 75, 98, 99
        CST: "99", // CST
        vBC: formatNumber(0), // Base de calculo
        pCOFINS: formatNumber(0), // Aliquota em percentual
        qBCProd: undefined, // Quantidade BC
        vAliqProd: undefined, // Aliquota em valor
        vCOFINS: formatNumber(0), // Valor do COFINS
      };
    } else {
      // Regime Normal ou simple com excesso
      switch (item.cstIcms) {
        case "00":
          ICMS.ICMS00 = {
            // Para CST 00
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CST: "00",
            modBC: item.modBcIcms, // Modaldade do ICMS 0 - MVA %, 1 - Pauta (valor), 2 - Preço Tabelado Maximo (valor), 3 - Valor da Operação
            vBC: item.bcIcms, // Base de calculo
            pICMS: item.pIcms, // Percentual de ICMS
            vICMS: item.vIcms, // Valor do ICMS
            pFCP: item.pFCP, // Percentual FCP Opcional
            vFCP: item.vFCP, // Valor FCP Opcional
          };
          break;
        case "10":
          ICMS.ICMS10 = {
            // Para CST 10
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CST: "10",
            modBC: item.modBcIcms, // Modaldade do ICMS 0 - MVA %, 1 - Pauta (valor), 2 - Preço Tabelado Maximo (valor), 3 - Valor da Operação
            vBC: item.bcIcms, // Base de calculo
            pICMS: item.pIcms, // Percentual de ICMS
            vICMS: item.vIcms, // Valor do ICMS
            vBCFCP: item.bcFCP, // Base de calculo do FCP Opcional
            pFCP: item.pFCP, // Percentual FCP Opcional
            vFCP: item.vFCP, // Valor FCP Opcional
            // Modalidade do ICMS ST
            // 0 - Preco tabelado
            // 1 - Lista Negativa
            // 2 - Lista Positiva
            // 3 - Lista Neutra
            // 4 - Margem de Valor Agregado
            // 5 - Pauta (valor)
            // 6 - Valor da Operacao
            modBCST: item.modBcIcmsST,
            pMVAST: item.pMVAST, // Percentual da MVA do ST Opcional
            pRedBCST: item.pRedBCST, // Percentual da reducado da base de ST Opcional
            vBCST: item.bcICMSST, // Base do ICMS ST
            pICMSST: item.pICMSST, // Aliquota do ICMS ST
            vICMSST: item.vICMSST, // Valor do ICMS ST
            vBCFCPST: item.bcICMSSTFCP, // Base do FCP de ST Opcional
            pFCPST: item.pICMSSTFCP, // Percentual do FCP de ST Opcional
            vFCPST: item.vICMSSTFCP, // Valor do FCP de ST Opcional
          };
          break;
        case "20":
          ICMS.ICMS20 = {
            // Para CST 20
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CST: "20",
            modBC: item.modBcIcms, // Modaldade do ICMS 0 - MVA %, 1 - Pauta (valor), 2 - Preço Tabelado Maximo (valor), 3 - Valor da Operação
            pRedBC: 0, // Reducao da Base de calculo
            vBC: item.bcIcms, // Base de calculo
            pICMS: item.pIcms, // Percentual de ICMS
            vICMS: item.vIcms, // Valor do ICMS
            vBCFCP: item.bcFCP, // Base de calculo do FCP Opcional
            pFCP: item.pFCP, // Percentual FCP Opcional
            vFCP: item.vFCP, // Valor FCP Opcional
          };
          break;
        case "30":
          ICMS.ICMS30 = {
            // Para CST 30
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CST: "30",
            modBCST: item.modBcIcmsST,
            pMVAST: item.pMVAST, // Percentual da MVA do ST Opcional
            pRedBCST: item.pRedBCST, // Percentual da reducado da base de ST Opcional
            vBCST: item.bcICMSST, // Base do ICMS ST
            pICMSST: item.pICMSST, // Aliquota do ICMS ST
            vICMSST: item.vICMSST, // Valor do ICMS ST
            vBCFCPST: item.bcICMSSTFCP, // Base do FCP de ST Opcional
            pFCPST: item.pICMSSTFCP, // Percentual do FCP de ST Opcional
            vFCPST: item.vICMSSTFCP, // Valor do FCP de ST Opcional
          };
          break;
        case "40":
        case "41":
        case "50":
          ICMS.ICMS40 = {
            // Para CST 40, 41, 50, 51
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CST: item.cstIcms,
          };
          break;
        case "51":
          ICMS.ICMS51 = {
            // Para CST 51 depende da UF
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CST: "51",
            modBC: item.modBcIcms, // Modaldade do ICMS 0 - MVA %, 1 - Pauta (valor), 2 - Preço Tabelado Maximo (valor), 3 - Valor da Operação Opcional
            pRedBC: item.pRedBCICMS, // Percentual de reducao de base de calculo Opcional
            vBC: item.bcIcms, // Base de calculo Opcional
            pICMS: item.pIcms, // Percentual de ICMS Opcional
            vICMSOp: 0, // Valor do ICMS da Operação Opcional
            pDif: 0, // Percentual do Diferimento Opcional
            vICMSDif: 0, // Valor do ICMS Diferido Opcional
            vICMS: item.vIcms, // Valor do ICMS Opcional
            vBCFCP: item.bcFCP, // Base de calculo do FCP Opcional
            pFCP: item.pFCP, // Percentual FCP Opcional
            vFCP: item.vFCP, // Valor FCP Opcional
          };
          break;
        case "60":
          ICMS.ICMS60 = {
            // Para CST 60
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CST: "60",
            // Bloco Opcional
            vBCSTRet: 0, // Base do ST Retido
            pST: 0, // Aliquota do consumidor final
            vICMSSubstituto: 0, // Valor do ICMS proprio cobrado anteriormente
            vICMSSTRet: 0, // Valor do ICMS ST Retido anteriormente
            // Bloco Opcional
            vBCFCPSTRet: 0, // Valor do ICMS FCP Retido anteriomente
            pFCPSTRet: 0, // Percentual do FCP Retido anteriormente
            vFCPSTRet: 0, // Valor do FCP Retido anteriomente
            // Bloco Opcional
            pRedBCEfet: 0, // Percentual de reducao da base de calculo efetiva
            vBCEfet: 0, // Base de calculo efetiva
            pICMSEfet: 0, // Percentual de ICMS efetivo
            vICMSEfet: 0, // Valor de ICMS efetivo
          };
          break;
        case "70":
          ICMS.ICMS70 = {
            // Para CST 70
            orig: `${item.prod.orig || 0}`, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CST: "70",
            modBC: item.modBcIcms, // Modaldade do ICMS 0 - MVA %, 1 - Pauta (valor), 2 - Preço Tabelado Maximo (valor), 3 - Valor da Operação
            pRedBC: item.pRedBCICMS, // Percentual de reducao da base de calculo
            vBC: item.bcIcms, // Base de calculo Opcional
            pICMS: item.pIcms, // Percentual de ICMS Opcional
            vICMS: item.vIcms, // Valor do ICMS Opcional
            vBCFCP: item.bcFCP, // Base de calculo do FCP Opcional
            pFCP: item.pFCP, // Percentual FCP Opcional
            vFCP: item.vFCP, // Valor FCP Opcional
            modBCST: item.modBcIcmsST,
            pMVAST: item.pMVAST, // Percentual da MVA do ST Opcional
            pRedBCST: item.pRedBCST, // Percentual da reducado da base de ST Opcional
            vBCST: item.bcICMSST, // Base do ICMS ST
            pICMSST: item.pICMSST, // Aliquota do ICMS ST
            vICMSST: item.vICMSST, // Valor do ICMS ST
            vBCFCPST: item.bcICMSSTFCP, // Base do FCP de ST Opcional
            pFCPST: item.pICMSSTFCP, // Percentual do FCP de ST Opcional
            vFCPST: item.vICMSSTFCP, // Valor do FCP de ST Opcional
          };
          break;
        case "90":
          ICMS.ICMS90 = {
            // Para CST 90
            orig: 0, // Origem da mercadoria 0 - Nacional, 1 - Estrangeira Importacao Direta, 2 - Estrageira Adquirida no mercado interno
            CST: "90",
            modBC: item.modBcIcms, // Modaldade do ICMS 0 - MVA %, 1 - Pauta (valor), 2 - Preço Tabelado Maximo (valor), 3 - Valor da Operação
            vBC: item.bcIcms, // Base de calculo Opcional
            pRedBC: item.pRedBCICMS, // Percentual de reducao da base de calculo
            pICMS: item.pIcms, // Percentual de ICMS Opcional
            vICMS: item.vIcms, // Valor do ICMS Opcional
            vBCFCP: item.bcFCP, // Base de calculo do FCP Opcional
            pFCP: item.pFCP, // Percentual FCP Opcional
            vFCP: item.vFCP, // Valor FCP Opcional
            // Modalidade do ICMS ST
            // 0 - Preco tabelado
            // 1 - Lista Negativa
            // 2 - Lista Positiva
            // 3 - Lista Neutra
            // 4 - Margem de Valor Agregado
            // 5 - Pauta (valor)
            // 6 - Valor da Operacao
            modBCST: item.modBcIcmsST,
            pMVAST: item.pMVAST, // Percentual da MVA do ST Opcional
            pRedBCST: item.pRedBCST, // Percentual da reducado da base de ST Opcional
            vBCST: item.bcICMSST, // Base do ICMS ST
            pICMSST: item.pICMSST, // Aliquota do ICMS ST
            vICMSST: item.vICMSST, // Valor do ICMS ST
            vBCFCPST: item.bcICMSSTFCP, // Base do FCP de ST Opcional
            pFCPST: item.pICMSSTFCP, // Percentual do FCP de ST Opcional
            vFCPST: item.vICMSSTFCP, // Valor do FCP de ST Opcional
          };
          break;
        default:
      }
      switch (item.piscst) {
        case "01":
        case "02":
          PIS.PISAliq = {
            // CST 01 e 02
            CST: item.piscst,
            vBC: item.bcPIS, // Valor de Base
            pPIS: item.pPIS, // Percentual do PIS
            vPIS: item.PIS,
          };
          break;
        case "03":
          PIS.PISQtde = {
            // CST 03
            CST: "03",
            qBCProd: 0, // Quantidade de Base
            vAliqProd: 0, // Valor de aliquota
            vPIS: item.PIS,
          };
          break;
        case "04":
        case "06":
        case "07":
        case "08":
        case "09":
          PIS.PISNT = {
            // CST 04, 06, 07, 08, 09
            CST: item.piscst,
          };
          break;
        default:
          PIS.PISOutr = {
            // CST 99
            CST: item.piscst,
            vBC: item.bcPIS, // Valor de Base
            pPIS: item.pPIS, // Percentual do PIS
            qBCProd: 0, // Quantidade de Base
            vAliqProd: 0, // Valor de aliquota
            vPIS: item.PIS,
          };
      }
      switch (item.cstcofins) {
        case "01":
        case "02":
          COFINS.COFINSAliq = {
            // Para CST 01 e 02
            CST: item.cstcofins, // CST
            vBC: item.bcCOFINS, // Base de calculo
            pCOFINS: item.pCOFINS, // Aliquota em percentual
            vCOFINS: item.COFINS, // Valor do COFINS
          };
          break;
        case "03":
          COFINS.COFINSQtde = {
            // Para CST 03
            CST: "03", // CST
            qBCProd: 0, // Quantidade BC
            vAliqProd: 0, // Aliquota em valor
            vCOFINS: item.COFINS, // Valor do COFINS
          };
          break;
        case "04":
        case "06":
        case "07":
        case "08":
        case "09":
          COFINS.COFINSNT = {
            // Para CST 04,06,07,08,09
            CST: item.cstcofins, // CST
          };
          break;
        default:
          COFINS.COFINSOutr = {
            // Para CST 49, 50, 51, 52, 53, 54, 55, 56, 60, 61, 62, 63, 64, 65, 66, 67, 70, 71, 72, 73, 74, 75, 98, 99
            CST: item.cstcofins, // CST
            vBC: item.bcCOFINS, // Base de calculo
            pCOFINS: item.pCOFINS, // Aliquota em percentual
            qBCProd: 0, // Quantidade BC
            vAliqProd: 0, // Aliquota em valor
            vCOFINS: item.COFINS, // Valor do COFINS
          };
      }
    }
    const IPI: Record<string, any> = {
      CNPJProd: item.cnpjProd ? item.cnpjProd : null, // Se o CNPJ emitente não é o fabricante caso de exportacao
      cEnq: item.cenq || "999", // Codigo de enquadramento do IPI
    };
    switch (item.cstipi) {
      case "00":
      case "49":
      case "50":
      case "99":
        IPI.IPITrib = {
          // Para CST 00, 49, 50, 99
          CST: item.cstipi,
          vBC: item.bcIPI, // Base do IPI
          pIPI: item.pIPI, // Percentual IPI
          vIPI: item.IPI, // Valor do IPI
        };
        break;
      default:
        IPI.IPINT = {
          // Para CST 01, 02, 03, 04, 05, 51, 52, 53, 54, 55
          CST: item.cstipi,
        };
    }
    return {
      _attr_: {
        nItem: i + 1, // Numero do item
      },
      prod: {
        cProd: item.prod.cod, // Codigo do produto ou CFOPNNNN caso nao tenha
        cEAN: item.prod.ean ? item.prod.ean : "SEM GTIN", // Codigo EAN ou GTIN do produto
        xProd:
          i === 0 && sale.emissor.modoNFe !== 0
            ? "NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL"
            : item.prod.name, // Nome do produto
        NCM: item.prod.ncm, // NCM de 8 posicoes
        CEST: item.prod.cest, // Codigo especifico de ST Opcional
        CFOP: item.cfop, // Codigo CFOP da operacao
        uCom: item.prod.unitType, // Unidade comercial
        qCom: formatNumber(item.qtd), // Quantidade comercial
        vUnCom: formatNumber(item.valor), // Valor unitario comercial
        vProd: formatNumber(item.total), // Valor total
        cEANTrib: item.prod.ean || "SEM GTIN", // Codigo EAN ou GTIN tributado
        uTrib: item.prod.unitType, // Unidade tributada
        qTrib: formatNumber(item.qtd), // Qtd tributada
        vUnTrib: formatNumber(item.valor), // Valor unitario tributado
        indTot: 1, // Valor total compoe o total da NFe ou 0 para nao compoe
      },
      imposto: {
        vTotTrib:
          sale.modelo === "65"
            ? Math.round(aproxTrib * item.total) / 100
            : undefined,
        ICMS,
        IPI: sale.modelo === "65" ? undefined : IPI,
        PIS,
        COFINS,
      },
    };
  });
  const det = await Promise.all<any>(detP);
  const vBC =
    sale.emissor.regime === 1
      ? 0
      : sale.items.reduce((a: number, p: any) => a + (p.bcIcms || 0), 0);
  const vICMS =
    sale.emissor.regime === 1
      ? 0
      : sale.items.reduce((a: number, p: any) => a + (p.vIcms || 0), 0);
  const vFCP = sale.items.reduce((a: number, p: any) => a + (p.vFCP || 0), 0);
  const vBCST = sale.items.reduce(
    (a: number, p: any) => a + (p.bcICMSST || 0),
    0
  );
  const vST = sale.items.reduce((a: number, p: any) => a + (p.vICMSST || 0), 0);
  const vFCPST = sale.items.reduce(
    (a: number, p: any) => a + (p.vICMSSTFCP || 0),
    0
  );
  const vProd = sale.items.reduce((a: number, p: any) => a + (p.total || 0), 0);
  const vIPI = sale.items.reduce((a: number, p: any) => a + (p.IPI || 0), 0);
  const vTotTrib = det.reduce(
    (a: number, p: any) => a + (p.imposto.vTotTrib || 0),
    0
  );
  const vPIS =
    sale.emissor.regime === 1
      ? 0
      : sale.items.reduce((a: number, p: any) => a + (p.PIS || 0), 0);
  const vCOFINS =
    sale.emissor.regime === 1
      ? 0
      : sale.items.reduce((a: number, p: any) => a + (p.COFINS || 0), 0);
  const vNF = vProd + vST;

  const transp: Record<string, any> = {
    modFrete: sale.modFrete || "9",
  };

  if (sale.modFrete !== "9" && sale.transp) {
    transp.transporta = {
      CNPJ: sale.transp.cnpj.replace(/[^0-9]/gi, ""),
      xNome: sale.transp.name, // Razao Social opcional
      IE: sale.transp.ie, // IE Opcional
      xEnder: sale.transp.address + ", " + sale.transp.addressNumber, // Endereco Completo Opcional
      xMun: sale.transp.city, // Municipio Opcional
      UF: sale.transp.estate, // UF Opcional
    };
    transp.vol = [
      {
        qVol: sale.vol, // Qtd Volumes Opcional
        esp: sale.especie, // Especie Opcional
      },
    ];
  }

  const NFe: Record<string, any> = {
    _attr_: {
      xmlns: "http://www.portalfiscal.inf.br/nfe",
    },
    infNFe: {
      _attr_: {
        Id: `NFe${chNFe}`,
        versao: "4.00",
      },
      ide: {
        cUF: ufemissor, // UF da emissao
        cNF: cNf, // Numero aleatorio de geracao da NF
        natOp: sale.tipoop.name, // Natureza da Operacao sendo feito Ex: Venda, Devolucao etc.
        mod: sale.modelo || "55", // Modelo 55 - NFe, 65 - NFCe
        serie: parseInt(serie), // 001 - 899
        nNF: parseInt(nNf), // Numero da NF
        dhEmi: moment(sale.modelo === "65" ? new Date() : sale.emission).format(
          "YYYY-MM-DDTHH:mm:ssZ"
        ), // Data e Hora da emissao format AAAA-MM-DDThh:mm:ssTZD 2020-01-20T10:15:15-03:00
        dhSaiEnt:
          sale.modelo === "65"
            ? undefined
            : moment(sale.dtExp).format("YYYY-MM-DDTHH:mm:ssZ"), // Opcional, data de saida ou entrada da mercadoria
        tpNF: 1, // 0 Entrada 1 Saida
        idDest, // Identificacao da operacao 1 - Interna, 2 - Interestadual, 3 - Exterior
        cMunFG: sale.emissor.cmun, // Codigo de municipio da geracao da NF
        tpImp: sale.modelo === "65" ? 5 : 1, // Formato de impressao do DANFe 0 - Sem, 1 - Retrato, 2 - Paisagem, 3 - Simplificado
        tpEmis: 1, // Tipo de emissao 1 - Normal, 2 - Cont FS, 3 - Cont SCAN, 4 - Cont DPEC, 5 - Cont FSDA, 6 - SVC-AN, 7 - SVC-RS, 9 - Offline NFCe
        cDV: `${dvNfe}`, // Digito verificador da chave da NFe
        tpAmb: sale.emissor.modoNFe === 0 ? 1 : 2, // Tipo de ambiente 1 - Producao, 2 - Homologacao
        finNFe: `${sale.tipoop.fin}`, // Finalidade NFe 1 - Normal, 2 - Complementar, 3 - Ajuste, 4 - Devolucao
        indFinal:
          (sale.dest && sale.dest.type === 1) ||
          !sale.dest ||
          sale.modelo === "65"
            ? 1
            : (sale.dest && sale.dest.indFinal) === true
            ? 1
            : 0, // 0 Não é consumidor final, 1 Consumidor Final
        indPres: sale.modelo === "65" ? 1 : 0, // 0 Não se aplica, 1 presencial, 2 internet, 3 teleatendimento, 4 entrega a domicilio, 5 presencial off site, 6 nao presencial outros
        // indIntermed: sale.modelo === "65" ? 0 : null,
        procEmi: 0, // 0 App contribuinte, 1 avulsa pelo fisco, 2 avulsa pelo contribuinte no site, 3 app fisco
        verProc: "sdcks 1.0.0", // Versao do app
      },
      emit: {
        CNPJ: cnpjemissor, // CNPJ do emissor
        xNome:
          sale.emissor.modoNFe !== 0
            ? "NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL"
            : sale.emissor.name, // Razao Social
        xFant:
          sale.emissor.modoNFe !== 0
            ? "NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL"
            : sale.emissor.fant, // Nome fantasia Opcional
        enderEmit: {
          xLgr: sale.emissor.address, // Logradouro
          nro: sale.emissor.addressNumber, // Numero
          xBairro: sale.emissor.bairro, // Bairro
          cMun: sale.emissor.cmun, // Codigo de Municipio
          xMun: sale.emissor.city, // Nome do Municipio
          UF: sale.emissor.estate, // UF do emitente
          CEP: sale.emissor.zipCode.replace(/[^0-9]/g, ""), // CEP do emitente
          fone: sale.emissor.phone && sale.emissor.phone.replace(/[^0-9]/g, ""), // Fone com DDD Opcional
        },
        IE: sale.emissor.ie && sale.emissor.ie.trim(), // Insc. Estadual
        IEST: sale.emissor.iest, // Insc. Estadual para ST Opcional
        IM: sale.emissor.im, // Insc. Municipal (Talvez Opcional)
        CRT: sale.emissor.regime, // Codigo do regime 1 - Simples Nacional, 2 - Simples Nacional Excesso, 3 - Regime Normal
        /*CNAE: sale.emissor.cnae && sale.emissor.cnae.replace(/[^0-9]/g, ''), // CNAE Opcional*/
      },
      dest,
      autXML: undefined,
      det,
      total: {
        ICMSTot: {
          vBC: formatNumber(vBC), // Soma do valor de base do ICMS
          vICMS: formatNumber(vICMS), // Soma do valor de ICMS
          vICMSDeson: 0, // Soma do ICMS Desonerado (nao existe no sistema)
          vFCP: formatNumber(vFCP), // Soma do valor de FCP
          vBCST: formatNumber(vBCST), // Soma da base de ST
          vST: formatNumber(vST), // Soma do valor de ST
          vFCPST: formatNumber(vFCPST), // Soma do FCP de ST
          vFCPSTRet: formatNumber(0), // Soma do FCP de ST Retido
          vProd: formatNumber(vProd), // Soma do valor total de produtos e serviços
          vFrete: formatNumber(0), // Soma do valor total de frete
          vSeg: formatNumber(0), // Soma do valor de seguro
          vDesc: formatNumber(0), // Soma dos descontos
          vII: formatNumber(0), // Soma do II
          vIPI: formatNumber(vIPI), // Soma do IPI
          vIPIDevol: formatNumber(0), // Soma do IPI Devolvido
          vPIS: formatNumber(vPIS), // Soma do PIS
          vCOFINS: formatNumber(vCOFINS), // Soma do COFINS
          vOutro: formatNumber(0), // Soma de outras despesas
          vNF: formatNumber(vNF), // Soma do total liquido da NF
          vTotTrib: sale.modelo === "65" ? formatNumber(vTotTrib) : undefined,
        },
      },
      transp,
      cobr: {}, // Mater ordem
      pag: {
        detPag: {
          indPag: sale.nparcelas > 0 ? "1" : "0",
          tPag: sale.tpag,
          vPag: formatNumber(vNF),
        },
      },
      infAdic: null,
      infRespTec: {
        // Opcional
        CNPJ: "22755957000180",
        xContato: "Nilton Gabriel Bueno Pires", // Nome do contato
        email: "g@sdcks.com", // Email do contato
        fone: "43988504933", // Telefone do contato 439888504933
      },
    },
  };
  if (
    sale.emissor.cnpjEscritorio &&
    sale.emissor.cnpjEscritorio.replace(/[^0-9]/g, "").length === 14
  ) {
    NFe.infNFe.autXML = {
      CNPJ: sale.emissor.cnpjEscritorio.replace(/[^0-9]/g, ""),
    };
  }
  if (sale.tpag !== "90" && sale.parcelas) {
    console.log("sales parcelas", sale.parcelas);
    NFe.infNFe.cobr = {
      // Opcional
      fat: {
        nFat: sale.num,
        vOrig: formatNumber(vNF), // Valor Original
        vDesc: 0, // Valor do desconto
        vLiq: formatNumber(vNF), // Valor Liquido
      },
      dup: sale.parcelas.map((v: any) => [
        {
          nDup: `${v.nParcela}`.padStart(3, "0"), // Numero da duplicada / parcela
          dVenc: moment(v.venc).format("YYYY-MM-DD"), // Data de vencimento
          vDup: formatNumber(v.vParcela), // Valor da duplicata
        },
      ]),
    };
  }
  if (sale.infAdFisco || sale.infCpl) {
    NFe.infNFe.infAdic = {
      infAdFisco: sale.infAdFisco,
      infCpl: sale.infCpl,
    };
  }

  return {
    xml: json2xml("NFe", NFe),
    NFe,
    chNFe,
  };
};

export default gnfe;
