import React from "react";
import { ButtonProps } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Service } from "./type";

export interface IBImprimirNfsProps extends ButtonProps {
  servico: Service;
}

export function BImprimirNfs({ servico, ...props }: IBImprimirNfsProps) {
  return (
    <Button
      href={`http://sertanopolis.simplissweb.com.br/contrib//app/nfse/relatorio.aspx?cnpj=${servico.emissor?.cnpj?.replace(
        /[^0-9]/g,
        ""
      )}&ser=${servico.serieNFs}&inum=${servico.numeroNfs}&icod=${
        servico.verificacaoNFs
      }`}
      target="_blank"
      {...props}
    >
      {props.children}
    </Button>
  );
}
