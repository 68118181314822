import { AWSRes } from "./../../redux/types";
// saga do modulo dest
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  DEST_INIT,
  DEST_DELETE_REQUESTING,
  destSuccess,
  destError,
  destDeleteSuccess,
  destDeleteError,
  pageInit,
  destImportarSuccess,
  destImportarError,
  DEST_IMPORTAR_REQUEST,
  Dest,
} from "./actions";
import formSaga from "./destForm/saga";
import selectorSaga from "./selector/saga";
import executeApiRequest from "../../misc/executeApiRequest";

function destCall(lastKey = null) {
  return executeApiRequest<AWSRes<Dest>>(
    "/dest",
    "GET",
    {},
    {
      queryStringParameters: {
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* destWorker() {
  try {
    const res: AWSRes<Dest> = yield call(destCall);
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: AWSRes<Dest> = yield call(destCall, res.lastKey);
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(destSuccess(res));
  } catch (error) {
    yield put(destError(error));
  }
}

function* destDeleteWorker({ payload }: any) {
  try {
    yield call(executeApiRequest, `/dest/${payload}`, "DELETE", {}, {});
    yield put(destDeleteSuccess());
    yield put(pageInit());
  } catch (error) {
    yield put(destDeleteError(error));
  }
}

function* destImportarWorker({ payload }: any) {
  try {
    const res = yield call(executeApiRequest, "/bulkcad", "POST", payload);
    yield put(destImportarSuccess(res));
  } catch (error) {
    yield put(destImportarError(error));
  }
}

export default function* destSaga() {
  yield all([
    takeLatest(DEST_INIT, destWorker),
    takeLatest(DEST_DELETE_REQUESTING, destDeleteWorker),
    takeLatest(DEST_IMPORTAR_REQUEST, destImportarWorker),
    formSaga(),
    selectorSaga(),
  ]);
}
