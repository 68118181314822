import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { Form } from "react-bootstrap";
import ReactInputMask from "react-input-mask";

type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  mask?: string;
  as?: React.ElementType<any>;
};

export const TextField: React.FC<TextFieldProps> = ({
  label,
  disabled,
  mask,
  as,
  ...props
}) => {
  const [field, { error, touched }] = useField(props);

  if (mask && mask.length > 1) {
    return (
      <>
        <Form.Label>{label}</Form.Label>
        <ReactInputMask
          disabled={disabled}
          mask={mask}
          {...field}
          className={
            touched && error
              ? "form-control text-input is-invalid"
              : "form-control text-input"
          }
        />
        {touched && error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
      </>
    );
  }

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        disabled={disabled}
        {...field}
        as={as}
        isInvalid={touched && error ? true : false}
      />
      {touched && error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};
