// component do modulo cond
import { format, subDays } from "date-fns";
import moment from "moment";
import React, { Component } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "underscore";
import DateSelector from "../../components/DateSelector";
import DeleteButton from "../../components/DeleteButton";
import { LinkButton } from "../../components/LinkButton";
import LoadingContainer from "../../components/LoadingContainer";
import ShowError from "../../components/ShowError";
import ShowSuccess from "../../components/ShowSuccess";
import TableOrCard from "../../components/TableOrCard";
import { formatNumber } from "../../misc/formatters";
import { addNotification } from "../../redux/notificationActions";
import CancelaNfeButton from "./CancelaNfeButton";
import DownloadDanfeButton from "./DownloadDanfeButton";
import EmailNfeButton from "./EmailNfeButton";
import EnviarNfeButton from "./EnviarNfeButton";
import GeraNfeButton from "./GeraNfeButton";
import { PayloadRequest, salesActions } from "./reducer";
import { Sale } from "./types";

type SalesProps = {
  items: Sale[];
  Count: number;
  lastKey: Record<string, string>;
  isRequesting: boolean;
  isDeleting: boolean;
  geraNFeError: string;
  enviarnfeError: string;
  downloadDanfeError: string;
  nfeCancelingError: string;
  sendNfeError: string;
  sendNfeSuccess: string;
  addNotification: (notification: any) => void;
  salesRequest: (payload: PayloadRequest) => void;
  deleteRequest: (sale: Sale) => void;
};

class Sales extends Component<SalesProps, {}> {
  state = {
    showModal: false,
    modalTitle: "",
    modalBody: "",
    modalFooter: "",
    filterString: "",
    filteredStatuses: ["1", "2"],
    tipoPesquisa: 1,
    dataIni: subDays(new Date(), 7),
    dataFim: new Date(),
    numIni: "",
    numFim: "",
  };

  showNFeErrors = (nf) => {
    this.setState({
      showModal: true,
      modalTitle: `Erros da NFe ${nf.num}`,
      modalBody: <pre>{`${nf.NFe.erros.replace(/></g, ">\n<")}`}</pre>,
    });
  };

  componentDidMount() {
    this.props.salesRequest({
      tags: ["NAOAUTORIZADA", "NAOGERADA"],
      modo: "lsi1",
    });
  }

  requestData = () => {
    const tags = this.state.filteredStatuses.map((s) => {
      switch (s) {
        case "1":
          return "NAOGERADA";
        case "2":
          return "NAOAUTORIZADA";
        case "3":
          return "AUTORIZADA";
        case "4":
          return "CANCELADA";
        default:
          return "";
      }
    });
    const modo = this.state.tipoPesquisa === 1 ? "lsi1" : "lsi2";
    this.props.salesRequest({
      tags,
      modo,
      inicio:
        this.state.tipoPesquisa === 1
          ? format(this.state.dataIni, "yyyy_MM_dd")
          : this.state.numIni,
      fim:
        this.state.tipoPesquisa === 1
          ? format(this.state.dataFim, "yyyy_MM_dd")
          : this.state.numFim,
    });
  };

  handleDelete = (item) => {
    this.props.deleteRequest(item);
  };

  getNFeStatus = (nf) => {
    if (!nf.NFe) {
      return "Não gerado NFe";
    }
    if (nf.NFe.cancelado === true) {
      return "NFe cancelada";
    }
    if (nf.NFe.autorizado === true) {
      return "NFe Autorizada";
    }
    return "NFe não autorizada";
  };

  getNumStatus = (nf) => {
    if (!nf.NFe) {
      return "1";
    }
    if (nf.NFe.cancelado === true) {
      return "4";
    }
    if (nf.NFe.autorizado === true) {
      return "3";
    }
    return "2";
  };

  handleMultiChange = (e) => {
    this.setState({
      filteredStatuses: _.map(e.target.selectedOptions, (opt) => opt.value),
    });
  };

  filterVendas = () => {
    const { items } = this.props;
    const rString = new RegExp(`.*${this.state.filterString}.*`, "i");
    const itensFiltered = _.filter(items, (nf) => {
      const filters = [
        rString.test(nf.num),
        nf.emissor && rString.test(nf.emissor.name),
        nf.dest && rString.test(nf.dest.name),
        nf.serie && rString.test(nf.serie.name),
        rString.test(moment(nf.emission).format("DD/MM/YYYY")),
        rString.test(
          formatNumber(
            nf.items ? nf.items.reduce((p, c) => p + c.total, 0) : 0,
            2
          )
        ),
      ];
      return (
        _.reduce(filters, (a, p) => a || p) &&
        this.state.filteredStatuses.indexOf(this.getNumStatus(nf)) !== -1
      );
    });
    return _.sortBy(itensFiltered, (nf) => parseInt(nf.num) * -1);
  };

  render() {
    const headers = [
      {
        label: "#",
        dataIndex: "num",
      },
      {
        label: "Emissor",
        render: (item) => `${item.emissor && item.emissor.name}`,
      },
      {
        label: "Cliente",
        render: (item) => `${item.dest ? item.dest.name : "Sem identificação"}`,
      },
      {
        label: "Série",
        render: (item) => `${item.serie && item.serie.name}`,
      },
      {
        label: "Emissão",
        render: (item) => moment(item.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Valor",
        render: (item) =>
          formatNumber(
            item.items ? item.items.reduce((p, c) => p + c.total, 0) : 0,
            2
          ),
      },
      {
        label: "Status",
        render: (item) => this.getNFeStatus(item),
      },
      {
        label: "Ações",
        render: (item) => {
          const statusN = this.getNumStatus(item);
          console.log("status n", statusN);

          return (
            <ButtonGroup>
              {/* Botoes de Gerar NFe, Autorizar NFe, Imprimir DANFe, Enviar XML e DANFe por email */}
              {statusN === "2" || statusN === "1" ? (
                <GeraNfeButton variant="primary" sale={item} title="Gerar NFe">
                  <i className="fas fa-receipt" />
                </GeraNfeButton>
              ) : null}
              {statusN === "2" ? (
                <EnviarNfeButton
                  variant="success"
                  sale={item}
                  title="Autorizar NFe"
                >
                  <i className="fas fa-upload" />
                </EnviarNfeButton>
              ) : null}
              {statusN === "3" ? (
                <DownloadDanfeButton
                  variant="primary"
                  sale={item}
                  title="Imprimir NFe"
                >
                  <i className="fas fa-print" />
                </DownloadDanfeButton>
              ) : null}
              {statusN === "3" ? (
                <EmailNfeButton
                  variant="success"
                  sale={item}
                  title="Enviar NFe por email"
                >
                  <i className="fas fa-envelope" />
                </EmailNfeButton>
              ) : null}
              {statusN === "3" ? (
                <CancelaNfeButton
                  variant="danger"
                  sale={item}
                  title="Cancelar NFe"
                >
                  <i className="fas fa-window-close" />
                </CancelaNfeButton>
              ) : null}
              {item.NFe && item.NFe.erros && statusN !== "3" ? (
                <Button
                  variant="danger"
                  onClick={() => this.showNFeErrors(item)}
                  title="Ver Erros na Emissão"
                >
                  <i className="fas fa-eye" />
                </Button>
              ) : null}
              <DeleteButton
                isRequesting={this.props.isDeleting}
                onDelete={() => this.handleDelete(item)}
              />
              <LinkButton
                variant="info"
                to={`/sales/${item.invoiceId}`}
                title="Editar NF"
              >
                <i className="fas fa-edit" />
              </LinkButton>
              <LinkButton
                variant="primary"
                to={`/sales/new/${item.invoiceId}`}
                title="Duplicar NFe"
              >
                <i className="fas fa-copy" />
              </LinkButton>
            </ButtonGroup>
          );
        },
      },
    ];

    const filteredItems = this.filterVendas();

    return (
      <>
        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>{this.state.modalFooter}</Modal.Footer>
        </Modal>
        <Row className="mt-3">
          <Col>
            <h2>Painel de notas</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <LinkButton variant="primary" to="/sales/new">
              <i className="fas fa-plus" /> Incluir Venda
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Filtros</h3>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} xs={12} md={4}>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              multiple
              onChange={this.handleMultiChange}
              value={this.state.filteredStatuses}
            >
              <option value={1}>Não gerado NFe</option>
              <option value={2}>Não Autorizada</option>
              <option value={3}>Autorizada</option>
              <option value={4}>Cancelada</option>
            </Form.Control>
          </Form.Group>

          <Col xs={12} md={8}>
            <Row>
              <Form.Group as={Col} xs={12}>
                <Form.Label>Tipo de pesquisa</Form.Label>
                <Form.Select
                  value={this.state.tipoPesquisa}
                  onChange={(e) =>
                    this.setState({ tipoPesquisa: parseInt(e.target.value) })
                  }
                >
                  <option value={1}>Data de Emissão</option>
                  <option value={2}>Numero</option>
                </Form.Select>
              </Form.Group>
            </Row>
            {this.state.tipoPesquisa === 1 && (
              <Row>
                <Form.Group
                  as={Col}
                  xs={12}
                  className="d-flex flex-row align-items-center"
                >
                  <Form.Label className="m-2">De</Form.Label>
                  <DateSelector
                    id="dataIni"
                    value={this.state.dataIni}
                    className="d-inline-flex m-2"
                    onChange={(dataIni) => this.setState({ dataIni })}
                  />
                  <Form.Label className="m-2">a</Form.Label>
                  <DateSelector
                    id="dataFim"
                    value={this.state.dataFim}
                    className="d-inline-flex m-2"
                    onChange={(dataFim) => this.setState({ dataFim })}
                  />
                </Form.Group>
              </Row>
            )}
            {this.state.tipoPesquisa === 2 && (
              <Row>
                <Form.Group
                  as={Col}
                  xs={12}
                  className="d-flex flex-row align-items-center"
                >
                  <Form.Label className="m-2">De</Form.Label>
                  <Form.Control
                    value={this.state.numIni}
                    className="d-inline-flex m-2 w-100 w-md-25"
                    onChange={(e) => this.setState({ numIni: e.target.value })}
                  />{" "}
                  <Form.Label className="m-2">a</Form.Label>
                  <Form.Control
                    value={this.state.numFim}
                    className="d-inline-flex m-2 w-100 w-md-25"
                    onChange={(e) => this.setState({ numFim: e.target.value })}
                  />
                </Form.Group>
              </Row>
            )}
          </Col>
          <Col className="d-flex flex-row justify-content-end mt-2">
            <Button variant="secondary" onClick={this.requestData}>
              <i className="fas fa-sync" /> Pesquisar
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <ShowError error={this.props.geraNFeError} />
        </Row>
        <Row className="mt-3 mb-3">
          <ShowError error={this.props.enviarnfeError} />
        </Row>
        <Row className="mt-3 mb-3">
          <ShowError error={this.props.downloadDanfeError} />
        </Row>
        <Row className="mt-3 mb-3">
          <ShowError error={this.props.nfeCancelingError} />
        </Row>
        <Row className="mt-3 mb-3">
          <ShowError error={this.props.sendNfeError} />
        </Row>
        <Row className="mt-3 mb-3">
          <ShowSuccess message={this.props.sendNfeSuccess} />
        </Row>
        <Row>
          <LoadingContainer isLoading={this.props.isRequesting}>
            <TableOrCard headers={headers} items={filteredItems} />
          </LoadingContainer>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.sales.list.items,
    Count: state.sales.list.Count,
    lastKey: state.sales.list.lastKey,
    isRequesting: state.sales.list.isRequesting,
    isDeleting: state.sales.list.isDeleting,
    geraNFeError: state.sales.list.geraNFeError,
    enviarnfeError: state.sales.list.enviarnfeError,
    downloadDanfeError: state.sales.list.downloadDanfeError,
    nfeCancelingError: state.sales.list.nfeCancelingError,
    sendNfeError: state.sales.list.sendNfeError,
    sendNfeSuccess: state.sales.list.sendNfeSuccess,
  };
};
const mapDispatchToProps = {
  addNotification,
  salesRequest: salesActions.salesRequest,
  deleteRequest: salesActions.deleteRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
