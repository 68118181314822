// actions do modulo invoice
export const COND_INIT = "COND_INIT";
export const COND_REQUESTING = "COND_REQUESTING";
export const COND_SUCCESS = "COND_SUCCESS";
export const COND_ERROR = "COND_ERROR";
export const MOV_TYPE = "MOV_CONDICIONAL";
export const COND_DELETE_REQUESTING = "COND_DELETE_REQUESTING";
export const COND_DELETE_SUCCESS = "COND_DELETE_SUCCESS";
export const COND_DELETE_ERROR = "COND_DELETE_ERROR";

export function pageInit() {
  return {
    type: COND_INIT,
  };
}

export function condRequest() {
  return {
    type: COND_REQUESTING,
  };
}

export function condSuccess(payload: any) {
  return {
    type: COND_SUCCESS,
    payload,
  };
}

export function condError(error: any) {
  return {
    type: COND_ERROR,
    error,
  };
}

export function condDeleteRequest(movId: string) {
  return {
    type: COND_DELETE_REQUESTING,
    payload: movId,
  };
}

export function condDeleteSuccess() {
  return {
    type: COND_DELETE_SUCCESS,
  };
}

export function condDeleteError(error: any) {
  return {
    type: COND_DELETE_ERROR,
    error,
  };
}
