import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderButton from "../../components/LoaderButton";
import { salesActions } from "./reducer";
import { Sale } from "./types";

type DownloadDanfeButtonProps = {
  disabled?: boolean;
  sale: Sale;
  isDownloadingDanfe: boolean;
  downloaddanfeRequest: (payload: Sale) => void;
  variant?: string;
  title?: string;
  children?: React.ReactNode;
};

class DownloadDanfeButton extends Component<DownloadDanfeButtonProps, {}> {
  handleNFeRequest = (e) => {
    e.preventDefault();
    if (!!this.props.sale) {
      this.props.downloaddanfeRequest(this.props.sale);
    }
  };

  render() {
    return (
      <LoaderButton
        {...this.props}
        isLoading={this.props.isDownloadingDanfe}
        disabled={this.props.disabled || !this.props.sale}
        onClick={this.handleNFeRequest}
      >
        {this.props.children}
      </LoaderButton>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isDownloadingDanfe: state.sales.list.isDownloadingDanfe,
  };
};
const mapDispatchToProps = {
  downloaddanfeRequest: salesActions.downloadDanfeRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadDanfeButton);
