import { FormikProps } from "formik";
import React from "react";

type CheckInputProps = {
  name: string;
  label: string;
  formik: FormikProps<any>;
  size: number;
  changeCallback?: (obj: Record<string, any>) => void;
};

const CheckInput: React.FC<CheckInputProps> = ({
  name,
  label,
  formik,
  size,
  changeCallback,
}) => (
  <div
    className={`form-group form-check col-12 col-md-${size} d-flex align-items-center justify-content-center`}
  >
    <input
      type="checkbox"
      className="form-check-input"
      id={`${name}`}
      onChange={() => {
        formik.setFieldValue(name, !formik.values[name]);

        if (typeof changeCallback === "function") {
          changeCallback({ [name]: !formik.values[name] });
        }
      }}
      checked={formik.values[name] === true}
    />
    <label className="form-check-label ps-2" htmlFor={`${name}`}>
      {label}
    </label>
  </div>
);

export default CheckInput;
