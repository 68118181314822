// component do form do modulo transp
import React, { Component } from "react";
import { connect } from "react-redux";
import { formInit, formSave } from "./actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LoadingContainer from "../../../components/LoadingContainer";
import TextInput from "../../../components/FormikComponents/TextInput";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import SelectInput from "../../../components/FormikComponents/SelectInput";
import LoaderButton from "../../../components/LoaderButton";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import { Link } from "@reach/router";
import { Transp } from "../actions";

type TranspFormProps = {
  item: Transp;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  formInit: (cadId: string) => void;
  formSave: (cad: Transp) => void;
  id: string;
};

class TranspForm extends Component<TranspFormProps, {}> {
  state = {};

  componentDidMount() {
    this.props.formInit(this.props.id);
  }

  handleSubmit = (item) => {
    this.props.formSave(item);
  };

  render() {
    const isSaving = this.props.isSaving;

    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>Transportador</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button as={Link} to="/transp" variant="primary">
              Todos os Transportadores
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required(
                "Preenchimento da razão social é obrigatório"
              ),
              cnpj: Yup.string().required(
                "Preenchimento do CNPJ é obrigatório"
              ),
              ie: Yup.string().required("Preenchimento do IE é obrigatório"),
              zipCode: Yup.string().required(
                "Preenchimento do CEP é obrigatório"
              ),
              address: Yup.string().required(
                "Preenchimento do logradouro é obrigatório"
              ),
              addressNumber: Yup.string().required(
                "Preenchimento do numero é obrigatório"
              ),
              city: Yup.string().required(
                "Preenchimento da cidade é obrigatório"
              ),
              estate: Yup.string().required(
                "Preenchimento do estado é obrigatório"
              ),
            })}
          >
            {(props) => {
              const { isValid, handleSubmit } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <TextInput
                      name="name"
                      label="Razão Social"
                      formik={props}
                      size={12}
                    />
                  </Row>
                  <Row>
                    <MaskInput
                      name="cnpj"
                      label="CNPJ"
                      mask="99.999.999/9999-99"
                      formik={props}
                      size={6}
                    />
                    <MaskInput
                      name="ie"
                      label="IE"
                      mask="99999999999999"
                      formik={props}
                      size={6}
                    />
                  </Row>
                  <Row>
                    <MaskInput
                      name="zipCode"
                      label="CEP"
                      mask="99.999-999"
                      formik={props}
                      size={4}
                    />
                    <TextInput
                      name="address"
                      label="Logradouro"
                      formik={props}
                      size={6}
                    />
                    <TextInput
                      name="addressNumber"
                      label="Numero"
                      formik={props}
                      size={2}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      name="city"
                      label="Cidade"
                      formik={props}
                      size={10}
                    />
                    <SelectInput
                      name="estate"
                      label="Estado"
                      formik={props}
                      size={2}
                    >
                      <option>AC</option>
                      <option>AL</option>
                      <option>AP</option>
                      <option>AM</option>
                      <option>BA</option>
                      <option>CE</option>
                      <option>DF</option>
                      <option>ES</option>
                      <option>GO</option>
                      <option>MA</option>
                      <option>MT</option>
                      <option>MS</option>
                      <option>MG</option>
                      <option>PA</option>
                      <option>PB</option>
                      <option>PR</option>
                      <option>PE</option>
                      <option>PI</option>
                      <option>RJ</option>
                      <option>RN</option>
                      <option>RS</option>
                      <option>RO</option>
                      <option>RR</option>
                      <option>SC</option>
                      <option>SP</option>
                      <option>SE</option>
                      <option>TO</option>
                    </SelectInput>
                  </Row>
                  <Row>
                    <Col>
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                      >
                        Salvar
                      </LoaderButton>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </LoadingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    item: state.transp.form.item,
    successMsg: state.transp.form.successMsg,
    error: state.transp.form.error,
    isRequesting: state.transp.form.isRequesting,
    isSaving: state.transp.form.isSaving,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    formInit: (cadId) => dispatch(formInit(cadId)),
    formSave: (cad) => dispatch(formSave(cad)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TranspForm);
