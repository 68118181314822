import { AWSRes } from "./../../redux/types";
import { Dest } from "../dest/actions";

// actions do modulo task
export const TASK_INIT = "TASK_INIT";
export const TASK_REQUESTING = "TASK_REQUESTING";
export const TASK_SUCCESS = "TASK_SUCCESS";
export const TASK_ERROR = "TASK_ERROR";
export const MOV_TYPE = "MOV_TASK";
export const TASK_DELETE_REQUESTING = "TASK_DELETE_REQUESTING";
export const TASK_DELETE_SUCCESS = "TASK_DELETE_SUCCESS";
export const TASK_DELETE_ERROR = "TASK_DELETE_ERROR";
export const TASK_SAVE_REQUESTING = "TASK_SAVE_REQUESTING";
export const TASK_SAVE_SUCCESS = "TASK_SAVE_SUCCESS";
export const TASK_SAVE_ERROR = "TASK_SAVE_ERROR";
export const NEWTASK_SET = "NEWTASK_SET";

export type Task = {
  modId?: string;
  userId?: string;
  movType: "MOV_TASK";
  version?: number;
  dest?: Dest;
  summary: string;
  dateDue?: Date;
  finshedDate?: Date;
};

export function pageInit() {
  return {
    type: TASK_INIT,
  };
}

export function taskRequest() {
  return {
    type: TASK_REQUESTING,
  };
}

export function taskSuccess(payload: AWSRes<Task[]>) {
  return {
    type: TASK_SUCCESS,
    payload,
  };
}

export function taskError(error: any) {
  return {
    type: TASK_ERROR,
    error,
  };
}

export function taskDeleteRequest(movId: string, onFinishAction?: any) {
  return {
    type: TASK_DELETE_REQUESTING,
    payload: movId,
    onFinishAction,
  };
}

export function taskDeleteSuccess() {
  return {
    type: TASK_DELETE_SUCCESS,
  };
}

export function taskDeleteError(error: any) {
  return {
    type: TASK_DELETE_ERROR,
    error,
  };
}

export function taskSaveRequest(task: Task, onFinishAction?: any) {
  return {
    type: TASK_SAVE_REQUESTING,
    payload: task,
    onFinishAction,
  };
}

export function taskSaveSuccess(payload: Task) {
  return {
    type: TASK_SAVE_SUCCESS,
    payload,
  };
}

export function taskSaveError(error: any) {
  return {
    type: TASK_SAVE_ERROR,
    error,
  };
}

export function newtaskSet(task: Partial<Task>) {
  return {
    type: NEWTASK_SET,
    payload: task,
  };
}
