import { Link } from "@reach/router";
import moment from "moment";
import React, { Component } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "underscore";
import LoadingContainer from "../../../components/LoadingContainer";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import { InvoiceParcela, pageInit } from "../actions";
import { clientInvoiceRequest } from "./actions";

type InvoiceViewProps = {
  pageInit: () => void;
  clientInvoiceRequest: (clientId: string) => void;
  items: InvoiceParcela[];
  isRequesting: boolean;
  destId: string;
  orgStatus: string;
};

class InvoiceView extends Component<InvoiceViewProps, {}> {
  state = {};

  componentDidMount() {
    // if (this.props.items.length <= 0) {
    if (this.props.destId) {
      this.props.clientInvoiceRequest(this.props.destId);
    }
    // }
  }

  render() {
    if (!this.props.destId) {
      return null;
    }
    const { orgStatus } = this.props;

    const invoices = _.filter(
      this.props.items,
      (item) => item.dest && item.dest.cadId === this.props.destId
    );

    let parcelsVencer = _.filter(invoices, (parcel) => {
      return (
        moment(parcel.vencimento).isSameOrAfter(new Date(), "day") &&
        parcel.status === "OPEN"
      );
    });
    let parcelsVencidas = _.filter(invoices, (parcel) => {
      return (
        moment(parcel.vencimento).isBefore(new Date(), "day") &&
        parcel.status === "OPEN"
      );
    });
    let parcelsPagas = _.filter(
      invoices,
      (parcel) => parcel.status === "CLOSED"
    );

    parcelsVencer = _.sortBy(parcelsVencer, (parcel) =>
      moment(parcel.vencimento).unix()
    );
    parcelsVencidas = _.sortBy(parcelsVencidas, (parcel) =>
      moment(parcel.vencimento).unix()
    );
    parcelsPagas = _.sortBy(
      parcelsPagas,
      (parcel) => moment(parcel.vencimento).unix() * -1
    );

    const totalVencer = _.reduce(parcelsVencer, (p, c) => p + c.valor, 0);
    const totalVencidas = _.reduce(parcelsVencidas, (p, c) => p + c.valor, 0);
    const totalPagas = _.reduce(parcelsPagas, (p, c) => p + c.valor, 0);

    const headers = [
      {
        label: "#",
        render: (item: InvoiceParcela) => `${item.num}/${item.numparcela}`,
      },
      {
        label: "Emissão",
        render: (item: InvoiceParcela) =>
          moment(item.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Valor",
        render: (item: InvoiceParcela) => formatNumber(item.valor, 2),
      },
      {
        label: "Vencimento",
        render: (item: InvoiceParcela) =>
          moment(item.vencimento).format("DD/MM/YYYY"),
      },
      {
        label: "Status",
        render: (item: InvoiceParcela) =>
          item.status === "OPEN" ? "Em aberto" : "Baixada",
      },
      {
        label: "Ações",
        render: (item: InvoiceParcela) =>
          orgStatus === "ACTIVE" ? (
            <ButtonGroup>
              <Button
                variant="info"
                as={Link}
                to={`/invoice/${item.invoiceId}`}
              >
                <i className="fas fa-edit" />
              </Button>
              {item.status === "OPEN" && (
                <Button
                  variant="light"
                  title="Receber a cobrança"
                  as={Link}
                  to={`/InvoicePay/${item.num}:${item.numparcela}`}
                >
                  <i className="fas fa-cash-register" />
                </Button>
              )}
            </ButtonGroup>
          ) : null,
      },
    ];

    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Cobranças a vencer</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableOrCard headers={headers} items={parcelsVencer} />
            <p>
              <b>Total: {formatNumber(totalVencer, 2)}</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Cobranças vencidas</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableOrCard headers={headers} items={parcelsVencidas} />
            <p>
              <b>Total: {formatNumber(totalVencidas, 2)}</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Cobranças pagas</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableOrCard headers={headers} items={parcelsPagas} />
            <p>
              <b>Total: {formatNumber(totalPagas, 2)}</b>
            </p>
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.invoice.view.items,
    isRequesting: state.invoice.view.isRequesting,
    orgStatus: state.login.orgStatus,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    pageInit: () => dispatch(pageInit()),
    clientInvoiceRequest: (clientId: string) =>
      dispatch(clientInvoiceRequest(clientId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceView);
