import React from "react";
import { Spinner } from "react-bootstrap";

type LoadingContainerProps = {
  isLoading: boolean;
};

const LoadingContainer: React.FC<LoadingContainerProps> = (props) => {
  if (props.isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }
  return <>{props.children}</>;
};

export default LoadingContainer;
