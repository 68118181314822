import { paymentFormActions } from "./reducer";
import { PayloadAction } from "@reduxjs/toolkit";
// saga do form do modulo payment
import { put, all, call, takeLatest, select } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { Emissor } from "../../emissor/reducer";
import { Payment } from "../types";
import PaymentParcela from "../types";
import _ from "underscore";

const getDefaultEmit = (state: any) => state.login.defaultEmit;

const newPayment = (emissor: Emissor = null): Payment => {
  return {
    orgId: "",
    invoiceId: "",
    lsi1: "",
    lsi2: "",
    lsi3: "",
    lsi4: "",
    movType: "MOV_PAYMENT",
    num: "",
    emission: new Date(),
    emissor,
    dest: null,
    valor: 0,
    parcelas: [],
    nparcelas: 0,
  };
};

function* paymentInitWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "new") {
      const defaultEmit = yield select(getDefaultEmit);
      yield put(paymentFormActions.requestSuccess(newPayment(defaultEmit)));
    } else {
      const invoiceIdSep = `${payload}`.split(".");
      if (invoiceIdSep.length < 2) {
        throw new Error(`Id selecionado é inválido`);
      }

      const res: PaymentParcela[] = yield call(
        executeApiRequest,
        `/payment/${invoiceIdSep[0]}.${invoiceIdSep[1]}`,
        "GET",
        {},
        {}
      );
      const uuid = invoiceIdSep.pop();
      const filteredRet: PaymentParcela[] = _.filter(
        res,
        (parcela) => `${parcela.invoiceId}`.split(".").pop() === uuid
      );
      if (filteredRet.length < 1) {
        throw new Error(`Id selecionado não foi encontrado!`);
      }
      const payment: Payment = {
        dest: filteredRet[0].dest,
        emission: filteredRet[0].emission,
        emissor: filteredRet[0].emissor,
        version: filteredRet[0].version,
        orgId: filteredRet[0].orgId,
        num: filteredRet[0].num,
        invoiceId: filteredRet[0].invoiceId,
        lsi1: filteredRet[0].lsi1,
        lsi2: filteredRet[0].lsi2,
        lsi3: filteredRet[0].lsi3,
        lsi4: filteredRet[0].lsi4,
        movType: filteredRet[0].movType,
        valor: filteredRet.reduce((p, c) => p + c.valor, 0),
        nparcelas: filteredRet.length,
        parcelas: filteredRet,
      };
      yield put(paymentFormActions.requestSuccess(payment));
    }
  } catch (error) {
    yield put(paymentFormActions.requestError(error));
  }
}

function* paymentSaveWorker({ payload }: PayloadAction<Payment>) {
  try {
    payload.num = `${payload.num}`.trim();
    const paymentParcelas = payload.parcelas.map((parcela) => {
      parcela.dest = payload.dest;
      parcela.emission = payload.emission;
      parcela.emissor = payload.emissor;
      parcela.num = payload.num;
      parcela.serialCreate = payload.serialCreate;
      return parcela;
    });

    console.log("mov to save", paymentParcelas);
    if (payload.invoiceId) {
      // Update
      const res: PaymentParcela[] = yield call(
        executeApiRequest,
        `/payment/${payload.invoiceId}`,
        "PUT",
        paymentParcelas
      );
      const payment: Payment = {
        dest: res[0].dest,
        emission: res[0].emission,
        emissor: res[0].emissor,
        version: res[0].version,
        orgId: res[0].orgId,
        num: res[0].num,
        invoiceId: res[0].invoiceId,
        lsi1: res[0].lsi1,
        lsi2: res[0].lsi2,
        lsi3: res[0].lsi3,
        lsi4: res[0].lsi4,
        movType: res[0].movType,
        valor: res.reduce((p, c) => p + c.valor, 0),
        nparcelas: res.length,
        parcelas: res,
      };

      yield put(paymentFormActions.saveSuccess(payment));
    } else {
      const res: PaymentParcela[] = yield call(
        executeApiRequest,
        "/payment",
        "POST",
        paymentParcelas
      );
      const payment: Payment = {
        dest: res[0].dest,
        emission: res[0].emission,
        emissor: res[0].emissor,
        version: res[0].version,
        orgId: res[0].orgId,
        num: res[0].num,
        invoiceId: res[0].invoiceId,
        lsi1: res[0].lsi1,
        lsi2: res[0].lsi2,
        lsi3: res[0].lsi3,
        lsi4: res[0].lsi4,
        movType: res[0].movType,
        valor: res.reduce((p, c) => p + c.valor, 0),
        nparcelas: res.length,
        parcelas: res,
      };
      yield put(paymentFormActions.saveSuccess(payment));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(paymentFormActions.saveError(error.response.data));
  }
}

export default function* invoiceFormSaga() {
  yield all([
    takeLatest("paymentForm/initForm", paymentInitWorker),
    takeLatest("paymentForm/requestSave", paymentSaveWorker),
  ]);
}
