import React from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import Csosn from "../../../components/Csosn";
import CstIcms from "../../../components/CstIcms";
import CstIpi from "../../../components/CstIpi";
import CstPisCofins from "../../../components/CstPisCofins";
import InputNumber from "../../../components/InputNumber";
import ModBcIcms from "../../../components/ModBcIcms";
import ModBcIcmsSt from "../../../components/ModBcIcmsSt";
import { formatNumber } from "../../../misc/formatters";
import { Sale, SaleItem } from "../types";

type ImpostoFormProps = {
  item: SaleItem;
  sale: Sale;
  onChange: (obj: Partial<SaleItem>) => void;
};

const ImpostoForm: React.FC<ImpostoFormProps> = (props) => {
  const keyToOpen = props.sale.emissor.regime === 1 ? "1" : "0";
  const { item, onChange } = props;

  return (
    <Accordion defaultActiveKey={keyToOpen}>
      {keyToOpen === "0" ? (
        <Accordion.Item eventKey="0">
          <Accordion.Header>ICMS</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Form.Group as={Col} controlId="cstIcms" xs={12} md={2}>
                <Form.Label>CST ICMS</Form.Label>
                <CstIcms
                  value={item.cstIcms}
                  onChange={(cstIcms) => onChange({ cstIcms })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="modBcIcms" xs={12} md={2}>
                <Form.Label>Modalidade Base do ICMS</Form.Label>
                <ModBcIcms
                  value={item.modBcIcms}
                  onChange={(modBcIcms) => onChange({ modBcIcms })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pRedBCICMS" xs={12} md={2}>
                <Form.Label>Redução da Base do ICMS</Form.Label>
                <InputNumber
                  placeholder="% ICMS"
                  value={item.pRedBCICMS}
                  decimalPlaces={2}
                  onChange={(pRedBCICMS) => onChange({ pRedBCICMS })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="bcIcms" xs={12} md={2}>
                <Form.Label>BC ICMS </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="BC ICMS"
                  disabled
                  value={formatNumber(item.bcIcms, 2)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pIcms" xs={12} md={2}>
                <Form.Label>Aliquota ICMS</Form.Label>
                <InputNumber
                  placeholder="% ICMS"
                  value={item.pIcms}
                  decimalPlaces={2}
                  onChange={(pIcms) => onChange({ pIcms })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="vIcms" xs={12} md={2}>
                <Form.Label>ICMS </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ICMS"
                  disabled
                  value={formatNumber(item.vIcms, 2)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="bcFCP" xs={12} md={4}>
                <Form.Label>Base ICMS FCP</Form.Label>
                <InputNumber
                  placeholder="Base ICMS-FCP"
                  value={item.bcFCP}
                  decimalPlaces={2}
                  onChange={(bcFCP) => onChange({ bcFCP })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pFCP" xs={12} md={4}>
                <Form.Label>Aliquota ICMS FCP</Form.Label>
                <InputNumber
                  placeholder="% ICMS-FCP"
                  value={item.pFCP}
                  decimalPlaces={2}
                  onChange={(pFCP) => onChange({ pFCP })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="vFCP" xs={12} md={4}>
                <Form.Label>ICMS FCP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ICMS"
                  disabled
                  value={formatNumber(item.vFCP, 2)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="modBcIcmsST" xs={12} md={4}>
                <Form.Label>Modalidade Base do ICMS-ST</Form.Label>
                <ModBcIcmsSt
                  value={item.modBcIcmsST}
                  onChange={(modBcIcmsST) => onChange({ modBcIcmsST })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pMVAST" xs={12} md={4}>
                <Form.Label>Percentual MVA</Form.Label>
                <InputNumber
                  placeholder="% MVA"
                  value={item.pMVAST}
                  decimalPlaces={2}
                  onChange={(pMVAST) => onChange({ pMVAST })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pRedBCST" xs={12} md={4}>
                <Form.Label>Redução de Base de ST</Form.Label>
                <InputNumber
                  placeholder="% Redução de Base de ST"
                  value={item.pRedBCST}
                  decimalPlaces={2}
                  onChange={(pRedBCST) => onChange({ pRedBCST })}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="bcICMSST" xs={12} md={4}>
                <Form.Label>Base de ICMS ST</Form.Label>
                <InputNumber
                  placeholder="Base de ICMS ST"
                  value={item.bcICMSST}
                  decimalPlaces={2}
                  onChange={(bcICMSST) => onChange({ bcICMSST })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pICMSST" xs={12} md={4}>
                <Form.Label>Aliquota ICMS ST</Form.Label>
                <InputNumber
                  placeholder="Aliquota ICMS ST"
                  value={item.pICMSST}
                  decimalPlaces={2}
                  onChange={(pICMSST) => onChange({ pICMSST })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="vICMSST" xs={12} md={4}>
                <Form.Label>ICMS ST</Form.Label>
                <InputNumber
                  placeholder="ICMS ST"
                  value={item.vICMSST}
                  decimalPlaces={2}
                  onChange={(vICMSST) => onChange({ vICMSST })}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="bcICMSSTFCP" xs={12} md={4}>
                <Form.Label>Base de ICMS ST FCP</Form.Label>
                <InputNumber
                  placeholder="Base de ICMS ST FCP"
                  value={item.bcICMSSTFCP}
                  decimalPlaces={2}
                  onChange={(bcICMSSTFCP) => onChange({ bcICMSSTFCP })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pICMSSTFCP" xs={12} md={4}>
                <Form.Label>Aliquota ICMS ST FCP</Form.Label>
                <InputNumber
                  placeholder="Aliquota ICMS ST FCP"
                  value={item.pICMSSTFCP}
                  decimalPlaces={2}
                  onChange={(pICMSSTFCP) => onChange({ pICMSSTFCP })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="vICMSSTFCP" xs={12} md={4}>
                <Form.Label>ICMS ST FCP</Form.Label>
                <InputNumber
                  placeholder="ICMS ST FCP"
                  value={item.vICMSSTFCP}
                  decimalPlaces={2}
                  onChange={(vICMSSTFCP) => onChange({ vICMSSTFCP })}
                />
              </Form.Group>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      ) : null}
      {keyToOpen === "1" ? (
        <Accordion.Item eventKey="1">
          <Accordion.Header>Simples Nacional</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Form.Group as={Col} controlId="csosn" xs={12}>
                <Form.Label>CSOSN</Form.Label>
                <Csosn
                  value={item.csosn}
                  onChange={(csosn) => onChange({ csosn })}
                />
              </Form.Group>
            </Row>
            {["101", "201", "900"].indexOf(item.csosn) !== -1 ? (
              <Row>
                <Form.Group as={Col} controlId="pCredSN" xs={12} md={6}>
                  <Form.Label>
                    Percentual de crédito Simples Nacional
                  </Form.Label>
                  <InputNumber
                    placeholder="Percentual de crédito Simples Nacional"
                    value={item.pCredSN}
                    decimalPlaces={2}
                    onChange={(pCredSN) =>
                      onChange({
                        pCredSN,
                        vCredSN: (pCredSN * item.total) / 100,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="vCredSN" xs={12} md={6}>
                  <Form.Label>Valor de crédito Simples Nacional</Form.Label>
                  <InputNumber
                    placeholder="Valor de crédito Simples Nacional"
                    value={item.vCredSN}
                    decimalPlaces={2}
                    onChange={(vCredSN) => onChange({ vCredSN })}
                  />
                </Form.Group>
              </Row>
            ) : null}
            {["201", "202", "203", "500", "900"].indexOf(item.csosn) !== -1 ? (
              <>
                <Row>
                  <Form.Group as={Col} controlId="modBcIcmsST" xs={12} md={4}>
                    <Form.Label>Modalidade Base do ICMS-ST</Form.Label>
                    <ModBcIcmsSt
                      value={item.modBcIcmsST}
                      onChange={(modBcIcmsST) => onChange({ modBcIcmsST })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="pMVAST" xs={12} md={4}>
                    <Form.Label>Percentual MVA</Form.Label>
                    <InputNumber
                      placeholder="% MVA"
                      value={item.pMVAST}
                      decimalPlaces={2}
                      onChange={(pMVAST) => onChange({ pMVAST })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="pRedBCST" xs={12} md={4}>
                    <Form.Label>Redução de Base de ST</Form.Label>
                    <InputNumber
                      placeholder="% Redução de Base de ST"
                      value={item.pRedBCST}
                      decimalPlaces={2}
                      onChange={(pRedBCST) => onChange({ pRedBCST })}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="bcICMSST" xs={12} md={4}>
                    <Form.Label>Base de ICMS ST</Form.Label>
                    <InputNumber
                      placeholder="Base de ICMS ST"
                      value={item.bcICMSST}
                      decimalPlaces={2}
                      onChange={(bcICMSST) => onChange({ bcICMSST })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="pICMSST" xs={12} md={4}>
                    <Form.Label>Aliquota ICMS ST</Form.Label>
                    <InputNumber
                      placeholder="Aliquota ICMS ST"
                      value={item.pICMSST}
                      decimalPlaces={2}
                      onChange={(pICMSST) => onChange({ pICMSST })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="vICMSST" xs={12} md={4}>
                    <Form.Label>ICMS ST</Form.Label>
                    <InputNumber
                      placeholder="ICMS ST"
                      value={item.vICMSST}
                      decimalPlaces={2}
                      onChange={(vICMSST) => onChange({ vICMSST })}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="bcICMSSTFCP" xs={12} md={4}>
                    <Form.Label>Base de ICMS ST FCP</Form.Label>
                    <InputNumber
                      placeholder="Base de ICMS ST FCP"
                      value={item.bcICMSSTFCP}
                      decimalPlaces={2}
                      onChange={(bcICMSSTFCP) => onChange({ bcICMSSTFCP })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="pICMSSTFCP" xs={12} md={4}>
                    <Form.Label>Aliquota ICMS ST FCP</Form.Label>
                    <InputNumber
                      placeholder="Aliquota ICMS ST FCP"
                      value={item.pICMSSTFCP}
                      decimalPlaces={2}
                      onChange={(pICMSSTFCP) => onChange({ pICMSSTFCP })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="vICMSSTFCP" xs={12} md={4}>
                    <Form.Label>ICMS ST FCP</Form.Label>
                    <InputNumber
                      placeholder="ICMS ST FCP"
                      value={item.vICMSSTFCP}
                      decimalPlaces={2}
                      onChange={(vICMSSTFCP) => onChange({ vICMSSTFCP })}
                    />
                  </Form.Group>
                </Row>
              </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      ) : null}
      <Accordion.Item eventKey="2">
        <Accordion.Header>IPI</Accordion.Header>
        <Accordion.Body>
          <Row>
            <Form.Group as={Col} controlId="cenq" xs={12} md={4}>
              <Form.Label>Código Enquadramento IPI</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ex: 99999"
                value={item.cenq}
                onChange={(e) => onChange({ cenq: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="cstipi" xs={12} md={4}>
              <Form.Label>CST IPI</Form.Label>
              <CstIpi
                value={item.cstipi}
                onChange={(cstipi) => onChange({ cstipi })}
                type="S"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="cnpjProd" xs={12} md={4}>
              <Form.Label>CNPJ Produtor</Form.Label>
              <InputMask
                className="form-control"
                mask="99.999.999/9999-99"
                maskPlaceholder=" "
                value={item.cnpjProd}
                onChange={(e) => onChange({ cnpjProd: e.target.value })}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="bcIPI" xs={12} md={4}>
              <Form.Label>BC IPI</Form.Label>
              <Form.Control
                type="text"
                placeholder="BC IPI"
                disabled
                value={formatNumber(item.bcIPI, 2)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="pIPI" xs={12} md={4}>
              <Form.Label>Aliquota do IPI (%)</Form.Label>
              <InputNumber
                placeholder="Aliquota do IPI (%)"
                value={item.pIPI}
                decimalPlaces={2}
                onChange={(pIPI) => onChange({ pIPI })}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="IPI" xs={12} md={4}>
              <Form.Label>IPI</Form.Label>
              <Form.Control
                type="text"
                placeholder="IPI"
                disabled
                value={formatNumber(item.IPI, 2)}
              />
            </Form.Group>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      {keyToOpen === "0" ? (
        <Accordion.Item eventKey="3">
          <Accordion.Header>PIS</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Form.Group as={Col} controlId="cstpis" xs={12}>
                <Form.Label>CST PIS</Form.Label>
                <CstPisCofins
                  value={item.cstpis}
                  onChange={(cstpis) => onChange({ cstpis })}
                  type="S"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="bcPIS" xs={12} md={4}>
                <Form.Label>BC PIS</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="BC PIS"
                  disabled
                  value={formatNumber(item.bcPIS, 2)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pPIS" xs={12} md={4}>
                <Form.Label>Aliquota do PIS (%)</Form.Label>
                <InputNumber
                  placeholder="Aliquota do PIS (%)"
                  value={item.pPIS}
                  decimalPlaces={4}
                  onChange={(pPIS) => onChange({ pPIS })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="PIS" xs={12} md={4}>
                <Form.Label>PIS</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="PIS"
                  disabled
                  value={formatNumber(item.PIS, 2)}
                />
              </Form.Group>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      ) : null}
      {keyToOpen === "0" ? (
        <Accordion.Item eventKey="4">
          <Accordion.Header>COFINS</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Form.Group as={Col} controlId="cstcofins" xs={12}>
                <Form.Label>CST COFINS</Form.Label>
                <CstPisCofins
                  value={item.cstcofins}
                  onChange={(cstcofins) => onChange({ cstcofins })}
                  type="S"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="bcCOFINS" xs={12} md={4}>
                <Form.Label>BC COFINS</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="BC COFINS"
                  disabled
                  value={formatNumber(item.bcCOFINS, 2)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pCOFINS" xs={12} md={4}>
                <Form.Label>Aliquota do COFINS (%)</Form.Label>
                <InputNumber
                  placeholder="Aliquota do COFINS (%)"
                  value={item.pCOFINS}
                  decimalPlaces={4}
                  onChange={(pCOFINS) => onChange({ pCOFINS })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="COFINS" xs={12} md={4}>
                <Form.Label>COFINS</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="COFINS"
                  disabled
                  value={formatNumber(item.COFINS, 2)}
                />
              </Form.Group>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      ) : null}
      <Accordion.Item eventKey="5">
        <Accordion.Header>ICMS UF de destino</Accordion.Header>
        <Accordion.Body>
          <Row>
            <Form.Group as={Col} controlId="vBCUFDest" xs={12} md={3}>
              <Form.Label>Base do ICMS da UF de destino</Form.Label>
              <InputNumber
                placeholder="Base do ICMS de UF de destino"
                value={item.vBCUFDest}
                decimalPlaces={2}
                onChange={(vBCUFDest) => onChange({ vBCUFDest })}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="pUFDest" xs={12} md={3}>
              <Form.Label>Aliquota do ICMS da UF de destino</Form.Label>
              <InputNumber
                placeholder="Aliquota do ICMS de UF de destino"
                value={item.pUFDest}
                decimalPlaces={2}
                onChange={(pUFDest) => onChange({ pUFDest })}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="pICMSInter" xs={12} md={3}>
              <Form.Label>Aliquota do ICMS interestadual</Form.Label>
              <InputNumber
                placeholder="Aliquota do ICMS interestadual"
                value={item.pICMSInter}
                decimalPlaces={2}
                onChange={(pICMSInter) => onChange({ pICMSInter })}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="vUFDest" xs={12} md={3}>
              <Form.Label>ICMS da UF de destino</Form.Label>
              <InputNumber
                placeholder="ICMS da UF de destino"
                value={item.vUFDest}
                decimalPlaces={2}
                onChange={(vUFDest) => onChange({ vUFDest })}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="vBCFCPUFDest" xs={12} md={4}>
              <Form.Label>Base do ICMS-FCP da UF de destino</Form.Label>
              <InputNumber
                placeholder="Base do ICMS-FCP de UF de destino"
                value={item.vBCFCPUFDest}
                decimalPlaces={2}
                onChange={(vBCFCPUFDest) => onChange({ vBCFCPUFDest })}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="pFCPUFDest" xs={12} md={4}>
              <Form.Label>Aliquota do ICMS-FCP de UF de destino</Form.Label>
              <InputNumber
                placeholder="Aliquota do ICMS-FCP de UF de destino"
                value={item.pFCPUFDest}
                decimalPlaces={2}
                onChange={(pFCPUFDest) => onChange({ pFCPUFDest })}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="vFCPUFDest" xs={12} md={4}>
              <Form.Label>ICMS-FCP da UF de destino</Form.Label>
              <InputNumber
                placeholder="ICMS-FCP da UF de destino"
                value={item.vFCPUFDest}
                decimalPlaces={2}
                onChange={(vFCPUFDest) => onChange({ vFCPUFDest })}
              />
            </Form.Group>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ImpostoForm;
