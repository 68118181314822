import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { AWSRes } from "./../../../redux/types";
import { Cond, CondRes } from "./../saga";
import { condPaneActions } from "./reducer";

function condCall(lastKey = null, startWith = "OPEN") {
  return executeApiRequest<CondRes>(
    "/cond",
    "GET",
    {},
    {
      queryStringParameters: {
        lastKey: JSON.stringify(lastKey),
        movIdStart: startWith,
      },
    }
  );
}

function* condpaneRequestOpened() {
  try {
    const res: AWSRes<Cond> = yield call(condCall, null, "OPEN");
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: AWSRes<Cond> = yield call(condCall, res.lastKey, "OPEN");
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(condPaneActions.condsOpenedSuccess(res));
  } catch (error) {
    yield put(condPaneActions.condsOpenedError(error));
  }
}

function* condpaneRequestClosed() {
  try {
    const res: AWSRes<Cond> = yield call(condCall, null, "CLOSED");
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: AWSRes<Cond> = yield call(condCall, res.lastKey, "CLOSED");
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(condPaneActions.condsClosedSuccess(res));
  } catch (error) {
    yield put(condPaneActions.condsClosedError(error));
  }
}

export default function* condPaneSaga() {
  yield all([
    takeLatest("condPane/requestCondsOpened", condpaneRequestOpened),
    takeLatest("condPane/requestCondsClosed", condpaneRequestClosed),
  ]);
}
