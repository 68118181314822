import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import executeApiUnauthRequest from "../../misc/executeApiUnauthRequest";
import { InvoiceParcela } from "./actions";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
// whatsapp://send?text=Abra%20o%20link%20para%20efetuar%20o%20pagamento%20PIX:%20https://apl.facion.com.br/pix/OPEN.S7.1.a2444a50-67ec-11ec-add5-6bd6ce87a790/d8206950-17f2-11ec-b31a-8b8ef007e4e1_MOV_INVOICE
export const ViewPix: React.FC<RouteComponentProps> = () => {
  const { id, orgId } = useParams();
  const [invoice, setInvoice] = useState<InvoiceParcela | null>(null);

  const handleCopiar = async (text?: string) => {
    if (!text) {
      return;
    }

    try {
      if ("clipboard" in navigator && text) {
        await navigator.clipboard.writeText(text);
        alert("Copiado!");
      }
    } catch (error) {
      console.error("error on copy", error);
    }
  };

  useEffect(() => {
    (async () => {
      const pixInvoice: InvoiceParcela = await executeApiUnauthRequest(
        `/get_pix_unauth/${id}/${orgId}`,
        "GET",
        orgId
      );
      setInvoice(pixInvoice);
    })();
  }, [id, orgId]);

  return (
    <>
      <h1>Leia o QR Code ou use o copia-e-cola para efetuar o pagamento</h1>

      {invoice && (
        <>
          <Image
            style={{ display: "block" }}
            src={`data:image/png;base64,${invoice.qrcodepix}`}
          />
          <div
            style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              wordWrap: "break-word",
            }}
          >
            URL Copia-e-cola: {atob(`${invoice.copiaecolapix}`)}
            <FontAwesomeIcon
              icon={faCopy}
              style={{ cursor: "pointer", marginLeft: "1rem" }}
              onClick={() => handleCopiar(atob(`${invoice.copiaecolapix}`))}
              title="Copiar"
            />
          </div>
        </>
      )}
    </>
  );
};
