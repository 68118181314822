import { AWSRes } from "./../../../redux/types";
// saga do modulo cond
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  clientTaskSuccess,
  clientTaskError,
  CLIENT_TASK_REQUESTING,
} from "./actions";
import { MOV_TYPE, Task } from "../actions";
import executeApiRequest from "../../../misc/executeApiRequest";

function taskCall(clientId: string, lastKey = null) {
  return executeApiRequest<AWSRes<Task[]>>(
    "/mov",
    "GET",
    {},
    {
      queryStringParameters: {
        movType: MOV_TYPE,
        movClientId: clientId,
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* taskWorker({ payload }: any) {
  try {
    const res: AWSRes<Task[]> = yield call(taskCall, payload);
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: AWSRes<Task[]> = yield call(taskCall, payload, res.lastKey);
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(clientTaskSuccess(res));
  } catch (error) {
    console.log("error on request", error, error.response);
    yield put(
      clientTaskError(error.response ? error.response.data.error : error)
    );
  }
}

export default function* taskViewSaga() {
  yield all([takeLatest(CLIENT_TASK_REQUESTING, taskWorker)]);
}
