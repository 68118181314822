export const SERIE_FORM_INIT = 'SERIE_FORM_INIT'
export const SERIE_FORM_SUCCESS = 'SERIE_FORM_SUCCESS'
export const SERIE_FORM_ERROR = 'SERIE_FORM_ERROR'
export const SERIE_SAVE_REQUESTING = 'SERIE_SAVE_REQUESTING'
export const SERIE_SAVE_SUCCESS = 'SERIE_SAVE_SUCCESS'
export const SERIE_SAVE_ERROR = 'SERIE_SAVE_ERROR'
export const SERIE_SET = 'SERIE_SET'

export function formInit(cadId) {
  return {
    type: SERIE_FORM_INIT,
    payload: cadId
  }
}

export function formInitSuccess(item) {
  return {
    type: SERIE_FORM_SUCCESS,
    payload: item
  }
}

export function formInitError(error) {
  return {
    type: SERIE_FORM_ERROR,
    error
  }
}

export function formSave(item) {
  return {
    type: SERIE_SAVE_REQUESTING,
    payload: item
  }
}

export function formSuccess(item) {
  return {
    type: SERIE_SAVE_SUCCESS,
    payload: item
  }
}

export function formError(error) {
  return {
    type: SERIE_SAVE_ERROR,
    error
  }
}

export function serieSet(payload) {
  return {
    type: SERIE_SET,
    payload
  }
}