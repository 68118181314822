import moment from "moment";
import _ from "underscore";

function geraTagXml(key: string, obj: any, level = 0): string {
  if (obj === undefined || obj === null) {
    return "";
  }
  let levelStr = "";
  if (typeof obj === "object") {
    if (obj instanceof Date) {
      return `${levelStr}<${key}>${moment(obj).toISOString()}</${key}>`;
    }
    let attrs = "";
    if (obj._attr_) {
      attrs = " " + _.map(obj._attr_, (v, k) => `${k}="${v}"`).join(" ");
      delete obj._attr_;
    }
    if (Array.isArray(obj)) {
      return _.map(obj, (v) => geraTagXml(key, v, level)).join("");
    }
    return `${levelStr}<${key}${attrs}>${_.map(obj, (v, k) =>
      geraTagXml(k, v, level + 1)
    ).join("")}${levelStr}</${key}>`;
  } else {
    return `${levelStr}<${key}>${`${obj}`.trim()}</${key}>`;
  }
}

export default geraTagXml;
