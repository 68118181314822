import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, call, takeLatest, takeEvery } from "redux-saga/effects";
import { Auth } from "aws-amplify";
import executeApiRequest from "../../misc/executeApiRequest";
import browserRedirect from "../../misc/browserRedirect";
import {
  ConfirmationActionPayload,
  ForgotPwdConfirmationPayload,
  LoginActionPayload,
  loginActions,
  Org,
  SignUpActionPayload,
  User,
  UserGroup,
} from "./reducer";
import { subcsriptionActions } from "../subscription/reducer";

function loginCall(email: string, password: string) {
  return Auth.signIn(email, password);
}

function* loginWorker({ payload }: PayloadAction<LoginActionPayload>) {
  try {
    console.log("calling login on aws cognito", payload);
    const user = yield call(loginCall, payload.email, payload.password);
    console.log("returned from logging", user);
    console.log("org returned", user.signInUserSession.idToken.payload.org);
    console.log(
      "subscription returned",
      user.signInUserSession.idToken.payload.subscription
    );
    const org = JSON.parse(user.signInUserSession.idToken.payload.org);
    const subscription =
      user.signInUserSession.idToken.payload.subscription &&
      JSON.parse(user.signInUserSession.idToken.payload.subscription);
    /// o que fazer quando não retornar org?
    /// - Tentar solicitar a org do usuario
    /// - Bloquear todo o sistema e pedir para o usuario logar novamente ou entrar em contato com o suporte
    /// o que fazer quando não retornar subscription? -> Travar o sistema e pedir regularização (Ver se tem trial)
    const userToStore = {
      username: user.username,
      attributes: user.attributes,
    };
    console.log("org Received", org);
    console.log("subscription Received", subscription);
    if (org) {
      yield put(loginActions.orgSuccess(org));
    } else {
      yield put(loginActions.orgRequest(user));
    }
    yield put(subcsriptionActions.setActiveSubscription(subscription || null));
    yield put(loginActions.loginSuccess(userToStore));
    browserRedirect(payload.redirect || "/dashboard");
  } catch (error) {
    console.log("error on login", error);
    if (error.code === "UserNotConfirmedException") {
      yield put(loginActions.setConfirmEmail(payload.email));
      browserRedirect("/confirmation");
      return;
    }
    if (error.code === "PasswordResetRequiredException") {
      yield put(loginActions.loginError("Usuario ou Senha inválido!"));
      return;
    }
    yield put(loginActions.loginError(error));
  }
}

function* orgWorker({ payload }: PayloadAction<User>) {
  if (!payload.attributes["custom:orgId"]) {
    console.log("didnt got the user");
    return;
  }
  try {
    const org = yield call(executeApiRequest, `/org`, "GET", {}, {});
    if (!org) {
      console.log("org doesnt exist");
      yield put(loginActions.logoutRequest());
      yield put(
        loginActions.loginError(
          "A organização não existe! Entre em contato com o suporte@notinha.app.br"
        )
      );
      // Create an org if it doesnt exists (send to initial wizard)
      /**
      console.log("Creating new org", org);
      const newOrg = {
        cadId: payload.attributes["custom:orgId"],
        cadType: "CAD_ORG",
        trialStart: new Date(),
        orgStatus: "ACTIVE",
        masterUser: payload.attributes["sub"],
        wizard: true,
      };
      const res = yield call(executeApiRequest, `/cad`, "POST", newOrg, {});
      yield put(loginActions.orgSuccess(res));
      console.log("created new org");
      browserRedirect("/Wizard");
      */
    } else {
      yield put(loginActions.orgSuccess(org));
      if (org.emissorId) {
        try {
          const resEmissor = yield call(
            executeApiRequest,
            `/emissor/${org.emissorId}`,
            "GET",
            {},
            {}
          );
          yield put(loginActions.setDefaultEmit(resEmissor));
        } catch (err) {
          console.log("default emissor error", err);
        }
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.error === "Item not found"
    ) {
      console.log("ORG com esse id nao existe");
      // Create an org if it doesnt exists
      console.log("Creating new org", payload);
      /*
      const newOrg = {
        cadId: payload.attributes["custom:orgId"],
        cadType: "CAD_ORG",
        trialStart: new Date(),
        orgStatus: "ACTIVE",
        masterUser: payload.attributes["sub"],
        wizard: true,
      };
      const res = yield call(executeApiRequest, `/cad`, "POST", newOrg, {});
      yield put(loginActions.orgSuccess(res));
      browserRedirect("/Wizard");
      */
      console.log("org doesnt exist");
      yield put(loginActions.logoutRequest());
      yield put(
        loginActions.loginError(
          "A organização não existe! Entre em contato com o suporte@notinha.app.br"
        )
      );
    } else {
      console.log("Nao deve ocorrer - ORG ", error, error.response);
    }
  }
}

function confirmCall(email: string, confirmationCode: string) {
  return Auth.confirmSignUp(email, confirmationCode);
}

function* confirmationWorker({
  payload,
}: PayloadAction<ConfirmationActionPayload>) {
  try {
    yield call(confirmCall, payload.email, payload.confirmationCode);
    yield put(loginActions.confirmationSuccess());
    browserRedirect("/login");
  } catch (error) {
    yield put(loginActions.confirmationError(error));
  }
}

function signupCall(email: string, password: string, orgId: string) {
  return Auth.signUp({
    username: email,
    password: password,
    attributes: {
      "custom:orgId": orgId,
    },
  });
}

function* signupWorker({ payload }: PayloadAction<SignUpActionPayload>) {
  try {
    yield call(signupCall, payload.email, payload.password, payload.orgId);
    yield put(loginActions.signupSuccess());
    yield put(loginActions.setConfirmEmail(payload.email));
    browserRedirect("/confirmation");
  } catch (error) {
    if (error.code === "UsernameExistsException") {
      yield put(
        loginActions.signupError(
          "Ja existe um usuario com este email cadastrado!"
        )
      );
    } else {
      yield put(loginActions.signupError(error));
    }
  }
}

function forgotPwdCall(email: string) {
  return Auth.forgotPassword(email);
}

function* forgotPwdWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(forgotPwdCall, payload);
    yield put(loginActions.forgotPwdConfirmSuccess());
  } catch (error) {
    yield put(loginActions.forgotPwdConfirmError(error));
  }
}

function forgotPwdConfirmCall(
  email: string,
  confirmationCode: string,
  newPassword: string
) {
  return Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
}

function* forgotPwdConfirmWorker({
  payload,
}: PayloadAction<ForgotPwdConfirmationPayload>) {
  try {
    yield call(
      forgotPwdConfirmCall,
      payload.email,
      payload.confirmationCode,
      payload.newPassword
    );
    yield put(loginActions.forgotPwdConfirmSuccess());
    browserRedirect("/login");
  } catch (error) {
    yield put(loginActions.forgotPwdConfirmError(error));
  }
}

function sessionCall() {
  return Auth.currentSession();
}

export function authUserCall() {
  return Auth.currentAuthenticatedUser();
}

function* sessionWorker() {
  try {
    yield call(sessionCall);
    const user = yield call(authUserCall);
    const userToStore = {
      username: user.username,
      attributes: user.attributes,
    };
    yield put(loginActions.orgRequest(user));
    yield put(loginActions.loginSuccess(userToStore));
    yield put(loginActions.sessionLoginEnd());
  } catch (_) {
    yield put(loginActions.init());
  }
}

function logoutCall() {
  return Auth.signOut();
}

function* logoutWorker() {
  try {
    yield call(logoutCall);
    browserRedirect("/login");
  } catch (error) {
    console.log("error on logout", error);
  }
}

function saveCall(user: string, newAttrs: any) {
  return Auth.updateUserAttributes(user, newAttrs);
}

function* userSaveWorker({ payload }: PayloadAction<User>) {
  try {
    const userActual = yield call(authUserCall);
    yield call(saveCall, userActual, payload);
    // const user = yield call(authUserCall);
    yield put(loginActions.userSaveSuccess());
  } catch (error) {
    yield put(loginActions.userSaveError(error));
  }
}

function* orgSaveWorker({ payload }: PayloadAction<Org>) {
  console.log("org save requesting", payload);
  try {
    const orgSaved = yield call(executeApiRequest, `/org`, "PUT", payload, {});
    yield put(loginActions.orgSaveSuccess(orgSaved));
  } catch (error) {
    console.log("error", error);
    yield put(loginActions.orgSaveError(error));
  }
}

function* createUserGroupWorker({ payload }: PayloadAction<UserGroup>) {
  try {
    yield call(executeApiRequest, `/create_group`, "POST", payload, {});
    yield put(loginActions.createUserGroupSuccess());
  } catch (error) {
    yield put(loginActions.createUserGroupError(error));
  }
}

function* resendConfirmationWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(Auth.resendSignUp, payload);
  } catch (error) {
    console.log("error", error);
    yield put(loginActions.confirmationError(error));
  }
}

export default function* loginSaga() {
  yield all([
    takeLatest("login/loginRequest", loginWorker),
    takeLatest("login/orgRequest", orgWorker),
    takeEvery("login/confirmationRequest", confirmationWorker),
    takeEvery("login/signupRequest", signupWorker),
    takeEvery("login/forgotPwdRequest", forgotPwdWorker),
    takeEvery("login/forgotPwdConfirmRequest", forgotPwdConfirmWorker),
    takeLatest("login/sessionLogin", sessionWorker),
    takeLatest("login/logoutRequest", logoutWorker),
    takeLatest("login/userSaveRequesting", userSaveWorker),
    takeLatest("login/orgSaveRequest", orgSaveWorker),
    takeLatest("login/createUserGroup", createUserGroupWorker),
    takeLatest("login/resendConfirmationCode", resendConfirmationWorker),
  ]);
}
