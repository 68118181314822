// reducer do modulo task
import {
  TASK_INIT,
  TASK_REQUESTING,
  TASK_SUCCESS,
  TASK_ERROR,
  TASK_DELETE_REQUESTING,
  TASK_DELETE_ERROR,
  TASK_DELETE_SUCCESS,
  TASK_SAVE_REQUESTING,
  TASK_SAVE_ERROR,
  TASK_SAVE_SUCCESS,
  MOV_TYPE,
  NEWTASK_SET,
  Task,
} from "./actions";
import { combineReducers } from "redux";
import viewReducer from "./taskView/reducer";

const newTask: Task = {
  summary: "",
  dest: null,
  dateDue: new Date(),
  movType: MOV_TYPE,
};

const initialState = {
  items: [],
  newTask: { ...newTask },
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
  isSaving: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TASK_INIT:
      return {
        ...state,
        items: [],
        newTask: { ...newTask },
        Count: 0,
        ScannedCount: 0,
        lastKey: null,
        successMsg: "",
        error: "",
        isRequesting: true,
        isDeleting: false,
      };
    case TASK_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      };
    case TASK_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload.items,
        Count: action.payload.Count,
        ScannedCount: action.payload.ScannedCount,
        lastKey: action.payload.lastKey,
      };
    case TASK_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
        successMsg: "",
      };
    case TASK_DELETE_REQUESTING:
      return {
        ...state,
        isDeleting: true,
      };
    case TASK_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        successMsg: "Tarefa removida com sucesso!",
      };
    case TASK_DELETE_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        successMsg: "",
      };
    case TASK_SAVE_REQUESTING:
      return {
        ...state,
        isSaving: true,
      };
    case TASK_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        successMsg: "Tarefa salva com sucesso!",
      };
    case TASK_SAVE_ERROR:
      return {
        ...state,
        isSaving: false,
        error: action.error,
      };
    case NEWTASK_SET:
      return {
        ...state,
        newTask: action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list: reducer,
  view: viewReducer,
});
