// reducer do form do modulo invoice
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Payment } from "../types";

type PaymentFormInitialState = {
  item: Payment;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isSaving: boolean;
};

const initialState: PaymentFormInitialState = {
  item: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
};

const reducerSlice = createSlice({
  name: "paymentForm",
  initialState,
  reducers: {
    initForm: (state, _: PayloadAction<string>) => {
      state.item = null;
      state.successMsg = "";
      state.error = "";
      state.isRequesting = true;
      state.isSaving = false;
    },
    requestSuccess: (state, { payload }: PayloadAction<Payment>) => {
      state.item = payload;
      state.isRequesting = false;
    },
    requestError: (state, { payload }: PayloadAction<string>) => {
      state.isRequesting = false;
      state.error = payload;
    },
    requestSave: (state, _: PayloadAction<Payment>) => {
      state.isSaving = true;
      state.error = "";
      state.successMsg = "";
    },
    saveSuccess: (state, { payload }: PayloadAction<Payment>) => {
      state.isSaving = false;
      state.successMsg = "Salvo com sucesso!";
      state.item = payload;
    },
    saveError: (state, { payload }: PayloadAction<string>) => {
      state.isSaving = false;
      state.error = payload;
    },
  },
});

export const paymentFormActions = reducerSlice.actions;

export default reducerSlice;
