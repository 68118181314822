import { faBarcode, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FaWhatsapp } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "@reach/router";
import moment from "moment";
import React, { Component } from "react";
import { Button, ButtonGroup, Col, Row, Modal, Image } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "underscore";
import Kpi from "../../../components/Kpi";
import { LinkButton } from "../../../components/LinkButton";
import LoaderButton from "../../../components/LoaderButton";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import { RootState } from "../../app/mainReducer";
import { invoiceDeleteRequest, InvoiceParcela } from "../actions";
import { invoicePaneActions } from "./reducer";
import DeleteButton from "../../../components/DeleteButton";

type InvoicePaneProps = {
  ItemsOpen: InvoiceParcela[];
  CountOpen: number;
  lastKeyOpen: any;
  isRequestingOpen: boolean;
  ItemsClosed: InvoiceParcela[];
  CountClosed: number;
  lastKeyClosed: any;
  isRequestingClosed: boolean;
  userRole: string;
  orgStatus: string;
  isRequestingBoleto: boolean;
  requestBoletoError: any;
  boleto: any;
  requestItemsOpen: () => void;
  requestItemsClosed: () => void;
  printNotas: (notas: any[]) => void;
  getBoletoRequest: (payload: InvoiceParcela) => void;
  isRequestingPix: boolean;
  getPixRequest: (payload: InvoiceParcela) => void;
  error: string;
  isDeleting: boolean;
  deleteRequest: (movId: string) => void;
};

function try_atob(text: string) {
  try {
    return atob(text);
  } catch (e) {
    console.error("error atob", text, e);
    return "";
  }
}

class InvoicePane extends Component<InvoicePaneProps, {}> {
  state = {
    parcelasSelected: [],
    pixIndex: -1,
  };

  componentDidMount() {
    this.props.requestItemsOpen();
    this.props.requestItemsClosed();
  }

  async handleCopiar(text?: string) {
    if (!text) {
      return;
    }

    try {
      if ("clipboard" in navigator && text) {
        await navigator.clipboard.writeText(text);
        alert("Copiado!");
      }
    } catch (error) {
      console.error("error on copy", error);
    }
  }

  handleSelectParcela = (invoiceId: string) => {
    this.setState({
      parcelasSelected: [...this.state.parcelasSelected, invoiceId],
    });
  };

  handleDeselectParcela = (invoiceId: string) => {
    const indSelected = this.state.parcelasSelected.indexOf(invoiceId);
    if (indSelected > -1) {
      const parcelasSelected = [...this.state.parcelasSelected];
      parcelasSelected.splice(indSelected, 1);
      this.setState({ parcelasSelected });
    }
  };

  isParcelaSelected = (invoiceId: string) => {
    const indSelected = this.state.parcelasSelected.indexOf(invoiceId);
    return indSelected > -1;
  };

  toggleParcela = (invoiceId: string) => {
    console.log("selecting", invoiceId);
    if (this.isParcelaSelected(invoiceId)) {
      this.handleDeselectParcela(invoiceId);
    } else {
      this.handleSelectParcela(invoiceId);
    }
  };

  printNPromSel = () => {
    if (this.state.parcelasSelected.length < 1) {
      return;
    }
    const notasPrint = [];
    for (let sel of this.state.parcelasSelected) {
      const cob = _.find(this.props.ItemsOpen, (m) => m.invoiceId === sel);
      notasPrint.push({
        numero: `${cob.num}/${cob.numparcela}`,
        emission: cob.emission,
        vencimento: cob.vencimento,
        valor: cob.valor,
        emissor: cob.emissor,
        dest: cob.dest,
      });
    }

    console.log("notas", this.state.parcelasSelected, notasPrint);
    this.props.printNotas(notasPrint);
    // this.props.setNotasProm(notasPrint)
    try {
      navigate("/PrintNotaPromissoria");
    } catch (error) {
      console.log("error on redirect", error, error.message);
    }
  };

  renderParcelasOptions = () => {
    return (
      <Col xs={12} sm={6} md={3} lg={3} xl={3}>
        <Button variant="primary" onClick={this.printNPromSel}>
          <i className="fas fa-print" /> Imprimir Nota Promissória da Seleção
        </Button>
      </Col>
    );
  };

  render() {
    const { orgStatus } = this.props;

    const headers = [
      {
        label: "#",
        render: (item: InvoiceParcela) => `${item.num}/${item.numparcela}`,
      },
      {
        label: "Emissão",
        render: (item: InvoiceParcela) =>
          moment(item.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Cliente",
        render: (item: InvoiceParcela) => `${item.dest && item.dest.name}`,
      },
      {
        label: "Valor",
        render: (item: InvoiceParcela) => formatNumber(item.valor, 2),
      },
      {
        label: "Vencimento",
        render: (item: InvoiceParcela) =>
          moment(item.vencimento).format("DD/MM/YYYY"),
      },
      {
        label: "Status",
        render: (item: InvoiceParcela) =>
          item.status === "OPEN" ? "Pendente" : "Recebido",
      },
      {
        label: "Ações",
        render: (item: InvoiceParcela, i: number) =>
          orgStatus === "ACTIVE" ? (
            <ButtonGroup>
              <LinkButton variant="info" to={`/invoice/${item.invoiceId}`}>
                <i className="fas fa-edit" />
              </LinkButton>
              <DeleteButton
                isRequesting={this.props.isDeleting}
                onDelete={() => this.props.deleteRequest(item.invoiceId)}
              />
              {item.status === "OPEN" && (
                <LinkButton
                  variant="light"
                  title="Receber a cobrança"
                  to={`/InvoicePay/${item.num}:${item.numparcela}`}
                >
                  <i className="fas fa-cash-register" />
                </LinkButton>
              )}
              {moment(item.vencimento).isSameOrAfter(moment(), "day") && (
                <LoaderButton
                  variant="light"
                  title="Emitir Boleto"
                  isLoading={this.props.isRequestingBoleto}
                  onClick={() => this.props.getBoletoRequest(item)}
                >
                  <FontAwesomeIcon icon={faBarcode} />
                </LoaderButton>
              )}
            </ButtonGroup>
          ) : null,
      },
    ];

    const cobsVencidas = _.filter(
      this.props.ItemsOpen,
      (parcel) =>
        parcel.status === "OPEN" &&
        moment(parcel.vencimento).isBefore(moment(), "day")
    );
    const totalVencidas = cobsVencidas.reduce((p, c) => p + c.valor, 0);

    const cobsVencer = _.filter(this.props.ItemsOpen, (parcel) =>
      moment(parcel.vencimento).isSameOrAfter(moment(), "day")
    );
    const totalVencer = cobsVencer.reduce((p, c) => p + c.valor, 0);

    return (
      <>
        <Row>
          <Col>
            <Kpi
              title="Total de vencidas"
              value={`R$ ${formatNumber(totalVencidas, 2, true)}`}
            />
            <Kpi
              title="Total a vencer"
              value={`R$ ${formatNumber(totalVencer, 2, true)}`}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={2} lg={2} xl={2}>
            <LinkButton variant="primary" to="/invoice/new">
              <i className="fas fa-plus" /> Incluir Cobrança
            </LinkButton>
          </Col>
          {this.state.parcelasSelected.length > 0
            ? this.renderParcelasOptions()
            : null}
        </Row>
        <Row>
          <Col xs={12}>
            <ShowError error={this.props.requestBoletoError} />
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Cobranças Vencidas</h2>
          </Col>
        </Row>
        <Row>
          <LoadingContainer isLoading={this.props.isRequestingOpen}>
            <TableOrCard
              itemClick={(item) => this.toggleParcela(item.invoiceId)}
              itemVariant={(item) =>
                this.isParcelaSelected(item.invoiceId) ? "info" : "default"
              }
              headers={headers}
              items={cobsVencidas}
            />
          </LoadingContainer>
        </Row>
        <Row>
          <Col>
            <h2>Cobranças a Vencer</h2>
          </Col>
        </Row>
        <Row>
          <LoadingContainer isLoading={this.props.isRequestingOpen}>
            <TableOrCard
              itemClick={(item) => this.toggleParcela(item.invoiceId)}
              itemVariant={(item) =>
                this.isParcelaSelected(item.invoiceId) ? "info" : "default"
              }
              headers={headers}
              items={cobsVencer}
            />
          </LoadingContainer>
        </Row>
        <Modal
          show={this.state.pixIndex >= 0 && !this.props.isRequestingPix}
          onHide={() => this.setState({ pixIndex: -1 })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              PIX gerado envie o QR-Code/Copia-e-cola para seu cliente!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image
              style={{ display: "block" }}
              src={`data:image/png;base64,${
                this.props.ItemsOpen[this.state.pixIndex] &&
                this.props.ItemsOpen[this.state.pixIndex].qrcodepix
              }`}
            />
            <div
              style={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                wordWrap: "break-word",
              }}
            >
              URL Copia-e-cola:{" "}
              {this.props.ItemsOpen[this.state.pixIndex] &&
                try_atob(
                  `${this.props.ItemsOpen[this.state.pixIndex].copiaecolapix}`
                )}
              <FontAwesomeIcon
                icon={faCopy}
                style={{ cursor: "pointer", marginLeft: "1rem" }}
                onClick={() =>
                  this.handleCopiar(
                    this.props.ItemsOpen[this.state.pixIndex] &&
                      atob(
                        `${
                          this.props.ItemsOpen[this.state.pixIndex]
                            .copiaecolapix
                        }`
                      )
                  )
                }
                title="Copiar"
              />
            </div>
            <a
              href={
                this.props.ItemsOpen[this.state.pixIndex] &&
                `whatsapp://send?text=Abra o link para efetuar o pagamento PIX: https://apl.notinha.app.br/pix/${
                  this.props.ItemsOpen[this.state.pixIndex].invoiceId
                }/${this.props.ItemsOpen[this.state.pixIndex].orgId}`
              }
            >
              <FaWhatsapp style={{ display: "inline" }} /> Compartilhar via
              Whatsapp
            </a>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    ItemsOpen: state.invoice.pane.ItemsOpen,
    CountOpen: state.invoice.pane.CountOpen,
    lastKeyOpen: state.invoice.pane.lastKeyOpen,
    isRequestingOpen: state.invoice.pane.isRequestingOpen,
    ItemsClosed: state.invoice.pane.ItemsClosed,
    CountClosed: state.invoice.pane.CountClosed,
    lastKeyClosed: state.invoice.pane.lastKeyClosed,
    isRequestingClosed: state.invoice.pane.isRequestingClosed,
    userRole: state.login.userRole,
    orgStatus: state.login.orgStatus,
    isRequestingBoleto: state.invoice.pane.isRequestingBoleto,
    requestBoletoError: state.invoice.pane.requestBoletoError,
    boleto: state.invoice.pane.boleto,
    isRequestingPix: state.invoice.pane.isRequestingPix,
    error: state.invoice.pane.error,
    isDeleting: state.invoice.list.isDeleting,
  };
};
const mapDispatchToProps = {
  requestItemsOpen: invoicePaneActions.requestItemsOpen,
  requestItemsClosed: invoicePaneActions.requestItemsClosed,
  printNotas: invoicePaneActions.printNotas,
  getBoletoRequest: invoicePaneActions.requestBoleto,
  getPixRequest: invoicePaneActions.requestPix,
  deleteRequest: invoiceDeleteRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePane);
