// component do modulo dest
import React, { Component } from "react";
import { connect } from "react-redux";
import { pageInit, destDeleteRequest, Dest } from "./actions";
import LoadingContainer from "../../components/LoadingContainer";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import TableOrCard from "../../components/TableOrCard";
import DeleteButton from "../../components/DeleteButton";
import _ from "underscore";
import { Link } from "@reach/router";

type DestProps = {
  items: Dest[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isDeleting: boolean;
  pageInit: () => void;
  deleteRequest: (cadId: string) => void;
  destFilter?: string;
};

type DestState = {
  destFilter: string;
};

class Dests extends Component<DestProps, DestState> {
  state = {
    destFilter: "",
  };

  headers = [
    {
      label: "Nome/Razão Social",
      dataIndex: "name",
    },
    {
      label: "Tipo",
      render: (item: any) =>
        item.type === 1 ? "Pessoa Física" : "Pessoa Jurídica",
    },
    {
      label: "CPF/CNPJ",
      render: (item: any) => (item.type === 1 ? item.cpf : item.cnpj),
    },
    {
      label: "Endereço",
      dataIndex: "address",
    },
    {
      label: "Numero",
      dataIndex: "addressNumber",
    },
    {
      label: "Cidade",
      dataIndex: "city",
    },
    {
      label: "UF",
      dataIndex: "estate",
    },
    {
      label: "Ações",
      render: (item) => (
        <ButtonGroup>
          <Button variant="info" as={Link} to={`/dest/${item.cadId}`}>
            <i className="fas fa-edit" />
          </Button>
          <DeleteButton
            isRequesting={this.props.isDeleting}
            onDelete={() => this.handleDelete(item)}
          />
        </ButtonGroup>
      ),
    },
  ];

  componentDidMount() {
    this.props.pageInit();
    this.setState({
      destFilter: this.props.destFilter,
    });
  }

  componentDidUpdate(prevProps: DestProps) {
    if (prevProps.destFilter !== this.props.destFilter) {
      this.setState({
        destFilter: this.props.destFilter,
      });
    }
  }

  handleDelete = (item) => {
    this.props.deleteRequest(item.cadId);
  };

  getFilteredItems = () => {
    if (!this.state.destFilter) {
      return _.sortBy(this.props.items, (dest: Dest) => dest.name);
    }
    const destRegex = new RegExp(this.state.destFilter, "i");
    return _.sortBy(
      _.filter(
        this.props.items,
        (dest) =>
          destRegex.test(dest.name) ||
          destRegex.test(dest.cpf) ||
          destRegex.test(dest.cnpj) ||
          destRegex.test(dest.address)
      ),
      (dest) => dest.name
    );
  };

  render() {
    const renderItems = this.getFilteredItems();

    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Clientes/Fornecedores</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <Button as={Link} to="/dest/new" variant="primary">
              Incluir
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={renderItems} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.dest.list.items,
    Count: state.dest.list.Count,
    lastKey: state.dest.list.lastKey,
    isRequesting: state.dest.list.isRequesting,
    isDeleting: state.dest.list.isDeleting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: () => dispatch(pageInit()),
    deleteRequest: (cadId) => dispatch(destDeleteRequest(cadId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dests);
