import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { AWSRes } from "../../../redux/types";
import { Banco } from "../types";
import { bancoSelectorActions } from "./reducer";

function* requestBancosWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AWSRes<Banco> = yield call(
      executeApiRequest,
      `/banco`,
      "GET",
      {},
      {
        queryStringParameters: {
          cadIdStart: payload,
        },
      }
    );
    yield put(bancoSelectorActions.requestSuccess(res));
  } catch (error) {
    yield put(bancoSelectorActions.requestError(error));
  }
}

export default function* bancoSelectorSaga() {
  yield all([takeLatest("bancoSelector/requestBancos", requestBancosWorker)]);
}
