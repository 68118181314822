// component do modulo payment
import { faCashRegister, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteButton from "../../components/DeleteButton";
import { LinkButton } from "../../components/LinkButton";
import LoadingContainer from "../../components/LoadingContainer";
import TableOrCard from "../../components/TableOrCard";
import { formatNumber } from "../../misc/formatters";
import { RootState } from "../app/mainReducer";
import { paymentReducerActions } from "./reducer";
import PaymentParcela from "./types";

type PaymentProps = {
  itemsThisWeek: PaymentParcela[];
  itemsNextWeek: PaymentParcela[];
  itemsNext30: PaymentParcela[];
  itemsPayed: PaymentParcela[];
  itemsLast: PaymentParcela[];
  CountThisWeek: number;
  CountNextWeek: number;
  CountNext30: number;
  CountPayed: number;
  CountLast: number;
  lastKeyThisWeek: Record<string, string>;
  lastKeyNextWeek: Record<string, string>;
  lastKeyNext30: Record<string, string>;
  lastKeyPayed: Record<string, string>;
  lastKeyLast: Record<string, string>;
  isRequestingThisWeek: boolean;
  isRequestingNextWeek: boolean;
  isRequestingNext30: boolean;
  isRequestingPayed: boolean;
  isRequestingLast: boolean;
  isDeleting: boolean;
  requestThisWeek: () => void;
  requestNextWeek: () => void;
  requestNext30: () => void;
  requestPayed: () => void;
  requestLast: () => void;
  requestDelete: (invoiceIds: string) => void;
};

class Payments extends Component<PaymentProps, {}> {
  state = {};

  headers = [
    {
      label: "Numero",
      render: (item: PaymentParcela) => `${item.num}/${item.numparcela}`,
    },
    {
      label: "Empresa",
      render: (item: PaymentParcela) => item.emissor && item.emissor.name,
    },
    {
      label: "Fornecedor",
      render: (item: PaymentParcela) => item.dest && item.dest.name,
    },
    {
      label: "Valor ($)",
      render: (item: PaymentParcela) => formatNumber(item.valor, 2),
    },
    {
      label: "Vencimento",
      render: (item: PaymentParcela) =>
        moment(item.vencimento).format("DD/MM/YYYY"),
    },
    {
      label: "Status",
      render: (item: PaymentParcela) =>
        item.status === "CLOSED" ? "Pago" : "Pendente",
    },
    {
      label: "Ações",
      render: (item: PaymentParcela) => (
        <ButtonGroup>
          <LinkButton variant="info" to={`/payment/${item.invoiceId}`}>
            <FontAwesomeIcon icon={faEdit} />
          </LinkButton>
          {item.status === "OPEN" && (
            <LinkButton
              variant="success"
              to={`/paymentPay/${item.num}:${item.numparcela}`}
            >
              <FontAwesomeIcon icon={faCashRegister} />
            </LinkButton>
          )}
          <DeleteButton
            isRequesting={this.props.isDeleting}
            onDelete={() => this.handleDelete(item)}
          />
        </ButtonGroup>
      ),
    },
  ];

  componentDidMount() {
    this.props.requestThisWeek();
    this.props.requestNextWeek();
    this.props.requestNext30();
    this.props.requestPayed();
    this.props.requestLast();
  }

  handleDelete = (item: PaymentParcela) => {
    this.props.requestDelete(item.invoiceId);
  };

  render() {
    return (
      <LoadingContainer
        isLoading={
          this.props.isRequestingThisWeek ||
          this.props.isRequestingNextWeek ||
          this.props.isRequestingNext30 ||
          this.props.isRequestingPayed ||
          this.props.isRequestingLast
        }
      >
        <Row>
          <Col>
            <h2>Painel de Pagamentos</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <LinkButton to="/payment/new" variant="primary">
              Incluir
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Vencendo esta semana</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard
              headers={this.headers}
              items={this.props.itemsThisWeek}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Vencendo na próxima semana</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard
              headers={this.headers}
              items={this.props.itemsNextWeek}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Vencendo nos próximos 30 dias</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard
              headers={this.headers}
              items={this.props.itemsNext30}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Ultimos pagamentos feitos</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={this.props.itemsPayed} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Ultimos pagamentos cadastrados</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={this.props.itemsLast} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    itemsThisWeek: state.payment.list.itemsThisWeek,
    itemsNextWeek: state.payment.list.itemsNextWeek,
    itemsNext30: state.payment.list.itemsNext30,
    itemsPayed: state.payment.list.itemsPayed,
    itemsLast: state.payment.list.itemsLast,
    CountThisWeek: state.payment.list.CountThisWeek,
    CountNextWeek: state.payment.list.CountNextWeek,
    CountNext30: state.payment.list.CountNext30,
    CountPayed: state.payment.list.CountPayed,
    CountLast: state.payment.list.CountLast,
    lastKeyThisWeek: state.payment.list.lastKeyThisWeek,
    lastKeyNextWeek: state.payment.list.lastKeyNextWeek,
    lastKeyNext30: state.payment.list.lastKeyNext30,
    lastKeyPayed: state.payment.list.lastKeyPayed,
    lastKeyLast: state.payment.list.lastKeyLast,
    isRequestingThisWeek: state.payment.list.isRequestingThisWeek,
    isRequestingNextWeek: state.payment.list.isRequestingNextWeek,
    isRequestingNext30: state.payment.list.isRequestingNext30,
    isRequestingPayed: state.payment.list.isRequestingPayed,
    isRequestingLast: state.payment.list.isRequestingLast,
    isDeleting: state.payment.list.isDeleting,
  };
};
const mapDispatchToProps = {
  requestThisWeek: paymentReducerActions.requestThisWeek,
  requestNextWeek: paymentReducerActions.requestNextWeek,
  requestNext30: paymentReducerActions.requestNext30,
  requestPayed: paymentReducerActions.requestPayed,
  requestLast: paymentReducerActions.requestLast,
  requestDelete: paymentReducerActions.requestDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
