import { InvoiceParcela } from "./../invoice/actions";
import { Prod } from "./../prod/actions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dest } from "./../dest/actions";
import PaymentParcela from "../payment/types";

export type TopSaleRecord = {
  userId: string;
  movId: string;
  clientId: string;
  client: Dest;
  valor: number;
};

export type TopProductRecord = {
  userId: string;
  movId: string;
  clientId: string;
  prod: Prod;
  valor: number;
};

type DashboardState = {
  isStatisticsRequesting: boolean;
  statisticsRecord: Record<string, any>;
  statisticsError: string;
  isTopSalesRequesting: boolean;
  topSales: TopSaleRecord[];
  topSalesError: string;
  isTopProdsRequesting: boolean;
  topProds: TopProductRecord[];
  topProdsError: string;
  isPaymentsRequesting: boolean;
  payments: PaymentParcela[];
  isInvoiceRequesting: boolean;
  invoices: InvoiceParcela[];
};

const initialState: DashboardState = {
  isStatisticsRequesting: false,
  statisticsRecord: null,
  statisticsError: "",
  isTopSalesRequesting: false,
  topSales: [],
  topSalesError: "",
  isTopProdsRequesting: false,
  topProds: [],
  topProdsError: "",
  isPaymentsRequesting: false,
  payments: [],
  isInvoiceRequesting: false,
  invoices: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    requestStatistics: (state) => {
      state.isStatisticsRequesting = true;
      state.statisticsError = "";
      state.statisticsRecord = null;
    },
    statisticsSuccess: (state, action: PayloadAction<Record<string, any>>) => {
      state.isStatisticsRequesting = false;
      state.statisticsRecord = action.payload;
    },
    statisticsError: (state, action: PayloadAction<string>) => {
      state.isStatisticsRequesting = false;
      state.statisticsRecord = null;
      state.statisticsError = action.payload;
    },
    requestTopSales: (state, _: PayloadAction<string>) => {
      state.isTopSalesRequesting = true;
      state.topSalesError = "";
      state.topSales = [];
    },
    topSalesSuccess: (state, action: PayloadAction<TopSaleRecord[]>) => {
      state.isTopSalesRequesting = false;
      state.topSales = action.payload;
    },
    topSalesError: (state, action: PayloadAction<string>) => {
      state.isTopSalesRequesting = false;
      state.topSalesError = action.payload;
    },
    requestTopProds: (state, _: PayloadAction<string>) => {
      state.isTopProdsRequesting = true;
      state.topProdsError = "";
      state.topProds = [];
    },
    topProdsSuccess: (state, action: PayloadAction<TopProductRecord[]>) => {
      state.isTopProdsRequesting = false;
      state.topProds = action.payload;
    },
    topProdsError: (state, action: PayloadAction<string>) => {
      state.isTopProdsRequesting = false;
      state.topProdsError = action.payload;
    },
    requestPayments: (state) => {
      state.isPaymentsRequesting = true;
      state.payments = [];
    },
    paymentsSuccess: (state, { payload }: PayloadAction<PaymentParcela[]>) => {
      state.isPaymentsRequesting = false;
      state.payments = payload;
    },
    paymentsError: (state) => {
      state.isPaymentsRequesting = false;
    },
    requestInvoices: (state) => {
      state.isInvoiceRequesting = true;
      state.invoices = [];
    },
    invoicesSuccess: (state, { payload }: PayloadAction<InvoiceParcela[]>) => {
      state.isInvoiceRequesting = false;
      state.invoices = payload;
    },
    invoicesError: (state) => {
      state.isInvoiceRequesting = false;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;
