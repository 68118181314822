import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { CityObject } from "../misc/cities";
import citiesList from "../misc/cities.json";

type CitySelectorProps = {
  value: string;
  uf: string;
  onChange: (object: any) => void;
};
/**
 * React component for selecting city with UF, CMun and the City
 */
const CitySelector: React.FC<CitySelectorProps> = (props) => {
  const uf = props.uf;

  const searchCity = (city: CityObject, props: any) => {
    const reSearch = new RegExp(`.*${props.text}.*`, "i");
    return city["UF"] === uf && reSearch.test(city["Município"]);
  };

  const renderCityOption = (city: CityObject) => `${city["Município"]}`;
  const onCitySelect = (arrCity: CityObject[]) => {
    if (arrCity.length > 0) {
      const city = arrCity[0];
      props.onChange({
        estate: city["UF"],
        city: city["Município"],
        cmun: city["IBGE7"],
      });
    }
  };

  return (
    <Typeahead
      id="citiesselector"
      labelKey={renderCityOption}
      options={citiesList as CityObject[]}
      filterBy={searchCity}
      onChange={onCitySelect}
      defaultInputValue={props.value}
    />
  );
};

export default CitySelector;
