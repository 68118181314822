export const CLIENT_INVOICE_REQUESTING = "CLIENT_INVOICE_REQUESTING";
export const CLIENT_INVOICE_SUCCESS = "CLIENT_INVOICE_SUCCESS";
export const CLIENT_INVOICE_ERROR = "CLIENT_INVOICE_ERROR";

export const clientInvoiceRequest = (payload: any) => ({
  type: CLIENT_INVOICE_REQUESTING,
  payload,
});

export const clientInvoiceSuccess = (payload: any) => ({
  type: CLIENT_INVOICE_SUCCESS,
  payload,
});

export const clientInvoiceError = (error: any) => ({
  type: CLIENT_INVOICE_ERROR,
  error,
});
