import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import ShowError from "../../components/ShowError";
import LoaderButton from "../../components/LoaderButton";
import { loginActions, Org, User } from "../login/reducer";
import { RootState } from "../app/mainReducer";

type FirstStepProps = {
  org: Org;
  loggedUser: User;
  setOrg: (org: Partial<Org>) => void;
  onNext: (nextPaylaod: { username: string }) => void;
  isFirstRequesting: boolean;
  firstStepError: string;
};

type FirstStepState = {
  username: string;
};

export class FirstStep extends Component<FirstStepProps, FirstStepState> {
  state = {
    username: "",
  };

  componentDidMount() {
    this.setState({
      username: this.props.loggedUser.attributes.name,
    });
  }

  isValid = () =>
    [
      this.state.username && this.state.username.length > 3,
      this.props.org.name && this.props.org.name.length > 3,
    ].reduce((a, p) => a && p);

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValid()) {
      this.props.onNext({
        username: this.state.username,
      });
    }
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Seu nome </Form.Label>
              <Form.Control
                name="username"
                value={this.state.username}
                onChange={(e) => this.setState({ username: e.target.value })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Qual o nome de sua organização </Form.Label>
              <Form.Control
                name="orgName"
                value={this.props.org.name}
                onChange={(e) => this.props.setOrg({ name: e.target.value })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowError error={this.props.firstStepError} />
          </Col>
        </Row>
        <Row>
          <Col>
            <LoaderButton
              id="wizard-btn-1"
              isLoading={this.props.isFirstRequesting}
              disabled={!this.isValid()}
              type="submit"
            >
              Próximo
            </LoaderButton>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    org: state.login.org,
    loggedUser: state.login.loggedUser,
    isFirstRequesting: state.wizard.isFirstRequesting,
    firstStepError: state.wizard.firstStepError,
  };
};

const dispatchProps = {
  setOrg: loginActions.setOrg,
};

export default connect(mapStateToProps, dispatchProps)(FirstStep);
