import { AWSRes } from "./../../../redux/types";
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  prodSelectorError,
  prodSelectorSuccess,
  PROD_SELECTOR_REQUESTING,
} from "./actions";
import executeApiRequest from "../../../misc/executeApiRequest";
import { Prod } from "../actions";

function prodSearchCall(cadIdStart) {
  return executeApiRequest<AWSRes<Prod>>(
    "/prod",
    "GET",
    {},
    {
      queryStringParameters: {
        cadIdStart,
      },
    }
  );
}

function* prodSearchWorker({ payload }: any) {
  try {
    const res: AWSRes<Prod> = yield call(prodSearchCall, payload);
    console.log("Searched", res);
    yield put(prodSelectorSuccess(res));
  } catch (error) {
    yield put(prodSelectorError(error));
  }
}

export default function* prodSelectorSaga() {
  yield all([takeLatest(PROD_SELECTOR_REQUESTING, prodSearchWorker)]);
}
