import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { navigate } from "@reach/router";

type QuickAction = {
  label: string;
  path: string;
  includeText?: boolean;
};

type QuickActionsState = {
  barText: string;
  options: QuickAction[];
};

class QuickActions extends Component<{}, QuickActionsState> {
  state = {
    barText: "",
    options: [
      {
        label: "Pesquisar Cliente xxx",
        path: "/destSearch/",
        includeText: true,
      },
      {
        label: "Baixar Cobrança(s) xxx",
        path: "/InvoicePay/",
        includeText: true,
      },
      {
        label: "Baixar Condicional(is) xxx",
        path: "/CondClose/",
        includeText: true,
      },
      {
        label: "Baixar Pagamento(s) xxx",
        path: "/paymentPay/",
        includeText: true,
      },
      {
        label: "Incluir Cobrança",
        path: "/invoice/new",
        includeText: false,
      },
      {
        label: "Incluir Cliente",
        path: "/dest/new",
        includeText: false,
      },
      {
        label: "Incluir Venda",
        path: "/sales/new",
        includeText: false,
      },
      {
        label: "Incluir Compra",
        path: "/purchases/new",
        includeText: false,
      },
      {
        label: "Incluir Condicional",
        path: "/cond/new",
        includeText: false,
      },
      {
        label: "Incluir Produto",
        path: "/prod/new",
        includeText: false,
      },
      {
        label: "Painel de Cobranças",
        path: "/invoicePane",
        includeText: false,
      },
      {
        label: "Painel de NFes",
        path: "/NFePane",
        includeText: false,
      },
      {
        label: "Condicionais",
        path: "/condPane",
        includeText: false,
      },
      {
        label: "Tarefas",
        path: "/Tasks",
        includeText: false,
      },
      {
        label: "Vendas Rápidas",
        path: "/quicksale",
        includeText: false,
      },
      {
        label: "Compras",
        path: "/purchases",
        includeText: false,
      },
    ],
  };

  refSearchBar: Typeahead<QuickAction> | null = null;

  componentDidMount() {
    window.onkeydown = (keyEvent) => {
      // Ctrl + i
      if (keyEvent.ctrlKey && keyEvent.keyCode === 73 && this.refSearchBar) {
        try {
          this.refSearchBar.focus();
        } catch (error) {
          console.log("error on trying to focus searchbar", error);
        }
      }
    };
  }

  isCobrancaNums = (term: string) =>
    /^([0-9S]+\/[0-9]+)([ ,]+([0-9S]+\/[0-9]+))*$/.test(term);

  isPaymentNums = (term: string) =>
    /^([0-9C]+\/[0-9]+)([ ,]+([0-9C]+\/[0-9]+))*$/.test(term);

  isCondicionalNums = (term: string) => /^([0-9]+)([ ,]+[0-9]+)*$/.test(term);

  handleFilter = (option: QuickAction, term: any) => {
    if (this.isCobrancaNums(term.text)) {
      return /^Baixar Cobrança.*/.test(option.label);
    }
    if (this.isPaymentNums(term.text)) {
      return /^Baixar Pagamento.*/.test(option.label);
    }
    if (this.isCondicionalNums(term.text)) {
      return /^Baixar Condicional.*/.test(option.label);
    }
    if (/^Baixar.*/.test(term.text)) {
      return false;
    }
    if (/^Pesquisar Cliente.*/.test(option.label)) {
      return true;
    }
    const reTerm = new RegExp(term.text, "i");
    return reTerm.test(option.label);
  };

  handleSelectOption = (options: QuickAction[]) => {
    if (options.length < 1) {
      return;
    }
    const option = options[0];

    if (option.includeText) {
      const textPrepared = this.state.barText.replace(/\//g, ":");
      try {
        navigate(`${option.path}${encodeURI(textPrepared)}`);
      } catch (error) {
        console.log("error on navigate", error);
      }
    } else {
      try {
        navigate(option.path);
      } catch (error) {
        console.log("error on navigate", error);
      }
    }
  };

  render() {
    return (
      // TypeaheadInput
      <Form inline className="flex-fill" onSubmit={(e) => e.preventDefault()}>
        <Typeahead
          id="quick-actions"
          labelKey={(option) =>
            `${option.label}`.replace("xxx", this.state.barText)
          }
          options={this.state.options}
          filterBy={this.handleFilter}
          emptyLabel="Nada encontrado"
          ref={(ref) => (this.refSearchBar = ref)}
          // @ts-ignore
          value={this.state.barText}
          onInputChange={(barText) => this.setState({ barText })}
          onChange={this.handleSelectOption}
          minLength={2}
          placeholder="Digite para buscar uma ação (Ex: cobrança)(Atalho: Ctrl + i)"
          className="mr-sm-2 flex-fill w-100"
          inputProps={{ className: "w-100" }}
        />
      </Form>
    );
  }
}

export default QuickActions;
