import { RouteComponentProps } from "@reach/router";
import React from "react";

export interface PoliticaPrivacidadeProps extends RouteComponentProps {}

const PoliticaPrivacidade: React.FC<PoliticaPrivacidadeProps> = (props) => {
  return (
    <>
      <h1>Política de Privacidade</h1>
      <h2>Apresentação</h2>
      <p>
        Em alinhamento à sua política de transparência junto aos clientes e em
        decorrência do que estabelece a lei no. 13.709 de 14 de agosto de 2018,
        a Nilton Tech Group descreve e implementa sua Política de Privacidade,
        conforme segue.
      </p>
      <h2>Definições</h2>
      <p>
        Lei Geral de Proteção de Dados (LGPD): dispõe sobre o tratamento de
        dados pessoais, inclusive nos meios digitais, por pessoa natural ou por
        pessoa jurídica de direito público ou privado, com o objetivo de
        proteger os direitos fundamentais de liberdade e de privacidade e o
        livre desenvolvimento da personalidade da pessoa natural;
      </p>
      <p>
        Dado pessoal: informação relacionada a pessoa natural identificada ou
        identificável;
      </p>
      <p>
        Dado pessoal sensível: dado pessoal sobre origem racial ou étnica,
        convicção religiosa, opinião política, filiação a sindicato ou a
        organização de caráter religioso, filosófico ou político, dado referente
        à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado
        a uma pessoa natural;
      </p>
      <p>
        Dado anonimizado: dado relativo a titular que não possa ser
        identificado, considerando a utilização de meios técnicos razoáveis e
        disponíveis na ocasião de seu tratamento;
      </p>
      <p>
        Titular: pessoa natural a quem se referem os dados pessoais que são
        objeto de tratamento;
      </p>
      <p>
        Controlador: pessoa natural ou jurídica, de direito público ou privado,
        a quem competem as decisões referentes ao tratamento de dados pessoais;
      </p>
      <p>
        Operador: pessoa natural ou jurídica, de direito público ou privado, que
        realiza o tratamento de dados pessoais em nome do controlador;
      </p>
      <p>Agentes de tratamento: o controlador e o operador;</p>
      <p>
        Tratamento: toda operação realizada com dados pessoais, como as que se
        referem a coleta, produção, recepção, classificação, utilização, acesso,
        reprodução, transmissão, distribuição, processamento, arquivamento,
        armazenamento, eliminação, avaliação ou controle da informação,
        modificação, comunicação, transferência, difusão ou extração;
      </p>
      <p>
        Consentimento: manifestação livre, informada e inequívoca pela qual o
        titular concorda com o tratamento de seus dados pessoais para uma
        finalidade determinada;
      </p>
      <p>
        Transferência internacional de dados: transferência de dados pessoais
        para país estrangeiro ou organismo internacional do qual o país seja
        membro;
      </p>
      <p>
        Uso compartilhado de dados: comunicação, difusão, transferência
        internacional, interconexão de dados pessoais ou tratamento
        compartilhado de bancos de dados pessoais por órgãos e entidades
        públicos no cumprimento de suas competências legais, ou entre esses e
        entes privados, reciprocamente, com autorização específica, para uma ou
        mais modalidades de tratamento permitidas por esses entes públicos, ou
        entre entes privados;
      </p>
      <p>
        Relatório de impacto à proteção de dados pessoais (DPIA): documentação
        do controlador que contém a descrição dos processos de tratamento de
        dados pessoais que podem gerar riscos às liberdades civis e aos direitos
        fundamentais, bem como medidas, salvaguardas e mecanismos de mitigação
        de risco;
      </p>
      <p>
        Autoridade nacional (ANPD): órgão da administração pública indireta
        responsável por zelar, implementar e fiscalizar o cumprimento desta Lei.
      </p>
      <p>
        Encarregado (DPO): pessoa natural, indicada pelo controlador, que atua
        como canal de comunicação entre o controlador e os titulares e a
        autoridade nacional;
      </p>
      <h2>Princípios</h2>
      <p>
        Concernente ao tratamento dos dados pessoais oriundos de seus clientes e
        com base no que determina o art. 6o da lei 13.709 de 14 de agosto de
        2018, a Nilton Tech Group observa os seguintes princípios:
      </p>
      <p>
        I – finalidade: realização do tratamento para propósitos legítimos,
        específicos, explícitos e informados ao titular, sem possibilidade de
        tratamento posterior de forma incompatível com essas finalidades;
      </p>
      <p>
        II – adequação: compatibilidade do tratamento com as finalidades
        informadas ao titular, de acordo com o contexto do tratamento;
      </p>
      <p>
        III – necessidade: limitação do tratamento ao mínimo necessário para a
        realização de suas finalidades, com abrangência dos dados pertinentes,
        proporcionais e não excessivos em relação às finalidades do tratamento
        de dados;
      </p>
      <p>
        IV – livre acesso: garantia, aos titulares, de consulta facilitada e
        gratuita sobre a forma e a duração do tratamento, bem como sobre a
        integralidade de seus dados pessoais;
      </p>
      <p>
        V – qualidade dos dados: garantia, aos titulares, de exatidão, clareza,
        relevância e atualização dos dados, de acordo com a necessidade e para o
        cumprimento da finalidade de seu tratamento;
      </p>
      <p>
        VI – transparência: garantia, aos titulares, de informações claras,
        precisas e facilmente acessíveis sobre a realização do tratamento e os
        respectivos agentes de tratamento, observados os segredos comercial e
        industrial;
      </p>
      <p>
        VII – segurança: utilização de medidas técnicas e administrativas aptas
        a proteger os dados pessoais de acessos não autorizados e de situações
        acidentais ou ilícitas de destruição, perda, alteração, comunicação ou
        difusão;
      </p>
      <p>
        VIII – prevenção: adoção de medidas para prevenir a ocorrência de danos
        em virtude do tratamento de dados pessoais;
      </p>
      <p>
        IX – não discriminação: impossibilidade de realização do tratamento para
        fins discriminatórios ilícitos ou abusivos;
      </p>
      <p>
        X – responsabilização e prestação de contas: demonstração, pelo agente,
        da adoção de medidas eficazes e capazes de comprovar a observância e o
        cumprimento das normas de proteção de dados pessoais e, inclusive, da
        eficácia dessas medidas.
      </p>
      <h2>Tratamento de dados pessoais (Ciclo de vida dos dados pessoais)</h2>
      <p>
        Ao acessar o site da Nilton Tech Group ou qualquer plataforma de
        operadores que ofereça produtos, serviços (treinamentos, cursos, etc) da
        Nilton Tech Group e introduzir seus dados pessoais, o titular terá suas
        informações pessoais tratadas de maneira padronizada, em conformidade
        descrito a seguir:
      </p>
      <h2>1. Origem dos dados</h2>
      <p>
        Os  dados pessoais e sensíveis que a Nilton Tech Group se compromete a
        controlar e proteger são oriundos das seguintes fontes:
      </p>
      <p>(i) dados pessoais dos colaboradores da Nilton Tech Group;</p>
      <p>
        (ii) os dados pessoais de potenciais clientes e clientes Nilton Tech
        Group;
      </p>
      <p>
        (iii) os dados pessoais de fornecedores e parceiros Nilton Tech Group.
      </p>
      <h2>2. Finalidade</h2>
      <p>Considerando:</p>
      <p>a) os dados pessoais dos colaboradores da Nilton Tech Group</p>
      <p>
        A coleta dos dados pessoais de colaboradores da Nilton Tech Group tem
        como finalidade específica o cumprimento da legislação trabalhista
        aplicável no que tange ao pagamento de salários e benefícios oferecidos
        pela Nilton Tech Group.
      </p>
      <p>
        Os dados pessoais de colaboradores da Nilton Tech Group não serão usados
        para tratamento posterior diferente do já exposto, salvo em casos de
        necessidade legal (resposta à passivos trabalhistas, solicitação da
        justiça para fins processuais, etc).
      </p>
      <p>
        b) os dados pessoais de potenciais clientes, clientes e parceiros Nilton
        Tech Group
      </p>
      <p>
        A coleta dos dados pessoais de potenciais clientes, clientes e parceiros
        Nilton Tech Group tem como finalidade específica a comercialização dos
        produtos e serviços oferecidos pela Nilton Tech Group e a prestação de
        serviços. Os dados pessoais serão usados para envio de mensagens com
        contexto comercial, bem como informações relacionadas aos produtos e
        serviços oferecidos pela Nilton Tech Group.
      </p>
      <p>
        Os dados não serão usados para tratamento posterior diferente do já
        exposto tal como enriquecimento de base de dados, venda de base de dados
        à terceiros, etc.
      </p>
      <p>c) os dados pessoais de fornecedores e parceiros Nilton Tech Group.</p>
      <p>
        A coleta dos dados pessoais de fornecedores e parceiros Nilton Tech
        Group tem como finalidade específica a contratação comercial para
        prestação de serviços. Os dados pessoais serão usados para cumprimento
        da legislação específica no que tange à emissão de notas fiscais e
        correlatos tendo, portanto, amparo legal.
      </p>
      <p>
        Os dados não serão usados para tratamento posterior diferente do já
        exposto, salvo em casos de necessidade legal (resposta à passivos
        trabalhistas, solicitação da justiça para fins processuais, etc).
      </p>
      <h2>3. Coleta</h2>
      <p>
        Os dados pessoais descritos no item 1 (Origem dos dados) serão coletados
        através de plataformas eletrônicas de cadastro integradas ao site da
        Nilton Tech Group e plataformas eletrônicas de cadastro integradas aos
        sites de operadores parceiros.
      </p>
      <h2>4. Classificação</h2>
      <p>Considerando:</p>
      <p>a) os dados pessoais dos colaboradores da Nilton Tech Group</p>
      <p>
        Para coleta dos dados pessoais dos colaboradores da Nilton Tech Group
        não há solicitação do consentimento de seus titulares por serem os dados
        pessoais amparados por base legal.
      </p>
      <p>
        b) os dados pessoais de potenciais clientes e clientes Nilton Tech Group
      </p>
      <p>
        Ao cadastrar os dados pessoais para acesso aos testes e/ou aquisição dos
        produtos e serviços da Nilton Tech Group, o titular é informado sobre a
        Política de Privacidade Nilton Tech Group (disponibilizada no site
        apl.notinha.app.br/politicaprivacidade) e sobre como seus dados serão
        tratados.
      </p>
      <p>
        Ao concordar com a Política de Privacidade Nilton Tech Group, o titular
        consente formalmente o uso de seus dados pessoais, em consonância com o
        descrito nesse documento.
      </p>
      <p>
        O não consentimento formal do uso de seus dados pessoais pela Nilton
        Tech Group e/ou seus operadores, pode acarretar ao potencial cliente ou
        cliente Nilton Tech Group impedimentos ou restrições de acesso aos
        produtos e serviços divulgados e comercializados.
      </p>
      <p>c) os dados pessoais de fornecedores e parceiros Nilton Tech Group</p>
      <p>
        Ao iniciar um relacionamento com a Nilton Tech Group, os fornecedores e
        parceiros são comunicados sobre a Política de Privacidade Nilton Tech
        Group.
      </p>
      <p>
        A formalização do consentimento ocorre na assinatura do contrato de
        prestação de serviços entre as partes.
      </p>
      <p>
        Ao concordar com a Política de Privacidade Nilton Tech Group, o titular
        consente formalmente o uso de seus dados pessoais, em consonância com o
        descrito nesse documento.
      </p>
      <p>
        O não consentimento formal do uso de seus dados pessoais pela Nilton
        Tech Group e/ou seus operadores, pode acarretar ao potencial fornecedor
        ou parceiro Nilton Tech Group a inviabilidade da prestação de serviços.
      </p>
      <h2>5. Acesso</h2>
      <p>
        A Nilton Tech Group assegura ao titular o livre acesso à seus dados a
        partir de prévia solicitação por escrito.  A partir de login e senha
        únicos fornecidos pela Nilton Tech Group ao titular, o mesmo poderá
        acessar seus dados pessoais a qualquer momento e solicitar a exclusão,
        alteração/atualização ou quaisquer informações correlatas à esse
        conteúdo.
      </p>
      <h2>6. Modificação</h2>
      <p>
        A Nilton Tech Group é a única autorizada a modificar, corrigir ou
        alterar os dados pessoais do titular, a partir da solicitação formal
        (autorização) do mesmo.
      </p>
      <p>
        A Nilton Tech Group se reserva o direito de efetuar as modificações
        solicitadas em até 30 dias da data da solicitação formal.
      </p>
      <h2>7. Atualização</h2>
      <p>
        Quando necessário e para assegurar que os dados pessoais do titular
        permaneçam válidos, a Nilton Tech Group poderá atualizá-los através de
        contato telefônico com o mesmo.
      </p>
      <h2>8. Reprodução</h2>
      <p>
        A reprodução dos dados pessoais que a Nilton Tech Group tem acesso
        considera:
      </p>
      <p>a) os dados pessoais dos colaboradores da Nilton Tech Group</p>
      <p>
        Os dados pessoais de colaboradores são reproduzidos para a empresa de
        contabilidade da Nilton Tech Group (operadora), responsável pelo
        processamento dos pagamentos e demais direitos trabalhistas assegurados
        por lei.
      </p>
      <p>
        b) os dados pessoais de potenciais clientes e clientes Nilton Tech Group
      </p>
      <p>
        A Nilton Tech Group reproduz os dados pessoais de seus potenciais
        clientes e clientes para empresas parceiras (operadoras), que são
        orientadas a tratar os dados em alinhamento ao que define a Nilton Tech
        Group (controlador), em conformidade com o descrito nesse documento.
      </p>
      <p>c) os dados pessoais de fornecedores e parceiros Nilton Tech Group</p>
      <p>
        Os dados pessoais de fornecedores e parceiros Nilton Tech Group são
        reproduzidos para a empresa de contabilidade da Nilton Tech Group
        (operadora), responsável pelo processamento dos pagamentos e demais
        direitos comerciais assegurados por lei.
      </p>
      <h2>9. Transmissão</h2>
      <p>
        Todos os dados pessoais que a Nilton Tech Group tem acesso são
        transmitidos para as operadoras através de planilhas eletrônicas
        protegidas por senha e/ou por e-mail.
      </p>
      <h2>10. Processamento</h2>
      <p>
        As empresas operadoras que se relacionam com a Nilton Tech Group são
        orientadas a tratar os dados pessoais de acordo com a finalidade
        explicitada nesse documento (Política de Privacidade Nilton Tech Group),
        sendo proibidas de enriquecimento de base e utilização destes para
        qualquer outra finalidade não comunicada e formalizada pela Nilton Tech
        Group.
      </p>
      <h2>11. Arquivamento</h2>
      <p>
        Os dados pessoais que a Nilton Tech Group controla são armazenados no
        banco de dados da Nilton Tech Group e nos servidores (cloud) de empresas
        parceiras operadoras.
      </p>
      <h2>12. Eliminação</h2>
      <p>
        Os dados pessoais que Nilton Tech Group controla ficam arquivados no
        banco de dados da Nilton Tech Group e nos servidores (cloud) de empresas
        parceiras operadoras por tempo indeterminado, salvo quando solicitado a
        exclusão por parte do titular.
      </p>
      <p>
        Dados pessoais dos colaboradores da Nilton Tech Group são eliminados
        somente após expirar os prazos legais aplicáveis a cada tipo de
        informação.
      </p>
    </>
  );
};

export default PoliticaPrivacidade;
