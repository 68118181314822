const prices =
  process.env.REACT_APP_STAGE === "prod"
    ? {
        basico: "price_1KDCuWAeOalWZYjgSjYrKTIV",
        basicoWhatsapp: "price_1LmL6yAeOalWZYjg94AH92L5",
        medio: "price_1KDCuTAeOalWZYjgxekFVSpq",
        medioWhatsapp: "price_1LmL6UAeOalWZYjgu5mG23RA",
        testeWhastapp: "none",
      }
    : {
        basico: "price_1JTYJRAeOalWZYjgzDkXE4fr",
        basicoWhatsapp: "price_1LmJk8AeOalWZYjg7gJPsCGy",
        medio: "price_1JTZ0LAeOalWZYjgBQXS1Ud0",
        medioWhatsapp: "price_1LmI3VAeOalWZYjg9PkCOMah",
        testeWhastapp: "price_1MMA4XAeOalWZYjgI3QSPuHK",
      };

export const plans = {
  trial: [
    "dashboard",
    "dest",
    "invoices",
    "sales",
    "services",
    "condPane",
    "task",
    "quicksale",
    "caixa",
    "repinvoices",
    "purchases",
    "prod",
    "payment",
    "emissor",
    "transp",
    "tipoop",
    "serie",
    "banco",
    "whatsapp",
  ],
  [prices.basico]: [
    "dashboard",
    "dest",
    "invoices",
    "sales",
    "quicksale",
    "caixa",
    "repinvoices",
    "prod",
    "emissor",
    "transp",
    "tipoop",
    "serie",
    "banco",
    "whatsapp",
  ],
  [prices.basicoWhatsapp]: [
    "dashboard",
    "dest",
    "invoices",
    "sales",
    "quicksale",
    "caixa",
    "repinvoices",
    "prod",
    "emissor",
    "transp",
    "tipoop",
    "serie",
    "banco",
    "whatsapp",
  ],
  [prices.medio]: [
    "dashboard",
    "dest",
    "invoices",
    "sales",
    "services",
    "condPane",
    "task",
    "quicksale",
    "caixa",
    "repinvoices",
    "purchases",
    "prod",
    "payment",
    "emissor",
    "transp",
    "tipoop",
    "serie",
    "banco",
  ],
  [prices.medioWhatsapp]: [
    "dashboard",
    "dest",
    "invoices",
    "sales",
    "services",
    "condPane",
    "task",
    "quicksale",
    "caixa",
    "repinvoices",
    "purchases",
    "prod",
    "payment",
    "emissor",
    "transp",
    "tipoop",
    "serie",
    "banco",
    "whatsapp",
  ],
  [prices.testeWhastapp]: [
    "dashboard",
    "dest",
    "invoices",
    "sales",
    "services",
    "condPane",
    "task",
    "quicksale",
    "caixa",
    "repinvoices",
    "purchases",
    "prod",
    "payment",
    "emissor",
    "transp",
    "tipoop",
    "serie",
    "banco",
    "whatsapp",
  ],
};
