import { subMonths } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DateField } from "../../components/FormikComponents/DateField";
import LoadingContainer from "../../components/LoadingContainer";
import ShowError from "../../components/ShowError";
import TableOrCard from "../../components/TableOrCard";
import { formatDate, formatNumber } from "../../misc/formatters";
import { RootState } from "../app/mainReducer";
import { Prod } from "./actions";
import { EstoqueRecord, prodActions } from "./reducer";

interface IListaEstoqueProps {
  prod: Prod;
}

const ListaEstoque: React.FunctionComponent<IListaEstoqueProps> = ({
  prod,
}) => {
  const isLoadingEstoque = useSelector(
    (state: RootState) => state.prod.list.isLoadingEstoque
  );
  const itensEstoque = useSelector(
    (state: RootState) => state.prod.list.itensEstoque
  );
  const estoqueError = useSelector(
    (state: RootState) => state.prod.list.estoqueError
  );
  const dispatch = useDispatch();
  const [data_inicial, setDataInicial] = React.useState(
    subMonths(new Date(), 1)
  );
  const [data_final, setDataFinal] = React.useState(new Date());

  useEffect(() => {
    dispatch(
      prodActions.buscarEstoqueRequest({
        data_inicial,
        data_final,
        prodId: prod.cadId,
      })
    );
  }, [prod, dispatch, data_inicial, data_final]);

  const headers = [
    {
      label: "#",
      dataIndex: "num",
    },
    {
      label: "Data",
      render: (item: EstoqueRecord) => formatDate(item.data),
    },
    {
      label: "Descrição",
      dataIndex: "desc",
    },
    {
      label: "Quantidade",
      render: (item: EstoqueRecord) => formatNumber(item.quantidade, 0, true),
    },
    {
      label: "Valor Unitário",
      render: (item: EstoqueRecord) =>
        formatNumber(item.valor_unitario, 2, true),
    },
    {
      label: "Total",
      render: (item: EstoqueRecord) =>
        formatNumber(item.valor_unitario * Math.abs(item.quantidade), 2, true),
    },
  ];

  return (
    <LoadingContainer isLoading={isLoadingEstoque}>
      <ShowError error={estoqueError} />
      <Row className="mt-2 mb-2">
        <Col>
          <Formik
            onSubmit={(values) => {
              console.log("values", values);
              setDataInicial(values.data_inicial);
              setDataFinal(values.data_final);
              // buscarEstoque();
            }}
            initialValues={{
              data_inicial,
              data_final,
            }}
          >
            {() => (
              <Form>
                <Row>
                  <Col>
                    <DateField label="Data Inicial" name="data_inicial" />
                  </Col>
                  <Col>
                    <DateField label="Data Final" name="data_final" />
                  </Col>
                  <Col className="d-flex align-items-center">
                    <Button type="submit" variant="primary">
                      Aplicar Filtro
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <TableOrCard headers={headers} items={itensEstoque} />
    </LoadingContainer>
  );
};

export default ListaEstoque;
