import React, { ReactNode } from "react";
import { Toast } from "react-bootstrap";

import "./Notification.css";

type NotificationProps = {
  onClose: () => void;
  title: string;
  message: string | ReactNode;
};

const Notification: React.FC<NotificationProps> = (props) => (
  <Toast className="Notification" onClose={props.onClose}>
    <Toast.Header>
      <strong className="mr-auto">{props.title}</strong>
    </Toast.Header>
    <Toast.Body>{props.message}</Toast.Body>
  </Toast>
);

export default Notification;
