import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Container, Form } from "react-bootstrap";
import _ from "underscore";
import moment from "moment";
import LoadingContainer from "../../../components/LoadingContainer";
import LoaderButton from "../../../components/LoaderButton";
import TaskComponent from "../TaskComponent";
import TaskForm from "../TaskForm";

import { pageInit, taskSaveRequest, newtaskSet, Task } from "../actions";
import { clientTaskRequest } from "./actions";
import { Dest } from "../../dest/actions";

type TaskViewProps = {
  newTask: Task;
  items: Task[];
  userRole: string;
  orgStatus: string;
  isRequesting: boolean;
  isSaving: boolean;
  pageInit: () => void;
  clientTaskRequest: (clientId: string) => void;
  taskSaveRequest: (task: Task, onFinishAction: any) => void;
  newtaskSet: (task: Partial<Task>) => void;
  dest: Dest;
  destId: string;
};

class TaskView extends Component<TaskViewProps, {}> {
  state = {};

  componentDidMount() {
    if (this.props.destId) {
      this.props.clientTaskRequest(this.props.destId);
    }
  }

  handleAddTask = (e) => {
    e.preventDefault();
    this.props.taskSaveRequest(
      {
        ...this.props.newTask,
        dest: this.props.dest,
      },
      clientTaskRequest(this.props.destId)
    );
  };

  handleFinishTask = (task) => {
    const saveTask = { ...task, finshedDate: new Date() };
    this.props.taskSaveRequest(saveTask, clientTaskRequest(this.props.destId));
  };

  handleUnfinishTask = (task) => {
    const saveTask = { ...task, finshedDate: null };
    this.props.taskSaveRequest(saveTask, clientTaskRequest(this.props.destId));
  };

  render() {
    if (!this.props.destId || !this.props.dest) {
      return null;
    }

    const items = _.filter(
      this.props.items || [],
      (item) =>
        !item.finshedDate && item.dest && item.dest.cadId === this.props.destId
    );
    const orderedItems = _.sortBy(items, (item) => item.dateDue);

    const validations = [
      this.props.newTask.summary &&
        this.props.newTask.summary.trim().length > 3,
      this.props.newTask.dateDue &&
        moment().isSameOrBefore(this.props.newTask.dateDue, "day"),
    ];

    const isValid = validations.reduce((a, p) => a && p);

    return (
      <>
        <Row>
          <Col>
            <h2>Tarefas do Cliente</h2>
          </Col>
        </Row>
        {/* Form de add task */}
        <Row>
          <Col className="mt-3 mb-3">
            <Form
              onSubmit={this.handleAddTask}
              className="border border-primary rounded mt-2 mb-2 pt-2 pr-2 pl-2 pb-2 bg-light"
            >
              <h3>Incluir tarefa</h3>
              <TaskForm
                task={this.props.newTask}
                noDest={true}
                onChange={(obj) =>
                  this.props.newtaskSet({ ...this.props.newTask, ...obj })
                }
              />
              <LoaderButton
                disabled={!isValid}
                isLoading={this.props.isSaving}
                type="submit"
              >
                Incluir tarefa
              </LoaderButton>
            </Form>
          </Col>
        </Row>
        <Row>
          <LoadingContainer isLoading={this.props.isRequesting}>
            {/* List of tasks */}
            <Container className="mb-3">
              <Row>
                <Col className="mb-3">
                  {orderedItems.map((item, i) => (
                    <TaskComponent
                      key={`${i}`}
                      {...item}
                      onFinish={() => this.handleFinishTask(item)}
                      isLoading={this.props.isSaving}
                      onUnfinish={() => this.handleUnfinishTask(item)}
                    />
                  ))}
                </Col>
              </Row>
            </Container>
          </LoadingContainer>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newTask: state.task.list.newTask,
    items: state.task.view.items,
    userRole: state.login.userRole,
    orgStatus: state.login.orgStatus,
    isRequesting: state.task.view.isRequesting,
    isSaving: state.task.list.isSaving,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: () => dispatch(pageInit()),
    clientTaskRequest: (clientId: string) =>
      dispatch(clientTaskRequest(clientId)),
    taskSaveRequest: (task: Task, onFinishAction: any) =>
      dispatch(taskSaveRequest(task, onFinishAction)),
    newtaskSet: (task: Partial<Task>) => dispatch(newtaskSet(task)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskView);
