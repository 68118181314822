import { RouteComponentProps, useParams } from "@reach/router";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "../../../components/FormikComponents/TextField";
import { LinkButton } from "../../../components/LinkButton";
import LoaderButton from "../../../components/LoaderButton";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import { RootState } from "../../app/mainReducer";
import { Banco } from "../types";
import { bancoFormActions } from "./reducer";

interface IBancoFormProps extends RouteComponentProps {}

const BancoForm: React.FunctionComponent<IBancoFormProps> = () => {
  const { id } = useParams();
  const isLoading = useSelector(
    (state: RootState) => state.banco.form.isLoading
  );
  const item = useSelector((state: RootState) => state.banco.form.item);
  const isSaving = useSelector((state: RootState) => state.banco.form.isSaving);
  const successMsg = useSelector(
    (state: RootState) => state.banco.form.successMsg
  );
  const error = useSelector((state: RootState) => state.banco.form.error);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("useEffect rodado", id);
    dispatch(bancoFormActions.initForm(id));
  }, [dispatch, id]);

  const handleSalvar = (values: Banco) => {
    dispatch(bancoFormActions.saveRequest(values));
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <Row>
        <Col>
          <h1>{id === "new" ? "Novo Banco" : "Editar Banco"}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <ShowError error={error} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ShowSuccess message={successMsg} />
        </Col>
      </Row>
      <Row>
        <Col className="mt-2 mb-2">
          <LinkButton to="/banco/" variant="primary">
            Todos os bancos
          </LinkButton>
        </Col>
      </Row>
      <Formik initialValues={item} onSubmit={handleSalvar}>
        {() => (
          <Form>
            <Row>
              <Col xs={12} md={2}>
                <TextField name="cod" autoFocus label="Código" />
              </Col>
              <Col xs={12} md={10}>
                <TextField name="name" label="Nome" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <TextField name="agencia" label="Agencia" />
              </Col>
              <Col xs={12} md={1}>
                <TextField name="agenciadv" label="DV-Agencia" />
              </Col>
              <Col xs={12} md={3}>
                <TextField name="conta" label="Conta" />
              </Col>
              <Col xs={12} md={1}>
                <TextField name="contadv" label="DV-Conta" />
              </Col>
              <Col xs={12} md={2}>
                <TextField name="codCliente" label="Código de Cliente" />
              </Col>
              <Col xs={12} md={2}>
                <TextField name="codClienteDv" label="DV-Código de Cliente" />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextField
                  name="sicrediChaveMaster"
                  type="password"
                  label="Chave master para emissão na api Sicredi"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-2 mb-3">
                <LoaderButton isLoading={isSaving} type="submit">
                  Salvar
                </LoaderButton>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </LoadingContainer>
  );
};

export default BancoForm;
