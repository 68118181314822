import { AWSRes } from "./../../redux/types";
// actions do modulo transp
export const TRANSP_INIT = "TRANSP_INIT";
export const TRANSP_REQUESTING = "TRANSP_REQUESTING";
export const TRANSP_SUCCESS = "TRANSP_SUCCESS";
export const TRANSP_ERROR = "TRANSP_ERROR";
export const CAD_TYPE = "CAD_TRANSP";
export const TRANSP_DELETE_REQUESTING = "TRANSP_DELETE_REQUESTING";
export const TRANSP_DELETE_SUCCESS = "TRANSP_DELETE_SUCCESS";
export const TRANSP_DELETE_ERROR = "TRANSP_DELETE_ERROR";

export type Transp = {
  cadId?: string;
  userId?: string;
  cadType: "CAD_TRANSP";
  version?: number;
  name: string;
  cnpj: string;
  ie: string;
  zipCode: string;
  address: string;
  addressNumber: string;
  city: string;
  estate: string;
};

export function pageInit() {
  return {
    type: TRANSP_INIT,
  };
}

export function transpRequest() {
  return {
    type: TRANSP_REQUESTING,
  };
}

export function transpSuccess(payload: AWSRes<Transp[]>) {
  return {
    type: TRANSP_SUCCESS,
    payload,
  };
}

export function transpError(error: any) {
  return {
    type: TRANSP_ERROR,
    error,
  };
}

export function transpDeleteRequest(cadId: string) {
  return {
    type: TRANSP_DELETE_REQUESTING,
    payload: cadId,
  };
}

export function transpDeleteSuccess() {
  return {
    type: TRANSP_DELETE_SUCCESS,
  };
}

export function transpDeleteError(error: any) {
  return {
    type: TRANSP_DELETE_ERROR,
    error,
  };
}
