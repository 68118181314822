import { Cond } from "./../saga";
// saga do form do modulo cond
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  COND_FORM_INIT,
  COND_SAVE_REQUESTING,
  formInitError,
  formInitSuccess,
  formSuccess,
  formError,
} from "./actions";
import { MOV_TYPE } from "../actions";
import executeApiRequest from "../../../misc/executeApiRequest";

const newCond = () => {
  return {
    movType: MOV_TYPE,
    num: "",
    emission: new Date(),
    dest: null,
    valor: 0,
    items: [],
  };
};

function condGetCall(movId: string) {
  return executeApiRequest<Cond>(`/cond/${movId}`, "GET", {}, {});
}

function* condInitWorker({ payload }: any) {
  try {
    if (payload === "new") {
      yield put(formInitSuccess(newCond()));
    } else {
      console.log("loading item");
      const res: Cond = yield call(condGetCall, `${payload}`);
      yield put(formInitSuccess(res));
    }
  } catch (error) {
    yield put(formInitError(error));
  }
}

function condSaveCall(cond: Cond) {
  if (cond.movId) {
    return executeApiRequest(`/cond/${cond.movId}`, "PUT", cond);
  } else {
    return executeApiRequest(`/cond`, "POST", cond);
  }
}

function* condSaveWorker({ payload }: any) {
  try {
    // Update
    const res: Cond = yield call(condSaveCall, payload);
    yield put(formSuccess(res));
  } catch (error) {
    console.log("error", error, error.response);
    yield put(formError(error.response.data));
  }
}

export default function* condFormSaga() {
  yield all([
    takeLatest(COND_FORM_INIT, condInitWorker),
    takeLatest(COND_SAVE_REQUESTING, condSaveWorker),
  ]);
}
