import React from "react";

type KpiProps = {
  title: string;
  value: string;
};

const Kpi: React.FC<KpiProps> = (props) => (
  <div className="saerp-kpi">
    <div className="saerp-kpi-title">{props.title}</div>
    <div className="saerp-kpi-value">{props.value}</div>
  </div>
);

export default Kpi;
