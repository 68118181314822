import React from "react";
import { Form } from "react-bootstrap";

type CsosnProps = {
  value: string;
  onChange: (value: string) => void;
};

const Csosn: React.FC<CsosnProps> = (props) => (
  <Form.Control
    as="select"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  >
    <option value="">Selecione um CSOSN</option>
    <option value="101">
      Tributada pelo Simples Nacional com permissão de crédito
    </option>
    <option value="102">
      Tributada pelo Simples Nacional sem permissão de crédito
    </option>
    <option value="103">
      Isenção do ICMS no Simples Nacional para faixa de receita bruta
    </option>
    <option value="201">
      Tributada pelo Simples Nacional com permissão de crédito e com cobrança do
      ICMS por substituição tributária
    </option>
    <option value="202">
      Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do
      ICMS por substituição tributária
    </option>
    <option value="203">
      Isenção do ICMS no Simples Nacional para faixa de receita bruta e com
      cobrança de ST
    </option>
    <option value="300">Imune</option>
    <option value="400">Não tributada</option>
    <option value="500">
      ICMS cobrado anteriormente por ST ou por antecipação
    </option>
    <option value="900">Outros</option>
  </Form.Control>
);

export default Csosn;
