import { PayloadAction } from "@reduxjs/toolkit";
import base64js from "base64-js";
import { all, call, put, takeLatest } from "redux-saga/effects";
import download from "../../../misc/download";
import executeApiRequest from "../../../misc/executeApiRequest";
import { AWSRes } from "./../../../redux/types";
import { Invoice, InvoiceParcela } from "./../actions";
import { invoicePaneActions } from "./reducer";

function invoiceCall(status: "OPEN" | "CLOSED" = "OPEN", lastKey = null) {
  return executeApiRequest<AWSRes<Invoice>>(
    "/invoice",
    "GET",
    {},
    {
      queryStringParameters: {
        lastKey: JSON.stringify(lastKey),
        idQuery: status,
        idFilterType: "START",
      },
    }
  );
}

function* invoicepaneOpenWorker() {
  try {
    const res: AWSRes<InvoiceParcela> = yield call(invoiceCall, "OPEN");
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: AWSRes<InvoiceParcela> = yield call(
          invoiceCall,
          "OPEN",
          res.lastKey
        );
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(invoicePaneActions.itemsOpenSuccess(res));
  } catch (error) {
    yield put(invoicePaneActions.itemsOpenError(error));
  }
}

function* invoicepaneClosedWorker() {
  try {
    const res: AWSRes<InvoiceParcela> = yield call(invoiceCall, "CLOSED");
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: AWSRes<InvoiceParcela> = yield call(
          invoiceCall,
          "CLOSED",
          res.lastKey
        );
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(invoicePaneActions.itemsClosedSuccess(res));
  } catch (error) {
    yield put(invoicePaneActions.itemsClosedError(error));
  }
}

function* getBoletoWorker({ payload }: PayloadAction<InvoiceParcela>) {
  try {
    const res = yield call(executeApiRequest, "/api_inter", "POST", {
      movId: payload.invoiceId,
    });
    console.log("res from getBoleto", res);
    const bPdf = base64js.toByteArray(res.pdf);
    download(
      bPdf,
      `Bol-${payload.num}-${payload.numparcela}.pdf`,
      "application/pdf"
    );
    yield put(invoicePaneActions.boletoSuccess(res.pdf));
  } catch (error) {
    console.log("error on getBoleto", JSON.stringify(error.response));
    yield put(
      invoicePaneActions.boletoError(
        error.response ? error.response.data.error : error
      )
    );
  }
}

function* getPixWorker({ payload }: PayloadAction<InvoiceParcela>) {
  try {
    const res = yield call(
      executeApiRequest,
      `/pix/${payload.invoiceId}`,
      "GET",
      {}
    );
    console.log("res from getPix", res);
    yield put(
      invoicePaneActions.pixSuccess(res.Attributes ? res.Attributes : res)
    );
  } catch (error) {
    console.log("error on getPix", JSON.stringify(error.response));
    yield put(
      invoicePaneActions.pixError(
        error.response ? error.response.data.error : error
      )
    );
  }
}

export default function* invoicePaneSaga() {
  yield all([
    takeLatest("invoicePane/requestItemsOpen", invoicepaneOpenWorker),
    takeLatest("invoicePane/requestItemsClosed", invoicepaneClosedWorker),
    takeLatest("invoicePane/requestBoleto", getBoletoWorker),
    takeLatest("invoicePane/requestPix", getPixWorker),
  ]);
}
