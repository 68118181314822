import moment from "moment";
import React, { Component } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "underscore";
import { LinkButton } from "../../../components/LinkButton";
import LoadingContainer from "../../../components/LoadingContainer";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import PaymentParcela from "../types";
import { clientPaymentRequest } from "./actions";

type PaymentViewProps = {
  items: PaymentParcela[];
  isRequesting: boolean;
  clientPaymentRequest: (clientId: string) => void;
  destId: string;
  orgStatus: string;
};

class PaymentView extends Component<PaymentViewProps, {}> {
  state = {};

  componentDidMount() {
    if (this.props.destId) {
      this.props.clientPaymentRequest(this.props.destId);
    }
  }

  render() {
    if (!this.props.destId) {
      return null;
    }
    const { orgStatus } = this.props;

    const payments = _.filter(
      this.props.items,
      (item) => item.dest && item.dest.cadId === this.props.destId
    );

    let parcelsVencer = _.filter(payments, (parcel) => {
      return (
        moment(parcel.vencimento).isSameOrAfter(new Date(), "day") &&
        parcel.status === "OPEN"
      );
    });
    let parcelsVencidas = _.filter(payments, (parcel) => {
      return (
        moment(parcel.vencimento).isBefore(new Date(), "day") &&
        parcel.status === "OPEN"
      );
    });
    let parcelsPagas = _.filter(
      payments,
      (parcel) => parcel.status === "CLOSED"
    );

    parcelsVencer = _.sortBy(parcelsVencer, (parcel) =>
      moment(parcel.vencimento).unix()
    );
    parcelsVencidas = _.sortBy(parcelsVencidas, (parcel) =>
      moment(parcel.vencimento).unix()
    );
    parcelsPagas = _.sortBy(
      parcelsPagas,
      (parcel) => moment(parcel.vencimento).unix() * -1
    );
    const totalVencer = _.reduce(parcelsVencer, (p, c) => p + c.valor, 0);
    const totalVencidas = _.reduce(parcelsVencidas, (p, c) => p + c.valor, 0);
    const totalPagas = _.reduce(parcelsPagas, (p, c) => p + c.valor, 0);

    const headers = [
      {
        label: "#",
        render: (item: PaymentParcela) => `${item.num}/${item.numparcela}`,
      },
      {
        label: "Emissão",
        render: (item: PaymentParcela) =>
          moment(item.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Valor",
        render: (item: PaymentParcela) => formatNumber(item.valor, 2),
      },
      {
        label: "Vencimento",
        render: (item: PaymentParcela) =>
          moment(item.vencimento).format("DD/MM/YYYY"),
      },
      {
        label: "Status",
        render: (item: PaymentParcela) =>
          item.status === "CLOSED" ? "Pago" : "Pendente",
      },
      {
        label: "Ações",
        render: (item: PaymentParcela) =>
          orgStatus === "ACTIVE" ? (
            <ButtonGroup>
              <LinkButton variant="info" to={`/payment/${item.invoiceId}`}>
                <i className="fas fa-edit" />
              </LinkButton>
              {item.status === "OPEN" && (
                <LinkButton
                  variant="light"
                  title="Baixar pagamento"
                  to={`/paymentPay/${item.num}:${item.numparcela}`}
                >
                  <i className="fas fa-cash-register" />
                </LinkButton>
              )}
            </ButtonGroup>
          ) : null,
      },
    ];

    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Pagamentos a vencer</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableOrCard headers={headers} items={parcelsVencer} />
            <p>
              <b>Total: {formatNumber(totalVencer, 2)}</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Pagamentos vencidos</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableOrCard headers={headers} items={parcelsVencidas} />
            <p>
              <b>Total: {formatNumber(totalVencidas, 2)}</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Pagamentos feitos</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableOrCard headers={headers} items={parcelsPagas} />
            <p>
              <b>Total: {formatNumber(totalPagas, 2)}</b>
            </p>
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.payment.view.items,
    isRequesting: state.payment.view.isRequesting,
    orgStatus: state.login.orgStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clientPaymentRequest: (clientId) =>
      dispatch(clientPaymentRequest(clientId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentView);
