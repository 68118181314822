import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "../type";

type servicesFormInitialState = {
  item: Service;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isSaving: boolean;
};

const initialState: servicesFormInitialState = {
  item: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
};

const reducerSlice = createSlice({
  name: "servicesForm",
  initialState,
  reducers: {
    serviceRequest(
      state,
      _: PayloadAction<{ invoiceId: string; duplicateId?: string }>
    ) {
      state.item = null;
      state.successMsg = "";
      state.error = "";
      state.isRequesting = true;
      state.isSaving = false;
    },
    serviceSuccess(state, { payload }: PayloadAction<Service>) {
      state.item = payload;
      state.isRequesting = false;
    },
    serviceError(state, { payload }: PayloadAction<string>) {
      state.isRequesting = false;
      state.error = payload;
    },
    saveRequest(state, _: PayloadAction<Service>) {
      state.isSaving = true;
      state.error = "";
      state.successMsg = "";
    },
    saveSuccess(state, { payload }: PayloadAction<Service>) {
      state.isSaving = false;
      state.item = payload;
      state.successMsg = `Serviço salvo com sucesso!`;
    },
    saveError(state, { payload }: PayloadAction<string>) {
      state.isSaving = false;
      state.error = payload;
    },
  },
});

export const servicesFormActions = reducerSlice.actions;

export default reducerSlice;
