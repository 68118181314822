import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../misc/executeApiRequest";
import { AWSRes } from "./../../redux/types";
import {
  pageInit,
  Tipoop,
  tipoopDeleteError,
  tipoopDeleteSuccess,
  tipoopError,
  tipoopSuccess,
  TIPOOP_DELETE_REQUESTING,
  TIPOOP_INIT,
} from "./actions";
import formSaga from "./tipoopForm/saga";

function tipoopCall() {
  return executeApiRequest<AWSRes<Tipoop[]>>("/tipoop", "GET", {}, {});
}

function* tipoopWorker() {
  try {
    const res: AWSRes<Tipoop[]> = yield call(tipoopCall);
    yield put(tipoopSuccess(res));
  } catch (error) {
    yield put(tipoopError(error));
  }
}

function* tipoopDeleteWorker({ payload }: any) {
  try {
    yield call(executeApiRequest, `/tipoop/${payload}`, "DELETE", {}, {});
    yield put(tipoopDeleteSuccess());
    yield put(pageInit());
  } catch (error) {
    yield put(tipoopDeleteError(error));
  }
}

export default function* tipoopSaga() {
  yield all([
    takeLatest(TIPOOP_INIT, tipoopWorker),
    takeLatest(TIPOOP_DELETE_REQUESTING, tipoopDeleteWorker),
    formSaga(),
  ]);
}
