import React from "react";
import { Form } from "react-bootstrap";
import { formatNumber } from "../misc/formatters";

type InputNumberProps = {
  id?: string;
  placeholder?: string;
  value: number;
  decimalPlaces?: number;
  onChange: (value: number) => void;
  onBlur?: (e: React.FocusEvent) => void;
  required?: boolean;
  disabled?: boolean;
  className?: string;
};

const InputNumber: React.FC<InputNumberProps> = (props) => {
  const decimalPlaces =
    props.decimalPlaces === 0 ? 0 : (props.decimalPlaces || 2) * 1;
  return (
    <>
      <Form.Control
        type="text"
        id={props.id}
        placeholder={props.placeholder}
        value={formatNumber(props.value, decimalPlaces)}
        onBlur={props.onBlur}
        isValid={props.required && props.value > 0}
        isInvalid={props.required && props.value <= 0}
        disabled={props.disabled}
        className={props.className}
        onChange={(e) => {
          const vl =
            "0".repeat(decimalPlaces + 1) +
            e.target.value.replace(/[^0-9]/, "");
          if (decimalPlaces === 0) {
            props.onChange(parseInt(vl));
          } else {
            const val = parseFloat(
              vl.substring(0, vl.length - decimalPlaces) +
                "." +
                vl.substring(vl.length - decimalPlaces)
            );
            props.onChange(val);
          }
        }}
      />
      <Form.Control.Feedback type="invalid">
        Preencha com um valor maior que zero
      </Form.Control.Feedback>
    </>
  );
};

export default InputNumber;
