import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import InputNumber from "../../components/InputNumber";
import LoaderButton from "../../components/LoaderButton";
import ShowError from "../../components/ShowError";
import { ThirdStepPayload, wizardActions } from "./reducer";

type ThirdStepProps = {
  isThirdStepRequesting: boolean;
  thirdStepError: string;
  thirdStepRequest: (payload: ThirdStepPayload) => void;
};

type ThirdStepState = {
  serie: string;
  serieStart: number;
};

class ThirdStep extends Component<ThirdStepProps, ThirdStepState> {
  state = {
    serie: "",
    serieStart: 1,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.thirdStepRequest({
      serie: this.state.serie,
      serieStart: this.state.serieStart,
    });
  };

  render() {
    const validForm = [
      this.state.serie &&
        this.state.serie.trim().length > 0 &&
        parseInt(this.state.serie.trim()) * 1 > 0,
      this.state.serieStart && this.state.serieStart * 1 > 0,
    ].reduce((a, p) => a && p);

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Form.Group as={Col}>
            <Form.Label id="title-serie" as="h3">
              Qual série de notas a utilizar?
            </Form.Label>
            <InputMask
              mask="999"
              maskPlaceholder=" "
              name="serie"
              value={this.state.serie}
              onChange={(e) => this.setState({ serie: e.target.value })}
              className="form-control text-input"
              placeholder="Ex: 001"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label id="label-start">
              Em qual numero se inicia a série?
            </Form.Label>
            <InputNumber
              value={this.state.serieStart}
              onChange={(serieStart) => this.setState({ serieStart })}
              decimalPlaces={0}
            />
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <ShowError error={this.props.thirdStepError} />
          </Col>
        </Row>
        <Row>
          <Col>
            <LoaderButton
              isLoading={this.props.isThirdStepRequesting}
              type="submit"
              disabled={!validForm}
            >
              Concluir
            </LoaderButton>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isThirdStepRequesting: state.wizard.isThirdStepRequesting,
    thirdStepError: state.wizard.thirdStepError,
  };
};
const dispatchProps = {
  thirdStepRequest: wizardActions.thirdStepRequest,
};

export default connect(mapStateToProps, dispatchProps)(ThirdStep);
