import React, { useState } from "react";
import PropTypes from "prop-types";
import { Badge, FormControl, InputGroup, Table } from "react-bootstrap";
import moment from "moment";
import { formatNumber } from "../misc/formatters";
import PaginationWidget from "./PaginationWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

type TableOrCardProps = {
  items: any[];
  headers: any[];
  itemClick?: (item: any, index: number) => void;
  itemVariant?: (item: any, index: number) => void;
};

const renderValue = (val: any) => {
  if (typeof val === "number") {
    if (Math.abs(Math.round(val) - val) > 0) {
      return formatNumber(val, 2);
    } else {
      return formatNumber(val);
    }
  }
  if (val instanceof Date) {
    return moment(val).format("DD/MM/YYYY");
  }
  return `${val}`;
};

const TableOrCard: React.FC<TableOrCardProps> = (props) => {
  // const isCardRender = window.innerWidth < 576
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  function filterItems(items: any[]) {
    try {
      if (searchText.trim().length < 1) {
        return items;
      }
      const reTest = new RegExp(searchText, "i");
      return items.filter((item) => reTest.test(JSON.stringify(item)));
    } catch (error) {
      console.log("error on filter", error);
      return items;
    }
  }
  const filteredItems = filterItems(props.items || []);
  const totalCount = filteredItems.length;
  const nPages = Math.ceil(totalCount / 100);
  const itemsPage = filteredItems.slice((page - 1) * 100, page * 100 - 1);
  /// Render table
  return (
    <>
      {props.items && props.items.length > 0 && (
        <InputGroup className="mb-2 col-12 col-md-3">
          <FormControl
            placeholder="Pesquisar"
            aria-label="Pesquisar"
            aria-describedby="search-addon"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <InputGroup.Text id="search-addon">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup>
      )}
      <Table striped hover variant="dark" className="px-xs-1 px-md-3">
        <thead>
          <tr className="d-none d-md-table-row">
            {props.headers.map((header, k) => (
              <th key={`${k}`}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {totalCount === 0 && (
            <tr>
              <th colSpan={100}>Nenhum item encontrado.</th>
            </tr>
          )}
          {itemsPage.map((item, i) => (
            <tr
              key={`${i}`}
              onClick={() =>
                typeof props.itemClick === "function" &&
                props.itemClick(item, i)
              }
              className={
                typeof props.itemVariant === "function"
                  ? `table-${props.itemVariant(item, i)}`
                  : ""
              }
            >
              {props.headers.map((header, k) => {
                if (typeof header.render === "function") {
                  return (
                    <td
                      key={`${k}`}
                      className="d-block d-sm-block d-md-table-cell d-lg-table-cell d-xl-table-cell"
                    >
                      <Badge
                        bg="secondary"
                        className="d-xs-inline d-sm-inline d-md-none d-lg-none d-xl-none mr-3"
                      >
                        {header.label}
                      </Badge>
                      {header.render(item, i)}
                    </td>
                  );
                }
                return (
                  <td
                    key={`${k}`}
                    className="d-block d-sm-block d-md-table-cell d-lg-table-cell d-xl-table-cell"
                  >
                    <Badge
                      bg="secondary"
                      className="d-xs-inline d-sm-inline d-md-none d-lg-none d-xl-none mr-3"
                    >
                      {header.label}
                    </Badge>
                    {renderValue(item[header.dataIndex])}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
        {nPages > 1 && (
          <tfoot>
            <tr>
              <td colSpan={100}>
                <PaginationWidget
                  totalPages={nPages}
                  currentPage={page}
                  onChangePage={(page) => setPage(page)}
                />
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
  // }
};

TableOrCard.propTypes = {
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
};

export default TableOrCard;
