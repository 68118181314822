import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import InputNumber from "../../../components/InputNumber";
import LoaderButton from "../../../components/LoaderButton";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import { RootState } from "../../app/mainReducer";
import { Prod } from "../actions";
import { inventoryActions, ProdInventory } from "./reducer";

export const Invetory: React.FC<{}> = () => {
  const isLoading = useSelector(
    (state: RootState) => state.prod.inventory.isLoading
  );
  const itens = useSelector((state: RootState) => state.prod.inventory.itens);
  const indexSaving = useSelector(
    (state: RootState) => state.prod.inventory.indexSaving
  );
  const isInventorySaving = useSelector(
    (state: RootState) => state.prod.inventory.isInventorySaving
  );
  const error = useSelector((state: RootState) => state.prod.inventory.error);
  const errors = useSelector((state: RootState) => state.prod.inventory.errors);
  const [qtdSalvar, setQtdSalvar] = useState({} as Record<number, number>);
  const dispatch = useDispatch();

  const headers = [
    {
      label: "Código",
      dataIndex: "cod",
    },
    {
      label: "Descrição",
      dataIndex: "name",
    },
    {
      label: "Preço",
      render: (item: Prod) => formatNumber(item.preco, 2, true),
    },
    {
      label: "Estoque",
      render: (item: Prod) => formatNumber(item.quantidadeAtual, 0, true),
    },
    {
      label: "Quantidade Inventariado",
      render: (item: Prod, i: number) => (
        <InputNumber
          value={qtdSalvar[i]}
          decimalPlaces={0}
          disabled={isInventorySaving}
          onChange={(qtd) => {
            const novoQtdSalvar = { ...qtdSalvar };
            novoQtdSalvar[i] = qtd;
            setQtdSalvar(novoQtdSalvar);
          }}
        />
      ),
    },
    {
      label: "Status",
      render: (_, i) =>
        errors[i] ? (
          <Alert variant="error">{errors[i]}</Alert>
        ) : isInventorySaving && indexSaving > i ? (
          <Alert variant="success">Concluido</Alert>
        ) : (
          isInventorySaving && <FontAwesomeIcon icon={faSync} spin />
        ),
    },
  ];

  useEffect(() => {
    dispatch(inventoryActions.initInventory());
  }, [dispatch]);

  const handleInventorySave = () => {
    const prodToSave: ProdInventory[] = [];

    for (const i in itens) {
      console.log("prod", i, itens[i], qtdSalvar[i]);
      if (
        qtdSalvar[i] > 0 &&
        Math.abs(itens[i].quantidadeAtual - qtdSalvar[i]) > 0
      ) {
        prodToSave.push({
          ...itens[i],
          qtdAlterar: qtdSalvar[i],
        });
      }
    }
    console.log("prodTosave", prodToSave);
    console.log("qtdSalvar", qtdSalvar);
    if (prodToSave.length > 0) {
      dispatch(inventoryActions.requestInventorySave(prodToSave));
    }
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <h1>Inventário do estoque</h1>
      <ShowError error={error} />
      <TableOrCard headers={headers} items={itens} />
      <LoaderButton
        isLoading={isInventorySaving}
        onClick={() => handleInventorySave()}
      >
        Concluir
      </LoaderButton>
    </LoadingContainer>
  );
};
