import { PayloadNfeAction, PayloadRequest, salesActions } from "./reducer";
import { AWSRes } from "./../../redux/types";
// saga do modulo cond
import { put, all, call, takeLatest, takeEvery } from "redux-saga/effects";
import formSaga from "./salesForm/saga";
import quicksaleSaga from "./QuickSale/saga";
import executeApiRequest from "../../misc/executeApiRequest";
import executeNFeRequest from "../../misc/executeNFeRequest";
import gnfe from "../../misc/gnfe";
import download from "../../misc/download";
import base64js from "base64-js";
import { PayloadAction } from "@reduxjs/toolkit";
import { Sale } from "./types";

function salesCall(
  lastKey = null,
  idQuery = "",
  idFilterType = "START",
  indexToUse = "lsi1"
) {
  return executeApiRequest<AWSRes<Sale[]>>(
    "/sale",
    "GET",
    {},
    {
      queryStringParameters: {
        lastKey: JSON.stringify(lastKey),
        idQuery,
        idFilterType,
        indexToUse,
      },
    }
  );
}

function* salesWorker({ payload }: PayloadAction<PayloadRequest>) {
  try {
    const res: AWSRes<Sale> = {
      items: [],
      Count: 0,
      ScannedCount: 0,
    };
    for (const tag of payload.tags) {
      const res1: AWSRes<Sale> = yield call(
        salesCall,
        null,
        payload.inicio && payload.fim
          ? `${tag}.${payload.inicio}:${tag}.${payload.fim}`
          : tag,
        payload.inicio && payload.fim ? "BETWEEN" : "START",
        payload.modo
      );
      res.items = [...res.items, ...res1.items];
      res.Count += res1.Count;
      res.ScannedCount += res1.ScannedCount;
      res.lastKey = res1.lastKey;
      if (res.lastKey) {
        for (let i = 0; i < 5; i++) {
          const res1: AWSRes<Sale> = yield call(
            salesCall,
            res.lastKey,
            payload.inicio && payload.fim
              ? `${tag}.${payload.inicio}:${tag}.${payload.fim}`
              : tag,
            payload.inicio && payload.fim ? "BETWEEN" : "START",
            payload.modo
          );
          res.items = [...res.items, ...res1.items];
          res.Count += res1.Count;
          res.ScannedCount += res1.ScannedCount;
          res.lastKey = res1.lastKey;
          if (!res1.lastKey) {
            break;
          }
        }
      }
    }
    yield put(salesActions.salesSuccess(res));
  } catch (error) {
    yield put(salesActions.salesError(error));
  }
}

function* salesDeleteWorker({ payload }: PayloadAction<Sale>) {
  try {
    yield call(
      executeApiRequest,
      `/sale/${payload.invoiceId}`,
      "DELETE",
      {},
      {}
    );
    yield put(salesActions.deleteSuccess());
    yield put(salesActions.salesRequest());
  } catch (error) {
    yield put(salesActions.deleteError(error));
  }
}

function callGeraNfe(sale: Sale) {
  return new Promise(async (resolve, reject) => {
    if (!sale) {
      return reject("Venda informada é inválida");
    }
    if (sale.NFe && sale.NFe.autorizado === true) {
      return reject(
        "Nota ja foi autorizada, não é possível gera-la novamente!"
      );
    }
    console.log("generating nfe");
    const NFet = await gnfe(sale);
    console.log("generating nfe 2", NFet.xml);
    return resolve({
      ...sale,
      NFe: NFet,
    });
  });
}

function* geraNfeWorker({ payload }: PayloadNfeAction) {
  try {
    if (!payload.sale.invoiceId) {
      throw new Error("Nota inválida para gerar NFe");
    }
    const saleWithNFe = yield call(callGeraNfe, payload.sale);
    saleWithNFe.tag = "NAOAUTORIZADA";
    yield call(
      executeApiRequest,
      `/sale/${payload.sale.invoiceId}`,
      "PUT",
      saleWithNFe
    );
    yield put(salesActions.geranfeSuccess());
    console.log("successDispatch", payload.onSuccessDispatch);
    if (typeof payload.onSuccessDispatch === "function") {
      payload.onSuccessDispatch();
    } else {
      yield put(salesActions.salesRequest());
    }
  } catch (error) {
    if (error.response) {
      yield put(salesActions.geranfeError(error.response));
      return;
    }
    yield put(salesActions.geranfeError(error));
  }
}

function* enviarnfeWorker({ payload }: PayloadNfeAction) {
  try {
    if (
      !payload ||
      (payload.sale.NFe && payload.sale.NFe.autorizado === true)
    ) {
      throw new Error("A Nota é inválida para enviar ou ja foi autorizada");
    }
    // Gera o XML
    console.log("emitindo nfe", payload.sale.orgId, payload.sale.invoiceId);
    const saleWithNFe = yield call(callGeraNfe, payload.sale);
    yield call(
      executeApiRequest,
      `/sale/${payload.sale.invoiceId}`,
      "PUT",
      saleWithNFe
    );
    console.log("emitindo nfe", payload.sale.orgId, payload.sale.invoiceId);

    // Enviar a NFe
    const res = yield call(
      executeNFeRequest,
      payload.sale.modelo === "65" ? "/emitirnfce" : "/emitir",
      "GET",
      {},
      {
        queryStringParameters: {
          invoiceId: payload.sale.invoiceId,
          orgId: payload.sale.orgId,
        },
      }
    );
    console.log("chamado enviar nfe");
    if (res.autorizado) {
      // NFe autorizado
      console.log("NFe autorizada com sucesso");
      yield put(salesActions.enviarnfeSuccess());
      if (typeof payload.onSuccessDispatch === "function") {
        payload.onSuccessDispatch();
      } else {
        yield put(salesActions.salesRequest());
      }
    } else {
      console.log("Erro ao enviar NFe", res.erros, res.xmlErro);
      if (typeof payload.onSuccessDispatch === "function") {
        payload.onSuccessDispatch();
      }
      yield put(
        salesActions.enviarnfeError(`NFe possui o seguinte erro: ${res.erros}`)
      );
    }
  } catch (error) {
    console.log("error on send nfe", error, error.response);
    if (error.response) {
      yield put(salesActions.enviarnfeError(error.response.data));
      return;
    }
    yield put(salesActions.enviarnfeError(error));
  }
}

function* downloaddanfeWorker({ payload }: PayloadAction<Sale>) {
  try {
    const res = yield call(
      executeNFeRequest,
      payload.modelo === "65" ? "/danfce" : "/danfe",
      "GET",
      {},
      {
        queryStringParameters: {
          invoiceId: payload.invoiceId,
          orgId: payload.orgId,
        },
      }
    );
    const bPdf = base64js.toByteArray(res.danfePDF);
    download(bPdf, `NFe-${payload.NFe.chNFe}.pdf`, "application/pdf");
    yield put(salesActions.downloadDanfeSuccess());
  } catch (error) {
    yield put(
      salesActions.downloadDanfeError(!!error.response ? error.response : error)
    );
  }
}

function* cancelanfeWorker({ payload }: PayloadNfeAction) {
  try {
    if (
      !payload ||
      !payload.sale ||
      !payload.sale.NFe ||
      payload.sale.NFe.cancelado === true ||
      !payload.sale.NFe.autorizado
    ) {
      throw new Error("A Nota é inválida para enviar ou ja foi cancelada");
    }

    const res = yield call(
      executeNFeRequest,
      "/cancelar",
      "GET",
      {},
      {
        queryStringParameters: {
          invoiceId: payload.sale.invoiceId,
          orgId: payload.sale.orgId,
        },
      }
    );
    if (res.cancelado) {
      // NFe cancelada
      console.log("NFe cancelada com sucesso");
      yield put(salesActions.cancelanfeSuccess());
      typeof payload.onSuccessDispatch === "function"
        ? payload.onSuccessDispatch()
        : yield put(salesActions.salesRequest());
    } else {
      console.log("Erro ao cancelar NFe", res.xMotivo, res.xmlErro);
      yield put(
        salesActions.cancelanfeError(
          `Cancelamento de NFe possui o seguinte erro: ${res.xMotivo}`
        )
      );
    }
  } catch (error) {
    yield put(
      salesActions.cancelanfeError(!!error.response ? error.response : error)
    );
  }
}

function* sendnfeWorker({ payload }: PayloadAction<Sale & { email: string }>) {
  try {
    const res = yield call(
      executeNFeRequest,
      "/enviar",
      "GET",
      {},
      {
        queryStringParameters: {
          invoiceId: payload.invoiceId,
          orgId: payload.orgId,
          email: payload.email,
        },
      }
    );
    if (res.sent) {
      // NFe autorizado
      const email_sent =
        payload.dest && !!payload.dest.email
          ? payload.dest.email
          : payload.email;
      console.log("NFe enviada com sucesso");
      yield put(
        salesActions.sendnfeSuccess(
          `A nota ${payload.num} foi enviada para o email ${email_sent} com sucesso!`
        )
      );
    } else {
      yield put(salesActions.sendnfeError(res.erros));
      console.log("Erro ao enviar NFe", res.erros, res.xmlErro);
    }
  } catch (error) {
    console.log("erroron send nfe", error);
    yield put(
      salesActions.sendnfeError(!!error.response ? error.response : error)
    );
  }
}

export default function* salesSaga() {
  yield all([
    takeLatest("sales/salesRequest", salesWorker),
    takeLatest("sales/deleteRequest", salesDeleteWorker),
    takeEvery("sales/geranfeRequest", geraNfeWorker),
    takeEvery("sales/enviarnfeRequest", enviarnfeWorker),
    takeEvery("sales/downloadDanfeRequest", downloaddanfeWorker),
    takeEvery("sales/cancelanfeRequest", cancelanfeWorker),
    takeEvery("sales/sendnfeRequest", sendnfeWorker),
    formSaga(),
    quicksaleSaga(),
  ]);
}
