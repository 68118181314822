import React from "react";
import { Form } from "react-bootstrap";

type CstIpiProps = {
  value: string;
  onChange: (value: string) => void;
  type: "E" | "S";
};

const CstIpi: React.FC<CstIpiProps> = (props) => (
  <Form.Control
    as="select"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  >
    {props.type === "E" ? (
      <>
        <option value="00">Entrada com recuperação de crédito</option>
        <option value="01">Entrada tributada com alíquota zero</option>
        <option value="02">Entrada isenta</option>
        <option value="03">Entrada não-tributada</option>
        <option value="04">Entrada imune</option>
        <option value="05">Entrada com suspensão</option>
        <option value="49">Outras entradas</option>
      </>
    ) : null}
    {props.type === "S" ? (
      <>
        <option value="50">Saída tributada</option>
        <option value="51">Saída tributada com alíquota zero</option>
        <option value="52">Saída isenta</option>
        <option value="53">Saída não-tributada</option>
        <option value="54">Saída imune</option>
        <option value="55">Saída com suspensão</option>
        <option value="99">Outras saídas</option>
      </>
    ) : null}
  </Form.Control>
);

export default CstIpi;
