import React from "react";
import { Form } from "react-bootstrap";

type ModBcIcmsProps = {
  value: string;
  onChange: (value: string) => void;
};

const ModBcIcms: React.FC<ModBcIcmsProps> = (props) => (
  <Form.Control
    as="select"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  >
    <option value="0">MVA percentual</option>
    <option value="1">Pauta (valor)</option>
    <option value="2">Preço Tabelado maximo (valor)</option>
    <option value="3">Valor da Operação</option>
  </Form.Control>
);

export default ModBcIcms;
