import { redirectTo, RouteComponentProps, useLocation } from "@reach/router";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";
import { useIsSubscriptionValid } from "../misc/useIsSubscriptionValid";
import { useIsAdmin } from "../misc/useIsAdmin";

interface AdminRouteProps extends RouteComponentProps {
  as: React.ElementType;
  children?: React.ReactNode;
}

export const AdminRoute: React.FC<AdminRouteProps> = ({
  as: Comp,
  ...rest
}) => {
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector(
    (state: RootState) => state.login.isAuthenticated
  );
  const isSubscriptionValid = useIsSubscriptionValid();
  const isAdmin = useIsAdmin();
  console.log("path", pathname, search);
  if (!isAuthenticated) {
    redirectTo(`/login?redirect=${pathname}${search}`);
  }
  if (!isSubscriptionValid) {
    redirectTo(`/subscription/subscriptions`);
  }
  if (!isAdmin) {
    redirectTo(`/admin-only`);
  }
  // const Comp = as;
  return <Comp {...rest} />;
};

export default AdminRoute;
