import React, { Component } from "react";
import { connect } from "react-redux";
import { pageInit, Serie, serieDeleteRequest } from "./actions";
import LoadingContainer from "../../components/LoadingContainer";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import TableOrCard from "../../components/TableOrCard";
import DeleteButton from "../../components/DeleteButton";
import { Link } from "@reach/router";

type SeriesProps = {
  items: Serie[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isDeleting: boolean;
  pageInit: () => void;
  deleteRequest: (cadId: string) => void;
};

class Series extends Component<SeriesProps, {}> {
  state = {};

  headers = [
    {
      label: "Código",
      dataIndex: "cod",
    },
    {
      label: "Descrição",
      dataIndex: "name",
    },
    {
      label: "Ações",
      render: (item: Serie) => (
        <ButtonGroup>
          <Button variant="info" as={Link} to={`/serie/${item.cadId}`}>
            <i className="fas fa-edit" />
          </Button>
          <DeleteButton
            isRequesting={this.props.isDeleting}
            onDelete={() => this.handleDelete(item)}
          />
        </ButtonGroup>
      ),
    },
  ];

  componentDidMount() {
    this.props.pageInit();
  }

  handleDelete = (item: Serie) => {
    this.props.deleteRequest(item.cadId);
  };

  render() {
    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Séries</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <Button variant="primary" as={Link} to="/serie/new">
              Incluir
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={this.props.items} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.serie.list.items,
    Count: state.serie.list.Count,
    lastKey: state.serie.list.lastKey,
    isRequesting: state.serie.list.isRequesting,
    isDeleting: state.serie.list.isDeleting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: () => dispatch(pageInit()),
    deleteRequest: (cadId: string) => dispatch(serieDeleteRequest(cadId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Series);
