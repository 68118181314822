import React, { Component } from "react";
import { connect } from "react-redux";
import { formInit, formSave } from "./actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LoadingContainer from "../../../components/LoadingContainer";
import TextInput from "../../../components/FormikComponents/TextInput";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import LoaderButton from "../../../components/LoaderButton";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import { Link } from "@reach/router";
import { Serie } from "../actions";

type SerieFormProps = {
  item: Serie;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  formInit: (cadId: string) => void;
  formSave: (cad: Serie) => void;
  id: string;
};

class SerieForm extends Component<SerieFormProps, {}> {
  state = {};

  componentDidMount() {
    this.props.formInit(this.props.id);
  }

  handleSubmit = (item) => {
    this.props.formSave(item);
  };

  render() {
    const isSaving = this.props.isSaving;

    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>Série</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" as={Link} to="/serie">
              Todas as séries
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              cod: Yup.string().required(
                "Preenchimento do código é obrigatório"
              ),
              name: Yup.string().required(
                "Preenchimento da descrição é obrigatório"
              ),
            })}
          >
            {(props) => {
              const { isValid, handleSubmit } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <MaskInput
                      name="cod"
                      label="Código"
                      mask="999"
                      formik={props}
                      size={2}
                    />
                    <TextInput
                      name="name"
                      label="Descrição"
                      formik={props}
                      size={10}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                      >
                        Salvar
                      </LoaderButton>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </LoadingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    item: state.serie.form.item,
    successMsg: state.serie.form.successMsg,
    error: state.serie.form.error,
    isRequesting: state.serie.form.isRequesting,
    isSaving: state.serie.form.isSaving,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    formInit: (cadId) => dispatch(formInit(cadId)),
    formSave: (cad) => dispatch(formSave(cad)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SerieForm);
