import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import NotaPromissoria from "../../../components/NotaPromissoria";

type InvoicePrintProps = {
  printNotas: any[];
};

class invoicePrint extends Component<InvoicePrintProps, {}> {
  state = {};

  render() {
    const notas = this.props.printNotas || [];
    if (!notas) {
      return (
        <Row>
          <Col>
            <h3>Nenhuma cobrança selecionada para impressão</h3>
          </Col>
        </Row>
      );
    }
    return (
      <>
        {notas.map((nota, i) => (
          <NotaPromissoria key={`${i}`} {...nota} />
        ))}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    printNotas: state.invoice.pane.printNotas,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(invoicePrint);
