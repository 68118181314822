import { Link, navigate } from "@reach/router";
import moment from "moment";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../components/LoadingAnimation";
import ShowError from "../../components/ShowError";
import { formatNumber } from "../../misc/formatters";
import { RootState } from "../app/mainReducer";
import { subcsriptionActions } from "./reducer";

export interface SubscriptionsProps {}

const subscriptionStatus = (status: string) => {
  switch (status) {
    case "canceled":
      return "Cancelado";
    case "incomplete":
      return "Incompleto";
    case "incomplete_expired":
      return "Incompleto e expirado";
    case "ended":
      return "Finalizado";
    case "active":
      return "Ativa";
    case "trialing":
      return "Testando";
    case "past_due":
      return "Em atraso";
    default:
      return status;
  }
};

const Subscriptions: React.FC<SubscriptionsProps> = (props) => {
  const dispatch = useDispatch();

  const isSubscriptionsRequesting = useSelector(
    (state: RootState) => state.subscription.isSubscriptionsRequesting
  );
  const subscriptions = useSelector(
    (state: RootState) => state.subscription.subscriptions
  );
  const subscriptionsError = useSelector(
    (state: RootState) => state.subscription.subscriptionsError
  );

  useEffect(() => {
    dispatch(subcsriptionActions.subscriptionsRequest());
  }, [dispatch]);

  console.log("subscribe", subscriptions);
  return (
    <>
      <h1>Informações da conta</h1>
      {isSubscriptionsRequesting && <LoadingAnimation />}
      {!isSubscriptionsRequesting && <ShowError error={subscriptionsError} />}
      {!isSubscriptionsRequesting &&
        subscriptions &&
        subscriptions.filter(
          (subscription) =>
            subscription.status !== "canceled" &&
            subscription.status !== "incomplete" &&
            subscription.status !== "incomplete_expired" &&
            subscription.status !== "ended"
        ).length === 0 && (
          <>
            <h3>Nenhuma assinatura ativa encontrada</h3>
            <Link to="/subscription/prices">
              <Button>Iniciar uma assinatura</Button>
            </Link>
          </>
        )}
      {!isSubscriptionsRequesting &&
        !subscriptionsError &&
        subscriptions &&
        subscriptions
          .filter(
            (subscription) =>
              subscription.status !== "canceled" &&
              subscription.status !== "incomplete" &&
              subscription.status !== "incomplete_expired" &&
              subscription.status !== "ended"
          )
          .map((subscription) => (
            <>
              <h3>Assinatura do plano {subscription.plan?.product?.name}</h3>
              <p>Status {subscriptionStatus(subscription.status)} </p>
              {subscription.status === "past_due" && (
                <p>
                  Houveram {subscription.latest_invoice.attempt_count}{" "}
                  tentativas de cobrança sem sucesso, para resolver altere o
                  cartão e seus dados de cobrança.
                </p>
              )}
              <p>
                Valor cobrado R${" "}
                {formatNumber(subscription.plan?.amount / 100, 2)}{" "}
              </p>
              <p>
                Ultimos 4 digitos do cartão
                {subscription.default_payment_method?.card?.last4}
              </p>
              <p>
                Data da ultima cobrança{" "}
                {moment(
                  new Date(subscription.latest_invoice?.period_start * 1000)
                ).format("DD/MM/yyyy")}
              </p>
              <p>
                Previsão da próxima cobrança{" "}
                {moment(
                  new Date(subscription.current_period_end * 1000)
                ).format("DD/MM/yyyy")}{" "}
              </p>
              <Button
                onClick={() => {
                  console.log("Alterar cartão");
                  dispatch(subcsriptionActions.subscriptionInit());
                  try {
                    navigate(`/subscription/prices`);
                  } catch (error) {
                    console.log("error on redirect", error);
                  }
                }}
              >
                Alterar Assinatura
              </Button>
              <Link to={`/subscription/cancel/${subscription.id}`}>
                <Button variant="danger">Cancelar</Button>
              </Link>
            </>
          ))}
    </>
  );
};

export default Subscriptions;
