import { all, call, put, takeEvery } from "redux-saga/effects";
import executeApiRequest from "../../misc/executeApiRequest";
import {
  sendinviteError,
  sendinviteSuccess,
  SENDINVITE_REQUESTING,
} from "./actions";
// import { orgRequest } from '../login/actions'
// import { USERPAGE_INIT } from './actions'

// function* pageInitWorker({ payload }) {
//   try {
//     yield put(orgRequest(payload))
//   } catch (error) {
//     console.log('error on requesting org', error)
//   }
// }

function* sendinviteWorker({ payload }: any) {
  try {
    yield call(executeApiRequest, "/send_invite", "POST", payload, {});
    yield put(sendinviteSuccess());
  } catch (error) {
    yield put(sendinviteError(error.response ? error.response : error));
  }
}

export default function* profileSaga() {
  yield all([
    // takeLatest(USERPAGE_INIT, pageInitWorker()),
    takeEvery(SENDINVITE_REQUESTING, sendinviteWorker),
  ]);
}
