import * as React from "react";
import { ButtonProps } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoaderButton from "../../components/LoaderButton";
import { RootState } from "../app/mainReducer";
import { servicesActions } from "./reducer";
import { Service } from "./type";

interface IBCancelaNfs extends ButtonProps {
  servico: Service;
  disabled: boolean;
}

const BCancelaNfs: React.FunctionComponent<IBCancelaNfs> = ({
  servico,
  disabled,
  ...props
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.services.list.isCancelingNFs
  );

  return (
    <LoaderButton
      {...props}
      isLoading={isLoading}
      disabled={!servico || disabled}
      onClick={() => dispatch(servicesActions.cancelaNfsRequest(servico))}
    ></LoaderButton>
  );
};

export default BCancelaNfs;
