import React from "react";
import { Button } from "react-bootstrap";

import "./PlanCard.css";

type PlanCardProps = {
  selected: boolean;
  planName: string;
  resources: string[];
  trialDays: number;
  planId: string;
  price: number;
  isEnabled: boolean;
  upgradeRequest?: (planId: string) => void;
};

const PlanCard: React.FC<PlanCardProps> = (props) => (
  <div className={`plan-card ${props.selected ? "plan-card-selected" : ""}`}>
    <div className="plan-card-header">{props.planName}</div>
    <div className="plan-card-body">
      Recursos:
      <ul>
        {props.resources.map((res, i) => (
          <li key={`${i}`}>{res}</li>
        ))}
      </ul>
    </div>
    {props.trialDays && props.selected && props.planId === "-" ? (
      <div className="plan-card-price">
        {" "}
        {props.trialDays > 0 ? `${props.trialDays} Dias Restantes` : `Expirado`}
      </div>
    ) : null}
    {props.price > 0 && (
      <div className="plan-card-price">
        {!props.selected && props.price > 0 && (
          <Button
            disabled={!props.isEnabled}
            onClick={() =>
              typeof props.upgradeRequest === "function" &&
              props.upgradeRequest(props.planId)
            }
          >
            Assinar
          </Button>
        )}
        <span>R$ {props.price.toFixed(2).replace(".", ",")}/m&ecirc;s</span>
      </div>
    )}
  </div>
);

export default PlanCard;
