import executeApiRequest from "./executeApiRequest";

export const saveOrg = async (orgObj: any) => {
  const newOrg = await executeApiRequest<any>(`/org`, "PUT", orgObj, {});
  return newOrg;
};

export const loadOrg = async () => {
  const organizations = await executeApiRequest<any>(`/org`, "GET", {}, {});
  console.log("loadOrg -> returned", organizations);
  if (organizations && organizations.items.length > 0) {
    return organizations.items[0];
  }
  return {};
};

export const setDefaultEmissor = async (emissorId: string, org: any) => {
  const newOrg = Object.assign({}, org, { emissorId });
  console.log("newOrg", newOrg);
  return await saveOrg(newOrg);
};
