import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderButton from "../../components/LoaderButton";
import ModalDialog from "../../components/ModalDialog";
import { Button, Form } from "react-bootstrap";
import { Sale } from "./types";
import { salesActions } from "./reducer";

type EmailNfeButtonDispatchProps = {
  sendnfeRequest: (payload: Sale & { email: string }) => void;
};

type EmailNfeButtonStateProps = {
  isSendingNfe: boolean;
};

type EmailNfeButtonProps = {
  sale: Sale;
  disabled?: boolean;
  children: React.ReactNode;
  variant?: string;
  title?: string;
};

type EmailNfeButtonState = {
  isEmailDialogOpen: boolean;
  emailAddress: string;
};

class EmailNfeButton extends Component<
  EmailNfeButtonProps & EmailNfeButtonDispatchProps & EmailNfeButtonStateProps,
  EmailNfeButtonState
> {
  state = {
    isEmailDialogOpen: false,
    emailAddress: "",
  };

  handleNFeRequest = (e) => {
    e.preventDefault();
    if (!!this.props.sale) {
      this.props.sendnfeRequest({
        ...this.props.sale,
        email: this.state.emailAddress,
      });
    }
    this.setState({
      isEmailDialogOpen: false,
    });
  };

  render() {
    const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      this.state.emailAddress
    );

    return (
      <>
        {/* Modal for getting the email address to send */}
        <ModalDialog
          show={this.state.isEmailDialogOpen}
          onClose={() => this.setState({ isEmailDialogOpen: false })}
          title="Qual e-mail enviar?"
          buttons={[
            <LoaderButton
              isLoading={this.props.isSendingNfe}
              disabled={!isEmailValid}
              onClick={this.handleNFeRequest}
            >
              Confirmar
            </LoaderButton>,
          ]}
        >
          <Form.Group controlId="emailAddress">
            <Form.Label>Email a enviar: </Form.Label>
            <Form.Control
              type="email"
              placeholder="Endereço de email"
              value={this.state.emailAddress}
              onChange={(e) => this.setState({ emailAddress: e.target.value })}
            />
            {!isEmailValid && this.state.emailAddress.length > 0 && (
              <Form.Control.Feedback type="invalid">
                Deve ser preenchido um email válido!
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </ModalDialog>
        <Button
          {...this.props}
          disabled={this.props.disabled || !this.props.sale}
          onClick={() =>
            this.setState({
              isEmailDialogOpen: true,
              emailAddress:
                this.props.sale &&
                this.props.sale.dest &&
                !!this.props.sale.dest.email
                  ? this.props.sale.dest.email
                  : "",
            })
          }
        >
          {this.props.children}
        </Button>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isSendingNfe: state.sales.list.isSendingNfe,
  };
};
const mapDispatchToProps = {
  sendnfeRequest: salesActions.sendnfeRequest,
};

export default connect<
  EmailNfeButtonStateProps,
  EmailNfeButtonDispatchProps,
  EmailNfeButtonProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailNfeButton);
