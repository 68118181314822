import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { Form } from "react-bootstrap";
import InputNumber from "../InputNumber";

type NumberFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  decimalPlaces?: number;
};

export const NumberField: React.FC<NumberFieldProps> = ({
  label,
  decimalPlaces,
  ...props
}) => {
  const [field, { error, touched }, { setValue }] = useField(props);
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <InputNumber
        id={field.name}
        value={field.value}
        className={`form-control text-input ${
          touched && error && "error is-invalid"
        }`}
        placeholder={props.placeholder}
        disabled={props.disabled}
        decimalPlaces={decimalPlaces}
        onChange={(val) => setValue(val)}
      />
      {touched && error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};
