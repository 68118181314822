import React, { Component } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteButton from "../../components/DeleteButton";
import { LinkButton } from "../../components/LinkButton";
import LoadingContainer from "../../components/LoadingContainer";
import TableOrCard from "../../components/TableOrCard";
import { pageInit, Tipoop, tipoopDeleteRequest } from "./actions";

type TipoopProps = {
  items: Tipoop[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isDeleting: boolean;
  pageInit: () => void;
  deleteRequest: (cadId: string) => void;
};

class Tipoops extends Component<TipoopProps, {}> {
  state = {};

  headers = [
    {
      label: "Código",
      dataIndex: "cod",
    },
    {
      label: "Descrição",
      dataIndex: "name",
    },
    {
      label: "Ações",
      render: (item: Tipoop) => (
        <ButtonGroup>
          <LinkButton variant="info" to={`/tipoop/${item.cadId}`}>
            <i className="fas fa-edit" />
          </LinkButton>
          <DeleteButton
            isRequesting={this.props.isDeleting}
            onDelete={() => this.handleDelete(item)}
          />
        </ButtonGroup>
      ),
    },
  ];

  componentDidMount() {
    this.props.pageInit();
  }

  handleDelete = (item: Tipoop) => {
    this.props.deleteRequest(item.cadId);
  };

  render() {
    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Tipos de operação</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <LinkButton to="/tipoop/new" variant="primary">
              Incluir
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={this.props.items} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.tipoop.list.items,
    Count: state.tipoop.list.Count,
    lastKey: state.tipoop.list.lastKey,
    isRequesting: state.tipoop.list.isRequesting,
    isDeleting: state.tipoop.list.isDeleting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: () => dispatch(pageInit()),
    deleteRequest: (cadId) => dispatch(tipoopDeleteRequest(cadId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tipoops);
