import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DateField } from "../../components/FormikComponents/DateField";
import { NumberField } from "../../components/FormikComponents/NumberField";
import { SelectField } from "../../components/FormikComponents/SelectField";
import { TextField } from "../../components/FormikComponents/TextField";
import LoaderButton from "../../components/LoaderButton";
import ShowError from "../../components/ShowError";
import { formatNumber } from "../../misc/formatters";
import { RootState } from "../app/mainReducer";
import { Prod } from "./actions";
import { prodActions } from "./reducer";

export const CreateEstoque: React.FC<{ prod: Prod }> = ({ prod }) => {
  const dispatch = useDispatch();

  const isCreating = useSelector(
    (state: RootState) => state.prod.list.isCreating
  );
  const createError = useSelector(
    (state: RootState) => state.prod.list.createError
  );

  return (
    <>
      <ShowError error={createError} />
      <Formik
        initialValues={{
          qtd: 0,
          tipo: "Entrada",
          desc: "",
          data: new Date(),
          valor_unitario: 0,
        }}
        onSubmit={(values) => {
          dispatch(
            prodActions.criarEstoqueRequest({
              data: values.data,
              desc: values.desc,
              prod,
              quantidade: values.qtd,
              valor_unitario: values.valor_unitario,
            })
          );
        }}
      >
        {(props) => (
          <Form>
            <Row>
              <Col>
                <DateField name="data" label="Data" />
              </Col>
              <Col>
                <SelectField name="tipo" label="Tipo">
                  <option value="Entrada">Entrada</option>
                  <option value="Saida">Saida</option>
                </SelectField>
              </Col>
              <Col>
                <TextField name="desc" label="Descrição" />
              </Col>
              <Col>
                <NumberField name="qtd" label="Quantidade" decimalPlaces={0} />
              </Col>
              <Col>
                <NumberField
                  name="valor_unitario"
                  label="Valor Unitário"
                  decimalPlaces={2}
                />
              </Col>
              <Col>
                <h5>Valor Total</h5>
                <h3>
                  {formatNumber(
                    props.values.qtd * props.values.valor_unitario,
                    2,
                    true
                  )}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <LoaderButton isLoading={isCreating} type="submit">
                  Incluir
                </LoaderButton>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
