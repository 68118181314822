import _ from "underscore";
import {
  PROD_SELECTOR_REQUESTING,
  PROD_SELECTOR_SUCCESS,
  PROD_SELECTOR_ERROR,
} from "./actions";

const initialState = {
  isRequesting: false,
  items: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROD_SELECTOR_REQUESTING:
      return {
        ...state,
        isRequesting: true,
        error: "",
      };
    case PROD_SELECTOR_SUCCESS:
      const mergedItems =
        state.items.length > 100
          ? action.payload.items
          : [...action.payload.items, ...state.items];
      return {
        ...state,
        isRequesting: false,
        error: "",
        items: _.uniq(mergedItems, false, (it) => it.cadId),
      };
    case PROD_SELECTOR_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
