import { differenceInDays } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";

/**
 * Se a assinatura esta ativa ou esta em trial valido.
 * @returns boolean
 */
export const useIsSubscriptionValid = () => {
  const subscription = useSelector(
    (state: RootState) => state.subscription.activeSubscription
  );
  const org = useSelector((state: RootState) => state.login.org);
  const isOrgRequesting = useSelector(
    (state: RootState) => state.login.isOrgRequesting
  );
  const orgCreated = new Date(org ? org.createdAt || 0 : 0);
  const dif = differenceInDays(new Date(), orgCreated);

  const isSubscriptionActive =
    subscription &&
    (subscription.status === "active" || subscription.status === "past_due");
  const isSubscriptionPastDue =
    subscription && subscription.status === "past_due";
  if (isOrgRequesting) {
    return true;
  }

  return (isSubscriptionActive && !isSubscriptionPastDue) || dif < 31;
};
