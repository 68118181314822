import { all } from "redux-saga/effects";
import emissorSaga from "../emissor/saga";
import serieSaga from "../serie/saga";
import tipoopSaga from "../tipoop/saga";
import prodSaga from "../prod/saga";
import transpSaga from "../transp/saga";
import destSaga from "../dest/saga";
import invoiceSaga from "../invoice/saga";
import condSaga from "../cond/saga";
import salesSaga from "../sales/saga";
import taskSaga from "../task/saga";
import loginSaga from "../login/saga";
import profileSaga from "../profile/saga";
import initialWizardSaga from "../initalWizard/saga";
import purchasesSaga from "../purchases/saga";
import paymentSaga from "../payment/saga";
import subscribeSaga from "../subscription/saga";
import dashboardSaga from "../dashboard/saga";
import caixaSaga from "../caixa/saga";
import bancosSaga from "../banco/saga";
import servicesSaga from "../services/saga";
import whatsSaga from "../whatsapp/saga";

export default function* mainSaga() {
  yield all([
    emissorSaga(),
    serieSaga(),
    tipoopSaga(),
    prodSaga(),
    transpSaga(),
    destSaga(),
    invoiceSaga(),
    condSaga(),
    salesSaga(),
    taskSaga(),
    loginSaga(),
    profileSaga(),
    initialWizardSaga(),
    purchasesSaga(),
    paymentSaga(),
    subscribeSaga(),
    dashboardSaga(),
    caixaSaga(),
    bancosSaga(),
    servicesSaga(),
    whatsSaga(),
  ]);
}
