import React from "react";
import { Link, LinkGetProps, LinkProps } from "@reach/router";
import { useState } from "react";
import { NavLink, NavLinkProps } from "react-bootstrap";

type MenuNavLinkProps = {
  activeClass: string;
} & NavLinkProps &
  LinkProps<{}>;

const MenuNavLink: React.FC<MenuNavLinkProps> = ({
  activeClass,
  className,
  children,
  ...rest
}) => {
  const [isActive, setActive] = useState(false);

  return (
    <NavLink
      as={Link}
      {...rest}
      className={`${className} ${isActive ? activeClass : ""}`}
      getProps={(props: LinkGetProps) => setActive(props.isPartiallyCurrent)}
    >
      {children}
    </NavLink>
  );
};

export default MenuNavLink;
