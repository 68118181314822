// actions of profile
export const USERPAGE_INIT = "USERPAGE_INIT";
export const SENDINVITE_REQUESTING = "SENDINVITE_REQUESTING";
export const SENDINVITE_SUCCESS = "SENDINVITE_SUCCESS";
export const SENDINVITE_ERROR = "SENDINVITE_ERROR";

export function userpageInit(orgId: string) {
  return {
    type: USERPAGE_INIT,
    payload: orgId,
  };
}

export function sendinviteRequesting(user: any) {
  return {
    type: SENDINVITE_REQUESTING,
    payload: user,
  };
}

export function sendinviteSuccess() {
  return {
    type: SENDINVITE_SUCCESS,
  };
}

export function sendinviteError(error: any) {
  return {
    type: SENDINVITE_ERROR,
    error,
  };
}
