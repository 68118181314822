import {
  faBarcode,
  faCashRegister,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { eachMonthOfInterval, format, subMonths } from "date-fns";
import moment from "moment";
import React, { useEffect } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { LinkButton } from "../../components/LinkButton";
import LoaderButton from "../../components/LoaderButton";
import LoadingContainer from "../../components/LoadingContainer";
import ShowError from "../../components/ShowError";
import TableOrCard from "../../components/TableOrCard";
import { formatNumber } from "../../misc/formatters";
import { RootState } from "../app/mainReducer";
import { InvoiceParcela } from "../invoice/actions";
import { invoicePaneActions } from "../invoice/invoicePane/reducer";
import PaymentParcela from "../payment/types";
import { dashboardActions } from "./reducer";

const AnnualBarChart: React.FC<{ data: Record<string, any> }> = ({ data }) => {
  const last12months = eachMonthOfInterval({
    start: subMonths(new Date(), 12),
    end: new Date(),
  }).map((dt) => {
    const monthToFind = format(dt, "yyyy_MM");
    const value = data[monthToFind] || 0;
    return {
      name: format(dt, "MMM/yyyy"),
      uv: value,
      amt: value,
    };
  });
  console.log("last12months data", last12months);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={last12months}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={(value) => formatNumber(value, 2, true)} />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="uv"
          name="Vendas (R$)"
          fill="#8884d9"
          label={{ position: "top" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const topSalesHeader = [
  {
    label: "Cliente",
    render: (item) =>
      item.client ? item.client.name : "Cliente não informado",
  },
  {
    label: "Vendas (R$)",
    render: (item) => formatNumber(item.valor, 2, true),
  },
];

const topProdsHeader = [
  {
    label: "Produto",
    render: (item) => (item.prod ? item.prod.name : "Produto não informado"),
  },
  {
    label: "Vendas (R$)",
    render: (item) => formatNumber(item.valor, 2, true),
  },
];

const Dashboard: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const statisticsRecord = useSelector(
    (state: RootState) => state.dashboard.statisticsRecord
  );
  const isStatisticsRequesting = useSelector(
    (state: RootState) => state.dashboard.isStatisticsRequesting
  );
  const statisticsError = useSelector(
    (state: RootState) => state.dashboard.statisticsError
  );
  const orgStatus = useSelector((state: RootState) => state.login.orgStatus);

  const topSales = useSelector((state: RootState) => state.dashboard.topSales);
  const isTopSalesRequesting = useSelector(
    (state: RootState) => state.dashboard.isTopSalesRequesting
  );
  const topSalesError = useSelector(
    (state: RootState) => state.dashboard.topSalesError
  );

  const topProds = useSelector((state: RootState) => state.dashboard.topProds);
  const isTopProdsRequesting = useSelector(
    (state: RootState) => state.dashboard.isTopProdsRequesting
  );
  const topProdsError = useSelector(
    (state: RootState) => state.dashboard.topProdsError
  );

  const invoices = useSelector((state: RootState) => state.dashboard.invoices);
  const isInvoiceRequesting = useSelector(
    (state: RootState) => state.dashboard.isInvoiceRequesting
  );

  const payments = useSelector((state: RootState) => state.dashboard.payments);
  const isPaymentsRequesting = useSelector(
    (state: RootState) => state.dashboard.isPaymentsRequesting
  );

  const isRequestingBoleto = useSelector(
    (state: RootState) => state.invoice.pane.isRequestingBoleto
  );

  useEffect(() => {
    dispatch(dashboardActions.requestStatistics());
    dispatch(dashboardActions.requestTopSales(format(new Date(), "yyyy_MM")));
    dispatch(dashboardActions.requestTopProds(format(new Date(), "yyyy_MM")));
    dispatch(dashboardActions.requestPayments());
    dispatch(dashboardActions.requestInvoices());
  }, [dispatch]);

  const invoicesHeader = [
    {
      label: "#",
      render: (item: InvoiceParcela) => `${item.num}/${item.numparcela}`,
    },
    {
      label: "Emissão",
      render: (item: InvoiceParcela) =>
        moment(item.emission).format("DD/MM/YYYY"),
    },
    {
      label: "Cliente",
      render: (item: InvoiceParcela) => `${item.dest && item.dest.name}`,
    },
    {
      label: "Valor",
      render: (item: InvoiceParcela) => formatNumber(item.valor, 2),
    },
    {
      label: "Vencimento",
      render: (item: InvoiceParcela) =>
        moment(item.vencimento).format("DD/MM/YYYY"),
    },
    {
      label: "Status",
      render: (item: InvoiceParcela) =>
        item.status === "OPEN" ? "Pendente" : "Recebido",
    },
    {
      label: "Ações",
      render: (item: InvoiceParcela) =>
        orgStatus === "ACTIVE" ? (
          <ButtonGroup>
            <LinkButton variant="info" to={`/invoice/${item.invoiceId}`}>
              <i className="fas fa-edit" />
            </LinkButton>
            {item.status === "OPEN" && (
              <LinkButton
                variant="light"
                title="Receber a cobrança"
                to={`/InvoicePay/${item.num}:${item.numparcela}`}
              >
                <i className="fas fa-cash-register" />
              </LinkButton>
            )}
            {moment(item.vencimento).isSameOrAfter(moment(), "day") && (
              <LoaderButton
                variant="light"
                title="Emitir Boleto"
                isLoading={isRequestingBoleto}
                onClick={() => dispatch(invoicePaneActions.requestBoleto(item))}
              >
                <FontAwesomeIcon icon={faBarcode} />
              </LoaderButton>
            )}
          </ButtonGroup>
        ) : null,
    },
  ];
  const paymentsHeader = [
    {
      label: "Numero",
      render: (item: PaymentParcela) => `${item.num}/${item.numparcela}`,
    },
    {
      label: "Empresa",
      render: (item: PaymentParcela) => item.emissor && item.emissor.name,
    },
    {
      label: "Fornecedor",
      render: (item: PaymentParcela) => item.dest && item.dest.name,
    },
    {
      label: "Valor ($)",
      render: (item: PaymentParcela) => formatNumber(item.valor, 2),
    },
    {
      label: "Vencimento",
      render: (item: PaymentParcela) =>
        moment(item.vencimento).format("DD/MM/YYYY"),
    },
    {
      label: "Status",
      render: (item: PaymentParcela) =>
        item.status === "CLOSED" ? "Pago" : "Pendente",
    },
    {
      label: "Ações",
      render: (item: PaymentParcela) => (
        <ButtonGroup>
          <LinkButton variant="info" to={`/payment/${item.invoiceId}`}>
            <FontAwesomeIcon icon={faEdit} />
          </LinkButton>
          {item.status === "OPEN" && (
            <LinkButton
              variant="success"
              to={`/paymentPay/${item.num}:${item.numparcela}`}
            >
              <FontAwesomeIcon icon={faCashRegister} />
            </LinkButton>
          )}
        </ButtonGroup>
      ),
    },
  ];

  return (
    <LoadingContainer
      isLoading={
        isStatisticsRequesting ||
        isTopSalesRequesting ||
        isTopProdsRequesting ||
        isPaymentsRequesting ||
        isInvoiceRequesting
      }
    >
      <Row>
        <Col>
          <h1 className="headerDashboard">Vendas nos ultimos 12 meses</h1>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <ShowError error={statisticsError} />
      </Row>
      <Row className="mt-3 mb-3">
        <ShowError error={topSalesError} />
      </Row>
      <Row className="mt-3 mb-3">
        <ShowError error={topProdsError} />
      </Row>
      {statisticsRecord && (
        <Row className="mt-3 mb-3">
          <Col style={{ height: 300, width: 500, backgroundColor: "#fff" }}>
            <AnnualBarChart data={statisticsRecord} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h1>Clientes com mais vendas no mês</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableOrCard headers={topSalesHeader} items={topSales} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>Produtos com mais vendas no mês</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableOrCard headers={topProdsHeader} items={topProds} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>Cobranças vencendo</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableOrCard headers={invoicesHeader} items={invoices} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>Pagamentos vencendo</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableOrCard headers={paymentsHeader} items={payments} />
        </Col>
      </Row>
    </LoadingContainer>
  );
};

export default Dashboard;
