import React from "react";
import { Form } from "react-bootstrap";

type CstIcmsProps = {
  value: string;
  onChange: (value: string) => void;
};

const CstIcms: React.FC<CstIcmsProps> = (props) => (
  <Form.Control
    as="select"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  >
    <option value="00">00 - Tributada integralmente</option>
    <option value="10">
      10 - Tributada e com cobrança do ICMS por substituição tributária
    </option>
    <option value="20">20 - Com redução da BC</option>
    <option value="30">
      30 - Isenta / não tributada e com cobrança do ICMS por substituição
      tributária
    </option>
    <option value="40">40 - Isenta</option>
    <option value="41">41 - Não tributada</option>
    <option value="50">50 - Com suspensão</option>
    <option value="51">51 - Com diferimento</option>
    <option value="60">
      60 - ICMS cobrado anteriormente por substituição tributária
    </option>
    <option value="70">
      70 - Com redução da BC e cobrança do ICMS por substituição tributária
    </option>
    <option value="90">90 - Outras</option>
  </Form.Control>
);

export default CstIcms;
