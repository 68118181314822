import { salesFormActions } from "./reducer";
// saga do form do modulo sales
import {
  put,
  all,
  call,
  takeLatest,
  select,
  takeEvery,
} from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { Emissor } from "../../emissor/reducer";
import { Sale } from "../types";
import { PayloadAction } from "@reduxjs/toolkit";

const getDefaultEmit = (state) => state.login.defaultEmit;

export const newSale = (emissor: Emissor | null = null): Sale => {
  return {
    orgId: "",
    invoiceId: "",
    lsi1: "",
    lsi2: "",
    lsi3: "",
    lsi4: "",
    createdAt: Date.now(),
    geraCobranca: false,
    tag: "NAOGERADA",
    version: 1,
    num: "",
    emission: new Date(),
    dtExp: new Date(),
    dest: null,
    emissor,
    tipoop: null,
    serie: null,
    modFrete: "9",
    transp: null,
    vol: 0,
    especie: "",
    tpag: "01",
    nparcelas: 0,
    modelo: "55",
    infAdFisco: "",
    infCpl: "",
    items: [],
    parcelas: [],
  };
};

function* salesInitWorker({
  payload,
}: PayloadAction<{ invoiceId: string; duplicateId: string }>) {
  try {
    if (payload.invoiceId === "new") {
      if (payload.duplicateId) {
        const res = yield call(
          executeApiRequest,
          `/sale/${payload.duplicateId}`,
          "GET",
          {},
          {}
        );
        delete res.invoiceId;
        delete res.orgId;
        delete res.lsi1;
        delete res.lsi2;
        delete res.lsi3;
        delete res.lsi4;
        delete res.NFe;
        res.num = "";
        res.emission = new Date();
        res.dtExp = new Date();
        yield put(salesFormActions.saleSuccess({ ...res }));
      } else {
        const defaultEmit: Emissor = yield select(getDefaultEmit);
        yield put(salesFormActions.saleSuccess(newSale(defaultEmit)));
      }
    } else {
      console.log("loading item");
      const res = yield call(
        executeApiRequest,
        `/sale/${payload.invoiceId}`,
        "GET",
        {},
        {}
      );
      yield put(salesFormActions.saleSuccess(res));
    }
  } catch (error) {
    yield put(salesFormActions.saleError(error));
  }
}

function* salesSaveWorker({ payload }: PayloadAction<Sale>) {
  try {
    if (payload.invoiceId) {
      // Update
      const res = yield call(
        executeApiRequest,
        `/sale/${payload.invoiceId}`,
        "PUT",
        payload
      );
      yield put(salesFormActions.saveSuccess(res));
      // If invoice Auto generate/update invoice from sale
      // if (
      //   payload.parcelas &&
      //   payload.tipoop &&
      //   payload.tipoop.invoiceAuto * 1 === 1
      // ) {
      //   yield put(saveSales({ ...payload, ...res }));
      // }
    } else {
      const res = yield call(executeApiRequest, "/sale", "POST", payload);
      yield put(salesFormActions.saveSuccess(res));
      // If invoice Auto generate/update invoice from sale
      // if (
      //   payload.parcelas &&
      //   payload.tipoop &&
      //   payload.tipoop.invoiceAuto * 1 === 1
      // ) {
      //   yield put(saveSales({ ...payload, ...res }));
      // }
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(salesFormActions.saveError(error.response.data));
  }
}

export default function* salesFormSaga() {
  yield all([
    takeLatest("salesForm/saleRequest", salesInitWorker),
    takeEvery("salesForm/saveRequest", salesSaveWorker),
  ]);
}
