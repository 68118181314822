import React, { Component } from "react";
import { Button, Row, Col, ButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import _ from "underscore";
import { Link } from "@reach/router";
import { formatNumber } from "../../../misc/formatters";
import LoadingContainer from "../../../components/LoadingContainer";
import TableOrCard from "../../../components/TableOrCard";
import Kpi from "../../../components/Kpi";
import { Cond } from "../saga";
import { RootState } from "../../app/mainReducer";
import { condPaneActions } from "./reducer";

type CondPaneProps = {
  requestCondsOpened: () => void;
  requestCondsClosed: () => void;
  orgStatus: string;
  itemsOpen: Cond[];
  CountOpen: number;
  lastKeyOpen: string;
  isOpenRequesting: boolean;
  itemsClosed: Cond[];
  CountClosed: number;
  lastKeyClosed: string;
  isClosedRequesting: boolean;
  error: string;
  isSaving: boolean;
  userRole: string;
};

class CondPane extends Component<CondPaneProps, {}> {
  state = {};

  componentDidMount() {
    this.props.requestCondsOpened();
    this.props.requestCondsClosed();
  }

  condsToItems = (conds: any) => {
    const items = [];
    if (!conds || !conds.forEach) {
      return items;
    }
    conds.forEach((cond: Cond) => {
      cond.items.map((item, i) => items.push({ ...item, cond }));
    });
    return items;
  };

  render() {
    const { orgStatus } = this.props;

    const headers = [
      {
        label: "#",
        render: (item: any) => `${item.cond.num}`,
      },
      {
        label: "Emissão",
        render: (item: any) => moment(item.cond.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Cliente",
        render: (item: any) => `${item.cond.dest && item.cond.dest.name}`,
      },
      {
        label: "Produto",
        render: (item: any) => `${item.prod && item.prod.name}`,
      },
      {
        label: "Quantidade",
        render: (item: any) => formatNumber(item.qtd, 0),
      },
      {
        label: "Total",
        render: (item: any) => formatNumber(item.total, 2),
      },
      {
        label: "Ações",
        render: (item: any) =>
          orgStatus === "ACTIVE" ? (
            <ButtonGroup>
              <Button as={Link} to={`/cond/${item.cond.movId}`} variant="info">
                <i className="fas fa-edit" />
              </Button>
              <Button
                as={Link}
                to={`/Condicional/${item.cond.movId}`}
                variant="secondary"
              >
                <i className="fas fa-print" />
              </Button>
              {!item.closed && (
                <Button
                  as={Link}
                  to={`/CondClose/${item.cond.num}`}
                  variant="light"
                  title="Baixar Condicional"
                >
                  <i className="fas fa-ban" />
                </Button>
              )}
            </ButtonGroup>
          ) : null,
      },
    ];
    const itemsCondsOpen = this.condsToItems(this.props.itemsOpen);
    const itemsCondsClosed = this.condsToItems(this.props.itemsClosed);

    const condsAbertas = _.sortBy(itemsCondsOpen, (item) =>
      moment(item.cond.emission).unix()
    );
    const totalAberto = condsAbertas.reduce((p, c) => p + c.total, 0);

    const condsClosed = _.sortBy(itemsCondsClosed, (item) =>
      moment(item.cond.emission).unix()
    );
    const totalClosed = condsClosed.reduce((p, c) => p + c.total, 0);

    return (
      <>
        <Row>
          <Col>
            <Kpi
              title="Total em aberto"
              value={`R$ ${formatNumber(totalAberto, 2, true)}`}
            />
            <Kpi
              title="Total Fechadas"
              value={`R$ ${formatNumber(totalClosed, 2, true)}`}
            />
          </Col>
        </Row>
        <Button variant="primary" as={Link} to="/cond/new">
          <i className="fas fa-plus" /> Incluir Condicional
        </Button>
        <Row>
          <Col>
            <h2>Condicionais em aberto</h2>
          </Col>
        </Row>
        <Row>
          <LoadingContainer isLoading={this.props.isOpenRequesting}>
            <TableOrCard headers={headers} items={condsAbertas} />
          </LoadingContainer>
        </Row>
        <Row>
          <Col>
            <h2>Condicionais Fechadas</h2>
          </Col>
        </Row>
        <Row>
          <LoadingContainer isLoading={this.props.isClosedRequesting}>
            <TableOrCard headers={headers} items={condsClosed} />
          </LoadingContainer>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    itemsOpen: state.cond.pane.itemsOpen,
    CountOpen: state.cond.pane.CountOpen,
    lastKeyOpen: state.cond.pane.lastKeyOpen,
    isOpenRequesting: state.cond.pane.isOpenRequesting,
    itemsClosed: state.cond.pane.itemsClosed,
    CountClosed: state.cond.pane.CountClosed,
    lastKeyClosed: state.cond.pane.lastKeyClosed,
    isClosedRequesting: state.cond.pane.isClosedRequesting,
    isSaving: state.cond.pane.isSaving,
    userRole: state.login.userRole,
    orgStatus: state.login.orgStatus,
  };
};
const mapDispatchToProps = {
  requestCondsOpened: condPaneActions.requestCondsOpened,
  requestCondsClosed: condPaneActions.requestCondsClosed,
};

export default connect(mapStateToProps, mapDispatchToProps)(CondPane);
