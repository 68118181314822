import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Banco } from "../types";

type TInitialState = {
  isLoading: boolean;
  item: Banco;
  isSaving: boolean;
  isConfigRequesting: boolean;
  successMsg: string;
  error: string;
  isConfigInterRequesting: boolean;
};

const initialState: TInitialState = {
  isLoading: false,
  item: null,
  isSaving: false,
  isConfigRequesting: false,
  isConfigInterRequesting: false,
  successMsg: "",
  error: "",
};

const bancoFormReducer = createSlice({
  name: "bancoForm",
  initialState,
  reducers: {
    initForm(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.item = null;
      state.isSaving = false;
      state.successMsg = "";
      state.error = "";
    },
    initSuccess(state, { payload }: PayloadAction<Banco>) {
      state.isLoading = false;
      state.item = payload;
    },
    initError(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    saveRequest(state, _: PayloadAction<Banco>) {
      state.isSaving = true;
      state.error = "";
      state.successMsg = "";
    },
    saveSuccess(state, { payload }: PayloadAction<Banco>) {
      state.isSaving = false;
      state.item = payload;
      state.successMsg = `Banco ${payload.cod} salvo com sucesso!`;
    },
    saveError(state, { payload }: PayloadAction<string>) {
      state.isSaving = false;
      state.error = payload;
    },
    configRequest(state, _: PayloadAction<string>) {
      state.isConfigRequesting = true;
      state.successMsg = "";
      state.error = "";
    },
    configSuccess(state, { payload }: PayloadAction<Banco>) {
      state.isConfigRequesting = false;
      state.successMsg = `Banco configurado com sucesso!`;
      state.item = payload;
    },
    configError(state, { payload }: PayloadAction<string>) {
      state.isConfigRequesting = false;
      state.error = payload;
    },
    configInter(state, _: PayloadAction<string>) {
      state.isConfigInterRequesting = true;
      state.successMsg = "";
      state.error = "";
    },
    configInterSuccess(state) {
      state.isConfigInterRequesting = false;
      state.successMsg = "Banco configurado com sucesso!";
    },
    configInterError(state, { payload }: PayloadAction<string>) {
      state.isConfigInterRequesting = false;
      state.error = payload;
    },
  },
});

export const bancoFormActions = bancoFormReducer.actions;

export default bancoFormReducer;
