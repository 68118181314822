import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, ButtonGroup, Button } from "react-bootstrap";
import moment from "moment";
import _ from "underscore";
import { Link } from "@reach/router";
import { formatNumber } from "../../../misc/formatters";
import LoadingContainer from "../../../components/LoadingContainer";
import TableOrCard from "../../../components/TableOrCard";

import { clientCondsRequest } from "./actions";
import ShowError from "../../../components/ShowError";
import { Cond } from "../saga";

type CondViewProps = {
  items: Cond[];
  error: any;
  userRole: any;
  orgStatus: any;
  isRequesting: boolean;
  destId: string;
  clientCondsRequest: (clientId: string) => void;
};

class CondView extends Component<CondViewProps, {}> {
  state = {};

  componentDidMount() {
    this.props.clientCondsRequest(this.props.destId);
  }

  condsToItems = (conds: Cond[]) => {
    const items = [];
    conds.forEach((cond) => {
      cond.items.map((item, i) => items.push({ ...item, cond }));
    });
    return items;
  };

  render() {
    if (!this.props.destId) {
      return null;
    }
    const orgStatus = this.props.orgStatus;

    const headers = [
      {
        label: "#",
        render: (item: any) => `${item.cond.num}`,
      },
      {
        label: "Emissão",
        render: (item: any) => moment(item.cond.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Cliente",
        render: (item: any) => `${item.cond.dest && item.cond.dest.name}`,
      },
      {
        label: "Produto",
        render: (item: any) => `${item.prod && item.prod.name}`,
      },
      {
        label: "Quantidade",
        render: (item: any) => formatNumber(item.qtd, 0),
      },
      {
        label: "Total",
        render: (item: any) => formatNumber(item.total, 2),
      },
      {
        label: "Ações",
        render: (item: any) =>
          orgStatus === "ACTIVE" ? (
            <ButtonGroup>
              <Button as={Link} to={`/cond/${item.cond.movId}`} variant="info">
                <i className="fas fa-edit" />
              </Button>
              <Button
                variant="secondary"
                as={Link}
                to={`/Condicional/${item.cond.movId}`}
              >
                <i className="fas fa-print" />
              </Button>
              {!item.closed && (
                <Button
                  variant="light"
                  title="Baixar Condicional"
                  as={Link}
                  to={`/CondClose/${item.cond.num}`}
                >
                  <i className="fas fa-ban" />
                </Button>
              )}
            </ButtonGroup>
          ) : null,
      },
    ];
    const itemsConds = this.condsToItems(this.props.items);

    const condsAbertas = _.sortBy(
      _.filter(
        itemsConds,
        (item) =>
          !item.closed &&
          item.cond.dest &&
          item.cond.dest.cadId === this.props.destId
      ),
      (item) => moment(item.cond.emission).unix()
    );
    const totalAberto = condsAbertas.reduce((p, c) => p + c.total, 0);

    return (
      <>
        <Row>
          <Col xs={12} md={6}>
            <h2>Condicionais em aberto</h2>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex align-itens-end justify-content-end"
          >
            <Button variant="primary" as={Link} to="/cond/new">
              <i className="fas fa-plus" /> Incluir Condicional
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <Row>
          <LoadingContainer isLoading={this.props.isRequesting}>
            <TableOrCard headers={headers} items={condsAbertas} />
            <b>Total .: R$ {formatNumber(totalAberto, 2)}</b>
          </LoadingContainer>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.cond.view.items,
    error: state.cond.view.error,
    userRole: state.login.userRole,
    orgStatus: state.login.orgStatus,
    isRequesting: state.cond.view.isRequesting,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    clientCondsRequest: (clientId: string) =>
      dispatch(clientCondsRequest(clientId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CondView);
