import { RootState } from "./../app/mainReducer";
import { AWSRes } from "./../../redux/types";
import { caixaActions, RegistroCaixa } from "./reducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import executeApiRequest from "../../misc/executeApiRequest";
import { format } from "date-fns";
import _ from "underscore";

export type ResultadoRequest = AWSRes<RegistroCaixa> & {
  ultimoSaldo?: RegistroCaixa;
};

const getDataInicial = (state: RootState) => state.caixa.ultimaDataInicial;
const getDataFinal = (state: RootState) => state.caixa.ultimaDataFinal;

function* requestWorker({
  payload,
}: PayloadAction<{ data_inicial: Date; data_final: Date }>) {
  try {
    console.log(
      "requesting ",
      format(payload.data_inicial, "yyyy_MM_dd"),
      format(payload.data_final, "yyyy_MM_dd")
    );
    const res: ResultadoRequest = yield call(
      executeApiRequest,
      `/caixa`,
      "GET",
      {},
      {
        queryStringParameters: {
          dataInicial: format(payload.data_inicial, "yyyy_MM_dd"),
          dataFinal: format(payload.data_final, "yyyy_MM_dd"),
        },
      }
    );

    res.items = _.sortBy(res.items, (registro) => registro.movId);

    yield put(caixaActions.requestSuccess(res));
  } catch (error) {
    yield put(caixaActions.requestError(error));
  }
}

function* createWorker({
  payload,
}: PayloadAction<{ id: string; valor: number; descricao: string }>) {
  try {
    yield call(
      executeApiRequest,
      `/caixa`,
      "POST",
      {
        ...payload,
      },
      {}
    );
    yield put(caixaActions.createLancamentoSuccess());
    const data_inicial = yield select(getDataInicial);
    const data_final = yield select(getDataFinal);
    if (data_inicial && data_final) {
      yield put(caixaActions.requestCaixa({ data_inicial, data_final }));
    }
  } catch (error) {
    yield put(caixaActions.createLancamentoError(error));
  }
}

function* removeWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(executeApiRequest, `/caixa/${payload}`, "DELETE", {}, {});
    yield put(caixaActions.removerLancamentoSuccess());
  } catch (error) {
    yield put(caixaActions.removerLancamentoError(error));
  }
}

function* fecharCaixaWorker({ payload }: PayloadAction<number>) {
  try {
    yield call(
      executeApiRequest,
      `/caixa`,
      "PUT",
      {
        valor: payload,
      },
      {}
    );
    yield put(caixaActions.fecharCaixaSuccess());
    const data_inicial = yield select(getDataInicial);
    const data_final = yield select(getDataFinal);
    if (data_inicial && data_final) {
      yield put(caixaActions.requestCaixa({ data_inicial, data_final }));
    }
  } catch (error) {
    yield put(
      caixaActions.fecharCaixaError(
        error && error.response && error.response.data
          ? error.response.data.error
          : error
      )
    );
  }
}

export default function* invoiceSaga() {
  yield all([
    takeLatest("caixaReducer/requestCaixa", requestWorker),
    takeLatest("caixaReducer/createLancamento", createWorker),
    takeLatest("caixaReducer/removeLancamento", removeWorker),
    takeLatest("caixaReducer/requestFecharCaixa", fecharCaixaWorker),
  ]);
}
