export const INVOICEPAY_INIT = "INVOICEPAY_INIT";
export const INVOICEPAY_REQUESTING = "INVOICEPAY_REQUESTING";
export const INVOICEPAY_SUCCESS = "INVOICEPAY_SUCCESS";
export const INVOICEPAY_ERROR = "INVOICEPAY_ERROR";
export const INVOICEPAY_PAYED_REQUESTING = "INVOICEPAY_PAYED_REQUESTING";
export const INVOICEPAY_PAYED_SUCCESS = "INVOICEPAY_PAYED_SUCCESS";
export const INVOICEPAY_PAYED_ERROR = "INVOICEPAY_PAYED_ERROR";
export const INVOICEPAY_SET_INVOICESTOCLOSE = "INVOICEPAY_SET_INVOICESTOCLOSE";

export function pageInit(payload?: any) {
  return {
    type: INVOICEPAY_INIT,
    payload,
  };
}

export function invoiceRequesting() {
  return {
    type: INVOICEPAY_REQUESTING,
  };
}

export function invoiceSuccess(payload: any) {
  return {
    type: INVOICEPAY_SUCCESS,
    payload,
  };
}

export function invoiceError(error: any) {
  return {
    type: INVOICEPAY_ERROR,
    error,
  };
}

export function setInvoicesToClose(invoicesToClose) {
  return {
    type: INVOICEPAY_SET_INVOICESTOCLOSE,
    payload: invoicesToClose,
  };
}

export function payedRequesting(payload) {
  return {
    type: INVOICEPAY_PAYED_REQUESTING,
    payload,
  };
}

export function payedSuccess() {
  return {
    type: INVOICEPAY_PAYED_SUCCESS,
  };
}

export function payedError(error: any) {
  return {
    type: INVOICEPAY_PAYED_ERROR,
    error,
  };
}
