import React from "react";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

type ShowErrorProps = {
  error: any;
};

const ShowError: React.FC<ShowErrorProps> = (props) => {
  if (
    props.error &&
    props.error.error &&
    typeof props.error.error === "string"
  ) {
    return (
      <Alert className="mt-3 mb-3" variant="danger">
        <Fa icon={faExclamationCircle} /> {props.error.error}
      </Alert>
    );
  }
  if (props.error && props.error.message) {
    return (
      <Alert className="mt-3 mb-3" variant="danger">
        <Fa icon={faExclamationCircle} /> {props.error.message}
      </Alert>
    );
  }
  if (Array.isArray(props.error)) {
    return (
      <>
        {props.error.map((err) => (
          <Alert className="mt-3 mb-3" variant="danger">
            <Fa icon={faExclamationCircle} /> {err}
          </Alert>
        ))}
      </>
    );
  }
  if (props.error && props.error.length > 0) {
    return (
      <Alert className="mt-3 mb-3" variant="danger">
        <Fa icon={faExclamationCircle} /> {props.error}
      </Alert>
    );
  }
  return null;
};

export default ShowError;
