import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Emissor } from "../emissor/reducer";

export type User = {
  username: string;
  name?: string;
  attributes: Record<string, any>;
};

export type UserFromOrg = {
  email: string;
  type: string;
};

export type Org = {
  cadId: string;
  cadType: "CAD_ORG";
  createdAt: number;
  version?: number;
  userId?: string;
  orgStatus: string;
  masterUser: string;
  users?: UserFromOrg[];
  name?: string;
  isWhatsappAuthenticated?: boolean;
};

type LoginState = {
  loggedUser: User;
  isAuthenticated: boolean;
  isOrgRequesting: boolean;
  orgId: string;
  org: Org;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  confirmEmail: string;
  confirmationRequesting: boolean;
  confirmationSuccessMsg: string;
  confirmationError: string;
  signupRequesting: boolean;
  signupSuccessMsg: string;
  signupError: string;
  forgotPwdConfirmOpen: boolean;
  forgotPwdIsRequesting: boolean;
  forgotPwdError: string;
  orgStatus: string;
  isOrgSaving: boolean;
  orgSaveError: string;
  userRole: string;
  defaultEmit: Emissor;
  isUserSaving: boolean;
  userSaveError: string;
  isSessionRequesting: boolean;
  isCreateGroupRequesting: boolean;
  createGroupSuccessMessage: string;
  createGroupError: string;
};

export type UserGroup = {
  groupName: string;
  groupDescription: string;
};

const initialState: LoginState = {
  loggedUser: null,
  isAuthenticated: false,
  isOrgRequesting: false,
  orgId: "",
  org: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  confirmEmail: "",
  confirmationRequesting: false,
  confirmationSuccessMsg: "",
  confirmationError: "",
  signupRequesting: false,
  signupSuccessMsg: "",
  signupError: "",
  forgotPwdConfirmOpen: false,
  forgotPwdIsRequesting: false,
  forgotPwdError: "",
  orgStatus: "",
  isOrgSaving: false,
  orgSaveError: "",
  userRole: "",
  defaultEmit: null,
  isUserSaving: false,
  userSaveError: null,
  isSessionRequesting: true,
  isCreateGroupRequesting: false,
  createGroupSuccessMessage: "",
  createGroupError: null,
};

export type LoginActionPayload = {
  email: string;
  password: string;
  redirect?: string;
};

export type ConfirmationActionPayload = {
  email: string;
  confirmationCode: string;
};

export type SignUpActionPayload = {
  email: string;
  password: string;
  orgId: string;
};

export type ForgotPwdConfirmationPayload = {
  email: string;
  confirmationCode: string;
  newPassword: string;
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    init: (state) => {
      state.loggedUser = null;
      state.orgId = "";
      state.org = null;
      state.isAuthenticated = false;
      state.isRequesting = false;
      state.orgStatus = "";
      state.userRole = "";
      state.error = "";
    },
    loginRequest: (state, _: PayloadAction<LoginActionPayload>) => {
      state.isRequesting = true;
      state.isAuthenticated = false;
    },
    loginSuccess: (state, action: PayloadAction<User>) => {
      state.isRequesting = false;
      state.isAuthenticated = true;
      state.loggedUser = action.payload;
      state.orgId = action.payload.attributes["custom:orgId"];
      state.userRole = action.payload.attributes["custom:role"];
      state.successMsg = "Login efetuado com sucesso!";
    },
    loginError: (state, action: PayloadAction<string>) => {
      state.isRequesting = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logoutRequest: (state) => {
      state.isAuthenticated = false;
      state.loggedUser = null;
      state.orgId = "";
      state.org = null;
      state.isOrgRequesting = false;
      state.userRole = "";
      state.successMsg = "";
      state.error = "";
    },
    orgRequest: (state, _: PayloadAction<User>) => {
      state.org = null;
      state.isOrgRequesting = true;
    },
    orgSuccess: (state, action: PayloadAction<Org>) => {
      /*
      if (state.loggedUser.attributes.sub === action.payload.masterUser) {
        state.userRole = "Master";
      } else {
        const users = action.payload.users || [];
        const userReg = users.find(
          (user) => user.email === state.loggedUser.attributes.email
        );
        state.userRole = (userReg && userReg.type) || "";
      }
      */
      state.org = action.payload;
      state.orgStatus = action.payload.orgStatus;
      state.isOrgRequesting = false;
    },
    orgSaveRequest: (state, _: PayloadAction<Org>) => {
      state.isOrgSaving = true;
    },
    orgSaveSuccess: (state, action: PayloadAction<Org>) => {
      state.isOrgSaving = false;
      state.org = action.payload;
      state.orgStatus = action.payload.orgStatus;
    },
    orgSaveError: (state, action: PayloadAction<string>) => {
      state.isOrgSaving = false;
      state.orgSaveError = action.payload;
    },
    setOrg: (state, action: PayloadAction<Partial<Org>>) => {
      state.org = {
        ...state.org,
        ...action.payload,
      };
    },
    setConfirmEmail: (state, action: PayloadAction<string>) => {
      state.confirmEmail = action.payload;
    },
    confirmationRequest: (
      state,
      _: PayloadAction<ConfirmationActionPayload>
    ) => {
      state.confirmationRequesting = true;
    },
    confirmationSuccess: (state) => {
      state.confirmationRequesting = false;
      state.confirmationSuccessMsg = "Confirmação feita com sucesso!";
    },
    confirmationError: (state, action: PayloadAction<string>) => {
      state.confirmationRequesting = false;
      state.confirmationError = action.payload;
    },
    resendConfirmationCode: (_, _action: PayloadAction<string>) => {},
    signupRequest: (state, _: PayloadAction<SignUpActionPayload>) => {
      state.signupRequesting = true;
    },
    signupSuccess: (state) => {
      state.signupRequesting = false;
      state.signupSuccessMsg = "Conta criada com sucesso!";
    },
    signupError: (state, action: PayloadAction<string>) => {
      state.signupRequesting = false;
      state.signupError = action.payload;
    },
    forgotPwdInit: (state) => {
      state.forgotPwdConfirmOpen = false;
      state.forgotPwdIsRequesting = false;
      state.forgotPwdError = "";
    },
    forgotPwdRequest: (state, _: PayloadAction<string>) => {
      state.forgotPwdConfirmOpen = false;
      state.forgotPwdIsRequesting = true;
      state.forgotPwdError = "";
    },
    forgotPwdSuccess: (state) => {
      state.forgotPwdConfirmOpen = true;
      state.forgotPwdIsRequesting = false;
      state.forgotPwdError = "";
    },
    forgotPwdError: (state, action: PayloadAction<string>) => {
      state.forgotPwdConfirmOpen = false;
      state.forgotPwdIsRequesting = false;
      state.forgotPwdError = action.payload;
    },
    forgotPwdConfirmRequest: (
      state,
      _: PayloadAction<ForgotPwdConfirmationPayload>
    ) => {
      state.forgotPwdIsRequesting = true;
      state.forgotPwdError = "";
    },
    forgotPwdConfirmSuccess: (state) => {
      state.forgotPwdIsRequesting = false;
      state.forgotPwdError = "";
    },
    forgotPwdConfirmError: (state, action: PayloadAction<string>) => {
      state.forgotPwdIsRequesting = false;
      state.forgotPwdError = action.payload;
    },
    setDefaultEmit: (state, action: PayloadAction<Emissor>) => {
      state.defaultEmit = action.payload;
    },
    userSaveRequesting: (state, _: PayloadAction<User>) => {
      state.isUserSaving = true;
    },
    userSaveSuccess: (state) => {
      state.isUserSaving = false;
    },
    userSaveError: (state, action: PayloadAction<string>) => {
      state.isUserSaving = false;
      state.userSaveError = action.payload;
    },
    sessionLogin: (state) => {
      state.isSessionRequesting = true;
      state.isUserSaving = false;
    },
    sessionLoginEnd: (state) => {
      state.isSessionRequesting = false;
    },
    createUserGroup: (state, _: PayloadAction<UserGroup>) => {
      state.isCreateGroupRequesting = true;
      state.createGroupSuccessMessage = "";
      state.createGroupError = null;
    },
    createUserGroupSuccess: (state) => {
      state.isCreateGroupRequesting = false;
      state.createGroupSuccessMessage = "Grupo criado com sucesso!";
    },
    createUserGroupError: (state, action: PayloadAction<string>) => {
      state.isCreateGroupRequesting = false;
      state.createGroupError = action.payload;
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
