import { CondRes } from "./../saga";
// saga do modulo cond
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  clientCondsSuccess,
  clientCondsError,
  CLIENT_CONDS_REQUESTING,
} from "./actions";
import executeApiRequest from "../../../misc/executeApiRequest";

function condCall(clientId: string, lastKey = null) {
  return executeApiRequest<CondRes>(
    "/cond",
    "GET",
    {},
    {
      queryStringParameters: {
        movClientId: clientId,
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* condWorker({ payload }: any) {
  try {
    const res: CondRes = yield call(condCall, payload);
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: CondRes = yield call(condCall, payload, res.lastKey);
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(clientCondsSuccess(res));
  } catch (error) {
    console.log("error on request", error, error.response);
    yield put(
      clientCondsError(error.response ? error.response.data.error : error)
    );
  }
}

export default function* condViewSaga() {
  yield all([takeLatest(CLIENT_CONDS_REQUESTING, condWorker)]);
}
