import * as types from "./types";

interface NotificationObject {
  title: string;
  message: string;
}

export const addNotification = ({
  notification,
}: {
  notification: NotificationObject;
}) => {
  return {
    type: types.ADDNOTIFICATION,
    payload: notification,
  };
};

export const delNotification = ({
  notification,
}: {
  notification: NotificationObject;
}) => {
  return {
    type: types.DELNOTIFICATION,
    payload: notification,
  };
};
