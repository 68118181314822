import { Dest } from "../actions";

export const DEST_SELECTOR_REQUESTING = "DEST_SELECTOR_REQUESTING";
export const DEST_SELECTOR_SUCCESS = "DEST_SELECTOR_SUCCESS";
export const DEST_SELECTOR_ERROR = "DEST_SELECTOR_ERROR";

export const destSelectorRequest = (payload: string) => ({
  type: DEST_SELECTOR_REQUESTING,
  payload,
});

export const destSelectorSuccess = (payload: Dest) => ({
  type: DEST_SELECTOR_SUCCESS,
  payload,
});

export const destSelectorError = (payload: any) => ({
  type: DEST_SELECTOR_ERROR,
  payload,
});
