import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentIntent } from "@stripe/stripe-js";

export type Price = {
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  id: string;
  livemode: boolean;
  lookup_key: string;
  metadata: Record<string, string>;
  nickname: string;
  product: {
    active: boolean;
    attributes: string[];
    description: string;
    id: string;
    images: string[];
    livemode: boolean;
    metadata: Record<string, string>;
    name: string;
    package_dimensions: string;
    shippable: string;
    statement_descriptor: string;
    tax_code: string;
    type: string;
    unit_label: string;
    updated: number;
    url: string;
  };
  recurring: {
    aggregate_usage: string;
    interval: string;
    interval_count: number;
    trial_period_days: number;
    usage_type: string;
  };
  tax_behavior: string;
  tiers_mode: string;
  transform_quantity: string;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
};

export type PriceListObject = {
  data: Price[];
  has_more: boolean;
  object: string;
  url: string;
};

export type Subscription = {
  application_fee_percent: number;
  automatic_tax: {
    enabled: boolean;
  };
  billing_cycle_anchor: number;
  billing_thresholds: number;
  cancel_at: number;
  cancel_at_period_end: boolean;
  canceled_at: number;
  collection_method: string;
  created: number;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  days_until_due: number;
  default_payment_method: string;
  default_source: string;
  default_tax_rates: string[];
  discount: number;
  ended_at: number;
  id: string;
  items: {
    object: string;
    data: any[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  latest_invoice: string;
  livemode: boolean;
  metadata: Record<string, any>;
  next_pending_invoice_item_invoice: string;
  object: string;
  pause_collection: string;
  payment_settings: {
    payment_method_options: string;
    payment_method_types: string;
  };
  pending_invoice_item_interval: number;
  pending_setup_intent: string;
  pending_update: string;
  plan: Price;
  quantity: number;
  schedule: number;
  start_date: number;
  status: string;
  transfer_data: string;
  trial_end: number;
  trial_start: number;
};

type SubscriptionState = {
  isConfigRequesting: boolean;
  planos: PriceListObject;
  publicKey: string;
  configRequestError: string;
  isSubscriptionRequesting: boolean;
  subscription: Subscription;
  subscriptionError: string;
  paymentIntent: PaymentIntent;
  isSubscriptionsRequesting: boolean;
  subscriptions: Subscription[];
  subscriptionsError: string;
  isSubscriptionCanceling: boolean;
  subscriptionCanceled: Record<string, any>;
  subscriptionCancelError: string;
  activeSubscription: Subscription;
};

const initialState: SubscriptionState = {
  isConfigRequesting: false,
  planos: null,
  publicKey: null,
  configRequestError: null,
  isSubscriptionRequesting: false,
  subscription: null,
  subscriptionError: null,
  paymentIntent: null,
  isSubscriptionsRequesting: false,
  subscriptions: [],
  subscriptionsError: null,
  isSubscriptionCanceling: false,
  subscriptionCanceled: null,
  subscriptionCancelError: null,
  activeSubscription: null,
};

type PayloadConfigSuccess = {
  prices: PriceListObject;
  publicKey: string;
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    subscriptionInit: (state) => {
      state.subscription = null;
      state.subscriptionError = "";
      state.subscriptionError = null;
      state.subscription = null;
      state.paymentIntent = null;
    },
    configRequest: (state) => {
      state.isConfigRequesting = true;
      state.publicKey = null;
    },
    configSuccess: (state, action: PayloadAction<PayloadConfigSuccess>) => {
      state.isConfigRequesting = false;
      state.publicKey = action.payload.publicKey;
      state.planos = action.payload.prices;
    },
    configError: (state, action: PayloadAction<string>) => {
      state.isConfigRequesting = false;
      state.configRequestError = action.payload;
    },
    subscriptionRequest: (state, _: PayloadAction<string>) => {
      state.isSubscriptionRequesting = true;
      state.subscriptionError = null;
      state.subscription = null;
    },
    subscriptionSuccess: (state, action: PayloadAction<Subscription>) => {
      state.isSubscriptionRequesting = false;
      state.subscription = action.payload;
      state.subscriptionError = "";
    },
    subscriptionError: (state, action: PayloadAction<string>) => {
      state.isSubscriptionRequesting = false;
      state.subscription = null;
      state.subscriptionError = action.payload;
    },
    setPaymentIntent: (state, action: PayloadAction<PaymentIntent>) => {
      state.paymentIntent = action.payload;
    },
    subscriptionsRequest: (state) => {
      state.isSubscriptionsRequesting = true;
      state.subscriptionsError = null;
      state.subscriptions = [];
    },
    subscriptionsSuccess: (state, action: PayloadAction<Subscription[]>) => {
      state.isSubscriptionsRequesting = false;
      state.subscriptionsError = null;
      state.subscriptions = [...action.payload];
    },
    subscriptionsError: (state, action: PayloadAction<string>) => {
      state.isSubscriptionsRequesting = false;
      state.subscriptionsError = action.payload;
    },
    subscriptionCancelInit: (state) => {
      state.isSubscriptionCanceling = false;
      state.subscriptionCanceled = null;
      state.subscriptionCancelError = null;
    },
    subscriptionCancelRequest: (state, action: PayloadAction<string>) => {
      state.isSubscriptionCanceling = true;
      state.subscriptionCanceled = null;
      state.subscriptionCancelError = null;
    },
    subscriptionCancelSuccess: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.isSubscriptionCanceling = false;
      state.subscriptionCanceled = action.payload;
    },
    subscriptionCancelError: (state, action: PayloadAction<string>) => {
      state.isSubscriptionCanceling = false;
      state.subscriptionCancelError = action.payload;
    },
    setActiveSubscription: (state, action: PayloadAction<Subscription>) => {
      state.activeSubscription = action.payload;
    },
    savePaymentMethod(state, action: PayloadAction<string>) {},
  },
});

export const subcsriptionActions = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
