import { InvoiceParcela } from "./../invoice/actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { AWSRes } from "./../../redux/types";
import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../misc/executeApiRequest";
import { dashboardActions, TopProductRecord, TopSaleRecord } from "./reducer";
import PaymentParcela from "../payment/types";
import { addDays, format, subDays } from "date-fns";

function dashboardStatisticsCall() {
  return executeApiRequest(`/stats/SALES_AGG`, "GET", {}, {});
}

function* dashboardStatisticsWorker() {
  try {
    const res: AWSRes<Record<string, any>> = yield call(
      dashboardStatisticsCall
    );
    yield put(
      dashboardActions.statisticsSuccess(
        res.items && res.items.length > 0 ? res.items[0] : {}
      )
    );
  } catch (error) {
    yield put(dashboardActions.statisticsError(error));
  }
}

function topSalesCall(month: string) {
  return executeApiRequest(`/list_top_records/SALE_${month}`, "GET", {}, {});
}

function* topSalesWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AWSRes<TopSaleRecord> = yield call(topSalesCall, payload);
    yield put(dashboardActions.topSalesSuccess(res.items));
  } catch (error) {
    yield put(dashboardActions.topSalesError(error));
  }
}

function topProdsCall(month: string) {
  return executeApiRequest(`/list_top_records/PROD_${month}`, "GET", {}, {});
}

function* topProdsWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AWSRes<TopProductRecord> = yield call(topProdsCall, payload);
    yield put(dashboardActions.topProdsSuccess(res.items));
  } catch (error) {
    yield put(dashboardActions.topProdsError(error));
  }
}

function* paymentsRequestWorker() {
  try {
    const datai = subDays(new Date(), 2);
    const dataf = addDays(new Date(), 8);
    const res: AWSRes<PaymentParcela> = yield call(
      executeApiRequest,
      `/payment`,
      "GET",
      {},
      {
        queryStringParameters: {
          idQuery: `OPEN.${format(datai, "yyyy_MM_dd")}:OPEN.${format(
            dataf,
            "yyyy_MM_dd"
          )}`,
          idFilterType: "BETWEEN",
          indexToUse: "lsi1",
        },
      }
    );
    yield put(dashboardActions.paymentsSuccess(res.items));
  } catch (error) {
    console.log("error on request payments", error);
    yield put(dashboardActions.paymentsError());
  }
}

function* invoicesRequestWorker() {
  try {
    const datai = subDays(new Date(), 2);
    const dataf = addDays(new Date(), 8);
    const res: AWSRes<InvoiceParcela> = yield call(
      executeApiRequest,
      `/invoice`,
      "GET",
      {},
      {
        queryStringParameters: {
          idQuery: `OPEN.${format(datai, "yyyy_MM_dd")}:OPEN.${format(
            dataf,
            "yyyy_MM_dd"
          )}`,
          idFilterType: "BETWEEN",
          indexToUse: "lsi1",
        },
      }
    );
    yield put(dashboardActions.invoicesSuccess(res.items));
  } catch (error) {
    console.log("error on request invoices", error);
    yield put(dashboardActions.invoicesError());
  }
}

export default function* dashboardSaga() {
  yield all([
    takeLatest("dashboard/requestStatistics", dashboardStatisticsWorker),
    takeLatest("dashboard/requestTopSales", topSalesWorker),
    takeLatest("dashboard/requestTopProds", topProdsWorker),
    takeLatest("dashboard/requestPayments", paymentsRequestWorker),
    takeLatest("dashboard/requestInvoices", invoicesRequestWorker),
  ]);
}
