import { AWSRes } from "./../../redux/types";
import { Dest } from "../dest/actions";
import { Emissor } from "../emissor/reducer";
import { Banco } from "../banco/types";
// actions do modulo invoice
export const INVOICE_INIT = "INVOICE_INIT";
export const INVOICE_REQUESTING = "INVOICE_REQUESTING";
export const INVOICE_SUCCESS = "INVOICE_SUCCESS";
export const INVOICE_ERROR = "INVOICE_ERROR";
export const MOV_TYPE = "MOV_INVOICE";
export const INVOICE_DELETE_REQUESTING = "INVOICE_DELETE_REQUESTING";
export const INVOICE_DELETE_SUCCESS = "INVOICE_DELETE_SUCCESS";
export const INVOICE_DELETE_ERROR = "INVOICE_DELETE_ERROR";

export type Invoice = {
  orgId: string;
  invoiceId: string;
  lsi1: string;
  lsi2: string;
  lsi3: string;
  lsi4: string;
  version?: number;
  num: string;
  dest: Dest;
  emission: Date;
  emissor: Emissor;
  nparcelas: number;
  valor: number;
  parcelas: InvoiceParcela[];
  banco: Banco;
  serialCreate?: Record<string, string>;
};

export type InvoiceParcela = {
  orgId: string;
  invoiceId: string;
  lsi1: string;
  lsi2: string;
  lsi3: string;
  lsi4: string;
  version?: number;
  num: string;
  dest: Dest;
  emission: Date;
  vencimento: Date;
  oldVencimento?: Date;
  numparcela: number;
  valor: number;
  nossoNumero?: string;
  emissor: Emissor;
  banco: Banco;
  status: "OPEN" | "CLOSED";
  valor_baixado?: number;
  data_baixa?: Date;
  juros?: number;
  desconto?: number;
  referente?: string;
  serialCreate?: Record<string, string>;
  deleted?: boolean;
  txid?: string;
  statuspix?: string;
  chavepix?: string;
  vencimentopix?: number;
  qrcodepix?: string;
  copiaecolapix?: string;
};

export function pageInit() {
  return {
    type: INVOICE_INIT,
  };
}

export function invoiceRequest() {
  return {
    type: INVOICE_REQUESTING,
  };
}

export function invoiceSuccess(payload: AWSRes<Invoice>) {
  return {
    type: INVOICE_SUCCESS,
    payload,
  };
}

export function invoiceError(error: any) {
  return {
    type: INVOICE_ERROR,
    error,
  };
}

export function invoiceDeleteRequest(movId: string) {
  return {
    type: INVOICE_DELETE_REQUESTING,
    payload: movId,
  };
}

export function invoiceDeleteSuccess() {
  return {
    type: INVOICE_DELETE_SUCCESS,
  };
}

export function invoiceDeleteError(error: any) {
  return {
    type: INVOICE_DELETE_ERROR,
    error,
  };
}
