// component do form do modulo dest
import { Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as Yup from "yup";
import CitySelector from "../../../components/CitySelector";
import CheckInput from "../../../components/FormikComponents/CheckInput";
import DateInput from "../../../components/FormikComponents/DateInput";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import RadioInput from "../../../components/FormikComponents/RadioInput";
import SelectInput from "../../../components/FormikComponents/SelectInput";
import TextInput from "../../../components/FormikComponents/TextInput";
import { LinkButton } from "../../../components/LinkButton";
import LoaderButton from "../../../components/LoaderButton";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import CondView from "../../cond/condView";
import InvoiceView from "../../invoice/invoiceView";
import PaymentView from "../../payment/paymentView";
import TaskList from "../../task/taskView";
import { Dest } from "../actions";
import { formInit, formSave } from "./actions";
import DestObsAdd from "./DestObsAdd";

type DestFormProps = {
  item: Dest;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  formInit: (cadId: string) => void;
  formSave: (cad: Dest) => void;
  id: string;
};

class DestForm extends Component<DestFormProps, {}> {
  state = {};

  componentDidMount() {
    this.props.formInit(this.props.id);
  }

  handleSubmit = (item: Dest) => {
    this.props.formSave(item);
  };

  render() {
    const isSaving = this.props.isSaving;

    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>Cliente/Fornecedor</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <LinkButton variant="primary" to="/dest">
              Todos os Clientes/Fornecedores
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required(
                "Preenchimento do nome/razão social é obrigatório"
              ),
              type: Yup.number(),
              indFinal: Yup.bool(),
              cpf: Yup.string(),
              rg: Yup.string(),
              cnpj: Yup.string(),
              indIe: Yup.number(),
              ie: Yup.string(),
              iest: Yup.string(),
              isuf: Yup.string(),
              im: Yup.string(),
              estate: Yup.string(),
              city: Yup.string(),
              zipCode: Yup.string(),
              address: Yup.string(),
              addressNumber: Yup.string(),
              bairro: Yup.string(),
              cmun: Yup.string(),
              email: Yup.string().email("Deve ser uma email valido"),
              phone: Yup.string(),
            })}
          >
            {(props) => {
              const { isValid, handleSubmit } = props;
              const handleObsAdd = (obs: string) => {
                const obss = props.values.obss || [];
                obss.push({ entryDate: new Date(), obs });
                props.setFieldValue("obss", obss);
              };
              const handleObsRemove = (index: number) => {
                const obss = props.values.obss || [];
                if (obss.length > index) {
                  obss.splice(index, 1);
                }
                props.setFieldValue("obss", obss);
              };
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <RadioInput
                      name="type"
                      label=""
                      formik={props}
                      size={10}
                      options={{
                        "Pessoa Física": 1,
                        "Pessoa Jurídica": 2,
                      }}
                    />
                    <CheckInput
                      label="Consumidor Final"
                      name="indFinal"
                      formik={props}
                      size={2}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      name="name"
                      label={props.values.type === 1 ? "Nome" : "Razão Social"}
                      formik={props}
                      size={12}
                    />
                  </Row>
                  {props.values.type === 1 ? (
                    <Row>
                      <MaskInput
                        name="cpf"
                        label="CPF"
                        mask="999.999.999-99"
                        formik={props}
                        size={4}
                      />
                      <MaskInput
                        name="rg"
                        label="RG"
                        mask="999999999999999"
                        formik={props}
                        size={4}
                      />
                      <DateInput
                        name="dn"
                        label="Data de nascimento"
                        formik={props}
                        size={4}
                      />
                    </Row>
                  ) : (
                    <>
                      <Row>
                        <MaskInput
                          name="cnpj"
                          label="CNPJ"
                          mask="99.999.999/9999-99"
                          formik={props}
                          size={3}
                        />
                        <SelectInput
                          name="indIe"
                          label="Tipo IE"
                          formik={props}
                          size={3}
                        >
                          <option value={1}>Normal</option>
                          <option value={2}>Isento</option>
                          <option value={9}>Não contribuinte</option>
                        </SelectInput>
                        {props.values.indIe === 1 ? (
                          <MaskInput
                            name="ie"
                            label="IE"
                            mask="9999999999999999"
                            formik={props}
                            size={3}
                          />
                        ) : null}
                        <MaskInput
                          name="iest"
                          label="IE-ST"
                          mask="9999999999999999"
                          formik={props}
                          size={3}
                        />
                      </Row>
                      <Row>
                        <MaskInput
                          name="isuf"
                          label="Sulframa"
                          mask="9999999999999999"
                          formik={props}
                          size={6}
                        />
                        <MaskInput
                          name="im"
                          label="IM"
                          mask="9999999999999999"
                          formik={props}
                          size={6}
                        />
                      </Row>
                    </>
                  )}
                  <Row>
                    <SelectInput
                      name="estate"
                      label="Estado"
                      formik={props}
                      size={1}
                    >
                      <option>AC</option>
                      <option>AL</option>
                      <option>AP</option>
                      <option>AM</option>
                      <option>BA</option>
                      <option>CE</option>
                      <option>DF</option>
                      <option>ES</option>
                      <option>GO</option>
                      <option>MA</option>
                      <option>MT</option>
                      <option>MS</option>
                      <option>MG</option>
                      <option>PA</option>
                      <option>PB</option>
                      <option>PR</option>
                      <option>PE</option>
                      <option>PI</option>
                      <option>RJ</option>
                      <option>RN</option>
                      <option>RS</option>
                      <option>RO</option>
                      <option>RR</option>
                      <option>SC</option>
                      <option>SP</option>
                      <option>SE</option>
                      <option>TO</option>
                    </SelectInput>
                    <Form.Group as={Col} controlId="city">
                      <Form.Label>Cidade:</Form.Label>
                      <CitySelector
                        uf={props.values.estate}
                        value={props.values.city}
                        onChange={(city) => {
                          props.setFieldValue("city", city.city);
                          props.setFieldValue("estate", city.estate);
                          props.setFieldValue("cmun", city.cmun);
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <MaskInput
                      name="zipCode"
                      label="CEP"
                      mask="99.999-999"
                      formik={props}
                      size={2}
                    />
                    <TextInput
                      name="address"
                      label="Logradouro"
                      formik={props}
                      size={9}
                    />
                    <TextInput
                      name="addressNumber"
                      label="Numero"
                      formik={props}
                      size={1}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      name="bairro"
                      label="Bairro"
                      formik={props}
                      size={8}
                    />
                    <TextInput
                      name="cmun"
                      label="Cód. Município"
                      formik={props}
                      size={4}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      name="email"
                      label="E-Mail"
                      formik={props}
                      size={6}
                    />
                    <MaskInput
                      name="phone"
                      label="Telefone"
                      mask="(99)99999-9999"
                      formik={props}
                      size={6}
                    />
                  </Row>
                  <Row>
                    {/* Add obs form */}
                    <DestObsAdd
                      name="destObsAdd"
                      label="Incluir Observação"
                      size={12}
                      onAdd={handleObsAdd}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <h3>Observações:</h3>
                        </Col>
                      </Row>
                      {(props.values.obss || []).length < 1 && (
                        <Row>
                          <Col>
                            <p>Nenhuma observação registrada!</p>
                          </Col>
                        </Row>
                      )}
                      {(props.values.obss || [])
                        .sort((a, b) =>
                          moment(a.entryDate).isBefore(b.entryDate) ? 1 : 0
                        )
                        .map((obs, i) => (
                          <div
                            key={`${i}`}
                            className="mt-1 mb-1 pt-1 pb-1 bg-light rounded-3"
                          >
                            <p>
                              <b>
                                {moment(obs.entryDate).format("DD/MM/YYYY")}
                              </b>
                            </p>
                            <p>{obs.obs}</p>
                            <p>
                              <Button
                                variant="danger"
                                onClick={() => handleObsRemove(i)}
                              >
                                Remover
                              </Button>
                            </p>
                          </div>
                        ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                      >
                        Salvar
                      </LoaderButton>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
          {this.props.item && this.props.item.cadId && (
            <>
              <Row>
                <Col>
                  <h1> Ficha Financeira</h1>
                </Col>
              </Row>
              <InvoiceView destId={this.props.item.cadId} />
              <PaymentView destId={this.props.item.cadId} />
              <TaskList dest={this.props.item} destId={this.props.item.cadId} />
              <CondView destId={this.props.item.cadId} />
            </>
          )}
        </LoadingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    item: state.dest.form.item,
    successMsg: state.dest.form.successMsg,
    error: state.dest.form.error,
    isRequesting: state.dest.form.isRequesting,
    isSaving: state.dest.form.isSaving,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    formInit: (cadId: string) => dispatch(formInit(cadId)),
    formSave: (cad: any) => dispatch(formSave(cad)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DestForm);
