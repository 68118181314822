import { redirectTo, RouteComponentProps, useLocation } from "@reach/router";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";
import { useIsSubscriptionValid } from "../misc/useIsSubscriptionValid";

interface AuthAndSubRouteProps extends RouteComponentProps {
  as: React.ElementType;
  children?: React.ReactNode;
}

export const AuthAndSubRoute: React.FC<AuthAndSubRouteProps> = ({
  as: Comp,
  ...rest
}) => {
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector(
    (state: RootState) => state.login.isAuthenticated
  );
  const isSubscriptionValid = useIsSubscriptionValid();
  const org = useSelector((state: RootState) => state.login.org);
  console.log("path", pathname, search);
  if (!isAuthenticated) {
    redirectTo(`/login?redirect=${pathname}${search}`);
  }
  if (!isSubscriptionValid) {
    redirectTo(`/subscription/subscriptions`);
  }
  console.log("org", org);
  if (org && org.orgStatus === "NEW") {
    console.log("org status new");
    redirectTo(`/Wizard`);
  }
  // const Comp = as;
  return <Comp {...rest} />;
};

export default AuthAndSubRoute;
