import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteButton from "../../components/DeleteButton";
import LoadingContainer from "../../components/LoadingContainer";
import { formatDate, formatNumber } from "../../misc/formatters";
import { RootState } from "../app/mainReducer";
import { caixaActions, RegistroCaixa } from "./reducer";
import { uuid4 } from "@sentry/utils";
import { parse, subDays } from "date-fns";
import TableOrCard from "../../components/TableOrCard";
import { Button, ButtonGroup, Col, Row, Accordion } from "react-bootstrap";
import { Formik, Form } from "formik";
import { DateField } from "../../components/FormikComponents/DateField";
import { TextField } from "../../components/FormikComponents/TextField";
import { SelectField } from "../../components/FormikComponents/SelectField";
import { NumberField } from "../../components/FormikComponents/NumberField";
import LoaderButton from "../../components/LoaderButton";
import ShowError from "../../components/ShowError";

type ItemCaixa = {
  id: string;
  data: Date;
  descricao: string;
  valor: number;
  saldo: number;
};

const ListaLancamentos: React.FC<{
  items: RegistroCaixa[];
  ultimoSaldo?: RegistroCaixa;
  headers: any[];
}> = ({ items, headers, ultimoSaldo }) => {
  if (items.length === 0) {
    if (ultimoSaldo) {
      const data = parse(ultimoSaldo.movId, "yyyy_MM_dd", new Date());
      const itensCaixa: ItemCaixa[] = [
        {
          id: "",
          data,
          descricao: "Saldo Anterior",
          valor: ultimoSaldo.saldo_fechamento || ultimoSaldo.saldo_final,
          saldo: ultimoSaldo.saldo_fechamento || ultimoSaldo.saldo_final,
        },
      ];
      return <TableOrCard headers={headers} items={itensCaixa} />;
    }
    return <h2>Nenhum lançamento encontrado!</h2>;
  }

  const itensCaixa: ItemCaixa[] = [];

  let saldo = 0;
  let saldo_fechamento = 0;
  let data_final = new Date();
  for (const item of items) {
    const data = parse(item.movId, "yyyy_MM_dd", new Date());
    data_final = parse(item.movId, "yyyy_MM_dd", new Date());
    if (itensCaixa.length === 0) {
      saldo = item.saldo_inicial;
      // Inclui registro de saldo inicial
      itensCaixa.push({
        id: "",
        data,
        descricao: "Saldo Inicial",
        valor: 0,
        saldo: item.saldo_inicial,
      });
    }
    for (const lancamento of item.lancamentos) {
      saldo += lancamento.valor;
      itensCaixa.push({
        id: lancamento.id,
        data,
        descricao: lancamento.descricao,
        valor: lancamento.valor,
        saldo,
      });
    }
    if (item.saldo_fechamento) {
      saldo_fechamento = item.saldo_fechamento;
    }
  }

  if (saldo_fechamento) {
    itensCaixa.push({
      id: "",
      data: data_final,
      descricao: "Saldo Fechamento",
      valor: 0,
      saldo: saldo_fechamento,
    });
  }

  return <TableOrCard headers={headers} items={itensCaixa} />;
};

type FormCriarLancamentoCaixaProps = {
  onCreate: (obj: { descricao: string; tipo: number; valor: number }) => void;
  isCreating: boolean;
};

const FormCriarLancamentoCaixa: React.FC<FormCriarLancamentoCaixaProps> = ({
  onCreate,
  isCreating,
}) => {
  return (
    <Formik
      initialValues={{
        descricao: "",
        tipo: 1,
        valor: 0,
      }}
      onSubmit={(values) => {
        console.log("submited values", values);
        onCreate(values);
      }}
    >
      {(props) => (
        <Form>
          <Row>
            <Col xs={12} md={7}>
              <TextField label="Descrição" name="descricao" />
            </Col>
            <Col xs={12} md={2}>
              <SelectField label="Tipo" name="tipo">
                <option value={1}>Entrada</option>
                <option value={2}>Saida</option>
              </SelectField>
            </Col>
            <Col xs={12} md={2}>
              <NumberField label="Valor" name="valor" decimalPlaces={2} />
            </Col>
            <Col
              xs={12}
              md={1}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <LoaderButton
                isLoading={isCreating}
                onClick={() => props.submitForm()}
                type="submit"
              >
                Incluir
              </LoaderButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

const FormFecharCaixa: React.FC<{
  isFechando: boolean;
  onSubmit: (valor: number) => void;
}> = ({ isFechando, onSubmit }) => {
  return (
    <Formik
      initialValues={{ valor: 0 }}
      onSubmit={(values) => onSubmit(values.valor)}
    >
      {() => (
        <Form>
          <Col xs={12}>
            <NumberField
              label="Dinheiro em Caixa ($)"
              name="valor"
              decimalPlaces={2}
            />
            <LoaderButton
              isLoading={isFechando}
              style={{ marginTop: "10pt" }}
              type="submit"
            >
              Fechar Caixa
            </LoaderButton>
          </Col>
        </Form>
      )}
    </Formik>
  );
};

export const Caixa: React.FC<RouteComponentProps> = () => {
  const isRequesting = useSelector(
    (state: RootState) => state.caixa.isRequesting
  );
  const items = useSelector((state: RootState) => state.caixa.items);
  const ultimoSaldo = useSelector(
    (state: RootState) => state.caixa.ultimoSaldo
  );
  const isRemoving = useSelector((state: RootState) => state.caixa.isRemoving);
  const isCreating = useSelector((state: RootState) => state.caixa.isCreating);
  const isFechando = useSelector((state: RootState) => state.caixa.isFechando);
  const error = useSelector((state: RootState) => state.caixa.error);
  const [tipoData, setTipoData] = useState(1);

  const dispatch = useDispatch();

  const handleRequestData = (
    data_inicial = new Date(),
    data_final = new Date()
  ) => {
    dispatch(caixaActions.requestCaixa({ data_inicial, data_final }));
  };
  const handleTipoDataSelect = (tipoData: number) => {
    let data_inicial = new Date();
    let data_final = new Date();

    switch (tipoData) {
      case 1: // Hoje
        setTipoData(1);
        break;
      case 2: // 3 dias
        setTipoData(2);
        data_inicial = subDays(data_final, 3);
        break;
      case 3: // 7 dias
        setTipoData(3);
        data_inicial = subDays(data_final, 7);
        break;
      case 4: // 10 dias
        setTipoData(4);
        data_inicial = subDays(data_final, 10);
        break;
      case 5: // 15 dias
        setTipoData(5);
        data_inicial = subDays(data_final, 15);
        break;
      case 6: // 30 dias
        setTipoData(6);
        data_inicial = subDays(data_final, 30);
        break;
      case 0: // Personalizado
        setTipoData(0);
        return;
      default:
        setTipoData(0);
        return;
    }

    handleRequestData(data_inicial, data_final);
  };

  const handleRequestCreate = (dados) => {
    dispatch(
      caixaActions.createLancamento({
        id: `${uuid4()}`,
        valor: dados.valor,
        descricao: dados.descricao,
      })
    );
  };

  const handleRequestRemove = (id: string) => {
    dispatch(caixaActions.removerLancamento(id));
  };

  const headers = [
    {
      label: "Data",
      render: (item: ItemCaixa) => formatDate(item.data),
    },
    {
      label: "Descrição",
      dataIndex: "descricao",
    },
    {
      label: "Tipo",
      render: (item: ItemCaixa) =>
        item.id ? (item.valor > 0 ? "Entrada" : "Saida") : "",
    },
    {
      label: "Valor",
      render: (item: ItemCaixa) => formatNumber(Math.abs(item.valor), 2, true),
    },
    {
      label: "Saldo",
      render: (item: ItemCaixa) => formatNumber(item.saldo, 2, true),
    },
    {
      label: "Ações",
      render: (item: ItemCaixa) =>
        item.id ? (
          <DeleteButton
            isRequesting={isRemoving}
            onDelete={() => handleRequestRemove(item.id)}
          />
        ) : null,
    },
  ];

  useEffect(() => {
    dispatch(
      caixaActions.requestCaixa({
        data_inicial: new Date(),
        data_final: new Date(),
      })
    );
  }, [dispatch]);

  return (
    <>
      <h1>Caixa</h1>
      {error && <ShowError error={error} />}
      <LoadingContainer isLoading={isRequesting}>
        {/* Form de incluir no caixa */}
        <FormCriarLancamentoCaixa
          isCreating={isCreating}
          onCreate={(values) => {
            handleRequestCreate({
              descricao: values.descricao,
              valor: (values.tipo * 1 === 1 ? 1 : -1) * values.valor,
            });
          }}
        />
        {/* Lista de datas para consulta */}
        <Row>
          <Col>
            <h3>Selecione o período a visualizar</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup>
              <Button
                disabled={tipoData === 1}
                variant={tipoData === 1 ? "primary" : "light"}
                onClick={() => handleTipoDataSelect(1)}
              >
                Hoje
              </Button>
              <Button
                disabled={tipoData === 2}
                variant={tipoData === 2 ? "primary" : "light"}
                onClick={() => handleTipoDataSelect(2)}
              >
                3 dias
              </Button>
              <Button
                disabled={tipoData === 3}
                variant={tipoData === 3 ? "primary" : "light"}
                onClick={() => handleTipoDataSelect(3)}
              >
                7 dias
              </Button>
              <Button
                disabled={tipoData === 4}
                variant={tipoData === 4 ? "primary" : "light"}
                onClick={() => handleTipoDataSelect(4)}
              >
                10 dias
              </Button>
              <Button
                disabled={tipoData === 5}
                variant={tipoData === 5 ? "primary" : "light"}
                onClick={() => handleTipoDataSelect(5)}
              >
                15 dias
              </Button>
              <Button
                disabled={tipoData === 6}
                variant={tipoData === 6 ? "primary" : "light"}
                onClick={() => handleTipoDataSelect(6)}
              >
                30 dias
              </Button>
              <Button
                disabled={tipoData === 0}
                variant={tipoData === 0 ? "primary" : "light"}
                onClick={() => handleTipoDataSelect(0)}
              >
                Personalizado
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        {tipoData === 0 && (
          <Row>
            <Col>
              <Formik
                onSubmit={(values) => {
                  handleRequestData(values.data_inicial, values.data_final);
                }}
                initialValues={{
                  data_inicial: new Date(),
                  data_final: new Date(),
                }}
              >
                {() => (
                  <>
                    <Row>
                      <Col>
                        <DateField label="Data Inicial" name="data_inicial" />
                      </Col>
                      <Col>
                        <DateField label="Data Final" name="data_final" />
                      </Col>
                      <Col>
                        <Button type="submit" variant="primary">
                          Aplicar Filtro
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Formik>
            </Col>
          </Row>
        )}
        {/* Lista de registros com saldo inicial e final */}
        <ListaLancamentos
          items={items}
          headers={headers}
          ultimoSaldo={ultimoSaldo}
        />
        {items.length > 0 && (
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Fechar caixa</Accordion.Header>
              <Accordion.Body>
                <FormFecharCaixa
                  isFechando={isFechando}
                  onSubmit={(valor) =>
                    dispatch(caixaActions.requestFecharCaixa(valor))
                  }
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </LoadingContainer>
    </>
  );
};
