import React from "react";
import { Form } from "react-bootstrap";

type CstPisCofinsProps = {
  value: string;
  onChange: (value: string) => void;
  type: "E" | "S";
};

const CstPisCofins: React.FC<CstPisCofinsProps> = (props) => (
  <Form.Control
    as="select"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  >
    {props.type === "S" ? (
      <>
        <option value="01">Operação Tributável - Aliquota básica</option>
        <option value="02">Operação Tributável - Aliquota diferenciada</option>
        <option value="03">
          Operação Tributável - Aliquota por unidade de produto
        </option>
        <option value="04">
          Operação Tributável Monofásica - Revenda a Alíquota Zero
        </option>
        <option value="05">
          Operação Tributável por Substituição Tributária
        </option>
        <option value="06">Operação Tributável a Alíquota Zero</option>
        <option value="07">Operação Isenta da Contribuição</option>
        <option value="08">Operação sem Incidência da Contribuição</option>
        <option value="09">Operação com Suspensão da Contribuição</option>
        <option value="49">Outras Operações de Saída</option>
      </>
    ) : null}
    {props.type === "E" ? (
      <>
        <option value="50">
          Operação com Direito a Crédito - Tributada - Exclusivo Mercado interno
        </option>
        <option value="51">
          Operação com Direito a Crédito - Não Tributada - Exclusivo Mercado
          interno
        </option>
        <option value="52">
          Operação com Direito a Crédito - Exclusivo Exportação
        </option>
        <option value="53">
          Operação com Direito a Crédito - Tributada/Não Tributada - Mercado
          interno
        </option>
        <option value="54">
          Operação com Direito a Crédito - Tributada - Mercado interno e
          Exportação
        </option>
        <option value="55">
          Operação com Direito a Crédito - Não Tributada - Mercado interno e
          Exportação
        </option>
        <option value="56">Operação com Direito a Crédito - Todas</option>
        <option value="60">
          Crédito Presumido - Tributada - Exclusivo Mercado interno
        </option>
        <option value="61">
          Crédito Presumido - Não Tributada - Exclusivo Mercado interno
        </option>
        <option value="62">Crédito Presumido - Exclusivo Exportação</option>
        <option value="63">
          Crédito Presumido - Tributada/Não Tributada - Mercado interno
        </option>
        <option value="64">
          Crédito Presumido - Tributada - Mercado interno e Exportação
        </option>
        <option value="65">
          Crédito Presumido - Não Tributada - Mercado interno e Exportação
        </option>
        <option value="66">Crédito Presumido - Todas</option>
        <option value="67">Crédito Presumido - Outras operações</option>
        <option value="70">Operação de Aquisição sem Direito a Crédito</option>
        <option value="71">Operação de Aquisição com Isenção</option>
        <option value="72">Operação de Aquisição com Suspensão</option>
        <option value="73">Operação de Aquisição a Alíquota Zero</option>
        <option value="74">
          Operação de Aquisição sem Incidência da Contribuição
        </option>
        <option value="75">
          Operação de Aquisição por Substituição Tributária
        </option>
        <option value="98">Outras Operações de Entrada</option>
      </>
    ) : null}
    <option value="99">Outras Operações</option>
  </Form.Control>
);

export default CstPisCofins;
