import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {
  Alert,
  Col,
  Container,
  Form,
  Row,
  Table,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import LoaderButton from "../../components/LoaderButton";
import {
  CAD_TYPE,
  Prod,
  prodImportarInit,
  prodImportarRequest,
} from "./actions";
import xmljs from "xml-js";
import InputNumber from "../../components/InputNumber";

type ImportarXMLProps = {
  isImportarRequesting: boolean;
  nimportados: number;
  nerros: number;
  importarError: any;
  prodImportarRequest: (payload: Prod[]) => void;
  prodImportarInit: () => void;
};

type ImportarXMLState = {
  xml: Blob;
  wb: any;
  sheetData: any;
  selectedSheet: string;
  headerMap: Record<string, string>;
  itensAImportar: any[];
  parseError: string;
};

class ImportarXML extends Component<ImportarXMLProps, ImportarXMLState> {
  state = {
    xml: null,
    wb: null,
    sheetData: null,
    selectedSheet: "",
    headerMap: {},
    itensAImportar: [],
    parseError: "",
  };

  componentDidMount() {
    this.props.prodImportarInit();
  }

  parseXml = () => {
    if (!this.state.xml) {
      return;
    }
    console.log("reading xml");
    try {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result as string;
        console.log("loading", bstr);
        const jsNFe: any = xmljs.xml2js(bstr, { compact: true });
        console.log("jsNFe", jsNFe);
        console.log("jsNFe", jsNFe.nfeProc);
        let NFe = jsNFe.NFe;
        if (!NFe) {
          NFe = jsNFe.nfeProc && jsNFe.nfeProc.NFe;
        }
        if (!NFe) {
          this.setState({
            parseError: "O XML informado não é uma NFe valida!",
          });
          return;
        }
        if (Array.isArray(NFe.infNFe.det)) {
          const itensAImportar = NFe.infNFe.det.map((item) => ({
            cadType: CAD_TYPE,
            cod: item.prod.cProd._text,
            name: item.prod.xProd._text,
            ean: item.prod.cEAN._text,
            ncm: item.prod.NCM._text,
            cest: item.prod.CEST && item.prod.CEST._text,
            unitType: item.prod.uCom._text,
            preco: parseFloat(item.prod.vUnCom._text),
            qtd: parseFloat(item.prod.qCom._text),
            // qtd: 30,
            orig: 0,
          }));
          this.setState({ itensAImportar });
        } else {
          const itensAImportar = [
            {
              cadType: CAD_TYPE,
              cod: NFe.infNFe.det.prod.cProd._text,
              name: NFe.infNFe.det.prod.xProd._text,
              ean: NFe.infNFe.det.prod.cEAN._text,
              ncm: NFe.infNFe.det.prod.NCM._text,
              cest: NFe.infNFe.det.prod.CEST && NFe.infNFe.det.prod.CEST._text,
              unitType: NFe.infNFe.det.prod.uCom._text,
              preco: parseFloat(NFe.infNFe.det.prod.vUnCom._text),
              qtd: parseFloat(NFe.infNFe.det.prod.qCom._text),
              // qtd: 30,
              orig: 0,
            },
          ];
          this.setState({ itensAImportar });
        }
      };
      reader.readAsText(this.state.xml, "UTF-8");
      //reader.readAsBinaryString(this.state.xml);
    } catch (error) {
      this.setState({ parseError: "O XML informado não é uma NFe valida!" });
      console.log("error xml parse", error);
    }
  };

  handleItemChange = (objItem, index) => {
    const itensAImportar = [...this.state.itensAImportar];
    if (itensAImportar[index]) {
      itensAImportar[index] = Object.assign({}, itensAImportar[index], objItem);
    }
    this.setState({
      itensAImportar,
    });
  };

  handleSubmitImport = () => {
    // Create the JSON object array

    if (!this.state.itensAImportar || this.state.itensAImportar.length < 1) {
      return;
    }

    this.props.prodImportarRequest(this.state.itensAImportar);
  };

  renderXmlParsed = () => {
    if (!this.state.itensAImportar || this.state.itensAImportar.length < 1) {
      return null;
    }

    const totalItens = this.state.itensAImportar.length;

    return (
      <>
        <Row className="d-print-none">
          <Col md={11} sm={11} xs={12} lg={11} xl={11}>
            <p>Confirme para importar {totalItens} produtos.</p>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1} xl={1}>
            <LoaderButton
              isLoading={this.props.isImportarRequesting}
              disabled={false}
              variant="primary"
              onClick={this.handleSubmitImport}
            >
              Concluir
            </LoaderButton>
          </Col>
        </Row>
        {/* <Row className="mt-2 mb-2">
          <Col xs={12} sm={3} md={3} lg={3} xl={3}>
            <Button variant="secondary" onClick={this.handlePrint33x21}>
              Imprimir Etiqueta 33x21mm Argox
            </Button>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3} xl={3}>
            <Button variant="secondary" onClick={this.handlePrint33x21text}>
              Imprimir Etiqueta 33x21mm Argox 2
            </Button>
          </Col>
        </Row> */}
        <Row className="d-print-none">
          <Col>
            <Alert variant="dark">
              Abaixo se encontram os registros a importar, voce pode alterar o
              que quiser e clicar em concluir para prosseguir.
            </Alert>
          </Col>
        </Row>
        <Row className="d-print-none">
          <Col>
            <Table variant="dark" striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Código</th>
                  <th>Descrição</th>
                  <th>EAN</th>
                  <th>NCM</th>
                  <th>CEST</th>
                  <th>Unidade</th>
                  <th>Qtd</th>
                  <th>Preço</th>
                  <th>Origem da mercadoria</th>
                </tr>
              </thead>
              <tbody>
                {this.state.itensAImportar.map((item, i) => (
                  <tr key={`${i}`}>
                    <td>{i + 1}</td>
                    <td>
                      <input
                        type="text"
                        id={`cod${i}`}
                        value={item.cod}
                        onChange={(e) =>
                          this.handleItemChange({ cod: e.target.value }, i)
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id={`name${i}`}
                        value={item.name}
                        onChange={(e) =>
                          this.handleItemChange({ name: e.target.value }, i)
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id={`ean${i}`}
                        value={item.ean}
                        onChange={(e) =>
                          this.handleItemChange({ ean: e.target.value }, i)
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id={`ncm${i}`}
                        value={item.ncm}
                        onChange={(e) =>
                          this.handleItemChange({ ncm: e.target.value }, i)
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id={`cest${i}`}
                        value={item.cest}
                        onChange={(e) =>
                          this.handleItemChange({ cest: e.target.value }, i)
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id={`unitType${i}`}
                        value={item.unitType}
                        onChange={(e) =>
                          this.handleItemChange({ unitType: e.target.value }, i)
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <InputNumber
                        value={item.qtd}
                        decimalPlaces={0}
                        onChange={(qtd) => this.handleItemChange({ qtd }, i)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        value={item.preco}
                        decimalPlaces={2}
                        onChange={(preco) =>
                          this.handleItemChange({ preco }, i)
                        }
                      />
                    </td>
                    <td>
                      <select
                        value={item.orig}
                        onChange={(e) =>
                          this.handleItemChange({ orig: e.target.value }, i)
                        }
                        className="form-control"
                      >
                        <option value={0}>Nacional</option>
                        <option value={1}>Estrangeira Importação Direta</option>
                        <option value={2}>
                          Estrangeira Adquirida no mercado interna
                        </option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    if (this.props.nimportados > 0) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <h2>
                Importados {this.props.nimportados} com sucesso, e{" "}
                {this.props.nerros} não puderam ser importados!
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => this.props.prodImportarInit()}
              >
                Importar outro arquivo
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container fluid>
        <Row className="d-print-none">
          <Col>
            <h2>Importar produtos de uma NFe</h2>
          </Col>
        </Row>
        <Row className="d-print-none">
          <Col>
            <Alert variant="warning">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              AVISO: Ao importar os produtos pode ser que o sistema importe a
              maioria e falhe ao importar alguns dos itens!
            </Alert>
          </Col>
        </Row>
        {!!this.props.importarError && (
          <Row className="d-print-none">
            <Col>
              <Alert variant="danger">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                Ocorreu um erro ao importar! Detalhes:{" "}
                {this.props.importarError}
              </Alert>
            </Col>
          </Row>
        )}
        <Row className="d-print-none">
          <Col>
            <Form.Group as={Col} controlId="xml">
              <label htmlFor="xml" className="btn btn-secondary">
                Selecionar um arquivo XML
              </label>
              <input
                accept=".xml"
                type="file"
                onChange={(e) =>
                  this.setState({ xml: e.target.files[0] }, this.parseXml)
                }
                style={{ visibility: "hidden" }}
                id="xml"
              />
              {this.state.xml && `Arquivo selecionado: ${this.state.xml.name}`}
            </Form.Group>
          </Col>
        </Row>
        {this.renderXmlParsed()}
        {/* <PrintBarcode itens={this.state.itensAImportar} /> */}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isImportarRequesting: state.prod.list.isImportarRequesting,
    nimportados: state.prod.list.nimportados,
    nerros: state.prod.list.nerros,
    importarError: state.prod.list.importarError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    prodImportarRequest: (payload) => dispatch(prodImportarRequest(payload)),
    prodImportarInit: () => dispatch(prodImportarInit()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportarXML);
