import React from "react";
import { Modal } from "react-bootstrap";

type ModalDialogProps = {
  title: string;
  buttons: React.ReactNode | React.ReactNodeArray;
  show: boolean;
  onClose: () => void;
};

const ModalDialog: React.FC<ModalDialogProps> = (props) => (
  <Modal show={props.show} onHide={props.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>{props.children}</Modal.Body>

    <Modal.Footer>{props.buttons}</Modal.Footer>
  </Modal>
);

export default ModalDialog;
