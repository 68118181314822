import { navigate, RouteComponentProps } from "@reach/router";
import React from "react";
import { connect } from "react-redux";

function querystring(name: string, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

interface UnauthenticatedRouteProps extends RouteComponentProps {
  isAuthenticated: boolean;
  as: React.ElementType;
}

const UnauthenticatedRoute: React.FC<UnauthenticatedRouteProps> = ({
  isAuthenticated,
  as: Comp,
  ...rest
}) => {
  const redirect = querystring("redirect");
  console.log(
    "unauth route",
    isAuthenticated,
    redirect,
    redirect === "" || redirect === null ? "/" : redirect
  );

  if (isAuthenticated) {
    navigate(redirect === "" || redirect === null ? "/" : redirect);
    return null;
  }
  return <Comp {...rest} />;
};

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.login.isAuthenticated,
  };
};

export default connect(mapStateToProps)(UnauthenticatedRoute);
