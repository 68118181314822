import { takeLatest } from "redux-saga/effects";
import { AWSRes } from "./../../redux/types";
import { bancoActions } from "./reducer";
import { all, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import executeApiRequest from "../../misc/executeApiRequest";
import { Banco } from "./types";
import bancoFormSaga from "./bancoForm/saga";
import bancoSelectorSaga from "./selector/saga";

function* requestBancosWorker({
  payload,
}: PayloadAction<{ lastKey?: Record<string, string> }>) {
  try {
    const res: AWSRes<Banco> = yield call(
      executeApiRequest,
      `/banco`,
      "GET",
      {},
      {
        queryStringParameters: {
          lastKey: payload.lastKey,
        },
      }
    );
    yield put(bancoActions.requestSuccess(res));
  } catch (error) {
    yield put(bancoActions.requestError(error));
  }
}

function* bancoDeleteWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(executeApiRequest, `/banco/${payload}`, "DELETE", {});
    yield put(bancoActions.deleteSuccess());
    yield put(bancoActions.requestBancos({}));
  } catch (error) {
    yield put(bancoActions.deleteError(error));
  }
}

export default function* bancosSaga() {
  yield all([
    takeLatest("banco/requestBancos", requestBancosWorker),
    takeLatest("banco/deleteRequest", bancoDeleteWorker),
    bancoFormSaga(),
    bancoSelectorSaga(),
  ]);
}
