import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { formatDate, formatNumber } from "../../../misc/formatters";
import usePrevious from "../../../misc/usePrevious";
import geraParcelas from "../../../misc/geraParcelas";
import { Purchase } from "../types";
import { Col, Row } from "react-bootstrap";
import TableOrCard from "../../../components/TableOrCard";
import { DateField } from "../../../components/FormikComponents/DateField";

export const ParcelasPurchase: React.FC<{}> = () => {
  const {
    values: {
      items,
      geraPagamento,
      nparcelas,
      desconto,
      acrescimo,
      entry,
      parcelas,
    },
    setFieldValue,
  } = useFormikContext<Purchase>();

  const vTotal =
    items.reduce((a, p) => a + p.total, 0) - (desconto || 0) + (acrescimo || 0);
  const prevTotal = usePrevious(vTotal);
  const prevGera = usePrevious(geraPagamento);
  const prevNparcelas = usePrevious(nparcelas);
  const [isChangingVencimento, setChangingVencimento] = useState(false);

  useEffect(() => {
    if (
      Math.abs(vTotal - prevTotal) === 0 &&
      prevGera === geraPagamento &&
      prevNparcelas === nparcelas
    ) {
      return;
    }
    setChangingVencimento(false);
    if (!geraPagamento) {
      console.log("nao gera parcelas", geraPagamento);
      return setFieldValue("parcelas", []);
    }

    const parcelas = geraParcelas(vTotal, nparcelas || 0, entry);
    console.log("gerado parcelas", vTotal, nparcelas, parcelas);
    setFieldValue("parcelas", parcelas);
  }, [
    vTotal,
    prevTotal,
    prevGera,
    setFieldValue,
    acrescimo,
    desconto,
    entry,
    geraPagamento,
    nparcelas,
    prevNparcelas,
  ]);

  const headerParcelas = [
    {
      label: "#",
      dataIndex: "nParcela",
    },
    {
      label: "Vencimento",
      render: (item, i) =>
        isChangingVencimento ? (
          <>
            <div style={{ maxWidth: "20ch" }}>
              <DateField name={`parcelas[${i}].venc`} label="" />
            </div>
          </>
        ) : (
          <div onClick={() => setChangingVencimento(true)}>
            {formatDate(item.venc)}
          </div>
        ),
    },
    {
      label: "Valor ($)",
      render: (item) => formatNumber(item.valor, 2, true),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <h4>Parcelas</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableOrCard headers={headerParcelas} items={parcelas || []} />
        </Col>
      </Row>
    </>
  );
};
