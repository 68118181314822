import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { ProdField } from "../../prod/selector";
import { Purchase } from "../types";
import { NumberField } from "../../../components/FormikComponents/NumberField";
import { useFormikContext } from "formik";
import usePrevious from "../../../misc/usePrevious";

const ItemForm: React.FC<{ itemEditIndex: number }> = ({ itemEditIndex }) => {
  const {
    values: { items },
    setFieldValue,
  } = useFormikContext<Purchase>();

  const { qtd, valor, total } = items[itemEditIndex];
  const previousQtd = usePrevious(qtd);
  const prevValor = usePrevious(valor);
  const prevTotal = usePrevious(total);

  useEffect(() => {
    if (Math.abs(qtd * valor - total) === 0) {
      return;
    }

    if (qtd !== previousQtd) {
      setFieldValue(`items[${itemEditIndex}].total`, qtd * valor);
    }
    if (valor !== prevValor) {
      setFieldValue(`items[${itemEditIndex}].total`, qtd * valor);
    }
    if (total !== prevTotal) {
      if (qtd > 0) {
        setFieldValue(`items[${itemEditIndex}].valor`, total / qtd);
      } else {
        setFieldValue(`items[${itemEditIndex}].valor`, total);
        setFieldValue(`items[${itemEditIndex}].qtd`, 1);
      }
    }
  }, [
    qtd,
    valor,
    setFieldValue,
    itemEditIndex,
    previousQtd,
    prevValor,
    prevTotal,
    total,
  ]);

  return (
    <>
      <Row>
        <Col>
          <ProdField name={`items[${itemEditIndex}].prod`} label="Produto" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <NumberField
            name={`items[${itemEditIndex}].qtd`}
            label="Quantidade"
            decimalPlaces={0}
          />
        </Col>
        <Col xs={12} md={4}>
          <NumberField
            name={`items[${itemEditIndex}].valor`}
            label="Valor Unitário"
          />
        </Col>
        <Col xs={12} md={4}>
          <NumberField name={`items[${itemEditIndex}].total`} label="Total" />
        </Col>
      </Row>
    </>
  );
};

// type ItemFormProps = {
//   item: PurchaseItem;
//   handleChange: (obj: any) => void;
// };

// type ItemFormState = {};

// class ItemForm extends Component<ItemFormProps, ItemFormState> {
//   static propTypes = {
//     item: PropTypes.object.isRequired,
//     handleChange: PropTypes.func.isRequired,
//   };

//   render() {
//     const { item } = this.props;
//     return (
//       <>
//         <Row>
//           <Form.Group as={Col} controlId="prod" xs={12}>
//             <Form.Label>Produto</Form.Label>
//             <ProdSelector
//               value={item.prod}
//               onChange={(prod) =>
//                 this.props.handleChange({
//                   prod,
//                   value: prod ? prod.preco : 0,
//                   total: (prod ? prod.preco : 0) * item.qtd,
//                 })
//               }
//             />
//           </Form.Group>
//         </Row>
//         <Row>
//           <Form.Group as={Col} controlId="qtd" xs={12} md={4}>
//             <Form.Label>Quantidade</Form.Label>
//             <InputNumber
//               placeholder="Quantidade"
//               value={item.qtd}
//               decimalPlaces={0}
//               onChange={(qtd) =>
//                 this.props.handleChange({ qtd, total: item.valor * qtd })
//               }
//             />
//           </Form.Group>
//           <Form.Group as={Col} controlId="valor" xs={12} md={4}>
//             <Form.Label>Valor Unitário</Form.Label>
//             <InputNumber
//               placeholder="Valor"
//               value={item.valor}
//               decimalPlaces={2}
//               onChange={(valor) =>
//                 this.props.handleChange({ valor, total: item.qtd * valor })
//               }
//             />
//           </Form.Group>
//           <Form.Group as={Col} controlId="total" xs={12} md={4}>
//             <Form.Label>Total</Form.Label>
//             <InputNumber
//               placeholder="Total"
//               value={item.total}
//               decimalPlaces={2}
//               onChange={(total) =>
//                 this.props.handleChange({
//                   total,
//                   valor: item.qtd > 0 ? total / item.qtd : total,
//                   qtd: item.qtd > 0 ? item.qtd : 1,
//                 })
//               }
//             />
//           </Form.Group>
//         </Row>
//       </>
//     );
//   }
// }

export default ItemForm;
