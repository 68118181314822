import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faMagnet } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "@reach/router";

const PageNotFound: React.FC<RouteComponentProps> = () => (
  <Container className="mt-4">
    <Row>
      <Col>
        <h1>
          <Icon icon={faMagnet} /> A página que voce está tentando acessar não
          existe, ou ainda não esta disponível.{" "}
        </h1>
        <h2 className="mt-3">Tente novamente mais tarde!</h2>
      </Col>
    </Row>
  </Container>
);

export default PageNotFound;
