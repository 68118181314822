import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AWSRes } from "../../redux/types";
import { Service } from "./type";
import formReducer from "./servicesForm/reducer";

type TInitialState = {
  items: Service[];
  Count: number;
  lastKey: Record<string, string>;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isDeleting: boolean;
  isEmitirNfsRequesting: boolean;
  isCancelingNFs: boolean;
};

const initialState: TInitialState = {
  items: [],
  Count: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
  isEmitirNfsRequesting: false,
  isCancelingNFs: false,
};

export type TRequestServices = {
  status: string[];
  inicio?: string;
  fim?: string;
  modo?: string;
};

const reducerSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    servicesRequest(state, _: PayloadAction<TRequestServices>) {
      state.Count = 0;
      state.items = [];
      state.lastKey = null;
      state.error = "";
      state.successMsg = "";
      state.isRequesting = true;
      state.isCancelingNFs = false;
    },
    servicesSuccess(state, { payload }: PayloadAction<AWSRes<Service>>) {
      state.Count = payload.Count;
      state.items = payload.items;
      state.lastKey = payload.lastKey;
      state.isRequesting = false;
    },
    servicesError(state, { payload }: PayloadAction<string>) {
      state.isRequesting = false;
      state.error = payload;
    },
    deleteRequest(state, _: PayloadAction<Service>) {
      state.isDeleting = true;
    },
    deleteSuccess(state) {
      state.isDeleting = false;
      state.successMsg = `Serviço removido com sucesso!`;
    },
    deleteError(state, { payload }: PayloadAction<string>) {
      state.isDeleting = false;
      state.error = payload;
    },
    emitirNfsRequest(state, _: PayloadAction<Service>) {
      state.isEmitirNfsRequesting = true;
      state.successMsg = "";
      state.error = "";
    },
    emitirNfsSuccess(state, { payload }: PayloadAction<Service>) {
      state.isEmitirNfsRequesting = false;
      state.successMsg = `NFS ${payload.num} emitida com sucesso!`;
      const items = [...state.items];
      const i = items.findIndex((item) => (item.invoiceId = payload.invoiceId));
      items[i].NFsAutorizada = true;
      state.items = items;
    },
    emitirNfsError(state, { payload }: PayloadAction<string>) {
      state.isEmitirNfsRequesting = false;
      state.error = payload;
    },
    cancelaNfsRequest(state, { payload }: PayloadAction<Service>) {
      state.isCancelingNFs = true;
      state.error = "";
      state.successMsg = "";
    },
    cancelaNfsSuccess(state, { payload }: PayloadAction<Service>) {
      state.isCancelingNFs = false;
      state.successMsg = `NFS ${payload.num} cancelada com sucesso!`;
      const items = [...state.items];
      const i = items.findIndex((item) => (item.invoiceId = payload.invoiceId));
      items[i].NFsCancelada = true;
      state.items = items;
    },
    cancelaNfsError(state, { payload }: PayloadAction<string>) {
      state.isCancelingNFs = false;
      state.error = payload;
    },
  },
});

export const servicesActions = reducerSlice.actions;

export default combineReducers({
  list: reducerSlice.reducer,
  form: formReducer.reducer,
});
