import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, call, takeLatest, select } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { s3Delete, s3Upload, s3Download } from "../../../misc/awsLib";
import { RootState } from "../../app/mainReducer";
import { CAD_TYPE, Emissor } from "../reducer";
import { EmissorSaveObject, formActions } from "./reducer";

const getOrg = (state: RootState) => state.login.org;

const newEmissor = (): Emissor => {
  return {
    cadType: CAD_TYPE,
    name: "",
    fant: "",
    cnpj: "",
    ie: "",
    iest: "",
    im: "",
    cnae: "",
    regime: 1,
    estate: "",
    city: "",
    zipCode: "",
    address: "",
    addressNumber: "",
    bairro: "",
    cmun: "",
    email: "",
    phone: "",
    modoNFe: 1,
    cnpjEscritorio: "",
    certificatePass: "",
  };
};
async function getLogo(logo: string) {
  return s3Download(logo);
}

function* emissorInitWorker({ payload }: any) {
  try {
    if (payload === "new") {
      yield put(formActions.formSuccess(newEmissor()));
    } else {
      const res = yield call(
        executeApiRequest,
        `/emissor/${payload}`,
        "GET",
        {},
        {}
      );
      if (res.logo) {
        res.logoUrl = yield call(getLogo, res.logo);
      }
      yield put(formActions.formSuccess(res));
    }
  } catch (error) {
    yield put(formActions.formError(error));
  }
}

function* uploadFile(oldFile: string, newFile: File, orgId: string) {
  if (oldFile) {
    yield call(s3Delete, oldFile);
  }
  return yield call(s3Upload, newFile, orgId);
}

function* emissorSaveWorker({ payload }: PayloadAction<EmissorSaveObject>) {
  try {
    // Get the org
    const org = yield select(getOrg);

    // Uploads Files
    if (payload.newLogo instanceof File) {
      payload.logo = yield call(
        uploadFile,
        payload.logo,
        payload.newLogo,
        org.cadId
      );
      delete payload.newLogo;
    }
    if (payload.newCertificate instanceof File) {
      payload.certificate = yield call(
        uploadFile,
        payload.certificate,
        payload.newCertificate,
        org.cadId
      );
      delete payload.newCertificate;
    }
    if (payload.newInterCrt instanceof File) {
      payload.interCrt = yield call(
        uploadFile,
        payload.interCrt,
        payload.newInterCrt,
        org.cadId
      );
      delete payload.newInterCrt;
    }
    if (payload.newInterKey instanceof File) {
      payload.interKey = yield call(
        uploadFile,
        payload.interKey,
        payload.newInterKey,
        org.cadId
      );
      delete payload.newInterKey;
    }
    if (payload.cadId) {
      // Update
      const res: Emissor = yield call(
        executeApiRequest,
        `/emissor/${payload.cadId}`,
        "PUT",
        payload
      );
      yield put(formActions.formSaveSuccess(res));
    } else {
      const res: Emissor = yield call(
        executeApiRequest,
        "/emissor",
        "POST",
        payload
      );
      yield put(formActions.formSaveSuccess(res));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(formActions.formSaveError(error.response.data));
  }
}

function* configInterWorker({ payload }: PayloadAction<string>) {
  try {
    yield executeApiRequest(`/config_inter/${payload}`, "GET", {}, {});
    yield put(formActions.configInterSuccess());
  } catch (error) {
    yield put(formActions.configInterError(error));
  }
}

export default function* emissorFormSaga() {
  yield all([
    takeLatest("emissor.form/formInit", emissorInitWorker),
    takeLatest("emissor.form/formSaveRequest", emissorSaveWorker),
    takeLatest("emissor.form/configInter", configInterWorker),
  ]);
}
