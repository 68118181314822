import { PayloadAction } from "@reduxjs/toolkit";
import { paymentReducerActions } from "./reducer";
import { AWSRes } from "./../../redux/types";
// saga do modulo payment
import { put, all, call, takeLatest } from "redux-saga/effects";
import formSaga from "./paymentForm/saga";
import paySaga from "./paymentPay/saga";
import viewSaga from "./paymentView/saga";
import executeApiRequest from "../../misc/executeApiRequest";
import PaymentParcela from "./types";
import { add, endOfWeek, format, startOfWeek } from "date-fns";

function paymentCall(
  idQuery: string,
  idFilterType: string,
  indexToUse: string,
  lastKey = null
) {
  return executeApiRequest<AWSRes<PaymentParcela>>(
    "/payment",
    "GET",
    {},
    {
      queryStringParameters: {
        idQuery,
        idFilterType,
        indexToUse,
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* thisweekWorker() {
  try {
    const dtIni = startOfWeek(new Date());
    const dtFim = endOfWeek(new Date());
    const res: AWSRes<PaymentParcela> = yield call(
      paymentCall,
      `OPEN.${format(dtIni, "yyyy_MM_dd")}:OPEN.${format(dtFim, "yyyy_MM_dd")}`,
      "BETWEEN",
      "lsi1"
    );
    yield put(paymentReducerActions.requestThisWeekSuccess(res));
  } catch (error) {
    yield put(paymentReducerActions.requestThisWeekError(error));
  }
}

function* nextweekWorker() {
  try {
    const dtIni = startOfWeek(add(new Date(), { days: 7 }));
    const dtFim = endOfWeek(dtIni);
    const res: AWSRes<PaymentParcela> = yield call(
      paymentCall,
      `OPEN.${format(dtIni, "yyyy_MM_dd")}:OPEN.${format(dtFim, "yyyy_MM_dd")}`,
      "BETWEEN",
      "lsi1"
    );
    yield put(paymentReducerActions.requestNextWeekSuccess(res));
  } catch (error) {
    yield put(paymentReducerActions.requestNextWeekError(error));
  }
}
function* next30Worker() {
  try {
    const dtIni = add(new Date(), { days: 1 });
    const dtFim = add(new Date(), { days: 30 });
    const res: AWSRes<PaymentParcela> = yield call(
      paymentCall,
      `OPEN.${format(dtIni, "yyyy_MM_dd")}:OPEN.${format(dtFim, "yyyy_MM_dd")}`,
      "BETWEEN",
      "lsi1"
    );
    yield put(paymentReducerActions.requestNext30Success(res));
  } catch (error) {
    yield put(paymentReducerActions.requestNext30Error(error));
  }
}

function* payedWorker() {
  try {
    const res: AWSRes<PaymentParcela> = yield call(
      paymentCall,
      `CLOSED`,
      "START",
      null
    );
    yield put(paymentReducerActions.requestPayedSuccess(res));
  } catch (error) {
    yield put(paymentReducerActions.requestPayedError(error));
  }
}

function* lastWorker() {
  try {
    const res: AWSRes<PaymentParcela> = yield call(
      paymentCall,
      `OPEN`,
      "START",
      null
    );
    yield put(paymentReducerActions.requestLastSuccess(res));
  } catch (error) {
    yield put(paymentReducerActions.requestLastError(error));
  }
}

function* paymentDeleteWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(executeApiRequest, `/payment/${payload}`, "DELETE", {}, {});
    yield put(paymentReducerActions.deleteSuccess());
    yield put(paymentReducerActions.requestThisWeek());
    yield put(paymentReducerActions.requestNextWeek());
    yield put(paymentReducerActions.requestNext30());
    yield put(paymentReducerActions.requestLast());
  } catch (error) {
    yield put(paymentReducerActions.deleteError(error));
  }
}

export default function* paymentSaga() {
  yield all([
    takeLatest("payment/requestThisWeek", thisweekWorker),
    takeLatest("payment/requestNextWeek", nextweekWorker),
    takeLatest("payment/requestNext30", next30Worker),
    takeLatest("payment/requestPayed", payedWorker),
    takeLatest("payment/requestLast", lastWorker),
    takeLatest("payment/requestDelete", paymentDeleteWorker),
    formSaga(),
    paySaga(),
    viewSaga(),
  ]);
}
