import React from "react";
import { Card } from "react-bootstrap";
import moment from "moment";
import LoaderButton from "../../components/LoaderButton";
import { Task } from "./actions";

type TaskComponentProps = {
  onFinish: () => void;
  onUnfinish: () => void;
  isLoading: boolean;
} & Task;

const TaskComponent: React.FC<TaskComponentProps> = (props) => (
  <Card
    className={`mt-2 ${
      props.finshedDate
        ? "task-finshed"
        : moment(props.dateDue).isBefore(new Date(), "day")
        ? "task-alert"
        : "task-normal"
    }`}
  >
    <Card.Body>
      <Card.Title>
        {props.finshedDate ? <del>{props.summary}</del> : props.summary}
      </Card.Title>
      <Card.Subtitle>
        {props.dest &&
          (props.finshedDate ? <del>{props.dest.name}</del> : props.dest.name)}
      </Card.Subtitle>
      {!props.finshedDate && (
        <Card.Text>
          Vencimento: {moment(props.dateDue).format("DD/MM/YYYY")}
        </Card.Text>
      )}
      {props.finshedDate && (
        <Card.Text>
          Concluído em: {moment(props.finshedDate).format("DD/MM/YYYY")}
        </Card.Text>
      )}
      {!props.finshedDate && (
        <LoaderButton
          isLoading={props.isLoading}
          variant="primary"
          onClick={props.onFinish}
        >
          Concluir
        </LoaderButton>
      )}
      {!!props.finshedDate && (
        <LoaderButton
          isLoading={props.isLoading}
          variant="danger"
          onClick={props.onUnfinish}
        >
          Desfazer Concluir
        </LoaderButton>
      )}
    </Card.Body>
  </Card>
);

export default TaskComponent;
