import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  PROD_FORM_INIT,
  PROD_SAVE_REQUESTING,
  formInitError,
  formInitSuccess,
  formSuccess,
  formError,
} from "./actions";
import { CAD_TYPE, Prod } from "../actions";
import executeApiRequest from "../../../misc/executeApiRequest";

const newProd = (): Prod => {
  return {
    cadType: CAD_TYPE,
    cod: "",
    name: "",
    ean: 0,
    ncm: "0",
    cest: "",
    unitType: "",
    preco: 0,
    orig: 0,
    quantidadeAtual: 0,
    custoMedio: 0,
  };
};

function* prodInitWorker({ payload }: any) {
  try {
    if (payload === "new") {
      yield put(formInitSuccess(newProd()));
    } else {
      const res = yield call(
        executeApiRequest,
        `/prod/${payload}`,
        "GET",
        {},
        {}
      );
      yield put(formInitSuccess(res));
    }
  } catch (error) {
    yield put(formInitError(error));
  }
}

function* prodSaveWorker({ payload }: any) {
  try {
    if (payload.cadId) {
      // Update
      const res = yield call(
        executeApiRequest,
        `/prod/${payload.cadId}`,
        "PUT",
        payload
      );
      yield put(formSuccess(res));
    } else {
      const res = yield call(executeApiRequest, "/prod", "POST", payload);
      yield put(formSuccess(res));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(formError(error.response.data));
  }
}

export default function* prodFormSaga() {
  yield all([
    takeLatest(PROD_FORM_INIT, prodInitWorker),
    takeLatest(PROD_SAVE_REQUESTING, prodSaveWorker),
  ]);
}
