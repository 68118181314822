import React, { Component } from "react";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "underscore";
import EmissorSelector from "../../emissor/selector";
import TipoopSelector from "../../tipoop/selector";
import SerieSelector from "../../serie/selector";
import DestSelector from "../../dest/selector";
import ProdSelector from "../../prod/selector";
import InputNumber from "../../../components/InputNumber";
import { formatNumber } from "../../../misc/formatters";
import {
  FontAwesomeIcon,
  FontAwesomeIcon as Icon,
} from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import TableOrCard from "../../../components/TableOrCard";
import LoaderButton from "../../../components/LoaderButton";
import ShowSuccess from "../../../components/ShowSuccess";
import ShowError from "../../../components/ShowError";
import CfopSelector from "../../../components/CfopSelector";
import QsCard from "./QsCard";
import { Dest } from "../../dest/actions";
import { Emissor } from "../../emissor/reducer";
import { quicksalesActions } from "./reducer";
import { Sale, SaleItem } from "../types";
import { Serie } from "../../serie/actions";
import { Tipoop } from "../../tipoop/actions";
import ImpostoForm from "../salesForm/ImpostoForm";
import { saleItemFill } from "../salesForm/saleItemFill";

type QuickSaleProps = {
  defaultEmit: Emissor;
  items: SaleItem[];
  emissor: Emissor;
  serie: Serie;
  tipoop: Tipoop;
  dest: Dest;
  geraEstoque: boolean;
  geraCobranca: boolean;
  isSaveRequesting: boolean;
  saveError: any;
  saveSuccess: any;
  quickSalesDone: any;
  updateError: any;
  isListRequesting: boolean;
  listError: any;
  nfeCancelingError: any;
  enviarnfeError: string;
  quicksaleInit: (payload: Emissor) => void;
  quicksaleSet: (payload: Partial<Sale>) => void;
  quicksaleSaveRequesting: (payload: Sale) => void;
  quicksaleListRequest: () => void;
};

type QuickSaleState = {
  emissor: Emissor | null;
  tipoop: any;
  serie: any;
  dest: Dest | null;
  itemAdd: SaleItem;
  items: SaleItem[];
  showImposto: boolean;
};

class QuickSale extends Component<QuickSaleProps, QuickSaleState> {
  state = {
    emissor: null,
    tipoop: null,
    serie: null,
    dest: null,
    itemAdd: {
      prod: null,
      qtd: 0,
      valor: 0,
      cfop: "",
      csosn: "102",
      cstipi: "53",
      total: 0,
    },
    showImposto: false,
    items: [],
  };

  prodSelector = React.createRef<HTMLInputElement>();

  componentDidMount() {
    this.props.quicksaleInit(this.props.defaultEmit);
  }

  handleItemChange = (item: Partial<SaleItem>) => {
    const itemToSave = saleItemFill(
      { ...this.state.itemAdd, ...item },
      {
        emissor: this.props.emissor,
        modelo: "65",
        tipoop: this.props.tipoop,
        serie: this.props.serie,
        dest: this.props.dest,
        createdAt: Date.now(),
        dtExp: new Date(),
        emission: new Date(),
        geraCobranca: false,
        invoiceId: "",
        items: [],
        lsi1: "",
        lsi2: "",
        lsi3: "",
        lsi4: "",
        orgId: "",
        tag: "",
        version: 1,
      }
    );

    itemToSave.total = itemToSave.qtd * itemToSave.valor;
    itemToSave.bcIcms =
      (itemToSave.total * (100 - itemToSave.pRedBCICMS)) / 100;
    itemToSave.vIcms = (itemToSave.bcIcms * itemToSave.pIcms) / 100;
    itemToSave.vCredSN = (itemToSave.total * itemToSave.pCredSN) / 100;
    itemToSave.vFCP = (itemToSave.bcFCP * itemToSave.pFCP) / 100;
    itemToSave.vICMSST = (itemToSave.pICMSST * itemToSave.bcICMSST) / 100;
    itemToSave.vICMSSTFCP =
      (itemToSave.pICMSSTFCP * itemToSave.bcICMSSTFCP) / 100;
    itemToSave.bcIPI = itemToSave.total;
    itemToSave.IPI = (itemToSave.total * itemToSave.pIPI) / 100;
    itemToSave.bcPIS = itemToSave.total;
    itemToSave.PIS = (itemToSave.bcPIS * itemToSave.pPIS) / 100;
    itemToSave.bcCOFINS = itemToSave.total;
    itemToSave.COFINS = (itemToSave.bcCOFINS * itemToSave.pCOFINS) / 100;

    this.setState({
      itemAdd: itemToSave,
    });
  };

  handleItemAdd = () => {
    if (
      !this.state.itemAdd.prod ||
      this.state.itemAdd.qtd < 1 ||
      this.state.itemAdd.valor <= 0
    ) {
      return;
    }
    this.props.quicksaleSet({
      items: [...this.props.items, this.state.itemAdd],
    });
    this.setState(
      {
        itemAdd: {
          prod: null,
          qtd: 0,
          valor: 0,
          cfop: "",
          csosn: "102",
          cstipi: "53",
          total: 0,
        },
      },
      () => {
        if (this.prodSelector && this.prodSelector.current)
          this.prodSelector.current.focus();
      }
    );
  };

  handleRemoveItem = (itemIndex: number) => {
    const items = this.props.items;
    items.splice(itemIndex, 1);
    this.props.quicksaleSet({
      items,
    });
  };

  handleSaveRequest = (e) => {
    e.preventDefault();
    const quickSale: Sale = {
      emissor: this.props.emissor,
      tipoop: this.props.tipoop,
      serie: this.props.serie,
      dest: this.props.dest,
      items: this.props.items,
      modelo: "65",
      emission: new Date(),
      dtExp: new Date(),
      tpag: "01",
      nparcelas: 0,
      orgId: "",
      invoiceId: "",
      lsi1: "",
      lsi2: "",
      lsi3: "",
      lsi4: "",
      createdAt: Date.now(),
      geraCobranca: this.props.geraCobranca,
      geraEstoque: this.props.geraEstoque,
      tag: "",
      version: 1,
      serialCreate: {
        num: `QUICK${this.props.serie ? this.props.serie.cod : "SALE"}`,
      },
    };
    this.props.quicksaleSaveRequesting(quickSale);
  };

  render() {
    const headers = [
      {
        label: "Produto",
        render: (item) => `${item.prod.cod} - ${item.prod.name}`,
      },
      {
        label: "Quantidade",
        render: (item) => formatNumber(item.qtd, 0),
      },
      {
        label: "Valor Un.",
        render: (item) => formatNumber(item.valor, 2),
      },
      {
        label: "Total",
        render: (item) => formatNumber(item.qtd * item.valor, 2),
      },
      {
        label: "Ações",
        render: (_, i) => (
          <Button variant="danger" onClick={() => this.handleRemoveItem(i)}>
            <Icon icon={faTrash} />
          </Button>
        ),
      },
    ];

    const totalGeral =
      Array.isArray(this.props.items) &&
      this.props.items.reduce((p, c) => p + c.qtd * c.valor, 0);

    const validForm = [
      !!this.props.emissor,
      !!this.props.tipoop,
      !!this.props.serie,
      !!this.props.items && this.props.items.length > 0,
    ].reduce((p, c) => p && c);

    const qsCards = _.sortBy(this.props.quickSalesDone, "dtExp")
      .reverse()
      .slice(0, 10)
      .map((sale, i) => <QsCard sale={sale} key={`${i}`} />);

    const sale: Sale = {
      emissor: this.props.emissor,
      modelo: "65",
      tipoop: this.props.tipoop,
      serie: this.props.serie,
      dest: this.props.dest,
      createdAt: Date.now(),
      dtExp: new Date(),
      emission: new Date(),
      geraCobranca: false,
      invoiceId: "",
      items: [],
      lsi1: "",
      lsi2: "",
      lsi3: "",
      lsi4: "",
      orgId: "",
      tag: "",
      version: 1,
    };

    return (
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={9} lg={10} xl={10}>
            <Row>
              <Col>
                <h2>Venda rápida</h2>
              </Col>
            </Row>
            <Row>
              <Form.Group as={Col} xs={12} controlId="emissor">
                <Form.Label>Emissor</Form.Label>
                <EmissorSelector
                  onChange={(emissor) => this.props.quicksaleSet({ emissor })}
                  value={this.props.emissor}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs={12} md={6} controlId="tipoop">
                <Form.Label>Tipo de Operação</Form.Label>
                <TipoopSelector
                  onChange={(tipoop) => this.props.quicksaleSet({ tipoop })}
                  value={this.props.tipoop}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} controlId="serie">
                <Form.Label>Série</Form.Label>
                <SerieSelector
                  onChange={(serie) => this.props.quicksaleSet({ serie })}
                  value={this.props.serie}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs={12} controlId="cliente">
                <Form.Label>Cliente (opcional)</Form.Label>
                <DestSelector
                  onChange={(dest) => this.props.quicksaleSet({ dest })}
                  value={this.props.dest}
                />
              </Form.Group>
            </Row>
            <Row className="mt-2 mb-2">
              <Form.Group as={Col} xs={12} md={6} controlId="geraEstoque">
                <Form.Check
                  type="checkbox"
                  label="Gerar Estoque"
                  onChange={() =>
                    this.props.quicksaleSet({
                      geraEstoque: !this.props.geraEstoque,
                    })
                  }
                  checked={this.props.geraEstoque}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} controlId="geraCobranca">
                <Form.Check
                  type="checkbox"
                  label="Gerar Cobranças"
                  onChange={() =>
                    this.props.quicksaleSet({
                      geraCobranca: !this.props.geraCobranca,
                    })
                  }
                  checked={this.props.geraCobranca}
                />
              </Form.Group>
            </Row>
            <div className="p-5 mb-4 bg-light rounded-3">
              <div className="container-found py-5">
                <h1 className="display-5 fw-bold">Incluir item</h1>
                <Row>
                  <Form.Group as={Col} xs={12} controlId="cfop">
                    <Form.Label>CFOP</Form.Label>
                    <CfopSelector
                      onChange={(cfop) => this.handleItemChange({ cfop })}
                      tipo="S"
                      value={this.state.itemAdd.cfop}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} xs={12} md={8} controlId="prod">
                    <Form.Label>Produto</Form.Label>
                    <ProdSelector
                      forwardRef={this.prodSelector}
                      onChange={(prod) =>
                        this.handleItemChange({
                          prod,
                          valor: prod ? prod.preco : 0,
                        })
                      }
                      value={this.state.itemAdd.prod}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="qtd" xs={12} md={2}>
                    <Form.Label>Quantidade</Form.Label>
                    <InputNumber
                      value={this.state.itemAdd.qtd}
                      onChange={(qtd) => this.handleItemChange({ qtd })}
                      decimalPlaces={0}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="valor" xs={12} md={2}>
                    <Form.Label>Valor</Form.Label>
                    <InputNumber
                      value={this.state.itemAdd.valor}
                      onChange={(valor) => this.handleItemChange({ valor })}
                      decimalPlaces={2}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="mt-2 mb-2"
                      onClick={() =>
                        this.setState({ showImposto: !this.state.showImposto })
                      }
                    >
                      <FontAwesomeIcon
                        icon={this.state.showImposto ? faEye : faEyeSlash}
                      />{" "}
                      Impostos
                    </Button>
                  </Col>
                </Row>
                {this.state.showImposto && (
                  <Row className="mt-2 mb-2">
                    <ImpostoForm
                      item={this.state.itemAdd}
                      onChange={this.handleItemChange}
                      sale={sale}
                    />
                  </Row>
                )}
                <Row>
                  <Col xs={12} md={8}>
                    <h3>
                      Total:{" "}
                      {formatNumber(
                        this.state.itemAdd.qtd * this.state.itemAdd.valor,
                        2
                      )}
                    </h3>
                  </Col>
                  <Col xs={12} md={4}>
                    <Button
                      onClick={this.handleItemAdd}
                      variant="primary"
                      className="float-right"
                    >
                      Incluir Item
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col>
                <TableOrCard items={this.props.items} headers={headers} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="float-right">
                  Total Geral: {formatNumber(totalGeral, 2)}
                </h3>
              </Col>
            </Row>
            <Row className="mb-2 mt-2">
              <Col>
                <ShowSuccess message={this.props.saveSuccess} />
                <ShowError error={this.props.saveError} />
              </Col>
            </Row>
            <Row className="mb-2 mt-2">
              <Col>
                <ShowError error={this.props.updateError} />
                <ShowError error={this.props.listError} />
                <ShowError error={this.props.nfeCancelingError} />
                <ShowError error={this.props.enviarnfeError} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <LoaderButton
                  isLoading={this.props.isSaveRequesting}
                  disabled={!validForm}
                  variant="primary"
                  onClick={this.handleSaveRequest}
                >
                  Emitir nota de consumidor
                </LoaderButton>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={3} lg={2} xl={2}>
            <h2>Ultimas emissões de venda rápida</h2>
            <LoaderButton
              isLoading={this.props.isListRequesting}
              variant="primary"
              onClick={() => this.props.quicksaleListRequest()}
            >
              Buscar
            </LoaderButton>
            {qsCards}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultEmit: state.login.defaultEmit,
    items: state.sales.quicksale.items,
    emissor: state.sales.quicksale.emissor,
    serie: state.sales.quicksale.serie,
    tipoop: state.sales.quicksale.tipoop,
    dest: state.sales.quicksale.dest,
    geraEstoque: state.sales.quicksale.geraEstoque,
    geraCobranca: state.sales.quicksale.geraCobranca,
    isSaveRequesting: state.sales.quicksale.isSaveRequesting,
    saveError: state.sales.quicksale.saveError,
    saveSuccess: state.sales.quicksale.saveSuccess,
    quickSalesDone: state.sales.quicksale.quickSalesDone,
    updateError: state.sales.quicksale.updateError,
    isListRequesting: state.sales.quicksale.isListRequesting,
    listError: state.sales.quicksale.listError,
    nfeCancelingError: state.sales.list.nfeCancelingError,
    enviarnfeError: state.sales.list.enviarnfeError,
  };
};
const mapDispatchToProps = {
  quicksaleInit: quicksalesActions.init,
  quicksaleSet: quicksalesActions.set,
  quicksaleSaveRequesting: quicksalesActions.saveRequest,
  quicksaleListRequest: quicksalesActions.listRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickSale);
