import { useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";
import { plans } from "../plans";

export const useSubscriptionRoles = () => {
  const subscription = useSelector(
    (state: RootState) => state.subscription.activeSubscription
  );
  const isOrgRequesting = useSelector(
    (state: RootState) => state.login.isOrgRequesting
  );
  if (!subscription) {
    return plans.trial;
  }

  if (isOrgRequesting) {
    return [];
  }

  return plans[subscription.plan?.id] || [];
};
