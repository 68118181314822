import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../components/LoadingAnimation";
import ShowError from "../../components/ShowError";
import { RootState } from "../app/mainReducer";
import { subcsriptionActions } from "./reducer";

export interface CancelProps extends RouteComponentProps {
  subscriptionId: string;
}

const Cancel: React.FC<CancelProps> = (props) => {
  const dispatch = useDispatch();

  const isSubscriptionCanceling = useSelector(
    (state: RootState) => state.subscription.isSubscriptionCanceling
  );
  const subscriptionCanceled = useSelector(
    (state: RootState) => state.subscription.subscriptionCanceled
  );
  const subscriptionCancelError = useSelector(
    (state: RootState) => state.subscription.subscriptionCancelError
  );

  const confirmCancel = () => {
    dispatch(
      subcsriptionActions.subscriptionCancelRequest(props.subscriptionId)
    );
  };

  useEffect(() => {
    dispatch(subcsriptionActions.subscriptionCancelInit());
  }, [dispatch]);

  if (isSubscriptionCanceling) {
    return <LoadingAnimation />;
  }

  if (subscriptionCanceled) {
    return <h1>Assinatura cancelada com sucesso!</h1>;
  }

  return (
    <>
      <ShowError error={subscriptionCancelError} />
      <h1>Confirme que voce realmente quer cancelar</h1>
      <Button onClick={confirmCancel} variant="danger">
        Confirmar cancelamento!
      </Button>
    </>
  );
};

export default Cancel;
