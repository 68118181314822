import { Formik } from "formik";
import React, { Component } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as Yup from "yup";
import RadioInput from "../../../components/FormikComponents/RadioInput";
import SelectInput from "../../../components/FormikComponents/SelectInput";
import TextInput from "../../../components/FormikComponents/TextInput";
import { LinkButton } from "../../../components/LinkButton";
import LoaderButton from "../../../components/LoaderButton";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import { Tipoop } from "../actions";
import { formInit, formSave } from "./actions";
import { RegraShow } from "./RegraShow";
import { RegraForm } from "./RegraForm";

type TipoopFormProps = {
  item: Tipoop;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  formInit: (cadId: string) => void;
  formSave: (cad: Tipoop) => void;
  id: string;
};

class TipoopForm extends Component<TipoopFormProps, {}> {
  state = {
    regraEditOpen: false,
    regraEditIndex: -1,
  };

  componentDidMount() {
    this.props.formInit(this.props.id);
  }

  handleSubmit = (item: Tipoop) => {
    this.props.formSave(item);
  };

  render() {
    const isSaving = this.props.isSaving;

    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>Tipo de operação</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <LinkButton variant="primary" to="/tipoop">
              Todos os tipos de operação
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              cod: Yup.string().required(
                "Preenchimento do código é obrigatório"
              ),
              name: Yup.string().required(
                "Preenchimento da descrição é obrigatório"
              ),
              type: Yup.number(),
              invoiceAuto: Yup.number(),
              modoNFe: Yup.number(),
            })}
          >
            {(props) => {
              const { isValid, handleSubmit } = props;
              const handleRegraAdd = () => {
                const regras = [...(props.values.regras || [])];
                regras.push({
                  quando: [
                    {
                      operador: "eq",
                      tipoObj: "TipoNF",
                      modelo: "55",
                    },
                  ],
                  seq: regras.length,
                });
                props.setFieldValue("regras", regras);
                this.setState({
                  regraEditIndex: regras.length - 1,
                  regraEditOpen: true,
                });
              };
              const handleRegraDelete = (index) => {
                const regras = [...props.values.regras];
                regras.splice(index, 1);
                props.setFieldValue("regras", regras);
              };
              return (
                <Form onSubmit={handleSubmit}>
                  <Modal
                    show={this.state.regraEditOpen}
                    size="xl"
                    onHide={() => this.setState({ regraEditOpen: false })}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Regra</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {props.values.regras &&
                        props.values.regras[this.state.regraEditIndex] && (
                          <RegraForm
                            onChange={(regra) => {
                              const regras = [...props.values.regras];
                              regras[this.state.regraEditIndex] = regra;
                              props.setFieldValue("regras", regras);
                            }}
                            regra={
                              props.values.regras[this.state.regraEditIndex]
                            }
                          />
                        )}
                      <Row>
                        <Col className="d-flex align-items-end justify-content-end mt-2 pt-1 border-top">
                          <Button
                            onClick={() =>
                              this.setState({ regraEditOpen: false })
                            }
                          >
                            Concluir
                          </Button>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>

                  <Row>
                    <TextInput
                      name="cod"
                      label="Código"
                      formik={props}
                      size={2}
                    />
                    <TextInput
                      name="name"
                      label="Descrição"
                      formik={props}
                      size={10}
                    />
                  </Row>
                  <Row>
                    <RadioInput
                      name="type"
                      label="Tipo"
                      formik={props}
                      size={12}
                      options={{
                        Entrada: 1,
                        Saída: 2,
                      }}
                    />
                  </Row>
                  <Row>
                    <SelectInput
                      name="modoNfe"
                      label="Modo NFe:"
                      formik={props}
                      size={6}
                    >
                      <option value={1}>NF-e Normal</option>
                      <option value={2}>NF-e Complementar</option>
                      <option value={3}>NF-e de Ajuste</option>
                      <option value={4}>NF-e Devolução</option>
                    </SelectInput>
                  </Row>
                  <Row>
                    <Col>
                      <h3>Regras fiscais</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button variant="primary" onClick={handleRegraAdd}>
                        Incluir Regra
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    {props.values.regras &&
                      props.values.regras.map((regra, i) => (
                        <RegraShow
                          regra={regra}
                          key={`${i}`}
                          onDelete={() => handleRegraDelete(i)}
                          onEdit={() =>
                            this.setState({
                              regraEditIndex: i,
                              regraEditOpen: true,
                            })
                          }
                        />
                      ))}
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                      >
                        Salvar
                      </LoaderButton>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </LoadingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    item: state.tipoop.form.item,
    successMsg: state.tipoop.form.successMsg,
    error: state.tipoop.form.error,
    isRequesting: state.tipoop.form.isRequesting,
    isSaving: state.tipoop.form.isSaving,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    formInit: (cadId) => dispatch(formInit(cadId)),
    formSave: (cad) => dispatch(formSave(cad)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TipoopForm);
