import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "underscore";
import InputNumber from "../../../components/InputNumber";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import { InvoiceParcela } from "../actions";
import { pageInit, payedRequesting, setInvoicesToClose } from "./actions";

type InvoiceParcelaToClose = InvoiceParcela & {
  valorPago: number;
  checked: boolean;
};

type InvoicePayProps = {
  items: InvoiceParcela[];
  invoicesItems: InvoiceParcelaToClose[];
  invoices: string;
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isSaving: boolean;
  error: string;
  pageInit: (payload: any) => void;
  payedRequesting: (payload) => void;
  setInvoicesToClose: (invoices: InvoiceParcelaToClose[]) => void;
};

class InvoicePay extends Component<InvoicePayProps, {}> {
  state = {
    invoicesFilter: [],
    checkedMovs: [],
    valorPago: {},
    valorCaixa: 0,
    valorCartaoCredito: 0,
    valorCartaoDebito: 0,
  };

  componentDidMount() {
    this.fetchInvoices(this.props.invoices);
  }

  componentDidUpdate(prevProps: InvoicePayProps) {
    if (prevProps.invoices !== this.props.invoices) {
      this.fetchInvoices(this.props.invoices);
    }
  }

  fetchInvoices(invoicesString: string) {
    const invoices = invoicesString.split(",");
    // const invoicesMap = invoices.map((invoice) => invoice.split(":")[0]);
    // const invoiceMin = _.min(invoicesMap); //, (p) =>
    //parseInt(p.replace(/[^0-9]/g, ""))
    //);
    // const invoiceMax = _.max(invoicesMap); //, (p) =>
    //parseInt(p.replace(/[^0-9]/g, ""))
    //);
    // const prefixChars = `${invoiceMax}`.replace(/[0-9]/g, "");
    // const maxToRequest = `${prefixChars}${
    //   parseInt(`${invoiceMax}`.replace(/[^0-9]/g, "")) + 1
    // }`;
    this.setState({
      invoicesFilter: invoices,
      valorCaixa: 0,
    });
    this.props.pageInit(invoices);
  }

  filterCobs = () => {
    return _.filter(
      this.props.items,
      (cob) =>
        _.findIndex(
          this.state.invoicesFilter,
          (fil) =>
            fil.split(":")[0] * 1 === parseInt(cob.num) * 1 ||
            fil.split(":")[0] === `${cob.num}`.trim()
        ) !== -1
    );
  };

  baixarCobrancas = () => {
    // Executar a parte da baixa da cobrança
    this.props.payedRequesting({
      invoicesItems: this.props.invoicesItems.filter((item) => item.checked),
      valorCaixa: this.state.valorCaixa,
      valorCartaoCredito: this.state.valorCartaoCredito,
      valorCartaoDebito: this.state.valorCartaoDebito,
    });
  };

  toggleCob = (parcel: InvoiceParcelaToClose) => {
    const invoices = [...this.props.invoicesItems];
    const iparcel = invoices.findIndex(
      (invoice) => invoice.invoiceId === parcel.invoiceId
    );
    if (iparcel !== -1) {
      invoices[iparcel].checked = !invoices[iparcel].checked;
    }
    this.props.setInvoicesToClose(invoices);
  };

  setValor = (parcel: InvoiceParcelaToClose, valor: number) => {
    const invoices = [...this.props.invoicesItems];
    const iparcel = invoices.findIndex(
      (invoice) => invoice.invoiceId === parcel.invoiceId
    );
    if (iparcel !== -1) {
      invoices[iparcel].valorPago = valor;
    }
    this.props.setInvoicesToClose(invoices);
  };

  render() {
    const headers = [
      {
        label: " ",
        render: (item: InvoiceParcelaToClose) => (
          <Form.Group>
            <Form.Check
              type="checkbox"
              checked={item.checked}
              onClick={() => this.toggleCob(item)}
              label=""
            />
          </Form.Group>
        ),
      },
      {
        label: "#",
        render: (item: InvoiceParcelaToClose) =>
          `${item.num}/${item.numparcela}`,
      },
      {
        label: "Emissão",
        render: (item: InvoiceParcelaToClose) =>
          moment(item.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Cliente",
        render: (item: InvoiceParcelaToClose) =>
          `${item.dest && item.dest.name}`,
      },
      {
        label: "Valor",
        render: (item: InvoiceParcelaToClose) => formatNumber(item.valor, 2),
      },
      {
        label: "Vencimento",
        render: (item: InvoiceParcelaToClose) =>
          moment(item.vencimento).format("DD/MM/YYYY"),
      },
      {
        label: "Valor Pago",
        render: (item: InvoiceParcelaToClose) => (
          <InputNumber
            value={item.valorPago}
            onChange={(valor) => this.setValor(item, valor)}
          />
        ),
      },
    ];

    const valorTotalPago = this.props.invoicesItems.reduce(
      (p, item) => p + (item.checked && (item.valorPago || 0)),
      0
    );

    const restante =
      this.state.valorCaixa +
      this.state.valorCartaoCredito +
      this.state.valorCartaoDebito -
      valorTotalPago;

    return (
      <>
        <Row>
          <Col>
            <h2>Baixa de Cobranças</h2>
          </Col>
        </Row>
        <Row>
          <LoadingContainer isLoading={this.props.isRequesting}>
            {this.props.error && (
              <Row>
                <Col>
                  <ShowError error={this.props.error} />
                </Col>
              </Row>
            )}
            <TableOrCard headers={headers} items={this.props.invoicesItems} />
            <Row>
              <Col className="d-flex flex-row-reverse">
                <Col
                  className="d-flex flex-column"
                  style={{ maxWidth: "35ch" }}
                >
                  <h4>
                    Valor Total a pagar {formatNumber(valorTotalPago, 2, true)}
                  </h4>
                  <Form.Group>
                    <Form.Label>Valor pago em caixa</Form.Label>
                    <InputNumber
                      value={this.state.valorCaixa}
                      onChange={(valorCaixa) => this.setState({ valorCaixa })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Valor pago em cartão de crédito</Form.Label>
                    <InputNumber
                      value={this.state.valorCartaoCredito}
                      onChange={(valorCartaoCredito) =>
                        this.setState({ valorCartaoCredito })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Valor pago em cartão de débito</Form.Label>
                    <InputNumber
                      value={this.state.valorCartaoDebito}
                      onChange={(valorCartaoDebito) =>
                        this.setState({ valorCartaoDebito })
                      }
                    />
                  </Form.Group>
                  {restante > 0 && (
                    <h4>Troco {formatNumber(restante, 2, true)}</h4>
                  )}
                  {restante < 0 && (
                    <h4>
                      Restante a pagar{" "}
                      {formatNumber(Math.abs(restante), 2, true)}
                    </h4>
                  )}
                  <Button
                    disabled={restante < 0}
                    variant="primary"
                    onClick={this.baixarCobrancas}
                  >
                    Baixas as cobranças selecionadas
                  </Button>
                </Col>
              </Col>
            </Row>
          </LoadingContainer>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.invoice.pay.items,
    invoicesItems: state.invoice.pay.invoicesItems,
    Count: state.invoice.pay.Count,
    lastKey: state.invoice.pay.lastKey,
    isRequesting: state.invoice.pay.isRequesting,
    isSaving: state.invoice.pay.isSaving,
    error: state.invoice.pay.error,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    pageInit: (payload: any) => dispatch(pageInit(payload)),
    payedRequesting: (payload: any) => dispatch(payedRequesting(payload)),
    setInvoicesToClose: (invoicesToClose: InvoiceParcelaToClose[]) =>
      dispatch(setInvoicesToClose(invoicesToClose)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePay);
