import { Formik } from "formik";
import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as Yup from "yup";
import CestSelector from "../../../components/CestSelector";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import { NumberField } from "../../../components/FormikComponents/NumberField";
import NumberInput from "../../../components/FormikComponents/NumberInput";
import SelectInput from "../../../components/FormikComponents/SelectInput";
import TextInput from "../../../components/FormikComponents/TextInput";
import { LinkButton } from "../../../components/LinkButton";
import LoaderButton from "../../../components/LoaderButton";
import LoadingContainer from "../../../components/LoadingContainer";
import NcmSelector from "../../../components/NcmSelector";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import { Prod } from "../actions";
import { CreateEstoque } from "../CreateEstoque";
import ListaEstoque from "../ListaEstoque";
import { formInit, formSave } from "./actions";

type ProdFormProps = {
  id: string;
  item: Prod;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  formInit: (cadId: string) => void;
  formSave: (cad: Prod) => void;
};

class ProdForm extends Component<ProdFormProps, {}> {
  state = {};

  componentDidMount() {
    this.props.formInit(this.props.id);
  }

  handleSubmit = (item) => {
    this.props.formSave(item);
  };

  render() {
    const isSaving = this.props.isSaving;

    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>Produtos</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <LinkButton variant="primary" to="/prod">
              Todos os produtos
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required(
                "Preenchimento da descrição é obrigatório"
              ),
              ean: Yup.string(),
              ncm: Yup.string().required("Preenchimento do NCM é obrigatório"),
              cest: Yup.string(),
              unitType: Yup.string().required(
                "Preenchimento da unidade é obrigatório"
              ),
              preco: Yup.number(),
              orig: Yup.number(),
            })}
          >
            {(props) => {
              const { isValid, handleSubmit } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <TextInput
                      name="cod"
                      label="Código"
                      formik={props}
                      size={2}
                    />
                    <TextInput
                      name="name"
                      label="Descrição"
                      formik={props}
                      size={10}
                    />
                  </Row>
                  <Row>
                    <MaskInput
                      name="ean"
                      label="EAN"
                      mask="9999999999999"
                      formik={props}
                      size={3}
                    />
                    <Form.Group as={Col} xs={12} md={3} controlId="ncm">
                      <Form.Label>NCM:</Form.Label>
                      <NcmSelector
                        value={props.values.ncm}
                        onChange={(ncm) => props.setFieldValue("ncm", ncm)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={3} controlId="cest">
                      <Form.Label>CEST:</Form.Label>
                      <CestSelector
                        value={props.values.cest}
                        onChange={(cest) => props.setFieldValue("cest", cest)}
                      />
                    </Form.Group>
                    <TextInput
                      name="unitType"
                      label="Unidade"
                      formik={props}
                      size={3}
                    />
                  </Row>
                  <Row>
                    <NumberInput
                      decimalPlaces={2}
                      formik={props}
                      name="preco"
                      label="Preço"
                      size={6}
                    />
                    <SelectInput
                      name="orig"
                      label="Origem da mercadoria"
                      formik={props}
                      size={6}
                    >
                      <option value={0}>Nacional</option>
                      <option value={1}>Estrangeira Importação Direta</option>
                      <option value={2}>
                        Estrangeira Adquirida no mercado interna
                      </option>
                    </SelectInput>
                  </Row>
                  <Row>
                    <Col>
                      <NumberField
                        disabled
                        name="custoMedio"
                        label="Custo Médio ($)"
                      />
                    </Col>
                    <Col>
                      <NumberField
                        disabled
                        name="quantidadeAtual"
                        label="Estoque atual"
                        decimalPlaces={0}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col>
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                      >
                        Salvar
                      </LoaderButton>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </LoadingContainer>
        {this.props.item && this.props.item.cadId && (
          <>
            <Row>
              <Col>
                <h3>Movimentar estoque</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <CreateEstoque prod={this.props.item} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Movimentos do estoque</h3>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
                <ListaEstoque prod={this.props.item} />
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    item: state.prod.form.item,
    successMsg: state.prod.form.successMsg,
    error: state.prod.form.error,
    isRequesting: state.prod.form.isRequesting,
    isSaving: state.prod.form.isSaving,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    formInit: (cadId) => dispatch(formInit(cadId)),
    formSave: (cad) => dispatch(formSave(cad)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProdForm);
