import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModSelectorAsync from "../../../components/ModSelectorAsync";
import { RootState } from "../../app/mainReducer";
import { Dest } from "../actions";
import { destSelectorRequest } from "./actions";
import { Form } from "react-bootstrap";

export const DestField: React.FC<
  InputHTMLAttributes<HTMLInputElement> & { label: string; name: string }
> = ({ label, ...props }) => {
  const [field, { error, touched }, { setValue }] = useField<Dest>(props);

  return (
    <>
      <label>{label}</label>
      <DestSelector value={field.value} onChange={(dest) => setValue(dest)} />
      {error && touched && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};

type DestSelectorProps = {
  forwardRef?: any;
  className?: string;
  onBlur?: (e: Event) => void;
  value: Dest;
  onChange?: (value: any) => void;
};

const DestSelector: React.FC<DestSelectorProps> = (props) => {
  const items = useSelector((state: RootState) => state.dest.selector.items);
  const isRequesting = useSelector(
    (state: RootState) => state.dest.selector.isRequesting
  );

  const dispatch = useDispatch();

  const onSearch = (term) => {
    console.log("searching", term);
    dispatch(destSelectorRequest(term));
  };

  return (
    <ModSelectorAsync
      options={items}
      isLoading={isRequesting}
      renderOption={(dest) =>
        dest &&
        `${dest.name} - 
      ${dest.type === 1 ? `CPF: ${dest.cpf}` : `CNPJ: ${dest.cnpj}`} - 
      Endereço: ${dest.address}, ${dest.addressNumber} - ${dest.city} - ${
          dest.estate
        }`
      }
      forwardRef={props.forwardRef}
      className={props.className}
      onBlur={props.onBlur}
      path="/dest"
      value={props.value}
      onChange={props.onChange}
      onSearch={onSearch}
      minLength={3}
    />
  );
};

export default DestSelector;

// class DestSelector extends Component<DestSelectorProps, {}> {
//   state = {};

//   onSearch = (term) => {
//     console.log("searching", term);
//     this.props.destSelectorRequest(term);
//   };

//   render() {
//     return (
//       <ModSelectorAsync
//         options={this.props.items}
//         isLoading={this.props.isRequesting}
//         renderOption={(dest) =>
//           dest &&
//           `${dest.name} -
//       ${dest.type === 1 ? `CPF: ${dest.cpf}` : `CNPJ: ${dest.cnpj}`} -
//       Endereço: ${dest.address}, ${dest.addressNumber} - ${dest.city} - ${
//             dest.estate
//           }`
//         }
//         forwardRef={this.props.forwardRef}
//         className={this.props.className}
//         onBlur={this.props.onBlur}
//         path="/dest"
//         value={this.props.value}
//         onChange={this.props.onChange}
//         onSearch={this.onSearch}
//         minLength={3}
//       />
//     );
//   }
// }

// const mapStateToProps = (state: any) => {
//   return {
//     items: state.dest.selector.items,
//     isRequesting: state.dest.selector.isRequesting,
//     error: state.dest.selector.error,
//   };
// };
// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     destSelectorRequest: (payload: any) =>
//       dispatch(destSelectorRequest(payload)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(DestSelector);
