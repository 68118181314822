import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import cestList from "../misc/cest.json";
import { CestObject } from "../misc/cest";

type CestSelectorProps = {
  value: string;
  onChange: (value: string) => void;
};

/**
 * React component for selecting city with UF, CMun and the City
 */
const CestSelector: React.FC<CestSelectorProps> = (props) => {
  const searchCest = (cest: CestObject, props: any) => {
    const reSearch = new RegExp(`.*${props.text}.*`, "i");
    return (
      reSearch.test(`${cest.CEST}`) || reSearch.test(cest["Descrição CEST"])
    );
  };

  const renderCestOption = (cest: CestObject) =>
    `${cest["CEST"]} - ${cest["Descrição CEST"]}`;

  const onCestSelect = (arrCest: CestObject[]) => {
    if (arrCest.length > 0) {
      const cfop = arrCest[0];
      props.onChange(`${cfop.CEST}`);
    }
  };

  return (
    <Typeahead
      id="cestselector"
      labelKey={renderCestOption}
      options={cestList}
      filterBy={searchCest}
      onChange={onCestSelect}
      defaultInputValue={props.value}
    />
  );
};

export default CestSelector;
