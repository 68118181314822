// component do form do modulo purchases
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import LoadingContainer from "../../../components/LoadingContainer";
import LoaderButton from "../../../components/LoaderButton";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import { EmissorField } from "../../emissor/selector";
import { DestField } from "../../dest/selector";
import { formatNumber } from "../../../misc/formatters";
import TableOrCard from "../../../components/TableOrCard";
import ModalDialog from "../../../components/ModalDialog";
import ItemForm from "./ItemForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { purchaseFormActions } from "./reducer";
import { RootState } from "../../app/mainReducer";
import { Classificacoes } from "../types";
import { NumberField } from "../../../components/FormikComponents/NumberField";
import { RouteComponentProps } from "@reach/router";
import { TextField } from "../../../components/FormikComponents/TextField";
import { DateField } from "../../../components/FormikComponents/DateField";
import { SelectField } from "../../../components/FormikComponents/SelectField";
import { CheckField } from "../../../components/FormikComponents/CheckField";
import { ParcelasPurchase } from "./ParcelasPurchase";
import { NfeField } from "../../../components/FormikComponents/NfeField";

const PurchasesForm: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.purchases.form.isRequesting
  );
  const isSaving = useSelector(
    (state: RootState) => state.purchases.form.isSaving
  );
  const purchase = useSelector((state: RootState) => state.purchases.form.item);
  const successMsg = useSelector(
    (state: RootState) => state.purchases.form.successMsg
  );
  const error = useSelector((state: RootState) => state.purchases.form.error);

  const [isItemOpen, setItemOpen] = useState(false);
  const [itemEditIndex, setItemIndex] = useState(-1);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {};

  useEffect(() => {
    dispatch(purchaseFormActions.requestItem(id));
  }, [id, dispatch]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Compra</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button href="/purchases" variant="primary">
            Todas as compras
          </Button>
        </Col>
      </Row>
      <LoadingContainer isLoading={isLoading}>
        <Formik
          initialValues={purchase}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={Yup.object().shape({
            num: Yup.string().required("Preenchimento do código é obrigatório"),
            dest: Yup.object().required(
              "Preenchimento do fornecedor é obrigatório"
            ),
            entry: Yup.date().required(
              "Preenchimento da data de entrada é obrigatório"
            ),
            emissor: Yup.object().required(
              "Preenchimento da empresa que recebeu é obrigatório"
            ),
            tag: Yup.string().required(
              "Preenchimento do classificação é obrigatório"
            ),
            tpag: Yup.string().required(
              "Preenchimento do tipo de pagamento é obrigatório"
            ),
            obs: Yup.string(),
            items: Yup.array().min(1, "Pelo menos um item deve ser preenchido"),
          })}
        >
          {({ isValid, handleSubmit, values, setFieldValue }) => {
            const headerItems = [
              {
                label: "#",
                dataIndex: "nItem",
              },
              {
                label: "Produto",
                render: (item) => (
                  <>
                    {item.prod ? `${item.prod.cod} - ${item.prod.name}` : ""}
                    {item.prod && !item.prod.cadId && (
                      <Badge bg="success">Novo</Badge>
                    )}
                  </>
                ),
              },
              {
                label: "Quantidade",
                dataIndex: "qtd",
              },
              {
                label: "Valor Un.",
                render: (item) => formatNumber(item.valor, 2),
              },
              {
                label: "Total",
                render: (item) => formatNumber(item.total, 2),
              },
              {
                label: "Ações",
                render: (_, i) => (
                  <ButtonGroup>
                    <Button variant="primary" onClick={() => handleEditItem(i)}>
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteItem(i)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </ButtonGroup>
                ),
              },
            ];

            const vTotal = values.items.reduce((a, p) => a + p.total, 0);

            const handleEditItem = (index: number) => {
              setItemIndex(index);
              setItemOpen(true);
            };

            const handleDeleteItem = (index: number) => {
              const items = [...values.items];
              if (items[index]) {
                items.splice(index, 1);
                setFieldValue("items", items);
              }
            };

            const handleAddItem = () => {
              console.log("adicionando items", values.items);
              const items = [
                ...values.items,
                {
                  nItem: values.items.length + 1,
                  prod: null,
                  qtd: 0,
                  valor: 0,
                  total: 0,
                },
              ];
              setFieldValue("items", items);
              setItemIndex(items.length - 1);
              setItemOpen(true);
            };

            return (
              <Form onSubmit={handleSubmit}>
                <ModalDialog
                  show={isItemOpen}
                  title="Produto da compra"
                  buttons={[
                    <Button
                      key="1"
                      variant="primary"
                      onClick={() => setItemOpen(false)}
                    >
                      Concluir
                    </Button>,
                  ]}
                  onClose={() => setItemOpen(false)}
                >
                  {values.items &&
                    itemEditIndex >= 0 &&
                    values.items[itemEditIndex] && (
                      <ItemForm itemEditIndex={itemEditIndex} />
                    )}
                </ModalDialog>
                <Row>
                  <Col className="my-2">
                    <NfeField label="Importar informações do xml da NFe" />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={2}>
                    <TextField name="num" label="Numero" />
                  </Col>
                  <Col xs={12} md={3}>
                    <DateField label="Data de Entrada" name="entry" />
                  </Col>
                  <Col xs={12} md={7}>
                    <EmissorField label="Empresa que recebeu" name="emissor" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DestField label="Fornecedor" name="dest" />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <SelectField name="tag" label="Classisficação">
                      <option value={Classificacoes.CUSTO_FIXO}>
                        Custo Fixo
                      </option>
                      <option value={Classificacoes.CUSTO_VARIAVEL}>
                        Custo Variavel
                      </option>
                      <option value={Classificacoes.DESPESA}>Despesa</option>
                      <option value={Classificacoes.TRIBUTOS}>Tributos</option>
                    </SelectField>
                  </Col>
                  <Col xs={12} md={4}>
                    <SelectField name="tpag" label="Forma Pagamento">
                      <option value="01">Dinheiro</option>
                      <option value="02">Cheque</option>
                      <option value="03">Cartão de Crédito</option>
                      <option value="04">Cartão de Débito</option>
                      <option value="05">Crédito Loja</option>
                      <option value="15">Boleto Bancário</option>
                      <option value="90">Sem pagamento</option>
                      <option value="99">Outros</option>
                    </SelectField>
                  </Col>
                  <Col xs={12} md={4}>
                    <NumberField
                      name="nparcelas"
                      label="Qtd Parcelas"
                      decimalPlaces={0}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <CheckField name="geraPagamento" label="Gerar Pagamento?" />
                  </Col>
                  <Col xs={12} md={4}>
                    <CheckField name="geraEstoque" label="Gerar Estoque?" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Produtos</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className="my-2"
                      onClick={handleAddItem}
                    >
                      Incluir Item
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TableOrCard
                      headers={headerItems}
                      items={values.items || []}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField name="obs" label="Observações" as="textarea" />
                  </Col>
                </Row>
                <ParcelasPurchase />
                <Row>
                  <Col xs={12} md={3}>
                    <div className="d-block border rounded mr-2 py-1 px-2 my-1 bg-white">
                      <span className="d-block lead pl-2">Total</span>
                      <span className="d-block mt-3 pl-3 lead font-weight-bold">
                        R$ {formatNumber(vTotal, 2, true)}
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} md={3}>
                    <NumberField
                      name="desconto"
                      label="Desconto Financeiro"
                      decimalPlaces={2}
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <NumberField
                      name="acrescimo"
                      label="Acréscimo Financeiro"
                      decimalPlaces={2}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ShowSuccess message={successMsg} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ShowError error={error} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <LoaderButton
                      isLoading={isSaving}
                      disabled={!isValid}
                      type="submit"
                    >
                      Salvar
                    </LoaderButton>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </LoadingContainer>
    </Container>
  );
};

export default PurchasesForm;

// class PurchasesForm extends Component<PurchaseFormProps, PurchaseFormState> {
//   state = {
//     itemEditOpen: false,
//     itemEditIndex: -1,
//     xmlReadOpen: false,
//     xml: null,
//   };

//   componentDidMount() {
//     this.props.requestItem(this.props.id);
//   }

//   handleSubmit = (item) => {
//     this.props.saveRequest(item);
//   };

//   render() {
//     const isSaving = this.props.isSaving;

//     return (
//       <Container className="mt-3">
//         <Row>
//           <Col>
//             <h1>Compra</h1>
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <Button href="/purchases" variant="primary">
//               Todas as compras
//             </Button>
//           </Col>
//         </Row>
//         <LoadingContainer
//           isLoading={this.props.isRequesting || !this.props.item}
//         >
//           <Formik
//             initialValues={this.props.item}
//             onSubmit={this.handleSubmit}
//             enableReinitialize
//             validationSchema={Yup.object().shape({
//               num: Yup.string().required(
//                 "Preenchimento do código é obrigatório"
//               ),
//               dest: Yup.object().required(
//                 "Preenchimento do fornecedor é obrigatório"
//               ),
//               entry: Yup.date().required(
//                 "Preenchimento da data de entrada é obrigatório"
//               ),
//               emissor: Yup.object().required(
//                 "Preenchimento da empresa que recebeu é obrigatório"
//               ),
//               tag: Yup.string().required(
//                 "Preenchimento do classificação é obrigatório"
//               ),
//               tpag: Yup.string().required(
//                 "Preenchimento do tipo de pagamento é obrigatório"
//               ),
//               obs: Yup.string(),
//               items: Yup.array().min(
//                 1,
//                 "Pelo menos um item deve ser preenchido"
//               ),
//             })}
//           >
//             {(props) => {
//               const { isValid, handleSubmit } = props;

//               const headerItems = [
//                 {
//                   label: "#",
//                   dataIndex: "nItem",
//                 },
//                 {
//                   label: "Produto",
//                   render: (item) => (
//                     <>
//                       {item.prod ? `${item.prod.cod} - ${item.prod.name}` : ""}
//                       {item.prod && !item.prod.cadId && (
//                         <Badge bg="success">Novo</Badge>
//                       )}
//                     </>
//                   ),
//                 },
//                 {
//                   label: "Quantidade",
//                   dataIndex: "qtd",
//                 },
//                 {
//                   label: "Valor Un.",
//                   render: (item) => formatNumber(item.valor, 2),
//                 },
//                 {
//                   label: "Total",
//                   render: (item) => formatNumber(item.total, 2),
//                 },
//                 {
//                   label: "Ações",
//                   render: (_, i) => (
//                     <ButtonGroup>
//                       <Button
//                         variant="primary"
//                         onClick={() => handleEditItem(i)}
//                       >
//                         <FontAwesomeIcon icon={faPen} />
//                       </Button>
//                       <Button
//                         variant="danger"
//                         onClick={() => handleDeleteItem(i)}
//                       >
//                         <FontAwesomeIcon icon={faTrash} />
//                       </Button>
//                     </ButtonGroup>
//                   ),
//                 },
//               ];

//               const headerParcelas = [
//                 {
//                   label: "#",
//                   dataIndex: "nParcela",
//                 },
//                 {
//                   label: "Vencimento",
//                   render: (item) => dayjs(item.venc).format("DD/MM/YYYY"),
//                 },
//                 {
//                   label: "Valor ($)",
//                   render: (item) => formatNumber(item.valor, 2, true),
//                 },
//               ];
//               const vTotal = props.values.items.reduce(
//                 (a, p) => a + p.total,
//                 0
//               );

//               const handleItemChange = (obj) => {
//                 const items = [...props.values.items];
//                 const item = { ...items[this.state.itemEditIndex], ...obj };
//                 items[this.state.itemEditIndex] = item;
//                 props.setFieldValue("items", items);
//                 handleParcelasGenerate({ items: items });
//               };

//               const handleAddItem = () => {
//                 const items = [
//                   ...props.values.items,
//                   {
//                     nItem: props.values.items.length + 1,
//                     prod: null,
//                     qtd: 0,
//                     valor: 0,
//                     total: 0,
//                   },
//                 ];

//                 props.setFieldValue("items", items);
//                 handleParcelasGenerate({ items: items });
//                 this.setState({
//                   itemEditIndex: items.length - 1,
//                   itemEditOpen: true,
//                 });
//               };

//               const handleParcelasGenerate = (obj) => {
//                 const values = {
//                   ...props.values,
//                   ...obj,
//                 };

//                 if (!values.geraPagamento) {
//                   console.log("nao gera parcelas", values.geraPagamento);
//                   return props.setFieldValue("parcelas", []);
//                 }

//                 const nparcelas = values.nparcelas;
//                 const vTotal = values.items.reduce((a, p) => a + p.total, 0);
//                 const parcelas = geraParcelas(
//                   vTotal -
//                     1 * (values.desconto || 0) +
//                     1 * (values.acrescimo || 0),
//                   nparcelas,
//                   values.entry
//                 );
//                 console.log("gerado parcelas", vTotal, nparcelas, parcelas);
//                 props.setFieldValue("parcelas", parcelas);
//               };

//               const handleEditItem = (i) => {
//                 this.setState({
//                   itemEditIndex: i,
//                   itemEditOpen: true,
//                 });
//               };

//               const handleDeleteItem = (i) => {
//                 const items = [...props.values.items];
//                 if (items[i]) {
//                   items.splice(i, 1);
//                   props.setFieldValue("items", items);
//                   handleParcelasGenerate({ items: items });
//                 }
//               };

//               return (
//                 <Form onSubmit={handleSubmit}>
//                   <ModalDialog
//                     show={this.state.itemEditOpen}
//                     title="Produto da compra"
//                     buttons={[
//                       <Button
//                         key="1"
//                         variant="primary"
//                         onClick={() => this.setState({ itemEditOpen: false })}
//                       >
//                         Concluir
//                       </Button>,
//                     ]}
//                     onClose={() => this.setState({ itemEditOpen: false })}
//                   >
//                     {props.values.items &&
//                       this.state.itemEditIndex >= 0 &&
//                       props.values.items[this.state.itemEditIndex] && (
//                         <ItemForm
//                           item={props.values.items[this.state.itemEditIndex]}
//                           handleChange={handleItemChange}
//                         />
//                       )}
//                   </ModalDialog>
//                   <Row>
//                     <Col className="my-2">
//                       <NfeField label="Importar informações do xml da NFe" />
//                     </Col>
//                   </Row>

//                   <Row>
//                     <TextInput
//                       name="num"
//                       label="Numero"
//                       formik={props}
//                       size={2}
//                     />
//                     <DateInput
//                       name="entry"
//                       label="Data de entrada"
//                       formik={props}
//                       size={2}
//                     />
//                     <Form.Group as={Col} controlId="emissor" xs={12} md={8}>
//                       <Form.Label>Empresa que recebeu</Form.Label>
//                       <EmissorSelector
//                         onChange={(emissor) =>
//                           props.setFieldValue("emissor", emissor)
//                         }
//                         value={props.values.emissor}
//                       />
//                     </Form.Group>
//                   </Row>

//                   <Row>
//                     <Form.Group as={Col} controlId="dest" xs={12}>
//                       <Form.Label>Fornecedor</Form.Label>
//                       <DestSelector
//                         onChange={(dest) => props.setFieldValue("dest", dest)}
//                         value={props.values.dest}
//                       />
//                       {props.values.dest && !props.values.dest?.cadId && (
//                         <Badge bg="success">Novo</Badge>
//                       )}
//                     </Form.Group>
//                   </Row>

//                   <Row>
//                     <SelectInput
//                       name="tag"
//                       label="Classificação"
//                       formik={props}
//                       size={4}
//                     >
//                       <option value={Classificacoes.CUSTO_FIXO}>
//                         Custo Fixo
//                       </option>
//                       <option value={Classificacoes.CUSTO_VARIAVEL}>
//                         Custo Variavel
//                       </option>
//                       <option value={Classificacoes.DESPESA}>Despesa</option>
//                       <option value={Classificacoes.TRIBUTOS}>Tributos</option>
//                     </SelectInput>
//                     <SelectInput
//                       name="tpag"
//                       label="Forma Pagamento"
//                       formik={props}
//                       size={4}
//                     >
//                       <option value="01">Dinheiro</option>
//                       <option value="02">Cheque</option>
//                       <option value="03">Cartão de Crédito</option>
//                       <option value="04">Cartão de Débito</option>
//                       <option value="05">Crédito Loja</option>
//                       <option value="15">Boleto Bancário</option>
//                       <option value="90">Sem pagamento</option>
//                       <option value="99">Outros</option>
//                     </SelectInput>

//                     <NumberInput
//                       name="nparcelas"
//                       label="Qtd Parcelas"
//                       formik={props}
//                       changeCallback={handleParcelasGenerate}
//                       size={4}
//                       decimalPlaces={0}
//                     />
//                     <CheckInput
//                       name="geraPagamento"
//                       label="Gerar Pagamento?"
//                       formik={props}
//                       changeCallback={handleParcelasGenerate}
//                       size={4}
//                     />
//                     <CheckInput
//                       name="geraEstoque"
//                       label="Gerar Estoque?"
//                       formik={props}
//                       size={4}
//                     />
//                   </Row>

//                   <Row>
//                     <Col>
//                       <h4>Produtos</h4>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col className="mt-3 mb-3">
//                       <Button variant="primary" onClick={handleAddItem}>
//                         Incluir Item
//                       </Button>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col>
//                       <TableOrCard
//                         headers={headerItems}
//                         items={props.values.items || []}
//                       />
//                     </Col>
//                   </Row>
//                   <Row>
//                     <TextAreaInput
//                       name="obs"
//                       label="Observações"
//                       formik={props}
//                       size={12}
//                     />
//                   </Row>

//                   <Row>
//                     <Col>
//                       <h4>Parcelas</h4>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col>
//                       <TableOrCard
//                         headers={headerParcelas}
//                         items={props.values.parcelas || []}
//                       />
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col xs={12} md={3}>
//                       <div className="d-block border rounded mr-2 py-1 px-2 my-1 bg-white">
//                         <span className="d-block lead pl-2">Total</span>
//                         <span className="d-block mt-3 pl-3 lead font-weight-bold">
//                           R$ {formatNumber(vTotal, 2, true)}
//                         </span>
//                       </div>
//                     </Col>
//                     <Col xs={12} md={3}>
//                       <NumberField
//                         name="desconto"
//                         label="Desconto Financeiro"
//                         decimalPlaces={2}
//                       />
//                     </Col>
//                     <Col xs={12} md={3}>
//                       <NumberField
//                         name="acrescimo"
//                         label="Acréscimo Financeiro"
//                         decimalPlaces={2}
//                       />
//                     </Col>
//                   </Row>

//                   <Row>
//                     <Col>
//                       <ShowSuccess message={this.props.successMsg} />
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col>
//                       <ShowError error={this.props.error} />
//                     </Col>
//                   </Row>

//                   <Row>
//                     <Col>
//                       <LoaderButton
//                         isLoading={isSaving}
//                         disabled={!isValid}
//                         type="submit"
//                       >
//                         Salvar
//                       </LoaderButton>
//                     </Col>
//                   </Row>
//                 </Form>
//               );
//             }}
//           </Formik>
//         </LoadingContainer>
//       </Container>
//     );
//   }
// }

// const mapStateToProps = (state: RootState) => {
//   return {
//     item: state.purchases.form.item,
//     successMsg: state.purchases.form.successMsg,
//     error: state.purchases.form.error,
//     isRequesting: state.purchases.form.isRequesting,
//     isSaving: state.purchases.form.isSaving,
//   };
// };
// const mapDispatchToProps = {
//   requestItem: purchaseFormActions.requestItem,
//   saveRequest: purchaseFormActions.saveRequest,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(PurchasesForm);
