// actions do form do modulo transp
export const TRANSP_FORM_INIT = 'TRANSP_FORM_INIT'
export const TRANSP_FORM_SUCCESS = 'TRANSP_FORM_SUCCESS'
export const TRANSP_FORM_ERROR = 'TRANSP_FORM_ERROR'
export const TRANSP_SAVE_REQUESTING = 'TRANSP_SAVE_REQUESTING'
export const TRANSP_SAVE_SUCCESS = 'TRANSP_SAVE_SUCCESS'
export const TRANSP_SAVE_ERROR = 'TRANSP_SAVE_ERROR'

export function formInit(cadId) {
  return {
    type: TRANSP_FORM_INIT,
    payload: cadId
  }
}

export function formInitSuccess(item) {
  return {
    type: TRANSP_FORM_SUCCESS,
    payload: item
  }
}

export function formInitError(error) {
  return {
    type: TRANSP_FORM_ERROR,
    error
  }
}

export function formSave(item) {
  return {
    type: TRANSP_SAVE_REQUESTING,
    payload: item
  }
}

export function formSuccess(item) {
  return {
    type: TRANSP_SAVE_SUCCESS,
    payload: item
  }
}

export function formError(error) {
  return {
    type: TRANSP_SAVE_ERROR,
    error
  }
}

