import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import ncmList from "../misc/ncm.json";
import { NCMObject } from "../misc/ncm";

type NcmSelectorProps = {
  value: string;
  onChange: (value: string) => void;
};

/**
 * React component for selecting city with UF, CMun and the City
 */
const NcmSelector: React.FC<NcmSelectorProps> = (props) => {
  const searchNcm = (ncm: NCMObject, props: any) => {
    const reSearch = new RegExp(`.*${props.text}.*`, "i");
    return reSearch.test(`${ncm.NCM}`) || reSearch.test(ncm["Descrição"]);
  };

  const renderNcmOption = (ncm: NCMObject) =>
    `${ncm["NCM"]} - ${ncm["Descrição"]}`;

  const onNcmSelect = (arrCest: NCMObject[]) => {
    if (arrCest.length > 0) {
      const cfop = arrCest[0];
      props.onChange(`${cfop.NCM}`);
    }
  };

  return (
    <Typeahead
      id="ncmselector"
      labelKey={renderNcmOption}
      options={ncmList as NCMObject[]}
      filterBy={searchNcm}
      onChange={onNcmSelect}
      defaultInputValue={props.value}
    />
  );
};

export default NcmSelector;
