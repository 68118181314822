import { inventoryActions, ProdInventory } from "./reducer";
import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { AWSRes } from "../../../redux/types";
import { Prod } from "../actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { formatDate } from "../../../misc/formatters";

function* inventoryInitWorker() {
  try {
    const res: AWSRes<Prod> = yield call(
      executeApiRequest,
      `/prod`,
      "GET",
      {},
      {}
    );
    yield put(inventoryActions.initSuccess(res));
  } catch (error) {
    yield put(inventoryActions.initError(error));
  }
}

function* inventorySaveWorker({ payload }: PayloadAction<ProdInventory[]>) {
  let i = 0;
  for (const item of payload) {
    try {
      yield call(executeApiRequest, `/estoque`, "POST", {
        prod: item,
        quantidade: item.qtdAlterar - item.quantidadeAtual,
        desc: `Inventário executado em ${formatDate(new Date())}`,
        valor_unitario: item.custoMedio,
        data: new Date(),
      });
      yield put(inventoryActions.inventorySaveIndex({ indexSaved: i }));
    } catch (error) {
      yield put(inventoryActions.inventorySaveIndex({ indexSaved: i, error }));
    }
  }
  yield put(inventoryActions.inventorySaveFinished());
  yield put(inventoryActions.initInventory());
}

export default function* inventorySaga() {
  yield all([
    takeLatest("inventory/initInventory", inventoryInitWorker),
    takeLatest("inventory/requestInventorySave", inventorySaveWorker),
  ]);
}
