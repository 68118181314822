import { AWSRes } from "./../../redux/types";
// saga do modulo task
import { put, all, call, takeLatest, takeEvery } from "redux-saga/effects";
import {
  TASK_INIT,
  TASK_DELETE_REQUESTING,
  TASK_SAVE_REQUESTING,
  MOV_TYPE,
  taskSuccess,
  taskError,
  taskDeleteSuccess,
  taskDeleteError,
  pageInit,
  taskSaveSuccess,
  taskSaveError,
  Task,
} from "./actions";
import viewSaga from "./taskView/saga";
import executeApiRequest from "../../misc/executeApiRequest";

function taskCall(lastKey = null) {
  return executeApiRequest<AWSRes<Task[]>>(
    "/mov",
    "GET",
    {},
    {
      queryStringParameters: {
        movType: MOV_TYPE,
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* taskWorker() {
  try {
    const res: AWSRes<Task[]> = yield call(taskCall);
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: AWSRes<Task[]> = yield call(taskCall, res.lastKey);
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(taskSuccess(res));
  } catch (error) {
    yield put(taskError(error));
  }
}

function* taskDeleteWorker({ payload, onFinishAction }: any) {
  try {
    yield call(
      executeApiRequest,
      `/mov/${payload}`,
      "DELETE",
      {},
      {
        queryStringParameters: {
          movType: MOV_TYPE,
        },
      }
    );
    yield put(taskDeleteSuccess());
    yield put(pageInit());
    if (onFinishAction && onFinishAction.type) {
      yield put(onFinishAction);
    }
  } catch (error) {
    yield put(taskDeleteError(error));
  }
}

function* taskSaveWorker({ payload, onFinishAction }: any) {
  try {
    if (payload.movId) {
      // Update
      const res = yield call(
        executeApiRequest,
        `/mov/${payload.movId}`,
        "PUT",
        payload
      );
      yield put(taskSaveSuccess(res));
    } else {
      const res = yield call(executeApiRequest, "/mov", "POST", payload);
      yield put(taskSaveSuccess(res));
    }
    if (onFinishAction && onFinishAction.type) {
      yield put(onFinishAction);
    }
    yield put(pageInit());
  } catch (error) {
    console.log("error", error, error.response);
    yield put(taskSaveError(error.response.data));
  }
}
export default function* taskSaga() {
  yield all([
    takeLatest(TASK_INIT, taskWorker),
    takeLatest(TASK_DELETE_REQUESTING, taskDeleteWorker),
    takeEvery(TASK_SAVE_REQUESTING, taskSaveWorker),
    viewSaga(),
  ]);
}
