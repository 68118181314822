import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { CfopObject } from "../misc/cfop";
import cfopsList from "../misc/cfop.json";

type CfopSelectorProps = {
  value: string;
  tipo: "E" | "S";
  onChange: (value: string) => void;
};

/**
 * React component for selecting city with UF, CMun and the City
 */
const CfopSelector: React.FC<CfopSelectorProps> = (props) => {
  const [cfopSelected, setCfopSelected] = useState([]);

  const searchCfop = (cfop: CfopObject, props: any) => {
    const reSearch = new RegExp(`.*${props.text}.*`, "i");
    const reTipo = props.tipo === "E" ? /^[123]/ : /^[567]/;
    return (
      reTipo.test(`${cfop["CFOP"]}`) &&
      (reSearch.test(`${cfop.CFOP}`) || reSearch.test(cfop["Descricao"]))
    );
  };

  const renderCfopOption = (cfop: CfopObject) =>
    `${cfop["CFOP"]} - ${cfop["Categoria"]} - ${cfop["Descricao"]}`;

  const onCitySelect = (arrCfop: CfopObject[]) => {
    if (arrCfop.length > 0) {
      const cfop = arrCfop[0];
      props.onChange(`${cfop.CFOP}`);
      setCfopSelected([cfop]);
    }
  };

  useEffect(() => {
    if (props.value) {
      const cfop = cfopsList.find((cfop) => `${cfop.CFOP}` === props.value);
      setCfopSelected([cfop]);
    }
  }, [props.value]);

  return (
    <Typeahead
      id="cfopselector"
      labelKey={renderCfopOption}
      options={cfopsList}
      filterBy={searchCfop}
      onChange={onCitySelect}
      selected={cfopSelected}
    />
  );
};

export default CfopSelector;
