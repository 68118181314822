import { redirectTo, RouteComponentProps, useLocation } from "@reach/router";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";
import { useIsMaster } from "../misc/useIsMaster";

interface MasterRouteProps extends RouteComponentProps {
  as: React.ElementType;
  children?: React.ReactNode;
}

export const MasterRoute: React.FC<MasterRouteProps> = ({
  as: Comp,
  ...rest
}) => {
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector(
    (state: RootState) => state.login.isAuthenticated
  );
  const isAdmin = useIsMaster();
  console.log("path", pathname, search);
  if (!isAuthenticated) {
    redirectTo(`/login?redirect=${pathname}${search}`);
  }
  if (!isAdmin) {
    redirectTo(`/admin-only`);
  }

  return <Comp {...rest} />;
};

export default MasterRoute;
