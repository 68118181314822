// saga do form do modulo cond
import { put, all, call, takeLatest, takeEvery } from "redux-saga/effects";
import {
  CONDCLOSE_INIT,
  condError,
  condSuccess,
  CONDCLOSE_CLOSE_REQUESTING,
  closedSuccess,
  closedError,
  pageInit,
} from "./actions";
import executeApiRequest from "../../../misc/executeApiRequest";
import { CondRes } from "../saga";

function condCall(movIdBet: any, lastKey: any = null) {
  return executeApiRequest<CondRes>(
    "/cond",
    "GET",
    {},
    {
      queryStringParameters: {
        movIdBet: movIdBet ? movIdBet : "",
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* condInitWorker({ payload }: any) {
  try {
    const res: CondRes = yield call(condCall, payload);
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: CondRes = yield call(condCall, payload, res.lastKey);
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(condSuccess(res));
  } catch (error) {
    yield put(condError(error));
  }
}

function* condSaveWorker({ payload, onFinishCallback }: any) {
  try {
    console.log("payload save", payload);

    for (let id of Object.keys(payload)) {
      const mov = payload[id];
      if (!mov.movId) {
        yield put(closedError("Condicional não localizada, tente novamente!"));
      }
      // Update
      yield call(executeApiRequest, `/closeCond/${mov.movId}`, "PUT", mov);
    }
    if (typeof onFinishCallback === "function") {
      onFinishCallback();
    }
    yield put(closedSuccess());
    yield put(pageInit());
  } catch (error) {
    console.log("error", error, error.response);
    yield put(closedError(error.response.data));
  }
}

export default function* condPaySaga() {
  yield all([
    takeLatest(CONDCLOSE_INIT, condInitWorker),
    takeEvery(CONDCLOSE_CLOSE_REQUESTING, condSaveWorker),
  ]);
}
