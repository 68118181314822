// actions do form do modulo dest
export const DEST_FORM_INIT = 'DEST_FORM_INIT'
export const DEST_FORM_SUCCESS = 'DEST_FORM_SUCCESS'
export const DEST_FORM_ERROR = 'DEST_FORM_ERROR'
export const DEST_SAVE_REQUESTING = 'DEST_SAVE_REQUESTING'
export const DEST_SAVE_SUCCESS = 'DEST_SAVE_SUCCESS'
export const DEST_SAVE_ERROR = 'DEST_SAVE_ERROR'

export function formInit(cadId) {
  return {
    type: DEST_FORM_INIT,
    payload: cadId
  }
}

export function formInitSuccess(item) {
  return {
    type: DEST_FORM_SUCCESS,
    payload: item
  }
}

export function formInitError(error) {
  return {
    type: DEST_FORM_ERROR,
    error
  }
}

export function formSave(item) {
  return {
    type: DEST_SAVE_REQUESTING,
    payload: item
  }
}

export function formSuccess(item) {
  return {
    type: DEST_SAVE_SUCCESS,
    payload: item
  }
}

export function formError(error) {
  return {
    type: DEST_SAVE_ERROR,
    error
  }
}

