import React from "react";
import { FormikProps } from "formik";

export type SelectInputProps = {
  name: string;
  label: string;
  size: number;
  formik: FormikProps<any>;
};

const SelectInput: React.FC<SelectInputProps> = (props) => (
  <div className={`form-group col-12 col-md-${props.size}`}>
    <label style={{ display: "block" }}>{props.label}</label>
    <select
      className="form-control"
      id={props.name}
      value={props.formik.values[props.name]}
      onChange={props.formik.handleChange}
      onBlur={props.formik.handleBlur}
    >
      {props.children}
    </select>
  </div>
);

export default SelectInput;
