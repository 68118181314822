import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AWSRes } from "./../../../redux/types";
import { InvoiceParcela } from "./../actions";

type InvoicePaneState = {
  ItemsOpen: InvoiceParcela[];
  CountOpen: number;
  lastKeyOpen: Record<string, any>;
  ItemsClosed: InvoiceParcela[];
  CountClosed: number;
  lastKeyClosed: Record<string, any>;
  successMsg: string;
  error: string;
  isRequestingOpen: boolean;
  isRequestingClosed: boolean;
  printNotas: InvoiceParcela[];
  isRequestingBoleto: boolean;
  requestBoletoError: string;
  boleto: string;
  isRequestingPix: boolean;
};

const initialState: InvoicePaneState = {
  ItemsOpen: [],
  CountOpen: 0,
  lastKeyOpen: null,
  ItemsClosed: [],
  CountClosed: 0,
  lastKeyClosed: null,
  successMsg: "",
  error: "",
  isRequestingOpen: false,
  isRequestingClosed: false,
  printNotas: [],
  isRequestingBoleto: false,
  requestBoletoError: "",
  boleto: null,
  isRequestingPix: false,
};

const paneSlice = createSlice({
  name: "invoicePane",
  initialState,
  reducers: {
    requestItemsOpen: (state) => {
      state.ItemsOpen = [];
      state.CountOpen = 0;
      state.lastKeyOpen = null;
      state.isRequestingOpen = true;
      state.isRequestingBoleto = false;
    },
    itemsOpenSuccess: (
      state,
      { payload }: PayloadAction<AWSRes<InvoiceParcela>>
    ) => {
      state.ItemsOpen = payload.items;
      state.CountOpen = payload.Count;
      state.lastKeyOpen = payload.lastKey;
      state.isRequestingOpen = false;
    },
    itemsOpenError: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingOpen = false;
      state.error = payload;
    },
    requestItemsClosed: (state) => {
      state.ItemsClosed = [];
      state.CountClosed = 0;
      state.lastKeyClosed = null;
      state.isRequestingClosed = true;
    },
    itemsClosedSuccess: (
      state,
      { payload }: PayloadAction<AWSRes<InvoiceParcela>>
    ) => {
      state.ItemsClosed = payload.items;
      state.CountClosed = payload.Count;
      state.lastKeyClosed = payload.lastKey;
      state.isRequestingClosed = false;
    },
    itemsClosedError: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingClosed = false;
      state.error = payload;
    },
    printNotas: (state, { payload }: PayloadAction<InvoiceParcela[]>) => {
      state.printNotas = payload;
    },
    requestBoleto: (state, _: PayloadAction<InvoiceParcela>) => {
      state.isRequestingBoleto = true;
      state.requestBoletoError = "";
      state.boleto = null;
    },
    boletoSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingBoleto = false;
      state.boleto = payload;
    },
    boletoError: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingBoleto = false;
      state.requestBoletoError = payload;
    },
    requestPix: (state, _: PayloadAction<InvoiceParcela>) => {
      state.isRequestingPix = true;
      state.error = "";
    },
    pixSuccess: (state, { payload }: PayloadAction<InvoiceParcela>) => {
      state.isRequestingPix = false;
      const items = [...state.ItemsOpen];
      const indItem = items.findIndex((i) => i.invoiceId === payload.invoiceId);
      if (indItem >= 0) {
        items[indItem] = payload;
        state.ItemsOpen = items;
      }
    },
    pixError: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingPix = false;
      state.error = payload;
    },
  },
});

export const invoicePaneActions = paneSlice.actions;

export default paneSlice;
