// reducer do modulo payment
import formReducer from "./paymentForm/reducer";
import payReducer from "./paymentPay/reducer";
import viewReducer from "./paymentView/reducer";
import { combineReducers } from "redux";
import PaymentParcela from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AWSRes } from "../../redux/types";

type PaymentsInitialState = {
  itemsThisWeek: PaymentParcela[];
  CountThisWeek: number;
  lastKeyThisWeek: Record<string, string>;
  itemsNextWeek: PaymentParcela[];
  CountNextWeek: number;
  lastKeyNextWeek: Record<string, string>;
  itemsNext30: PaymentParcela[];
  CountNext30: number;
  lastKeyNext30: Record<string, string>;
  itemsPayed: PaymentParcela[];
  CountPayed: number;
  lastKeyPayed: Record<string, string>;
  itemsLast: PaymentParcela[];
  CountLast: number;
  lastKeyLast: Record<string, string>;
  successMsg: string;
  error: string;
  isRequestingThisWeek: boolean;
  isRequestingNextWeek: boolean;
  isRequestingNext30: boolean;
  isRequestingPayed: boolean;
  isRequestingLast: boolean;
  isDeleting: boolean;
};

const initialState: PaymentsInitialState = {
  itemsThisWeek: [],
  CountThisWeek: 0,
  lastKeyThisWeek: null,
  itemsNextWeek: [],
  CountNextWeek: 0,
  lastKeyNextWeek: null,
  itemsNext30: [],
  CountNext30: 0,
  lastKeyNext30: null,
  itemsPayed: [],
  CountPayed: 0,
  lastKeyPayed: null,
  itemsLast: [],
  CountLast: 0,
  lastKeyLast: null,
  successMsg: "",
  error: "",
  isRequestingThisWeek: false,
  isRequestingNextWeek: false,
  isRequestingNext30: false,
  isRequestingPayed: false,
  isRequestingLast: false,
  isDeleting: false,
};

const reducerSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    requestThisWeek: (state) => {
      state.isRequestingThisWeek = true;
      state.itemsThisWeek = [];
      state.error = "";
    },
    requestThisWeekSuccess: (
      state,
      { payload }: PayloadAction<AWSRes<PaymentParcela>>
    ) => {
      state.isRequestingThisWeek = false;
      state.itemsThisWeek = payload.items;
      state.CountThisWeek = payload.Count;
      state.lastKeyThisWeek = payload.lastKey;
    },
    requestThisWeekError: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingThisWeek = false;
      state.error = payload;
    },
    requestNextWeek: (state) => {
      state.isRequestingNextWeek = true;
      state.itemsNextWeek = [];
      state.CountNextWeek = 0;
      state.error = "";
    },
    requestNextWeekSuccess: (
      state,
      { payload }: PayloadAction<AWSRes<PaymentParcela>>
    ) => {
      state.isRequestingNextWeek = false;
      state.itemsNextWeek = payload.items;
      state.CountNextWeek = payload.Count;
      state.lastKeyNextWeek = payload.lastKey;
    },
    requestNextWeekError: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingNextWeek = false;
      state.error = payload;
    },
    requestNext30: (state) => {
      state.isRequestingNext30 = true;
      state.itemsNext30 = [];
      state.CountNext30 = 0;
      state.lastKeyNext30 = null;
      state.error = "";
    },
    requestNext30Success: (
      state,
      { payload }: PayloadAction<AWSRes<PaymentParcela>>
    ) => {
      state.isRequestingNext30 = false;
      state.itemsNext30 = payload.items;
      state.CountNext30 = payload.Count;
      state.lastKeyNext30 = payload.lastKey;
    },
    requestNext30Error: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingNext30 = false;
      state.error = payload;
    },
    requestPayed: (state) => {
      state.isRequestingPayed = true;
      state.itemsPayed = [];
      state.CountPayed = 0;
      state.lastKeyPayed = null;
      state.error = "";
    },
    requestPayedSuccess: (
      state,
      { payload }: PayloadAction<AWSRes<PaymentParcela>>
    ) => {
      state.isRequestingPayed = false;
      state.itemsPayed = payload.items;
      state.CountPayed = payload.Count;
      state.lastKeyPayed = payload.lastKey;
    },
    requestPayedError: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingPayed = false;
      state.error = payload;
    },
    requestLast: (state) => {
      state.isRequestingLast = true;
      state.itemsLast = [];
      state.CountLast = 0;
      state.lastKeyLast = null;
      state.error = "";
    },
    requestLastSuccess: (
      state,
      { payload }: PayloadAction<AWSRes<PaymentParcela>>
    ) => {
      state.isRequestingLast = false;
      state.itemsLast = payload.items;
      state.CountLast = payload.Count;
      state.lastKeyLast = payload.lastKey;
    },
    requestLastError: (state, { payload }: PayloadAction<string>) => {
      state.isRequestingLast = false;
      state.error = payload;
    },
    requestDelete: (state, _: PayloadAction<string>) => {
      state.isDeleting = true;
      state.error = "";
      state.successMsg = "";
    },
    deleteSuccess: (state) => {
      state.isDeleting = false;
      state.successMsg = `Pagamento removido com sucesso`;
    },
    deleteError: (state, { payload }: PayloadAction<string>) => {
      state.isDeleting = false;
      state.error = payload;
    },
  },
});

export const paymentReducerActions = reducerSlice.actions;

export default combineReducers({
  list: reducerSlice.reducer,
  form: formReducer.reducer,
  pay: payReducer.reducer,
  view: viewReducer,
});
