import { AWSRes } from "./../../redux/types";
// saga do modulo invoice
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  INVOICE_INIT,
  INVOICE_DELETE_REQUESTING,
  invoiceSuccess,
  invoiceError,
  invoiceDeleteSuccess,
  invoiceDeleteError,
  pageInit,
  Invoice,
} from "./actions";
import formSaga from "./invoiceForm/saga";
import paySaga from "./invoicePay/saga";
import paneSaga from "./invoicePane/saga";
import viewSaga from "./invoiceView/saga";
import executeApiRequest from "../../misc/executeApiRequest";
import invoiceReportSaga from "./invoiceReport/saga";

function invoiceCall() {
  return executeApiRequest<AWSRes<Invoice>>("/invoice", "GET", {}, {});
}

function* invoiceWorker() {
  try {
    const res = yield call(invoiceCall);
    yield put(invoiceSuccess(res));
  } catch (error) {
    yield put(invoiceError(error));
  }
}

function* invoiceDeleteWorker({ payload }: any) {
  try {
    yield call(executeApiRequest, `/invoice/${payload}`, "DELETE", {}, {});
    yield put(invoiceDeleteSuccess());
    yield put(pageInit());
  } catch (error) {
    yield put(invoiceDeleteError(error));
  }
}

export default function* invoiceSaga() {
  yield all([
    takeLatest(INVOICE_INIT, invoiceWorker),
    takeLatest(INVOICE_DELETE_REQUESTING, invoiceDeleteWorker),
    formSaga(),
    paySaga(),
    paneSaga(),
    viewSaga(),
    invoiceReportSaga(),
  ]);
}
