import React, { Component, MouseEvent } from "react";
import moment from "moment";
import Calendar from "./Calendar";

import "./DateSelector.css";
import ReactInputMask from "react-input-mask";

type DateSelectorProps = {
  id: string;
  value: Date;
  onChange: (value: Date) => void;
  className?: string;
};

type DateSelectorState = {
  isOpen: boolean;
  isInvalidDate: boolean;
  inputText: string;
};

class DateSelector extends Component<DateSelectorProps, DateSelectorState> {
  state = {
    isOpen: false,
    isInvalidDate: false,
    inputText: "",
  };

  openCalendar = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (`${e.target.value}`.replace(/[^0-9]/g, "").length >= 8) {
      const momentParsed = moment(e.target.value, "DD/MM/YYYY");
      if (momentParsed.isValid()) {
        return this.selectDate(momentParsed.toDate());
      } else {
        return this.setState({
          inputText: moment(this.props.value).format("DD/MM/YYYY"),
        });
      }
    }
    this.setState({
      inputText: e.target.value,
    });
  };

  handleTextBlur = () => {
    if (`${this.state.inputText}`.replace(/[^0-9]/g, "").length >= 8) {
      const momentParsed = moment(this.state.inputText, "DD/MM/YYYY");
      if (momentParsed.isValid()) {
        return this.selectDate(momentParsed.toDate());
      } else {
        return this.setState({
          inputText: moment(this.props.value).format("DD/MM/YYYY"),
        });
      }
    } else {
      this.setState({
        inputText: moment(this.props.value).format("DD/MM/YYYY"),
      });
    }
  };

  componentDidMount() {
    if (this.props.value instanceof Date) {
      this.setState({
        inputText: moment(this.props.value).format("DD/MM/YYYY"),
      });
    } else {
      try {
        const date = moment(this.props.value);
        this.setState({
          inputText: date.format("DD/MM/YYYY"),
        });
      } catch (error) {}
    }
  }

  componentDidUpdate(prevProps: DateSelectorProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value instanceof Date) {
        this.setState({
          inputText: moment(this.props.value).format("DD/MM/YYYY"),
        });
      } else {
        try {
          const date = moment(this.props.value);
          this.setState({
            inputText: date.format("DD/MM/YYYY"),
          });
        } catch (error) {}
      }
    }
  }

  selectDate = (date: Date) => {
    this.setState({
      isOpen: false,
      inputText: moment(date).format("DD/MM/YYYY"),
    });
    this.props.onChange(date);
  };

  render() {
    return (
      <div className={`input-group ${this.props.className}`}>
        <ReactInputMask
          id={this.props.id}
          mask="99/99/9999"
          maskPlaceholder=" "
          value={this.state.inputText}
          onChange={this.handleTextChange}
          onBlur={this.handleTextBlur}
          className={`form-control ${
            this.state.isInvalidDate ? "is-invalid" : ""
          }`}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary dropdown-toggle"
            type="button"
            onClick={this.openCalendar}
            id="calendarDropDown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {" "}
          </button>
          <div
            className="dropdown-menu"
            style={this.state.isOpen ? { display: "block" } : {}}
            aria-labelledby="calendarDropDown"
          >
            <Calendar value={this.props.value} onChange={this.selectDate} />
            <div className="pl-1 pr-1 w-100">
              <button
                className="btn btn-secondary w-100"
                type="button"
                onClick={() => this.selectDate(new Date())}
              >
                Hoje
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DateSelector;
