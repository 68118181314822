import React, { Component } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteButton from "../../components/DeleteButton";
import { LinkButton } from "../../components/LinkButton";
import LoadingContainer from "../../components/LoadingContainer";
import TableOrCard from "../../components/TableOrCard";
import { formatNumber } from "../../misc/formatters";
import { Prod } from "./actions";
import { prodActions } from "./reducer";

type ProdsProps = {
  items: Prod[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isDeleting: boolean;
  pageInit: () => void;
  deleteRequest: (cadId: string) => void;
};

class Prods extends Component<ProdsProps, {}> {
  state = {};

  headers = [
    {
      label: "Código",
      dataIndex: "cod",
    },
    {
      label: "Descrição",
      dataIndex: "name",
    },
    {
      label: "Preço",
      render: (item: Prod) => formatNumber(item.preco, 2, true),
    },
    {
      label: "Estoque",
      render: (item: Prod) => formatNumber(item.quantidadeAtual, 0, true),
    },
    {
      label: "Ações",
      render: (item: Prod) => (
        <ButtonGroup>
          <LinkButton variant="info" to={`/prod/${item.cadId}`}>
            <i className="fas fa-edit" />
          </LinkButton>
          <DeleteButton
            isRequesting={this.props.isDeleting}
            onDelete={() => this.handleDelete(item)}
          />
        </ButtonGroup>
      ),
    },
  ];

  componentDidMount() {
    this.props.pageInit();
  }

  handleDelete = (item: Prod) => {
    this.props.deleteRequest(item.cadId);
  };

  render() {
    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Produtos</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <LinkButton variant="primary" to="/prod/new">
              Incluir
            </LinkButton>
            <LinkButton variant="primary" to="/inventory">
              Inventario de estoque
            </LinkButton>
            <LinkButton
              variant="secondary"
              className="ml-3"
              to="/importar-prod-xml/"
            >
              Importar de XML de NFe
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={this.props.items} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.prod.list.items,
    Count: state.prod.list.Count,
    lastKey: state.prod.list.lastKey,
    isRequesting: state.prod.list.isRequesting,
    isDeleting: state.prod.list.isDeleting,
  };
};
const mapDispatchToProps = {
  pageInit: prodActions.init,
  deleteRequest: prodActions.deleteRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Prods);
