import { AWSRes } from "./../../redux/types";
import { put, all, call, takeLatest } from "redux-saga/effects";
import formSaga from "./emissorForm/saga";
import executeApiRequest from "../../misc/executeApiRequest";
import {
  success,
  error,
  deleteSuccess,
  init,
  deleteError,
  Emissor,
} from "./reducer";

function emissorCall() {
  return executeApiRequest<AWSRes<Emissor>>("/emissor", "GET", {}, {});
}

function* emissorWorker() {
  try {
    const res: AWSRes<Emissor> = yield call(emissorCall);
    // yield put(emissorSuccess(res));
    yield put(success(res));
  } catch (err) {
    yield put(error(err));
  }
}

function* emissorDeleteWorker({ payload }: any) {
  try {
    yield call(executeApiRequest, `/emissor/${payload}`, "DELETE", {}, {});
    // yield put(emissorDeleteSuccess());
    // yield put(pageInit());
    yield put(deleteSuccess("Emissor removido com sucesso!"));
    yield put(init());
  } catch (error) {
    yield put(deleteError(error));
  }
}

export default function* emissorSaga() {
  yield all([
    takeLatest("emissor.list/init", emissorWorker),
    takeLatest("emissor.list/deleteRequest", emissorDeleteWorker),
    formSaga(),
  ]);
}
