import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type WhatsappState = {
  isStarting: boolean;
  successMsg: string;
  error: string;
  whatsQrCode?: string;
  isFinishedAuthenticated?: boolean;
  isSendingTest?: boolean;
  isSavingTemplate?: boolean;
  isTemplateLoading?: boolean;
  whatsTemplates?: Record<string, WhatsappTemplate>;
  receberVencimento?: WhatsappTemplate | Partial<WhatsappTemplate>;
};

export type WhatsappTemplate = {
  templateMsg: string;
  situation: string;
  isActivated: boolean;
  orgId: string;
  userId: string;
  cadId: string;
  cadType: string;
  version: number;
};

const initialState: WhatsappState = {
  isStarting: false,
  successMsg: "",
  error: "",
  isSendingTest: false,
  whatsTemplates: {},
};

const whatsappSlice = createSlice({
  name: "whats",
  initialState,
  reducers: {
    startWhatsapp(state) {
      state.isStarting = true;
      state.successMsg = "";
      state.error = "";
      state.isFinishedAuthenticated = false;
    },
    startSuccess(state, { payload }: PayloadAction<string>) {
      state.isStarting = false;
      state.successMsg = `Whatsapp iniciado com sucesso!`;
      state.whatsQrCode = payload;
    },
    startError(state, { payload }: PayloadAction<string>) {
      state.isStarting = false;
      state.error = payload;
    },
    finishedAuthenticated(state) {
      state.isFinishedAuthenticated = true;
    },
    sendTest(state, _: PayloadAction<{ to: string; text: string }>) {
      state.isSendingTest = true;
      state.successMsg = "";
      state.error = "";
    },
    testSuccess(state) {
      state.isSendingTest = false;
      state.successMsg =
        "Mensagem de teste enviada com sucesso, deve chegar no destino em até 5 minutos";
    },
    testError(state, { payload }: PayloadAction<string>) {
      state.isSendingTest = false;
      state.error = payload;
    },
    loadTemplate(state, _: PayloadAction<string>) {
      state.isTemplateLoading = true;
      state.error = "";
    },
    loadTemplateSuccess(state, { payload }: PayloadAction<WhatsappTemplate>) {
      state.isTemplateLoading = false;
      state.whatsTemplates[payload.situation] = payload;
    },
    loadTemplateError(state, { payload }: PayloadAction<string>) {
      state.isTemplateLoading = false;
      state.error = payload;
    },
    saveTemplate(state, _: PayloadAction<Partial<WhatsappTemplate>>) {
      state.isSavingTemplate = true;
      state.error = "";
      state.successMsg = "";
    },
    saveTemplateSuccess(state, { payload }: PayloadAction<WhatsappTemplate>) {
      state.isSavingTemplate = false;
      state.successMsg = "Template de mensagem salvo com sucesso!";
      state.receberVencimento = payload;
    },
    saveTemplateError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isSavingTemplate = false;
    },
  },
});

export const whatsappActions = whatsappSlice.actions;

export default whatsappSlice.reducer;
