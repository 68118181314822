import React, { Component } from "react";
import { connect } from "react-redux";
import { pageInit, Tipoop } from "../actions";
import ModSelector from "../../../components/ModSelector";

type TipoopSelectorStateProps = {
  items: Tipoop[];
};
type TipoopSelectorDispatchProps = {
  pageInit: () => void;
};
type TipoopSelectorProps = {
  value: Tipoop | null;
  onChange: (tipoop: Tipoop) => void;
};

class TipoopSelector extends Component<
  TipoopSelectorStateProps & TipoopSelectorDispatchProps & TipoopSelectorProps,
  {}
> {
  state = {};

  componentDidMount() {
    if (this.props.items.length <= 0) {
      this.props.pageInit();
    }
  }

  filterOptions = (tipoop, term) => {
    const reTerm = new RegExp(term.text, "i");
    return reTerm.test(tipoop.cod) || reTerm.test(tipoop.name);
  };

  render() {
    return (
      <ModSelector
        options={this.props.items}
        renderOption={(tipoop) => `${tipoop.cod} - ${tipoop.name}`}
        path="/tipoop"
        value={this.props.value}
        onChange={this.props.onChange}
        filterBy={this.filterOptions}
        minLength={2}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.tipoop.list.items,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: () => dispatch(pageInit()),
  };
};

export default connect<
  TipoopSelectorStateProps,
  TipoopSelectorDispatchProps,
  TipoopSelectorProps
>(
  mapStateToProps,
  mapDispatchToProps
)(TipoopSelector);
