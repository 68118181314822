export const CLIENT_CONDS_REQUESTING = "CLIENT_CONDS_REQUESTING";
export const CLIENT_CONDS_SUCCESS = "CLIENT_CONDS_SUCCESS";
export const CLIENT_CONDS_ERROR = "CLIENT_CONDS_ERROR";

export const clientCondsRequest = (payload: any) => ({
  type: CLIENT_CONDS_REQUESTING,
  payload,
});

export const clientCondsSuccess = (payload: any) => ({
  type: CLIENT_CONDS_SUCCESS,
  payload,
});

export const clientCondsError = (error: any) => ({
  type: CLIENT_CONDS_ERROR,
  error,
});
