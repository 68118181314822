// import { setOrg } from "../login/actions";
import { Link } from "@reach/router";
import React, { Component } from "react";
import { Badge, Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteButton from "../../components/DeleteButton";
import LoadingContainer from "../../components/LoadingContainer";
import TableOrCard from "../../components/TableOrCard";
import { setDefaultEmissor } from "../../misc/orgOperations";
import { RootState } from "../app/mainReducer";
import { loginActions, Org } from "../login/reducer";
import { Emissor, init, request } from "./reducer";

type EmissorStateProps = {
  org: any;
  items: Emissor[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isDeleting: boolean;
};
type EmissorDispatchProps = {
  setOrg: (org: Org) => void;
  pageInit: () => void;
  deleteRequest: (cadId: string) => void;
};
type EmissorProps = {};

class Emissores extends Component<
  EmissorProps & EmissorStateProps & EmissorDispatchProps,
  {}
> {
  state = {};

  handleSetEmissor = async (item: Emissor) => {
    try {
      console.log("data", item.cadId, this.props.org);
      const newOrg = await setDefaultEmissor(item.cadId, this.props.org);
      if (typeof this.props.setOrg === "function") {
        this.props.setOrg(newOrg);
      }
    } catch (error) {
      console.log("error", error);
      alert(
        "Ocorreu um erro ao selecionar o emissor padrao, tente novamente mais tarde!"
      );
    }
  };

  headers = [
    {
      label: "Razão Social",
      render: (item: Emissor) => (
        <>
          {item.name}&nbsp;
          {this.props.org && this.props.org.emissorId === item.cadId ? (
            <Badge variant="primary">Emissores padrão</Badge>
          ) : (
            <Badge
              variant="secondary"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSetEmissor(item)}
            >
              Selecionar como emissor padrão
            </Badge>
          )}
        </>
      ),
    },
    {
      label: "CNPJ",
      dataIndex: "cnpj",
    },
    {
      label: "Endereço",
      dataIndex: "address",
    },
    {
      label: "Numero",
      dataIndex: "addressNumber",
    },
    {
      label: "Cidade",
      dataIndex: "city",
    },
    {
      label: "UF",
      dataIndex: "estate",
    },
    {
      label: "Ações",
      render: (item: Emissor) => (
        <ButtonGroup>
          <Button variant="info" as={Link} to={`/emissor/${item.cadId}`}>
            <i className="fas fa-edit" />
          </Button>
          <DeleteButton
            isRequesting={this.props.isDeleting}
            onDelete={() => this.handleDelete(item)}
          />
        </ButtonGroup>
      ),
    },
  ];

  componentDidMount() {
    this.props.pageInit();
  }

  handleDelete = (item: Emissor) => {
    this.props.deleteRequest(item.cadId);
  };

  render() {
    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Emissores</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <Button as={Link} to="/emissor/new" variant="primary">
              Incluir
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={this.props.items} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    items: state.emissor.list.items,
    Count: state.emissor.list.Count,
    lastKey: state.emissor.list.lastKey,
    isRequesting: state.emissor.list.isRequesting,
    isDeleting: state.emissor.list.isDeleting,
    org: state.login.org,
  };
};
const dispatchProps = {
  pageInit: init,
  deleteRequest: request,
  setOrg: loginActions.setOrg,
};

export default connect<EmissorStateProps, EmissorDispatchProps, EmissorProps>(
  mapStateToProps,
  dispatchProps
)(Emissores);
