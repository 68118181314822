import * as types from "./types";

interface NotificationsState {
  notifications: any[];
}

export const initialState: NotificationsState = {
  notifications: [],
};

export const notificationsReducer = (
  state = initialState,
  action: types.reduxAction
) => {
  if (action.type === types.ADDNOTIFICATION) {
    return Object.assign({}, state, {
      notifications: [...state.notifications, action.payload],
    });
  }
  if (action.type === types.DELNOTIFICATION) {
    if (state.notifications.indexOf(action.payload)) {
      return state;
    }
    const notifications = [...state.notifications];
    notifications.splice(notifications.indexOf(action.payload), 1);
    return Object.assign({}, state, {
      notifications,
    });
  }
  return state;
};

export default notificationsReducer;
