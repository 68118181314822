import React, { Component } from "react";
import { connect } from "react-redux";
import { pageInit, Serie } from "../actions";
import ModSelector from "../../../components/ModSelector";

type SerieSelectorStateProps = {
  items: Serie[];
};
type SerieSelectorDispatchProps = {
  pageInit: () => void;
};
type SerieSelectorProps = {
  value: Serie | null;
  onChange: (serie: Serie) => void;
};

class SerieSelector extends Component<
  SerieSelectorStateProps & SerieSelectorDispatchProps & SerieSelectorProps,
  {}
> {
  state = {};

  componentDidMount() {
    if (this.props.items.length <= 0) {
      this.props.pageInit();
    }
  }

  filterOptions = (serie, term) => {
    const reTerm = new RegExp(term.text, "i");
    return reTerm.test(serie.cod) || reTerm.test(serie.name);
  };

  render() {
    return (
      <ModSelector
        options={this.props.items}
        renderOption={(serie) => `${serie.cod} - ${serie.name}`}
        path="/serie"
        value={this.props.value}
        onChange={this.props.onChange}
        filterBy={this.filterOptions}
        minLength={2}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.serie.list.items,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: () => dispatch(pageInit()),
  };
};

export default connect<
  SerieSelectorStateProps,
  SerieSelectorDispatchProps,
  SerieSelectorProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SerieSelector);
