import { AWSRes } from "./../../../redux/types";
import { Task } from "../actions";

export const CLIENT_TASK_REQUESTING = "CLIENT_TASK_REQUESTING";
export const CLIENT_TASK_SUCCESS = "CLIENT_TASK_SUCCESS";
export const CLIENT_TASK_ERROR = "CLIENT_TASK_ERROR";

export const clientTaskRequest = (payload: string) => ({
  type: CLIENT_TASK_REQUESTING,
  payload,
});

export const clientTaskSuccess = (payload: AWSRes<Task[]>) => ({
  type: CLIENT_TASK_SUCCESS,
  payload,
});

export const clientTaskError = (error: any) => ({
  type: CLIENT_TASK_ERROR,
  error,
});
