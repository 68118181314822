import { AWSRes } from "./../../redux/types";
// actions do modulo dest
export const DEST_INIT = "DEST_INIT";
export const DEST_REQUESTING = "DEST_REQUESTING";
export const DEST_SUCCESS = "DEST_SUCCESS";
export const DEST_ERROR = "DEST_ERROR";
export const CAD_TYPE = "CAD_DEST";
export const DEST_DELETE_REQUESTING = "DEST_DELETE_REQUESTING";
export const DEST_DELETE_SUCCESS = "DEST_DELETE_SUCCESS";
export const DEST_DELETE_ERROR = "DEST_DELETE_ERROR";
export const DEST_IMPORTAR_INIT = "DEST_IMPORTAR_INIT";
export const DEST_IMPORTAR_REQUEST = "DEST_IMPORTAR_REQUEST";
export const DEST_IMPORTAR_SUCCESS = "DEST_IMPORTAR_SUCCESS";
export const DEST_IMPORTAR_ERROR = "DEST_IMPORTAR_ERROR";

export type Dest = {
  cadId?: string;
  version?: number;
  userId?: string;
  cadType: "CAD_DEST";
  name: string;
  type: number;
  indFinal: boolean;
  dn?: Date;
  indIe?: number;
  iest?: string;
  isuf?: string;
  im?: string;
  cpf?: string;
  rg?: string;
  cnpj?: string;
  ie?: string;
  estate: string;
  city: string;
  zipCode: string;
  address: string;
  addressNumber: string;
  bairro: string;
  cmun: string;
  email: string;
  phone: string;
  obss?: DestObs[];
};

export type DestObs = {
  entryDate?: Date;
  obs?: string;
};

export function pageInit() {
  return {
    type: DEST_INIT,
  };
}

export function destRequest() {
  return {
    type: DEST_REQUESTING,
  };
}

export function destSuccess(payload: AWSRes<Dest>) {
  return {
    type: DEST_SUCCESS,
    payload,
  };
}

export function destError(error: any) {
  return {
    type: DEST_ERROR,
    error,
  };
}

export function destDeleteRequest(cadId: string) {
  return {
    type: DEST_DELETE_REQUESTING,
    payload: cadId,
  };
}

export function destDeleteSuccess() {
  return {
    type: DEST_DELETE_SUCCESS,
  };
}

export function destDeleteError(error: any) {
  return {
    type: DEST_DELETE_ERROR,
    error,
  };
}

export const destImportarInit = () => ({
  type: DEST_IMPORTAR_INIT,
});

export const destImportarRequest = (payload: any) => ({
  type: DEST_IMPORTAR_REQUEST,
  payload,
});

export const destImportarSuccess = (payload: any) => ({
  type: DEST_IMPORTAR_SUCCESS,
  payload,
});

export const destImportarError = (error: any) => ({
  type: DEST_IMPORTAR_ERROR,
  error,
});
