import { parseISO, format } from "date-fns";

export function formatNumber(val: number, decimalPlaces = 0, showZero = false) {
  if (!val) {
    return showZero ? (0).toFixed(decimalPlaces).replace(".", ",") : "";
  }
  return val.toFixed(decimalPlaces).replace(".", ",");
}

export function formatDate(
  date: string | Date,
  formatStr: string = "dd/MM/yyyy"
): string {
  if (!date) {
    return "";
  }
  try {
    if (typeof date === "string") {
      const dt = parseISO(date);
      return format(dt, formatStr);
    } else {
      return format(date, formatStr);
    }
  } catch (error) {
    console.error("Erro ao formatar data", date, formatStr, error);
    return "";
  }
}
