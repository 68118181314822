import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingAnimation: React.FC<{}> = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner animation="border" role="status"></Spinner>
    </div>
  );
};

export default LoadingAnimation;
