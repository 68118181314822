import { PayloadAction } from "@reduxjs/toolkit";
// reducer.js  - initial Wizard reducer
import { createSlice } from "@reduxjs/toolkit";
import { Emissor } from "../emissor/reducer";
import { Org, User } from "../login/reducer";

type WizardState = {
  step: number;
  isWizardStarted: boolean;
  isFirstRequesting: boolean;
  firstStepError: string;
  isSecondRequesting: boolean;
  secondStepError: string;
  isThirdStepRequesting: boolean;
  thirdStepError: string;
  emissorToSave: Emissor;
};

export type FirstStepPayload = {
  org: Partial<Org>;
  user: Partial<User>;
};

export type SecondStepPayload = {
  emissor: Emissor & { newLogo: File; newCertificate: File };
  org: Org;
};

export type ThirdStepPayload = {
  serie: string;
  serieStart: number;
};

export const initialState: WizardState = {
  step: 1,
  isWizardStarted: false,
  isFirstRequesting: false,
  firstStepError: "",
  isSecondRequesting: false,
  secondStepError: "",
  isThirdStepRequesting: false,
  thirdStepError: "",
  emissorToSave: null,
};

const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  reducers: {
    init: (state) => {
      state.isWizardStarted = true;
      state.step = 1;
    },
    finishWizard: (state) => {
      state.isWizardStarted = false;
    },
    closeWizard: (state) => {
      state.isWizardStarted = false;
    },
    firstStepRequest: (state, _: PayloadAction<FirstStepPayload>) => {
      state.isFirstRequesting = true;
      state.firstStepError = "";
    },
    firstStepSuccess: (state) => {
      state.isFirstRequesting = false;
      state.firstStepError = "";
      state.step = 2;
    },
    firstStepError: (state, action: PayloadAction<string>) => {
      state.isFirstRequesting = false;
      state.firstStepError = action.payload;
    },
    secondStepRequest: (state, action: PayloadAction<SecondStepPayload>) => {
      state.isSecondRequesting = false;
      state.secondStepError = "";
      state.emissorToSave = action.payload.emissor;
      state.step = 3;
    },
    /*
    secondStepSuccess: (state) => {
      state.isSecondRequesting = false;
      state.step = 3;
    },
    secondStepError: (state, action: PayloadAction<string>) => {
      state.isSecondRequesting = false;
      state.secondStepError = action.payload;
    },
    */
    thirdStepRequest: (state, _: PayloadAction<ThirdStepPayload>) => {
      state.isThirdStepRequesting = true;
      state.thirdStepError = "";
    },
    thirdStepSuccess: (state) => {
      state.isThirdStepRequesting = false;
      state.step = 4;
    },
    thirdStepError: (state, action: PayloadAction<string>) => {
      state.isThirdStepRequesting = false;
      state.thirdStepError = action.payload;
    },
  },
});

export const wizardActions = wizardSlice.actions;

export default wizardSlice.reducer;
