import { Prod } from "../actions";

export const PROD_FORM_INIT = "PROD_FORM_INIT";
export const PROD_FORM_SUCCESS = "PROD_FORM_SUCCESS";
export const PROD_FORM_ERROR = "PROD_FORM_ERROR";
export const PROD_SAVE_REQUESTING = "PROD_SAVE_REQUESTING";
export const PROD_SAVE_SUCCESS = "PROD_SAVE_SUCCESS";
export const PROD_SAVE_ERROR = "PROD_SAVE_ERROR";

export function formInit(cadId: string) {
  return {
    type: PROD_FORM_INIT,
    payload: cadId,
  };
}

export function formInitSuccess(item: Prod) {
  return {
    type: PROD_FORM_SUCCESS,
    payload: item,
  };
}

export function formInitError(error: any) {
  return {
    type: PROD_FORM_ERROR,
    error,
  };
}

export function formSave(item: Prod) {
  item.cod = `${item.cod}`;
  if (!item.cod || (item.cod && item.cod.trim().length < 1)) {
    console.log("item cod type", item.cod, typeof item.cod);
    item.serialCreate = {
      cod: "prod",
    };
    console.log("item num type", item.serialCreate);
  }
  return {
    type: PROD_SAVE_REQUESTING,
    payload: item,
  };
}

export function formSuccess(item: Prod) {
  return {
    type: PROD_SAVE_SUCCESS,
    payload: item,
  };
}

export function formError(error: any) {
  return {
    type: PROD_SAVE_ERROR,
    error,
  };
}
