import Axios from "axios";

const URI_IBPT = "http://ibpt.nfe.io";

export async function getTaxByNCM(ncm: string, uf: string) {
  const res = await Axios.get(
    `${URI_IBPT}/ncm/${uf.trim().toLowerCase()}/${ncm}.json`
  );
  return (
    res.data.federalNationalRate +
    res.data.federalImportedRate +
    res.data.stateRate +
    res.data.municipalRate
  );
}
