import React from "react";
import { FormikProps } from "formik";

type TextInputProps = {
  name: string;
  label: string;
  size: number;
  formik: FormikProps<any>;
  cols?: number;
  rows?: number;
};

const TextInput: React.FC<TextInputProps> = (props) => (
  <div className={`form-group col-12 col-md-${props.size}`}>
    <label htmlFor={props.name}>{props.label}</label>
    <textarea
      id={props.name}
      value={props.formik.values[props.name]}
      cols={props.cols || 80}
      rows={props.rows || 5}
      onChange={props.formik.handleChange}
      onBlur={props.formik.handleBlur}
      className={
        props.formik.errors[props.name] && props.formik.touched[props.name]
          ? "form-control text-input error"
          : "form-control text-input"
      }
      placeholder={props.label}
    />
    {props.formik.errors[props.name] && props.formik.touched[props.name] && (
      <div className="input-feedback">{props.formik.errors[props.name]}</div>
    )}
  </div>
);

export default TextInput;
