import { RouteComponentProps } from "@reach/router";
import React from "react";

import "./Home.css";

const Home: React.FC<RouteComponentProps> = () => (
  <div className="welcome-message">
    <h1>
      Bem vindo ao <span className="sdcks-text">Notinha.app</span>!
    </h1>
    <h3>Um app para gerenciar e automatizar sua empresa!</h3>
  </div>
);

export default Home;
