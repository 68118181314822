import React from "react";
import { Nav } from "react-bootstrap";
import MenuNavLink from "./MenuNavLink";
import menu_links from "./menu_links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useSubscriptionRoles } from "../../misc/useSubscriptionRoles";

const Menu: React.FC<{}> = () => {
  const subscriptionRoles = useSubscriptionRoles();
  console.log("subscriptionRoles", subscriptionRoles);

  return (
    <Nav className="text-secondary d-xs-flex d-lg-none">
      {menu_links
        .filter(
          (link) => subscriptionRoles.findIndex((val) => val === link.id) >= 0
        )
        .map((link, i) => (
          <MenuNavLink to={link.link} activeClass="disabled" key={`${i}`}>
            {link.icon && <FontAwesomeIcon icon={link.icon} />} {link.label}
          </MenuNavLink>
        ))}
      <MenuNavLink to="/Profile" activeClass="disabled">
        <FontAwesomeIcon icon={faUser} /> Perfil
      </MenuNavLink>
    </Nav>
  );
};

export default Menu;
