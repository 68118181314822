// index.js - initial wizard component to setup org and initial emissor
// TODO: Create Initial Wizard
import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import FirstStep from "./FirstStep";
import EmissorForm from "../emissor/emissorForm";

import "./index.css";
import LoadingContainer from "../../components/LoadingContainer";
import ThirdStep from "./ThirdStep";
import ShowError from "../../components/ShowError";
import { loginActions, Org, User } from "../login/reducer";
import { FirstStepPayload, SecondStepPayload, wizardActions } from "./reducer";
import { RootState } from "../app/mainReducer";

type InitialWizardProps = {
  org: Org;
  loggedUser: User;
  step: number;
  isSecondRequesting: boolean;
  secondStepError: string;
  orgRequest: (loginUser: User) => void;
  setOrg: (orgObj: Partial<Org>) => void;
  firstStepRequest: (payload: FirstStepPayload) => void;
  secondStepRequest: (payload: SecondStepPayload) => void;
  initWizard: () => void;
  closeWizard: () => void;
};

type InitialWizardState = {
  username: string;
};

export class InitialWizard extends Component<
  InitialWizardProps,
  InitialWizardState
> {
  state = {
    username: "",
  };

  componentDidMount() {
    this.props.orgRequest(this.props.loggedUser);
    this.setState({
      username: this.props.loggedUser.attributes.name,
    });
    this.props.initWizard();
  }

  componentWillUnmount() {
    this.props.closeWizard();
  }

  handleFirstStep = (payload) => {
    const payloadFirstStep = {
      user: {
        name: payload.username,
      },
      org: this.props.org,
    };
    this.props.firstStepRequest(payloadFirstStep);
    this.setState({ ...payload });
  };

  handleSecondStep = (emissor) => {
    this.props.secondStepRequest({ emissor, org: this.props.org });
  };

  render() {
    if (!this.props.org) {
      return (
        <Container
          fluid
          className="d-flex justify-content-center align-items-center vh-100"
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Carregando...</span>
          </Spinner>
        </Container>
      );
    }
    if (this.props.step === 4) {
      return (
        <Container fluid>
          <Row className="mb-3 mt-3">
            <Col>
              <h1>
                Parabéns, a configuração inicial foi concluida com sucesso!
              </h1>
            </Col>
          </Row>
          <Row className="mb-3 mt-3">
            <Col>
              <h3>
                Agora voce pode prosseguir e cadastrar seus produtos, clientes,
                e começar a emitir Notas, Cobranças e Condicionais.
              </h3>
            </Col>
          </Row>
          <Row className="mb-3 mt-3">
            <Col>
              <h3>
                Se precisar de mais ajuda, entre em contato usando a bóia
                vermelha no menu superior.
              </h3>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container fluid>
        <Row className="mb-3 mt-3">
          <Col>
            <h1>Bem-Vindo, vamos fazer o cadastro de sua empresa agora.</h1>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h3>
              Para iniciar a configuração, por favor, tenha em mãos os dados da
              sua empresa, e o certificado digital A1.
            </h3>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={1}>
            <Button
              variant={this.props.step === 1 ? "primary" : "outline-primary"}
              className="rounded-circle"
              id="first-step"
            >
              1
            </Button>
          </Col>
          <Col>
            <hr className="wizard-separator" />
          </Col>
          <Col xs={1}>
            <Button
              variant={this.props.step === 2 ? "primary" : "outline-primary"}
              className="rounded-circle"
              disabled={this.props.step < 3}
              id="second-step"
            >
              2
            </Button>
          </Col>
          <Col>
            <hr className="wizard-separator" />
          </Col>
          <Col xs={1}>
            <Button
              variant={this.props.step === 3 ? "primary" : "outline-primary"}
              className="rounded-circle"
              disabled={this.props.step < 4}
              id="third-step"
            >
              3
            </Button>
          </Col>
          <Col>
            <hr className="wizard-separator" />
          </Col>
          <Col xs={1}>
            <Button
              variant={this.props.step === 4 ? "primary" : "outline-primary"}
              className="rounded-circle"
              disabled={this.props.step < 5}
              id="final-step"
            >
              4
            </Button>
          </Col>
        </Row>
        {/* First step - set username and org name */}
        {this.props.step === 1 && <FirstStep onNext={this.handleFirstStep} />}
        {/* Second step - add default emissor */}
        {this.props.step === 2 && (
          <LoadingContainer isLoading={this.props.isSecondRequesting}>
            <EmissorForm id="new" onSave={this.handleSecondStep} />
          </LoadingContainer>
        )}
        <Row>
          <Col>
            <ShowError error={this.props.secondStepError} />
          </Col>
        </Row>
        {/* Third step - add other basic info - tipoop, serie */}
        {this.props.step === 3 && <ThirdStep />}
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    org: state.login.org,
    loggedUser: state.login.loggedUser,
    step: state.wizard.step,
    isSecondRequesting: state.wizard.isSecondRequesting,
    secondStepError: state.wizard.secondStepError,
  };
};
const mapDispatchToProps = {
  orgRequest: loginActions.orgRequest,
  setOrg: loginActions.setOrg,
  firstStepRequest: wizardActions.firstStepRequest,
  secondStepRequest: wizardActions.secondStepRequest,
  initWizard: wizardActions.init,
  closeWizard: wizardActions.closeWizard,
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialWizard);
