import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";

import DestSelector from "../dest/selector";
import DateSelector from "../../components/DateSelector";
import { Task } from "./actions";

type TaskFormProps = {
  task: Task;
  noDest?: boolean;
  onChange: (obj: Partial<Task>) => void;
};

class TaskForm extends Component<TaskFormProps, {}> {
  state = {};

  render() {
    return (
      <>
        <Row>
          <Form.Group as={Col} controlId="summary" xs={12} md={5}>
            <Form.Label>T&iacute;tulo</Form.Label>
            <Form.Control
              value={this.props.task.summary}
              onChange={(e) => this.props.onChange({ summary: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="dateDue" xs={12} md={2}>
            <Form.Label>Vencimento</Form.Label>
            <DateSelector
              id="dateDue"
              value={this.props.task.dateDue}
              onChange={(dateDue) => this.props.onChange({ dateDue })}
            />
          </Form.Group>
          {!this.props.noDest && (
            <Form.Group as={Col} controlId="dest" xs={12} md={5}>
              <Form.Label>Cliente</Form.Label>
              <DestSelector
                onChange={(dest) => this.props.onChange({ dest })}
                value={this.props.task.dest}
              />
            </Form.Group>
          )}
        </Row>
      </>
    );
  }
}

export default TaskForm;
