import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DeleteButton from "../../components/DeleteButton";
import { LinkButton } from "../../components/LinkButton";
import LoadingContainer from "../../components/LoadingContainer";
import ShowError from "../../components/ShowError";
import ShowSuccess from "../../components/ShowSuccess";
import TableOrCard from "../../components/TableOrCard";
import { RootState } from "../app/mainReducer";
import { bancoActions } from "./reducer";
import { Banco } from "./types";

interface IBancosProps extends RouteComponentProps {}

const Bancos: React.FunctionComponent<IBancosProps> = () => {
  const isLoading = useSelector(
    (state: RootState) => state.banco.list.isLoading
  );
  const itens = useSelector((state: RootState) => state.banco.list.itens);
  const lastKey = useSelector((state: RootState) => state.banco.list.lastKey);
  const error = useSelector((state: RootState) => state.banco.list.error);
  const isDeleting = useSelector(
    (state: RootState) => state.banco.list.isDeleting
  );
  const deleteSuccessMsg = useSelector(
    (state: RootState) => state.banco.list.deleteSuccessMsg
  );
  const dispatch = useDispatch();

  const handleDelete = (cadId: string) => {
    dispatch(bancoActions.deleteRequest(cadId));
  };

  const carregarMais = () => {
    if (lastKey) {
      dispatch(bancoActions.requestBancos({ lastKey }));
    }
  };

  React.useEffect(() => {
    dispatch(bancoActions.requestBancos({}));
  }, [dispatch]);

  const headers = [
    {
      label: "#",
      dataIndex: "cod",
    },
    {
      label: "Nome",
      dataIndex: "name",
    },
    {
      label: "Agencia",
      render: (item: Banco) => `${item.agencia}-${item.agenciadv}`,
    },
    {
      label: "Conta",
      render: (item: Banco) => `${item.conta}-${item.contadv}`,
    },
    {
      label: "Ações",
      render: (item: Banco) => (
        <ButtonGroup>
          <LinkButton variant="info" to={`/banco/${item.cadId}`}>
            <FontAwesomeIcon icon={faEdit} />
          </LinkButton>
          <DeleteButton
            isRequesting={isDeleting}
            onDelete={() => handleDelete(item.cadId || "")}
          />
        </ButtonGroup>
      ),
    },
  ];

  return (
    <LoadingContainer isLoading={isLoading}>
      <Row>
        <Col>
          <h1>Bancos</h1>
        </Col>
      </Row>
      <Row>
        <Col className="mt-1 mb-2">
          <LinkButton variant="primary" to="/banco/new">
            Novo Banco
          </LinkButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <ShowError error={error} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ShowSuccess message={deleteSuccessMsg} />
        </Col>
      </Row>
      <Row>
        <Col>
          <TableOrCard items={itens} headers={headers} />
        </Col>
      </Row>
      {!!lastKey && (
        <Row>
          <Col className="mt-2 mb-3">
            <Button onClick={carregarMais} variant="info">
              Carregar mais
            </Button>
          </Col>
        </Row>
      )}
    </LoadingContainer>
  );
};

export default Bancos;
