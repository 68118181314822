import { Formik } from "formik";
import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as Yup from "yup";
import CitySelector from "../../../components/CitySelector";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import SelectInput from "../../../components/FormikComponents/SelectInput";
import { TextField } from "../../../components/FormikComponents/TextField";
import TextInput from "../../../components/FormikComponents/TextInput";
import { LinkButton } from "../../../components/LinkButton";
import LoaderButton from "../../../components/LoaderButton";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import { RootState } from "../../app/mainReducer";
import SerieSelector from "../../serie/selector";
import TipoOpSelector from "../../tipoop/selector";
import { Emissor } from "../reducer";
import { formActions } from "./reducer";

type EmissorFormStateProps = {
  item: Emissor;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  isConfigInterRequesting: boolean;
};
type EmissorFormDispatchProps = {
  formInit: (cadId: string) => void;
  formSave: (cad: Emissor) => void;
  configInter: (cadId: string) => void;
};
type EmissorFormProps = {
  id: string;
  onSave?: (cad: Emissor) => void;
};

class EmissorForm extends Component<
  EmissorFormProps & EmissorFormStateProps & EmissorFormDispatchProps,
  {}
> {
  state = {};

  componentDidMount() {
    this.props.formInit(this.props.id);
  }

  handleSubmit = (item) => {
    if (typeof this.props.onSave === "function") {
      this.props.onSave(item);
      return;
    }
    this.props.formSave(item);
  };

  render() {
    const isSaving = this.props.isSaving;

    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>Emissor</h1>
          </Col>
        </Row>
        {!(typeof this.props.onSave === "function") && (
          <Row>
            <Col>
              <LinkButton to="/emissor" variant="primary">
                Todos os emissores
              </LinkButton>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required(
                "Preenchimento do nome é obrigatório"
              ),
              fant: Yup.string(),
              cnpj: Yup.string(),
              ie: Yup.string(),
              iest: Yup.string(),
              im: Yup.string(),
              cnae: Yup.string(),
              regime: Yup.number(),
              estate: Yup.string(),
              city: Yup.string(),
              cep: Yup.string(),
              address: Yup.string(),
              addressNumber: Yup.string(),
              bairro: Yup.string(),
              cmun: Yup.string(),
              email: Yup.string().email(),
              phone: Yup.string(),
              modoNFe: Yup.number(),
              cnpjEscritorio: Yup.string(),
              tipoopVenda: Yup.object(),
              serie: Yup.object(),
              contaInter: Yup.string(),
            })}
          >
            {(props) => {
              const { isValid, values, setFieldValue, handleSubmit } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Row>
                        <TextInput
                          name="name"
                          label="Razão Social"
                          formik={props}
                          size={12}
                        />
                      </Row>
                      <Row>
                        <TextInput
                          name="fant"
                          label="Nome Fantasia"
                          formik={props}
                          size={12}
                        />
                      </Row>
                      <Row>
                        <MaskInput
                          name="cnpj"
                          label="CNPJ"
                          mask="99.999.999/9999-99"
                          formik={props}
                          size={6}
                        />
                        <TextInput
                          name="ie"
                          label="IE"
                          formik={props}
                          size={6}
                        />
                      </Row>
                    </Col>
                    <Col sm={2} md={2} xs={2} xl={2}>
                      <Form.Group as={Col} controlId="logo">
                        <label htmlFor="logo" className="btn">
                          Selecionar um logo
                        </label>
                        <input
                          type="file"
                          onChange={(e) =>
                            setFieldValue("newLogo", e.target.files[0])
                          }
                          style={{ visibility: "hidden" }}
                          id="logo"
                        />
                        {values.logoUrl ? (
                          <img
                            src={values.logoUrl}
                            style={{ width: "250px", height: "150px" }}
                            alt="Logo"
                          />
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <MaskInput
                      name="iest"
                      label="IE-ST"
                      mask="99999999999999"
                      formik={props}
                      size={4}
                    />
                    <MaskInput
                      name="im"
                      label="IM"
                      mask="99999999999999"
                      formik={props}
                      size={4}
                    />
                    <MaskInput
                      name="cnae"
                      label="CNAE"
                      mask="9999-9/99"
                      formik={props}
                      size={4}
                    />
                  </Row>
                  <Row>
                    <SelectInput
                      name="regime"
                      label="Regime"
                      formik={props}
                      size={12}
                    >
                      <option value={1}>Simples Nacional</option>
                      <option value={2}>
                        Simples Nacional - Excesso de sublimite de receita bruta
                      </option>
                      <option value={3}>Regime Normal</option>
                    </SelectInput>
                  </Row>
                  <Row>
                    <SelectInput
                      name="estate"
                      label="Estado"
                      formik={props}
                      size={1}
                    >
                      <option>AC</option>
                      <option>AL</option>
                      <option>AP</option>
                      <option>AM</option>
                      <option>BA</option>
                      <option>CE</option>
                      <option>DF</option>
                      <option>ES</option>
                      <option>GO</option>
                      <option>MA</option>
                      <option>MT</option>
                      <option>MS</option>
                      <option>MG</option>
                      <option>PA</option>
                      <option>PB</option>
                      <option>PR</option>
                      <option>PE</option>
                      <option>PI</option>
                      <option>RJ</option>
                      <option>RN</option>
                      <option>RS</option>
                      <option>RO</option>
                      <option>RR</option>
                      <option>SC</option>
                      <option>SP</option>
                      <option>SE</option>
                      <option>TO</option>
                    </SelectInput>
                    <Form.Group as={Col} controlId="city" xs={12} md={11}>
                      <Form.Label>Cidade:</Form.Label>
                      <CitySelector
                        uf={values.estate}
                        value={values.city}
                        onChange={(city) => {
                          setFieldValue("city", city.city);
                          setFieldValue("estate", city.estate);
                          setFieldValue("cmun", city.cmun);
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <MaskInput
                      name="zipCode"
                      label="CEP"
                      mask="99.999-999"
                      formik={props}
                      size={2}
                    />
                    <TextInput
                      name="address"
                      label="Logradouro"
                      formik={props}
                      size={9}
                    />
                    <TextInput
                      name="addressNumber"
                      label="Numero"
                      formik={props}
                      size={1}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      name="bairro"
                      label="Bairro"
                      formik={props}
                      size={8}
                    />
                    <TextInput
                      name="cmun"
                      label="Cód. Município"
                      formik={props}
                      size={4}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      name="email"
                      label="Email"
                      formik={props}
                      size={8}
                    />
                    <MaskInput
                      name="phone"
                      label="Telefone"
                      formik={props}
                      size={4}
                      mask="(99)99999-9999"
                    />
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="tipoopVenda" xs={12} md={6}>
                      <Form.Label>
                        Tipo de Operação de Venda (Padrão)
                      </Form.Label>
                      <TipoOpSelector
                        onChange={(tipoopVenda) =>
                          props.setFieldValue("tipoopVenda", tipoopVenda)
                        }
                        value={props.values.tipoopVenda}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="serie" xs={12} md={6}>
                      <Form.Label>Série (Padrão)</Form.Label>
                      <SerieSelector
                        onChange={(serie) =>
                          props.setFieldValue("serie", serie)
                        }
                        value={props.values.serie}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="certificado" xs={12} md={6}>
                      <label htmlFor="certificado">
                        Selecione o certificado(
                        {values.certificate || "-"})
                      </label>
                      <input
                        type="file"
                        onChange={(e) =>
                          setFieldValue("newCertificate", e.target.files[0])
                        }
                        className="form-control-file"
                        id="certificado"
                      />
                    </Form.Group>
                    <TextInput
                      name="certificatePass"
                      label="Senha Certificado"
                      formik={props}
                      size={6}
                    />
                  </Row>
                  <Row>
                    <SelectInput
                      name="modoNFe"
                      label="Modo NFe:"
                      formik={props}
                      size={6}
                    >
                      <option value={1}>Homologação</option>
                      <option value={0}>Produção</option>
                    </SelectInput>
                    <MaskInput
                      name="cnpjEscritorio"
                      label="CNPJ Escritório"
                      formik={props}
                      size={6}
                      mask="99.999.999/9999-99"
                    />
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={12}>
                      <h2>Integração de boletos - Inter</h2>
                    </Col>
                  </Row>
                  <Row>
                    <TextInput
                      name="contaInter"
                      label="Conta do banco Inter - Integração de boletos"
                      formik={props}
                      size={12}
                    />
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="interCrt" xs={12} md={4}>
                      <label htmlFor="interCrt">
                        Selecione o arquivo CRT da chave da API(
                        {values.interCrt || "-"})
                      </label>
                      <input
                        type="file"
                        onChange={(e) =>
                          setFieldValue("newInterCrt", e.target.files[0])
                        }
                        className="form-control-file"
                        id="interCrt"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="interKey" xs={12} md={4}>
                      <label htmlFor="interKey">
                        Selecione o arquivo KEY da chave da API(
                        {values.interKey || "-"})
                      </label>
                      <input
                        type="file"
                        onChange={(e) =>
                          setFieldValue("newInterKey", e.target.files[0])
                        }
                        className="form-control-file"
                        id="interKey"
                      />
                    </Form.Group>
                    <TextInput
                      name="interPass"
                      label="Palavra passe da chave da API"
                      formik={props}
                      size={4}
                    />
                    <TextInput
                      name="interClientId"
                      label="Client ID da API"
                      formik={props}
                      size={4}
                    />
                    <TextInput
                      name="interClientSecret"
                      label="Client Secret da API"
                      formik={props}
                      size={4}
                    />
                    <Col>
                      {this.props.id !== "new" &&
                        !!values.interClientId &&
                        !!values.interClientSecret && (
                          <LoaderButton
                            className="ml-2"
                            onClick={() =>
                              this.props.configInter(this.props.id)
                            }
                            isLoading={this.props.isConfigInterRequesting}
                            type="button"
                            title="Isso faz a baixa automatica dos boletos recebidos no Inter"
                          >
                            Configurar Webhook Inter
                          </LoaderButton>
                        )}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={12}>
                      <h2>Integração de NFS - Simpliss</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextField name="urlSimpliss" label="URL do Simpliss" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextField
                        name="usuarioSimpliss"
                        label="Usuário do Simpliss"
                      />
                    </Col>
                    <Col>
                      <TextField
                        name="senhaSimpliss"
                        label="Senha do Simpliss"
                        type="password"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                      >
                        Salvar
                      </LoaderButton>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </LoadingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    item: state.emissor.form.item,
    successMsg: state.emissor.form.successMsg,
    error: state.emissor.form.error,
    isRequesting: state.emissor.form.isRequesting,
    isSaving: state.emissor.form.isSaving,
    isConfigInterRequesting: state.emissor.form.isConfigInterRequesting,
  };
};
const dispatchProps = {
  formInit: formActions.formInit,
  formSave: formActions.formSaveRequest,
  configInter: formActions.configInter,
};

export default connect<
  EmissorFormStateProps,
  EmissorFormDispatchProps,
  EmissorFormProps
>(
  mapStateToProps,
  dispatchProps
)(EmissorForm);
