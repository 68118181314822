import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "underscore";
import InputNumber from "../../../components/InputNumber";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import PaymentParcela from "../types";
import {
  PaymentParcelaToClose,
  PaymentPayActions,
  RequestClosePayments,
} from "./reducer";

export type PaymentPayProps = {
  items: PaymentParcela[];
  itemsToClose: PaymentParcelaToClose[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isSaving: boolean;
  requestPayments: (payload: string[]) => void;
  requestClose: (payload: RequestClosePayments) => void;
  setItensToClose: (payload: PaymentParcelaToClose[]) => void;
  payments: string;
  error: string;
};

type PaymentPayState = {
  paymentsFilter: string[];
  checkedMovs: string[];
  valorCaixa: number;
  valorCartaoCredito: number;
  valorCartaoDebito: number;
};

class PaymentPay extends Component<PaymentPayProps, PaymentPayState> {
  state = {
    paymentsFilter: [],
    checkedMovs: [],
    valorCaixa: 0,
    valorCartaoCredito: 0,
    valorCartaoDebito: 0,
  };

  componentDidMount() {
    this.loadPayments();
  }

  loadPayments() {
    const payments = this.props.payments.split(",");
    this.setState({
      paymentsFilter: payments,
    });
    this.props.requestPayments(payments);
  }

  componentDidUpdate(prevProps: PaymentPayProps) {
    if (prevProps.payments !== this.props.payments) {
      this.loadPayments();
    }
  }

  filterCobs = () => {
    return _.filter(
      this.props.items,
      (cob) =>
        _.findIndex(
          this.state.paymentsFilter,
          (fil) =>
            fil.split(":")[0] * 1 === parseInt(cob.num) * 1 ||
            fil.split(":")[0] === `${cob.num}`.trim()
        ) !== -1
    );
  };

  baixarPagamentos = () => {
    // Executar a parte da baixa da pagamento
    // Ordenar as cobranças com parcelas
    const paymentsToClose = this.props.itemsToClose.filter(
      (parcela) => parcela.checked
    );
    if (paymentsToClose.length <= 0) {
      alert("Nenhum pagamento selecionado a baixar");
      return;
    }
    this.props.requestClose({
      itemsToClose: paymentsToClose,
      valorCaixa: this.state.valorCaixa,
      valorCartaoCredito: this.state.valorCartaoCredito,
      valorCartaoDebito: this.state.valorCartaoDebito,
    });
  };

  toggleCob = (parcel: PaymentParcela) => {
    const items = [...this.props.itemsToClose];
    const indexOfParcel = items.findIndex(
      (item) => item.invoiceId === parcel.invoiceId
    );
    if (indexOfParcel !== -1) {
      items[indexOfParcel].checked = !items[indexOfParcel].checked;
      this.props.setItensToClose(items);
    }
  };

  setValor = (parcel: PaymentParcela, valor: number) => {
    const items = [...this.props.itemsToClose];
    const indexOfParcel = items.findIndex(
      (item) => item.invoiceId === parcel.invoiceId
    );
    if (indexOfParcel !== -1) {
      items[indexOfParcel].valor_baixado = valor;
      this.props.setItensToClose(items);
    }
  };

  render() {
    const headers = [
      {
        label: " ",
        render: (item: PaymentParcelaToClose) => (
          <Form.Group>
            <Form.Check
              type="checkbox"
              checked={item.checked}
              onChange={() => this.toggleCob(item)}
              label=""
            />
          </Form.Group>
        ),
      },
      {
        label: "#",
        render: (item: PaymentParcelaToClose) =>
          `${item.num}/${item.numparcela}`,
      },
      {
        label: "Emissão",
        render: (item: PaymentParcelaToClose) =>
          moment(item.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Cliente",
        render: (item: PaymentParcelaToClose) =>
          `${item.dest && item.dest.name}`,
      },
      {
        label: "Valor",
        render: (item: PaymentParcelaToClose) => formatNumber(item.valor, 2),
      },
      {
        label: "Vencimento",
        render: (item: PaymentParcelaToClose) =>
          moment(item.vencimento).format("DD/MM/YYYY"),
      },
      {
        label: "Valor Pago",
        render: (item: PaymentParcelaToClose) => (
          <InputNumber
            value={item.valor_baixado}
            onChange={(valor) => this.setValor(item, valor)}
          />
        ),
      },
    ];

    const valorTotalPago =
      this.props.itemsToClose &&
      this.props.itemsToClose.reduce(
        (p, item) => p + (item.checked && (item.valor_baixado || 0)),
        0
      );

    const restante =
      this.state.valorCaixa +
      this.state.valorCartaoCredito +
      this.state.valorCartaoDebito -
      valorTotalPago;

    return (
      <>
        <Row>
          <Col>
            <h2>Baixa de Pagamentos</h2>
          </Col>
        </Row>
        {this.props.error && (
          <Row>
            <Col>
              <ShowError error={this.props.error} />
            </Col>
          </Row>
        )}
        <Row>
          <LoadingContainer isLoading={this.props.isRequesting}>
            <TableOrCard headers={headers} items={this.props.itemsToClose} />
            <Row>
              <Col className="d-flex flex-row-reverse">
                <Col
                  className="d-flex flex-column"
                  style={{ maxWidth: "35ch" }}
                >
                  <h4>
                    Valor Total a pagar {formatNumber(valorTotalPago, 2, true)}
                  </h4>
                  <Form.Group>
                    <Form.Label>Valor pago em caixa</Form.Label>
                    <InputNumber
                      value={this.state.valorCaixa}
                      onChange={(valorCaixa) => this.setState({ valorCaixa })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Valor pago em cartão de crédito</Form.Label>
                    <InputNumber
                      value={this.state.valorCartaoCredito}
                      onChange={(valorCartaoCredito) =>
                        this.setState({ valorCartaoCredito })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Valor pago em cartão de débito</Form.Label>
                    <InputNumber
                      value={this.state.valorCartaoDebito}
                      onChange={(valorCartaoDebito) =>
                        this.setState({ valorCartaoDebito })
                      }
                    />
                  </Form.Group>
                  {restante > 0 && (
                    <h4>Troco {formatNumber(restante, 2, true)}</h4>
                  )}
                  {restante < 0 && (
                    <h4>
                      Restante a pagar{" "}
                      {formatNumber(Math.abs(restante), 2, true)}
                    </h4>
                  )}
                  <Button
                    disabled={restante < 0}
                    variant="primary"
                    onClick={this.baixarPagamentos}
                  >
                    Baixas as cobranças selecionadas
                  </Button>
                </Col>
              </Col>
            </Row>
          </LoadingContainer>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.payment.pay.items,
    itemsToClose: state.payment.pay.itemsToClose,
    Count: state.payment.pay.Count,
    lastKey: state.payment.pay.lastKey,
    isRequesting: state.payment.pay.isRequesting,
    isSaving: state.payment.pay.isSaving,
    error: state.payment.pay.error,
  };
};
const mapDispatchToProps = {
  requestPayments: PaymentPayActions.requestPayments,
  requestClose: PaymentPayActions.requestClose,
  setItensToClose: PaymentPayActions.setItemsToClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPay);
