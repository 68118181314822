import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderButton from "../../components/LoaderButton";
import { salesActions } from "./reducer";
import { Sale } from "./types";

type CancelaNfeButtonProps = {
  cancelanfeRequesting: (payload: {
    sale: Sale;
    onSuccessDispatch?: () => void;
  }) => void;
  isNfeCanceling: boolean;
  sale: Sale;
  onSuccessDispatch?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  variant?: string;
  title?: string;
};

type CancelaNfeButtonState = {};

class CancelaNfeButton extends Component<
  CancelaNfeButtonProps,
  CancelaNfeButtonState
> {
  handleNFeRequest = (e) => {
    e.preventDefault();
    if (!!this.props.sale) {
      this.props.cancelanfeRequesting({
        sale: this.props.sale,
        onSuccessDispatch: this.props.onSuccessDispatch,
      });
    }
  };

  render() {
    return (
      <LoaderButton
        {...this.props}
        isLoading={this.props.isNfeCanceling}
        disabled={this.props.disabled || !this.props.sale}
        onClick={this.handleNFeRequest}
      >
        {this.props.children}
      </LoaderButton>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isNfeCanceling: state.sales.list.isNfeCanceling,
  };
};
const mapDispatchToProps = {
  cancelanfeRequesting: salesActions.cancelanfeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelaNfeButton);
