import React from "react";
import InputNumber from "../InputNumber";
import { FormikProps } from "formik";

type NumberInputProps = {
  name: string;
  label: string;
  size: number;
  formik: FormikProps<any>;
  disabled?: boolean;
  decimalPlaces?: number;
  changeCallback?: (objectChange: any) => void;
};

const NumberInput: React.FC<NumberInputProps> = (props) => (
  <div className={`form-group col-12 col-md-${props.size}`}>
    <label htmlFor={props.name}>{props.label}</label>
    <InputNumber
      id={props.name}
      value={props.formik.values[props.name]}
      onChange={(valor) => {
        props.formik.setFieldValue(props.name, valor);

        typeof props.changeCallback === "function" &&
          props.changeCallback({ [props.name]: valor });
      }}
      disabled={props.disabled}
      onBlur={props.formik.handleBlur}
      decimalPlaces={props.decimalPlaces === 0 ? 0 : props.decimalPlaces || 2}
      className={
        props.formik.errors[props.name] && props.formik.touched[props.name]
          ? "form-control text-input error is-invalid"
          : "form-control text-input"
      }
      placeholder={props.label}
    />
    {props.formik.errors[props.name] && props.formik.touched[props.name] && (
      <div className="input-feedback">{props.formik.errors[props.name]}</div>
    )}
  </div>
);

export default NumberInput;
