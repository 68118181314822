import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  TIPOOP_FORM_INIT,
  TIPOOP_SAVE_REQUESTING,
  formInitError,
  formInitSuccess,
  formSuccess,
  formError,
} from "./actions";
import { CAD_TYPE, Tipoop } from "../actions";
import executeApiRequest from "../../../misc/executeApiRequest";

const newTipoop = (): Tipoop => {
  return {
    cadType: CAD_TYPE,
    cod: "",
    name: "",
    type: 2,
    invoiceAuto: 1,
    modoNfe: 1,
  };
};

function* tipoopInitWorker({ payload }: any) {
  try {
    if (payload === "new") {
      yield put(formInitSuccess(newTipoop()));
    } else {
      const res = yield call(
        executeApiRequest,
        `/tipoop/${payload}`,
        "GET",
        {},
        {}
      );
      yield put(formInitSuccess(res));
    }
  } catch (error) {
    yield put(formInitError(error));
  }
}

function* tipoopSaveWorker({ payload }: any) {
  try {
    if (payload.cadId) {
      // Update
      const res = yield call(
        executeApiRequest,
        `/tipoop/${payload.cadId}`,
        "PUT",
        payload
      );
      yield put(formSuccess(res));
    } else {
      const res = yield call(executeApiRequest, "/tipoop", "POST", payload);
      yield put(formSuccess(res));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(formError(error.response.data));
  }
}

export default function* tipoopFormSaga() {
  yield all([
    takeLatest(TIPOOP_FORM_INIT, tipoopInitWorker),
    takeLatest(TIPOOP_SAVE_REQUESTING, tipoopSaveWorker),
  ]);
}
