import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Image } from "react-bootstrap";
import { Link } from "@reach/router";
import LoaderButton from "../../components/LoaderButton";
import ShowError from "../../components/ShowError";

import "./index.css";
import logo from "../../assets/logo-completo.png";
import { LoginActionPayload, loginActions } from "./reducer";

type LoginProps = {
  error: any;
  isRequesting: boolean;
  pageInit: () => void;
  loginRequest: (payload: LoginActionPayload) => void;
};

type LoginState = {
  email: string;
  password: string;
};

class Login extends Component<LoginProps, LoginState> {
  state = {
    email: "",
    password: "",
  };

  handleLogin = (e) => {
    e.preventDefault();
    this.props.loginRequest({
      email: this.state.email,
      password: this.state.password,
    });
  };

  render() {
    const validations = [
      this.state.email && this.state.email.trim().length > 3,
      this.state.password && this.state.password.trim().length >= 6,
    ];

    const validForm = validations.reduce((a, p) => a && p);

    return (
      <>
        <Form onSubmit={this.handleLogin} className="Login bg-light">
          <div className="logo">
            <Image src={logo} width={150} height={40} />
          </div>
          <ShowError
            error={
              typeof this.props.error === "string"
                ? this.props.error
                : "Usuário ou senha incorretos!"
            }
          />
          <Form.Group controlId="login">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              autoFocus
              name="email"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              placeholder="Ex: fulano@free.org"
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              placeholder="Senha"
            />
          </Form.Group>
          <Link to="/forgot">Esqueci a senha</Link>
          <LoaderButton
            variant="primary"
            type="submit"
            isLoading={this.props.isRequesting}
            disabled={!validForm}
            className="button"
          >
            Entrar
          </LoaderButton>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    error: state.login.error,
    isRequesting: state.login.isRequesting,
  };
};

const dispatchProps = {
  pageInit: loginActions.init,
  loginRequest: loginActions.loginRequest,
};

export default connect(mapStateToProps, dispatchProps)(Login);
