// component do modulo cond
import React, { Component } from "react";
import { connect } from "react-redux";
import { pageInit, condDeleteRequest } from "./actions";
import LoadingContainer from "../../components/LoadingContainer";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import TableOrCard from "../../components/TableOrCard";
import DeleteButton from "../../components/DeleteButton";
import { formatNumber } from "../../misc/formatters";
import { Link } from "@reach/router";

type CondProps = {
  pageInit: () => void;
  deleteRequest: (item: any) => void;
  isDeleting: boolean;
  isRequesting: boolean;
  items: any[];
};

class Cond extends Component<CondProps, {}> {
  state = {};

  componentDidMount() {
    this.props.pageInit();
  }

  handleDelete = (item: any) => {
    this.props.deleteRequest(item.movId);
  };

  render() {
    const headers = [
      {
        label: "Numero",
        dataIndex: "num",
      },
      {
        label: "Cliente",
        render: (item: any) => item.dest && item.dest.name,
      },
      {
        label: "Valor ($)",
        render: (item: any) => formatNumber(item.valor, 2),
      },
      {
        label: "Status",
        render: (item: any) =>
          item.items.reduce((p: any, c: any) => p && c.closed, true)
            ? "Devolvido"
            : item.items.reduce((p: any, c: any) => p || c.closed, false)
            ? "Devolvido parcialmente"
            : "Em aberto",
      },
      {
        label: "Ações",
        render: (item: any) => (
          <ButtonGroup>
            <Button as={Link} to={`/cond/${item.movId}`} variant="info">
              <i className="fas fa-edit" />
            </Button>
            <DeleteButton
              isRequesting={this.props.isDeleting}
              onDelete={() => this.handleDelete(item)}
            />
          </ButtonGroup>
        ),
      },
    ];

    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Condicionais</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <Button as={Link} to="/cond/new" variant="primary">
              Incluir
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={headers} items={this.props.items} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.cond.list.items,
    Count: state.cond.list.Count,
    lastKey: state.cond.list.lastKey,
    isRequesting: state.cond.list.isRequesting,
    isDeleting: state.cond.list.isDeleting,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    pageInit: () => dispatch(pageInit()),
    deleteRequest: (movId: string) => dispatch(condDeleteRequest(movId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cond);
