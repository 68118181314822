export const PROD_INIT = "PROD_INIT";
export const PROD_REQUESTING = "PROD_REQUESTING";
export const PROD_SUCCESS = "PROD_SUCCESS";
export const PROD_ERROR = "PROD_ERROR";
export const CAD_TYPE = "CAD_PROD";
export const PROD_DELETE_REQUESTING = "PROD_DELETE_REQUESTING";
export const PROD_DELETE_SUCCESS = "PROD_DELETE_SUCCESS";
export const PROD_DELETE_ERROR = "PROD_DELETE_ERROR";
export const PROD_IMPORTAR_INIT = "PROD_IMPORTAR_INIT";
export const PROD_IMPORTAR_REQUESTING = "PROD_IMPORTAR_REQUESTING";
export const PROD_IMPORTAR_SUCCESS = "PROD_IMPORTAR_SUCCESS";
export const PROD_IMPORTAR_ERROR = "PROD_IMPORTAR_ERROR";

export type Prod = {
  cadType: "CAD_PROD";
  cadId?: string;
  userId?: string;
  cod: string;
  name: string;
  ean: number;
  ncm: string;
  cest: string;
  unitType: string;
  preco: number;
  orig: number;
  quantidadeAtual: number;
  custoMedio: number;
  serialCreate?: any;
};

export function pageInit() {
  return {
    type: PROD_INIT,
  };
}

export function prodRequest() {
  return {
    type: PROD_REQUESTING,
  };
}

export function prodSuccess(payload) {
  return {
    type: PROD_SUCCESS,
    payload,
  };
}

export function prodError(error) {
  return {
    type: PROD_ERROR,
    error,
  };
}

export function prodDeleteRequest(cadId) {
  return {
    type: PROD_DELETE_REQUESTING,
    payload: cadId,
  };
}

export function prodDeleteSuccess() {
  return {
    type: PROD_DELETE_SUCCESS,
  };
}

export function prodDeleteError(error) {
  return {
    type: PROD_DELETE_ERROR,
    error,
  };
}

export function prodImportarRequest(payload) {
  return {
    type: PROD_IMPORTAR_REQUESTING,
    payload,
  };
}

export function prodImportarSuccess(payload) {
  return {
    type: PROD_IMPORTAR_SUCCESS,
    payload,
  };
}

export function prodImportarError(error) {
  return {
    type: PROD_IMPORTAR_ERROR,
    error,
  };
}

export function prodImportarInit() {
  return {
    type: PROD_IMPORTAR_INIT,
  };
}
