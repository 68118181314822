import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  destSelectorError,
  destSelectorSuccess,
  DEST_SELECTOR_REQUESTING,
} from "./actions";
import executeApiRequest from "../../../misc/executeApiRequest";

function destSearchCall(cadIdStart: string) {
  return executeApiRequest(
    "/dest",
    "GET",
    {},
    {
      queryStringParameters: {
        cadIdStart,
      },
    }
  );
}

function* destSearchWorker({ payload }: any) {
  try {
    const searchTerm = `${payload}`
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ /g, "_")
      .replace(/[^A-Z0-9 _]/g, "");
    const res = yield call(destSearchCall, searchTerm);
    console.log("Searched", res);
    yield put(destSelectorSuccess(res));
  } catch (error) {
    yield put(destSelectorError(error));
  }
}

export default function* destSelectorSaga() {
  yield all([takeLatest(DEST_SELECTOR_REQUESTING, destSearchWorker)]);
}
