import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Image } from "react-bootstrap";
import * as uuid from "uuid";
import ShowError from "../../components/ShowError";
import LoaderButton from "../../components/LoaderButton";

import logo from "../../assets/logo-completo.png";
import "./index.css";
import { RootState } from "../app/mainReducer";
import { loginActions } from "./reducer";
import { Link } from "@reach/router";
import { gtag } from "../../misc/gtag";

type SignUpProps = {
  signupRequesting: boolean;
  signupSuccessMsg: string;
  signupError: any;
  signupRequest: (objSignUp: any) => void;
  uid: string;
};

type SignUpState = {
  email: string;
  password: string;
  confirmPassword: string;
  aceite: boolean;
};

class SignUp extends Component<SignUpProps, SignUpState> {
  state = {
    email: "",
    password: "",
    confirmPassword: "",
    aceite: false,
  };

  handleSignup = (e) => {
    e.preventDefault();
    const orgId = this.props.uid || uuid.v1();
    // Send signup event to google
    gtag("event", "signup", { send_to: "AW-845520796/90xqCNzGjaADEJy_lpMD" });
    this.props.signupRequest({
      email: this.state.email,
      password: this.state.password,
      orgId,
    });
  };

  render() {
    const validations = [
      this.state.email && this.state.email.trim().length > 3,
      this.state.password && this.state.password.trim().length >= 6,
      this.state.password === this.state.confirmPassword,
      this.state.aceite === true,
    ];

    const validForm = validations.reduce((a, p) => a && p);

    return (
      <Form onSubmit={this.handleSignup} className="Signup bg-light">
        <div className="logo">
          <Image src={logo} width={150} height={40} />
        </div>
        <ShowError error={this.props.signupError} />
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            placeholder="Ex: fulano@free.org"
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Senha</Form.Label>
          <Form.Control
            type="password"
            value={this.state.password}
            onChange={(e) => this.setState({ password: e.target.value })}
            placeholder="Senha"
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Confirme a Senha</Form.Label>
          <Form.Control
            type="password"
            value={this.state.confirmPassword}
            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
            placeholder="Confirmação"
          />
        </Form.Group>
        <Form.Group controlId="aceite">
          <Form.Check
            type="checkbox"
            checked={this.state.aceite}
            onChange={() => this.setState({ aceite: !this.state.aceite })}
            label="Declaro que li e concordo com os termos de uso e politica de privacidade"
          />
          <Link to={`/termouso`} target="_blank">
            Termo de uso
          </Link>{" "}
          <Link to={`/politicaprivacidade`} target="_blank">
            Política de Privacidade
          </Link>
        </Form.Group>
        <LoaderButton
          variant="primary"
          isLoading={this.props.signupRequesting}
          disabled={!validForm}
          type="submit"
          className="button pull-right"
        >
          Criar conta
        </LoaderButton>
      </Form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    signupRequesting: state.login.signupRequesting,
    signupSuccessMsg: state.login.signupSuccessMsg,
    signupError: state.login.signupError,
  };
};

const dispatchProps = {
  signupRequest: loginActions.signupRequest,
};

export default connect(mapStateToProps, dispatchProps)(SignUp);
