// saga do modulo cond
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  COND_INIT,
  COND_DELETE_REQUESTING,
  MOV_TYPE,
  condSuccess,
  condError,
  condDeleteSuccess,
  condDeleteError,
  pageInit,
} from "./actions";
import formSaga from "./condForm/saga";
import closeSaga from "./condClose/saga";
import paneSaga from "./condPane/saga";
import viewSaga from "./condView/saga";
import executeApiRequest from "../../misc/executeApiRequest";

interface CondItem {
  prod: Record<string, any>;
  qtd: number;
  valor: number;
  total: number;
}

export interface Cond {
  movId?: string;
  num: string;
  dest: Record<string, any>;
  items: CondItem[];
}

export interface CondRes {
  items: Cond[];
  Count: number;
  ScannedCount: number;
  lastKey?: string;
}

function condCall(lastKey: string | null = null) {
  return executeApiRequest<CondRes>(
    "/cond",
    "GET",
    {},
    {
      queryStringParameters: {
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* condWorker() {
  try {
    const res: CondRes = yield call(condCall);
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: CondRes = yield call(condCall, res.lastKey);
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(condSuccess(res));
  } catch (error) {
    yield put(condError(error));
  }
}

function* condDeleteWorker({ payload }: any) {
  try {
    yield call(
      executeApiRequest,
      `/cond/${payload}`,
      "DELETE",
      {},
      {
        queryStringParameters: {
          movType: MOV_TYPE,
        },
      }
    );
    yield put(condDeleteSuccess());
    yield put(pageInit());
  } catch (error) {
    yield put(condDeleteError(error));
  }
}

export default function* invoiceSaga() {
  yield all([
    takeLatest(COND_INIT, condWorker),
    takeLatest(COND_DELETE_REQUESTING, condDeleteWorker),
    formSaga(),
    closeSaga(),
    paneSaga(),
    viewSaga(),
  ]);
}
