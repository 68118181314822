import { PayloadAction } from "@reduxjs/toolkit";
import { PriceListObject, subcsriptionActions } from "./reducer";
import { put } from "redux-saga-test-plan/matchers";
import { all, call, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../misc/executeApiRequest";
import { loginActions } from "../login/reducer";
import { authUserCall } from "../login/saga";

type StripeConfigResponse = {
  prices: PriceListObject;
  publicKey: string;
};

function configCall() {
  return executeApiRequest<StripeConfigResponse>(
    `/stripe_config`,
    "GET",
    {},
    {}
  );
}

function* configWorker() {
  try {
    const res: StripeConfigResponse = yield call(configCall);
    yield put(subcsriptionActions.configSuccess(res));
  } catch (error) {
    console.log("error ocurred on stripe config", error);
    yield put(subcsriptionActions.configError(error));
  }
}

function subscriptionCall(planId: string) {
  return executeApiRequest(`/stripe_subscription`, "POST", { planId });
}

function* subscriptionWorker({ payload }: PayloadAction<string>) {
  try {
    const res = yield call(subscriptionCall, payload);
    console.log("res subscription", res);
    yield put(subcsriptionActions.subscriptionSuccess(res));
    yield put(subcsriptionActions.subscriptionsRequest());
    const user = yield call(authUserCall);
    yield put(loginActions.orgRequest(user));
  } catch (error) {
    console.log("error ocurred on subcription creation", error);
    yield put(subcsriptionActions.subscriptionError(error));
  }
}

function subscriptionsCall() {
  return executeApiRequest("/stripe_subscriptions", "GET", {}, {});
}

function* subscriptionsWorker() {
  try {
    const res = yield call(subscriptionsCall);
    yield put(subcsriptionActions.subscriptionsSuccess(res.data));
    const activeSubscription = res.data.find(
      (sub) => sub.status === "active" || sub.status === "past_due"
    );
    yield put(
      subcsriptionActions.setActiveSubscription(activeSubscription || null)
    );
  } catch (error) {
    console.log("error ocurred on subcription creation", error);
    yield put(subcsriptionActions.subscriptionsError(error));
  }
}

function subscriptionCancelCall(subscriptionId) {
  return executeApiRequest(
    `/stripe_subscription/${subscriptionId}`,
    "DELETE",
    {},
    {}
  );
}

function* subscriptionCancelWorker({ payload }: PayloadAction<string>) {
  try {
    const res = yield call(subscriptionCancelCall, payload);
    yield put(subcsriptionActions.subscriptionCancelSuccess(res));
  } catch (error) {
    yield put(subcsriptionActions.subscriptionCancelError(error));
  }
}

function savePaymentMethodCall(payment_method: string) {
  return executeApiRequest(`/stripe_setpm`, "POST", { payment_method });
}

function* savePaymentMethodWorker({ payload }: PayloadAction<string>) {
  try {
    const res = yield call(savePaymentMethodCall, payload);
    console.log("res subscription", res);
  } catch (error) {
    console.log("error ocurred on save payment Method", error);
  }
}

export default function* subscriptionSaga() {
  yield all([
    takeLatest("subscription/configRequest", configWorker),
    takeLatest("subscription/subscriptionRequest", subscriptionWorker),
    takeLatest("subscription/savePaymentMethod", savePaymentMethodWorker),
    takeLatest("subscription/subscriptionsRequest", subscriptionsWorker),
    takeLatest(
      "subscription/subscriptionCancelRequest",
      subscriptionCancelWorker
    ),
  ]);
}
