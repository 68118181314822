import React from "react";
import ReactDOM from "react-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./modules/app/custom-bootstrap.scss";
import "./index.css";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";
import { Amplify, Auth } from "aws-amplify";
import config from "./config";
import { initSentry } from "./misc/errorLib";

initSentry();

Amplify.configure({
  Auth: {
    madatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "sdcks",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return {
            orgId: (await Auth.currentAuthenticatedUser()).attributes[
              "custom:orgId"
            ],
          };
          // Alternatively, with Cognito User Pools use this:
          // return { (await Auth.currentSession()).idToken.jwtToken }
        },
      },
      {
        name: "sdcks_unauth",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "nfe",
        endpoint: config.apiNFe.URL,
        region: config.apiNFe.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
