import { PayloadAction } from "@reduxjs/toolkit";
import { invoiceFormActions } from "./reducer";
import { Invoice, InvoiceParcela } from "./../actions";
// saga do form do modulo invoice
import { put, all, call, takeLatest, select } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import _ from "underscore";

const getDefaultEmit = (state) => state.login.defaultEmit;

const newInvoice = (emissor = null): Invoice => {
  return {
    num: "",
    emission: new Date(),
    emissor,
    dest: null,
    parcelas: [],
    nparcelas: 0,
    orgId: "",
    invoiceId: "",
    lsi1: "",
    lsi2: "",
    lsi3: "",
    lsi4: "",
    valor: 0,
    banco: null,
  };
};

function* invoiceRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "new") {
      const defaultEmit = yield select(getDefaultEmit);
      yield put(invoiceFormActions.requestSuccess(newInvoice(defaultEmit)));
    } else {
      const invoiceIdSep = `${payload}`.split(".");
      if (invoiceIdSep.length < 2) {
        throw new Error(`Id selecionado é inválido`);
      }

      const res: InvoiceParcela[] = yield call(
        executeApiRequest,
        `/invoice/${invoiceIdSep[0]}.${invoiceIdSep[1]}`,
        "GET",
        {},
        {}
      );
      const uuid = invoiceIdSep.pop();
      const filteredRet = _.filter(
        res,
        (parcela) => `${parcela.invoiceId}`.split(".").pop() === uuid
      );
      if (filteredRet.length < 1) {
        throw new Error(`Id selecionado não foi encontrado!`);
      }
      const invoice: Invoice = {
        dest: filteredRet[0].dest,
        emission: filteredRet[0].emission,
        emissor: filteredRet[0].emissor,
        nparcelas: filteredRet.length,
        version: filteredRet[0].version,
        orgId: filteredRet[0].orgId,
        num: filteredRet[0].num,
        invoiceId: filteredRet[0].invoiceId,
        lsi1: filteredRet[0].lsi1,
        lsi2: filteredRet[0].lsi2,
        lsi3: filteredRet[0].lsi3,
        lsi4: filteredRet[0].lsi4,
        parcelas: filteredRet,
        valor: filteredRet.reduce((p, c) => p + c.valor, 0),
        banco: filteredRet[0].banco,
      };

      yield put(invoiceFormActions.requestSuccess(invoice));
    }
  } catch (error) {
    yield put(invoiceFormActions.requestError(error));
  }
}

function* invoiceSaveWorker({ payload }: PayloadAction<Invoice>) {
  try {
    // Transform from Invoice to InvoiceParcelas[]
    // Request save of invoice to ctrl_invoice
    // Request removal of parcelas
    // on update

    payload.num = `${payload.num}`.trim();
    const invoiceParcelas = payload.parcelas.map((parcela) => {
      return {
        ...parcela,
        dest: payload.dest,
        emission: payload.emission,
        emissor: payload.emissor,
        banco: payload.banco,
        num: payload.num,
        serialCreate: payload.serialCreate,
      };
    });
    console.log("mov to save", invoiceParcelas);
    if (payload.invoiceId) {
      // Update
      const res: InvoiceParcela[] = yield call(
        executeApiRequest,
        `/invoice/${payload.invoiceId}`,
        "PUT",
        invoiceParcelas
      );
      console.log("res received", res);
      const invoice: Invoice = {
        dest: res[0].dest,
        emission: res[0].emission,
        emissor: res[0].emissor,
        nparcelas: res.length,
        version: res[0].version,
        orgId: res[0].orgId,
        num: res[0].num,
        invoiceId: res[0].invoiceId,
        lsi1: res[0].lsi1,
        lsi2: res[0].lsi2,
        lsi3: res[0].lsi3,
        lsi4: res[0].lsi4,
        parcelas: res,
        valor: res.reduce((p, c) => p + c.valor, 0),
        banco: res[0].banco,
      };
      yield put(invoiceFormActions.saveSuccess(invoice));
    } else {
      const res: InvoiceParcela[] = yield call(
        executeApiRequest,
        "/invoice",
        "POST",
        invoiceParcelas
      );
      console.log("res", res);
      const invoice: Invoice = {
        dest: res[0].dest,
        emission: res[0].emission,
        emissor: res[0].emissor,
        nparcelas: res.length,
        version: res[0].version,
        orgId: res[0].orgId,
        num: res[0].num,
        invoiceId: res[0].invoiceId,
        lsi1: res[0].lsi1,
        lsi2: res[0].lsi2,
        lsi3: res[0].lsi3,
        lsi4: res[0].lsi4,
        parcelas: res,
        valor: res.reduce((p, c) => p + c.valor, 0),
        banco: res[0].banco,
      };
      yield put(invoiceFormActions.saveSuccess(invoice));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(invoiceFormActions.saveError(error.response.data));
  }
}

export default function* invoiceFormSaga() {
  yield all([
    takeLatest("invoiceForm/requestItem", invoiceRequestWorker),
    takeLatest("invoiceForm/saveRequest", invoiceSaveWorker),
  ]);
}
