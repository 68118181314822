import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderButton from "../../components/LoaderButton";
import { salesActions } from "./reducer";
import { Sale } from "./types";

type ComponentStateProps = {
  isEnviarnfeRequesting: boolean;
};
type ComponentDispatchProps = {
  enviarnfeRequest: (payload: {
    sale: Sale;
    onSuccessDispatch?: () => void;
  }) => void;
};

type EnviarNfeButtonProps = {
  sale: Sale;
  onSuccessDispatch?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  variant?: string;
  title?: string;
};
type EnviarNfeButtonState = {};

class EnviarNfeButton extends Component<
  EnviarNfeButtonProps & ComponentStateProps & ComponentDispatchProps,
  EnviarNfeButtonState
> {
  handleNFeRequest = (e) => {
    e.preventDefault();
    if (!!this.props.sale) {
      this.props.enviarnfeRequest({
        sale: this.props.sale,
        onSuccessDispatch: this.props.onSuccessDispatch,
      });
    }
  };

  render() {
    return (
      <LoaderButton
        {...this.props}
        isLoading={this.props.isEnviarnfeRequesting}
        disabled={this.props.disabled || !this.props.sale}
        onClick={this.handleNFeRequest}
      >
        {this.props.children}
      </LoaderButton>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isEnviarnfeRequesting: state.sales.list.isEnviarnfeRequesting,
  };
};
const mapDispatchToProps = {
  enviarnfeRequest: salesActions.enviarnfeRequest,
};

export default connect<
  ComponentStateProps,
  ComponentDispatchProps,
  EnviarNfeButtonProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EnviarNfeButton);
