import { AWSRes } from "./../../redux/types";
import { PayloadAction } from "@reduxjs/toolkit";
// reducer do modulo purchases
import formReducer from "./purchasesForm/reducer";
import { combineReducers } from "redux";
import { Purchase } from "./types";
import { createSlice } from "@reduxjs/toolkit";

type PurchaseInitialState = {
  items: Purchase[];
  Count: number;
  lastKey: Record<string, string>;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isDeleting: boolean;
  lastPurchaseSearch: PurchaseRequestAction;
};

const initialState: PurchaseInitialState = {
  items: [],
  Count: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
  lastPurchaseSearch: null,
};

export type PurchaseRequestAction = {
  tipoPesquisa: "DATA" | "NUM" | "CLASSIFICACAO";
  termoPesquisa: string;
  tipoFiltro: "=" | "BETWEEN" | "START";
};

const purchasesSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {
    requestPurchases: (
      state,
      { payload }: PayloadAction<PurchaseRequestAction>
    ) => {
      state.items = [];
      state.Count = 0;
      state.lastKey = null;
      state.isRequesting = true;
      state.isDeleting = false;
      state.lastPurchaseSearch = payload;
    },
    purchasesSuccess: (state, { payload }: PayloadAction<AWSRes<Purchase>>) => {
      state.items = payload.items;
      state.Count = payload.Count;
      state.lastKey = payload.lastKey;
      state.isRequesting = false;
    },
    purchasesError: (state, { payload }: PayloadAction<string>) => {
      state.isRequesting = false;
      state.error = payload;
    },
    requestDelete: (state, _: PayloadAction<Purchase>) => {
      state.isDeleting = true;
      state.successMsg = "";
      state.error = "";
    },
    deleteSuccess: (state) => {
      state.isDeleting = false;
      state.successMsg = "Compra removida com sucesso!";
    },
    deleteError: (state, { payload }: PayloadAction<string>) => {
      state.isDeleting = false;
      state.error = payload;
    },
  },
});

export const purchasesActions = purchasesSlice.actions;

export default combineReducers({
  list: purchasesSlice.reducer,
  form: formReducer.reducer,
});
