import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { Form } from "react-bootstrap";

type CheckInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
};

export const CheckField: React.FC<CheckInputProps> = ({
  label,
  children,
  ...props
}) => {
  const [field, { error, touched }] = useField(props);
  return (
    <>
      <Form.Check
        {...field}
        label={label}
        value={field.value}
        isInvalid={touched && error ? true : false}
      >
        {children}
      </Form.Check>
      {touched && error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};
