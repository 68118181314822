import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderButton from "../../components/LoaderButton";
import ShowError from "../../components/ShowError";
import ShowSuccess from "../../components/ShowSuccess";
import { RootState } from "../app/mainReducer";
import { whatsappActions } from "./reducer";
import * as Yup from "yup";
import { Button, Col, Row } from "react-bootstrap";
import { TextField } from "../../components/FormikComponents/TextField";
import LoadingAnimation from "../../components/LoadingAnimation";
import { CheckField } from "../../components/FormikComponents/CheckField";

const WhatsappTemplate: React.FC<{ situation: string; title: string }> = ({
  situation,
  title,
}) => {
  const isWhatsappAuthenticated = useSelector(
    (state: RootState) => state.login.org?.isWhatsappAuthenticated
  );

  const isSavingTemplate = useSelector(
    (state: RootState) => state.whats.isSavingTemplate
  );
  const isTemplateLoading = useSelector(
    (state: RootState) => state.whats.isTemplateLoading
  );
  const whatsTemplates = useSelector(
    (state: RootState) => state.whats.whatsTemplates
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(whatsappActions.loadTemplate(situation));
  }, [situation, dispatch]);

  if (!isWhatsappAuthenticated) {
    return null;
  }

  if (isTemplateLoading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <Formik
        onSubmit={(values) => {
          console.log("Enviar mensagem de teste", values);
          dispatch(
            whatsappActions.saveTemplate({
              situation,
              templateMsg: values.templateMsg,
              isActivated: values.isActivated,
            })
          );
        }}
        initialValues={{ ...whatsTemplates[situation] }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          templateMsg: Yup.string().required("Texto deve ser preenchido"),
        })}
      >
        {({ isValid, values, setFieldValue }) => (
          <Form className="bg-light rounded border border-dark py-2 px-1 my-1">
            <Row className="mt-2">
              <Col>
                <h3>{title}</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={9}>
                <TextField
                  name="templateMsg"
                  label="Modelo de Mensagem a enviar"
                  as="textarea"
                />
              </Col>
              <Col xs={12} md={3} className="d-flex flex-column">
                Lista de váriaveis
                <Button
                  className="text-start my-1"
                  onClick={() =>
                    setFieldValue(
                      "templateMsg",
                      `${values.templateMsg || ""} \${dest.name}`
                    )
                  }
                >
                  dest.name: Nome do Cliente
                </Button>
                <Button
                  className="text-start my-1"
                  onClick={() =>
                    setFieldValue(
                      "templateMsg",
                      `${values.templateMsg || ""} \${valor}`
                    )
                  }
                >
                  valor: Valor do titulo
                </Button>
                <Button
                  className="text-start my-1"
                  onClick={() =>
                    setFieldValue(
                      "templateMsg",
                      `${values.templateMsg || ""} \${referente}`
                    )
                  }
                >
                  referente: Referencia do titulo
                </Button>
                <Button
                  className="text-start my-1"
                  onClick={() =>
                    setFieldValue(
                      "templateMsg",
                      `${values.templateMsg || ""} \${vencimento}`
                    )
                  }
                >
                  vencimento: Vencimento do titulo
                </Button>
                <Button
                  className="text-start my-1"
                  onClick={() =>
                    setFieldValue(
                      "templateMsg",
                      `${values.templateMsg || ""} \${emission}`
                    )
                  }
                >
                  emission: Data de emissão do titulo
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <CheckField name="isActivated" label="Ativo" />
              </Col>
            </Row>
            <Row>
              <Col>
                <LoaderButton
                  isLoading={isSavingTemplate}
                  disabled={!isValid}
                  type="submit"
                >
                  Salvar
                </LoaderButton>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

const WhatsappTeste: React.FC<{}> = () => {
  const isWhatsappAuthenticated = useSelector(
    (state: RootState) => state.login.org?.isWhatsappAuthenticated
  );

  const isSendingTest = useSelector(
    (state: RootState) => state.whats.isSendingTest
  );

  const dispatch = useDispatch();

  if (!isWhatsappAuthenticated) {
    return null;
  }

  return (
    <>
      <Formik
        onSubmit={(values) => {
          console.log("Enviar mensagem de teste", values);
          dispatch(
            whatsappActions.sendTest({
              to: `55${values.to}`.replace(/[^0-9]/g, ""),
              text: values.text,
            })
          );
        }}
        initialValues={{ to: "", text: "" }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          to: Yup.string().required("Telefone deve ser preenchido"),
          text: Yup.string().required(
            "Preencha uma mensagem para enviar de teste"
          ),
        })}
      >
        {({ isValid }) => (
          <Form>
            <Row>
              <h3>Enviar mensagem de teste</h3>
            </Row>
            <Row>
              <Col xs={12} md={5}>
                <TextField
                  name="to"
                  mask="(99)99999-9999"
                  label="Numero de telefone"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextField name="text" label="Mensagem a enviar" />
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
                <LoaderButton
                  isLoading={isSendingTest}
                  disabled={!isValid}
                  type="submit"
                >
                  Enviar teste
                </LoaderButton>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const WhatsConfig: React.FC<{}> = () => {
  const isWhatsappAuthenticated = useSelector(
    (state: RootState) => state.login.org?.isWhatsappAuthenticated
  );
  const isStarting = useSelector((state: RootState) => state.whats.isStarting);
  const isFinishedAuthenticated = useSelector(
    (state: RootState) => state.whats.isFinishedAuthenticated
  );
  const successMsg = useSelector((state: RootState) => state.whats.successMsg);
  const error = useSelector((state: RootState) => state.whats.error);
  const whatsQrCode = useSelector(
    (state: RootState) => state.whats.whatsQrCode
  );

  const dispatch = useDispatch();

  const startWhatsapp = () => {
    dispatch(whatsappActions.startWhatsapp());
  };

  return (
    <div>
      <h1>Configurações da API do Whatsapp</h1>
      <ShowSuccess message={successMsg} />
      <ShowError error={error} />
      {isFinishedAuthenticated && (
        <ShowSuccess message="Autenticado no whatsapp com sucesso!" />
      )}
      {!isWhatsappAuthenticated && (
        <div>
          <LoaderButton
            isLoading={isStarting}
            onClick={startWhatsapp}
            className="d-block"
          >
            Autenticar no whatsapp
          </LoaderButton>
          {!!whatsQrCode && (
            <>
              <h3>Leia o QRCode abaixo no seu WhatsApp para ativar.</h3>
              <img
                className="mt-2"
                src={`data:application/png;base64,${whatsQrCode}`}
                alt="QR Code Whatsapp"
              />
            </>
          )}
        </div>
      )}
      <WhatsappTeste />
      <WhatsappTemplate
        situation="INVOICE"
        title="Modelo de mensagem a ser enviado como lembrete de vencimento"
      />
    </div>
  );
};
