import formReducer from "./emissorForm/reducer";
import { combineReducers } from "redux";
import { AWSRes, cadKey } from "../../redux/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const CAD_TYPE = "CAD_EMISSOR";

export type Emissor = {
  cadId?: string;
  cadType?: "CAD_EMISSOR";
  userId?: string;
  name: string;
  fant: string;
  cnpj: string;
  ie: string;
  iest?: string;
  im?: string;
  cnae: string;
  regime: number;
  estate: string;
  city: string;
  zipCode: string;
  address: string;
  addressNumber: string;
  bairro: string;
  cmun: string;
  email: string;
  phone: string;
  modoNFe: number;
  cnpjEscritorio?: string;
  certificatePass?: string;
  logo?: string;
  certificate?: string;
  interCrt?: string;
  interKey?: string;
};

type EmissorState = {
  items: Emissor[];
  Count: number;
  ScannedCount: number;
  lastKey?: cadKey;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isDeleting: boolean;
};

const initialState: EmissorState = {
  items: [],
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
};

const emissorListSlice = createSlice({
  name: "emissor.list",
  initialState,
  reducers: {
    init: (state) => {
      state.items = [];
      state.Count = 0;
      state.ScannedCount = 0;
      state.lastKey = null;
      state.successMsg = "";
      state.error = "";
      state.isDeleting = false;
      state.isRequesting = false;
    },
    request: (state, _: PayloadAction<string>) => {
      state.isRequesting = true;
    },
    success: (state, action: PayloadAction<AWSRes<Emissor>>) => {
      state.isRequesting = false;
      state.items = action.payload.items;
      state.Count = action.payload.Count;
      state.ScannedCount = action.payload.ScannedCount;
      state.lastKey = action.payload.lastKey as cadKey;
    },
    error: (state, action: PayloadAction<string>) => {
      state.isRequesting = false;
      state.error = action.payload;
      state.successMsg = "";
    },
    deleteRequest: (state) => {
      state.isDeleting = true;
      state.error = "";
      state.successMsg = "";
    },
    deleteSuccess: (state, action: PayloadAction<string>) => {
      state.successMsg = action.payload;
      state.isDeleting = false;
      state.error = "";
    },
    deleteError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isDeleting = false;
      state.successMsg = "";
    },
  },
});

export const {
  init,
  request,
  success,
  error,
  deleteRequest,
  deleteSuccess,
  deleteError,
} = emissorListSlice.actions;

export default combineReducers({
  list: emissorListSlice.reducer,
  form: formReducer,
});
