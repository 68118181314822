import React from "react";
import moment from "moment";
import extenso from "extenso";
import { formatNumber } from "../misc/formatters";

import "./NotaPromissoria.css";

type NotaPromissoriaProps = {
  numero: string;
  valor: number;
  vencimento: Date;
  emission: Date;
  emissor: any;
  dest: any;
};

const NotaPromissoria: React.FC<NotaPromissoriaProps> = (props) => {
  let numeroExtenso = "";
  try {
    numeroExtenso = extenso(props.valor.toFixed(2).replace(".", ","), {
      mode: "currency",
    });
  } catch (e) {
    console.log("erro no extenso", props.valor.toFixed(2), e);
  }
  return (
    <div className="NotaPromissoria mt-2">
      <div className="block-avalista">
        <span className="texto-avalista text-center">AVALISTAS</span>
        <span className="texto-avalista campo-fill">CPF/CNPJ</span>
        <span
          className="texto-avalista campo-fill"
          style={{ paddingTop: "0.5rem" }}
        >
          ENDEREÇO
        </span>
        <span
          className="texto-avalista campo-fill"
          style={{ paddingTop: "0.5rem" }}
        >
          CPF/CNPJ
        </span>
        <span
          className="texto-avalista campo-fill"
          style={{ paddingTop: "0.5rem" }}
        >
          ENDEREÇO
        </span>
      </div>
      <div className="block-nota">
        <div className="block-line">
          <div>
            <span className="texto-normal">Nº </span>
            <span className="texto-normal texto-bold border-around">
              {props.numero}
            </span>
          </div>
          <span className="texto-normal">
            Vencimento{" "}
            {moment(props.vencimento).format("DD [de] MMMM [de] YYYY")}
          </span>
          <span className="texto-normal texto-bold border-around">
            R$ {formatNumber(props.valor, 2)}
          </span>
        </div>
        <div className="block-line">
          <span>Ao(s)&nbsp;</span>
          <span className="campo-fill">
            {extenso(moment(props.vencimento).format("D"))} dia
            {parseInt(moment(props.vencimento).format("D")) > 1 ? "s" : ""} de{" "}
            {moment(props.vencimento).format("MMMM")} de{" "}
            {extenso(moment(props.vencimento).format("YYYY"))}
          </span>
        </div>
        <div className="block-line">
          <span className="campo-fill">&nbsp;</span>
          <span>pagar</span>
          <span className="campo-fill">&nbsp;&nbsp;ei</span>
          <span className="texto-normal text-nowrap">
            {" "}
            por esta única via de <b>NOTA PROMISSÓRIA</b> a
          </span>
        </div>
        <div className="block-line">
          <span className="campo-fill">{props.emissor.name}</span>
          <span className="texto-normal text-nowrap"> CPF/CNPJ &nbsp; </span>
          <span className="campo-fill">{props.emissor.cnpj} </span>
        </div>
        <div className="block-line">
          <span className="texto-normal text-nowrap">
            Ou à sua ordem, a quantia de
          </span>
          <span className="campo-fill"> {numeroExtenso}</span>
        </div>
        <div className="block-line">
          <span className="texto-normal text-nowrap">
            em moeda corrente deste país, pagável em &nbsp;
          </span>
          <span className="campo-fill">
            {props.emissor.city}/{props.emissor.estate}
          </span>
        </div>
        <div className="block-line pt-1">
          <span>EMITENTE</span>
          <span className="campo-fill">&nbsp;{props.dest.name}</span>
          <span>EMISSÃO </span>
          <span className="campo-fill">
            &nbsp;{moment(props.emission).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="block-line pt-1">
          <span>CPF/CNPJ </span>
          <span className="campo-fill">&nbsp;{props.dest.cpf}</span>
          <span>ENDEREÇO </span>
          <span className="campo-fill">
            &nbsp;{props.dest.address}, {props.dest.addressNumber}
          </span>
        </div>
        <div className="block-line pt-4" style={{ bottom: "5px" }}>
          <span className="campo-fill">
            &nbsp;{props.dest.city} - {props.dest.estate}
          </span>
          <span className="texto-normal text-nowrap">ASS. DO EMITENTE</span>
          <span className="campo-fill">&nbsp;</span>
        </div>
      </div>
    </div>
  );
};

export default NotaPromissoria;
