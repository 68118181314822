import { AWSRes } from "./../../../redux/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PaymentParcela from "../types";

export type PaymentParcelaToClose = PaymentParcela & {
  checked: boolean;
};

export type RequestClosePayments = {
  itemsToClose: PaymentParcelaToClose[];
  valorCaixa: number;
  valorCartaoCredito: number;
  valorCartaoDebito: number;
};

type PaymentPayState = {
  items: PaymentParcela[];
  itemsToClose: PaymentParcelaToClose[];
  Count: number;
  lastKey: Record<string, string>;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isSaving: boolean;
};

const initialState: PaymentPayState = {
  items: [],
  itemsToClose: [],
  Count: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
};

const reducer = createSlice({
  name: "paymentPay",
  initialState,
  reducers: {
    requestPayments: (state, _: PayloadAction<string[]>) => {
      state.items = [];
      state.Count = 0;
      state.lastKey = null;
      state.successMsg = "";
      state.error = "";
      state.isRequesting = true;
      state.isSaving = false;
    },
    paymentsSuccess: (
      state,
      { payload }: PayloadAction<AWSRes<PaymentParcela>>
    ) => {
      state.itemsToClose = payload.items.map((item) => ({
        ...item,
        checked: true,
        valor_baixado: item.valor,
      }));
      state.items = payload.items;
      state.Count = payload.Count;
      state.lastKey = payload.lastKey;
      state.isRequesting = false;
    },
    setItemsToClose: (
      state,
      { payload }: PayloadAction<PaymentParcelaToClose[]>
    ) => {
      state.itemsToClose = payload;
    },
    paymentsError: (state, { payload }: PayloadAction<string>) => {
      state.isRequesting = false;
      state.error = payload;
    },
    requestClose: (state, _: PayloadAction<RequestClosePayments>) => {
      state.isSaving = true;
      state.error = "";
      state.successMsg = "";
    },
    closeSuccess: (state) => {
      state.isSaving = false;
      state.successMsg = "";
    },
    closeError: (state, { payload }: PayloadAction<string>) => {
      state.isSaving = false;
      state.error = payload;
    },
  },
});

export const PaymentPayActions = reducer.actions;

export default reducer;
