const dev = {
  s3: {
    REGION: "sa-east-1",
    BUCKET: "sdcks-app-api-dev-attachmentsbucket-181bu8dafdlsx",
  },
  apiGateway: {
    REGION: "sa-east-1",
    URL: "https://es1410bsge.execute-api.sa-east-1.amazonaws.com/dev",
  },
  apiNFe: {
    REGION: "sa-east-1",
    URL: "https://x9kthtord2.execute-api.sa-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "sa-east-1",
    USER_POOL_ID: "sa-east-1_MSGAWjws6",
    APP_CLIENT_ID: "15dria0kum3c4prcdtdhjl9df0",
    IDENTITY_POOL_ID: "sa-east-1:dd616352-855a-4b7d-b0bf-81cebf2dee67",
  },
  MAX_ATTACHMENT_SIZE: 5000000,
};

const prod = {
  s3: {
    REGION: "sa-east-1",
    BUCKET: "sdcks-app-api-prod-attachmentsbucket-1c04tuq7xusl1",
  },
  apiGateway: {
    REGION: "sa-east-1",
    // URL: "https://6dblkll8a1.execute-api.sa-east-1.amazonaws.com/prod",
    URL: "https://api.notinha.app.br",
  },
  apiNFe: {
    REGION: "sa-east-1",
    // URL: "https://dl869ya1ub.execute-api.sa-east-1.amazonaws.com/prod",
    URL: "https://apipy.notinha.app.br",
  },
  cognito: {
    REGION: "sa-east-1",
    USER_POOL_ID: "sa-east-1_91E4Flywn",
    APP_CLIENT_ID: "4dg9kssvgptj5rl2brtkjo8etu",
    IDENTITY_POOL_ID: "sa-east-1:60170e47-0dcf-416a-982b-771191fb9b98",
  },
  MAX_ATTACHMENT_SIZE: 5000000,
};

export default process.env.REACT_APP_STAGE === "prod"
  ? { ...prod }
  : { ...dev };
