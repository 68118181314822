import { PayloadAction } from "@reduxjs/toolkit";
import { PaymentPayActions, RequestClosePayments } from "./reducer";
import { AWSRes } from "./../../../redux/types";
// saga do form do modulo payment
import { put, all, call, takeLatest, takeEvery } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import PaymentParcela from "../types";
import _ from "underscore";

function paymentCall(movIdBet: string, lastKey = null) {
  return executeApiRequest<AWSRes<PaymentParcela>>(
    "/payment",
    "GET",
    {},
    {
      queryStringParameters: {
        idQuery: movIdBet ? movIdBet : "",
        idFilterType: "BETWEEN",
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* paymentInitWorker({ payload }: PayloadAction<string[]>) {
  try {
    const resFinal: AWSRes<PaymentParcela> = {
      items: [],
      Count: 0,
      ScannedCount: 0,
      lastKey: null,
    };
    if (payload.length === 0) {
      yield put(PaymentPayActions.paymentsSuccess(resFinal));
      return;
    }
    const paymentsMap = payload.map((payment) => payment.split(":")[0]);
    const paymentMin = _.min(paymentsMap);
    const paymentMax = _.max(paymentsMap);

    const res: AWSRes<PaymentParcela> = yield call(
      paymentCall,
      `OPEN.${paymentMin}:OPEN.${paymentMax}ó`
    );

    resFinal.items = [...resFinal.items, ...res.items];
    resFinal.Count += res.Count;
    resFinal.ScannedCount += res.ScannedCount;
    resFinal.lastKey = res.lastKey;
    yield put(PaymentPayActions.paymentsSuccess(resFinal));
  } catch (error) {
    console.log("error", error, error.response);
    yield put(
      PaymentPayActions.paymentsError(
        error.response ? error.response.data : error
      )
    );
  }
}

function* paymentSaveWorker({ payload }: PayloadAction<RequestClosePayments>) {
  try {
    console.log("payload save aa", payload);
    console.log("payload save aa", Object.keys(payload));

    // Update
    yield call(executeApiRequest, `/closePayment/aa`, "PUT", payload);
    yield put(PaymentPayActions.closeSuccess());
    yield put(PaymentPayActions.requestPayments([]));
  } catch (error) {
    console.log("error", error, error.response);
    yield put(PaymentPayActions.closeError(error.response.data));
  }
}

export default function* paymentFormSaga() {
  yield all([
    takeLatest("paymentPay/requestPayments", paymentInitWorker),
    takeEvery("paymentPay/requestClose", paymentSaveWorker),
  ]);
}
