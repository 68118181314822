import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { formatNumber } from "../../../misc/formatters";
import { Regra } from "../actions";
import { traduzQuandoTipoObj } from "./traduzQuandoTipoObj";
import { exibeQuandoObj } from "./exibeQuandoObj";

export const RegraShow: React.FC<{
  regra: Regra;
  onDelete: () => void;
  onEdit: () => void;
}> = ({ regra, onDelete, onEdit }) => (
  <Row className="border rounded bg-light p-2 mt-2">
    <Col sm={12}>
      {regra.quando.map((item) => (
        <Row>
          <Col className="p-2">
            <FontAwesomeIcon icon={faCircle} /> Quando{" "}
            {traduzQuandoTipoObj(item.tipoObj)}{" "}
            {item.operador === "eq" ? "for" : "não for"} {exibeQuandoObj(item)}
          </Col>
        </Row>
      ))}
    </Col>
    <Col sm={12}>
      <b className="mx-1">Aplicar:</b>
      <ul className="list-group list-group-flush">
        {regra.csosn && (
          <li className="list-group-item">CSOSN: {regra.csosn}</li>
        )}
        {regra.pCredSN && (
          <li className="list-group-item">
            Percentual de Crédito Simples Nacional:{" "}
            {formatNumber(regra.pCredSN, 2)}%
          </li>
        )}
        {regra.cfop && <li className="list-group-item">CFOP: {regra.cfop}</li>}
        {regra.csticms && (
          <li className="list-group-item">CST ICMS: {regra.csticms}</li>
        )}
        {regra.modBcIcms && (
          <li className="list-group-item">Mod. Base ICMS: {regra.modBcIcms}</li>
        )}
        {regra.pRedIcms && (
          <li className="list-group-item">
            Percentual de Redução da base de ICMS:{" "}
            {formatNumber(regra.pRedIcms, 2)}%
          </li>
        )}
        {regra.pIcms && (
          <li className="list-group-item">
            Aliquota de ICMS: {formatNumber(regra.pIcms, 2)}%
          </li>
        )}
        {regra.pIcmsFcp && (
          <li className="list-group-item">
            Aliquota de ICMS-FCP: {formatNumber(regra.pIcmsFcp, 2)}%
          </li>
        )}
        {regra.modBcIcmsSt && (
          <li className="list-group-item">
            Mod. Base ICMS-ST: {regra.modBcIcmsSt}
          </li>
        )}
        {regra.pMVA && (
          <li className="list-group-item">
            Percentual MVA: {formatNumber(regra.pMVA, 2)}%
          </li>
        )}
        {regra.pRedBcIcmsSt && (
          <li className="list-group-item">
            Percentual de Redução da base de ICMS-ST:{" "}
            {formatNumber(regra.pRedBcIcmsSt, 2)}%
          </li>
        )}
        {regra.pIcmsSt && (
          <li className="list-group-item">
            Aliquota de ICMS-ST: {formatNumber(regra.pIcmsSt, 2)}%
          </li>
        )}
        {regra.pIcmsStFcp && (
          <li className="list-group-item">
            Aliquota de ICMST-ST-FCP: {formatNumber(regra.pIcmsStFcp, 2)}%
          </li>
        )}
        {regra.cstipi && (
          <li className="list-group-item">CST IPI: {regra.cstipi}</li>
        )}
        {regra.pipi && (
          <li className="list-group-item">
            Aliquota IPI: {formatNumber(regra.pipi, 2)}%
          </li>
        )}
        {regra.cstpis && (
          <li className="list-group-item">CST PIS: {regra.cstpis}</li>
        )}
        {regra.ppis && (
          <li className="list-group-item">
            Aliquota PIS: {formatNumber(regra.ppis, 2)}%
          </li>
        )}
        {regra.cstcofins && (
          <li className="list-group-item">CST COFINS: {regra.cstcofins}</li>
        )}
        {regra.pcofins && (
          <li className="list-group-item">
            Aliquota COFINS: {formatNumber(regra.pcofins, 2)}%
          </li>
        )}
        {regra.pIcmsUfDest && (
          <li className="list-group-item">
            Aliquota ICMS da UF de Destino: {formatNumber(regra.pIcmsUfDest, 2)}
            %
          </li>
        )}
        {regra.pIcmsInter && (
          <li className="list-group-item">
            Aliquota ICMS Interestadual: {formatNumber(regra.pIcmsInter, 2)}%
          </li>
        )}
        {regra.pIcmsFcpUfDest && (
          <li className="list-group-item">
            Aliquota ICMS-FCP da UF de Destino:{" "}
            {formatNumber(regra.pIcmsFcpUfDest, 2)}%
          </li>
        )}
      </ul>
    </Col>
    <Col sm={12} className="d-flex justify-items-end mt-2 mb-2">
      <Button onClick={onDelete} variant="danger" className="me-2">
        Remover regra
      </Button>
      <Button onClick={onEdit} variant="warning">
        Editar regra
      </Button>
    </Col>
  </Row>
);
