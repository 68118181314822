import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Image } from "react-bootstrap";
import ShowError from "../../components/ShowError";
import LoaderButton from "../../components/LoaderButton";

import logo from "../../assets/sdks-logo.png";
import "./index.css";
import { RootState } from "../app/mainReducer";
import { ForgotPwdConfirmationPayload, loginActions } from "./reducer";

type ForgotPasswordProps = {
  forgotPwdConfirmOpen: boolean;
  forgotPwdIsRequesting: boolean;
  forgotPwdError: any;
  forgotPwdInit: () => void;
  forgotPwdRequest: (email: string) => void;
  forgotPwdConfirmRequest: (payload: ForgotPwdConfirmationPayload) => void;
};

type ForgotPasswordState = {
  email: string;
  confirmationCode: string;
  password: string;
  confirmPassword: string;
};

class ForgotPassword extends Component<
  ForgotPasswordProps,
  ForgotPasswordState
> {
  state = {
    email: "",
    confirmationCode: "",
    password: "",
    confirmPassword: "",
  };

  componentDidMount() {
    this.props.forgotPwdInit();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.forgotPwdConfirmOpen) {
      this.props.forgotPwdConfirmRequest({
        email: this.state.email,
        confirmationCode: this.state.confirmationCode,
        newPassword: this.state.password,
      });
    } else {
      this.props.forgotPwdRequest(this.state.email);
    }
  };

  renderConfirmReset = () => {
    if (this.props.forgotPwdConfirmOpen === true) {
      return (
        <>
          <Form.Group controlId="confirmationCode">
            <Form.Label>Código de confirmação </Form.Label>
            <Form.Control
              autoFocus
              type="tel"
              onChange={(e) =>
                this.setState({ confirmationCode: e.target.value })
              }
              value={this.state.confirmationCode}
            />
            <Form.Text className="text-muted">
              Por favor verifique seu email para receber o código.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Nova Senha</Form.Label>
            <Form.Control
              type="password"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              placeholder="Senha"
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Confirme a Senha</Form.Label>
            <Form.Control
              type="password"
              value={this.state.confirmPassword}
              onChange={(e) =>
                this.setState({ confirmPassword: e.target.value })
              }
              placeholder="Confirmação"
            />
          </Form.Group>
        </>
      );
    }
    return null;
  };

  render() {
    const validations = [
      this.state.email && this.state.email.trim().length > 3,
    ];
    if (this.props.forgotPwdConfirmOpen === true) {
      validations.push(
        this.state.confirmationCode &&
          this.state.confirmationCode.trim().length > 0
      );
      validations.push(
        this.state.password && this.state.password.trim().length >= 6
      );
      validations.push(this.state.password === this.state.confirmPassword);
    }
    const validForm = validations.reduce((a, p) => a && p);
    return (
      <Form onSubmit={this.handleSubmit} className="ForgotPassword bg-light">
        <div className="logo">
          <Image src={logo} width={150} height={90} />
        </div>
        <ShowError error={this.props.forgotPwdError} />
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={this.state.email}
            disabled={this.props.forgotPwdConfirmOpen}
            onChange={(e) => this.setState({ email: e.target.value })}
            placeholder="Ex: fulano@free.org"
          />
        </Form.Group>
        {this.renderConfirmReset()}
        <LoaderButton
          variant="primary"
          isLoading={this.props.forgotPwdIsRequesting}
          disabled={!validForm}
          type="submit"
          className="button pull-right"
        >
          Confirmar
        </LoaderButton>
      </Form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    forgotPwdConfirmOpen: state.login.forgotPwdConfirmOpen,
    forgotPwdIsRequesting: state.login.forgotPwdIsRequesting,
    forgotPwdError: state.login.forgotPwdError,
  };
};

const dispatchProps = {
  forgotPwdInit: loginActions.forgotPwdInit,
  forgotPwdRequest: loginActions.forgotPwdRequest,
  forgotPwdConfirmRequest: loginActions.forgotPwdConfirmRequest,
};

export default connect(mapStateToProps, dispatchProps)(ForgotPassword);
