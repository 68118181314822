import { API } from "aws-amplify";

type ApiMethod = "GET" | "POST" | "PUT" | "DELETE";

const executeApiRequest = async <T>(
  path: string,
  method: ApiMethod,
  data: any,
  options = {}
): Promise<T | null> => {
  let res: T;
  const API_NAME = "sdcks";

  switch (method) {
    case "GET":
      res = await API.get(API_NAME, path, options);
      break;
    case "POST":
      res = await API.post(API_NAME, path, {
        body: data,
        ...options,
      });
      break;
    case "PUT":
      res = await API.put(API_NAME, path, {
        body: data,
        ...options,
      });
      break;
    case "DELETE":
      res = await API.del(API_NAME, path, options);
      break;
    default:
      return null;
  }
  return res;
};

export default executeApiRequest;
