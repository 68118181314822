import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Image } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import ShowError from "../../components/ShowError";

import logo from "../../assets/sdks-logo.png";
import "./index.css";
import { ConfirmationActionPayload, loginActions } from "./reducer";
import { RootState } from "../app/mainReducer";

type ConfirmEmailProps = {
  confirmEmail: string;
  confirmationRequesting: boolean;
  confirmationError: any;
  submitConfirmation: (payload: ConfirmationActionPayload) => void;
  resendConfirmationCode: (payload: string) => void;
};

type ConfirmEmailState = {
  confirmationCode: string;
};

class ConfirmEmail extends Component<ConfirmEmailProps, ConfirmEmailState> {
  state = {
    confirmationCode: "",
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.submitConfirmation({
      email: this.props.confirmEmail,
      confirmationCode: this.state.confirmationCode,
    });
  };

  render() {
    const validForm =
      this.state.confirmationCode &&
      this.state.confirmationCode.trim().length > 0;

    return (
      <Form onSubmit={this.handleSubmit} className="Login bg-light">
        <div className="logo">
          <Image src={logo} width={150} height={90} />
        </div>
        <Form.Group controlId="confirmationCode">
          <ShowError error={this.props.confirmationError} />
          <Form.Label>Código de Confirmação</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={(e) =>
              this.setState({ confirmationCode: e.target.value })
            }
            value={this.state.confirmationCode}
          />
          <Form.Text className="text-muted">
            Por favor verifique seu email para receber o código.
          </Form.Text>
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          className="button pull-right"
          isLoading={this.props.confirmationRequesting}
          disabled={!validForm}
        >
          Verificar
        </LoaderButton>
        <Button
          onClick={() =>
            this.props.resendConfirmationCode(this.props.confirmEmail)
          }
        >
          Reenviar código
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    confirmEmail: state.login.confirmEmail,
    confirmationRequesting: state.login.confirmationRequesting,
    confirmationError: state.login.confirmationError,
  };
};

const dispatchProps = {
  submitConfirmation: loginActions.confirmationRequest,
  resendConfirmationCode: loginActions.resendConfirmationCode,
};

export default connect(mapStateToProps, dispatchProps)(ConfirmEmail);
