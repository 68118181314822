import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
// import { s3Download } from "../../misc/awsLib";
import executeApiRequest from "../../misc/executeApiRequest";
import { loginActions } from "../login/reducer";
import { whatsappActions, WhatsappTemplate } from "./reducer";

const sleep = (ms: number) =>
  new Promise((resolve) => window.setTimeout(resolve, ms));

function* startWhatsappWorker() {
  try {
    const res = yield call(executeApiRequest, "/start_whatsapp", "GET", {}, {});
    console.log("resposta api", res);
    for (let i = 0; i < 13; i++) {
      yield sleep(5 * 1000);
      const resConsulta = yield call(
        executeApiRequest,
        `/status_whatsapp/${res}`,
        "GET",
        {},
        {}
      );
      console.log("resposta consulta", resConsulta);
      if (resConsulta === "OK") {
        break;
      }
    }
    yield sleep(2000);
    for (let i = 0; i < 20; i++) {
      // Atualizar qrcode a cada 15segundos
      const qrCodeFile = yield call(
        executeApiRequest,
        "/qrcode_whatsapp",
        "GET",
        {},
        {}
      );
      console.log("qrCodeFile retrieved", qrCodeFile);
      // const qrCode = yield call(s3Download, `${orgId}.png`);
      yield put(whatsappActions.startSuccess(qrCodeFile));
      yield sleep(15 * 1000);
      // check if authentication went through
      const org = yield call(executeApiRequest, `/org`, "GET", {}, {});
      if (org.isWhatsappAuthenticated) {
        // Authenticated
        yield put(loginActions.orgSuccess(org));
        yield put(whatsappActions.finishedAuthenticated());
        break;
      }
    }
  } catch (error) {
    console.log("Error on start whatsapp", error);
    yield put(whatsappActions.startError(error));
  }
}

function* sendWhatsapptestWorker({
  payload,
}: PayloadAction<{ to: string; text: string }>) {
  try {
    yield call(executeApiRequest, "/whatsapp_test", "POST", payload, {});
    yield put(whatsappActions.testSuccess());
  } catch (error) {
    yield put(whatsappActions.testError(error));
  }
}

function* saveTemplateWorker({
  payload,
}: PayloadAction<Partial<WhatsappTemplate>>) {
  try {
    const res = yield call(
      executeApiRequest,
      "/whatsapp_template",
      "POST",
      payload,
      {}
    );
    yield put(whatsappActions.saveTemplateSuccess(res));
  } catch (error) {
    yield put(whatsappActions.saveTemplateError(error));
  }
}

function* loadTemplateWorker({
  payload,
}: PayloadAction<Partial<WhatsappTemplate>>) {
  try {
    const res = yield call(
      executeApiRequest,
      `/whatsapp_template/${payload}`,
      "GET",
      payload,
      {}
    );
    yield put(whatsappActions.loadTemplateSuccess(res));
  } catch (error) {
    yield put(whatsappActions.loadTemplateError(error));
  }
}

export default function* whatsSaga() {
  yield all([
    takeLatest("whats/startWhatsapp", startWhatsappWorker),
    takeLatest("whats/sendTest", sendWhatsapptestWorker),
    takeLatest("whats/saveTemplate", saveTemplateWorker),
    takeLatest("whats/loadTemplate", loadTemplateWorker),
  ]);
}
