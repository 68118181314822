import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { Form } from "react-bootstrap";

type SelectFieldProps = InputHTMLAttributes<HTMLSelectElement> & {
  label: string;
  name: string;
};

export const SelectField: React.FC<SelectFieldProps> = ({
  label,
  children,
  ...props
}) => {
  const [field, { error, touched }] = useField(props);
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Select {...field} isInvalid={touched && error ? true : false}>
        {children}
      </Form.Select>
      {touched && error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};
