import {
  faBoxes,
  faBriefcase,
  faBuilding,
  faCashRegister,
  faChartBar,
  faCheckCircle,
  faCoins,
  faColumns,
  faCrosshairs,
  faFileInvoiceDollar,
  faList,
  faPiggyBank,
  faReceipt,
  faShoppingCart,
  faSortNumericUp,
  faStickyNote,
  faTruckPickup,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const menu_links = [
  {
    link: "/dashboard",
    label: "Dashboard",
    icon: faChartBar,
    id: "dashboard",
  },
  {
    link: "/dest",
    label: "Clientes/Fornecedores",
    icon: faUsers,
    id: "dest",
  },
  {
    link: "/invoicePane",
    label: "Painel de Cobranças",
    icon: faCoins,
    id: "invoices",
  },
  {
    link: "/sales",
    label: "Painel de NFes",
    icon: faColumns,
    id: "sales",
  },
  {
    link: "/services",
    label: "Serviços",
    icon: faBriefcase,
    id: "services",
  },
  {
    link: "/condPane",
    label: "Condicionais",
    icon: faStickyNote,
    id: "condPane",
  },
  {
    link: "/Tasks",
    label: "Tarefas",
    icon: faCheckCircle,
    id: "task",
  },
  {
    link: "/quicksale",
    label: "Vendas Rápidas",
    icon: faCashRegister,
    id: "quicksale",
  },
  {
    link: "/caixa",
    label: "Livro Caixa",
    icon: faReceipt,
    id: "caixa",
  },
  {
    link: "/RepInvoices",
    label: "Relatório de cobranças",
    icon: faList,
    id: "repinvoices",
  },
  {
    link: "/purchases",
    label: "Compras",
    icon: faShoppingCart,
    id: "purchases",
  },
  {
    link: "/prod",
    label: "Produtos",
    icon: faBoxes,
    id: "prod",
  },
  {
    link: "/payment",
    label: "Pagamentos",
    icon: faFileInvoiceDollar,
    id: "payment",
  },
  {
    link: "/emissor",
    label: "Emissores",
    icon: faBuilding,
    id: "emissor",
  },
  {
    link: "/transp",
    label: "Transportadores",
    icon: faTruckPickup,
    id: "transp",
  },
  {
    link: "/tipoop",
    label: "Tipo de Operação",
    icon: faCrosshairs,
    id: "tipoop",
  },
  {
    link: "/serie",
    label: "Série",
    icon: faSortNumericUp,
    id: "serie",
  },
  {
    link: "/banco",
    label: "Banco",
    icon: faPiggyBank,
    id: "banco",
  },
  {
    link: "/whatsConfig",
    label: "Configurar Whatsapp",
    icon: faWhatsapp,
    id: "whatsapp",
  },
];

export default menu_links;
