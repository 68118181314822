// reducer do form do modulo dest
import {
  DEST_FORM_INIT,
  DEST_FORM_SUCCESS,
  DEST_FORM_ERROR,
  DEST_SAVE_REQUESTING,
  DEST_SAVE_SUCCESS,
  DEST_SAVE_ERROR,
} from "./actions";

const initialState = {
  item: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DEST_FORM_INIT:
      return {
        ...state,
        item: null,
        isRequesting: true,
        successMsg: "",
        error: "",
      };
    case DEST_FORM_SUCCESS:
      return {
        ...state,
        item: action.payload,
        isRequesting: false,
      };
    case DEST_FORM_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
      };
    case DEST_SAVE_REQUESTING:
      return {
        ...state,
        isSaving: true,
      };
    case DEST_SAVE_SUCCESS:
      return {
        ...state,
        item: action.payload,
        successMsg: "Cliente/Fornecedor salvo com sucesso",
        error: "",
        isSaving: false,
      };
    case DEST_SAVE_ERROR:
      return {
        ...state,
        error: action.error,
        isSaving: false,
      };
    default:
      return state;
  }
};

export default reducer;
