import { Invoice } from "./../actions";
import { PayloadAction } from "@reduxjs/toolkit";
// reducer do form do modulo invoice
import { createSlice } from "@reduxjs/toolkit";

type InvoiceFormState = {
  item: Invoice;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isSaving: boolean;
};

const initialState: InvoiceFormState = {
  item: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
};

const reducerSlice = createSlice({
  initialState,
  name: "invoiceForm",
  reducers: {
    requestItem: (state, _: PayloadAction<string>) => {
      state.isRequesting = true;
      state.isSaving = false;
      state.error = "";
      state.item = null;
    },
    requestSuccess: (state, action: PayloadAction<Invoice>) => {
      state.isRequesting = false;
      state.item = action.payload;
    },
    requestError: (state, action: PayloadAction<string>) => {
      state.isRequesting = false;
      state.error = action.payload;
    },
    saveRequest: (state, _: PayloadAction<Invoice>) => {
      state.isSaving = true;
      state.error = "";
      state.successMsg = "";
    },
    saveSuccess: (state, action: PayloadAction<Invoice>) => {
      state.isSaving = false;
      state.item = action.payload;
      state.successMsg = "Cobrança salva com sucesso!";
    },
    saveError: (state, action: PayloadAction<string>) => {
      state.isSaving = false;
      state.error = action.payload;
    },
  },
});
export const invoiceFormActions = reducerSlice.actions;

export default reducerSlice;
