// reducer do modulo cond
import {
  COND_INIT,
  COND_REQUESTING,
  COND_SUCCESS,
  COND_ERROR,
  COND_DELETE_REQUESTING,
  COND_DELETE_ERROR,
  COND_DELETE_SUCCESS,
} from "./actions";
import formReducer from "./condForm/reducer";
import closeReducer from "./condClose/reducer";
import paneReducer from "./condPane/reducer";
import viewReducer from "./condView/reducer";
import { combineReducers } from "redux";

const initialState = {
  items: [],
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case COND_INIT:
      return {
        ...state,
        items: [],
        Count: 0,
        ScannedCount: 0,
        lastKey: null,
        successMsg: "",
        error: "",
        isRequesting: true,
        isDeleting: false,
      };
    case COND_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      };
    case COND_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload.items,
        Count: action.payload.Count,
        ScannedCount: action.payload.ScannedCount,
        lastKey: action.payload.lastKey,
      };
    case COND_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
        successMsg: "",
      };
    case COND_DELETE_REQUESTING:
      return {
        ...state,
        isDeleting: true,
      };
    case COND_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        successMsg: "Condicional removida com sucesso!",
      };
    case COND_DELETE_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        successMsg: "",
      };
    default:
      return state;
  }
};

export default combineReducers({
  list: reducer,
  form: formReducer,
  close: closeReducer,
  pane: paneReducer.reducer,
  view: viewReducer,
});
