import { Dest } from "../dest/actions";
import { Emissor } from "../emissor/reducer";
import { Prod } from "../prod/actions";

export type Purchase = {
  orgId?: string;
  invoiceId?: string;
  lsi1: string;
  lsi2: string;
  lsi3: string;
  lsi4: string;
  version?: number;
  tag: string;
  num: string;
  dest: Dest;
  entry: Date;
  emissor: Emissor;
  tpag: string;
  obs?: string;
  items: PurchaseItem[];
  geraPagamento?: boolean;
  geraEstoque?: boolean;
  nparcelas?: number;
  parcelas?: ParcelaPurchase[];
  serialCreate?: Record<string, string>;
  desconto?: number;
  acrescimo?: number;
};

export type PurchaseItem = {
  prod: Prod;
  qtd: number;
  valor: number;
  total: number;
};

export type ParcelaPurchase = {
  valor: number;
  venc: Date;
  nParcela: number;
};

export const Classificacoes = {
  CUSTO_FIXO: "CUSTO_FIXO",
  CUSTO_VARIAVEL: "CUSTO_VARIAVEL",
  DESPESA: "DESPESA",
  TRIBUTOS: "TRIBUTOS",
};
