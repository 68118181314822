import { Storage } from "aws-amplify";

export const s3Upload = async (file: File, orgId: string) => {
  const filename = `${Date.now()}-${orgId}-${file.name}`;
  const stored: any = await Storage.vault.put(filename, file, {
    level: "public",
    contentType: file.type,
  });

  return stored.key;
};

export const s3Delete = (filename: string) => {
  return Storage.vault.remove(filename);
};

export const s3Download = (filename: string) => {
  return Storage.vault.get(filename, {
    level: "public",
  }) as Promise<string>;
};
