// component do modulo task
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  pageInit,
  taskDeleteRequest,
  taskSaveRequest,
  newtaskSet,
  Task,
} from "./actions";
import LoadingContainer from "../../components/LoadingContainer";
import { Col, Row, Container, Form } from "react-bootstrap";
import _ from "underscore";
import moment from "moment";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import LoaderButton from "../../components/LoaderButton";
import TaskForm from "./TaskForm"; // Refactor

import "./task.css";
import TaskComponent from "./TaskComponent";

type TasksProps = {
  newTask: Task;
  items: Task[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isDeleting: boolean;
  isSaving: boolean;
  pageInit: () => void;
  deleteRequest: (movId: string) => void;
  taskSaveRequest: (task: Task) => void;
  newtaskSet: (task: Partial<Task>) => void;
};

class Tasks extends Component<TasksProps, {}> {
  state = {
    showFinshed: false,
  };

  componentDidMount() {
    this.props.pageInit();
  }

  handleAddTask = (e) => {
    e.preventDefault();
    this.props.taskSaveRequest(this.props.newTask);
  };

  handleFinishTask = (task) => {
    const saveTask = { ...task, finshedDate: new Date() };
    this.props.taskSaveRequest(saveTask);
  };

  handleUnfinishTask = (task) => {
    const saveTask = { ...task, finshedDate: null };
    this.props.taskSaveRequest(saveTask);
  };

  handleDelete = (item) => {
    this.props.deleteRequest(item.movId);
  };

  render() {
    const validations = [
      this.props.newTask.summary &&
        this.props.newTask.summary.trim().length > 3,
      this.props.newTask.dateDue &&
        moment().isSameOrBefore(this.props.newTask.dateDue, "day"),
    ];

    const isValid = validations.reduce((a, p) => a && p);

    const itemsToday = _.filter(
      this.props.items,
      (item) =>
        moment().isSameOrAfter(item.dateDue, "day") &&
        (this.state.showFinshed ||
          (!this.state.showFinshed && !item.finshedDate))
    );
    const itemsWeek = _.filter(
      this.props.items,
      (item) =>
        moment(item.dateDue).isBetween(
          moment(),
          moment().endOf("week"),
          "day",
          "(]"
        ) &&
        (this.state.showFinshed ||
          (!this.state.showFinshed && !item.finshedDate))
    );
    const itemsFuture = _.filter(
      this.props.items,
      (item) =>
        moment(item.dateDue).isAfter(moment().endOf("week")) &&
        (this.state.showFinshed ||
          (!this.state.showFinshed && !item.finshedDate))
    );

    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Tarefas</h2>
          </Col>
          <Col sm="1">
            {this.state.showFinshed && (
              <button
                className="button-eye"
                onClick={() =>
                  this.setState({ showFinshed: !this.state.showFinshed })
                }
                title="Esconder concluidos"
              >
                <Icon icon={faEye} />
              </button>
            )}
            {!this.state.showFinshed && (
              <button
                className="button-eye"
                onClick={() =>
                  this.setState({ showFinshed: !this.state.showFinshed })
                }
                title="Mostrar concluidos"
              >
                <Icon icon={faEyeSlash} />
              </button>
            )}
          </Col>
        </Row>
        {/* Form de add task */}
        <Row>
          <Col className="mt-3 mb-3">
            <Form
              onSubmit={this.handleAddTask}
              className="border border-primary rounded mt-2 mb-2 pt-2 pr-2 pl-2 pb-2"
            >
              <h3>Incluir tarefa</h3>
              <TaskForm
                task={this.props.newTask}
                onChange={(obj) =>
                  this.props.newtaskSet({ ...this.props.newTask, ...obj })
                }
              />
              <LoaderButton
                disabled={!isValid}
                isLoading={this.props.isSaving}
                type="submit"
              >
                Incluir tarefa
              </LoaderButton>
            </Form>
          </Col>
        </Row>
        {/* List of tasks */}
        <Container>
          <Row>
            <Col className="task-group tasks-today" xs={12} md={3}>
              <h3>Hoje</h3>
              {itemsToday.map((item, i) => (
                <TaskComponent
                  key={`${i}`}
                  {...item}
                  onFinish={() => this.handleFinishTask(item)}
                  isLoading={this.props.isSaving}
                  onUnfinish={() => this.handleUnfinishTask(item)}
                />
              ))}
            </Col>
            <Col className="task-group tasks-week" xs={12} md={3}>
              <h3>Semana</h3>
              {itemsWeek.map((item, i) => (
                <TaskComponent
                  key={`${i}`}
                  {...item}
                  onFinish={() => this.handleFinishTask(item)}
                  isLoading={this.props.isSaving}
                  onUnfinish={() => this.handleUnfinishTask(item)}
                />
              ))}
            </Col>
            <Col className="task-group tasks-future" xs={12} md={3}>
              <h3>Futuro</h3>
              {itemsFuture.map((item, i) => (
                <TaskComponent
                  key={`${i}`}
                  {...item}
                  onFinish={() => this.handleFinishTask(item)}
                  isLoading={this.props.isSaving}
                  onUnfinish={() => this.handleUnfinishTask(item)}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newTask: state.task.list.newTask,
    items: state.task.list.items,
    Count: state.task.list.Count,
    lastKey: state.task.list.lastKey,
    isRequesting: state.task.list.isRequesting,
    isDeleting: state.task.list.isDeleting,
    isSaving: state.task.list.isSaving,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: () => dispatch(pageInit()),
    deleteRequest: (movId: string) => dispatch(taskDeleteRequest(movId)),
    taskSaveRequest: (task: Task) => dispatch(taskSaveRequest(task)),
    newtaskSet: (task: Partial<Task>) => dispatch(newtaskSet(task)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
