import {
  SERIE_INIT,
  SERIE_REQUESTING,
  SERIE_SUCCESS,
  SERIE_ERROR,
  SERIE_DELETE_REQUESTING,
  SERIE_DELETE_ERROR,
  SERIE_DELETE_SUCCESS,
} from "./actions";
import formReducer from "./serieForm/reducer";
import { combineReducers } from "redux";

const initialState = {
  items: [],
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SERIE_INIT:
      return {
        ...state,
        items: [],
        Count: 0,
        ScannedCount: 0,
        lastKey: null,
        successMsg: "",
        error: "",
        isRequesting: false,
        isDeleting: false,
      };
    case SERIE_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      };
    case SERIE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload.items,
        Count: action.payload.Count,
        ScannedCount: action.payload.ScannedCount,
        lastKey: action.payload.lastKey,
      };
    case SERIE_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
        successMsg: "",
      };
    case SERIE_DELETE_REQUESTING:
      return {
        ...state,
        isDeleting: true,
      };
    case SERIE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        successMsg: "Série removida com sucesso!",
      };
    case SERIE_DELETE_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        successMsg: "",
      };
    default:
      return state;
  }
};

export default combineReducers({
  list: reducer,
  form: formReducer,
});
