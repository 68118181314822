import { Serie } from "./../serie/actions";
import { Tipoop } from "./../tipoop/actions";
import { Prod } from "./../prod/actions";
import { Dest } from "../dest/actions";
import { InvoiceParcela } from "../invoice/actions";
import { Transp } from "../transp/actions";
import { Emissor } from "../emissor/reducer";
// actions do modulo sales
export const SALES_INIT = "SALES_INIT";
export const SALES_RESET = "SALES_RESET";
export const SALES_REQUESTING = "SALES_REQUESTING";
export const SALES_SUCCESS = "SALES_SUCCESS";
export const SALES_ERROR = "SALES_ERROR";
export const MOV_TYPE = "MOV_SALES";
export const SALES_DELETE_REQUESTING = "SALES_DELETE_REQUESTING";
export const SALES_DELETE_SUCCESS = "SALES_DELETE_SUCCESS";
export const SALES_DELETE_ERROR = "SALES_DELETE_ERROR";
export const GERANFE_REQUESTING = "GERANFE_REQUESTING";
export const GERANFE_SUCCESS = "GERANFE_SUCCESS";
export const GERANFE_ERROR = "GERANFE_ERROR";
export const ENVIARNFE_REQUESTING = "ENVIARNFE_REQUESTING";
export const ENVIARNFE_SUCCESS = "ENVIARNFE_SUCCESS";
export const ENVIARNFE_ERROR = "ENVIARNFE_ERROR";
export const DOWNLOADDANFE_REQUESTING = "DOWNLOADDANFE_REQUESTING";
export const DOWNLOADDANFE_SUCCESS = "DOWNLOADDANFE_SUCCESS";
export const DOWNLOADDANFE_ERROR = "DOWNLOADDANFE_ERROR";
export const CANCELANFE_REQUESTING = "CANCELANFE_REQUESTING";
export const CANCELANFE_SUCCESS = "CANCELANFE_SUCCESS";
export const CANCELANFE_ERROR = "CANCELANFE_ERROR";
export const SENDNFE_REQUESTING = "SENDNFE_REQUESTING";
export const SENDNFE_SUCCESS = "SENDNFE_SUCCESS";
export const SENDNFE_ERROR = "SENDNFE_ERROR";

export type Sale = {
  movId?: string;
  movType: "MOV_SALES";
  userId?: string;
  version?: number;
  clientId?: string;
  num?: string;
  emission: Date;
  dtExp: Date;
  tipoop: Tipoop;
  serie: Serie;
  emissor: Emissor;
  dest: Dest;
  modFrete?: string;
  transp?: Transp;
  vol?: number;
  especie?: string;
  tpag?: string;
  infAdFisco?: string;
  infCpl?: string;
  items: SaleItem[];
  modelo?: string;
  NFe?: any;
  serialCreate?: any;
  nparcelas?: number;
  parcelas?: InvoiceParcela[];
};

export type SaleItem = {
  nItem?: number;
  prod: Prod;
  qtd: number;
  valor: number;
  cfop?: string;
  infAdProd?: string;
  total?: number;
  vICMSST?: number;
  cstIcms?: string;
  modBcIcms?: string;
  pRedBCICMS?: number;
  bcIcms?: number;
  pIcms?: number;
  vIcms?: number;
  bcFCP?: number;
  pFCP?: number;
  vFCP?: number;
  modBcIcmsST?: string;
  pMVAST?: number;
  pRedBCST?: number;
  bcICMSST?: number;
  pICMSST?: number;
  bcICMSSTFCP?: number;
  pICMSSTFCP?: number;
  vICMSSTFCP?: number;
  csosn?: string;
  pCredSN?: number;
  vCredSN?: number;
  cenq?: string;
  cstipi?: string;
  cnpjProd?: string;
  bcIPI?: number;
  pIPI?: number;
  IPI?: number;
  cstpis?: string;
  bcPIS?: number;
  pPIS?: number;
  PIS?: number;
  cstcofins?: string;
  bcCOFINS?: number;
  pCOFINS?: number;
  COFINS?: number;
  vBCUFDest?: number;
  pUFDest?: number;
  pICMSInter?: number;
  vUFDest?: number;
  vBCFCPUFDest?: number;
  pFCPUFDest?: number;
  vFCPUFDest?: number;
};

export function resetMessages() {
  return {
    type: SALES_RESET,
  };
}
export function pageInit() {
  return {
    type: SALES_INIT,
  };
}

export function salesRequest() {
  return {
    type: SALES_REQUESTING,
  };
}

export function salesSuccess(payload) {
  return {
    type: SALES_SUCCESS,
    payload,
  };
}

export function salesError(error) {
  return {
    type: SALES_ERROR,
    error,
  };
}

export function salesDeleteRequest(movId) {
  return {
    type: SALES_DELETE_REQUESTING,
    payload: movId,
  };
}

export function salesDeleteSuccess() {
  return {
    type: SALES_DELETE_SUCCESS,
  };
}

export function salesDeleteError(error) {
  return {
    type: SALES_DELETE_ERROR,
    error,
  };
}

export function geranfeRequest(payload, onSuccessDispatch) {
  return {
    type: GERANFE_REQUESTING,
    payload,
    onSuccessDispatch,
  };
}

export function geranfeSuccess() {
  return {
    type: GERANFE_SUCCESS,
  };
}

export function geranfeError(error) {
  return {
    type: GERANFE_ERROR,
    error,
  };
}

export function enviarnfeRequest(payload, onSuccessDispatch) {
  return {
    type: ENVIARNFE_REQUESTING,
    payload,
    onSuccessDispatch,
  };
}

export function enviarnfeSuccess() {
  return {
    type: ENVIARNFE_SUCCESS,
  };
}

export function enviarnfeError(error) {
  return {
    type: ENVIARNFE_ERROR,
    error,
  };
}

export function downloaddanfeRequest(payload) {
  return {
    type: DOWNLOADDANFE_REQUESTING,
    payload,
  };
}

export function downloaddanfeSuccess() {
  return {
    type: DOWNLOADDANFE_SUCCESS,
  };
}

export function downloaddanfeError(error) {
  return {
    type: DOWNLOADDANFE_ERROR,
    error,
  };
}

export function cancelanfeRequesting(payload, onSuccessDispatch) {
  return {
    type: CANCELANFE_REQUESTING,
    payload,
    onSuccessDispatch,
  };
}

export function cancelanfeSuccess() {
  return {
    type: CANCELANFE_SUCCESS,
  };
}

export function cancelanfeError(error) {
  return {
    type: CANCELANFE_ERROR,
    error,
  };
}

export function sendnfeRequesting(payload, email = undefined) {
  return {
    type: SENDNFE_REQUESTING,
    payload,
    email,
  };
}

export function sendnfeSuccess(payload) {
  return {
    type: SENDNFE_SUCCESS,
    payload,
  };
}

export function sendnfeError(error) {
  return {
    type: SENDNFE_ERROR,
    error,
  };
}
