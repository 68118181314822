import { AWSRes } from "./../../../redux/types";
import { Cond } from "./../saga";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type condPaneInitialState = {
  itemsOpen: Cond[];
  CountOpen: number;
  lastKeyOpen: Record<string, string>;
  successMsg: string;
  error: string;
  isOpenRequesting: boolean;
  itemsClosed: Cond[];
  CountClosed: number;
  lastKeyClosed: Record<string, string>;
  isClosedRequesting: boolean;
};

const initialState: condPaneInitialState = {
  itemsOpen: [],
  CountOpen: 0,
  lastKeyOpen: null,
  successMsg: "",
  error: "",
  isOpenRequesting: false,
  itemsClosed: [],
  CountClosed: 0,
  lastKeyClosed: null,
  isClosedRequesting: false,
};

const condPaneSlice = createSlice({
  name: "condPane",
  initialState,
  reducers: {
    requestCondsOpened: (state) => {
      state.isOpenRequesting = true;
      state.CountOpen = 0;
      state.lastKeyOpen = null;
      state.itemsOpen = [];
      state.error = "";
    },
    condsOpenedSuccess: (state, { payload }: PayloadAction<AWSRes<Cond>>) => {
      state.isOpenRequesting = false;
      state.CountOpen = payload.Count;
      state.lastKeyOpen = payload.lastKey;
      state.itemsOpen = payload.items;
    },
    condsOpenedError: (state, { payload }: PayloadAction<string>) => {
      state.isOpenRequesting = false;
      state.error = payload;
    },
    requestCondsClosed: (state) => {
      state.isClosedRequesting = true;
      state.CountClosed = 0;
      state.lastKeyClosed = null;
      state.itemsClosed = [];
      state.error = "";
    },
    condsClosedSuccess: (state, { payload }: PayloadAction<AWSRes<Cond>>) => {
      state.isClosedRequesting = false;
      state.CountClosed = payload.Count;
      state.lastKeyClosed = payload.lastKey;
      state.itemsClosed = payload.items;
      state.error = "";
    },
    condsClosedError: (state, { payload }: PayloadAction<string>) => {
      state.isClosedRequesting = false;
      state.error = payload;
    },
  },
});

export const condPaneActions = condPaneSlice.actions;

export default condPaneSlice;
