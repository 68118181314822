import React from "react";
import DateTimeSelector from "../DateTimeSelector";
import { FormikProps } from "formik/dist/types";

type DateTimeInputProps = {
  size: number;
  name: string;
  label: string;
  formik: FormikProps<any>;
};

const DateTimeInput: React.FC<DateTimeInputProps> = (props) => (
  <div className={`form-group col-12 col-md-${props.size}`}>
    <label htmlFor={props.name}>{props.label}</label>
    <DateTimeSelector
      id={props.name}
      value={props.formik.values[props.name]}
      onChange={(dt) => props.formik.setFieldValue(props.name, dt)}
    />
    {props.formik.errors[props.name] && props.formik.touched[props.name] && (
      <div className="input-feedback">{props.formik.errors[props.name]}</div>
    )}
  </div>
);

export default DateTimeInput;
