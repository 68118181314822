import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { Banco } from "../types";
import { bancoFormActions } from "./reducer";

const newBanco = (): Banco => ({
  cadType: "CAD_BANCO",
  cod: "",
  name: "",
  agencia: "",
  agenciadv: "",
  conta: "",
  contadv: "",
  codCliente: "",
  codClienteDv: "",
  junoMode: "fake",
});

function* initBancoFormWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "new") {
      yield put(bancoFormActions.initSuccess(newBanco()));
    } else {
      const res: Banco = yield call(
        executeApiRequest,
        `/banco/${payload}`,
        "GET",
        {}
      );
      yield put(bancoFormActions.initSuccess(res));
    }
  } catch (error) {
    yield put(bancoFormActions.initError(error));
  }
}

function* saveBancoWorker({ payload }: PayloadAction<Banco>) {
  try {
    if (payload.cadId) {
      // alterar
      const res: Banco = yield call(
        executeApiRequest,
        `/banco/${payload.cadId}`,
        "PUT",
        payload
      );
      yield put(bancoFormActions.saveSuccess(res));
    } else {
      // Incluir
      const res: Banco = yield call(
        executeApiRequest,
        `/banco`,
        "POST",
        payload
      );
      yield put(bancoFormActions.saveSuccess(res));
    }
  } catch (error) {
    yield put(bancoFormActions.saveError(error));
  }
}

function* configJuno({ payload }: PayloadAction<string>) {
  try {
    const res: Banco = yield call(
      executeApiRequest,
      `/config_juno/${payload}`,
      "GET",
      {},
      {}
    );
    yield put(bancoFormActions.configSuccess(res));
  } catch (error) {
    yield put(bancoFormActions.configError(error));
  }
}

export default function* bancoFormSaga() {
  yield all([
    takeLatest("bancoForm/initForm", initBancoFormWorker),
    takeLatest("bancoForm/saveRequest", saveBancoWorker),
    takeLatest("bancoForm/configRequest", configJuno),
  ]);
}
