import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Form, Alert } from "react-bootstrap";
import _ from "underscore";
import moment from "moment";
import { pageInit, closedRequesting } from "./actions";
import LoadingContainer from "../../../components/LoadingContainer";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import InputNumber from "../../../components/InputNumber";
import { formSave } from "../../sales/salesForm/actions";
import { MOV_TYPE as SALE_TYPE } from "../../sales/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import ShowError from "../../../components/ShowError";
import { Cond } from "../saga";

type CondCloseProps = {
  items: any[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isSaving: boolean;
  isSaleSaving: boolean;
  saleSaveError: any;
  defaultEmit: any;
  pageInit: (payload: any) => void;
  closedRequesting: (mov: any, onFinishCallback: () => void) => void;
  saveSale: (sale: any) => void;
  conds?: string;
};

type CondCloseState = {
  condsFilter: any[];
  checkedMovs: Record<string, any>;
  qtdVendidas: any;
};

class CondClose extends Component<CondCloseProps, CondCloseState> {
  state = {
    condsFilter: [],
    checkedMovs: {},
    qtdVendidas: {},
  };

  loadConds = (condArray: any[]) => {
    console.log("loading", `${_.min(condArray)}:${_.max(condArray) * 1 + 1}`);
    this.props.pageInit(
      `OPEN.${_.min(condArray)}:OPEN.${_.max(condArray) * 1 + 1}`
    );
  };

  componentDidMount() {
    const conds = (this.props.conds + "").split(",");
    this.setState({
      condsFilter: conds,
    });
    this.loadConds(conds);
  }

  componentDidUpdate(prevProps: CondCloseProps) {
    if (prevProps.conds !== this.props.conds && this.props.conds) {
      const conds = this.props.conds.split(",");
      this.setState({
        condsFilter: conds,
      });
      this.loadConds(conds);
    }
  }

  filterConds = () => {
    return _.filter(
      this.props.items,
      (cond: Cond) => this.state.condsFilter.indexOf(`${cond.num}`) !== -1
    );
  };

  condsToItems = (conds: Cond[]) => {
    const items: any[] = [];
    conds.forEach((cond) => {
      cond.items.forEach((item) => items.push({ ...item, cond }));
    });
    return items;
  };

  closeConds = () => {
    const selectedConds = Object.keys(this.state.checkedMovs).filter(
      (item) => !!this.state.checkedMovs[item]
    );
    if (selectedConds.length <= 0) {
      alert("Nenhuma condicional selecionada a fechar!");
      return;
    }
    // Executar a parte da baixa da condicional
    // Ordenar as condicionais
    selectedConds.sort();
    const movsToSave: Record<string, any> = {};
    const sellsByClient = {};
    // Marcar como fechado o Item
    selectedConds.forEach((mov) => {
      const [movId, nItem] = mov.split("/", 2);
      if (movsToSave[movId]) {
        const indItem = movsToSave[movId].items.findIndex(
          (item: any) => item.nItem === parseInt(nItem) * 1
        );
        movsToSave[movId].items[indItem].closed = true;
        movsToSave[movId].items[indItem].dateClosed = new Date();
        movsToSave[movId].items[indItem].qtdVendida =
          this.state.qtdVendidas[mov] || 0;
      } else {
        const movToSave = this.props.items.find((mov) => mov.movId === movId);
        if (!movToSave) {
          return;
        }
        const indItem = movToSave.items.findIndex(
          (item) => item.nItem === parseInt(nItem) * 1
        );
        movToSave.items[indItem].closed = true;
        movToSave.items[indItem].dateClosed = new Date();
        movToSave.items[indItem].qtdVendida = this.state.qtdVendidas[mov] || 0;
        movToSave.defaultEmit = this.props.defaultEmit;
        movsToSave[movId] = movToSave;
      }
    });
    console.log("sellsByClient", sellsByClient);

    console.log("movs to save", movsToSave);
    // Salvar todos os movId mencionados
    this.props.closedRequesting(movsToSave, () =>
      // this.saveSales(sellsByClient)
      console.log("finished closing ")
    );
  };

  saveSales = (sellsByClient) => {
    _.forEach(sellsByClient, (sale) => {
      const saleToSave = this.generateSale(sale[0].dest, sale);
      this.props.saveSale(saleToSave);
    });
  };

  generateSale = (client, items) => ({
    num: "",
    movType: SALE_TYPE,
    emissor: this.props.defaultEmit,
    tipoop: this.props.defaultEmit && this.props.defaultEmit.tipoopVenda,
    serie: this.props.defaultEmit && this.props.defaultEmit.serie,
    emission: new Date(),
    dtExp: new Date(),
    dest: client,
    modFrete: 9,
    vol: items.length,
    especie: "Unidade",
    tpag: "99",
    items: items.map((item, i) => {
      return {
        nItem: i + 1,
        prod: item.prod,
        qtd: item.qtdVendida,
        valor: item.valor,
        total: item.qtdVendida * item.valor,
        cfop: "5102",
        csosn: "102",
        cstipi: "53",
      };
    }),
  });

  toggleItem = (item) => {
    const checkedMovs = { ...this.state.checkedMovs };
    checkedMovs[`${item.cond.movId}/${item.nItem}`] =
      !checkedMovs[`${item.cond.movId}/${item.nItem}`];
    this.setState({ checkedMovs });
  };

  handleQtdVendida = (item, qtd) => {
    const qtdVendidas = { ...this.state.qtdVendidas };
    qtdVendidas[`${item.cond.movId}/${item.nItem}`] =
      item.qtd > qtd ? qtd : item.qtd;
    this.setState({
      qtdVendidas,
    });
  };

  render() {
    const headers = [
      {
        label: " ",
        render: (item) => (
          <Form.Group>
            <Form.Check
              type="checkbox"
              value={this.state.checkedMovs[`${item.cond.movId}/${item.nItem}`]}
              onClick={() => this.toggleItem(item)}
              label=""
            />
          </Form.Group>
        ),
      },
      {
        label: "#",
        render: (item) => `${item.cond.num}`,
      },
      {
        label: "Emissão",
        render: (item) => moment(item.cond.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Cliente",
        render: (item) => `${item.cond.dest && item.cond.dest.name}`,
      },
      {
        label: "Produto",
        render: (item) => `${item.prod && item.prod.name}`,
      },
      {
        label: "Quantidade",
        render: (item) => formatNumber(item.qtd, 0),
      },
      {
        label: "Valor",
        render: (item) => formatNumber(item.valor, 2),
      },
      {
        label: "Total",
        render: (item) => formatNumber(item.total, 2),
      },
      {
        label: "Qtd Vendida",
        render: (item) => (
          <InputNumber
            decimalPlaces={0}
            value={this.state.qtdVendidas[`${item.cond.movId}/${item.nItem}`]}
            onChange={(val) => this.handleQtdVendida(item, val)}
          />
        ),
      },
    ];
    const data = _.filter(
      this.condsToItems(this.filterConds()),
      (item) => item.closed !== true
    );

    return (
      <>
        <Row>
          <Col>
            <h2>Baixa de Condicionais</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2 mb-2">
            <Button variant="primary" onClick={this.closeConds}>
              Baixar as condicionais selecionadas
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2 mb-2">
            {this.props.isSaleSaving && (
              <Alert variant="secondary">
                <FontAwesomeIcon icon={faSync} spin />
                Salvando venda
              </Alert>
            )}
          </Col>
          <Col xs={12} className="mt-2 mb-2">
            <ShowError error={this.props.saleSaveError} />
          </Col>
        </Row>
        <Row>
          <LoadingContainer isLoading={this.props.isRequesting}>
            <TableOrCard headers={headers} items={data} />
          </LoadingContainer>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cond.close.items,
    Count: state.cond.close.Count,
    lastKey: state.cond.close.lastKey,
    isRequesting: state.cond.close.isRequesting,
    isSaving: state.cond.close.isSaving,
    isSaleSaving: state.sales.form.isSaving,
    saleSaveError: state.sales.form.error,
    defaultEmit: state.login.defaultEmit,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: (payload) => dispatch(pageInit(payload)),
    closedRequesting: (mov, onFinishCallback) =>
      dispatch(closedRequesting(mov, onFinishCallback)),
    saveSale: (sale) => dispatch(formSave(sale)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CondClose);
