import React from "react";
import { FormikProps } from "formik";

type RadioInputProps = {
  name: string;
  label: string;
  size: number;
  options: Record<string, any>;
  formik: FormikProps<any>;
};

const RadioInput: React.FC<RadioInputProps> = (props) => (
  <div className={`form-group col-12 col-md-${props.size}`}>
    <label style={{ display: "block" }}>{props.label}</label>
    {Object.keys(props.options).map((key, i) => (
      <div className="form-check form-check-inline" key={`${i}`}>
        <input
          className="form-check-input"
          type="radio"
          name={props.name}
          id={`${props.name}${i}`}
          checked={props.formik.values[props.name] === props.options[key]}
          onChange={() =>
            props.formik.setFieldValue(props.name, props.options[key])
          }
        />
        <label className="form-check-label" htmlFor={`${props.name}${i}`}>
          {key}
        </label>
      </div>
    ))}
  </div>
);

export default RadioInput;
