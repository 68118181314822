export const SETISAUTHENTICATED = "SETISAUTHENTICATED";
export const SETORGID = "SETORGID";
export const SETORGSTATUS = "SETORGSTATUS";
export const SETACTIVEPLAN = "SETACTIVEPLAN";
export const SETUSERROLE = "SETUSERROLE";
export const SETCAD = "SETCAD";
export const LISTCAD = "LISTCAD";
export const DELCAD = "DELCAD";
export const ADDNOTIFICATION = "ADDNOTIFICATION";
export const DELNOTIFICATION = "DELNOTIFICATION";
export const SETMOV = "SETMOV";
export const LISTMOV = "LISTMOV";
export const DELMOV = "DELMOV";
export const SETXML = "SETXML";
export const DELXML = "DELXML";
export const SETTASK = "SETTASK";
export const SAVETASK = "SAVETASK";
export const LISTTASK = "LISTTASK";
export const SETNOTASPROM = "SETNOTASPROM";
export const SETDEFAULTEMIT = "SETDEFAULTEMIT";
export const GETDEFAULTEMIT = "GETDEFAULTEMIT";
export const SETORG = "SETORG";

// Types of cad
export const CAD_EMISSOR = "CAD_EMISSOR";
export const CAD_DEST = "CAD_DEST";
export const CAD_TRANSP = "CAD_TRANSP";
export const CAD_PROD = "CAD_PROD";
export const CAD_TIPOOP = "CAD_TIPOOP";
export const CAD_SERIE = "CAD_SERIE";
export const CAD_FORMAPAG = "CAD_FORMAPAG";

// Types of mov
export const MOV_SALES = "MOV_SALES";
export const MOV_INVOICE = "MOV_INVOICE";
export const MOV_TASKPANE = "MOV_TASKPANE";
export const MOV_TASK = "MOV_TASK";
export const MOV_CONDICIONAL = "MOV_CONDICIONAL";

export type reduxAction = {
  type: string;
  payload: any;
};

export type movKey = {
  movId: string;
  userId: string;
};

export type cadKey = {
  cadId: string;
  userId: string;
};

export type AWSRes<T> = {
  items: T[];
  Count: number;
  ScannedCount: number;
  lastKey?: movKey | cadKey;
};
