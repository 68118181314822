import { AWSRes } from "./../../redux/types";
// saga do modulo purchases
import { put, all, call, takeLatest, select } from "redux-saga/effects";
import formSaga from "./purchasesForm/saga";
import executeApiRequest from "../../misc/executeApiRequest";
import { PayloadAction } from "@reduxjs/toolkit";
import { PurchaseRequestAction, purchasesActions } from "./reducer";
import { Purchase } from "./types";
import { RootState } from "../app/mainReducer";

function purchasesCall({
  tipoPesquisa,
  termoPesquisa,
  tipoFiltro,
}: PurchaseRequestAction) {
  return executeApiRequest<AWSRes<Purchase>>(
    "/purchase",
    "GET",
    {},
    {
      queryStringParameters: {
        idFilterType: tipoFiltro,
        idQuery: termoPesquisa,
        indexToUse:
          tipoPesquisa === "NUM"
            ? undefined
            : tipoPesquisa === "DATA"
            ? "lsi3"
            : "lsi1",
      },
    }
  );
}

function* purchasesWorker({ payload }: PayloadAction<PurchaseRequestAction>) {
  try {
    const res: AWSRes<Purchase> = yield call(purchasesCall, payload);
    yield put(purchasesActions.purchasesSuccess(res));
  } catch (error) {
    yield put(purchasesActions.purchasesError(error));
  }
}

const getLastSearch = (state: RootState) =>
  state.purchases.list.lastPurchaseSearch;

function* purchasesDeleteWorker({ payload }: PayloadAction<Purchase>) {
  try {
    yield call(
      executeApiRequest,
      `/purchase/${payload.invoiceId}`,
      "DELETE",
      {},
      {}
    );
    yield put(purchasesActions.deleteSuccess());
    const searchObj = yield select(getLastSearch);
    yield put(purchasesActions.requestPurchases(searchObj));
  } catch (error) {
    yield put(purchasesActions.deleteError(error));
  }
}

export default function* purchasesSaga() {
  yield all([
    takeLatest("purchases/requestPurchases", purchasesWorker),
    takeLatest("purchases/requestDelete", purchasesDeleteWorker),
    formSaga(),
  ]);
}
