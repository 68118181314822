import { useField } from "formik";
import React, { InputHTMLAttributes, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModSelector from "../../../components/ModSelector";
import { RootState } from "../../app/mainReducer";
import { Emissor, init } from "../reducer";
import { Form } from "react-bootstrap";

export const EmissorField: React.FC<
  InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    name: string;
  }
> = ({ label, ...props }) => {
  const [field, { error, touched }, { setValue }] = useField<Emissor>(props);

  return (
    <>
      <label>{label}</label>
      <EmissorSelector
        value={field.value}
        onChange={(emissor) => setValue(emissor)}
      />
      {error && touched && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};

const EmissorSelector: React.FC<{
  value: Emissor;
  onChange?: (value: Emissor) => void;
}> = ({ value, onChange }) => {
  const emissores = useSelector((state: RootState) => state.emissor.list.items);

  const filterOptions = (emissor: Emissor, term: any) => {
    const reTerm = new RegExp(term.text, "i");
    return (
      reTerm.test(emissor.name) ||
      reTerm.test(emissor.cnpj) ||
      reTerm.test(emissor.address) ||
      reTerm.test(emissor.city)
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (emissores.length <= 0) {
      dispatch(init());
    }
  });

  return (
    <ModSelector
      options={emissores}
      renderOption={(emit) => `${emit.name} - CNPJ: ${emit.cnpj} - 
      Endereço: ${emit.address}, ${emit.addressNumber} - ${emit.city} - ${emit.estate}`}
      path="/emissor"
      value={value}
      onChange={onChange}
      filterBy={filterOptions}
      minLength={3}
    />
  );
};

export default EmissorSelector;
