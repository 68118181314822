// component do modulo purchases
import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingContainer from "../../components/LoadingContainer";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import TableOrCard from "../../components/TableOrCard";
import DeleteButton from "../../components/DeleteButton";
import dayjs from "dayjs";
import { formatNumber } from "../../misc/formatters";
import { PurchaseRequestAction, purchasesActions } from "./reducer";
import { RootState } from "../app/mainReducer";
import { Classificacoes, Purchase } from "./types";
import { format, startOfMonth } from "date-fns";
import DateSelector from "../../components/DateSelector";

type PurchasesProps = {
  items: Purchase[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isDeleting: boolean;
  requestPurchases: (payload: PurchaseRequestAction) => void;
  requestDelete: (purchase: Purchase) => void;
};

class Purchases extends Component<PurchasesProps, {}> {
  state = {
    tipoPesquisa: "DATA",
    datai: startOfMonth(new Date()),
    dataf: new Date(),
    numi: "",
    numf: "",
    classificacao: "",
  };

  headers = [
    {
      label: "Data",
      render: (item: Purchase) => dayjs(item.entry).format("DD/MM/YYYY"),
    },
    {
      label: "Numero",
      dataIndex: "num",
    },
    {
      label: "Fornecedor",
      render: (item: Purchase) =>
        item.dest ? `${item.dest.name}` : "Não informado",
    },
    {
      label: "Total ($)",
      render: (item: Purchase) =>
        formatNumber(
          item.items ? item.items.reduce((a, p) => a + p.total, 0) : 0,
          2
        ),
    },
    {
      label: "Ações",
      render: (item: Purchase) => (
        <ButtonGroup>
          <Button href={`/purchases/${item.invoiceId}`} variant="info">
            <i className="fas fa-edit" />
          </Button>
          <DeleteButton
            isRequesting={this.props.isDeleting}
            onDelete={() => this.handleDelete(item)}
          />
        </ButtonGroup>
      ),
    },
  ];

  componentDidMount() {
    this.pesquisa();
  }

  handleDelete = (item: Purchase) => {
    this.props.requestDelete(item);
  };

  pesquisa = () => {
    const objPesquisa: PurchaseRequestAction = {
      termoPesquisa: "",
      tipoFiltro: "START",
      tipoPesquisa: "DATA",
    };
    switch (this.state.tipoPesquisa) {
      case "DATA":
        objPesquisa.termoPesquisa = `${format(
          this.state.datai,
          "yyyy_MM_dd"
        )}:${format(this.state.dataf, "yyyy_MM_dd")}`;
        objPesquisa.tipoFiltro = "BETWEEN";
        break;
      case "NUM":
        objPesquisa.termoPesquisa = `${this.state.numi}:${this.state.numf}`;
        objPesquisa.tipoFiltro = "BETWEEN";
        break;
      case "CLASSIFICACAO":
        objPesquisa.termoPesquisa = `${format(
          this.state.datai,
          "yyyy_MM_dd"
        )}:${format(this.state.dataf, "yyyy_MM_dd")}`;
        objPesquisa.tipoFiltro = "BETWEEN";
        break;
    }
    this.props.requestPurchases(objPesquisa);
  };

  render() {
    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Compras</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              type="radio"
              inline
              checked={this.state.tipoPesquisa === "DATA"}
              onClick={() => this.setState({ tipoPesquisa: "DATA" })}
              name="tipo"
              id="inline-radio-1"
              label="Pesquisar por Data"
            />
            <Form.Check
              type="radio"
              inline
              checked={this.state.tipoPesquisa === "NUM"}
              onClick={() => this.setState({ tipoPesquisa: "NUM" })}
              name="tipo"
              id="inline-radio-2"
              label="Pesquisar por Numero"
            />
            <Form.Check
              type="radio"
              inline
              checked={this.state.tipoPesquisa === "CLASSIFICACAO"}
              onClick={() => this.setState({ tipoPesquisa: "CLASSIFICACAO" })}
              name="tipo"
              id="inline-radio-3"
              label="Pesquisar por Classificação"
            />
          </Col>
        </Row>
        {this.state.tipoPesquisa === "CLASSIFICACAO" && (
          <Row>
            <Col>Classificação:</Col>
            <Col>
              <Form.Control
                as="select"
                onChange={(ev) =>
                  this.setState({ classificacao: ev.target.value })
                }
                value={this.state.classificacao}
              >
                <option value={Classificacoes.CUSTO_FIXO}>Custo fixo</option>
                <option value={Classificacoes.CUSTO_VARIAVEL}>
                  Custo variavel
                </option>
                <option value={Classificacoes.DESPESA}>Despesa</option>
                <option value={Classificacoes.TRIBUTOS}>Tributos</option>
              </Form.Control>
            </Col>
          </Row>
        )}
        {(this.state.tipoPesquisa === "DATA" ||
          this.state.tipoPesquisa === "CLASSIFICACAO") && (
          <Row>
            <Col className="d-flex flex-wrap justify-content-start align-items-center">
              <label>Data:</label>
              <DateSelector
                id="datai"
                value={this.state.datai}
                className="w-25 d-inline-flex m-2"
                onChange={(datai) => this.setState({ datai })}
              />{" "}
              a
              <DateSelector
                id="dataf"
                value={this.state.dataf}
                className="w-25 d-inline-flex m-2"
                onChange={(dataf) => this.setState({ dataf })}
              />
            </Col>
          </Row>
        )}
        {this.state.tipoPesquisa === "NUM" && (
          <Row>
            <Col>Numero: </Col>
            <Col>
              <Form.Control
                type="text"
                value={this.state.numi}
                className="w-25 d-inline-flex m-2"
                onChange={(e) => this.setState({ numi: e.target.value })}
              />
              a
              <Form.Control
                type="text"
                value={this.state.numf}
                className="w-25 d-inline-flex m-2"
                onChange={(e) => this.setState({ numf: e.target.value })}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="mt-3 mb-3">
            <Button onClick={this.pesquisa} variant="primary" className="me-3">
              Pesquisar
            </Button>
            <Button href="/purchases/new" variant="primary">
              Incluir
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={this.props.items} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    items: state.purchases.list.items,
    Count: state.purchases.list.Count,
    lastKey: state.purchases.list.lastKey,
    isRequesting: state.purchases.list.isRequesting,
    isDeleting: state.purchases.list.isDeleting,
  };
};
const mapDispatchToProps = {
  requestPurchases: purchasesActions.requestPurchases,
  requestDelete: purchasesActions.requestDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchases);
