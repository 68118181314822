import React, { MouseEvent } from "react";
import moment from "moment";

import "./TimePicker.css";

type TimePickerProps = {
  value: Date;
  onChange: (value: Date) => void;
};

const TimePicker: React.FC<TimePickerProps> = (props) => {
  const hour = moment(props.value).format("HH");
  const minute = moment(props.value).format("mm");

  const incHour = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onChange(moment(props.value).add("h", 1).toDate());
  };

  const incMin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onChange(moment(props.value).add("m", 1).toDate());
  };

  const decHour = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onChange(moment(props.value).subtract("h", 1).toDate());
  };

  const decMin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onChange(moment(props.value).subtract("m", 1).toDate());
  };

  return (
    <div className="TimePicker">
      <div className="upperButtons">
        <button className="btn btn-light btn-sm" onClick={incHour}>
          <i className="fas fa-angle-up" />
        </button>
        <button className="btn btn-light btn-sm" onClick={incMin}>
          <i className="fas fa-angle-up" />
        </button>
      </div>
      <div className="values">
        <span>{hour}</span>:<span>{minute}</span>
      </div>
      <div className="lowerButtons">
        <button className="btn btn-light btn-sm" onClick={decHour}>
          <i className="fas fa-angle-down" />
        </button>
        <button className="btn btn-light btn-sm" onClick={decMin}>
          <i className="fas fa-angle-down" />
        </button>
      </div>
    </div>
  );
};

export default TimePicker;
