import { Dest } from "../dest/actions";
import { Emissor } from "../emissor/reducer";
import { Prod } from "../prod/actions";
import { AWSRes } from "./../../redux/types";
export const TIPOOP_INIT = "TIPOOP_INIT";
export const TIPOOP_REQUESTING = "TIPOOP_REQUESTING";
export const TIPOOP_SUCCESS = "TIPOOP_SUCCESS";
export const TIPOOP_ERROR = "TIPOOP_ERROR";
export const CAD_TYPE = "CAD_TIPOOP";
export const TIPOOP_DELETE_REQUESTING = "TIPOOP_DELETE_REQUESTING";
export const TIPOOP_DELETE_SUCCESS = "TIPOOP_DELETE_SUCCESS";
export const TIPOOP_DELETE_ERROR = "TIPOOP_DELETE_ERROR";

type Quando = {
  tipoObj: "Prod" | "NCM" | "Dest" | "UF" | "Emissor" | "TipoNF";
  operador: "eq" | "ne";
  prod?: Prod;
  ncm?: string;
  dest?: Dest;
  uf?: string;
  emissor?: Emissor;
  modelo?: "55" | "65";
};

export type Regra = {
  seq: number;
  quando: Quando[];
  csosn?: string;
  pCredSN?: number;
  cfop?: string;
  csticms?: string;
  modBcIcms?: string;
  pRedIcms?: number;
  pIcms?: number;
  pIcmsFcp?: number;
  modBcIcmsSt?: string;
  pMVA?: number;
  pRedBcIcmsSt?: number;
  pIcmsSt?: number;
  pIcmsStFcp?: number;
  cstipi?: string;
  pipi?: number;
  cstpis?: string;
  ppis?: number;
  cstcofins?: string;
  pcofins?: number;
  pIcmsUfDest?: number;
  pIcmsInter?: number;
  pIcmsFcpUfDest?: number;
};

export type Tipoop = {
  cadId?: string;
  userId?: string;
  cadType: "CAD_TIPOOP";
  cod: string;
  name: string;
  type: number;
  invoiceAuto: number;
  modoNfe: number;
  regras: Regra[];
};

export function pageInit() {
  return {
    type: TIPOOP_INIT,
  };
}

export function tipoopRequest() {
  return {
    type: TIPOOP_REQUESTING,
  };
}

export function tipoopSuccess(payload: AWSRes<Tipoop[]>) {
  return {
    type: TIPOOP_SUCCESS,
    payload,
  };
}

export function tipoopError(error: any) {
  return {
    type: TIPOOP_ERROR,
    error,
  };
}

export function tipoopDeleteRequest(cadId: string) {
  return {
    type: TIPOOP_DELETE_REQUESTING,
    payload: cadId,
  };
}

export function tipoopDeleteSuccess() {
  return {
    type: TIPOOP_DELETE_SUCCESS,
  };
}

export function tipoopDeleteError(error: any) {
  return {
    type: TIPOOP_DELETE_ERROR,
    error,
  };
}
