// saga do modulo transp
import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../misc/executeApiRequest";
import { AWSRes } from "./../../redux/types";
import {
  pageInit,
  Transp,
  transpDeleteError,
  transpDeleteSuccess,
  transpError,
  transpSuccess,
  TRANSP_DELETE_REQUESTING,
  TRANSP_INIT,
} from "./actions";
import formSaga from "./transpForm/saga";

function transpCall() {
  return executeApiRequest<AWSRes<Transp[]>>("/transp", "GET", {}, {});
}

function* transpWorker() {
  try {
    const res: AWSRes<Transp[]> = yield call(transpCall);
    yield put(transpSuccess(res));
  } catch (error) {
    yield put(transpError(error));
  }
}

function* transpDeleteWorker({ payload }: any) {
  try {
    yield call(executeApiRequest, `/transp/${payload}`, "DELETE", {}, {});
    yield put(transpDeleteSuccess());
    yield put(pageInit());
  } catch (error) {
    yield put(transpDeleteError(error));
  }
}

export default function* transpSaga() {
  yield all([
    takeLatest(TRANSP_INIT, transpWorker),
    takeLatest(TRANSP_DELETE_REQUESTING, transpDeleteWorker),
    formSaga(),
  ]);
}
