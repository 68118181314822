import React, { Component } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

type DestObsAddProps = {
  name: string;
  label: string;
  size: number;
  onAdd: (valor: string) => void;
  type?: string;
  cols?: number;
  rows?: number;
  placeholder?: string;
};

type DestObsAddState = {
  obsToAdd: string;
};

class DestObsAdd extends Component<DestObsAddProps, DestObsAddState> {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    onAdd: PropTypes.func.isRequired,
    type: PropTypes.string,
    cols: PropTypes.number,
    rows: PropTypes.number,
  };

  state = {
    obsToAdd: "",
  };

  obsField = React.createRef<HTMLTextAreaElement>();

  handleObsAdd = () => {
    if (!this.state.obsToAdd || `${this.state.obsToAdd}`.trim().length < 3) {
      return;
    }
    this.props.onAdd(this.state.obsToAdd);
    this.setState(
      {
        obsToAdd: "",
      },
      () => {
        if (this.obsField && this.obsField.current) {
          this.obsField.current.focus();
        }
      }
    );
  };

  render() {
    const isInvalid =
      !this.state.obsToAdd || `${this.state.obsToAdd}`.trim().length < 3;

    return (
      <div className={`form-group col-${this.props.size}`}>
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <textarea
          id={this.props.name}
          value={this.state.obsToAdd}
          cols={this.props.cols || 80}
          rows={this.props.rows || 5}
          ref={this.obsField}
          onChange={(e) => this.setState({ obsToAdd: e.target.value })}
          placeholder={this.props.placeholder}
          className="form-control text-input"
        />
        <Button
          className="float-right mt-2"
          disabled={isInvalid}
          variant="secondary"
          onClick={this.handleObsAdd}
        >
          Incluir
        </Button>
      </div>
    );
  }
}

export default DestObsAdd;
