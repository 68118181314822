import moment from "moment";

const geraParcelas = (
  vTotal: number,
  nparcelas = 0,
  emissao: Date = new Date()
) => {
  const parcelas = [];
  if (nparcelas === 0) {
    console.log("gerando 1 parcela", vTotal);
    parcelas.push({
      nParcela: 1,
      venc: new Date(),
      valor: vTotal,
    });
    return parcelas;
  }

  const vParcela = Math.round((100 * vTotal) / nparcelas) / 100;

  let soma = 0;

  for (let i = 0; i < nparcelas * 1; i++) {
    parcelas.push({
      nParcela: i + 1,
      venc: moment(emissao)
        .add(i + 1, "month")
        .toDate(),
      valor: vParcela,
    });
    soma += vParcela;
  }
  if (Math.abs(Math.round(100 * (vTotal - soma)) / 100) > 0) {
    parcelas[parcelas.length - 1].valor +=
      Math.round(100 * (vTotal - soma)) / 100;
  }

  return parcelas;
};

export default geraParcelas;
