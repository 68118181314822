// saga do form do modulo transp
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  TRANSP_FORM_INIT,
  TRANSP_SAVE_REQUESTING,
  formInitError,
  formInitSuccess,
  formSuccess,
  formError,
} from "./actions";
import { CAD_TYPE, Transp } from "../actions";
import executeApiRequest from "../../../misc/executeApiRequest";

const newTransp = (): Transp => {
  return {
    cadType: CAD_TYPE,
    name: "",
    cnpj: "",
    ie: "",
    zipCode: "",
    address: "",
    addressNumber: "",
    city: "",
    estate: "",
  };
};

function* transpInitWorker({ payload }: any) {
  try {
    if (payload === "new") {
      yield put(formInitSuccess(newTransp()));
    } else {
      const res = yield call(
        executeApiRequest,
        `/transp/${payload}`,
        "GET",
        {},
        {}
      );
      yield put(formInitSuccess(res));
    }
  } catch (error) {
    yield put(formInitError(error));
  }
}

function* transpSaveWorker({ payload }: any) {
  try {
    if (payload.cadId) {
      // Update
      const res = yield call(
        executeApiRequest,
        `/transp/${payload.cadId}`,
        "PUT",
        payload
      );
      yield put(formSuccess(res));
    } else {
      const res = yield call(executeApiRequest, "/transp", "POST", payload);
      yield put(formSuccess(res));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(formError(error.response.data));
  }
}

export default function* transpFormSaga() {
  yield all([
    takeLatest(TRANSP_FORM_INIT, transpInitWorker),
    takeLatest(TRANSP_SAVE_REQUESTING, transpSaveWorker),
  ]);
}
