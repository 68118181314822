import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../misc/executeApiRequest";
import { AWSRes } from "./../../redux/types";
import {
  pageInit,
  Serie,
  serieDeleteError,
  serieDeleteSuccess,
  serieError,
  serieSuccess,
  SERIE_DELETE_REQUESTING,
  SERIE_INIT,
} from "./actions";
import formSaga from "./serieForm/saga";

function serieCall() {
  return executeApiRequest<AWSRes<Serie>>("/serie", "GET", {}, {});
}

function* serieWorker() {
  try {
    const res: AWSRes<Serie> = yield call(serieCall);
    yield put(serieSuccess(res));
  } catch (error) {
    yield put(serieError(error));
  }
}

function* serieDeleteWorker({ payload }: any) {
  try {
    yield call(executeApiRequest, `/serie/${payload}`, "DELETE", {}, {});
    yield put(serieDeleteSuccess());
    yield put(pageInit());
  } catch (error) {
    yield put(serieDeleteError(error));
  }
}

export default function* serieSaga() {
  yield all([
    takeLatest(SERIE_INIT, serieWorker),
    takeLatest(SERIE_DELETE_REQUESTING, serieDeleteWorker),
    formSaga(),
  ]);
}
