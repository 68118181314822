// actions do form do modulo invoice
export const COND_FORM_INIT = "COND_FORM_INIT";
export const COND_FORM_SUCCESS = "COND_FORM_SUCCESS";
export const COND_FORM_ERROR = "COND_FORM_ERROR";
export const COND_SAVE_REQUESTING = "COND_SAVE_REQUESTING";
export const COND_SAVE_SUCCESS = "COND_SAVE_SUCCESS";
export const COND_SAVE_ERROR = "COND_SAVE_ERROR";

export function formInit(cadId: string) {
  return {
    type: COND_FORM_INIT,
    payload: cadId,
  };
}

export function formInitSuccess(item: any) {
  return {
    type: COND_FORM_SUCCESS,
    payload: item,
  };
}

export function formInitError(error: any) {
  return {
    type: COND_FORM_ERROR,
    error,
  };
}

export function formSave(item: any) {
  /// Create a number if doenst exists
  item.num = `${item.num}`;
  if (!item.num || (item.num && item.num.trim().length < 1)) {
    item.serialCreate = {
      num: "condicional",
    };
  }
  /// Updates to match the value of total itens in basket
  item.valor = item.items.reduce((p: any, c: any) => p + c.total, 0);

  return {
    type: COND_SAVE_REQUESTING,
    payload: item,
  };
}

export function formSuccess(item: any) {
  return {
    type: COND_SAVE_SUCCESS,
    payload: item,
  };
}

export function formError(error: any) {
  return {
    type: COND_SAVE_ERROR,
    error,
  };
}
