// component do modulo transp
import React, { Component } from "react";
import { connect } from "react-redux";
import { pageInit, Transp, transpDeleteRequest } from "./actions";
import LoadingContainer from "../../components/LoadingContainer";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import TableOrCard from "../../components/TableOrCard";
import DeleteButton from "../../components/DeleteButton";
import { Link } from "@reach/router";

type TranspsProps = {
  items: Transp[];
  Count: number;
  lastKey: any;
  isRequesting: boolean;
  isDeleting: boolean;
  pageInit: () => void;
  deleteRequest: (cadId: string) => void;
};

class Transps extends Component<TranspsProps, {}> {
  state = {};

  headers = [
    {
      label: "Razão Social",
      dataIndex: "name",
    },
    {
      label: "CNPJ",
      dataIndex: "cnpj",
    },
    {
      label: "Endereço",
      dataIndex: "address",
    },
    {
      label: "Numero",
      dataIndex: "addressNumber",
    },
    {
      label: "Cidade",
      dataIndex: "city",
    },
    {
      label: "UF",
      dataIndex: "estate",
    },
    {
      label: "Ações",
      render: (item: Transp) => (
        <ButtonGroup>
          <Button variant="info" as={Link} to={`/transp/${item.cadId}`}>
            <i className="fas fa-edit" />
          </Button>
          <DeleteButton
            isRequesting={this.props.isDeleting}
            onDelete={() => this.handleDelete(item)}
          />
        </ButtonGroup>
      ),
    },
  ];

  componentDidMount() {
    this.props.pageInit();
  }

  handleDelete = (item: Transp) => {
    this.props.deleteRequest(item.cadId);
  };

  render() {
    return (
      <LoadingContainer isLoading={this.props.isRequesting}>
        <Row>
          <Col>
            <h2>Transportadores</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <Button variant="primary" as={Link} to="/transp/new">
              Incluir
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <TableOrCard headers={this.headers} items={this.props.items} />
          </Col>
        </Row>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.transp.list.items,
    Count: state.transp.list.Count,
    lastKey: state.transp.list.lastKey,
    isRequesting: state.transp.list.isRequesting,
    isDeleting: state.transp.list.isDeleting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pageInit: () => dispatch(pageInit()),
    deleteRequest: (cadId: string) => dispatch(transpDeleteRequest(cadId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transps);
