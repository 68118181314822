import { Router } from "@reach/router";
import React from "react";
import AdminRoute from "./components/AdminRoute";
import AuthAndSubRoute from "./components/AuthAndSubRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import MasterRoute from "./components/MasterRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import { Caixa } from "./modules/caixa";
import Cond from "./modules/cond";
import CondClose from "./modules/cond/condClose";
import CondForm from "./modules/cond/condForm";
import CondPane from "./modules/cond/condPane";
import CondPrint from "./modules/cond/condPrint";
import Dashboard from "./modules/dashboard";
import Dest from "./modules/dest";
import DestForm from "./modules/dest/destForm";
import ImportarDest from "./modules/dest/importar";
import Emissor from "./modules/emissor";
import EmissorForm from "./modules/emissor/emissorForm";
import InitialWizard from "./modules/initalWizard";
import Invoice from "./modules/invoice";
import InvoiceForm from "./modules/invoice/invoiceForm";
import InvoicePane from "./modules/invoice/invoicePane";
import InvoicePay from "./modules/invoice/invoicePay";
import InvoicePrint from "./modules/invoice/invoicePrint";
import InvoiceReport from "./modules/invoice/invoiceReport";
import Login from "./modules/login";
import ConfirmEmail from "./modules/login/ConfirmEmail";
import ForgotPasswordN from "./modules/login/ForgotPassword";
import SignUp from "./modules/login/SignUp";
import Payment from "./modules/payment";
import PaymentForm from "./modules/payment/paymentForm";
import PaymentPay from "./modules/payment/paymentPay";
import Prod from "./modules/prod";
import ImportarProd from "./modules/prod/importar";
import ImportarProdXML from "./modules/prod/importar-xml";
import ProdForm from "./modules/prod/prodForm";
import Profile from "./modules/profile";
import Purchases from "./modules/purchases";
import PurchasesForm from "./modules/purchases/purchasesForm";
import Sales from "./modules/sales";
import QuickSale from "./modules/sales/QuickSale";
import SalesForm from "./modules/sales/salesForm";
import Serie from "./modules/serie";
import SerieForm from "./modules/serie/serieForm";
import SubscriptionPane from "./modules/subscription";
import Cancel from "./modules/subscription/Cancel";
import { Prices } from "./modules/subscription/Prices";
import { Subscribe } from "./modules/subscription/Subscribe";
import Subscriptions from "./modules/subscription/Subscriptions";
import Tasks from "./modules/task";
import Tipoop from "./modules/tipoop";
import TipoopForm from "./modules/tipoop/tipoopForm";
import Transp from "./modules/transp";
import TranspForm from "./modules/transp/transpForm";
import AdminOnly from "./pages/AdminOnly";
import Home from "./pages/Home";
import Maintenance from "./pages/Maitenance";
import PageNotFound from "./pages/PageNotFound";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import TermoUso from "./pages/TermoUso";
import { Invetory } from "./modules/prod/inventory";
import Bancos from "./modules/banco";
import BancoForm from "./modules/banco/bancoForm";
import { ViewPix } from "./modules/invoice/ViewPix";
import Services from "./modules/services";
import ServiceForm from "./modules/services/servicesForm";
import { WhatsConfig } from "./modules/whatsapp";

const MAITENANCE = false;

const Routes = () => {
  if (MAITENANCE) {
    return (
      <Router>
        <Maintenance path="*" />
      </Router>
    );
  }
  return (
    <Router>
      <AuthAndSubRoute as={Home} path="/" />

      <UnauthenticatedRoute as={Login} path="/login" />
      <UnauthenticatedRoute as={ConfirmEmail} path="/confirmation" />
      <UnauthenticatedRoute as={SignUp} path="/signup" />
      <UnauthenticatedRoute as={SignUp} path="/signup/:uid" />
      <UnauthenticatedRoute as={ForgotPasswordN} path="/forgot" />
      <UnauthenticatedRoute as={ViewPix} path="/pix/:id/:orgId" />

      <AuthAndSubRoute as={InvoicePane} path="/invoicePane" />
      <AuthAndSubRoute as={CondPane} path="/condPane" />
      <AuthAndSubRoute as={Tasks} path="/Tasks" />

      <AdminRoute as={Profile} path="/Profile" />
      <AuthenticatedRoute as={InitialWizard} path="/Wizard" />

      <AuthAndSubRoute as={InvoicePrint} path="/PrintNotaPromissoria" />
      <AuthAndSubRoute as={InvoicePay} path="/InvoicePay/:invoices" />
      <AuthAndSubRoute as={CondClose} path="/CondClose/:conds" />
      <AuthAndSubRoute as={CondPrint} path="/Condicional/:id" />
      <AuthAndSubRoute as={InvoiceReport} path="/RepInvoices" />
      <AuthAndSubRoute as={Emissor} path="/emissor" />
      <AdminRoute as={EmissorForm} path="/emissor/:id" />
      <AuthAndSubRoute as={Serie} path="/serie" />
      <AdminRoute as={SerieForm} path="/serie/:id" />
      <AuthAndSubRoute as={Tipoop} path="/tipoop" />
      <AdminRoute as={TipoopForm} path="/tipoop/:id" />
      <AuthAndSubRoute as={Prod} path="/prod" />
      <AuthAndSubRoute as={ProdForm} path="/prod/:id" />
      <AuthAndSubRoute as={Transp} path="/transp" />
      <AuthAndSubRoute as={TranspForm} path="/transp/:id" />
      <AuthAndSubRoute as={Dest} path="/dest" />
      <AuthAndSubRoute as={Dest} path="/destSearch/:destFilter" />
      <AuthAndSubRoute as={DestForm} path="/dest/:id" />
      <AuthAndSubRoute as={Invoice} path="/invoice" />
      <AuthAndSubRoute as={InvoiceForm} path="/invoice/:id" />
      <AuthAndSubRoute as={Cond} path="/cond" />
      <AuthAndSubRoute as={CondForm} path="/cond/:id" />
      <AuthAndSubRoute as={Sales} path="/sales" />
      <AuthAndSubRoute as={SalesForm} path="/sales/:id" />
      <AuthAndSubRoute as={SalesForm} path="/sales/:id/:duplicate" />
      <AuthAndSubRoute as={QuickSale} path="/quicksale" />
      <AuthAndSubRoute as={ImportarProd} path="/importar-prod" />
      <AuthAndSubRoute as={ImportarProdXML} path="/importar-prod-xml" />
      <AuthAndSubRoute as={ImportarDest} path="/importar-dest" />
      <AuthAndSubRoute as={Purchases} path="/purchases" />
      <AuthAndSubRoute as={PurchasesForm} path="/purchases/:id" />
      <AuthAndSubRoute as={Payment} path="/payment" />
      <AuthAndSubRoute as={PaymentForm} path="/payment/:id" />
      <AuthAndSubRoute as={PaymentPay} path="/paymentPay/:payments" />
      <AuthAndSubRoute as={Dashboard} path="/dashboard" />
      <AuthAndSubRoute as={Caixa} path="/caixa" />
      <AuthAndSubRoute as={Invetory} path="/inventory" />
      <AuthAndSubRoute as={Bancos} path="/banco" />
      <AuthAndSubRoute as={BancoForm} path="/banco/:id" />
      <AuthAndSubRoute as={Services} path="/services" />
      <AuthAndSubRoute as={ServiceForm} path="/service/:id" />
      <AuthAndSubRoute as={ServiceForm} path="/service/:id/:duplicateId" />

      <MasterRoute as={SubscriptionPane} path="subscription">
        <MasterRoute as={Prices} path="prices" />
        <MasterRoute as={Subscribe} path="subscribe" />
        <MasterRoute as={Subscriptions} path="subscriptions" />
        <MasterRoute as={Cancel} path="cancel/:subscriptionId" />
      </MasterRoute>
        <MasterRoute as={WhatsConfig} path="whatsConfig" />
      <AdminOnly path="/admin-only" />

      <TermoUso path="/termouso" />
      <PoliticaPrivacidade path="/politicaprivacidade" />
      <PageNotFound path="*" />
    </Router>
  );
};

export default Routes;
