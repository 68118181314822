import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect } from "@reach/router";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlanCard from "../../components/PlanCard";
import ShowError from "../../components/ShowError";
import { RootState } from "../app/mainReducer";
import { PriceListObject, subcsriptionActions } from "./reducer";
import _ from "underscore";

export const Prices: React.FC<{}> = () => {
  const planos: PriceListObject = useSelector(
    (state: RootState) => state.subscription.planos
  );
  const isSubscriptionLoading = useSelector(
    (state: RootState) => state.subscription.isSubscriptionRequesting
  );
  const subscriptionError = useSelector(
    (state: RootState) => state.subscription.subscriptionError
  );
  const subscription = useSelector(
    (state: RootState) => state.subscription.subscription
  );

  const dispatch = useDispatch();

  const createSubscription = async (planId) => {
    dispatch(subcsriptionActions.subscriptionRequest(planId));
  };

  const timeDif = subscription ? Date.now() - subscription.created * 1000 : 0;
  console.log("timeDif", timeDif, 23 * 3600 * 1000);
  if (!!subscription && !isSubscriptionLoading && 23 * 3600 * 1000 > timeDif) {
    return <Redirect to={"/subscription/subscribe"} />;
  }

  const planosOrdenados = _.sortBy(planos.data, (a) => a.unit_amount);
  console.log("planos Ordenados", planosOrdenados);

  return (
    <>
      <Row>
        <Col>
          <h2>Selecione um plano para continuar </h2>
        </Col>
      </Row>
      {isSubscriptionLoading && <FontAwesomeIcon icon={faSync} spin />}
      {!isSubscriptionLoading && subscriptionError && (
        <ShowError error={subscriptionError} />
      )}
      {!isSubscriptionLoading && (
        <Row>
          <Col xs={4} md={4} lg={3}>
            <PlanCard
              planName="Trial"
              planId="-"
              trialDays={30}
              selected={false}
              resources={["Uso ilimitado por 30 dias de teste."]}
              price={0}
              isEnabled={true}
              upgradeRequest={(planId) => console.log("Plano Trial", planId)}
            />
          </Col>
          {planosOrdenados.map((plan) => (
            <Col xs={4} md={4} lg={3} key={plan.id}>
              <PlanCard
                planName={`${plan.product.name} ${plan.nickname || ""}`}
                planId={plan.id}
                trialDays={10}
                selected={false}
                resources={Object.values(plan.product.metadata)}
                price={plan.unit_amount / 100}
                isEnabled={true}
                upgradeRequest={createSubscription}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};
