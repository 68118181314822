import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Emissor } from "../reducer";

type EmissorFormState = {
  item: Emissor;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isSaving: boolean;
  isConfigInterRequesting: boolean;
};

export type EmissorSaveObject = {
  newLogo?: File;
  newCertificate?: File;
  newInterCrt?: File;
  newInterKey?: File;
} & Emissor;

const initialState: EmissorFormState = {
  item: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
  isConfigInterRequesting: false,
};

const emissorFormSlice = createSlice({
  name: "emissor.form",
  initialState,
  reducers: {
    formInit: (state) => {
      state.item = null;
      state.isRequesting = true;
      state.successMsg = "";
      state.error = "";
      state.isConfigInterRequesting = false;
    },
    formSuccess: (state, action: PayloadAction<Emissor>) => {
      state.item = action.payload;
      state.isRequesting = false;
    },
    formError: (state, action: PayloadAction<string>) => {
      state.isRequesting = false;
      state.error = action.payload;
    },
    formSaveRequest: (state, _: PayloadAction<EmissorSaveObject>) => {
      state.isSaving = true;
      state.successMsg = "";
      state.error = "";
    },
    formSaveSuccess: (state, action: PayloadAction<Emissor>) => {
      state.isSaving = false;
      state.successMsg = "Emissor salvo com sucesso!";
      state.error = "";
      state.item = action.payload;
    },
    formSaveError: (state, action: PayloadAction<string>) => {
      state.isSaving = false;
      state.error = action.payload;
      state.successMsg = "";
    },
    configInter(state, _: PayloadAction<string>) {
      state.isConfigInterRequesting = true;
      state.successMsg = "";
      state.error = "";
    },
    configInterSuccess(state) {
      state.isConfigInterRequesting = false;
      state.successMsg = "Banco Inter configurado com sucesso!";
    },
    configInterError(state, { payload }: PayloadAction<string>) {
      state.isConfigInterRequesting = false;
      state.error = payload;
    },
  },
});
export const formActions = emissorFormSlice.actions;

export default emissorFormSlice.reducer;
