import { Prod } from "./actions";
import formReducer from "./prodForm/reducer";
import selectorReducer from "./selector/reducer";
import { combineReducers } from "redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AWSRes } from "../../redux/types";
import inventorySlice from "./inventory/reducer";

export type EstoqueRecord = {
  movId: string; // prodId_Data
  clientId: string; // prodId
  userId: string; // orgId_MOV_ESTOQUE
  num: number;
  data: Date;
  quantidade: number;
  valor_unitario: number;
  desc: string;
};

export type BuscarEstoquePayload = {
  data_inicial: Date;
  data_final: Date;
  prodId: string;
};

type ProdInitialState = {
  items: Prod[];
  Count: number;
  ScannedCount: number;
  lastKey: Record<string, string>;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isDeleting: boolean;
  isImportarRequesting: boolean;
  nimportados: number;
  nerros: number;
  importarError: string;
  isCreating: boolean;
  createError: string;
  isLoadingEstoque: boolean;
  estoqueError: string;
  itensEstoque: EstoqueRecord[];
};

const initialState: ProdInitialState = {
  items: [],
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isDeleting: false,
  isImportarRequesting: false,
  nimportados: 0,
  nerros: 0,
  importarError: "",
  isCreating: false,
  createError: "",
  isLoadingEstoque: false,
  estoqueError: "",
  itensEstoque: [],
};

export type PayloadImportarSuccess = {
  nimportados: number;
  nerros: number;
};

export type PayloadCreateEstoqueRequest = {
  prod: Prod;
  quantidade: number;
  desc: string;
  valor_unitario: number;
  data: Date;
};

const prodSlice = createSlice({
  name: "prod",
  initialState,
  reducers: {
    init(state) {
      state.items = [];
      state.Count = 0;
      state.ScannedCount = 0;
      state.lastKey = null;
      state.successMsg = "";
      state.error = "";
      state.isRequesting = true;
      state.isDeleting = false;
    },
    initSuccess(state, { payload }: PayloadAction<AWSRes<Prod>>) {
      state.items = payload.items;
      state.Count = payload.Count;
      state.ScannedCount = payload.ScannedCount;
      state.lastKey = payload.lastKey;
      state.isRequesting = false;
    },
    initError(state, { payload }: PayloadAction<string>) {
      state.isRequesting = false;
      state.error = payload;
    },
    deleteRequest(state, _: PayloadAction<string>) {
      state.isDeleting = true;
      state.successMsg = "";
      state.error = "";
    },
    deleteSuccess(state) {
      state.isDeleting = false;
      state.successMsg = "Produto removido com sucesso!";
    },
    deleteError(state, { payload }: PayloadAction<string>) {
      state.isDeleting = false;
      state.error = payload;
    },
    importarInit(state) {
      state.isImportarRequesting = false;
      state.nimportados = 0;
      state.nerros = 0;
      state.importarError = "";
    },
    importarRequest(state, _: PayloadAction<Prod[]>) {
      state.isImportarRequesting = true;
    },
    importarSuccess(state, { payload }: PayloadAction<PayloadImportarSuccess>) {
      state.isImportarRequesting = false;
      state.nimportados = payload.nimportados;
      state.nerros = payload.nerros;
    },
    importarError(state, { payload }: PayloadAction<string>) {
      state.isImportarRequesting = false;
      state.importarError = payload;
    },
    criarEstoqueRequest(state, _: PayloadAction<PayloadCreateEstoqueRequest>) {
      state.isCreating = true;
      state.createError = "";
    },
    criarEstoqueSuccess(state) {
      state.isCreating = false;
    },
    criarEstoqueError(state, { payload }: PayloadAction<string>) {
      state.isCreating = false;
      state.createError = payload;
    },
    buscarEstoqueRequest(state, _: PayloadAction<BuscarEstoquePayload>) {
      state.isLoadingEstoque = true;
      state.estoqueError = "";
      state.itensEstoque = [];
    },
    buscarEstoqueSuccess(
      state,
      { payload }: PayloadAction<AWSRes<EstoqueRecord>>
    ) {
      state.isLoadingEstoque = false;
      state.itensEstoque = payload.items;
    },
    buscarEstoqueError(state, { payload }: PayloadAction<string>) {
      state.isLoadingEstoque = false;
      state.estoqueError = payload;
    },
  },
});

export const prodActions = prodSlice.actions;

export default combineReducers({
  list: prodSlice.reducer,
  form: formReducer,
  selector: selectorReducer,
  inventory: inventorySlice.reducer,
});

/*
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PROD_INIT:
      return {
        ...state,
        items: [],
        Count: 0,
        ScannedCount: 0,
        lastKey: null,
        successMsg: "",
        error: "",
        isRequesting: true,
        isDeleting: false,
      };
    case PROD_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      };
    case PROD_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload.items,
        Count: action.payload.Count,
        ScannedCount: action.payload.ScannedCount,
        lastKey: action.payload.lastKey,
      };
    case PROD_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
        successMsg: "",
      };
    case PROD_DELETE_REQUESTING:
      return {
        ...state,
        isDeleting: true,
      };
    case PROD_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        successMsg: "Produto removido com sucesso!",
      };
    case PROD_DELETE_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        successMsg: "",
      };
    case PROD_IMPORTAR_INIT:
      return {
        ...state,
        isImportarRequesting: false,
        nimportados: 0,
        nerros: 0,
        importarError: action.error,
      };
    case PROD_IMPORTAR_REQUESTING:
      return {
        ...state,
        isImportarRequesting: true,
      };
    case PROD_IMPORTAR_SUCCESS:
      return {
        ...state,
        isImportarRequesting: false,
        nimportados: action.payload.nsucesso,
        nerros: action.payload.nerro,
      };
    case PROD_IMPORTAR_ERROR:
      return {
        ...state,
        isImportarRequesting: false,
        importarError: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list: reducer,
  form: formReducer,
  selector: selectorReducer,
});
*/
