// saga do form do modulo invoice
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import _ from "underscore";
import executeApiRequest from "../../../misc/executeApiRequest";
import { Invoice } from "../actions";
import { AWSRes } from "./../../../redux/types";
import {
  invoiceError,
  INVOICEPAY_INIT,
  INVOICEPAY_PAYED_REQUESTING,
  invoiceSuccess,
  pageInit,
  payedError,
  payedSuccess,
} from "./actions";

function invoiceCall(movIdBet: string, lastKey = null) {
  return executeApiRequest<AWSRes<Invoice>>(
    "/invoice",
    "GET",
    {},
    {
      queryStringParameters: {
        idQuery: movIdBet ? movIdBet : "",
        idFilterType: "BETWEEN",
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* invoiceInitWorker({ payload }: any) {
  try {
    const resFinal: AWSRes<Invoice> = {
      items: [],
      Count: 0,
      ScannedCount: 0,
    };
    if (!payload) {
      yield put(invoiceSuccess(resFinal));
    } else {
      const invoicesMap = payload.map((invoice) => invoice.split(":")[0]);
      const invoiceMin = _.min(invoicesMap);
      const invoiceMax = _.max(invoicesMap);

      const res: AWSRes<Invoice> = yield call(
        invoiceCall,
        `OPEN.${invoiceMin}:OPEN.${invoiceMax}ó`
      );
      resFinal.items = [...resFinal.items, ...res.items];
      resFinal.Count += res.Count;
      resFinal.ScannedCount += res.ScannedCount;
      resFinal.lastKey = res.lastKey;
      yield put(invoiceSuccess(resFinal));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(invoiceError(error.response ? error.response.data : error));
  }
}

function* invoiceCloseWorker({ payload }: any) {
  try {
    console.log("payload save", payload);

    // Update
    yield call(executeApiRequest, `/closeInvoice/1`, "PUT", payload);
    yield put(payedSuccess());
    yield put(pageInit());
  } catch (error) {
    console.log("error", error, error.response);
    yield put(payedError(error.response.data));
  }
}

export default function* invoiceFormSaga() {
  yield all([
    takeLatest(INVOICEPAY_INIT, invoiceInitWorker),
    takeEvery(INVOICEPAY_PAYED_REQUESTING, invoiceCloseWorker),
  ]);
}
