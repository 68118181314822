import { Redirect } from "@reach/router";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Formik } from "formik";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoaderButton from "../../components/LoaderButton";
import ShowError from "../../components/ShowError";
import { RootState } from "../app/mainReducer";
import { loginActions } from "../login/reducer";
import { subcsriptionActions } from "./reducer";

export const Subscribe: React.FC<{}> = () => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const initialValues = {
    nomeCompleto: "",
    address: "",
    phone: "",
  };

  const subscription = useSelector(
    (state: RootState) => state.subscription.subscription
  );
  const isSubscriptionRequesting = useSelector(
    (state: RootState) => state.subscription.isSubscriptionRequesting
  );
  const activeSubscription = useSelector(
    (state: RootState) => state.subscription.activeSubscription
  );
  console.log("activeSub", activeSubscription);
  const timeDif = !!subscription ? Date.now() - subscription.created * 1000 : 0;
  console.log(
    "timedif found",
    timeDif,
    subscription,
    subscription && new Date(subscription.created * 1000)
  );

  if (
    !isSubscriptionRequesting &&
    (!subscription || timeDif >= 23 * 3600 * 1000)
  ) {
    return <Redirect to={"/subscription/prices"} />;
  }

  return (
    <>
      <Row>
        <Col>
          <h1>Informe os dados de cobrança</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <ShowError error={errorMsg} />
        </Col>
      </Row>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          console.log("getting values", values);
          const cardElement = elements.getElement(CardElement);
          console.log(
            "Client secret",
            subscription.latest_invoice.payment_intent
          );

          try {
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              subscription.latest_invoice.payment_intent.client_secret,
              {
                payment_method: {
                  card: cardElement,
                  billing_details: {
                    name: values.nomeCompleto,
                    address: {
                      line1: values.address,
                    },
                    phone: values.phone,
                  },
                },
                setup_future_usage: "off_session",
              }
            );

            if (error) {
              console.log("error on process payment", error);
              setErrorMsg(error.message);
              return;
            }

            dispatch(subcsriptionActions.setPaymentIntent(paymentIntent));

            console.log("activeSubscription", activeSubscription);
            console.log("paymentIntent", paymentIntent);

            switch (paymentIntent.status) {
              case "succeeded":
                setSuccessMsg("Pagamento efetuado com sucesso!");
                // TODO Set the default payment method
                // paymentIntent.payment_method
                dispatch(
                  subcsriptionActions.savePaymentMethod(
                    paymentIntent.payment_method
                  )
                );
                if (activeSubscription) {
                  dispatch(
                    subcsriptionActions.subscriptionCancelRequest(
                      activeSubscription.id
                    )
                  );
                }
                break;
              case "processing":
                setSuccessMsg("Pagamento esta sendo processado!");
                break;
              case "requires_action":
                setSuccessMsg("Aguardando sua verificação do pagamento!");
                break;
              case "requires_capture":
              case "requires_confirmation":
                setSuccessMsg("Aguardando confirmação do pagamento!");
                break;
              case "requires_payment_method":
                setErrorMsg(
                  "As informações de cartão não foram preenchidas ou são inválidas!"
                );
                break;
              case "canceled":
                setErrorMsg("Pagamento cancelado!");
                break;
              default:
                console.log("paymentIntent", paymentIntent.status);
                setErrorMsg("Erro desconhecido!");
            }
            setTimeout(() => dispatch(loginActions.orgRequest()), 1000);
          } catch (error) {
            console.log("Error on process payment", error);
            setErrorMsg(
              `Erro ao processar pagamento: ${JSON.stringify(error)}`
            );
          }
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Row>
              <Form.Group as={Col} xs>
                <Form.Label>Nome Completo:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Nome Completo"
                  id="nomeCompleto"
                  value={props.values.nomeCompleto}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs>
                <Form.Label>Endereço:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Endereço"
                  id="address"
                  value={props.values.address}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </Form.Group>
              <Form.Group as={Col} xs>
                <Form.Label>Telefone:</Form.Label>
                <Form.Control
                  required
                  type="phone"
                  placeholder="Telefone"
                  id="phone"
                  value={props.values.phone}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="cartao">
                <Form.Label>Dados do cartão</Form.Label>
                <CardElement className="border border-dark rounded p-2 my-3" />
              </Form.Group>
            </Row>
            <Row>
              <Col>
                {!successMsg && (
                  <LoaderButton isLoading={props.isSubmitting} type="submit">
                    Confirmar
                  </LoaderButton>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <sup>
                  Ao confirmar voce estará aderindo ao plano e será debitado em
                  seu cartão!
                </sup>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
