import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { Form } from "react-bootstrap";
import DateSelector from "../DateSelector";

type DateFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
};

export const DateField: React.FC<DateFieldProps> = ({ label, ...props }) => {
  const [field, { error, touched }, { setValue }] = useField<Date>(props);

  return (
    <>
      <label>{label}</label>
      <DateSelector
        id={field.name}
        onChange={(dt) => setValue(dt)}
        value={field.value}
      />
      {error && touched && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};
