import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, takeLatest, put, select } from "redux-saga/effects";
import { Auth } from "aws-amplify";
import executeApiRequest from "../../misc/executeApiRequest";
import { s3Upload } from "../../misc/awsLib";
import { loginActions } from "../login/reducer";
import { RootState } from "../app/mainReducer";
import { FirstStepPayload, ThirdStepPayload, wizardActions } from "./reducer";

const getOrg = (state: RootState) => state.login.org;
const getEmissorToSave = (state: RootState) => state.wizard.emissorToSave;

function authUserCall() {
  return Auth.currentAuthenticatedUser();
}

function saveUserCall(user: string, newAttrs: Record<string, any>) {
  return Auth.updateUserAttributes(user, newAttrs);
}

function* firstStepWorker({ payload }: PayloadAction<FirstStepPayload>) {
  try {
    // Search actual user
    const actualUser = yield call(authUserCall);
    // Save the user
    yield call(saveUserCall, actualUser, payload.user);
    // Save the org
    yield put(wizardActions.firstStepSuccess());
  } catch (error) {
    console.log("firstStepError", error);
    yield put(wizardActions.firstStepError(error));
  }
}

/*
function* secondStepWorker({ payload }: PayloadAction<SecondStepPayload>) {
  try {
    const { emissor, org } = payload;

    // Save the emissor
    if (emissor.newLogo instanceof File) {
      // New File
      const logo = yield call(s3Upload, emissor.newLogo, org.cadId);
      emissor.logo = logo;
      delete emissor.newLogo;
    }
    if (emissor.newCertificate instanceof File) {
      const certificate = yield call(
        s3Upload,
        emissor.newCertificate,
        org.cadId
      );
      emissor.certificate = certificate;
      delete emissor.newCertificate;
    }
    const res = yield call(executeApiRequest, "/cad", "POST", emissor);

    const orgSaved = yield call(setDefaultEmissor, res.cadId, org);
    yield put(loginActions.orgSaveSuccess(orgSaved));
    yield put(wizardActions.secondStepSuccess());
  } catch (error) {
    console.log("secondStep error", error);
    yield put(wizardActions.secondStepError(error));
  }
}
    */

function* thirdStepWorker({ payload }: PayloadAction<ThirdStepPayload>) {
  try {
    const orgAtual = yield select(getOrg);
    const org = { ...orgAtual };
    const emissorToSave = yield select(getEmissorToSave);
    const emissor = { ...emissorToSave };
    // Save the emissor
    console.log("saving emissor");
    if (emissor.newLogo instanceof File) {
      // New File
      console.log("uploading logo");
      const logo = yield call(s3Upload, emissor.newLogo, org.cadId);
      console.log("logo uploaded");
      emissor.logo = logo;
      delete emissor.newLogo;
    }
    if (emissor.newCertificate instanceof File) {
      console.log("uploading certificate");
      const certificate = yield call(
        s3Upload,
        emissor.newCertificate,
        org.cadId
      );
      emissor.certificate = certificate;
      delete emissor.newCertificate;
    }
    console.log("saving emissor");
    const resEmissor = yield call(
      executeApiRequest,
      "/emissor",
      "POST",
      emissor
    );
    // Create the series
    const serie = {
      cod: `${payload.serie}`.trim().padStart(3, "0"),
      name: `Série ${payload.serie}`,
      cadType: "CAD_SERIE",
      initial: payload.serieStart * 1,
    };
    console.log("saving serie");
    yield call(executeApiRequest, `/serie`, "POST", serie);
    // Create the default TipoOp
    const tipoOp = {
      cod: "001",
      name: "Venda",
      type: 2,
      invoiceAuto: 1,
      modoNfe: 1,
      fin: 1,
      cadType: "CAD_TIPOOP",
    };
    console.log("saving tipo op");
    yield call(executeApiRequest, `/tipoop`, "POST", tipoOp);
    // Create the series Sequence
    // const serial = {
    //   serialId: serie.cod,
    //   serialNumber: payload.serieStart * 1,
    //   cadType: "SERIAL",
    // };
    // console.log("saving serial");
    // yield call(executeApiRequest, `/cad`, "POST", serial);
    org.orgStatus = "ACTIVE";
    org.emissorId = resEmissor.cadId;
    console.log("saving org", org);
    const orgSaved = yield call(executeApiRequest, `/org`, "PUT", org);
    yield put(loginActions.orgSaveSuccess(orgSaved));
    console.log("called wizard finish");
    yield put(wizardActions.thirdStepSuccess());
  } catch (error) {
    console.log("third step error", error);
    yield put(wizardActions.thirdStepError(error));
  }
}

export default function* wizardSaga() {
  yield all([
    takeLatest("wizard/firstStepRequest", firstStepWorker),
    takeLatest("wizard/thirdStepRequest", thirdStepWorker),
  ]);
}
