import {
  CLIENT_CONDS_ERROR,
  CLIENT_CONDS_REQUESTING,
  CLIENT_CONDS_SUCCESS,
} from "./actions";

const initialState = {
  items: [],
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  error: "",
  isRequesting: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLIENT_CONDS_REQUESTING:
      return {
        ...state,
        isRequesting: true,
        error: "",
      };
    case CLIENT_CONDS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload.items,
        Count: action.payload.Count,
        ScannedCount: action.payload.ScannedCount,
        lastKey: action.payload.lastKey,
      };
    case CLIENT_CONDS_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
