import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import DateSelector from "../../../components/DateSelector";
import LoadingContainer from "../../../components/LoadingContainer";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import { InvoiceParcela } from "../actions";
import { InvoiceReportRequestPayload, invoiceReportsActions } from "./reducer";

type InvoiceReportProps = {
  items: InvoiceParcela[];
  isRequesting: boolean;
  requestInvoices: (payload: InvoiceReportRequestPayload) => void;
};

type InvoiceReportState = {
  dataIni: Date;
  dataFim: Date;
  tipo: boolean;
  tipoData: string;
};

class InvoiceReport extends Component<InvoiceReportProps, InvoiceReportState> {
  state = {
    dataIni: moment().subtract(7, "days").toDate(),
    dataFim: new Date(),
    tipo: false,
    tipoData: "EMISSAO",
  };

  componentDidMount() {
    this.applyFilters();
  }

  applyFilters() {
    this.props.requestInvoices({
      dataIni: this.state.dataIni,
      dataFim: this.state.dataFim,
      tipo: this.state.tipo,
      tipoData: this.state.tipoData,
    });
  }

  render() {
    const total = this.props.items.reduce((p, c) => p + c.valor, 0);

    const headers = [
      {
        label: "#",
        render: (item: InvoiceParcela) => `${item.num}/${item.numparcela}`,
      },
      {
        label: "Emissão",
        render: (item: InvoiceParcela) =>
          moment(item.emission).format("DD/MM/YYYY"),
      },
      {
        label: "Cliente",
        render: (item: InvoiceParcela) => item.dest && item.dest.name,
      },
      {
        label: "Valor",
        render: (item: InvoiceParcela) => formatNumber(item.valor, 2),
      },
      {
        label: "Vencimento",
        render: (item: InvoiceParcela) =>
          moment(item.vencimento).format("DD/MM/YYYY"),
      },
    ];

    return (
      <>
        <Row>
          <Col>
            <h1>Relatório de Cobran&ccedil;as</h1>
          </Col>
        </Row>
        <Row className="mx-2">
          <Col>
            {/* Filter bar */}
            <h3>Filtros</h3>
            <Row xs={1} className="mt-1">
              <Col>
                <Form.Check
                  type="radio"
                  inline
                  checked={this.state.tipo}
                  onClick={() => this.setState({ tipo: true })}
                  name="tipo"
                  id="inline-radio-1"
                  label={"Títulos Abertos"}
                ></Form.Check>
                <Form.Check
                  type="radio"
                  inline
                  checked={!this.state.tipo}
                  onClick={() => this.setState({ tipo: false })}
                  name="tipo"
                  id="inline-radio-2"
                  label={"Títulos Fechados"}
                ></Form.Check>
              </Col>
            </Row>
            <Row xs={1} sm={2} md={3} lg={6} xl={12}>
              <Form.Group as={Col}>
                <Form.Label>Data a considerar</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(ev) =>
                    this.setState({ tipoData: ev.target.value })
                  }
                  value={this.state.tipoData}
                >
                  <option value={"EMISSAO"}>Emissão</option>
                  <option value={"VENCIMENTO"}>Vencimento</option>
                  {!this.state.tipo && <option value={"BAIXA"}>Baixa</option>}
                </Form.Control>
              </Form.Group>
            </Row>
            <Row xs={1} className="mt-1">
              <Col>
                <DateSelector
                  id="dataIni"
                  value={this.state.dataIni}
                  className="w-25 d-inline-flex m-2"
                  onChange={(dataIni) => this.setState({ dataIni })}
                />
                a
                <DateSelector
                  id="dataFim"
                  value={this.state.dataFim}
                  className="w-25 d-inline-flex m-2"
                  onChange={(dataFim) => this.setState({ dataFim })}
                />
              </Col>
            </Row>
            <Row xs={1} sm={2} md={3} lg={6} xl={12}>
              <Col>
                <Button className="m-2" onClick={() => this.applyFilters()}>
                  Consultar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <LoadingContainer isLoading={this.props.isRequesting}>
          <TableOrCard headers={headers} items={this.props.items} />
          <Row className="bg-dark text-light pt-2 pb-2">
            <Col>
              <b>Total de titulos: </b> {this.props.items.length} - R${" "}
              {formatNumber(total, 2)}
            </Col>
          </Row>
        </LoadingContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.invoice.report.Items,
    isRequesting: state.invoice.report.isRequesting,
  };
};
const mapDispatchToProps = {
  requestInvoices: invoiceReportsActions.requestInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReport);
