import { combineReducers } from "redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AWSRes } from "../../redux/types";
import bancoFormReducer from "./bancoForm/reducer";
import { Banco } from "./types";
import bancoSelectorReducer from "./selector/reducer";

type TInitialState = {
  itens: Banco[];
  Count: number;
  lastKey: Record<string, string>;
  isLoading: boolean;
  error: string;
  isDeleting: boolean;
  deleteSuccessMsg: string;
};

const initialState: TInitialState = {
  itens: [],
  Count: 0,
  lastKey: null,
  isLoading: false,
  error: "",
  isDeleting: false,
  deleteSuccessMsg: "",
};

const bancoReducer = createSlice({
  name: "banco",
  initialState,
  reducers: {
    requestBancos(
      state,
      { payload }: PayloadAction<{ lastKey?: Record<string, string> }>
    ) {
      if (!payload.lastKey) {
        state.itens = [];
        state.Count = 0;
        state.lastKey = null;
      }
      state.isLoading = true;
      state.error = "";
    },
    requestSuccess(state, { payload }: PayloadAction<AWSRes<Banco>>) {
      state.itens = [...state.itens, ...payload.items];
      state.Count += payload.Count;
      state.lastKey = payload.lastKey;
      state.isLoading = false;
    },
    requestError(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    deleteRequest(state, _: PayloadAction<string>) {
      state.isDeleting = true;
    },
    deleteSuccess(state) {
      state.isDeleting = false;
      state.deleteSuccessMsg = `Registro removido com sucesso!`;
    },
    deleteError(state, { payload }: PayloadAction<string>) {
      state.isDeleting = false;
      state.error = payload;
    },
  },
});

export const bancoActions = bancoReducer.actions;

const reducers = combineReducers({
  list: bancoReducer.reducer,
  form: bancoFormReducer.reducer,
  selector: bancoSelectorReducer.reducer,
});

export default reducers;
