import { RouteComponentProps, useParams } from "@reach/router";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Container,
  ButtonGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoadingContainer from "../../../components/LoadingContainer";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import { RootState } from "../../app/mainReducer";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import DateInput from "../../../components/FormikComponents/DateInput";
import SerieSelector from "../../serie/selector";
import TipoOpSelector from "../../tipoop/selector";
import EmissorSelector from "../../emissor/selector";
import DestSelector from "../../dest/selector";
import TextAreaInput from "../../../components/FormikComponents/TextAreaInput";
import { formatDate, formatNumber } from "../../../misc/formatters";
import TableOrCard from "../../../components/TableOrCard";
import InputNumber from "../../../components/InputNumber";
import LoaderButton from "../../../components/LoaderButton";
import { servicesFormActions } from "./reducer";
import { SelectField } from "../../../components/FormikComponents/SelectField";
import { NumberField } from "../../../components/FormikComponents/NumberField";
import { CheckField } from "../../../components/FormikComponents/CheckField";
import { TextField } from "../../../components/FormikComponents/TextField";
import { Service } from "../type";
import geraParcelas from "../../../misc/geraParcelas";

const ServiceParcelas: React.FC<{ servico: Service }> = ({ servico }) => {
  if (!servico.geraCobranca || servico.items.length < 1) {
    return null;
  }
  const parcelas = geraParcelas(
    servico.items.reduce((a, p) => a + p.quantidade * p.valor, 0) -
      (servico.desconto || 0),
    servico.nparcelas,
    servico.emission
  );
  console.log("parcelas", parcelas, servico.emission);
  const headers = [
    {
      label: "#",
      dataIndex: "nParcela",
    },
    {
      label: "Vencimento",
      render: (item) => formatDate(item.venc),
    },
    {
      label: "Valor",
      render: (item) => formatNumber(item.valor, 2),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <h2>Parcelas</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableOrCard headers={headers} items={parcelas} />
        </Col>
      </Row>
    </>
  );
};

const ServiceForm: React.FC<RouteComponentProps> = () => {
  const isRequesting = useSelector(
    (state: RootState) => state.services.form.isRequesting
  );
  const item = useSelector(
    (state: RootState) => state.services.form.item || {}
  );
  const successMsg = useSelector(
    (state: RootState) => state.services.form.successMsg
  );
  const error = useSelector((state: RootState) => state.services.form.error);
  const isSaving = useSelector(
    (state: RootState) => state.services.form.isSaving
  );

  const [isItemEditOpen, setIsItemEditOpen] = useState(false);
  const [itemEditIndex, setItemEditIndex] = useState(-1);

  const handleEditItem = (index: number) => {
    setItemEditIndex(index);
    setIsItemEditOpen(true);
  };
  const handleDeleteItem = (props: FormikProps<any>, index: number) => {
    const items = props.values.items;
    items.splice(index, 1);
    props.setFieldValue("items", items);
  };
  const handleAddItem = (props: FormikProps<any>) => {
    const items = [...(props.values.items || [])];
    items.push({
      nItem: items.length + 1,
      prod: null,
      qtd: 1,
      valor: 0,
      total: 0,
    });
    props.setFieldValue("items", items);
    setItemEditIndex(items.length - 1);
    setIsItemEditOpen(true);
  };

  const { id, duplicateId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("Requesting", id);
    dispatch(
      servicesFormActions.serviceRequest({ invoiceId: id, duplicateId })
    );
  }, [id, dispatch, duplicateId]);

  return (
    <Container className="mt-3" fluid>
      <Row>
        <Col>
          <h1>Serviço</h1>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3 mb-3">
          <ShowSuccess message={successMsg} />
        </Col>
      </Row>
      <Row>
        <Col className="mt-3 mb-3">
          <ShowError error={error} />
        </Col>
      </Row>
      <LoadingContainer isLoading={isRequesting}>
        <Formik
          onSubmit={(val) => {
            const values = { ...val };

            if (values.geraCobranca && values.items.length > 0) {
              values.parcelas = geraParcelas(
                values.items.reduce((a, p) => a + p.quantidade * p.valor, 0) -
                  (values.desconto || 0),
                values.nparcelas,
                values.emission
              );
            }
            console.log("values", values);
            if (values.num === undefined || `${values.num}`.trim().length < 1) {
              values.serialCreate = {
                num: values.serie ? `S${values.serie.cod}` : "SERVICE",
              };
            }
            dispatch(servicesFormActions.saveRequest(values));
          }}
          initialValues={item}
          enableReinitialize
          validationSchema={Yup.object().shape({
            num: Yup.string(),
            geraCobranca: Yup.bool(),
            status: Yup.string(),
            emission: Yup.date().required(),
            serie: Yup.object(),
            emissor: Yup.object(),
            tipoop: Yup.object(),
            dest: Yup.object(),
            descricao: Yup.string().required(),
            items: Yup.array().required(),
          })}
        >
          {(props) => {
            console.log("values", props.values);
            const headerItems = [
              {
                label: "#",
                dataIndex: "nItem",
              },
              {
                label: "Produto",
                render: (item) =>
                  item.prod ? `${item.prod.cod} - ${item.prod.name}` : "",
              },
              {
                label: "Quantidade",
                dataIndex: "quantidade",
              },
              {
                label: "Valor Un.",
                render: (item) => formatNumber(item.valor, 2),
              },
              {
                label: "Total",
                render: (item) => formatNumber(item.valor * item.quantidade, 2),
              },
              {
                label: "Ações",
                render: (_, i) => (
                  <>
                    <ButtonGroup>
                      <Button variant="info" onClick={() => handleEditItem(i)}>
                        <i className="fas fa-edit" />
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteItem(props, i)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </ButtonGroup>
                  </>
                ),
              },
            ];

            const handleChange = (obj: any) => {
              const item = {
                ...props.values.items[itemEditIndex],
                ...obj,
              };

              const items = [...props.values.items];
              items[itemEditIndex] = item;
              props.setFieldValue("items", items);
            };

            return (
              <Form onSubmit={props.handleSubmit}>
                <Modal
                  show={isItemEditOpen}
                  size="xl"
                  onHide={() => setIsItemEditOpen(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Item do Serviço</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* Form do item */}
                    {itemEditIndex >= 0 &&
                      props.values.items[itemEditIndex] && (
                        <>
                          <Row>
                            <Form.Group
                              as={Col}
                              controlId="descricao"
                              xs={12}
                              md={8}
                            >
                              <Form.Label>Descrição</Form.Label>
                              <Form.Control
                                value={
                                  props.values.items[itemEditIndex].descricao
                                }
                                onChange={(e) =>
                                  handleChange({ descricao: e.target.value })
                                }
                              />
                            </Form.Group>
                            <Form.Group as={Col} controlId="qtd" xs={12} md={2}>
                              <Form.Label>Quantidade</Form.Label>
                              <InputNumber
                                placeholder="Quantidade"
                                required
                                value={
                                  props.values.items[itemEditIndex].quantidade
                                }
                                decimalPlaces={0}
                                onChange={(quantidade) =>
                                  handleChange({
                                    quantidade,
                                    total: item.valor * quantidade,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              controlId="valor"
                              xs={12}
                              md={2}
                            >
                              <Form.Label>Valor Unitário</Form.Label>
                              <InputNumber
                                required
                                placeholder="Valor"
                                value={props.values.items[itemEditIndex].valor}
                                decimalPlaces={2}
                                onChange={(valor) =>
                                  handleChange({
                                    valor,
                                    total:
                                      props.values.items[itemEditIndex]
                                        .quantidade * valor,
                                  })
                                }
                              />
                            </Form.Group>
                          </Row>
                        </>
                      )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => setIsItemEditOpen(false)}
                    >
                      Concluir
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Row>
                  <MaskInput
                    name="num"
                    label="Numero"
                    mask="9999999999"
                    formik={props}
                    size={1}
                  />
                  <DateInput
                    name="emission"
                    label="Emissão"
                    formik={props}
                    size={2}
                  />
                  <Form.Group as={Col} controlId="tipoop" xs={12} md={4}>
                    <Form.Label>Tipo de Operação</Form.Label>
                    <TipoOpSelector
                      onChange={(tipoop) =>
                        props.setFieldValue("tipoop", tipoop)
                      }
                      value={props.values.tipoop}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="serie" xs={12} md={3}>
                    <Form.Label>Série</Form.Label>
                    <SerieSelector
                      onChange={(serie) => props.setFieldValue("serie", serie)}
                      value={props.values.serie}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="emissor" xs={12}>
                    <Form.Label>Emissor</Form.Label>
                    <EmissorSelector
                      onChange={(emissor) =>
                        props.setFieldValue("emissor", emissor)
                      }
                      value={props.values.emissor}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="dest" xs={12}>
                    <Form.Label>Cliente</Form.Label>
                    <DestSelector
                      onChange={(dest) => props.setFieldValue("dest", dest)}
                      value={props.values.dest}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <TextAreaInput
                    name="descricao"
                    label="Descrição"
                    formik={props}
                    size={12}
                  />
                </Row>
                <Row>
                  <Col xs={12} md={2}>
                    <CheckField name="geraCobranca" label="Gera Cobrança?" />
                  </Col>
                  {props.values.geraCobranca && (
                    <>
                      <Col xs={12} md={3}>
                        <NumberField
                          name="nparcelas"
                          label="Numero de Parcelas"
                          decimalPlaces={0}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        <NumberField
                          name="desconto"
                          label="Desconto Financeiro"
                          decimalPlaces={2}
                        />
                      </Col>
                    </>
                  )}
                  <Col xs={12} md={5}>
                    <SelectField name="status" label="Status" size={5}>
                      <option value="OPEN">Iniciado</option>
                      <option value="DOING">Em andamento</option>
                      <option value="WAITING">Aguardando terceiros</option>
                      <option value="CLOSED">Finalizado</option>
                    </SelectField>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={5}>
                    <SelectField
                      name="naturezaOperacao"
                      label="Natureza da Operação"
                    >
                      <option value="1">Tributação no município</option>
                      <option value="2">Tributação fora do município</option>
                      <option value="3">Isenção</option>
                      <option value="4">Imune</option>
                      <option value="5">
                        Exigibilidade suspensa por decisão judicial
                      </option>
                      <option value="6">
                        Exigibilidade suspensa por procedimento administrativo
                      </option>
                    </SelectField>
                  </Col>
                  <Col xs={12} md={3}>
                    <NumberField
                      name="aliquotaIss"
                      label="Aliquota de ISS"
                      decimalPlaces={2}
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <TextField
                      name="codTributacaoMunicipio"
                      label="Código Tributação do Municipio"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Itens</h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3 mb-3">
                    <Button
                      variant="primary"
                      onClick={() => handleAddItem(props)}
                    >
                      Incluir Item
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TableOrCard
                      headers={headerItems}
                      items={props.values.items}
                    />
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col xs={12} md={3}>
                    <div className="d-block border rounded me-2 pt-1 pb-2 bg-white">
                      <span className="d-block lead ps-2">Total</span>
                      <span className="d-block mt-3 ps-3 lead fw-bold">
                        R${" "}
                        {formatNumber(
                          props.values?.items?.reduce(
                            (p, c) => p + c.valor * c.quantidade,
                            0
                          ),
                          2
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
                <ServiceParcelas servico={props.values} />
                <Row>
                  <Col className="mt-3 mb-4">
                    <LoaderButton
                      isLoading={isSaving}
                      disabled={!props.isValid}
                      type="submit"
                    >
                      Salvar
                    </LoaderButton>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </LoadingContainer>
    </Container>
  );
};

export default ServiceForm;
