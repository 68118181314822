import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import notification from "../../redux/notification";
import emissorReducer from "../emissor/reducer";
import serieReducer from "../serie/reducer";
import tipoopReducer from "../tipoop/reducer";
import prodReducer from "../prod/reducer";
import transpReducer from "../transp/reducer";
import destReducer from "../dest/reducer";
import invoiceReducer from "../invoice/reducer";
import condReducer from "../cond/reducer";
import salesReducer from "../sales/reducer";
import taskReducer from "../task/reducer";
import loginReducer from "../login/reducer";
import profileReducer from "../profile/reducer";
import initialWizardReducer from "../initalWizard/reducer";
import purchasesReducer from "../purchases/reducer";
import paymentReducer from "../payment/reducer";
import subscriptionReducer from "../subscription/reducer";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistReducer, persistStore } from "redux-persist";
import logger from "redux-logger";
import mainSaga from "./mainSaga";
import dashboardSlice from "../dashboard/reducer";
import caixaSlice from "../caixa/reducer";
import bancoReducer from "../banco/reducer";
import serviceReducer from "../services/reducer";
import whatsappReducer from "../whatsapp/reducer";

const sagaMiddleware = createSagaMiddleware();

// Our root reducer starts with the initial state
// and must return a representation of the next state
export const appReducer = combineReducers({
  notification: notification,
  emissor: emissorReducer,
  serie: serieReducer,
  tipoop: tipoopReducer,
  prod: prodReducer,
  transp: transpReducer,
  dest: destReducer,
  invoice: invoiceReducer,
  cond: condReducer,
  sales: salesReducer,
  task: taskReducer,
  login: loginReducer,
  profile: profileReducer,
  wizard: initialWizardReducer,
  purchases: purchasesReducer,
  payment: paymentReducer,
  subscription: subscriptionReducer,
  dashboard: dashboardSlice.reducer,
  caixa: caixaSlice.reducer,
  banco: bancoReducer,
  services: serviceReducer,
  whats: whatsappReducer,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware, logger],
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(mainSaga);
