import {
  CONDCLOSE_INIT,
  CONDCLOSE_REQUESTING,
  CONDCLOSE_SUCCESS,
  CONDCLOSE_ERROR,
  CONDCLOSE_CLOSE_REQUESTING,
  CONDCLOSE_CLOSE_SUCCESS,
  CONDCLOSE_CLOSE_ERROR,
} from "./actions";

const initialState = {
  items: [],
  Count: 0,
  ScannedCount: 0,
  lastKey: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CONDCLOSE_INIT:
      return {
        ...state,
        error: "",
        successMsg: "",
        isRequesting: true,
      };
    case CONDCLOSE_REQUESTING:
      return {
        ...state,
      };
    case CONDCLOSE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isSaving: false,
        items: action.payload.items,
        Count: action.payload.Count,
        ScannedCount: action.payload.ScannedCount,
        lastKey: action.payload.lastKey,
      };
    case CONDCLOSE_ERROR:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
        isSaving: false,
      };
    case CONDCLOSE_CLOSE_REQUESTING:
      return {
        ...state,
        error: "",
        successMsg: "",
        isSaving: true,
      };
    case CONDCLOSE_CLOSE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        successMsg: "Baixado com sucesso!",
      };
    case CONDCLOSE_CLOSE_ERROR:
      return {
        ...state,
        isSaving: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
