import React, {
  useState,
  useEffect,
  MouseEventHandler,
  MouseEvent,
} from "react";
import moment from "moment";
import "moment/locale/pt-br";

import "./Calendar.css";

moment.locale("pt-br");

type DayProps = {
  selected: string | boolean;
  day: string;
  ind: number;
  onClick: MouseEventHandler<HTMLButtonElement>;
};
const Day: React.FC<DayProps> = (props) => (
  <button
    className={`btn ${
      props.selected === props.day
        ? "btn-primary"
        : props.ind === 0 || props.ind === 6
        ? "btn-outline-secondary"
        : "btn-light"
    } btn-sm`}
    onClick={props.onClick}
  >
    {props.day}
  </button>
);

type CalendarProps = {
  value: Date;
  onChange?: (value: Date) => void;
};

const Calendar: React.FC<CalendarProps> = (props) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  useEffect(() => {
    setSelectedMonth(props.value);
  }, [props.value]);

  const prevMonth = (e: MouseEvent) => {
    e.preventDefault();
    setSelectedMonth(moment(selectedMonth).subtract(1, "month").toDate());
  };

  const nextMonth = (e: MouseEvent) => {
    e.preventDefault();
    setSelectedMonth(moment(selectedMonth).add(1, "month").toDate());
  };

  const prevYear = (e: MouseEvent) => {
    e.preventDefault();
    setSelectedMonth(moment(selectedMonth).subtract(1, "year").toDate());
  };

  const nextYear = (e: MouseEvent) => {
    e.preventDefault();
    setSelectedMonth(moment(selectedMonth).add(1, "year").toDate());
  };

  const changeDay = (day: string) => {
    const newDate = moment(selectedMonth)
      .startOf("month")
      .add(parseInt(day) * 1 - 1, "day")
      .toDate();
    typeof props.onChange === "function" && props.onChange(newDate);
  };

  const selectedDay = (): string | boolean => {
    if (
      props.value &&
      moment(props.value).month() === moment(selectedMonth).month() &&
      moment(props.value).year() === moment(selectedMonth).year()
    ) {
      return moment(props.value).format("DD");
    }
    return false;
  };

  const days = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  const weeks: (number | string)[][] = [];
  const startOfMonth = moment(selectedMonth).startOf("month").day();
  days.forEach((d) => {
    if (weeks.length === 0) {
      const initalWeek = [];
      for (let i = 0; i < startOfMonth; i++) {
        initalWeek.push("");
      }
      initalWeek.push(d);
      weeks.push(initalWeek);
    } else {
      if (weeks[weeks.length - 1].length < 7) {
        weeks[weeks.length - 1].push(d);
      } else {
        weeks.push([d]);
      }
    }
  });
  const weeksTable = weeks.map((week, i) => (
    <tr key={i}>
      {week.map((d, k) => (
        <td key={k}>
          {d && (
            <Day
              selected={selectedDay()}
              onClick={(e) => {
                e.preventDefault();
                changeDay(`${d}`);
              }}
              ind={k}
              day={`${d}`}
            />
          )}
        </td>
      ))}
    </tr>
  ));
  return (
    <div className="Calendar">
      <div className="Calendar-head">
        <button className="btn btn-light btn-sm" onClick={prevYear}>
          <i className="fas fa-angle-double-left" />
        </button>
        <button className="btn btn-light btn-sm" onClick={prevMonth}>
          <i className="fas fa-angle-left" />
        </button>
        {moment(selectedMonth).format("MMMM/YYYY")}
        <button className="btn btn-light btn-sm" onClick={nextMonth}>
          <i className="fas fa-angle-right" />
        </button>
        <button className="btn btn-light btn-sm" onClick={nextYear}>
          <i className="fas fa-angle-double-right" />
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>D</th>
            <th>S</th>
            <th>T</th>
            <th>Q</th>
            <th>Q</th>
            <th>S</th>
            <th>S</th>
          </tr>
        </thead>
        <tbody>{weeksTable}</tbody>
      </table>
    </div>
  );
};

export default Calendar;
