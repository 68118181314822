import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderButton from "../../components/LoaderButton";
import { ButtonProps } from "react-bootstrap";
import { Sale } from "./types";
import { salesActions } from "./reducer";
import { PayloadAction } from "@reduxjs/toolkit";

type GeraNfeButtonStateProps = {
  isGeranfeRequesting: boolean;
};
type GeraNfeButtonDispatchProps = {
  geranfeRequest: (payload: {
    sale: Sale;
    onSuccessDispatch?: () => PayloadAction;
  }) => void;
};

type GeraNfeButtonOwnProps = {
  sale: Sale;
  onSuccessDispatch?: () => PayloadAction;
  disabled?: boolean;
  children: React.ReactNode;
} & ButtonProps;

class GeraNfeButton extends Component<
  GeraNfeButtonOwnProps & GeraNfeButtonStateProps & GeraNfeButtonDispatchProps,
  {}
> {
  handleNFeRequest = (e) => {
    e.preventDefault();
    if (!!this.props.sale) {
      this.props.geranfeRequest({
        sale: this.props.sale,
        onSuccessDispatch: this.props.onSuccessDispatch,
      });
    }
  };

  render() {
    return (
      <LoaderButton
        {...this.props}
        isLoading={this.props.isGeranfeRequesting}
        disabled={this.props.disabled || !this.props.sale}
        onClick={this.handleNFeRequest}
      >
        {this.props.children}
      </LoaderButton>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isGeranfeRequesting: state.sales.list.isGeranfeRequesting,
  };
};
const mapDispatchToProps = {
  geranfeRequest: salesActions.geranfeRequest,
};

export default connect<
  GeraNfeButtonStateProps,
  GeraNfeButtonDispatchProps,
  GeraNfeButtonOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(GeraNfeButton);
