import React from "react";
import { Alert } from "react-bootstrap";

type ShowSuccessProps = {
  message?: string;
};

const ShowSuccess: React.FC<ShowSuccessProps> = (props) => {
  if (props.message && props.message.length > 0) {
    return (
      <Alert className="my-1" variant="success">
        {props.message}
      </Alert>
    );
  }
  return null;
};

export default ShowSuccess;
