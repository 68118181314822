export const exibeQuandoObj = (quandoObj: any) => {
  switch (quandoObj.tipoObj) {
    case "Prod":
      return quandoObj.prod && `${quandoObj.prod.cod} - ${quandoObj.prod.name}`;
    case "NCM":
      return quandoObj.ncm;
    case "Dest":
      return (
        quandoObj.dest &&
        `${quandoObj.dest.name} - ${
          quandoObj.dest.type === 1
            ? `CPF: ${quandoObj.dest.cpf}`
            : `CNPJ: ${quandoObj.dest.cnpj}`
        }`
      );
    case "UF":
      return quandoObj.uf;
    case "Emissor":
      return (
        quandoObj.emissor &&
        `${quandoObj.emissor.name} - CNPJ: ${quandoObj.emissor.cnpj}`
      );
    case "TipoNF":
      return quandoObj.modelo === "65" ? "Venda rápida" : "Venda normal";
    default:
      return `[Item não suportado]`;
  }
};
