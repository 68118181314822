import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import gnfe from "../../../misc/gnfe";
import { Sale } from "../types";
import { AWSRes } from "./../../../redux/types";
import { quicksalesActions } from "./reducer";

function* quicksaleSaveWorker({ payload }: PayloadAction<Sale>) {
  try {
    const res = yield call(executeApiRequest, `/sale`, "POST", payload);
    const nfceG = yield call(gnfe, res);
    console.log("nfceG", nfceG);
    res.NFe = nfceG;
    const resPut = yield call(
      executeApiRequest,
      `/sale/${res.invoiceId}`,
      "PUT",
      res
    );
    /// Call the emit NFCe webservice
    // const resNfce = yield call(
    //   executeNFeRequest,
    //   "/emitirnfce",
    //   "GET",
    //   {},
    //   {
    //     queryStringParameters: {
    //       invoiceId: res.invoiceId,
    //       orgId: res.userId,
    //     },
    //   }
    // );
    // if (resNfce.autorizado) {
    //   console.log("NFCe autorizada");
    // } else {
    //   console.log("NFCe com erros", resNfce);
    // }
    const resFin = yield call(
      executeApiRequest,
      `/sale/${resPut.invoiceId}`,
      "GET",
      {},
      {}
    );
    yield put(quicksalesActions.saveSuccess(resFin));
  } catch (error) {
    console.log("error response", error, error.response);
    yield put(
      quicksalesActions.saveError(error.response ? error.response.data : error)
    );
  }
}

function* quicksaleUpdateWorker({ payload }: PayloadAction<string>) {
  try {
    const res = yield call(
      executeApiRequest,
      `/sale/${payload}`,
      "GET",
      {},
      {}
    );
    yield put(quicksalesActions.updateSuccess(res));
  } catch (error) {
    yield put(quicksalesActions.updateError(error));
  }
}

function* quicksaleListWorker() {
  try {
    const sales: AWSRes<Sale> = yield call(
      executeApiRequest,
      `/sale`,
      "GET",
      {},
      {
        queryStringParameters: {
          lastKey: null,
        },
      }
    );
    const quickSales = sales.items.filter((sale) => sale.modelo === "65");
    yield put(quicksalesActions.listSuccess(quickSales));
  } catch (error) {
    console.log("error on quicksalesList worker", error);
    yield put(
      quicksalesActions.listError(error.response ? error.response : error)
    );
  }
}

export default function* quicksaleSaga() {
  yield all([
    takeLatest("quicksale/saveRequest", quicksaleSaveWorker),
    takeLatest("quicksale/updateRequest", quicksaleUpdateWorker),
    takeLatest("quicksale/listRequest", quicksaleListWorker),
  ]);
}
