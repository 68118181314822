import { combineReducers } from "redux";
import {
  USERPAGE_INIT,
  SENDINVITE_REQUESTING,
  SENDINVITE_SUCCESS,
  SENDINVITE_ERROR,
} from "./actions";

const initialState = {
  isUpdating: false,
  isInvinting: false,
  sendinviteError: "",
  sendinviteSuccess: "",
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USERPAGE_INIT:
      return {
        ...state,
        isUpdating: false,
      };
    case SENDINVITE_REQUESTING:
      return {
        ...state,
        isInvinting: true,
        sendinviteError: "",
        sendinviteSuccess: "",
      };
    case SENDINVITE_SUCCESS:
      return {
        ...state,
        isInvinting: false,
        sendinviteError: "",
        sendinviteSuccess: "Usuario convidado com sucesso!",
      };
    case SENDINVITE_ERROR:
      return {
        ...state,
        isInvinting: false,
        sendinviteError: action.error,
        sendinviteSuccess: "",
      };
    default:
      return state;
  }
};

export default combineReducers({
  profilePage: reducer,
});
