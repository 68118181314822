import { faBan, faPrint, faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DeleteButton from "../../components/DeleteButton";
import { LinkButton } from "../../components/LinkButton";
import LoadingContainer from "../../components/LoadingContainer";
import ShowError from "../../components/ShowError";
import ShowSuccess from "../../components/ShowSuccess";
import { formatDate, formatNumber } from "../../misc/formatters";
import { RootState } from "../app/mainReducer";
import BCancelaNfs from "./BCancelaNfs";
import BEmitirNfs from "./BEmitirNfs";
import { BImprimirNfs } from "./BImprimirNfs";
import { servicesActions } from "./reducer";
import { Service } from "./type";

interface IServicesProps extends RouteComponentProps {}

const ServicoCard: React.FC<{
  servico: Service;
  isDeleting: boolean;
  onDelete: (servico: Service) => void;
}> = ({ servico, isDeleting, onDelete }) => (
  <Col xs={12} className="my-1 px-2 py-3 bg-secondary rounded text-white">
    <p>Serviço Nº {servico.num}</p>
    <p>
      Cliente:{" "}
      {servico.dest &&
        `${servico.dest.name} - ${
          servico.dest.type === 1
            ? "CPF: " + servico.dest.cpf
            : "CNPJ: " + servico.dest.cnpj
        }`}
    </p>
    <p>Data Emissão: {formatDate(servico.emission)}</p>
    <p>
      Valor:{" "}
      {formatNumber(
        servico.items?.reduce(
          (a, item) => a + item.quantidade * item.valor,
          0
        ) || 0,
        2
      )}
    </p>
    <p>Descrição: {servico.descricao}</p>
    <p>
      Status NFS:
      {servico.NFsAutorizada && servico.NFsCancelada && "Cancelada"}
      {servico.NFsAutorizada && !servico.NFsCancelada && "Autorizada"}
      {!servico.NFsAutorizada && !servico.NFsCancelada && "Não emitida"}
    </p>
    {servico.NFsAutorizada && !servico.NFsCancelada && servico.numeroNfs && (
      <p>Numero NFS: {servico.numeroNfs}</p>
    )}
    <ButtonGroup>
      <DeleteButton
        isRequesting={isDeleting}
        onDelete={() => onDelete(servico)}
      />
      <LinkButton
        variant="info"
        to={`/service/${servico.invoiceId}`}
        title="Editar"
      >
        <i className="fas fa-edit" />
      </LinkButton>
      <LinkButton
        variant="primary"
        to={`/service/new/${servico.invoiceId}`}
        title="Duplicar"
      >
        <i className="fas fa-copy" />
      </LinkButton>
      {!servico.NFsAutorizada && (
        <BEmitirNfs
          variant="success"
          title="Emitir NFS"
          servico={servico}
          disabled={false}
        >
          <FontAwesomeIcon icon={faReceipt} />
        </BEmitirNfs>
      )}
      {servico.NFsAutorizada && !servico.NFsCancelada && (
        <BCancelaNfs
          variant="danger"
          title="Cancela NFS"
          servico={servico}
          disabled={false}
        >
          <FontAwesomeIcon icon={faBan} />
        </BCancelaNfs>
      )}
      {servico.NFsAutorizada && !servico.NFsCancelada && (
        <BImprimirNfs
          variant="success"
          title="Imprimir NFS"
          servico={servico}
          disabled={false}
        >
          <FontAwesomeIcon icon={faPrint} />
        </BImprimirNfs>
      )}
    </ButtonGroup>
  </Col>
);

const Services: React.FC<IServicesProps> = (props) => {
  const isDeleting = useSelector(
    (state: RootState) => state.services.list.isDeleting
  );
  const handleDelete = (service: Service) => {
    dispatch(servicesActions.deleteRequest(service));
  };

  const items = useSelector((state: RootState) => state.services.list.items);
  const isRequesting = useSelector(
    (state: RootState) => state.services.list.isRequesting
  );
  const error = useSelector((state: RootState) => state.services.list.error);
  const successMsg = useSelector(
    (state: RootState) => state.services.list.successMsg
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      servicesActions.servicesRequest({ status: ["OPEN", "DOING", "WAITING"] })
    );
  }, [dispatch]);

  const itensOpen = items?.filter((item) => item.status === "OPEN");
  const itensDoing = items?.filter((item) => item.status === "DOING");
  const itensWaiting = items?.filter((item) => item.status === "WAITING");
  const itensClosed = items?.filter((item) => item.status === "CLOSED");

  return (
    <LoadingContainer isLoading={isRequesting}>
      <Row className="mt-3 mb-3">
        <Col>
          <h1>Serviços</h1>
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <ShowError error={error} />
      </Row>
      <Row className="mt-3 mb-3">
        <ShowSuccess message={successMsg} />
      </Row>
      <Row className="mt-3 mb-3">
        <Col>
          <LinkButton variant="primary" to="/service/new">
            <i className="fas fa-plus" /> Incluir Serviço
          </LinkButton>
        </Col>
      </Row>
      <Row>
        <Col md={3} xs={12} className="px-1">
          <Col
            xs={12}
            className="border rounded pb-1 px-1"
            style={{ minHeight: "25vh" }}
          >
            <h1 className="text-center">Iniciado</h1>
            {itensOpen.map((item) => (
              <ServicoCard
                servico={item}
                onDelete={handleDelete}
                isDeleting={isDeleting}
              />
            ))}
          </Col>
        </Col>
        <Col md={3} xs={12} className="px-1">
          <Col
            xs={12}
            className="border rounder pb-1 px-1"
            style={{ minHeight: "25vh" }}
          >
            <h1 className="text-center">Em andamento</h1>
            {itensDoing.map((item) => (
              <ServicoCard
                servico={item}
                onDelete={handleDelete}
                isDeleting={isDeleting}
              />
            ))}
          </Col>
        </Col>
        <Col md={3} xs={12} className="px-1">
          <Col
            xs={12}
            className="border rounded pb-1 px-1"
            style={{ minHeight: "25vh" }}
          >
            <h1 className="text-center">Aguardando terceiros</h1>
            {itensWaiting.map((item) => (
              <ServicoCard
                servico={item}
                onDelete={handleDelete}
                isDeleting={isDeleting}
              />
            ))}
          </Col>
        </Col>
        <Col md={3} xs={12} className="px-1">
          <Col
            xs={12}
            className="border rounded pb-1 px-1"
            style={{ minHeight: "25vh" }}
          >
            <h1 className="text-center">Finalizado</h1>
            {itensClosed.map((item) => (
              <ServicoCard
                servico={item}
                onDelete={handleDelete}
                isDeleting={isDeleting}
              />
            ))}
          </Col>
        </Col>
      </Row>
    </LoadingContainer>
  );
};

export default Services;
