import { RouteComponentProps } from "@reach/router";
import React from "react";

export interface TermoUsoProps extends RouteComponentProps {}

const TermoUso: React.FC<TermoUsoProps> = () => {
  return (
    <>
      <h1>Termos e Condições de Uso</h1>
      <p>
        Este site, cujo domínio é apl.notinha.app.br (“SITE”) e o software
        NOTINHA.APP (“SOFTWARE”) são de propriedade, mantidos e operados pela
        NGB PIRES TECNOLOGIA DA INFORMACAO LTDA, sociedade empresária limitada,
        inscrita no CNPJ nº. 40.592.216/0001-08, com sede na Rua Corisco, 140,
        Jd. Aeroporto, CEP 86170-000 em Sertanópolis, Paraná (“Nilton Tech
        Group”).
      </p>
      <p>
        O presente documento estabelece os termos e condições de uso do software
        NOTINHA.APP como Serviço – SaaS, sendo firmado entre a Nilton Tech Group
        e o contratante (“usuário” ou “cliente”), que concorda e adere
        integralmente aos presentes termos e condições de uso. Além disso, este
        instrumento visa prestar informações sobre o modo de utilização do
        software e suas ferramentas, condições, nomenclaturas, direitos e
        obrigações das partes envolvidas.
      </p>
      <p>
        Alertamos que todo o texto deve ser lido com atenção. Em caso de
        discordância com o conteúdo desse documento, não será possível o
        prosseguimento da relação comercial, bem como a navegação ou a
        utilização dos serviços prestados pela Nilton Tech Group.
      </p>
      <p>
        Recomendamos ainda que, caso de aceitação, o cliente armazene ou imprima
        uma cópia deste documento, incluindo todos os seus termos e condições de
        uso.
      </p>
      <h2>1 - Atualização e Disponibilização</h2>
      <p>
        O presente documento foi atualizado e disponibilizado em 01/02/2021,
        incluindo cláusulas relacionadas à confidencialidade e sigilo de
        informações, e em atendimento à Lei no 13.709/18 – Lei Geral de Proteção
        de Dados (LGPD), conforme item 14.1.
      </p>
      <h2>2 - Nomenclaturas</h2>
      <p>
        Abaixo estão dispostos os significados de algumas definições e termos na
        língua inglesa.
      </p>
      <p>
        Cookies: Pequenos arquivos de texto que ficam gravados no computador do
        internauta e podem ser recuperados pelo site que o enviou durante a
        navegação.
      </p>
      <p>
        Dados da empresa: qualquer informação disponibilizada pelo usuário que
        identifique sua empresa, tais como: nome empresarial, CNPJ, endereço,
        e-mail, número de telefone.
      </p>
      <p>
        Dados do responsável pela contratação: qualquer informação
        disponibilizada pelo usuário que permita a identificação da pessoa
        física responsável pela contratação e comunicação com a Nilton Tech
        Group, tais como: nome completo, carteira de identidade (RG), CPF e
        e-mail.
      </p>
      <p>
        IP: Significa “Internet Protocol”, traduzido para português como
        Protocolo da Internet e é um número que identifica um dispositivo
        (computador, impressora, roteador) em uma rede.
      </p>
      <p>Link: Ligação entre documentos na Internet.</p>
      <p>
        SaaS: sigla para “Software as a Service”, que em português significa
        Software como Serviço, permite que os usuários se conectem e usem
        aplicativos baseados em nuvem pela Internet.
      </p>
      <p>
        Spam: Mensagem eletrônica recebida, mas não solicitada pelo
        destinatário.
      </p>
      <p>LGPD: Lei Geral de Proteção de Dados (Lei no 13.709/18).</p>
      <p>
        Login: Método de autenticação com usuario e senha disponibilizados ao
        cliente.
      </p>
      <h3>3 - O Serviço</h3>
      <p>
        3.1 – O serviço fornecido pela Nilton Tech Group consiste em um software
        para gestão empresarial.
      </p>
      <p>
        3.2 – A cessão de uso do software é realizada de forma irrevogável, não
        exclusiva e intransferível por meio da internet, na modalidade de
        Software as a Service (SaaS), incluindo os serviços de hospedagem do
        software e banco de dados.
      </p>
      <p>3.3 – Para contratação dos serviços será necessário:</p>
      <p>
        (i)  que o usuário se cadastre em no site https://apl.notinha.app.br;
      </p>
      <p>
        (ii) que o usuário forneça seu aceite em um dos planos disponiveis para
        adesão após o login.
      </p>
      <p>
        3.5 – As limitações no serviço se darão de acordo com as especificações
        do plano contratado.
      </p>
      <p>
        3.6 – O serviço contratado contempla a versão atual e vigente do
        software. A Nilton Tech Group poderá realizar melhorias e atualizações
        no software a qualquer momento, sem prejuízo para o usuario.
      </p>
      <p>
        3.7 – Durante o cadastro, o cliente determinará sua senha de acesso,
        sendo de sua exclusiva responsabilidade a manutenção do sigilo dessa
        informação.
      </p>
      <p>
        3.8 – Em caso de perda, divulgação, roubo ou identificação de uso não
        autorizado da senha e/ou da conta de acesso, se verificado pelo cliente,
        este deverá informar imediatamente a Nilton Tech Group.
      </p>
      <p>
        3.10 – A Nilton Tech Group não se responsabilizará pelas ações,
        pagamentos e danos decorrentes do uso irregular da conta de acesso pelo
        cliente ou terceiros, exceto se comprovada a falha por parte da Nilton
        Tech Group.
      </p>
      <h2>4 - Alteração no plano contratado</h2>
      <p>
        4.1 – Para alteração do serviço contratado é necessário que, o usuario
        efetue a adesão ao novo plano dentro do painel do usuario.
      </p>
      <h2>5 - Da Remuneração</h2>
      <p>
        5.1 – Para utilizar o software o cliente deverá pagar à Nilton Tech
        Group os valores estabelecidos na adesão de seu plano.
      </p>
      <p>
        5.2 Os preços e formas de pagamento são variáveis de acordo com as
        condições e serviços contratados, e o plano selecionado no momento da
        contratação.
      </p>
      <p>
        5.3 – A Nilton Tech Group também possui serviços que podem ser
        contratados e pagos de forma avulsa, cujos valores e condições serão
        definidos em termo de contratação específico.
      </p>
      <p>
        5.4 – Os valores cobrados não são reembolsáveis, ainda que o cliente não
        faça uso dos serviços.
      </p>
      <p>
        5.5 – A falta de pagamento de quaisquer valores nas respectivas datas de
        vencimento não acarretará na rescisão automática do contrato. A Nilton
        Tech Group se reserva o direito de bloquear o acesso ao software, caso a
        pendência não seja regularizada em até 30 (trinta) dias contados a
        partir da data de vencimento da fatura.
      </p>
      <p>
        5.6 – Caso o cliente não resolva a pendência financeira no prazo de 60
        (sessenta) dias, contados da data de vencimento da fatura, a Nilton Tech
        Group se reserva o direito de rescindir o contrato entre as partes e de
        excluir de forma definitiva os dados do usuário que por ventura estejam
        armazenados no software.
      </p>
      <p>
        5.7 – A modificação e/ou atualização dos preços cobrados pelo uso do
        software, poderá ocorrer mediante aviso prévio de 90 dias por meio de
        mensagem por e-mail.
      </p>
      <p>
        5.8 – Ocorrendo a contratação de serviços adicionais ao plano vigente, a
        Nilton Tech Group se reserva o direito de aplicar os valores conforme
        tabela de preços atualizada.
      </p>
      <h2>6 - Nível de serviço (SLA)</h2>
      <p>
        6.1 – A Nilton Tech Group garante a disponibilidade do software em no
        mínimo 99% (noventa e nove por cento), considerando o tempo em cada mês
        civil.
      </p>
      <p>
        6.2 – O compromisso de Nível de Serviço de 99% (noventa e nove por
        cento) não se aplica, caso as circunstâncias de indisponibilidade
        resultem de:
      </p>
      <p>
        (i) interrupções planejadas, que serão informadas pela Nilton Tech Group
        por meio de mensagem por e-mail ou aviso no software e que serão
        programadas, na medida do possível, em horários noturnos, entre 22h00 e
        06h00 (horário de Brasília);
      </p>
      <p>
        (ii) casos fortuitos ou de força maior, nos termos do artigo 393 do
        Código Civil;
      </p>
      <p>(iii) quaisquer atos ou omissões do usuário ou de terceiros.</p>
      <h2>7 - Promoções e períodos de testes</h2>
      <p>
        7.1 – A Nilton Tech Group poderá oferecer períodos de testes, descontos,
        acessos gratuitos ou pacotes promocionais a qualquer momento, por
        motivos puramente comerciais e a seu exclusivo critério.
      </p>
      <p>
        7.2 – As promoções e testes não serão cumulativos e poderão ser
        limitados a determinados serviços, períodos de tempo e a aceitação de
        condições especiais.
      </p>
      <p>
        7.3 – A Nilton Tech Group poderá interromper, cancelar ou modificar as
        promoções e testes a qualquer momento, respeitando as condições e prazos
        estabelecidos na Proposta Comercial correspondente.
      </p>
      <h2>8 - Propriedade intelectual</h2>
      <p>
        8.1 – O software tem seu uso licenciado por modalidade de serviço
        “Software as a Service” (SaaS) e não venda. A titularidade e os direitos
        relativos ao software pertencem exclusivamente à Nilton Tech Group.
      </p>
      <p>
        8.2 – O usuário não está autorizado a utilizar, sob qualquer forma ou
        pretexto, as marcas, suas variações, domínios e sinais que sejam da
        Nilton Tech Group ou que estiverem expostas no software, sob pena de
        infração à legislação aplicável.
      </p>
      <p>
        8.3 – Todo o conteúdo do software, incluindo programas, bases de dados,
        arquivos, textos, desenhos, fotos, layouts, cabeçalhos e demais
        elementos, foi criado, desenvolvido ou cedido a Nilton Tech Group,
        sendo, portanto, de propriedade exclusiva da Nilton Tech Group ou à ela
        licenciado e encontra-se protegido pelas leis brasileiras e tratados
        internacionais que versam sobre direitos de propriedade intelectual.
      </p>
      <p>
        8.4 – São proibidas: a exploração, cessão, imitação, cópia, plágio,
        aplicação de engenharia reversa, armazenamento, alteração, modificação
        de características, ampliação, sub licenciamento, venda, locação,
        doação, alienação, transferência, reprodução, integral ou parcial, de
        qualquer conteúdo do site ou do software.
      </p>
      <p>
        8.5 – O usuário que violar as proibições contidas na legislação sobre
        propriedade intelectual e nestes Termos e Condições de Uso Nilton Tech
        Group será responsabilizado, civil e criminalmente, pelas infrações
        cometidas, além de ser excluído da base de dados do software.
      </p>
      <h2>9 - Obrigações das Partes</h2>
      <p>9.1 – São obrigações da Nilton Tech Group:</p>
      <p>
        (i) realizar os serviços conforme as condições contratadas pelo usuário,
        responsabilizando-se pelo funcionamento do software, pelas correções que
        eventualmente sejam necessárias e disponibilizando acesso aos serviços
        de suporte para esclarecimento de dúvidas em relação ao uso do software;
      </p>
      <p>(ii) comunicar qualquer alteração dos serviços aos usuários;</p>
      <p>
        (iii) efetuar alterações no software, decorrentes de ordem legal, sem
        qualquer custo para o cliente, de acordo com a política de atualização
        do software;
      </p>
      <p>
        (iv) restituir ao usuário, após o fim da prestação dos serviços, todos
        os documentos que lhe tenham sido entregues ou tiver obtido, por
        qualquer forma, em decorrência do serviço, bem como, se for o caso,
        apagar, desinstalar e/ou destruir toda e qualquer informação ou dado de
        propriedade do cliente ou de terceiros a ele cedidos, ficando autorizada
        a retenção apenas dos documentos e materiais necessários para comprovar
        o cumprimento de suas obrigações.
      </p>
      <p>
        (v) tratar os dados pessoais coletados e utilizados para a comunicação
        comercial dos produtos, serviços e promoções da Nilton Tech Group em
        conformidade com a Lei Geral de Proteção de Dados (Lei 13.709/18) e em
        conformidade ao descrito no documento Política de Privacidade Nilton
        Tech Group.
      </p>
      <p>9.2 – São obrigações do usuário:</p>
      <p>
        (i) utilizar o software conforme os critérios de utilização definidos
        pela Nilton Tech Group, sem alterar a sua programação, quebrar senhas ou
        realizar procedimentos que venham causar prejuízos aos demais usuários
        ou a empresa;
      </p>
      <p>
        (ii) responsabilizar-se pelo teor das informações e documentos que
        introduzir no software, uma vez que essas informações ficam apenas
        armazenadas nos servidores da Nilton Tech Group;
      </p>
      <p>
        (iii) efetuar os pagamentos dos valores devidos, nos prazos e formas
        contratados;
      </p>
      <p>
        (iv) informar a Nilton Tech Group sobre quaisquer alterações nas
        especificações dos serviços a serem realizadas.
      </p>
      <h2>10 - Restrições</h2>
      <p>10.1 – O usuário não poderá:</p>
      <p>
        (i) lesar os direitos da Nilton Tech Group, seus parceiros, usuários ou
        terceiros ou agir sob qualquer meio ou forma que possa contribuir com
        tal violação;
      </p>
      <p>
        (ii) executar atos que limitem ou impeçam a utilização do software ou
        acessar ilicitamente o software da Nilton Tech Group;
      </p>
      <p>
        (iii) introduzir programas, vírus ou realizar ações para obtenção de
        informações, serviços ou acesso as áreas de programação;
      </p>
      <p>
        (iv) explorar falhas do software, interferir na segurança ou em qualquer
        recurso do software;
      </p>
      <p>
        (v) utilizar aplicativos automatizados de coleta e seleção de dados para
        realizar operações massificadas ou, ainda, para coletar e transferir
        dados que possam ser extraídos do software para fins ilícitos, sendo
        reservado à Nilton Tech Group o direito de investigar qualquer atividade
        suspeita;
      </p>
      <p>
        (vi) utilizar o software para difundir mensagens não relacionadas com o
        software ou com suas finalidades, incluindo mensagens com conteúdo
        impróprio;
      </p>
      <p>
        (vii) inserir dados que sejam falsos, desatualizados ou incompletos.
      </p>
      <p>
        10.2 – O usuário que praticar ações ilícitas e/ou proibidas e que
        estejam em desacordo com o presente documento, poderá ter seus serviços
        suspensos, os dados excluídos do software, respondendo civil e
        criminalmente por qualquer dano decorrente da violação.
      </p>
      <h2>11 - Limitação de Responsabilidade</h2>
      <p>
        11.1 – O usuário contratante é o responsável pela utilização do software
        e pelas informações, senhas, conteúdo e documentos inseridos no
        software.
      </p>
      <p>
        11.2 – A Nilton Tech Group e seus representantes não serão
        responsabilizados por:
      </p>
      <p>
        (i) danos decorrentes de falhas no ambiente do usuário, tais como: ações
        de terceiros, problemas na conexão de rede e internet, ocorrência de
        vírus, falhas no hardware, falta de energia e indisponibilidade no
        ambiente operacional (equipamentos);
      </p>
      <p>
        (ii) danos e prejuízos que o usuário contratante possa ter em
        decorrência do mau uso do software em desacordo com as cláusulas do
        presente instrumento;
      </p>
      <p>
        11.3 – Fica esclarecido que o presente serviço não estabelece entre as
        partes qualquer vínculo empregatício, societário ou associativo,
        permanecendo cada parte como única responsável por todas as suas
        respectivas despesas e encargos, sejam de natureza trabalhista,
        previdenciária, fiscal, securitária, civil, penal ou de qualquer outra
        natureza ou espécie.
      </p>
      <h2>12 - Disponibilidade do software e garantias</h2>
      <p>
        12.1 – O software, disponibilizado na Internet, não inclui as seguintes
        garantias:
      </p>
      <p>
        (i) adequação do software para um determinado fim específico solicitado
        pelo cliente;
      </p>
      <p>(ii) inexistência de defeitos, erros ou falhas;</p>
      <p>
        (iii) correção de problemas, danos ou prejuízos causados por decisões
        tomadas pelo usuário, assim como defeitos ou erros decorrentes de
        negligência, imprudência ou imperícia do cliente;
      </p>
      <p>
        (iv) problemas provenientes de caso fortuito ou força maior nos termos
        do artigo 393 do Código Civil;
      </p>
      <p>
        12.2 A Nilton Tech Group se reserva o direito de realizar modificações
        no software, tais como layout, conteúdo, funcionalidades e qualquer
        outro elemento que não impacte significativamente na usabilidade do
        mesmo.
      </p>
      <p>
        12.3 A Nilton Tech Group se reserva o direito de efetuar o cancelamento
        do software, mediante aviso prévio ao usuário de no mínimo 90 (noventa)
        dias.
      </p>

      <p>
        12.4 – Na eventual impossibilidade de continuar suas atividades, a
        Nilton Tech Group se compromete a entregar ao usuário contratante o
        backup de todos os seus dados carregados ao software.
      </p>
      <h2>13 - Política de confidencialidade e sigilo de informações</h2>
      <p>
        13.1 – Todas as informações obtidas de nossos clientes serão tratadas
        como confidenciais e sigilosas por parte da Nilton Tech Group e seus
        colaboradores.
      </p>
      <p>
        13.2 – Serão consideradas como informação confidencial e sigilosa toda e
        qualquer informação, patenteada ou não, de natureza técnica,
        operacional, comercial, jurídica, know-how, invenções, processos,
        fórmulas e designs, patenteáveis ou não, planos de negócios, métodos de
        contabilidade, técnicas e experiências acumuladas, documentos,
        registros, contratos, papéis, estudos, pareceres e pesquisas,
        pertencentes ao cliente e que a Nilton Tech Group e seus colaboradores
        tenham acesso:
      </p>
      <p>
        a) por qualquer meio físico (v.g. documentos impressos, manuscritos,
        mensagens eletrônicas (email), fotografias etc;
      </p>
      <p>
        b) por qualquer forma registrada em mídia eletrônica (pen drive, cds,
        disquetes, etc).
      </p>
      <p>
        13.3 – A Nilton Tech Group e seus colaboradores se comprometem a manter
        sigilo absoluto sobre qualquer tipo de informação confidencial e
        sigilosa de nossos clientes, não utilizando tais informações para gerar
        benefício próprio ou alheio, presente ou futuro.
      </p>
      <p>
        13.4 – As informações confidenciais e sigilosas de nossos clientes,
        confiadas aos colaboradores da Nilton Tech Group, somente poderão ser
        comunicadas à terceiros ou outrem, mediante consentimento prévio e por
        escrito da empresa cliente e titular das informações.
      </p>
      <p>
        13.5 – Não configuram informações confidenciais e sigilosas aquelas já
        disponíveis ao público em geral e as que não são mais tratadas como
        confidenciais pela empresa cliente.
      </p>
      <p>13.6 – A Nilton Tech Group e seus colaboradores:</p>
      <p>
        (i) usarão as informações confidenciais e sigilosas de nossos clientes
        apenas com o propósito relacionado ao objeto contratual;
      </p>
      <p>
        (ii) manterão o controle das informações confidenciais e sigilosas de
        nossos clientes, as revelando apenas aos colaboradores que tiverem
        necessidade de ter conhecimento sobre elas;
      </p>
      <p>
        (iii) protegerão as informações confidenciais e sigilosas de seus
        clientes, através de criptografia em banco de dados e acessos restritos
        através de login e senha específicos;
      </p>
      <p>
        (iv) comunicarão imediatamente à empresa cliente, a ocorrência de
        qualquer incidente relacionado às informações confidenciais e sigilosas,
        não se eximindo de suas responsabilidades.
      </p>
      <p>
        13.7 – A manutenção da confidencialidade e sigilo das informações
        recebidas de nossos clientes será válida por prazo indeterminado,
        permanecendo válida mesmo após o fim da relação comercial entre a Nilton
        Tech Group e seu(s) cliente(s).
      </p>
      <h2>14 - Política de proteção de dados pessoais</h2>
      <p>
        14.1 – A Nilton Tech Group dispõe de uma política específica para
        regular a coleta, guarda e utilização de dados pessoais em atendimento a
        Lei Geral de Proteção de Dados (LGPD): Política de Privacidade Nilton
        Tech Group.
      </p>
      <h2>15 - Idioma</h2>
      <p>
        15.1 – Toda a documentação legal da Nilton Tech Group foi elaborada em
        língua portuguesa. A Nilton Tech Group poderá, a seu exclusivo critério,
        disponibilizar traduções de tal documentação caso entenda como
        necessário.
      </p>
      <p>
        15.2 – A versão em língua portuguesa dos documentos citados em 15.1 é a
        única consentida pela Nilton Tech Group. Em caso de contradição ou
        divergência entre a versão em português e eventual tradução para
        qualquer outro idioma, prevalecerá sempre a versão em língua portuguesa.
      </p>
      <h2>16 - Do prazo e da rescisão</h2>
      <p>
        16.1 – A contratação dos serviços, entra em vigor na data do cadastro no
        site, vigorando por prazo indeterminado.
      </p>
      <p>
        16.2 – O presente instrumento e a Política de Privacidade Nilton Tech
        Group, permanecerão em vigor por tempo indeterminado.
      </p>
      <p>
        16.3 – Qualquer das partes poderá a qualquer tempo promover a rescisão
        da prestação dos serviços, sem qualquer ônus, desde que comunicado por
        escrito com no mínimo 30 (trinta) dias de antecedência.
      </p>
      <p>
        16.4 – A rescisão poderá ser promovida a qualquer tempo, desde que a
        parte solicitante esteja em dia com suas respectivas obrigações,
        conforme estabelece o item 9 do presente instrumento.
      </p>
      <h2>17 - Alterações do presente instrumento</h2>
      <p>
        17.1 – A Nilton Tech Group poderá revisar, modificar e/ou atualizar, a
        qualquer momento, qualquer cláusula ou disposição contidas no presente
        instrumento.
      </p>
      <p>
        17.2 – A versão atualizada do presente instrumento será comunicada pela
        Nilton Tech Group através de e-mail e pelo site apl.notinha.app.br.
      </p>
      <h2>18 - Lei aplicável</h2>
      <p>
        18.1 – O software é controlado, operado e administrado pela Nilton Tech
        Group na cidade de Sertanópolis, Estado do Paraná, Brasil, podendo ser
        acessado por qualquer dispositivo conectado à Internet.
      </p>
      <p>
        18.2 – Em vista das diferentes legislações locais nacionais e
        internacionais que possam existir, ao acessar o software, o usuário
        concorda que a legislação aplicável, para fins deste documento, será a
        vigente na República Federativa do Brasil.
      </p>
      <h2>19 - Contato</h2>
      <p>
        19.1 – A Nilton Tech Group disponibiliza canais de atendimento para
        receber todas as comunicações que o usuário desejar fazer:
      </p>
      <p>(i) e-mail: para o endereço eletrônico suporte@notinha.app.br.</p>
      <p>
        19.2 – Todas as solicitações devem sempre estar acompanhadas das
        informações de contato do cliente, para que possamos finalizar o
        atendimento.
      </p>
      <h2>20 - Disposições Gerais</h2>
      <p>
        20.1 Os tributos que sejam devidos em decorrência direta ou indireta do
        presente termo, ou de sua execução, serão de exclusiva responsabilidade
        do contribuinte, assim definido na norma tributária, sem direito a
        reembolso. O cliente, quando fonte retentora, descontará e recolherá,
        nos prazos da lei, os tributos a que esteja obrigado pela legislação
        vigente.
      </p>
      <p>
        20.2 A Nilton Tech Group não poderá ceder, transferir ou sub-rogar, no
        todo ou em parte, a terceiros, qualquer direito ou obrigação objeto do
        presente termo, exceto mediante mútuo acordo entre as PARTES, por
        escrito.
      </p>
      <p>
        20.3 Eventual tolerância, por uma das partes, pelo não cumprimento de
        qualquer obrigação relacionada ao presente termo, será considerada mera
        liberalidade, não constituindo novação, precedente invocável, renúncia a
        direitos, alteração tácita de seus termos ou direito adquirido da outra
        parte.
      </p>
      <p>
        20.4 A Nilton Tech Group obriga-se a não empregar e/ou utilizar
        mão-de-obra infantil para a execução do presente Contrato, durante todo
        o seu prazo de vigência, bem como se obriga a não subcontratar e/ou
        manter relações negociais com quaisquer outras empresas que utilizem,
        explorem e/ou, por qualquer outro meio ou forma, empreguem o trabalho
        infantil em não observância ao contido na Lei n.º 8.069/90 (ECA –
        Estatuto da Criança e do Adolescente) e demais dispositivos legais que
        regulamentam a matéria.
      </p>
      <p>
        20.5 A Nilton Tech Group se compromete a não oferecer, dar ou concordar
        em dar à qualquer pessoa, ou solicitar ou aceitar ou concordar em
        aceitar de qualquer pessoa (seja por conta própria ou através de outra
        pessoa), qualquer presente ou pagamento, contrapartida ou benefício de
        qualquer espécie que constitua uma prática ilegal ou que possa ser
        considerada suborno pelas leis anticorrupção em vigor.
      </p>
      <p>
        20.6 A Nilton Tech Group terá total liberdade para comunicar seu
        cliente, através do canal de denúncia anônima ou qualquer meio e a
        qualquer tempo, os detalhes de eventual prática ilegal que possa ser
        considerada suborno pelas leis anticorrupção em vigor.
      </p>
      <h2>21 - Foro</h2>
      <p>
        21.1 – A Nilton Tech Group e o usuário concordam que o Foro de
        Sertanópolis, Estado do Paraná, Brasil, será o único competente para
        dirimir qualquer questão ou controvérsia oriunda ou resultante do uso do
        software, renunciando expressamente a qualquer outro, por mais
        privilegiado que seja, ou venha a ser.
      </p>
    </>
  );
};

export default TermoUso;
