import { Prod } from "./../actions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AWSRes } from "../../../redux/types";

interface TInitialState {
  isLoading: boolean;
  itens: Prod[];
  Count: number;
  lastKey: Record<string, string>;
  isInventorySaving: boolean;
  indexSaving: number;
  error?: string;
  errors: string[];
}

export type ProdInventory = Prod & {
  qtdAlterar: number;
};

const initialState: TInitialState = {
  isLoading: false,
  itens: [],
  Count: 0,
  lastKey: null,
  isInventorySaving: false,
  indexSaving: -1,
  error: "",
  errors: [],
};

const reducerSlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    initInventory(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.isInventorySaving = false;
      state.itens = [];
      state.Count = 0;
      state.indexSaving = -1;
      state.lastKey = null;
    },
    initSuccess(state, { payload }: PayloadAction<AWSRes<Prod>>) {
      state.isLoading = false;
      state.itens = payload.items;
      state.Count = payload.Count;
      state.lastKey = payload.lastKey;
    },
    initError(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    requestInventorySave(state, _: PayloadAction<ProdInventory[]>) {
      state.isInventorySaving = true;
      state.indexSaving = -1;
    },
    inventorySaveIndex(
      state,
      { payload }: PayloadAction<{ indexSaved: number; error?: string }>
    ) {
      state.indexSaving = payload.indexSaved;
      state.errors[payload.indexSaved] = payload.error || "";
    },
    inventorySaveFinished(state) {
      state.isInventorySaving = false;
    },
  },
});

export const inventoryActions = reducerSlice.actions;

export default reducerSlice;
