// component do form do modulo invoice
import React, { Component } from "react";
import { connect } from "react-redux";
import { formInit, formSave } from "./actions";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Modal,
  ButtonGroup,
} from "react-bootstrap";
import LoadingContainer from "../../../components/LoadingContainer";
import MaskInput from "../../../components/FormikComponents/MaskInput";
import DateInput from "../../../components/FormikComponents/DateInput";
import ProdSelector from "../../prod/selector";
import InputNumber from "../../../components/InputNumber";
import LoaderButton from "../../../components/LoaderButton";
import ShowError from "../../../components/ShowError";
import ShowSuccess from "../../../components/ShowSuccess";
import DestSelector from "../../dest/selector";
import TableOrCard from "../../../components/TableOrCard";
import { formatNumber } from "../../../misc/formatters";
import { Link, RouteComponentProps } from "@reach/router";
import { Cond } from "../saga";

type CondFormProps = {
  formInit: (movId: string) => void;
  formSave: (cond: Cond) => void;
  item: Cond;
  successMsg: string;
  error: any;
  isRequesting: boolean;
  isSaving: boolean;
  id: string;
} & RouteComponentProps;

class CondForm extends Component<CondFormProps, {}> {
  state = {
    itemEditIndex: -1,
    itemEditOpen: false,
  };

  componentDidMount() {
    this.props.formInit(this.props.id);
  }

  handleSubmit = (item: Cond) => {
    this.props.formSave(item);
  };

  handleChangeItem = (formik: FormikProps<any>, objItem: any) => {
    const items = formik.values.items;
    items[this.state.itemEditIndex] = Object.assign(
      items[this.state.itemEditIndex],
      objItem
    );
    formik.setFieldValue("items", items);
  };

  handleDeleteItem = (formik: FormikProps<any>, itemIndex: number) => {
    const items = formik.values.items;
    items.splice(itemIndex, 1);
    formik.setFieldValue("items", items);
  };

  handleEditItem = (itemIndex: number) => {
    this.setState({
      itemEditOpen: true,
      itemEditIndex: itemIndex,
    });
  };

  handleAddItem = (formik: FormikProps<any>) => {
    const items = formik.values.items || [];
    items.push({
      nItem: items.length + 1,
      prod: null,
      qtd: 1,
      valor: 0,
      total: 0,
    });
    formik.setFieldValue("items", items);
    this.setState({
      itemEditIndex: items.length - 1,
      itemEditOpen: true,
    });
  };

  renderItem = (formik: FormikProps<any>) => {
    if (
      !formik.values.items ||
      formik.values.items.length < 1 ||
      this.state.itemEditIndex < 0
    ) {
      return null;
    }
    const item = formik.values.items[this.state.itemEditIndex];
    return (
      <>
        <Form.Row>
          <Form.Group as={Col} controlId="prod" xs={12}>
            <Form.Label>Produto</Form.Label>
            <ProdSelector
              value={item.prod}
              onChange={(prod: any) =>
                this.handleChangeItem(formik, {
                  prod,
                  valor: prod ? prod.preco : 0,
                  total: (prod ? prod.preco : 0) * item.qtd,
                })
              }
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="qtd" xs={12} md={4}>
            <Form.Label>Quantidade</Form.Label>
            <InputNumber
              placeholder="Quantidade"
              required
              value={item.qtd}
              decimalPlaces={0}
              onChange={(qtd) =>
                this.handleChangeItem(formik, { qtd, total: item.valor * qtd })
              }
            />
          </Form.Group>
          <Form.Group as={Col} controlId="valor" xs={12} md={4}>
            <Form.Label>Valor Unitário</Form.Label>
            <InputNumber
              required
              placeholder="Valor"
              value={item.valor}
              decimalPlaces={2}
              onChange={(valor) =>
                this.handleChangeItem(formik, {
                  valor,
                  total: valor * item.qtd,
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col} controlId="total" xs={12} md={4}>
            <Form.Label>Total</Form.Label>
            <InputNumber
              placeholder="Total"
              value={item.total}
              decimalPlaces={2}
              onChange={(total) =>
                this.handleChangeItem(formik, {
                  total,
                  valor: item.qtd > 0 ? total / item.qtd : 0,
                })
              }
            />
          </Form.Group>
        </Form.Row>
      </>
    );
  };

  render() {
    const isSaving = this.props.isSaving;

    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>Condicional</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              as={Link}
              to="/condPane"
              variant="primary"
              className="mb-3 mt-3"
            >
              Todas as condicionais
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <ShowSuccess message={this.props.successMsg} />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <ShowError error={this.props.error} />
          </Col>
        </Row>
        <LoadingContainer
          isLoading={this.props.isRequesting || !this.props.item}
        >
          <Formik
            initialValues={this.props.item}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              num: Yup.string(),
              dest: Yup.object(),
              items: Yup.array().min(1),
            })}
          >
            {(props) => {
              const { isValid, handleSubmit } = props;

              const headerItems = [
                {
                  label: "#",
                  dataIndex: "nItem",
                },
                {
                  label: "Produto",
                  render: (item: any) =>
                    item.prod ? `${item.prod.cod} - ${item.prod.name}` : "",
                },
                {
                  label: "Quantidade",
                  dataIndex: "qtd",
                },
                {
                  label: "Valor Un.",
                  render: (item: any) => formatNumber(item.valor, 2),
                },
                {
                  label: "Total",
                  render: (item: any) => formatNumber(item.total, 2),
                },
                {
                  label: "Ações",
                  render: (_: any, i: number) => (
                    <>
                      <ButtonGroup>
                        <Button
                          variant="info"
                          onClick={() => this.handleEditItem(i)}
                        >
                          <i className="fas fa-edit" />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => this.handleDeleteItem(props, i)}
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </ButtonGroup>
                    </>
                  ),
                },
              ];

              // props.setFieldValue('parcelas', parcelas)
              return (
                <Form onSubmit={handleSubmit}>
                  <Modal
                    show={this.state.itemEditOpen}
                    size="xl"
                    onHide={() => this.setState({ itemEditOpen: false })}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Item da Condicional</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* Form do item */}
                      {this.renderItem(props)}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={() => this.setState({ itemEditOpen: false })}
                      >
                        Concluir
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Row>
                    <MaskInput
                      name="num"
                      label="Numero"
                      mask="99999999999"
                      formik={props}
                      size={3}
                    />
                    <DateInput
                      name="emission"
                      label="Emissão"
                      formik={props}
                      size={3}
                    />
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="dest">
                      <Form.Label>Cliente</Form.Label>
                      <DestSelector
                        onChange={(dest: any) =>
                          props.setFieldValue("dest", dest)
                        }
                        value={props.values.dest}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col>
                      <h4>Produtos</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3 mb-3">
                      <Button
                        variant="primary"
                        onClick={() => this.handleAddItem(props)}
                      >
                        Incluir Item
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TableOrCard
                        headers={headerItems}
                        items={props.values.items}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3 mb-4">
                      <LoaderButton
                        isLoading={isSaving}
                        disabled={!isValid}
                        type="submit"
                      >
                        Salvar
                      </LoaderButton>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </LoadingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    item: state.cond.form.item,
    successMsg: state.cond.form.successMsg,
    error: state.cond.form.error,
    isRequesting: state.cond.form.isRequesting,
    isSaving: state.cond.form.isSaving,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    formInit: (cadId: string) => dispatch(formInit(cadId)),
    formSave: (cad: Cond) => dispatch(formSave(cad)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CondForm);
