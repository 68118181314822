import { Serie } from "./../actions";
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  SERIE_FORM_INIT,
  SERIE_SAVE_REQUESTING,
  formInitError,
  formInitSuccess,
  formSuccess,
  formError,
} from "./actions";
import { CAD_TYPE } from "../actions";
import executeApiRequest from "../../../misc/executeApiRequest";

const newSerie = (): Serie => {
  return {
    cadType: CAD_TYPE,
    cod: "",
    name: "",
  };
};

function* serieInitWorker({ payload }: any) {
  try {
    if (payload === "new") {
      yield put(formInitSuccess(newSerie()));
    } else {
      const res = yield call(
        executeApiRequest,
        `/serie/${payload}`,
        "GET",
        {},
        {}
      );
      yield put(formInitSuccess(res));
    }
  } catch (error) {
    yield put(formInitError(error));
  }
}

function* serieSaveWorker({ payload }: any) {
  try {
    if (payload.cadId) {
      // Update
      const res = yield call(
        executeApiRequest,
        `/serie/${payload.cadId}`,
        "PUT",
        payload
      );
      yield put(formSuccess(res));
    } else {
      const res = yield call(executeApiRequest, "/serie", "POST", payload);
      yield put(formSuccess(res));
    }
  } catch (error) {
    console.log("error", error, error.response);
    yield put(formError(error.response.data));
  }
}

export default function* serieFormSaga() {
  yield all([
    takeLatest(SERIE_FORM_INIT, serieInitWorker),
    takeLatest(SERIE_SAVE_REQUESTING, serieSaveWorker),
  ]);
}
