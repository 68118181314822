// saga do modulo cond
import { all, call, put, takeLatest } from "redux-saga/effects";
import executeApiRequest from "../../../misc/executeApiRequest";
import { AWSRes } from "./../../../redux/types";
import { InvoiceParcela } from "./../actions";
import {
  clientInvoiceError,
  clientInvoiceSuccess,
  CLIENT_INVOICE_REQUESTING,
} from "./actions";

function invoiceCall(clientId: string, lastKey = null) {
  return executeApiRequest<AWSRes<InvoiceParcela>>(
    "/invoice",
    "GET",
    {},
    {
      queryStringParameters: {
        idQuery: clientId,
        idFilterType: "=",
        indexToUse: "lsi4",
        lastKey: JSON.stringify(lastKey),
      },
    }
  );
}

function* invoiceWorker({ payload }: any) {
  try {
    const res: AWSRes<InvoiceParcela> = yield call(invoiceCall, payload);
    if (res.lastKey) {
      for (let i = 0; i < 5; i++) {
        const res1: AWSRes<InvoiceParcela> = yield call(
          invoiceCall,
          payload,
          res.lastKey
        );
        res.items = [...res.items, ...res1.items];
        res.Count += res1.Count;
        res.ScannedCount += res1.ScannedCount;
        res.lastKey = res1.lastKey;
        if (!res1.lastKey) {
          break;
        }
      }
    }
    yield put(clientInvoiceSuccess(res));
  } catch (error) {
    console.log("error on request", error, error.response);
    yield put(
      clientInvoiceError(error.response ? error.response.data.error : error)
    );
  }
}

export default function* invoiceViewSaga() {
  yield all([takeLatest(CLIENT_INVOICE_REQUESTING, invoiceWorker)]);
}
