import { useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";

/**
 * If the logger user has admin privileges
 * @returns boolean
 */
export const useIsMaster = () => {
  const userRole = useSelector((state: RootState) => state.login.userRole);

  return userRole === "Master";
};
