import { AWSRes } from "./../../../redux/types";
import { InvoiceParcela } from "./../actions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InvoiceReportInitialState = {
  Items: InvoiceParcela[];
  Count: number;
  lastKey: Record<string, string>;
  error: string;
  isRequesting: boolean;
};

export type InvoiceReportRequestPayload = {
  dataIni: Date;
  dataFim: Date;
  tipo: boolean;
  tipoData: string;
};

const initialState: InvoiceReportInitialState = {
  Items: [],
  Count: 0,
  lastKey: null,
  error: "",
  isRequesting: false,
};

const reportSlice = createSlice({
  name: "invoiceReport",
  initialState,
  reducers: {
    requestInvoices: (state, _: PayloadAction<InvoiceReportRequestPayload>) => {
      state.isRequesting = true;
      state.Items = [];
      state.lastKey = null;
      state.error = "";
      state.Count = 0;
    },
    invoiceSuccess: (
      state,
      { payload }: PayloadAction<AWSRes<InvoiceParcela>>
    ) => {
      state.isRequesting = false;
      state.Items = payload.items;
      state.lastKey = payload.lastKey;
      state.Count = payload.Count;
    },
    invoiceError: (state, { payload }: PayloadAction<string>) => {
      state.isRequesting = false;
      state.error = payload;
    },
  },
});

export const invoiceReportsActions = reportSlice.actions;

export default reportSlice;
