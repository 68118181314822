export const traduzQuandoTipoObj = (tipoObj: string) => {
  switch (tipoObj) {
    case "Prod":
      return "Produto";
    case "NCM":
      return "NCM";
    case "Dest":
      return "Cliente";
    case "UF":
      return "UF";
    case "Emissor":
      return "Emissor";
    case "TipoNF":
      return "tipo";
    default:
      return tipoObj;
  }
};
