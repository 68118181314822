import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// reducer do form do modulo sales
import { Sale } from "../types";

type salesFormInitialState = {
  item: Sale;
  successMsg: string;
  error: string;
  isRequesting: boolean;
  isSaving: boolean;
};

const initialState: salesFormInitialState = {
  item: null,
  successMsg: "",
  error: "",
  isRequesting: false,
  isSaving: false,
};

const reducerSlice = createSlice({
  name: "salesForm",
  initialState,
  reducers: {
    saleRequest(
      state,
      _: PayloadAction<{ invoiceId: string; duplicateId?: string }>
    ) {
      state.item = null;
      state.successMsg = "";
      state.error = "";
      state.isRequesting = true;
      state.isSaving = false;
    },

    saleSuccess(state, { payload }: PayloadAction<Sale>) {
      state.item = payload;
      state.isRequesting = false;
    },

    saleError(state, { payload }: PayloadAction<string>) {
      state.isRequesting = false;
      state.error = payload;
    },

    saveRequest(state, _: PayloadAction<Sale>) {
      state.isSaving = true;
      state.error = "";
      state.successMsg = "";
    },

    saveSuccess(state, { payload }: PayloadAction<Sale>) {
      state.isSaving = false;
      state.successMsg = "Venda salva com sucesso!";
      state.item = payload;
    },

    saveError(state, { payload }: PayloadAction<string>) {
      state.isSaving = false;
      state.error = payload;
    },
  },
});

export const salesFormActions = reducerSlice.actions;

export default reducerSlice;
